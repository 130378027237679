import React, { useState, useEffect } from "react";
import bootstrap from "../../assets/libraries/bootstrap/js/bootstrap";
import { api } from "../../utils/Api";
import ChooseBrandOutletModal from "./ChooseBrandOutletModal";
import ModalUsers from "./ModalUsers";
import toast, { Toaster } from "react-hot-toast";

let myModal : any;
export default function ModalReward(props : any) {
  const [close_data, SetCloseData] = useState({
    action: "close",
    value:"",
    index:"",
    data:"",
    demo_order:false
  });

  const [allow,setAllow] = useState("");
  const [reward,setReward] = useState("")
  const [notes,setNotes] = useState([])
  const [note_id,setNoteId] = useState("")
  const [brand_id,setBrandId] = useState("")
  const [user_id,setUserId] = useState("")
  const [dialCode,setDialCode] = useState("+91")
  const [mobileNumber, setMobileNumber] = useState("");
  const [isValid, setIsValid] = useState(true);


  const [selectedOption, setSelectedOption] = useState(null);
  const [seller_modal, setSellerModal] = useState({
    is_open: false,

  });

  const [user_modal, setUserModal] = useState({
    is_open: false,

  });

  const handleMobileNumberChange = (e) => {
    const value = e.target.value;
    // Only allow numbers and check if the length is 10
    if (/^\d{0,10}$/.test(value)) {
      setMobileNumber(value);
      setIsValid(value.length === 10); // Valid if exactly 10 digits
    }
  };

  const handleInputChange = (e: any) => {
    const searchValue = e.target.value;
    setReward(searchValue);
  }
  const handleDialCodeChange = (event) => {
    setDialCode(event.target.value);
  };

  
  useEffect(() => {
    console.log("ModelDeleteUserRoles On mount :", props);
    let myModal1 = document.getElementById("cart");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      // setSelectedOption(null)
      setReward("")
      setBrandId("")
      setUserId("")
        setDialCode("+91")
        setMobileNumber("")
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {

      myModal = new bootstrap.Modal(
        document.getElementById("cart"),
        {}
      );
      myModal.show();
console.log(user_id)
    }
  }, [props.open]);
  

 
  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
    if(event.target.value  != "mobileNumber" ){
    setIsValid(true)
    }else{
    setIsValid(mobileNumber.length === 10)

    }
  };


  

  return (
    <div
      className="modal fade"
      id="cart"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="  modal-title" id="ModalLabel">
            Add Reward Points
            {console.log(props)}
            </h6>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
      <Toaster />

          <div class="container">
            
          <b>Account Type</b>
      <form>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="options"
            id="inApp"
            value="inApp"
            onChange={handleOptionChange}
            // checked={selectedOption === 'inApp'} 
          />
          <label className="form-check-label" htmlFor="inApp">
            InApp
          </label>
        </div>

        {selectedOption === 'inApp' && (
          <div className="mt-3">
            <button type="button" className="btn btn-primary"
              onClick={() =>
                // setSellerModal((prevValue: any) => ({
                //   ...prevValue,
                //   is_open: true,
                // })
              // )
    props.sendMessageToParent("brand")

            }
            >
              Choose Seller
            </button>
          </div>
        )}
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="options"
            id="user"
            value="user"
            onChange={handleOptionChange}
            // checked={selectedOption === 'user'} 
          />
          <label className="form-check-label" htmlFor="user">
            User
          </label>
        </div>
        {selectedOption === 'user' && (
          <div className="mt-3">
            <button type="button" className="btn btn-primary"
              onClick={() =>
                // setUserModal((prevValue: any) => ({
                //   ...prevValue,
                //   is_open: true,
                // })
    props.sendMessageToParent("user")
              
             }
            >
              Choose User
            </button>
          </div>
        )}
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="options"
            id="mobileNumber"
            value="mobileNumber"
            onChange={handleOptionChange}
            // checked={selectedOption === 'mobileNumber'}
          />
          <label className="form-check-label" htmlFor="mobileNumber">
            Mobile Number
          </label>
        </div>

        {selectedOption === 'mobileNumber' && (
      <form className="d-flex">
      <input type="tel" id="dialCode" name="dialCode" placeholder="+91"     value={dialCode} style={{width:"40px"}}
        onChange={handleDialCodeChange}
             pattern="\+[0-9]{1,4}" required />
    
    <input
        type="tel"
        id="mobile"
        name="mobile"
        placeholder="Enter mobile number"
        value={mobileNumber}
        onChange={handleMobileNumberChange}
        pattern="[0-9]{10}"
        required
      />
     
    
    </form>
        )}
        </form>
        {!isValid && (
        <p style={{ color: 'red' }}>Please enter a valid 10-digit mobile number.</p>
      )}
  </div>
          {/* <select
              className="form-select p-2 border-radius"
              value={note_id}
              onChange={handleApplicationChange}
            >
               <option value="" disabled hidden>Select Response</option>
              {notes?.map((ele, i) => (
                <option key={i} value={ele?.id}>
                  {ele?.name}
                </option>
              ))}

<option  value="0">
                  Other
                </option>
            </select> */}
            <div>
           <div className="m-2">Reward Points</div> 
          <input  className="form-control" value= {reward} onChange={handleInputChange}/>
          </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-success"
            
              onClick={() => {
                // myModal.hide();
                // Save

                if (!isValid) {
                  toast.error("Please enter a valid 10-digit mobile number.");
                  return; // Stop further execution if invalid
                }
                let item: any = {
                  action: "close",
                  value: "",
                  index: "",
                  demo_order: false
                };
                let data: any = {
                mobile:mobileNumber,
                dial:dialCode,
reward:reward,
                };
                item.action = "save";

                setReward("")
                setBrandId("")
                setUserId("")
                  setDialCode("+91")
                  setMobileNumber("")
                item.data = data;
                props.close(item);

                myModal.hide();
               } }
                  // data-bs-dismiss="modal"
            >
              Save
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>

     
        </div>
      </div>
   
    </div>
  );
}
