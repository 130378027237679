import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { api } from "../../utils/Api";

import { CopyToClipboard } from "react-copy-to-clipboard";
import ModalAddGallery from "./ModalAddGallery";
import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import ModalDelete from "../../components/ModalDelete/ModalDelete";
import toast from "react-hot-toast";
import InfiniteScroll from "react-infinite-scroll-component";
import { AppHelper } from "../../utils/Helper";

import Skeleton from "react-loading-skeleton";

export default function Gallery(props: any) {
  const [id_value, SetIdValue] = useState(useParams());

  const [image_base_url, SetImageBaseUrl] = useState("");
  const [gallery_data, SetGalleryData] = useState([]);
  const [copied, setCopied] = useState(false);
  const [modal_gallery, SetModalGallery] = useState({
    is_open: false,
    data: {},
    type: "add",
  });
  const [modal_delete, SetModalDelete] = useState({
    is_open: false,
    delete_type: "single",
    data: {
      title: "",
      body: "",
    },
    have_option: false,
    options: {},
  });

  const [filter_data, SetFilterData] = useState({
    is_checked: false,
    is_all_checked: false,
    page_no: 1,
    next_page: false,
    count: { label: "All", value: "all" },
    count_option: [
      { label: "All", value: "all" },
      { label: "10", value: "10" },
      { label: "20", value: "20" },
      { label: "30", value: "30" },
    ],
    sort: { label: "asc", value: "asc" },
    sort_option: [
      { label: "asc", value: "asc" },
      { label: "desc", value: "desc" },
    ],
    search: "",
  });

  useEffect(() => {
    if (filter_data.search == "") {
      get_gallery(filter_data, false);
    } else {
      const timeOutId = setTimeout(
        () => triggerItemChange(filter_data.search),
        500
      );
      return () => clearTimeout(timeOutId);
    }
  }, [filter_data.search]);

  function triggerItemChange(value) {
    SetFilterData((prevValue: any) => {
      prevValue.page_no = 1;
      get_gallery(prevValue, false);
      return { ...prevValue };
    });
  }

  async function get_gallery(filter_value, fetch_value) {
    let pass_data = {
      get: {
        page: filter_value.page_no,
        search : filter_value.search,
      },
    };

    let response = await api("/Imagegallery/get", pass_data);
    if (response.status_code == 200) {
      if (response.response.hasOwnProperty("next_page")) {
        SetFilterData((prevValue: any) => {
          prevValue.next_page = response.response.next_page;
          return { ...prevValue };
        });
      }
      if (response.response.hasOwnProperty("sizes")) {
        SetSizeData(response.response.sizes);
      }
      if (response.response.hasOwnProperty("image_base_url")) {
        SetImageBaseUrl(response.response.image_base_url);
      }
      if (response.response.hasOwnProperty("data")) {
        let gallery_value = response.response.data;

        if (fetch_value) {
          SetGalleryData((prevValue: any) => {
            let old_data = prevValue,
              resp_data = response.response.data,
              new_data = [];
            old_data.map((ele: any) => {
              new_data.push(ele);
            });
            resp_data.map((ele: any) => {
              new_data.push(ele);
            });
            prevValue = new_data;

            SetFilterData((prevValue1: any) => {
              if (prevValue.every((v_ele) => v_ele.checked == true)) {
                prevValue1.is_all_checked = true;
              } else {
                prevValue1.is_all_checked = false;
              }
              return { ...prevValue1 };
            });
            return [...prevValue];
          });
        } else {
          SetFilterData((prevValue: any) => {
            if (gallery_value.every((v_ele) => v_ele.checked == true)) {
              prevValue.is_all_checked = true;
            } else {
              prevValue.is_all_checked = false;
            }
            return { ...prevValue };
          });
          SetGalleryData(response.response.data);
        }
      }
    }
   
  }

  async function delete_image(pass_value, product_data_value, delete_type) {
    let pass_data = {
      get: {},
      post: {
        images: [],
      },
    };

    if (delete_type == "single") {
      pass_data.post.images.push(pass_value.data.id);
    } else {
      let push_value = [];
      product_data_value.map((ele: any) => {
        if (ele.checked) {
          push_value.push(ele.id);
        }
      });
      pass_data.post.images = push_value;
    }
    // if (context.app_data.app_from == 'seller') {
    if (id_value.hasOwnProperty("brand_id")) {
      pass_data.get.brand = id_value.brand_id;
    } else {
      pass_data.get.m_id = id_value.m_id;
    }

    let res_data = await api("/Imagegallery/delete", pass_data);
    if (res_data.status_code == 200) {
      toast.success("Gallery Image Deleted");

      SetFilterData((prevValue1: any) => {
        prevValue1.is_checked = false;
        prevValue1.is_all_checked = false;
        return { ...prevValue1 };
      });

      get_gallery(filter_data, false);

      if (res_data.response.hasOwnProperty("data")) {
        SetGalleryData(res_data.response.data);
      } else {
        SetGalleryData([]);
      }
    } else {
      get_gallery(filter_data, false);
    }
  }
  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <div className="">
      {/* Header */}

      <div className="head-bar ">
        <div className="row">
          <div className="col-md-6 col-9">
            <h5 className="mb-0">Gallery</h5>
          </div>
          <div className="d-md-none d-sm-block col-3 text-end">
            <button
              className="btn btn-prime btn-small px-3"
              onClick={() => {
                SetModalGallery((prevValue: any) => {
                  prevValue.is_open = true;
                  prevValue.type = "add";
                  return { ...prevValue };
                });
              }}
            >
              <span className="small">Add</span>
            </button>
          </div>
          <div className="col-md-6 col-12 sm-pt-2">
            <div className="input-icons form-group p-2">
              <input
                type="text"
                className="form-control search-bar p-0"
                placeholder="Search here ..."
                onChange={(e) => {
                  SetFilterData((prevValue1: any) => {
                    prevValue1.search = e.target.value;
                    get_gallery(prevValue1, false);
                    return { ...prevValue1 };
                  });
                }}
            
              />
              <div className="cursor px-2 border-left">
                <Ionicons name="md-search" size={16} color="#ccc" />
              </div>
            </div>
          </div>
        </div>
        <div className=" row align-items-center pt-3 mb-2">
          <div className="col-md-6 col-10 d-flex px-0">
            {/* search */}
            <div className="">
              {!filter_data.is_checked ? (
                <div className="">
                  {/* Select All */}
                  <div
                    className="px-3  sm-px-1 d-flex align-items-center cursor"
                    onClick={() => {
                      SetGalleryData((prevValue: any) => {
                        prevValue.map((ele) => {
                          ele.checked = true;
                        });
                        SetFilterData((prevValue1: any) => {
                          prevValue1.is_checked = true;
                          if (
                            prevValue.every((v_ele) => v_ele.checked == true)
                          ) {
                            prevValue1.is_all_checked = true;
                          } else {
                            prevValue1.is_all_checked = false;
                          }
                          return { ...prevValue1 };
                        });
                        return [...prevValue];
                      });
                    }}
                  >
                    <MaterialCommunityIcons
                      name="checkbox-marked-circle-outline"
                      size={16}
                      color="#333"
                    />
                    <p className="ps-1 text-black">Select All</p>
                  </div>
                </div>
              ) : (
                <div className="d-flex align-items-center">
                  {/* Select All */}
                  {!filter_data.is_all_checked ? (
                    <div
                      className="px-3   sm-px-1 d-flex align-items-center cursor border-right"
                      onClick={() => {
                        SetGalleryData((prevValue: any) => {
                          prevValue.map((ele) => {
                            ele.checked = true;
                          });
                          SetFilterData((prevValue1: any) => {
                            prevValue1.is_checked = true;
                            if (
                              prevValue.every((v_ele) => v_ele.checked == true)
                            ) {
                              prevValue1.is_all_checked = true;
                            } else {
                              prevValue1.is_all_checked = false;
                            }
                            return { ...prevValue1 };
                          });
                          return [...prevValue];
                        });
                      }}
                    >
                      <MaterialCommunityIcons
                        name="checkbox-marked-circle-outline"
                        size={16}
                        color="#333"
                      />
                      <p className="ps-1 text-black ">Select All</p>
                    </div>
                  ) : null}
                  {/* Un Select All */}
                  <div
                    className="px-3  sm-px-1 border-right d-flex align-items-center cursor"
                    onClick={() => {
                      SetGalleryData((prevValue: any) => {
                        prevValue.map((ele) => {
                          ele.checked = false;
                        });
                        SetFilterData((prevValue1: any) => {
                          prevValue1.is_checked = false;
                          if (
                            prevValue.every((v_ele) => v_ele.checked == true)
                          ) {
                            prevValue1.is_all_checked = true;
                          } else {
                            prevValue1.is_all_checked = false;
                          }
                          return { ...prevValue1 };
                        });
                        return [...prevValue];
                      });
                    }}
                  >
                    <MaterialCommunityIcons
                      name="progress-close"
                      size={16}
                      color="#333"
                    />
                    <p className="ps-1 text-black">Unselect All</p>
                  </div>
                  {/* Delete */}
                  <div
                    className="px-3   sm-px-1 d-flex align-items-center cursor"
                    onClick={() => {
                      SetModalDelete((prevValue: any) => {
                        prevValue.is_open = true;
                        prevValue.delete_type = "multiple";
                        prevValue.data = {
                          title: "Delete Gallery Images ",
                          body: "Are you sure? want to delete selected gallery image",
                          data: gallery_data,
                        };
                        prevValue.have_option = false;
                        prevValue.options = {};
                        return { ...prevValue };
                      });
                    }}
                  >
                    <MaterialCommunityIcons
                      name="delete-outline"
                      size={16}
                      color="#333"
                    />
                    <p className="ps-1 text-black">Delete</p>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="col-md-6 col-2 text-end d-sm-none d-md-block">
            <button
              className="btn btn-prime btn-small px-3 btn-sm"
              onClick={() => {
                SetModalGallery((prevValue: any) => {
                  prevValue.is_open = true;
                  prevValue.type = "add";
                  return { ...prevValue };
                });
              }}
            >
              <span className="small">Add</span>
            </button>
          </div>
        </div>
      </div>

      {/* Body */}
      <div className=" sm-mt-2 sm-pt-4">
        <div className="p-3 mb-3">
          <div className="w-100">
            {gallery_data.length > 0 ? (
              <InfiniteScroll
                dataLength={gallery_data.length}
                next={() => {
                  SetFilterData((prevValue: any) => {
                    prevValue.page_no = prevValue.page_no + 1;
                    get_gallery(prevValue, true);
                    return { ...prevValue };
                  });
                }}
                hasMore={filter_data.next_page}
                className=""
                loader={
                  <div className="center m-5">
                    <div className="lds-dual-ring"></div>
                    <p className=" small ps-2">Just a second ...</p>
                  </div>
                }
                endMessage={
                  <div className="center pt-5 pb-3">
                    <hr className="w-25" />
                  </div>
                }
              >
                {gallery_data.length != 0 ? (
                  <div className="row px-2">
                    {gallery_data.map((i_ele: any, i_index: any) => (
                      <div className="col-md-3 col-6 mb-2 px-1" key={i_index}>
                        <div
                          className={
                            gallery_data[i_index].checked
                              ? "card-border card p-1 h-100"
                              : "card p-1 h-100"
                          }
                        >
                          <label className="d-flex align-items-center cursor">
                            <div className="position-check ">
                              <input
                                className={
                                  gallery_data[i_index].checked
                                    ? "form-check-input-custom"
                                    : ""
                                }
                                type="checkbox"
                                checked={i_ele.checked}
                                onChange={() => {
                                  SetGalleryData((prevValue: any) => {
                                    prevValue[i_index].checked =
                                      !prevValue[i_index].checked;

                                    SetFilterData((prevValue1: any) => {
                                      if (
                                        prevValue.some(
                                          (v_ele) => v_ele.checked == true
                                        )
                                      ) {
                                        prevValue1.is_checked = true;
                                        // SetImageUrl(image_base_url + i_ele.url);
                                      } else {
                                        prevValue1.is_checked = false;
                                        // SetImageUrl("");
                                      }

                                      if (
                                        prevValue.every(
                                          (v_ele) => v_ele.checked == true
                                        )
                                      ) {
                                        prevValue1.is_all_checked = true;
                                      } else {
                                        prevValue1.is_all_checked = false;
                                      }
                                      return { ...prevValue1 };
                                    });

                                    return [...prevValue];
                                  });
                                }}
                              />
                            </div>
                            <img
                              src={AppHelper.imageSrc(
                                image_base_url,
                                i_ele.url,
                                {
                                  format: "webp",
                                  width: 320,
                                }
                              )}
                              className="gallery_img"
                            />
                            <div className="copyclipboar-div">
                              <CopyToClipboard
                                text={image_base_url + i_ele.url}
                                onCopy={() => {
                                  setCopied(true);
                                  toast.success("Gallery Image Deleted");
                                }}
                              >
                                <button
                                  className="btn btn-dark btn-sm shadow px-2"
                                  onClick={() => {}}
                                >
                                  <Ionicons
                                    name="clipboard-outline"
                                    size={16}
                                    color="white"
                                  />
                                </button>
                              </CopyToClipboard>
                            </div>
                             <div className="copyclipboarsec-div">
                              <CopyToClipboard
                                text={i_ele.url}
                                onCopy={() => {
                                  setCopied(true);
                                }}
                              >
                                <button
                                  className="btn btn-dark btn-sm shadow px-2"
                                  onClick={() => {}}
                                >
                                  <Ionicons
                                    name="image"
                                    size={16}
                                    color="white"
                                  />
                                </button>
                              </CopyToClipboard>
                            </div>
                          </label>
                          <div className="d-flex"></div>
                          <div className="img_over_content_gallery">
                            <div className="d-flex align-items-center mt-1">
                            <p className="v-small text-darkgray mb-0 img-name" style={{ maxHeight: "3em", overflow: "hidden", lineHeight: "1.5em", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                                {i_ele.name}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="center p-3">
                    <div className="card p-3 w-100">
                      <div className="w-100 text-center">
                        <img
                          src={require("../../assets/img/not-found.png")}
                          className="no-data-img"
                        />
                      </div>
                      <p className="center pt-3">No images found</p>
                    </div>
                  </div>
                )}
              </InfiniteScroll>
            ) : (
              <div className="row px-2 ">
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((ele) => (
                  <div className="col-md-3 col-6 mb-2 px-1">
                    <div className="card p-1">
                      <Skeleton height={140} width="100%" />

                      <div className=" mt-1">
                        <Skeleton height={10} width="100%" />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      <ModalAddGallery
        open={modal_gallery.is_open}
        type={modal_gallery.type}
        data={modal_gallery.data}
        from={"gallery"}
        close={(data: any) => {
          SetModalGallery((prevValue: any) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });
          if (data.action == "save") {

            console.log("hi")
            SetFilterData((prevValue: any) => {
              prevValue.page_no = 1;
              get_gallery(prevValue, false);
              return { ...prevValue };
            });
          }
        }}
      />

      <ModalDelete
        open={modal_delete.is_open}
        data={modal_delete.data}
        delete_type={modal_delete.delete_type}
        have_option={modal_delete.have_option}
        options={modal_delete.options}
        close={(data) => {
          SetModalDelete((prevValue: any) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });

          if (data.action == "save") {
            delete_image(data, data.data, data.delete_type);
          }
        }}
      />
    </div>
  );
}
