"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resolveAxisDirection = exports.getDirectionValue = exports.getDirectionKey = exports.getDifference = void 0;

var _types = require("../types");

var getDirectionKey = function getDirectionKey() {
  var object = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var key = Object.keys(object).toString();

  switch (key) {
    case _types.TraceDirectionKey.POSITIVE:
      return _types.TraceDirectionKey.POSITIVE;

    case _types.TraceDirectionKey.NEGATIVE:
      return _types.TraceDirectionKey.NEGATIVE;

    default:
      return _types.TraceDirectionKey.NONE;
  }
};

exports.getDirectionKey = getDirectionKey;

var getDirectionValue = function getDirectionValue() {
  var values = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  return values[values.length - 1] || 0;
};

exports.getDirectionValue = getDirectionValue;

var getDifference = function getDifference() {
  var x = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  var y = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  return Math.abs(x - y);
};

exports.getDifference = getDifference;

var resolveAxisDirection = function resolveAxisDirection(axis, key) {
  var negative = _types.Direction.LEFT;
  var positive = _types.Direction.RIGHT;
  var direction = _types.Direction.NONE;

  if (axis === _types.Axis.Y) {
    negative = _types.Direction.BOTTOM;
    positive = _types.Direction.TOP;
  }

  if (key === _types.TraceDirectionKey.NEGATIVE) {
    direction = negative;
  }

  if (key === _types.TraceDirectionKey.POSITIVE) {
    direction = positive;
  }

  return direction;
};

exports.resolveAxisDirection = resolveAxisDirection;