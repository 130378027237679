import React, { useState, useEffect } from "react";
import bootstrap from "../../assets/libraries/bootstrap/js/bootstrap";
import { api } from "../../utils/Api";
let myModal : any;
export default function ModalSettleUpdate(props : any) {

  const initial_data={
    action: "close",
    det:"",
    from:"",

    value:{},
  }
  const [close_data, SetCloseData] = useState(initial_data);
  const [price,setPrice] = useState("")

  const [allow,setAllow] = useState("")
  const [eligible,setEligible] = useState(false)

  const [approve,setApprove] = useState(false)

  const [paid,setPaid] = useState(false)



  

  useEffect(() => {
    console.log("ModelDeleteUserRoles On mount :", props);
    let myModal1 = document.getElementById("eligible");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      // console.log("event :", event);
      // SetCloseData(initial_data)

      resetCloseData();
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      console.log("ModelDeleteUserRoles On props.open :", props.datayyy);

      setPrice(props.data.price)
      if(props.data.eligible ==true){
        setEligible(true)
      }else{
        setEligible(false)
 }

 if(props.data.approve ==true){
  setApprove(true)
}else{
  setApprove(false)
}


if(props.data.paid ==true){
  setPaid(true)
}else{
  setPaid(false)
}

      myModal = new bootstrap.Modal(
        document.getElementById("eligible"),
        {}
      );
      myModal.show();
    }
  }, [props.open]);



  function eligibleOnChange(val){
    if(val==true){
    setEligible(false)
    saveEligible(false)
    }else{
      setEligible(true)
      saveEligible(true)

    }
  }

  function approveOnChange(val){
    if(val==true){
    setApprove(false)
    saveApprove(false)

    }else{
      setApprove(true)
    saveApprove(true)


    }
  }

  function paidOnChange(val){
    if(val==true){
    setPaid(false)
    }else{
      setPaid(true)

    }
  }
  async function Save(val) {

    let pass_data = {
    
      post: {

        status: "2",
        order_id: props.data.value
    
      }
    };
    console.log(pass_data)
    let data_res;
    if(props.data.from =="ship"){

      if(props.data.det =="eligible"){
      
        data_res = await api("/deliverypartners/update_settlement_eligible", pass_data);
        }else if(props.data.det =="approved"){
          data_res = await api("/deliverypartners/update_settlement_approved", pass_data);

  
        }else{
          data_res = await api("/deliverypartners/update_settlement_paid", pass_data);
        }
    
    } else if(props.data.from =="cod"){
      if(props.data.det =="eligible"){
      data_res = await api("/deliverypartners/update_partner_cod_eligible_orders", pass_data);
      }else if(props.data.det =="approved"){
        data_res = await api("/deliverypartners/update_partner_cod_approved_orders", pass_data);

      }else{
        data_res = await api("/deliverypartners/update_partner_cod_paid_orders", pass_data);
      }
     }else{
      if(props.data.det =="eligible"){
      data_res = await api("/orders/update_seller_settlement_eligible", pass_data);
      }else if(props.data.det =="approved"){
        data_res = await api("/orders/update_seller_settlement_approved", pass_data);

      }else{
        data_res = await api("/orders/update_seller_settlement_paid", pass_data);
      }
    }
  console.log(data_res)
    if (data_res.status_code == 200) {
    let item = close_data;
    console.log(item)
    item.action = "save";
    // item.value =  data_res.response.data.cod_allowed_all_users;

    if(props.data.from =="cod"){
      item.value =  data_res.response;
      item.det = props.data.det;
      item.from = props.data.from;
      }

    if(props.data.from =="ship"){
      item.value =  data_res.response;
      item.det = props.data.det;
      item.from = props.data.from;
      }
    if(props.data.from =="details"){
    item.value =  data_res.response.data[0];
    item.det = props.data.det
    }

    SetCloseData(item);
  
    myModal.hide();
    }
  }

  // async function saveApprove(val) {

  //   let pass_data = {
    
  //     post: {

  //       status: val?"1":"2",
  //       order_id: props.data.value
    
  //     }
  //   };
  //   console.log(pass_data)
  //   // let data_res;
  //   // if(props.data.from =="ship"){
  //   //  data_res = await api("/deliverypartners/update_settlement_eligible", pass_data);
  //   // } else if(props.data.from =="cod"){
  //   //   data_res = await api("/deliverypartners/update_partner_cod_eligible_orders", pass_data);
  //   //  }else{
  //   //  let data_res = await api("/orders/update_seller_settlement_eligible", pass_data);

  //    let data_res = await api("/orders/update_seller_settlement_approved", pass_data);
  //   // }
  // console.log(data_res)
  //   if (data_res.status_code == 200) {
  //   // let item = close_data;
  //   // console.log(item)
  //   // item.action = "save";
  //   // item.value =  data_res.response.data.cod_allowed_all_users;
  //   // if(props.data.from =="details"){
  //   // item.value =  data_res.response.data[0];
  //   // }

  //   // SetCloseData(item);
  
  //   // myModal.hide();
  //   }
  // }
  // async function Save() {

  //   let pass_data = {
    
  //     post: {

  //       status: paid?"1":"2",
  //       order_id: props.data.value,
  //       amount: price
  //     }
  //   };
  //   console.log(pass_data)
  //   // let data_res;
  //   // if(props.data.from =="ship"){
  //   //  data_res = await api("/deliverypartners/update_settlement_paid", pass_data);
  //   // } else if(props.data.from =="cod"){
  //   //   data_res = await api("/deliverypartners/update_partner_cod_paid_orders", pass_data);
  //   //  }else{
  //    let data_res = await api("/orders/update_seller_settlement_paid", pass_data);
  //   // }
  // console.log(data_res)
  //   if (data_res.status_code == 200) {
  //   let item = close_data;
  //   console.log(item)
  //   item.action = "save";
  //   // if(props.data.from =="details"){
  //   // item.value =  data_res.response.data[0];
  //   // }
  //   SetCloseData(item);
  
  //   myModal.hide();
  //   }
  // }
  // function priceOnchange(e){
  //   setPrice(e.target.value)
  // }
  const resetCloseData = () => {
    SetCloseData(initial_data);
  };
  return (
    <div
      className="modal fade"
      id="eligible"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="  modal-title" id="ModalLabel">
            {/* {props.data.from =="cod"?"Handover":"Settlement"} Eligible
             */}
             Are you confirm make {props.data.from == "cod"? "COD":"settlement"} not {props.data.det}
            </h6>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">

   
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-success"
            
              onClick={
                // myModal.hide();
                Save}
                  // data-bs-dismiss="modal"
            >
              Ok
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={resetCloseData}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
