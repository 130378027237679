"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateTrace = updateTrace;

function updateTrace(trace, value) {
  var last = trace[trace.length - 1];

  if (last !== value) {
    trace.push(value);
  }

  return trace;
}