import React, { useState, useEffect, useContext } from "react";
import bootstrap from "../../assets/libraries/bootstrap/js/bootstrap";
import { api } from "../../utils/Api";

import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  Ionicons,
  MaterialIcons,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
let myModal = {};

export default function DeleteConfirmModal(props: any) {
  const [close_data, SetCloseData] = useState({
    action: "close",
  });
  const [timeoutId, setTimeoutId] = useState(null);

  const [page_no, SetPageNo] = useState(1);
  const [next_page, SetNextPage] = useState(false);
  const [filter_data, setFilterData] = useState("");
  const [order_data, SetOrderData] = useState([]);
  const [visibleContent, SetVisibleContent] = useState(false);


  useEffect(() => {
    let myModal1 = document.getElementById("DeleteConfirmModalID");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      myModal = new bootstrap.Modal(
        document.getElementById("DeleteConfirmModalID"),
        {}
      );
      myModal.show();

      let pass_data = {
        order_status: 1,
        seller_brand_id: "",
        seller_outlet_id: "",
        sort: "",
        page_no: page_no,
      };
      get_users(pass_data, filter_data);
    }
  }, [props.open]);


  async function get_users(get_data: any, search: any) {
 console.log("hi")
    if (!get_data.load_more) {
      SetVisibleContent(false);
    }
    // {get_data.refresh &&
    //   setLoader(true)
    // }
    let pass_data = {
      get: {
        // order_status: get_data.order_status,
        sort:get_data.sort,
        page: get_data.page_no,
        
        search: search,
      },
    };
    let response: any = await api("/settings/superapp_users", pass_data);
    if (response.status_code == 200) {
      
// console.log(response.response)
// setLoader(false)
// disable()
      SetVisibleContent(true);

      if (response.response.hasOwnProperty("data")) {
        if (get_data.load_more) {
          SetOrderData((prevValue) => {
            response?.response?.data?.map((ele: any) => {
              prevValue.push(ele);
            });
            return [...prevValue];
          });
        } else {
          SetOrderData(response.response.data);
        }
      }

      if (response.response.hasOwnProperty("next_page")) {
        SetNextPage(response.response.next_page);
        SetPageNo((prevalue) => {
          prevalue = prevalue + 1;
          return prevalue;
        });
      }

    //   if (response.response?.ordered_product_spec) {
    //     SetProductsDietType((prevValue: any) => {
    //       return { ...prevValue, ...response?.response?.ordered_product_spec };
    //     });
    //   }
    }
  }
  const handleInputChange = (e: any) => {
   
    const searchValue = e.target.value;
   
    // Clear previous timeout
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // Set a new timeout
    const newTimeoutId = setTimeout(() => {
      SetPageNo(1)
      // Call your API with the search value
      setFilterData(searchValue);
      let pass_data = {
        sort: "",
        page_no: 1,
        // order_status:order_status
      };
console.log("hi")
      get_users(pass_data, searchValue);
     
    }, 1000); // 5 milliseconds delay
 
    setTimeoutId(newTimeoutId);
  };

  return (
    <div
      className="modal fade"
      id="DeleteConfirmModalID"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog  modal-lg modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <div className="  modal-title" id="ModalLabel">
              {/* <h5>{props.title}</h5> */}
              <h5>Choose Users</h5>
         
            </div>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                let item = close_data;
                item.action = "close";
                SetCloseData(item);
                myModal.hide();
              }}
            ></button>
          </div>
          <div className="modal-body" id="userBody">
          <div className="input-icons form-group p-2 w-100" style={{ width: '100%' }}>
        <input
          type="text"
          className="form-control search-bar p-0"
          placeholder="Search here ..."
          onChange={handleInputChange}
        />
        <div className="cursor px-2 border-left">
          <Ionicons name="md-search" size={16} color="#ccc" />
        </div>
      </div>
          {props.open && (
              <InfiniteScroll
                dataLength={order_data.length}
                next={() => {
                  let pass_data = {
                    // order_status: order_status,
                    seller_brand_id: "",
                    seller_outlet_id: "",
                    sort: "",
                    load_more: true,
    
                    page_no: page_no,
                  };
                  console.log("no")
                  get_users(pass_data, filter_data);
                }}
                hasMore={next_page}
                loader={
                  <div className="center m-5">
                    <div className="lds-dual-ring"></div>
                    <p className=" small ps-2">Just a second ...</p>
                  </div>
                }
                scrollableTarget="userBody"
              >
                {visibleContent ? (
                  order_data.length != 0 ? (
                    order_data?.map((ord_ele: any, ord_index: any) => (
                      <div 
                      // className="col-md-4 col-12 mb-3"
                       key={ord_index}>
  
  <div className="card p-12 mb-2 cursor"    onClick={() => {
                          let item: any = {
                            action: "close",
                            value: "",
                            index: "",
                            demo_order: false
                          };
                          item.action = "save";
                          item.data = ord_ele;
                          props.close(item);

                          myModal.hide();
                        }}>
                           {/* <Link to={"/orders/" + ord_ele.id}> */}
                             
                                <div className="d-flex border-bottom pb-2 mb-2">
                                  <div>
                                  <div className=" pb-3 d-flex">
                            {ord_ele.hasOwnProperty("profile") && ord_ele.profile != null
                             ? (
                              <div className="pe-2">
                                <img
                                  src={ord_ele.profile}
                                  alt="16.a1d4f3f3"
                                  className="rounded-float-start"
                                  height="34"
                                  width="34"
                                  style={{ borderRadius: "50px" }}
                                ></img>
                              </div>
                            ) : (
                              <div className="pe-2">
                                <img
                                  src={require("../../assets/icon/avatar.png")}
                                  className="rounded-float-start"
                                  height="34"
                                  width="34"
                                />
                              </div>
                            )}
                            <div className="">
                              <p className="text-dark fw-bold fs-7">
                                {ord_ele?.name}
                              </p>
                              <p>{ord_ele?.user_name}</p>
                            </div>
                          </div>
                          {/* {ord_ele.demo_order && */}
                          <div>
                                      Demo Order User:{" "}
                                      <span className="fw-500">{ord_ele.demo_order ? "Yes":"No"}</span>
                                    </div>
  {/* } */}
  

                                    <div>
                                   
                                      <span className="fw-500">
                                      {ord_ele.full_mobile}
  
  
                                      </span>
                                    </div>
  
                                    
  
                                    {/* <div className="mb-2">
                                      <span
                                        className={
                                          ord_ele?.status ==  "Order Placed" || ord_ele?.status =="Seller Accepted"
                                            ? "badge-1 me-2 badge-blue"
                                            : ord_ele?.status == "Seller Rejected"
                                            ? "badge-1 me-2 badge-red"
                                            : ord_ele?.status == "Auto Cancelled"
                                            ? "badge-1 me-2 badge-red-2"
                                            : ord_ele?.status == "Completed"
                                            ? "badge-1 me-2 badge-green"
                                            : "badge-1 me-2 badge-red-2"
                                        }
                                      >
                                        {ord_ele?.status}
                                      </span>
                                      
                                      <span className="fs-12">
                                        {ord_ele?.delivery_type
   == "Hyper Local" && (
                                          <img
                                            src={
                                              AppConfig.CDN_Media_url +
                                              "31b719f813a5801b0b9e68653ff6df89.svg"
                                            }
                                            className="img-fluid me-1"
                                            width="14"
                                          />
                                        )}
                                        {ord_ele?.delivery_type}
                                      </span>
                                    </div>
  {ord_ele.dm_order &&
                                    <span
                                        className="badge-1 me-2 badge-blue mt-2"
                                        >Demo</span>
  }
                                    <div className="mb-1 fw-500">
                                      {ord_ele?.seller?.brand_name}
                                    </div>
                                    <div className="mb-1 fw-500">
                                    {ord_ele?.seller?.outlet?.name}
                                    </div>
                                   */}
                                  </div>
                                  <div className="ms-auto text-end">
                                    <div className="mb-1">
                                      {/* {format(ord_ele?.created_timeago, "en_US")} */}
                                {ord_ele?.created_date}
                                    
                                    </div>
                                    <div>
                                      ID:{" "}
                                      <span className="fw-500">{ord_ele?.id}</span>
                                    </div>
                                    <div>
                                   {ord_ele?.sector}
                                    </div>

  
                                </div>
  
                                </div>
  
                                {/* <ProductList
                                  products={ord_ele?.ordered_products}
                                  diet_type={products_diet_type}
                                /> */}
                               {/* { !ord_ele.demo_order && */}
  
                            </div>
                            </div>
  
                    ))
                  ) : (
                    <div className="center p-3">
                      <div className="card p-3 w-100">
                        <div className="w-100 text-center">
                          <img
                            src={require("../../assets/img/not-found.png")}
                            className="w-20"
                          />
                        </div>
                        <p className="center pt-3">No Users</p>
                      </div>
                    </div>
                  )
                ) : (
                  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((ele: any) => (
                    <div className="card mb-2 p-3">
                      <Skeleton height={10} width={80} />
                    </div>
                  ))

                )}
              </InfiniteScroll>
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-sm btn-secondary me-3"
              onClick={() => {
                let item = close_data;
                item.action = "close";
                SetCloseData(item);
                myModal.hide();
              }}
            >
              Close
            </button>
            {/* <button
              type="button"
              className="btn btn-sm btn-secondary me-3"
              onClick={() => {
                myModal.hide();
                props.on_ok(props.data);
              }}
            >
              Ok
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
}
