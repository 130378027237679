import React ,{useState,useEffect} from 'react'
import { useOutletContext } from "react-router-dom";
import { api } from "../../utils/Api";
import InfiniteScroll from "react-infinite-scroll-component";

import {
  Ionicons,
  MaterialIcons,
  MaterialCommunityIcons,
} from "@expo/vector-icons";

function SellerTransaction() {
  const [statistics_data, SetStatisticsData]: any = useState([]);
  const OutletContext = useOutletContext();
  const [timeoutId, setTimeoutId] = useState(null);

  const [sort, setSort] = useState("");
  const [filter_data, setFilterData] = useState("");


    const [startDateShow, setStartDateShow] = useState("");
  const [endDateShow, setEndDateShow] = useState("");
  const [page_no, SetPageNo] = useState(1);
  const [next_page, SetNextPage] = useState(false);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [width, SetWidth] = useState(innerWidth);

  useEffect(() => {
   
    let send_data = {
      startDate:startDate,
      endDate:endDate,
      page_no: 1,
      search:filter_data
    };
    get_overview(send_data);
SetPageNo(1)
    // get_outlets_and_channels();
  }, [sort]);


  

  async function get_overview(get_data: any) {

 
     let pass_data = {
       get: {
         filter_from:get_data.startDate,
         filter_to: get_data.endDate,
         order_by: sort,

search:get_data.search,
         
        page: get_data.page_no,
       },
     };
     let response: any = await api("/rewardpoints/transactions", pass_data);
     console.log("/qr/overview response :", response.response);
     if (response.status_code == 200) {
       if (response.response.hasOwnProperty("data")) {

        
        //  let statistics_data = response.response.data;
 
         if (get_data.load_more) {
          SetStatisticsData((prevValue) => {
            response?.response?.data?.map((ele: any) => {
              prevValue.push(ele);
            });
            return [...prevValue];
          });
        } else {
          SetStatisticsData(response.response.data);

        }
    
 
  
       }
  
      // if(!get_data.hasOwnProperty("type")){
      if (response.response.hasOwnProperty("next_page")) {
        SetNextPage(response.response.next_page);
        SetPageNo((prevalue) => {
          prevalue = prevalue + 1;
          return prevalue;
        });
      }
 
      
     }
   }

   const handleInputChange = (e: any) => {
    const searchValue = e.target.value;
    setFilterData(searchValue);
    // Clear previous timeout
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // Set a new timeout
    const newTimeoutId = setTimeout(() => {
      // Call your API with the search value
      let send_data = {
      startDate:startDate,
      endDate:endDate,
      page_no: 1,
      search:   searchValue
    };
    get_overview(send_data);
      SetPageNo(1);
    }, 1000); // 5 milliseconds delay

    setTimeoutId(newTimeoutId);
  };

  return (
    <div>
    <div
    className={OutletContext ? "head-bar sticky-sm py-3" : "head-bar py-3"}
    style={OutletContext == false ? { width: width } : {}}
  >
        <h6 className="fs-5  mb-0">Reward Points Transaction</h6>
      
    <div className="d-flex align-items-center">
    {/* <div className="input-icons form-group p-2">
                            <input
                                type="text"
                                className="form-control search-bar p-0"
                                placeholder="Search here ..."
                                onChange={handleInputChange}
                            />
                            <div className="cursor px-2 border-left">

                            </div>
                        </div> */}
 


        </div>

    

        </div>

        
        <div className="container mt-5">
       
      
      <InfiniteScroll
            dataLength={statistics_data.length}
            next={() => {
              let send_data = {
                startDate:startDate,
                endDate:endDate,
                load_more: true,

                page_no: page_no,
      search:filter_data

              };
              get_overview(send_data);
            }}
            hasMore={next_page}
            loader={
              <div className="center m-5">
                <div className="lds-dual-ring"></div>
                <p className=" small ps-2">Just a second ...</p>
              </div>
            }
          >
            <div className="table-responsive " >
      <table class="table table-striped table-bordered">
            <thead class="table-dark">
                <tr style={{ cursor: 'pointer' }}>
             
         
         
                <th scope="row" onClick={() => {
        const newSort = sort === 'asc_id' ? 'desc_id' : 'asc_id';
        setSort(newSort);
      }}>
  <div className='d-flex'>
    <div>ID</div>
    {sort === "desc_id" ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === "asc_id" ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : <div> </div>}
  </div>
</th>

{/* Created At column */}
<th scope="row" onClick={() => {
        const newSort = sort === 'asc_created_at' ? 'desc_created_at' : 'asc_created_at';
        setSort(newSort);
      }}>
  <div className='d-flex'>
    <div>Date & Time</div>
    {sort === "desc_created_at" ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === "asc_created_at" ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : <div> </div>}
  </div>
</th>

{/* Points column */}
<th scope="row" onClick={() => {
        const newSort = sort === 'asc_points' ? 'desc_points' : 'asc_points';
        setSort(newSort);
      }}>
  <div className='d-flex'>
    <div>Points</div>
    {sort === "desc_points" ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === "asc_points" ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : <div> </div>}
  </div>
</th>
{/* From */}
<th scope="row" onClick={() => {
        const newSort = sort === 'asc_from_user_id' ? 'desc_from_user_id' : 'asc_from_user_id';
        setSort(newSort);
      }}>
  <div className='d-flex'>
    <div>From</div>
    {sort === "desc_from_user_id" ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === "asc_from_user_id" ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : <div> </div>}
        {sort === "desc_from_brand_id" ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === "asc_from_brand_id" ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : <div> </div>}
  </div>
</th>
{/* To */}
<th scope="row" onClick={() => {
        const newSort = sort === 'asc_brand_name' ? 'desc_brand_name' : 'asc_brand_name';
        setSort(newSort);
      }}>
  <div className='d-flex'>
    <div>To</div>
    {sort === "desc_brand_name" ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === "asc_brand_name" ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : <div> </div>}
  </div>
</th>

{/* Notes column */}
<th scope="row" onClick={() => {
        const newSort = sort === 'asc_notes' ? 'desc_notes' : 'asc_notes';
        setSort(newSort);
      }}>
  <div className='d-flex'>
    <div>Notes</div>
    {sort === "desc_notes" ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === "asc_notes" ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : <div> </div>}
  </div>
</th>
{/* Wallet Updated column */}
<th scope="row" onClick={() => {
        const newSort = sort === 'asc_wallet_updated' ? 'desc_wallet_updated' : 'asc_wallet_updated';
        setSort(newSort);
      }}>
  <div className='d-flex'>
    <div>Wallet Updated</div>
    {sort === "desc_wallet_updated" ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === "asc_wallet_updated" ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : <div> </div>}
  </div>
</th>

{/* Added User ID column */}
<th scope="row" onClick={() => {
        const newSort = sort === 'asc_added_user_id' ? 'desc_added_user_id' : 'asc_added_user_id';
        setSort(newSort);
      }}>
  <div className='d-flex'>
    <div>Added User Details</div>
    {sort === "desc_added_user_id" ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === "asc_added_user_id" ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : <div> </div>}
  </div>
</th>
{/* 
<th scope="row" onClick={() => {
        const newSort = sort === 'asc_added_user_id' ? 'desc_added_user_id' : 'asc_added_user_id';
        setSort(newSort);
      }}>
  <div className='d-flex'>
    <div>Added User Name</div>
    {sort === "desc_added_user_id" ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === "asc_added_user_id" ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : <div> </div>}
  </div>
</th>
<th scope="row" onClick={() => {
        const newSort = sort === 'asc_brand_name' ? 'desc_brand_name' : 'asc_brand_name';
        setSort(newSort);
      }}>
  <div className='d-flex'>
    <div>Brand Name</div>
    {sort === "desc_brand_name" ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === "asc_brand_name" ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : <div> </div>}
  </div>
</th>



<th scope="row" onClick={() => {
        const newSort = sort === 'asc_outlet_name' ? 'desc_outlet_name' : 'asc_outlet_name';
        setSort(newSort);
      }}>
  <div className='d-flex'>
    <div>Outlet Name</div>
    {sort === "desc_outlet_name" ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === "asc_outlet_name" ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : <div> </div>}
  </div>
</th>
<th scope="row" onClick={() => {
        const newSort = sort === 'asc_type' ? 'desc_type' : 'asc_type';
        setSort(newSort);
      }}>
  <div className='d-flex'>
    <div>Type</div>
    {sort === "desc_type" ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === "asc_type" ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : <div> </div>}
  </div>
</th>

<th scope="row" onClick={() => {
        const newSort = sort === 'asc_purpose' ? 'desc_purpose' : 'asc_purpose';
        setSort(newSort);
      }}>
  <div className='d-flex'>
    <div>Purpose</div>
    {sort === "desc_purpose" ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === "asc_purpose" ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : <div> </div>}
  </div>
</th>



<th scope="row" onClick={() => {
        const newSort = sort === 'asc_type_id' ? 'desc_type_id' : 'asc_type_id';
        setSort(newSort);
      }}>
  <div className='d-flex'>
    <div>Type ID</div>
    {sort === "desc_type_id" ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === "asc_type_id" ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : <div> </div>}
  </div>
</th>




<th scope="row" onClick={() => {
        const newSort = sort === 'asc_brand_id' ? 'desc_brand_id' : 'asc_brand_id';
        setSort(newSort);
      }}>
  <div className='d-flex'>
    <div>Brand ID</div>
    {sort === "desc_brand_id" ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === "asc_brand_id" ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : <div> </div>}
  </div>
</th>

<th scope="row" onClick={() => {
        const newSort = sort === 'asc_outlet_id' ? 'desc_outlet_id' : 'asc_outlet_id';
        setSort(newSort);
      }}>
  <div className='d-flex'>
    <div>Outlet ID</div>
    {sort === "desc_outlet_id" ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === "asc_outlet_id" ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : <div> </div>}
  </div>
</th>


<th scope="row" onClick={() => {
        const newSort = sort === 'asc_user_id' ? 'desc_user_id' : 'asc_user_id';
        setSort(newSort);
      }}>
  <div className='d-flex'>
    <div>User ID</div>
    {sort === "desc_user_id" ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === "asc_user_id" ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : <div> </div>}
  </div>
</th>
<th scope="row" onClick={() => {
        const newSort = sort === 'asc_user_id' ? 'desc_user_id' : 'asc_user_id';
        setSort(newSort);
      }}>
  <div className='d-flex'>
    <div>User ID</div>
    {sort === "desc_user_id" ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === "asc_user_id" ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : <div> </div>}
  </div>
</th>

<th scope="row" onClick={() => {
        const newSort = sort === 'asc_org_id' ? 'desc_org_id' : 'asc_org_id';
        setSort(newSort);
      }}>
  <div className='d-flex'>
    <div>Org ID</div>
    {sort === "desc_org_id" ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === "asc_org_id" ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : <div> </div>}
  </div>
</th>
 */}





              
                </tr>
            </thead>
       
            <tbody>
           
              {statistics_data?.map((ele,i)=>(
                <tr>
         <td>{ele.id}</td>
  <td>{ele.created_at}</td>
  <td>{ele.points}</td>
  <td>  {ele.from_user_id ? (
       <div className=" pb-3 d-flex">
       {ele.from_user_details.hasOwnProperty("profile") && ele.from_user_details.profile != null
        ? (
         <div className="pe-2">
           <img
             src={ele.from_user_details.profile}
             alt="16.a1d4f3f3"
             className="rounded-float-start"
             height="34"
             width="34"
             style={{ borderRadius: "50px" }}
           ></img>
         </div>
       ) : (
         <div className="pe-2">
           <img
             src={require("../../assets/icon/avatar.png")}
             className="rounded-float-start"
             height="34"
             width="34"
           />
         </div>
       )}
       <div className="">
         <p className="text-dark fw-bold fs-7">
           {ele.from_user_details.name}
         </p>
         <p>{ele.from_user_details.username}</p>

         
       </div>
     </div>
  ) : ele.from_brand_id != null ? ( 
    // If no user_id and brand logo exists, show logo and brand name
    <div className="pe-2">
   {   ele.from_brand_logo !=null &&
    <img
      src={ele.from_brand_logo}
      alt="16.a1d4f3f3"
      className="rounded-float-start"
      height="34"
      width="34"
      style={{ borderRadius: "50px" }}
    ></img>
   }
     <div className="">
            <p className="text-dark fw-bold fs-7">
              {ele.from_brand_name}
            </p>
   
  </div>
  </div>
  ) : ele.from_admin && <div>Admin</div> }</td>
  <td>  {ele.to_user_id != null? (
          <div className=" pb-3 d-flex">
          {ele.to_user_details.hasOwnProperty("profile") && ele.to_user_details.profile != null
           ? (
            <div className="pe-2">
              <img
                src={ele.to_user_details.profile}
                alt="16.a1d4f3f3"
                className="rounded-float-start"
                height="34"
                width="34"
                style={{ borderRadius: "50px" }}
              ></img>
            </div>
          ) : (
            <div className="pe-2">
              <img
                src={require("../../assets/icon/avatar.png")}
                className="rounded-float-start"
                height="34"
                width="34"
              />
            </div>
          )}
          <div className="">
            <p className="text-dark fw-bold fs-7">
              {ele.to_user_details.name}
            </p>
            <p>{ele.to_user_details.username}</p>

            
          </div>
        </div>
  ) :ele.to_brand_id != null ? ( 
    // If no user_id and brand logo exists, show logo and brand name
    <div className="pe-2">
   {   ele.to_brand_logo !=null &&
    <img
      src={ele.to_brand_logo}
      alt="16.a1d4f3f3"
      className="rounded-float-start"
      height="34"
      width="34"
      style={{ borderRadius: "50px" }}
    ></img>
   }
     <div className="">
            <p className="text-dark fw-bold fs-7">
              {ele.to_brand_name}
            </p>
   
  </div>
  </div>
  ) : null }</td>
  
  <td>{ele.notes ? ele.notes : ''}</td>
  <td>{ele.wallet_updated ? 'Yes' : 'No'}</td>
  

  <td>  <div className=" pb-3 d-flex">
          {ele.added_user_details.hasOwnProperty("profile") && ele.added_user_details.profile != null
           ? (
            <div className="pe-2">
              <img
                src={ele.added_user_details.profile}
                alt="16.a1d4f3f3"
                className="rounded-float-start"
                height="34"
                width="34"
                style={{ borderRadius: "50px" }}
              ></img>
            </div>
          ) : (
            <div className="pe-2">
              <img
                src={require("../../assets/icon/avatar.png")}
                className="rounded-float-start"
                height="34"
                width="34"
              />
            </div>
          )}
          <div className="">
            <p className="text-dark fw-bold fs-7">
              {ele.added_user_details.name}
            </p>
            <p>{ele.added_user_details.username}</p>

            
          </div>
        </div></td>




         {/* <td>{ele.brand_logo && <img src={ele.brand_logo} alt="Brand Logo" />} {ele.brand_name}</td>
         <td>{ele.outlet_name}</td>
  <td>{ele.type}</td>

  <td>{ele.purpose}</td>


  <td>{ele.type_id}</td>
  <td>{ele.brand_id}</td>
  <td>{ele.outlet_id}</td>
  <td>{ele.user_id ? ele.user_id : 'N/A'}</td>
  <td>{ele.org_id ? ele.org_id : 'N/A'}</td>
 
  <td>{ele.added_user_details.name}</td> */}
 
                </tr>
                  ))}
            </tbody>
     
        </table>
        
        </div>
        </InfiniteScroll>

        </div>

</div>
  )
}

export default SellerTransaction