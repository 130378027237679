
import React, { useState, useEffect, useContext } from "react";

import {
    Ionicons,
    Entypo,
    MaterialIcons,
    MaterialCommunityIcons,
  } from "@expo/vector-icons";
function DeliveryPartnerSubComponent({ data,from}) {

    // const[from,SetFrom] =  useState("");
    // const[data,SetData] =  useState([]);

    useEffect(()=>{

//         const { data, from } = props;
// console.log(from)
console.log(data)
console.log(from)


// SetFrom(props.from)
// SetData(props)

    },[data,from])

    const OwnAccordian = (acc_props: any) => {
        const [is_open, SetIsopen] = useState(false);
        return (
          <>
          { acc_props.from == "shipment"?
          <>
       
            <div
              className="d-flex pt-3"
              onClick={() => {
                SetIsopen(!is_open);
              }}
            >
          
              <h6 className="text-dark ">{acc_props.heading}</h6>
       
                {is_open ? (
                  <MaterialIcons
                    name="keyboard-arrow-down"
                    size={24}
                    color="black"
                  />
                ) : (
                  <MaterialIcons name="keyboard-arrow-up" size={24} color="black" />
                )}
        
          
            </div>
            <div className="">{is_open ? acc_props.children : null}</div>
          </>
    
          :
          <>
       
          <div
            className="d-flex pt-3"
            onClick={() => {
              SetIsopen(!is_open);
            }}
          >
            <h6 className="text-dark ">{acc_props.heading}</h6>
            <div className="ms-auto">
              {is_open ? (
                <MaterialIcons
                  name="keyboard-arrow-down"
                  size={24}
                  color="black"
                />
              ) : (
                <MaterialIcons name="keyboard-arrow-up" size={24} color="black" />
              )}
            </div>
          </div>
          <div className="">{is_open ? acc_props.children : null}</div>
        </>} </>
        );
      };

      function secondsToMinutes(seconds:any) {
        if (isNaN(seconds) || seconds < 0) {
          return "Invalid input";
        }
      
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
      
        return `${minutes} mins & ${remainingSeconds} sec`;
      }
  return (
    <div className="col-md-6 md-pe-3 sm-px-1">
<div className="card pt-3 px-3 pb-1 mb-3">
    {/* {console.log(props.from)} */}
  <h6 className="text-dark fw-bold">{from}</h6>
  <div className="row pt-3">
    <div className="col-6 ">
      <p className="text-gray h-30">Company Name</p>
      <p className="pt-1 text-dark fw-bold">{data?.company_name}</p>
    </div>

    <div className="col-6 ">
      <p className="text-gray h-30">Executive Name</p>
      <p className="pt-1 text-dark fw-bold">  
      <div className="d-flex pt-2">
                
                <img
                  src={data?.delivery_partner?.profile}
                  className="type-img"
                />
         
                  <div className="ps-2">
                    <p className=" pt-1 text-dark fw-bold">
                       {data?.delivery_partner?.name}</p>
                    <p className="extra-small">   
                    @{data?.delivery_partner?.username}
             
                    </p>
                </div>
        
             
              </div>
      </p>
    </div>
  </div>
  <OwnAccordian heading={"Pickup Details"}>
    <div className="row">
      <div className="col-4 ">
        <p className="text-gray h-30">Distance</p>
        <p className="pt-1 text-dark fw-bold">{data?.pickup?.distance} km{from=="Actual"&&" (approx)"}</p>
      </div>
      <div className="col-4 ">
        <p className="text-gray h-30">Distance Payable</p>
        <p className="pt-1 text-dark fw-bold"> {data?.pickup?.distance_payable} km{from=="Actual"&&" (approx)"}</p>
      </div>

      <div className="col-4 ">
        <p className="text-gray h-30">Rate</p>
        <p className="pt-1 text-dark fw-bold"> ₹ {data?.pickup?.rate}/km</p>
      </div>
      <div className="col-4 ">
        <p className="text-gray h-30">Charge</p>
        <p className="pt-1 text-dark fw-bold"> ₹ {data?.pickup?.charge}</p>
      </div>

      <div className="col-4 ">
        <p className="text-gray h-30">Start Time</p>
        <p className="pt-1 text-dark fw-bold">{data?.pickup?.start_time}</p>
      </div>
      <div className="col-4 ">
        <p className="text-gray h-30">End Time</p>
        <p className="pt-1 text-dark fw-bold">{data?.pickup?.end_time}</p>
      </div>

      <div className="col-4 ">
        <p className="text-gray h-30">Duration</p>
        <p className="pt-1 text-dark fw-bold">{data?.pickup?.duration> 60 ? secondsToMinutes(data?.pickup?.duration):data?.pickup?.duration}</p>
      </div>
    </div>
  </OwnAccordian>
  <OwnAccordian heading={"Delivery Details"}>
    <div className="row">
      <div className="col-4 ">
        <p className="text-gray h-30">Distance</p>
        <p className="pt-1 text-dark fw-bold">{data?.delivery?.distance} km{from=="Actual"&&" (approx)"}</p>
      </div>
      <div className="col-4 ">
        <p className="text-gray h-30">Distance Payable</p>
        <p className="pt-1 text-dark fw-bold">{data?.delivery?.distance_payable} km</p>
      </div>

      <div className="col-4 ">
        <p className="text-gray h-30">Rate</p>
        <p className="pt-1 text-dark fw-bold"> ₹ {data?.delivery?.rate}/km</p>
      </div>
      <div className="col-4 ">
        <p className="text-gray h-30">Charge</p>
        <p className="pt-1 text-dark fw-bold"> ₹ {data?.delivery?.charge}</p>
      </div>

      <div className="col-4 ">
        <p className="text-gray h-30">Start Time</p>
        <p className="pt-1 text-dark fw-bold">{data?.delivery?.start_time}</p>
      </div>
      <div className="col-4 ">
        <p className="text-gray h-30">End Time</p>
        <p className="pt-1 text-dark fw-bold">{data?.delivery?.end_time}</p>
      </div>

      <div className="col-4 ">
        <p className="text-gray h-30">Duration</p>
        <p className="pt-1 text-dark fw-bold">{data?.delivery?.duration> 60 ? secondsToMinutes(data?.pickup?.duration):data?.pickup?.duration}</p>
      </div>
    </div>
  </OwnAccordian>

  <OwnAccordian heading={"Return Details"}>
    <div className="row">
      <div className="col-4 ">
        <p className="text-gray h-30">Distance</p>
        <p className="pt-1 text-dark fw-bold">N/A</p>
      </div>
      <div className="col-4 ">
        <p className="text-gray h-30">Distance Payable</p>
        <p className="pt-1 text-dark fw-bold">N/A</p>
      </div>

      <div className="col-4 ">
        <p className="text-gray h-30">Rate</p>
        <p className="pt-1 text-dark fw-bold">N/A</p>
      </div>
      <div className="col-4 ">
        <p className="text-gray h-30">Charge</p>
        <p className="pt-1 text-dark fw-bold">N/A</p>
      </div>

      <div className="col-4 ">
        <p className="text-gray h-30">Start Time</p>
        <p className="pt-1 text-dark fw-bold">N/A</p>
      </div>
      <div className="col-4 ">
        <p className="text-gray h-30">End Time</p>
        <p className="pt-1 text-dark fw-bold">N/A</p>
      </div>

      <div className="col-4 ">
        <p className="text-gray h-30">Duration</p>
        <p className="pt-1 text-dark fw-bold">N/A</p>
      </div>
    </div>
  </OwnAccordian>
</div>
</div>
  )
}

export default DeliveryPartnerSubComponent