import React, { useState, useEffect } from "react";
import bootstrap from "../../assets/libraries/bootstrap/js/bootstrap";

import { api } from "../../utils/Api";

export default function ModalAcceptRequest(props: any) {
  const [close_data, SetCloseData] = useState({
    action: "close",
  });
  let myModal: any;

  useEffect(() => {
    let myModal1 = document.getElementById("reqAcModal");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      myModal = new bootstrap.Modal(document.getElementById("reqAcModal"), {});
      myModal.show();
    }
  }, [props.open]);

  async function deleteUserRole() {
    let pass_data = {
      post: {
        id: props.data.id,
        status: "1",
      },
    };
     let data_res;
   
   if (props.data.from === "details") {
     data_res = await api("/products/product_group_locale_update_request_status", pass_data);
   } else {
     data_res = await api("/products/product_update_request_status", pass_data);
   }
    if (data_res.status_code == 200) {
      let item = close_data;
      item.action = "save";
      item.value = data_res.response.data;
      SetCloseData(item);

      myModal.hide();
    }
  }

  return (
    <div
      className="modal fade"
      id="reqAcModal"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
      // style={{marginTop:"17%"}}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="  modal-title" id="ModalLabel">
              Accept Changes
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">Are you sure want to accept changes?</div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              name="id"
              value={props.id}
              onClick={(e) => {
                deleteUserRole(e.target.name, e.target.value);
              }}
            >
              Accept
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
