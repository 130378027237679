import React, { useState, useEffect } from "react";
import bootstrap from "../../assets/libraries/bootstrap/js/bootstrap";
import { api } from "../../utils/Api";
let myModal : any;
export default function ModalAllow(props : any) {
  const [close_data, SetCloseData] = useState({
    action: "close",
    value:"",
  });

  const [allow,setAllow] = useState("")

  

  useEffect(() => {
    console.log("ModelDeleteUserRoles On mount :", props);
    let myModal1 = document.getElementById("codAllow");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      console.log("event :", event);
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      console.log("ModelDeleteUserRoles On props.open :", props.data.value);
      if(props.data.value ==true){
        setAllow("on")
      }else{
        setAllow("off")

      }
      myModal = new bootstrap.Modal(
        document.getElementById("codAllow"),
        {}
      );
      myModal.show();
    }
  }, [props.open]);



  function StatusOnChange(val){
    setAllow(val)
  }
  async function Save() {

    let pass_data = {
    
      post: {
        field: "cod_allowed_all_users",
        value: allow,
    
      }
    };
    console.log(pass_data)
    let data_res = await api("/settings/save_general", pass_data);
    console.log("add_brand_user response :", data_res.response.data.default_checkout_payment_type);
    if (data_res.status_code == 200) {
    let item = close_data;
    console.log(item)
    item.action = "save";
    item.value =  data_res.response.data.cod_allowed_all_users;
    SetCloseData(item);
  
    myModal.hide();
    }
  }

  return (
    <div
      className="modal fade"
      id="codAllow"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="  modal-title" id="ModalLabel">
            Allow Cod for all users
            </h6>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
          <div className="form-check form-check-inline">
  <input className="form-check-input" type="radio"
   name="inlineRadioOptions4" id="inlineRadio4" value="option1"
    checked={allow=="on"} onClick={()=>{StatusOnChange("on")}}/>
  <label className="form-check-label" for="inlineRadio4">Allowed</label>
</div>
<div className="form-check form-check-inline">
  <input className="form-check-input" type="radio"
   name="inlineRadioOptions5" id="inlineRadio5" value="option2"
    checked={allow=="off"} onClick={()=>{StatusOnChange("off")}}/>
  <label className="form-check-label" for="inlineRadio5">Not Allowed</label>
</div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-success"
            
              onClick={
                // myModal.hide();
                Save}
                  // data-bs-dismiss="modal"
            >
              Save
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
