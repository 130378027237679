import React, { useEffect, useState } from "react";
import bootstrap from "../../assets/libraries/bootstrap/js/bootstrap";
let myModal = {};

export default function ModalChannelAvailable(props) {
  const [selectedDuration, setSelectedDuration]:any = useState({});
  const [custom_date_show, SetShowCustomDate] = useState(2);
  const [customDateVisible, setCustomDateVisible] = useState(false);
  const [selectedDateTime, setselectedDateTime] = useState("");
  const [data, setData] = useState([]);
  const [label, setLabel] = useState("");
  const [unaval, setUnAval] = useState("");

  const [close_data, SetCloseData] = useState({
    action: "close",
  });
  useEffect(() => {
    console.log("ModalUserNew On mount :", props);
    let myModal2 = document.getElementById("ModalUserNew");
    myModal2.addEventListener("hidden.bs.modal", function (event) {
      console.log("event :", event);
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      setUnAval(props.next_slot);
      console.log("ModalUserNew On mount :", props);
      myModal = new bootstrap.Modal(
        document.getElementById("ModalUserNew"),
        {}
      );
      myModal.show();
    }
  }, [props.open]);

  function add_user() {
    if(selectedDuration?.value){
      let item = close_data;
      item.action = "save";
      item.value = selectedDuration;
      item.data = props.data;
      item.type = props.type;
  
      item.label = label;
  
      console.log("Close data : ", item);
      // props.close(item); // Passes the item which includes the action "save" to the close function
      SetCloseData(item);
      setSelectedDuration({});
      setCustomDateVisible(false);
      setselectedDateTime("");
      setLabel("");
      setUnAval("");
      myModal.hide();
      console.log(selectedDuration);
      // Add user logic
    }
  }

  function custom_date_func(prop, e) {
    setCustomDateVisible(prop == 1);
    setLabel(e.target.value);
  }

  const handleDateTimeChange = (e) => {
    setselectedDateTime(e.target.value);
    setSelectedDuration({
      value: 5,
      time: e.target.value,
    });
  };

  return (
    <div
      className="modal fade"
      id="ModalUserNew"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-fullscreen">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title" id="ModalLabel">
              Auto turn-on after
            </h6>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => props.close({ action: "close" })}
            ></button>
          </div>
          <div className="modal-body out_of_stock_swipe">
            {/* Your modal content goes here */}
            <div className="p-3">
              <div className="mb-3 row">
                <label
                  htmlFor="2hours"
                  className="form-label d-flex align-items-center mb-3 cursor out_of_stock_swipe_label"
                >
                  <input
                    type="radio"
                    className="form-check-input me-2"
                    id="2hours"
                    name="duration"
                    value="2 hours"
                    checked={selectedDuration?.hours === "2"}
                    onChange={(e) => {
                      setSelectedDuration({
                        value: 3,
                        hours: "2",
                      });
                      custom_date_func(2, e);
                    }}
                  />
                  <p className="capitalize">{console.log(unaval)}2 hours</p>
                </label>
                <label
                  htmlFor="4hours"
                  className="form-label d-flex align-items-center mb-3 cursor out_of_stock_swipe_label"
                >
                  <input
                    type="radio"
                    className="form-check-input me-2"
                    id="4hours"
                    name="duration"
                    value="4 hours"
                    checked={selectedDuration?.hours === "4"}
                    onChange={(e) => {
                      setSelectedDuration({
                        value: 3,
                        hours: "4",
                      });
                      custom_date_func(2, e);
                    }}
                  />
                  <p className="capitalize">4 Hours</p>
                </label>
                <label className="form-label d-flex align-items-center mb-3 cursor out_of_stock_swipe_label">
                  <input
                    type="radio"
                    className="form-check-input me-2"
                    id="1day"
                    name="duration"
                    value="1 day"
                    checked={selectedDuration?.days === "1"}
                    onChange={(e) => {
                      setSelectedDuration({
                        value: 4,
                        days: "1",
                      });
                      custom_date_func(2, e);
                    }}
                  />
                  <p className="capitalize">1 Day</p>
                </label>

                <label
                  htmlFor="nextday"
                  className={!unaval.hasOwnProperty("next_slot") ? "form-label d-flex align-items-center mb-3 cursor border-light-gray" : "form-label d-flex align-items-center mb-3 cursor out_of_stock_swipe_label"}
                >
                  <input
                    type="radio"
                    className="form-check-input me-2"
                    id="nextday"
                    name="duration"
                    disabled={!unaval.hasOwnProperty("next_slot")}
                    value="Tomorrow before 1st slot"
                    checked={selectedDuration.value == "6"}
                    onChange={(e) => {
                      setSelectedDuration({
                        value: 6,
                      });
                      custom_date_func(2, e);
                    }}
                  />
                  <p className={!unaval.hasOwnProperty("next_slot") ?  "capitalize text-light-gray" : "capitalize"}>Next available time slot
                    {unaval.hasOwnProperty("next_slot") && (
                      <div className="capitalize">{unaval.next_slot}</div>
                    )}
                  </p>
                </label>

                <label
                  htmlFor="Customday"
                  className="form-label d-flex align-items-center mb-3 cursor out_of_stock_swipe_label"
                >
                  <input
                    type="radio"
                    className="form-check-input me-2"
                    id="Customday"
                    name="duration"
                    value={selectedDateTime}
                    checked={customDateVisible}
                    onChange={(e) => custom_date_func(1, e)}
                  />
                  <p className="capitalize">Custom date & time</p>
                </label>
                {customDateVisible && (
                  <div className="px-0 w-50">
                    <input
                      type="datetime-local"
                      className="me-2 form-control"
                      value={selectedDateTime}
                      onChange={handleDateTimeChange}
                    />
                  </div>
                )}
                <div className="py-2 px-0">
                  <div className="d-flex pb-2">
                    <p className="text-dark fw-bold fs-7">Manualy turn on</p>
                  </div>
                  <label
                    htmlFor="willturn"
                    className="form-label d-flex align-items-center mb-3 cursor out_of_stock_swipe_label"
                  >
                    <input
                      type="radio"
                      className="form-check-input me-2"
                      id="willturn"
                      name="duration"
                      value="I will turn on my self"
                      checked={selectedDuration.value == "2"}
                      onChange={(e) => {
                        setSelectedDuration({
                          value: 2,
                        });
                        custom_date_func(2, e);
                      }}
                    />
                    <p className="capitalize">I will turn on my self</p>
                  </label>
                </div>
                <div className="py-2 px-0">
                  <p className="v-small text-darkgray">
                    <span className="text-dark pe-1 fw-bold">Note:</span>
                    This item will not be visible to customers on the 1SuperApp
                    till your switch it on
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-white "
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={add_user}
            >
              Done
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
