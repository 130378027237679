import React, { useState, useEffect, useRef } from "react";
import bootstrap from "../../assets/libraries/bootstrap/js/bootstrap";
import "react-loading-skeleton/dist/skeleton.css";

let myModal: any;
export default function EligibleLogsModal(props: any) {
  const [close_data, SetCloseData] = useState({
    action: "close",
    value: "",
    index: "",
    status: false
  });

  useEffect(() => {
    let myModal1: any = document.getElementById("EligibleLogsModal");
    myModal1.addEventListener("hidden.bs.modal", function (event: any) {
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      myModal = new bootstrap.Modal(
        document.getElementById("EligibleLogsModal"),
        {}
      );
      myModal.show();
    }
  }, [props.open]);


  return (
    <div
      className="modal fade"
      id="EligibleLogsModal"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog  modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title" id="ModalLabel">Current Eligible Status</h6>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body" id="EligibleLogsModalBody">
            <div className="card mb-5 p-2">
              <div className="d-flex mb-2">
                <div>
                  <div className="">{props?.data?.order?.seller_settlement?.settlement_eligible ? 'Eligible' : 'Not Eligible'}</div>
                  {props?.data?.order?.seller_settlement?.settlement_eligible_amount && (
                    <div className="">&#8377; {props?.data?.order?.seller_settlement?.settlement_eligible_amount}</div>
                  )}
                </div>
                <div className="ms-auto">
                  {props?.data?.order?.seller_settlement?.settlement_eligible_time && (
                    <div className="">{props?.data?.order?.seller_settlement?.settlement_eligible_time}</div>
                  )}
                </div>
              </div>
              {props?.data?.order?.seller_settlement?.settlement_eligible_user?.id && (
                <>
                  <div>Updated User</div>
                  <div className="text-muted d-flex mb-1">
                    {props?.data?.order?.seller_settlement?.settlement_eligible_user?.profile && (
                      <div><img className="img-fluid" src={props?.data?.order?.seller_settlement?.settlement_eligible_user?.profile} width="30" /></div>
                    )}
                    <div>
                      <div>{props?.data?.order?.seller_settlement?.settlement_eligible_user?.name} #{props?.data?.order?.seller_settlement?.settlement_eligible_user?.id}</div>
                      <div>{props?.data?.order?.seller_settlement?.settlement_eligible_user?.username}</div>
                    </div>
                  </div>
                </>
              )}
              {props?.data?.order?.seller_settlement?.settlement_eligible_notes && (
                <>
                  <div>Notes</div>
                  <div className="text-muted">{props?.data?.order?.seller_settlement?.settlement_eligible_notes}</div>
                </>
              )}
            </div>
            <div className="mb-3">
              <h6 className="text-uppercase">Logs</h6>
              {props?.data?.order?.settlement_eligible_logs?.length > 0 && (
                props?.data?.order?.settlement_eligible_logs.map((log:any, i:any) => (
                  <div className="card mb-3 p-2" key={i}>
                    <div className="d-flex mb-2">
                      <div>
                        <div className="">{log?.settlement_eligible ? 'Eligible' : 'Not Eligible'}</div>
                        {log?.settlement_eligible_amount && (
                          <div className="">&#8377; {log?.settlement_eligible_amount}</div>
                        )}
                      </div>
                      <div className="ms-auto">
                        {log?.time && (
                          <div className="">{log?.time}</div>
                        )}
                      </div>
                    </div>
                    {log?.user?.id && (
                      <>
                        <div>Updated User</div>
                        <div className="text-muted d-flex mb-1">
                          {log?.user?.profile && (
                            <div><img className="img-fluid" src={log?.user?.profile} width="30" /></div>
                          )}
                          <div>
                            <div>{log?.user?.name} #{log?.user?.id}</div>
                            <div>{log?.user?.username}</div>
                          </div>
                        </div>
                      </>
                    )}
                    {log?.settlement_eligible_notes && (
                      <>
                        <div>Notes</div>
                        <div className="text-muted">{log?.settlement_eligible_notes}</div>
                      </>
                    )}
                  </div>
                ))
              )}
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  );
}
