const imageSrc = (base_url: any, img_url: any, data = {}) => {
  let pass_string = base_url + img_url;
  if (Object.keys(data).length > 0) {
    Object.entries(data).forEach(([key, value], index) => {
      if (index == 0) {
        let join_data;
        join_data = "?" + key + "=" + value;
        pass_string = pass_string + join_data;
      } else {
        let join_data = "&" + key + "=" + value;
        pass_string = pass_string + join_data;
      }
    });
  }
  return pass_string;
};

const randomStr:any = (length:number) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

const base64toBlob = (b64Data: any, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(
    b64Data.replace(/^data:image\/(png|jpeg|jpg);base64,/, "")
  );
  // const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

const ConvertRupee = (get_data : any) => {
  let rupee = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
  });
  return rupee.format(get_data)
}



export const AppHelper = {
  imageSrc,
  randomStr,
  base64toBlob,
  ConvertRupee
};

