"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _calculateDirection = require("./calculateDirection");

Object.keys(_calculateDirection).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _calculateDirection[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _calculateDirection[key];
    }
  });
});

var _calculateDirectionDelta = require("./calculateDirectionDelta");

Object.keys(_calculateDirectionDelta).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _calculateDirectionDelta[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _calculateDirectionDelta[key];
    }
  });
});

var _calculateDuration = require("./calculateDuration");

Object.keys(_calculateDuration).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _calculateDuration[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _calculateDuration[key];
    }
  });
});

var _calculateMovingPosition = require("./calculateMovingPosition");

Object.keys(_calculateMovingPosition).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _calculateMovingPosition[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _calculateMovingPosition[key];
    }
  });
});

var _calculatePosition = require("./calculatePosition");

Object.keys(_calculatePosition).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _calculatePosition[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _calculatePosition[key];
    }
  });
});

var _calculateTraceDirections = require("./calculateTraceDirections");

Object.keys(_calculateTraceDirections).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _calculateTraceDirections[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _calculateTraceDirections[key];
    }
  });
});

var _calculateVelocity = require("./calculateVelocity");

Object.keys(_calculateVelocity).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _calculateVelocity[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _calculateVelocity[key];
    }
  });
});

var _checkIsMoreThanSingleTouches = require("./checkIsMoreThanSingleTouches");

Object.keys(_checkIsMoreThanSingleTouches).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _checkIsMoreThanSingleTouches[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _checkIsMoreThanSingleTouches[key];
    }
  });
});

var _checkIsPassiveSupported = require("./checkIsPassiveSupported");

Object.keys(_checkIsPassiveSupported).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _checkIsPassiveSupported[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _checkIsPassiveSupported[key];
    }
  });
});

var _checkIsTouchEventsSupported = require("./checkIsTouchEventsSupported");

Object.keys(_checkIsTouchEventsSupported).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _checkIsTouchEventsSupported[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _checkIsTouchEventsSupported[key];
    }
  });
});

var _common = require("./common");

Object.keys(_common).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _common[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _common[key];
    }
  });
});

var _createOptions = require("./createOptions");

Object.keys(_createOptions).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _createOptions[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _createOptions[key];
    }
  });
});

var _getInitialState = require("./getInitialState");

Object.keys(_getInitialState).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _getInitialState[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _getInitialState[key];
    }
  });
});

var _getInitialProps = require("./getInitialProps");

Object.keys(_getInitialProps).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _getInitialProps[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _getInitialProps[key];
    }
  });
});

var _getOptions = require("./getOptions");

Object.keys(_getOptions).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _getOptions[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _getOptions[key];
    }
  });
});

var _resolveDirection = require("./resolveDirection");

Object.keys(_resolveDirection).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _resolveDirection[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _resolveDirection[key];
    }
  });
});

var _rotateByAngle = require("./rotateByAngle");

Object.keys(_rotateByAngle).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _rotateByAngle[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _rotateByAngle[key];
    }
  });
});

var _updateTrace = require("./updateTrace");

Object.keys(_updateTrace).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _updateTrace[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _updateTrace[key];
    }
  });
});