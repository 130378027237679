import React, { useState, useEffect } from "react";
import bootstrap from "../../assets/libraries/bootstrap/js/bootstrap";
import { api } from "../../utils/Api";
let myModal : any;
export default function ModalDeleteUserRole(props : any) {
  const [close_data, SetCloseData] = useState({
    action: "close",
  });
  const [id,setId] = useState("")
  

  useEffect(() => {
    console.log("ModelDeleteUserRoles On mount :", props);
    let myModal1 = document.getElementById("DelUserRole");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      console.log("event :", event);
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      console.log("ModelDeleteUserRoles On props.open :", props.data.element.id);
setId(props.data.element.id)
      myModal = new bootstrap.Modal(
        document.getElementById("DelUserRole"),
        {}
      );
      myModal.show();
    }
  }, [props.open]);

  async function deleteUserRole() {

    let pass_data = {
    
      post: {
       id: id,
    
      }
    };
    console.log(pass_data)
    let data_res = await api("/sellers/delete_role", pass_data);
    console.log("add_brand_user response :", data_res.response);
    if (data_res.status_code == 200) {
    let item = close_data;
    console.log(item)
    item.action = "delete";
    // item.value = props.data;
    SetCloseData(item);
  
    myModal.hide();
    }
  }

  return (
    <div
      className="modal fade"
      id="DelUserRole"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="  modal-title" id="ModalLabel">
              Delete User Role
            </h6>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            Are you sure want to delete user role?
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger"
            
              onClick={
                // myModal.hide();
                deleteUserRole}
                  // data-bs-dismiss="modal"
            >
              Delete
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
