import React ,{useState,useEffect} from 'react'
import { useOutletContext } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { api } from "../../utils/Api";
import ReactApexChart from 'react-apexcharts';


function OrderStatistics() {

  const [overview_is_loading, SetOverviewIsLoading] = useState(true);
  const [statistics_data, SetStatisticsData]: any = useState([]);
  const OutletContext = useOutletContext();
  const [chartData, setChartData] = useState<any>({
    series: [],
    options: {
      chart: {
        type: 'donut',
      
      },
      labels: [],
      responsive: [
        {
          breakpoint: 80,
          options: {
            chart: {
              width: 200,
           
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
  });
  const [barChartData, setBarChartData] = useState<any>({
    series: [{
      name: 'Total Orders',
      data: []
    }],
    options: {
      chart: {
        type: 'bar',
        height: 400,
      },
     
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '45%',
        }
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        title: {
          text: 'Number of Orders'
        }
      },
      dataLabels: {
        enabled: false
      },
      colors: ['#00E396'], // Customize the color of bars
      title: {
        text: 'Total Orders by Range',
        align: 'center'
      }
    }
  });

  const now = new Date();

  // const startOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
  // const endOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
  // const [startDateShow, setStartDateShow] = useState(getFormattedDateTime(startOfToday));
  // const [endDateShow, setEndDateShow] = useState(getFormattedDateTime(endOfToday));
  // const [startDate, setStartDate] = useState(convertDateTime(getFormattedDateTime(startOfToday)));
  // const [endDate, setEndDate] = useState(convertDateTime(getFormattedDateTime(endOfToday)));
    const [startDateShow, setStartDateShow] = useState("");
  const [endDateShow, setEndDateShow] = useState("");

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [width, SetWidth] = useState(innerWidth);

  useEffect(() => {
   
    let send_data = {
      startDate:startDate,
      endDate:endDate,
    };
    get_overview(send_data);

    // get_outlets_and_channels();
  }, []);


  
  function getFormattedDateTime(date) {
    
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-11
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }
  function convertDateTime(dateTimeStr) {
    const date = new Date(dateTimeStr);
    
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-11
    const year = date.getFullYear();
    
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = '00'; // Set seconds to 00
    
    const formattedDateTime = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    return formattedDateTime;
  }

  const handleStartChange = (e) => {
    //  let start:any = moment(date).format('DD-MM-YYYY HH:mm:ss')
    console.log(e.target.value)
    const convertedDateTime = convertDateTime(e.target.value);
      console.log(convertedDateTime);
      setStartDateShow(e.target.value)
     setStartDate(convertedDateTime);
    //  getSuperappStatistics(start,endDate)
    //  get_order_statistics(start,endDate)
    //  get_order_price_statistics(start,endDate)
    
    };
  
    const handleEndChange = (e) => {
      const convertedDateTime = convertDateTime(e.target.value);
      setEndDateShow(e.target.value)
    
    
        setEndDate(convertedDateTime);
        
      // getSuperappStatistics(startDate,end)
      // get_order_statistics(startDate,end)
      // get_order_price_statistics(startDate,end)
   
     
     
     };

     const handleApply = () => {

      const startTimestamp = new Date(startDateShow).getTime();
      const endTimestamp = new Date(endDateShow).getTime();
  
      console.log(startTimestamp)
      console.log(endTimestamp)
  
      
      console.log("hi")
      if (startTimestamp < endTimestamp) {
      //     getSuperappStatistics(startDate,endDate)
      // get_order_statistics(startDate,endDate,activeOrTab)
      // get_order_price_statistics(startDate,endDate,activeTab)
          // Add your logic for applying the selected dates

          let send_data = {
            page_no: 1,
            have_more: false,
            startDate:startDate,
            endDate:endDate,
          };
        
          get_overview(send_data);
      } else {
        toast.error("End date cannot be earlier than start date.");
      }
  };


  async function get_overview(get_data: any) {

    SetOverviewIsLoading(true)
     let pass_data = {
       get: {
         filter_from:get_data.startDate,
         filter_to: get_data.endDate,
       },
     };
     let response: any = await api("/marketing/statistics_total_order", pass_data);
     console.log("/qr/overview response :", response.response);
     if (response.status_code == 200) {
       if (response.response.hasOwnProperty("statistics")) {
         let statistics_data = response.response.statistics;
 
         console.log(statistics_data);
         SetStatisticsData(statistics_data);

         const series = [
          statistics_data?.percentage_total_orders_0_50,
          statistics_data?.percentage_total_orders_51_100,
          statistics_data?.percentage_total_orders_101_150,
          statistics_data?.percentage_total_orders_151_200,
          statistics_data?.percentage_total_orders_201_250,
          statistics_data?.percentage_total_orders_251_300,
          statistics_data?.percentage_total_orders_301_350,
          statistics_data?.percentage_total_orders_351_400,
          statistics_data?.percentage_total_orders_401_450,
          statistics_data?.percentage_total_orders_451_500,
          statistics_data?.percentage_total_orders_501_600,
          statistics_data?.percentage_total_orders_601_700,
          statistics_data?.percentage_total_orders_701_800,
          statistics_data?.percentage_total_orders_801_900,
          statistics_data?.percentage_total_orders_901_1000,
          statistics_data?.percentage_total_orders_1001_1500,
          statistics_data?.percentage_total_orders_1501_2000,
          statistics_data?.percentage_total_orders_2001_5000,
          statistics_data?.percentage_total_orders_5000_above,
        ].filter(Boolean); // Filter out any undefined or null values
  
        const labels = [
          '0-50',
          '51-100',
          '101-150',
          '151-200',
          '201-250',
          '251-300',
          '301-350',
          '351-400',
          '401-450',
          '451-500',
          '501-600',
          '601-700',
          '701-800',
          '801-900',
          '901-1000',
          '1001-1500',
          '1501-2000',
          '2001-5000',
          '5000+',
        ];

        
  
        setChartData({
          series,
          options: {
            ...chartData.options,
            labels,
          },
        });


        const order_series = [
          statistics_data?.total_orders_0_50,
          statistics_data?.total_orders_51_100,
          statistics_data?.total_orders_101_150,
          statistics_data?.total_orders_151_200,
          statistics_data?.total_orders_201_250,
          statistics_data?.total_orders_251_300,
          statistics_data?.total_orders_301_350,
          statistics_data?.total_orders_351_400,
          statistics_data?.total_orders_401_450,
          statistics_data?.total_orders_451_500,
          statistics_data?.total_orders_501_600,
          statistics_data?.total_orders_601_700,
          statistics_data?.total_orders_701_800,
          statistics_data?.total_orders_801_900,
          statistics_data?.total_orders_901_1000,
          statistics_data?.total_orders_1001_1500,
          statistics_data?.total_orders_1501_2000,
          statistics_data?.total_orders_2001_5000,
          statistics_data?.total_orders_5000_above,
        ].filter(Boolean); // Filter out null/undefined values
      
        const order_labels = [
          '0-50',
          '51-100',
          '101-150',
          '151-200',
          '201-250',
          '251-300',
          '301-350',
          '351-400',
          '401-450',
          '451-500',
          '501-600',
          '601-700',
          '701-800',
          '801-900',
          '901-1000',
          '1001-1500',
          '1501-2000',
          '2001-5000',
          '5000+',
        ];
        setBarChartData((prevState: any) => ({
          ...prevState,
          series: [{
            name: 'Total Orders',
            data: order_series // Filter out null/undefined values
          }],
          options: {
            ...prevState.options,
            xaxis: {
              ...prevState.options.xaxis,
              categories:  order_labels 

            },
          },
        }));
 
  
       }
 
      
 
       SetOverviewIsLoading(false);
     }
   }
 
  return (
    <div>
    <div
    className={OutletContext ? "head-bar sticky-sm py-3" : "head-bar py-3"}
    style={OutletContext == false ? { width: width } : {}}
  >
    <div className="d-flex align-items-center">
      <div className="">
        <h6 className="fs-5  mb-0">Order Statistics</h6>
      </div>

      <div className="ms-lg-auto">  
<div className="d-flex-lg">
  <div className="m-2"> Statistics Filter :</div>
        <div className="me-2">
          {console.log(startDate)}
        <input type="datetime-local"
          onChange={handleStartChange}
        id="startDateTime"
        className="form-control"
        value={startDateShow}
        name="startDateTime" required/>

                {/* <Datetime 
                    id="start"
                    // value={startDate}
                    onChange={handleStartChange}
                    dateFormat={dateFormat}
                    timeFormat={timeFormat}
                    inputProps={{ placeholder: 'Select start date and time' }}
                /> */}
            </div>
          
            <div>
            <input type="datetime-local"
          onChange={handleStartChange}
        id="endDateTime"
        className="form-control"
        value={endDateShow}
        onChange={handleEndChange}
        name="endDateTime" required/>
{/*         
                <Datetime 
                    id="end"
                    value={endDateShow}
                    onChange={handleEndChange}
                    dateFormat={dateFormat}
                    timeFormat={timeFormat}
                    inputProps={{ placeholder: 'Select end date and time' }}
                /> */}
            </div>
            <button className="btn btn-primary btn-sm ms-2 h-10" 
            onClick={handleApply}
            >Apply</button>
            </div>
        </div>
        </div>

    

        </div>

        
<div className="container sm-screen mt-1 mx-1 row">
  <div className='col-lg-5'>
<h6 className='mt-2'>Percentage Total Orders</h6>
{overview_is_loading?
 <div className="row">
    {[1, 2, 3, 4, 5, 6].map(() => (
  <div className="col-lg-3">
  <div className="card p-1 cursor my-1">
    <div>
      <Skeleton height={20} width={100} />
    </div>
    <div>
      <Skeleton height={20} width={150} />
    </div>
  </div>
  </div>
    ))}
</div>:
  <div className="card p-2 cursor">
   <div className="chart-container">
<ReactApexChart options={chartData.options} series={chartData.series} type="donut" className="h-50" />
</div>
</div>

}

</div>
<div className='col-lg-7'>

<h6 className='mt-2'>Total Orders</h6>
{overview_is_loading?
 <div className="row">
    {[1, 2, 3, 4, 5, 6].map(() => (
  <div className="col-lg-3">
  <div className="card p-1 cursor my-1">
    <div>
      <Skeleton height={20} width={100} />
    </div>
    <div>
      <Skeleton height={20} width={150} />
    </div>
  </div>
  </div>
    ))}
</div>:
<div className="card p-2 cursor">
<ReactApexChart options={barChartData.options} series={barChartData.series} type="bar" height={400} />
</div>
}

</div>
<h6 className='mt-2'>Percentage Total Orders</h6>
<div className="row">
  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">0-50</div>
      <div className="text-end fw-bold">{statistics_data?.percentage_total_orders_0_50}%</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">51-100</div>
      <div className="text-end fw-bold">{statistics_data?.percentage_total_orders_51_100}%</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">101-150</div>
      <div className="text-end fw-bold">{statistics_data?.percentage_total_orders_101_150}%</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">151-200</div>
      <div className="text-end fw-bold">{statistics_data?.percentage_total_orders_151_200}%</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">201-250</div>
      <div className="text-end fw-bold">{statistics_data?.percentage_total_orders_201_250}%</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">251-300</div>
      <div className="text-end fw-bold">{statistics_data?.percentage_total_orders_251_300}%</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">301-350</div>
      <div className="text-end fw-bold">{statistics_data?.percentage_total_orders_301_350}%</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">351-400</div>
      <div className="text-end fw-bold">{statistics_data?.percentage_total_orders_351_400}%</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">401-450</div>
      <div className="text-end fw-bold">{statistics_data?.percentage_total_orders_401_450}%</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">451-500</div>
      <div className="text-end fw-bold">{statistics_data?.percentage_total_orders_451_500}%</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">501-600</div>
      <div className="text-end fw-bold">{statistics_data?.percentage_total_orders_501_600}%</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">601-700</div>
      <div className="text-end fw-bold">{statistics_data?.percentage_total_orders_601_700}%</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">701-800</div>
      <div className="text-end fw-bold">{statistics_data?.percentage_total_orders_701_800}%</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">801-900</div>
      <div className="text-end fw-bold">{statistics_data?.percentage_total_orders_801_900}%</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">901-1000</div>
      <div className="text-end fw-bold">{statistics_data?.percentage_total_orders_901_1000}%</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">1001-1500</div>
      <div className="text-end fw-bold">{statistics_data?.percentage_total_orders_1001_1500}%</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">1501-2000</div>
      <div className="text-end fw-bold">{statistics_data?.percentage_total_orders_1501_2000}%</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">2001-5000</div>
      <div className="text-end fw-bold">{statistics_data?.percentage_total_orders_2001_5000}%</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">5000+</div>
      <div className="text-end fw-bold">{statistics_data?.percentage_total_orders_5000_above}%</div>
    </div>
  </div>
</div>

<h6 className='mt-2'>Total Orders</h6>
<div className="row">
  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">0-50</div>
      <div className="text-end fw-bold">{statistics_data?.total_orders_0_50}</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">51-100</div>
      <div className="text-end fw-bold">{statistics_data?.total_orders_51_100}</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">101-150</div>
      <div className="text-end fw-bold">{statistics_data?.total_orders_101_150}</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">151-200</div>
      <div className="text-end fw-bold">{statistics_data?.total_orders_151_200}</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">201-250</div>
      <div className="text-end fw-bold">{statistics_data?.total_orders_201_250}</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">251-300</div>
      <div className="text-end fw-bold">{statistics_data?.total_orders_251_300}</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">301-350</div>
      <div className="text-end fw-bold">{statistics_data?.total_orders_301_350}</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">351-400</div>
      <div className="text-end fw-bold">{statistics_data?.total_orders_351_400}</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">401-450</div>
      <div className="text-end fw-bold">{statistics_data?.total_orders_401_450}</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">451-500</div>
      <div className="text-end fw-bold">{statistics_data?.total_orders_451_500}</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">501-600</div>
      <div className="text-end fw-bold">{statistics_data?.total_orders_501_600}</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">601-700</div>
      <div className="text-end fw-bold">{statistics_data?.total_orders_601_700}</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">701-800</div>
      <div className="text-end fw-bold">{statistics_data?.total_orders_701_800}</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">801-900</div>
      <div className="text-end fw-bold">{statistics_data?.total_orders_801_900}</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">901-1000</div>
      <div className="text-end fw-bold">{statistics_data?.total_orders_901_1000}</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">1001-1500</div>
      <div className="text-end fw-bold">{statistics_data?.total_orders_1001_1500}</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">1501-2000</div>
      <div className="text-end fw-bold">{statistics_data?.total_orders_1501_2000}</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">2001-5000</div>
      <div className="text-end fw-bold">{statistics_data?.total_orders_2001_5000}</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">5000+</div>
      <div className="text-end fw-bold">{statistics_data?.total_orders_5000_above}</div>
    </div>
  </div>
</div>


</div>

</div>
  )
}

export default OrderStatistics