import React, { useState, useEffect, useContext } from "react";
import DataContext from "../../utils/Context/DataContext";
import { api } from "../../utils/Api";
import ModalCod from "./ModalCod";
import ModalAllow from "./ModalAllow";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";


export default function SettingPage(props: any) {
  const context = useContext(DataContext);
  const [setting_loading, SetSettingLoading] = useState(true);

  const [page_no, SetPageNo] = useState(1);
  const [defaultCheckout, SetDefaultCheckout] = useState("");
  const [cod, setCod] = useState(false);
  const [cod_modal, setCodModal] = useState({
    is_open: false,
    data: {},
  });
  const [cod_allow_modal, setCodAllowModal] = useState({
    is_open: false,
    data: {},
  });

  useEffect(() => {

    get_settings();

  }, []);

  async function get_settings() {
    SetSettingLoading(true);


    let response: any = await api("/settings/general");
    if (response.status_code == 200) {
    console.log(response.response.data.default_checkout_payment_type)

        SetDefaultCheckout(response.response.data.default_checkout_payment_type)
        setCod(response.response.data.cod_allowed_all_users)
        SetSettingLoading(false);
  
   
    }
  }


  return (
    <div className="">
      {/* <div > */}
      {/* Header */}
      <div className="head-bar row">
        <div className="col-md-6">
          <h5 className="  mb-2">Settings</h5>
        </div>
      </div>

      {/* body */}
      <div className="px-3 sm-mt-2 sm-pt-4">
        <div className="mt-4">
          <div className="row">
            {/* {order_data?.map((ord_ele: any, ord_index: any) => ( */}
            <div className="col-lg-8 mb-3">
              <div
                className="card p-3 cursor"
        
              >
                {/* header */}
                <div className="d-flex mb-2"     onClick={(e) => {
e.stopPropagation()


                              let set_data = {
                                value: defaultCheckout,
                              
                                
                              };
                              setCodModal({
                                is_open: true,
                                data: set_data,
                              });
                          
                            }} >
                
                    <div className="mb-2"  >Default checkout payment type</div>
                   
                    <div className="ms-auto"> {setting_loading?    <Skeleton height={10} width={50} />:defaultCheckout}</div>
                 
                </div>
                <div className="d-flex mb-2"  onClick={(e) => {
e.stopPropagation()
                              let set_data = {
                                value: cod,
                              
                                
                              };
                              setCodAllowModal({
                                is_open: true,
                                data: set_data,
                              });
                          
                            }}>
                
                <div className="mb-2" >Allow COD for all users</div>
                <div className="ms-auto"> {setting_loading?    <Skeleton height={10} width={50} />:(cod?"Allowed":"Not Allowed")}</div>
             
            </div>
              </div>
            </div>
            {/* ))} */}
          </div>
        </div>
        <div className="d-flex">
          {page_no > 1 ? (
            <button
              className="btn btn-primary "
              onClick={() => {
                SetPageNo((prevalue: any) => {
                  return prevalue - 1;
                });
              }}
            >
              Prev
            </button>
          ) : null}
        </div>
      </div>

      <ModalCod
        open={cod_modal.is_open}
        data={cod_modal.data}
     
        close={(data: any) => {
          console.log("Edit Outlet Modal On Close :", data);
          setCodModal({
            is_open: false,
            data: {},
          
          });
          if (data.action == "save") {
            SetDefaultCheckout(data.value)
            // get_users();
            // SetUsersData((prevValue) => {
            //   prevValue[data.index] = data.value;
            //   return [...prevValue];
            // });
          }
        }}
      />

<ModalAllow
        open={cod_allow_modal.is_open}
        data={cod_allow_modal.data}
     
        close={(data: any) => {
          console.log("Edit Outlet Modal On Close :", data);
          setCodAllowModal({
            is_open: false,
            data: {},
          
          });
          if (data.action == "save") {
            setCod(data.value)
            // get_users();
            // SetUsersData((prevValue) => {
            //   prevValue[data.index] = data.value;
            //   return [...prevValue];
            // });
          }
        }}
      />
    </div>
  );
}
