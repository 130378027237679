import React, { useState, useEffect, useContext } from "react";

import { Link, useParams } from "react-router-dom";
import { api } from "../../utils/Api";
import {
  Ionicons,

  MaterialIcons,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import AvailableDeliveryPartner from "./AvailableDeliveryPartner";
import DeleteConfirmModal from "./DeleteConfirmModal";

import StarRatings from "react-star-ratings";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { AppConfig } from "../../config";


export default function OrderDetails1(props: any) {
  const [id_value, SetIdValue] = useState(useParams());
  const [order_data, SetOrderData]: any = useState({});

  const [image_base_url, SetImageBaseUrl] = useState("");
  const [available_delivery_partner_modal, SetAvailableDeliveryPartnerModal] =
    useState({
      is_open: false,
      data: {},
    });
  const [delete_confirm_modal_data, SetDeleteConfirmModalData] = useState({
    is_open: false,
    data: {},
    title: "",
    body: "",
  });

  const [is_resta_product_open, SetIsRestaProductOpen] = useState(false);
  const [set_order_details_loading, SetOrderDetailsLoading] = useState(true);

  useEffect(() => {
 
    get_order_detail(id_value.ord_id);
  }, []);

  async function get_order_detail(get_data: any) {
    SetOrderDetailsLoading(true);

    let pass_data = {
      get: {
        order_id: get_data,
      },
    };
    let response: any = await api("/orders/order_details", pass_data);
    if (response.status_code == 200) {
      SetOrderDetailsLoading(false);
      let order_value = response.response.data[0];
      if (response.response.data.length > 0) {
        SetOrderData(order_value);
        SetTimelineAccrData((prevalue: any) => {
         
          prevalue["status_timeline"] = false;
      
          if (order_value.sector_id == 4) {
            if (order_value.pickup_drop.length > 0) {
           
              prevalue["pickup_drop_status_timeline"] = false;
    
            }
          }
          return { ...prevalue };
        });
      }

      SetImageBaseUrl(response.response.image_base_url);
    }
  }

  async function delete_delivery_company_order(get_data: any) {
    let pass_data = {
      post: {
        id: get_data.id,
      },
    };

    let response: any = await api("/orders/delete_company_order", pass_data);
    if (response.status_code == 200) {
      get_order_detail(id_value.ord_id);
    }
  }

  async function delete_delivery_partner_order(get_data: any) {
  

    let pass_data = {
      post: {
        id: get_data.id,
      },
    };

    let response: any = await api(
      "/orders/delete_delivery_partner_order",
      pass_data
    );
    if (response.status_code == 200) {
      get_order_detail(id_value.ord_id);
    }
  }

  async function delete_delivery_company_reservation(get_data: any) {
    let pass_data = {
      post: {
        id: get_data.id,
      },
    };

    let response: any = await api(
      "/orders/delete_company_reservation",
      pass_data
    );
    if (response.status_code == 200) {
      get_order_detail(id_value.ord_id);
    }
  }

  async function delete_delivery_partner_reservation(get_data: any) {
    let pass_data = {
      post: {
        id: get_data.id,
      },
    };

    let response: any = await api(
      "/orders/delete_delivery_partner_reservation",
      pass_data
    );
    if (response.status_code == 200) {
      get_order_detail(id_value.ord_id);
    }
  }

  async function assign_delivery_partner(get_data: any) {
    let pass_data = {
      post: {
        order_id: get_data.order_id,
        company_order_id: available_delivery_partner_modal.data.comp_ord_id,
        delivery_partner_id: get_data.delivery_partner_id,
      },
    };
    let response: any = await api("/orders/assign_delivery_partner", pass_data);
    if (response.status_code == 200) {
      get_order_detail(id_value.ord_id);
    }
  }

  async function cancel_store_order(get_data: any) {
    let pass_data = {
      post: {
        id: get_data.id,
      },
    };
    let response: any = await api("/orders/cancel_store_order", pass_data);
    if (response.status_code == 200) {
      get_order_detail(get_data.id);
    }
  }

  function openMap(dir_lat: any, dir_lng: any) {
    window.open(
      "https://www.google.com/maps/dir/?api=1&destination=" +
        dir_lat +
        "," +
        dir_lng +
        "&travelmode=driving",
      "_blank"
    );
  }

  function generate_address(get_data: any) {
    let return_data: any = "";
    if (get_data.door_no != "") {
      return_data = return_data + get_data.door_no + ", ";
    }
    if (get_data.address != "") {
      return_data = return_data + get_data.address + ", ";
    }
    if (get_data.city != "") {
      return_data = return_data + get_data.city + ", ";
    }
    if (get_data.state != "") {
      return_data = return_data + get_data.state + ", ";
    }
    if (get_data.country != "") {
      return_data = return_data + get_data.country + " - ";
    }
    if (get_data.pincode != "") {
      return_data = return_data + get_data.pincode + ".";
    }

    return return_data;
  }

  function sec_to_min(seconds: any) {
    let result = new Date(seconds * 1000).toISOString().slice(11, 19);
    return result;
  }

  async function activate_order(get_data: any) {
    let pass_data = {
      post: {
        order_id: get_data.id,
      },
    };
    let response: any = await api("/orders/activate_store_order", pass_data);
    if (response.status_code == 200) {
      let pass_data = {
        order_id: id_value.ord_id,
      };
      get_order_detail(pass_data.order_id);
    }
  }

  const TimelineAccordian = (acc_props: any) => {
    const [is_open, SetIsopen] = useState(false);
    return (
      <>
        <div
          className="d-flex align-items-cetner"
          onClick={() => {
        
            SetIsopen(!is_open);
          }}
        >
          <h6 className="text-dark fw-bold">Timeline</h6>
          <div className="ms-auto">
            {is_open ? (
              <MaterialIcons
                name="keyboard-arrow-down"
                size={24}
                color="black"
              />
            ) : (
              <MaterialIcons name="keyboard-arrow-up" size={24} color="black" />
            )}
          </div>
        </div>
        <div className="">{is_open ? acc_props.children : null}</div>
      </>
    );
  };

  const OwnAccordian = (acc_props: any) => {
    const [is_open, SetIsopen] = useState(false);
    return (
      <>
        <div
          className="d-flex pt-3"
          onClick={() => {
            SetIsopen(!is_open);
          }}
        >
          <h6 className="text-dark pb-3">{acc_props.heading}</h6>
          <div className="ms-auto">
            {is_open ? (
              <MaterialIcons
                name="keyboard-arrow-down"
                size={24}
                color="black"
              />
            ) : (
              <MaterialIcons name="keyboard-arrow-up" size={24} color="black" />
            )}
          </div>
        </div>
        <div className="">{is_open ? acc_props.children : null}</div>
      </>
    );
  };

  return (
    <div className="">
      {/* header */}
      <div className="row align-items-center head-bar">
        <div className="col-md-6 col-9 sm-mb-2 ps-0">
          <div className="d-flex align-items-center">
            <div
              className="cursor mx-2"
              onClick={() => {
                window.history.go(-1);
              }}
            >
              <MaterialCommunityIcons
                name="arrow-left"
                size={18}
                color="black"
              />
            </div>
            <h5 className="  mb-0">Order Details1</h5>
          </div>
        </div>
      </div>

      {/* body */}
      <div className="mb-5 pt-3 sm-pt-4 sm-mt-6 mt-0">
        {/* Skeleton */}
        {set_order_details_loading ? (
          <div className="row">
            <div className="col-md-4 col-sm-6 col-12 sm-ps-3 md-ps-3">
              <div className="card p-3 mb-3">
                <div className="d-flex align-items-center mb-3">
                  <Skeleton height={10} width={90} />
                  <div className="ms-auto d-flex align-items-center">
                    <Skeleton height={30} width={80} borderRadius={5} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <Skeleton height={10} width={30} />
                    <div className="d-flex align-items-center pt-2">
                      <Skeleton height={18} width={18} />
                      <div className="ps-2">
                        <Skeleton height={10} width={90} />
                      </div>
                    </div>
                  </div>
                  <div className="col-6 text-end">
                    <div className="pb-2">
                      <Skeleton height={10} width={90} />
                    </div>
                    <div className="">
                      <Skeleton height={10} width={90} />
                    </div>
                  </div>
                </div>

                <div className="pb-3 pt-2">
                  <hr className="text-gray m-0" />
                </div>

                <div className="d-flex pb-2">
                  <Skeleton height={18} width={18} />
                  <div className="ps-2">
                    <Skeleton height={8} width={60} />
                    <Skeleton height={10} width={120} />
                  </div>
                </div>

                <div className="d-flex pb-2">
                  <Skeleton height={18} width={18} />
                  <div className="ps-2">
                    <Skeleton height={8} width={60} />
                    <Skeleton height={10} width={120} />
                  </div>
                </div>
              </div>

              <div className="card p-3 mb-3">
                <Skeleton height={10} width={90} />
                <div className="row pt-2">
                  <div className="col-8 d-flex">
                    <Skeleton height={18} width={18} />
                    <div className="ps-2">
                      <Skeleton height={10} width={30} />
                      <div className="pt-1">
                        <Skeleton height={10} width={90} />
                      </div>
                    </div>
                  </div>

                  <div className="col-4 row align-items-center p-0">
                    <div className="col-6 border-right cursor">
                      <Skeleton height={20} width={20} />
                    </div>
                    <div className="col-6 end cursor">
                      <Skeleton height={20} width={20} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="card p-3 mb-3">
                <Skeleton height={10} width={90} />
                <div className="d-flex pt-2">
                  <Skeleton height={18} width={18} />
                  <div className="ps-2">
                    <Skeleton height={10} width={90} />
                    <div className="pt-1">
                      <Skeleton height={10} width={90} />
                    </div>
                  </div>
                </div>
                <div className="d-flex pt-2">
                  <Skeleton height={15} width={15} />
                  <div className="ps-2">
                    <Skeleton height={10} width={90} />
                  </div>
                </div>
              </div>

              <div className="card p-3 mb-3">
                <Skeleton height={10} width={90} />
                <div className="d-flex pt-3">
                  <Skeleton height={18} width={18} />
                  <div className="ps-2">
                    <Skeleton height={10} width={90} />
                    <div className="pt-1">
                      <Skeleton height={10} width={90} />
                    </div>
                  </div>
                </div>

                <div className="d-flex pt-3">
                  <Skeleton height={18} width={18} />
                  <div className="ps-2">
                    <Skeleton height={10} width={90} />
                    <div className="pt-1">
                      <Skeleton height={10} width={90} />
                    </div>
                  </div>
                </div>

                <div className="d-flex py-3">
                  <Skeleton height={18} width={18} />
                  <div className="ps-2">
                    <Skeleton height={10} width={90} />
                    <div className="pt-1">
                      <Skeleton height={10} width={90} />
                    </div>
                  </div>
                </div>

                {order_data.sector_id != 4 && (
                  <div className="row pt-3 border-top">
                    <div className="col-6 pb-3">
                      <Skeleton height={10} width={50} />
                      <div className="pt-1">
                        <Skeleton height={10} width={90} />
                      </div>
                    </div>
                    <div className="col-6 pb-3">
                      <Skeleton height={10} width={50} />
                      <div className="pt-1">
                        <Skeleton height={10} width={90} />
                      </div>
                    </div>
                    <div className="col-6 pb-3">
                      <Skeleton height={10} width={50} />
                      <div className="pt-1">
                        <Skeleton height={10} width={90} />
                      </div>
                    </div>
                    <div className="col-6 pb-3">
                      <Skeleton height={10} width={50} />
                      <div className="pt-1">
                        <Skeleton height={10} width={90} />
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="card p-3 mb-3">
                <Skeleton height={10} width={120} />
                <div className="row pt-3">
                  <div className="col-6">
                    <Skeleton height={10} width={90} />
                  </div>
                  <div className="col-6 text-end">
                    <Skeleton height={10} width={50} />
                  </div>
                </div>

                <div className="row pt-2">
                  <div className="col-6">
                    <Skeleton height={10} width={100} />
                  </div>
                  <div className="col-6 text-end">
                    <Skeleton height={10} width={50} />
                  </div>
                </div>

                <div className="row pt-2">
                  <div className="col-6">
                    <Skeleton height={10} width={110} />
                  </div>
                  <div className="col-6 text-end">
                    <Skeleton height={10} width={50} />
                  </div>
                </div>

                <div className="row pt-2">
                  <div className="col-6">
                    <Skeleton height={10} width={115} />
                  </div>
                  <div className="col-6 text-end">
                    <Skeleton height={10} width={50} />
                  </div>
                </div>

                <div className="row pt-2">
                  <div className="col-6">
                    <Skeleton height={10} width={120} />
                  </div>
                  <div className="col-6 text-end">
                    <Skeleton height={10} width={50} />
                  </div>
                </div>

                <div className="row pt-2">
                  <div className="col-6">
                    <Skeleton height={10} width={125} />
                  </div>
                  <div className="col-6 text-end">
                    <Skeleton height={10} width={50} />
                  </div>
                </div>
                <div className="row border-top pt-3">
                  <div className="col-6">
                    <Skeleton height={10} width={150} />
                  </div>
                  <div className="col-6 text-end">
                    <Skeleton height={10} width={50} />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-12 px-0 sm-px-1">
              <div className="card p-3 mb-3">
                <div className="d-flex align-items-center mb-3">
                  <Skeleton height={10} width={90} />
                  <div className="ms-auto">
                    <Skeleton height={30} width={90} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-1">
                    <Skeleton height={18} width={18} />
                  </div>
                  <div className="ps-2 col-11">
                    <div className="pb-2 border-bottom">
                      <Skeleton height={10} width={90} />
                      <div className="pt-1">
                        <Skeleton height={10} width="100%" count={2} />
                      </div>
                    </div>

                    <div className="pt-2 d-flex align-items-center">
                      <div>
                        <Skeleton height={10} width={90} />
                        <div className="pt-1">
                          <Skeleton height={10} width={90} />
                        </div>
                      </div>
                      <div className="ms-auto">
                        <Skeleton height={30} width={30} borderRadius={50} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card p-3 mb-3">
                <div className="d-flex align-items-center mb-3">
                  <Skeleton height={10} width={90} />
                  <div className="ms-auto">
                    <Skeleton height={30} width={90} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-1">
                    <Skeleton height={18} width={18} />
                  </div>
                  <div className="ps-2 col-11">
                    <div className="pb-2 border-bottom">
                      <Skeleton height={10} width={90} />
                      <div className="pt-1">
                        <Skeleton height={10} width="100%" count={2} />
                      </div>
                    </div>

                    <div className="pt-2 d-flex align-items-center">
                      <div>
                        <Skeleton height={10} width={90} />
                        <div className="pt-1">
                          <Skeleton height={10} width={90} />
                        </div>
                      </div>
                      <div className="ms-auto">
                        <Skeleton height={30} width={30} borderRadius={50} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {order_data.sector_id == 4 && (
                <div className="card p-3 mb-3">
                  <Skeleton height={10} width={90} />
                  <div className="pt-3">
                    <Skeleton height={10} width={80} />
                    <div className="pt-1">
                      <Skeleton height={10} width={120} />
                    </div>
                  </div>
                  <div className="pt-3">
                    <Skeleton height={10} width={80} />
                    <div className="pt-1">
                      <Skeleton height={10} width={120} />
                    </div>
                  </div>
                  <div className="pt-3">
                    <Skeleton height={10} width={80} />
                    <div className="pt-1">
                      <Skeleton height={10} width={120} />
                    </div>
                  </div>
                </div>
              )}

              {order_data.sector_id != 4 && (
                <div className="card p-3 mb-3">
                  <Skeleton height={10} width={90} />
                  <div className="pt-3 row align-items-start">
                    <div className="col-7">
                      <div className="pb-2">
                        <Skeleton height={10} width={60} />
                      </div>
                      <Skeleton height={10} width={90} />
                    </div>
                    <div className="col-5 end">
                      <Skeleton height={10} width={80} />
                    </div>
                  </div>
                  <div className="pt-3">
                    <Skeleton height={10} width={120} />
                  </div>

                  <div className="">
                    <div className="pt-2 d-flex align-items-cetner cursor">
                      <Skeleton height={10} width={200} />
                      <div className="ms-auto">
                        <Skeleton height={10} width={10} />
                      </div>
                    </div>

                    <div className="pt-2">
                      <Skeleton height={10} width={90} />
                      <div className="pt-1">
                        <Skeleton height={10} width="100%" count={2} />
                      </div>
                      <div className="pt-2">
                        <Skeleton height={10} width={90} />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="card p-3 mb-3">
                {/* <TimelineAccordian> */}
                <div className="">
                  <Skeleton height={10} width={90} />
                  <ul className="delivery_status_vertical-skeleton w-100 pt-2">
                    {[1, 2, 3].map(() => (
                      <li>
                        <time className="extra-small text-end text-gray">
                          <Skeleton height={5} width={30} />
                        </time>
                        <div className="d-flex timeline">
                          <div className="pe-1">
                            <Skeleton
                              height={30}
                              width={30}
                              borderRadius={50}
                            />
                          </div>
                          <div>
                            <Skeleton height={10} width={90} />
                            <div className="pb-1">
                              <Skeleton height={8} width={50} />
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
           
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-12 md-pe-3  sm-px-1">
              <div className="card p-3 mb-3">
                <Skeleton height={10} width={90} />
                <div className="pt-3 row">
                  {[1, 2, 3, 4, 5, 6, 7, 8].map(() => (
                    <div className="col-md-4 col-6 mb-3">
                      <div className="pb-2">
                        <Skeleton height={10} width={90} />
                      </div>
                      <div className="pt-2">
                        <Skeleton height={10} width={30} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="card p-3 mb-3">
                <Skeleton height={10} width={90} />
                <div>
                  <div className="row pt-3">
                    <div className="col-6">
                      <Skeleton height={10} width={90} />
                    </div>
                    <div className="col-6 end">
                      <Skeleton height={10} width={50} />
                    </div>
                  </div>
                  <div className="row pt-3">
                    {[1, 2, 3].map(() => (
                      <div className="col-md-4 col-6 border-right d-flex pb-3">
                        <Skeleton height={18} width={18} />
                        <div className="ps-2">
                          <Skeleton height={10} width={40} />
                          <div className="pt-1">
                            <Skeleton height={10} width={90} />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="py-2">
              
                    <Skeleton height={10} width={90} />
                    <div className="pt-1">
                      <Skeleton height={10} width={150} />
                    </div>
                  
                  </div>
                  <div className="d-flex align-items-center pt-4">
                    <Skeleton height={30} width={80} />

                    <div className="ms-auto">
                      <Skeleton height={30} width={50} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="card p-3 mb-3">
      
                <div className="">
                  <Skeleton height={10} width={90} />
                  <ul className="delivery_status_vertical-skeleton w-100 pt-2">
                    {[1, 2, 3].map(() => (
                      <li>
                        <time className="extra-small text-end text-gray">
                          <Skeleton height={5} width={30} />
                        </time>
                        <div className="d-flex timeline">
                          <div className="pe-1">
                            <Skeleton
                              height={30}
                              width={30}
                              borderRadius={50}
                            />
                          </div>
                          <div>
                            <Skeleton height={10} width={90} />
                            <div className="pb-1">
                              <Skeleton height={8} width={50} />
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
           
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col-md-4 col-sm-6 col-12 sm-px-1 md-ps-3">
                <div className="card p-3 mb-3">
                  <div className="d-flex align-items-center mb-3">
                    <h6 className="fw-bold">Order Details1</h6>
                    <div className="ms-auto d-flex align-items-center">
                      {(order_data.status_id != 4 ||
                        order_data.status_id != 5 ||
                        order_data.status_id != 6) && (
                        <button className="btn btn-prime btn-sm " onClick={() => {
                    
                          SetDeleteConfirmModalData((prevalue) => {
                            prevalue.is_open = true;
                            prevalue.title = "Cancel Confirmation";
                            prevalue.body = "Confirm Cancel Store Order";
                            prevalue.data = {
                              id: "cancel_store_order",
                              pass_data: {
                                id: "",
                              },
                            };
                            return { ...prevalue };
                          });
                        }}>
                          Cancel Orders
                        </button>
                      )}
                      {!order_data.active ? (
                        <div className="ms-2">
                          <button
                            className="btn btn-blue btn-sm ms-auto "
                            onClick={() => {
                              activate_order(order_data);
                            }}
                          >
                            Accept
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <p className="text-dark fw-bold pb-2 extra-small">
                        #{order_data.id}
                      </p>
                      <div className="d-flex align-items-center">
                        <img
                          src={require("../../assets/icon/Delivery.png")}
                          className="icon-18px"
                        />
                        <p className="text-dark ps-2 fw-bold">
                          {order_data.sector}
                        </p>
                      </div>
                    </div>
                    <div className="col-6">
                      <p className="text-gray text-end extra-small pb-2">
                        {order_data.date}
                      </p>
                      <div className="end">
                        <div
                          className={
                            order_data.status == "Completed"
                              ? "color-dot-green"
                              : order_data.status == "Cancelled" ||
                                order_data.status == "Rejected"
                              ? "color-dot-red"
                              : "color-dot-blue"
                          }
                        ></div>
                        <p
                          className={
                            order_data.status == "Completed"
                              ? "text-green ps-2"
                              : order_data.status == "Cancelled" ||
                                order_data.status == "Rejected"
                              ? "text-red ps-2"
                              : "text-blue ps-2"
                          }
                        >
                          {order_data.status}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="pb-3 pt-2">
                    <hr className="text-gray m-0" />
                  </div>
                  <div className="d-flex">
                    <img
                      src={require("../../assets/icon/pay.png")}
                      className="icon-18px"
                    />
                    <div className="ps-2">
                      <p className="extra-small">Payment Type</p>
                      <p className="text-dark pt-1 small fw-bold">
                        {" "}
                        {order_data?.payment_type}
                      </p>
                    </div>
                  </div>

                  <div className="d-flex pt-3">
                    <img
                      src={require("../../assets/icon/scooter.png")}
                      className="icon-15px"
                    />
                    <div className="ps-2">
                      <p className="extra-small">Delivery Type</p>
                      <p className="text-dark pt-1 small fw-bold">
                        {" "}
                        {order_data?.delivery_type}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card p-3 mb-3">
                  <h6 className="fw-bold">Store Details</h6>
                  <div className="row pt-2">
                    <div className="col-8 d-flex">
                      <img
                        src={require("../../assets/icon/store.png")}
                        className="icon-18px"
                      />
                      <div className="ps-2">
                        <p className="extra-small text-gray">
                          #{order_data?.store_order_id}
                        </p>
                        <p className="pt-1 text-dark fw-bold">
                          {order_data?.store_name}
                        </p>
                      </div>
                    </div>

                    <div className="col-4 row align-items-center p-0">
                      <div className="col-6 border-right cursor">
                        <img
                          src={require("../../assets/icon/phone.png")}
                          className="icon-18px"
                        />
                      </div>
                      <div className="col-6 end cursor">
                        <img
                          src={require("../../assets/icon/i.png")}
                          className="icon-20px"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card p-3 mb-3">
                  <h6 className="fw-bold">Customer Details</h6>
                  <div className="d-flex pt-2">
                    <img
                      src={require("../../assets/icon/profile.png")}
                      className="icon-18px"
                    />
                    <div className="ps-2">
                      <p className="text-dark fw-bold">
                        {order_data.customer?.name}
                      </p>
                      <p className="pt-1 extra-small">
                        @{order_data.customer?.username}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex pt-2">
                    <img
                      src={require("../../assets/icon/phone.png")}
                      className="icon-15px"
                    />
                    <div className="ps-2">
                      <p className="text-dark fw-bold">
                        {order_data.customer?.delivery_address.mobile}
                      </p>
                    </div>
                  </div>
                </div>

                {/* Seller Details */}
                {order_data.sector_id != 4 && (
                  <div className="card p-3 mb-3">
                    <h6 className="fw-bold">Seller Details</h6>
                    <div className="d-flex pt-3">
                      <img
                        src={require("../../assets/icon/store.png")}
                        className="icon-18px"
                      />
                      <div className="ps-2">
                        <p className="extra-small">Brand Name</p>
                        <p className=" pt-1 text-dark fw-bold">
                          {order_data.seller?.brand_name}
                        </p>
                      </div>
                    </div>

                    <div className="d-flex pt-3">
                      <img
                        src={require("../../assets/icon/outlet.png")}
                        className="icon-18px"
                      />
                      <div className="ps-2">
                        <p className="extra-small">Outlet Name</p>
                        <p className=" pt-1 text-dark fw-bold">
                          {order_data.seller?.outlet?.name}
                        </p>
                      </div>
                    </div>

                    <div className="d-flex py-3">
                      <img
                        src={require("../../assets/icon/indian-rupee.png")}
                        className="icon-18px"
                      />
                      <div className="ps-2">
                        <p className="extra-small">COD</p>
                        <p className=" pt-1 small text-dark fw-bold">
                          {order_data?.seller?.outlet?.cod
                            ? "Available"
                            : "Not Available"}
                        </p>
                      </div>
                    </div>

                    <div className="row pt-3 border-top">
                      {order_data?.seller?.outlet?.mobile ? (
                        <div className="col-6 pb-3">
                          <p className="extra-small">Mobile</p>
                          <p className=" pt-1 small text-dark fw-bold">
                            {order_data?.seller?.outlet?.mobile}
                          </p>
                        </div>
                      ) : null}
                      {order_data?.seller?.outlet?.whatsapp ? (
                        <div className="col-6 pb-3">
                          <p className="extra-small">Whatsapp</p>
                          <p className=" pt-1 small text-dark fw-bold">
                            {order_data?.seller?.outlet?.whatsapp}
                          </p>
                        </div>
                      ) : null}
                      {order_data?.seller?.outlet?.email ? (
                        <div className="col-6 pb-3">
                          <p className="extra-small">Email</p>
                          <p className=" pt-1 small text-dark fw-bold">
                            {order_data?.seller?.outlet?.email}
                          </p>
                        </div>
                      ) : null}
                      {order_data?.seller?.outlet?.website ? (
                        <div className="col-6 pb-3">
                          <p className="extra-small">Website</p>
                          <p className=" pt-1 small text-dark fw-bold">
                            {order_data?.seller?.outlet?.website}
                          </p>
                        </div>
                      ) : null}
                    </div>
                  </div>
                )}

                <div className="card p-3 mb-3">
                  <h6 className="fw-bold">Order Summary</h6>
                  <div className="row pt-2">
                    <p className="text-gray col-6">Amount </p>
                    <p className="text-dark col-6 text-end">
                      ₹ {order_data?.amount}{" "}
                    </p>
                  </div>
                  <div className="row pt-2">
                    <p className="text-gray col-6">Gst Amount </p>
                    <p className="text-dark col-6 text-end">
                      ₹ {order_data?.gst_amount}
                    </p>
                  </div>

                  <div className="row pt-2">
                    <p className="text-gray col-6">Total Amount </p>
                    <p className="text-dark col-6 text-end">
                      ₹ {order_data?.total_amount}
                    </p>
                  </div>

                  <div className="row pt-2">
                    <p className="text-gray col-6">Delivery Charges </p>
                    <p className="text-dark col-6 text-end">
                      ₹ {order_data?.delivery_charges}
                    </p>
                  </div>

                  <div className="row pt-2">
                    <p className="text-gray col-6">Convenience Fee (%) </p>
                    <p className="text-dark col-6 text-end">
                      {order_data?.convenience_fee_percent}
                    </p>
                  </div>

                  <div className="row pb-2 pt-2">
                    <p className="text-gray col-6">Convenience Fee </p>
                    <p className="text-dark col-6 text-end">
                      ₹ {order_data?.convenience_fee}
                    </p>
                  </div>

                  <div className="row border-top pt-3">
                    <p className="text-dark fw-bold col-6 fs-7">
                      Total Amount Payable{" "}
                    </p>
                    <p className="text-dark fw-bold col-6 text-end fs-7">
                      ₹ {order_data?.total_amount_payable}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-sm-6 col-12 px-0 sm-px-1">
                {order_data.sector_id == 4 && (
                  <div>
                    <div className="card p-3 mb-3">
                      <div className="d-flex align-items-center mb-3">
                        <h6 className="fw-bold">Pickup Location</h6>
                        <div className="ms-auto">
                          <button
                            className="btn btn-prime btn-small "
                            onClick={() => {
                              openMap(
                                order_data?.customer?.pickup_address?.lat,
                                order_data?.customer?.pickup_address.lng
                              );
                            }}
                          >
                            <span className="small">Map</span>
                          </button>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-1">
                          <img
                            src={require("../../assets/icon/up-arrow.png")}
                            className="icon-18px"
                          />
                        </div>
                        <div className="ps-2 col-11">
                          <div className="pb-2 border-bottom">
                            <p className="text-dark fw-bold"></p>
                            <p className="small text-gray pt-1">
                              {order_data.customer?.pickup_address.door_no &&
                              order_data.customer?.pickup_address.address
                                ? generate_address(
                                    order_data.customer?.pickup_address
                                  )
                                : order_data.customer?.pickup_address
                                    .map_address}
                            </p>
                          </div>

                          <div className="pt-2 d-flex align-items-center">
                            <div>
                              <p className="text-dark fw-bold">
                                {" "}
                                {
                                  order_data.customer?.pickup_address?.firstname
                                }{" "}
                                {order_data.customer?.pickup_address?.lastname}
                              </p>
                              <p className="text-black pt-1">
                                {order_data.customer?.pickup_address.mobile}
                              </p>
                            </div>
                            {order_data.customer?.pickup_address.mobile ? (
                              <div className="ms-auto">
                                <div className="call-btn">
                                  <Ionicons
                                    name="md-call"
                                    size={18}
                                    color="#A80032"
                                  />
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card p-3 mb-3">
                      <div className="d-flex align-items-center mb-3">
                        <h6 className="fw-bold">Drop Location</h6>
                        <div className="ms-auto">
                          <button
                            className="btn btn-prime btn-small "
                            onClick={() => {
                              openMap(
                                order_data?.customer?.delivery_address?.lat,
                                order_data?.customer?.delivery_address?.lng
                              );
                            }}
                          >
                            <span className="small">Map</span>
                          </button>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-1">
                          <img
                            src={require("../../assets/icon/up-arrow.png")}
                            className="icon-18px"
                          />
                        </div>
                        <div className="ps-2 col-11">
                          <div className="pb-2 border-bottom">
                            <p className="text-dark fw-bold"></p>
                            <p className="small text-gray">
                              {order_data.customer?.delivery_address.door_no &&
                              order_data.customer?.delivery_address.address
                                ? generate_address(
                                    order_data.customer?.delivery_address
                                  )
                                : order_data.customer?.delivery_address
                                    .map_address}
                            </p>
                          </div>

                          <div className="pt-2 d-flex align-items-center">
                            <div>
                              <p className="text-dark fw-bold">
                                {
                                  order_data.customer?.delivery_address
                                    ?.firstname
                                }{" "}
                                {
                                  order_data.customer?.delivery_address
                                    ?.lastname
                                }
                              </p>
                              <p className="text-black pt-1">
                                {order_data.customer?.delivery_address.mobile}
                              </p>
                            </div>
                            {order_data.customer?.delivery_address.mobile ? (
                              <div className="ms-auto">
                                <div className="call-btn">
                                  <Ionicons
                                    name="md-call"
                                    size={18}
                                    color="#A80032"
                                  />
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card p-3 mb-3">
                      <h6 className="fw-bold">Package Details</h6>
                      <div className="pt-3">
                        <p className="extra-small text-gray">Package Content</p>
                        <p className="pt-1 text-dark fw-bold">
                          {order_data.package_content}
                        </p>
                      </div>
                      <div className="pt-3">
                        <p className="extra-small text-gray">Package Value</p>
                        <p className="pt-1 text-dark fw-bold">
                          {" "}
                          {order_data.package_value_currency_symbol}{" "}
                          {order_data.package_value_amount}
                        </p>
                      </div>
                      <div className="pt-3">
                        <p className="extra-small text-gray">Instructions</p>
                        <p className="pt-1 text-dark fw-bold">
                          {order_data.instructions}
                        </p>
                      </div>
                    </div>

                    <div className="card p-3 mb-3">
                      {/* <TimelineAccordian> */}
                      <OwnAccordian heading={"Location Timeline"}>
                      <ul className="delivery_status_vertical w-100 pt-2">
                          {order_data?.status_timeline?.map((ele: any) => (
                            <li>
                              <time className="extra-small text-end text-gray">
                                {ele?.time_formatted}
                              </time>
                              <div className="d-flex timeline">
                                {ele?.user.profile ? (
                                  <img
                                    src={ele?.user.profile}
                                    className="me-1 user-img-timeline"
                                  />
                                ) : (
                                  <img
                                    src={require("../../assets/img/profile.png")}
                                    className="me-1 user-img-timeline"
                                  />
                                )}
                                <div>
                                  <p className="text-dark pb-1">
                                    {ele?.status}
                                  </p>
                                  {ele?.user.profile != "" && (
                                    <p className="text-gray">
                                      {" "}
                                      {ele?.user.name}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
</OwnAccordian>
                   
                    
                    </div>
                  </div>
                )}

                {order_data.sector_id != 4 && (
                  <div>
                           {order_data?.shipments?.length !=0 ?
                    order_data?.shipments?.map(
                      (ship_ele: any, ship_index: any) => (
                        <div>
                             <div className="card p-3 mb-3">
                            <div className="card p-3 mb-3">
                            <h6 className="fw-bold">Shipment Details</h6>
                            <div className="pt-3 row align-items-start">
                              <div className="col-7">
                                <p className="text-black extra-small pb-2">
                                  SI No: {ship_index + 1}
                                </p>
                                <p className="text-gray small">
                                  {ship_ele.date}
                                </p>
                              </div>
                              <div className="col-5 end">
                                <div
                                  className={
                                    ship_ele.status == "Completed" ||
                                    ship_ele.status == "Delivered"
                                      ? "color-dot-green"
                                      : ship_ele.status == "Cancelled" ||
                                        ship_ele.status == "Rejected"
                                      ? "color-dot-red"
                                      : "color-dot-blue"
                                  }
                                ></div>
                                <p
                                  className={
                                    ship_ele.status == "Completed" ||
                                    ship_ele.status == "Delivered"
                                      ? "text-green ps-2"
                                      : ship_ele.status == "Cancelled" ||
                                        ship_ele.status == "Rejected"
                                      ? "text-red ps-2"
                                      : "text-blue ps-2"
                                  }
                                >
                                  {ship_ele.status}
                                </p>
                              </div>
                            </div>
                            <p className="small pt-3 text-gray">
                              Products Name
                            </p>
                            {ship_ele.items.map(
                              (item_ele: any, item_index: any) => (
                                <div className="">
                                  <div
                                    className="pt-2 d-flex align-items-cetner cursor"
                                    onClick={() => {
                                      SetIsRestaProductOpen(
                                        !is_resta_product_open
                                      );
                                    }}
                                  >
                                    <p className="text-dark fw-bold">
                              {      item_ele.ordered_product.hasOwnProperty('product_name') 
  ? item_ele.ordered_product.product_name 
  : item_ele.ordered_product.variant_name}
                                    </p>
                                    <div className="ms-auto">
                                      {is_resta_product_open &&
                                      item_index == item_index ? (
                                        <MaterialIcons
                                          name="keyboard-arrow-down"
                                          size={20}
                                          color="black"
                                        />
                                      ) : (
                                        <MaterialIcons
                                          name="keyboard-arrow-up"
                                          size={20}
                                          color="black"
                                        />
                                      )}
                                    </div>
                                  </div>

                                  {is_resta_product_open ? (
                                    <div className="pt-2">
                                      <p className=" text-dark">
                                        Product Description
                                      </p>
                                      <p className="pt-1 small text-gray">
                                      {      item_ele.ordered_product.hasOwnProperty('product_description') 
                                        ? item_ele.ordered_product.product_description 
                                        : item_ele.ordered_product.variant_name}
                                      </p>
                                      <p className="pt-2 extra-small text-dark">
                                        Added User : {item_ele.added_user.name}{" "}
                                      </p>
                                    </div>
                                  ) : null}
                                </div>
                              )
                            )}
                          </div>

                          <div className="card p-3 mb-3">
                            <div className="d-flex align-items-center mb-3">
                              <h6 className="fw-bold">Pickup Location</h6>
                              <div className="ms-auto">
                                <button
                                  className="btn btn-prime btn-small "
                                  onClick={() => {}}
                                >
                                  <span className="small">Map</span>
                                </button>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-1">
                                <img
                                  src={require("../../assets/icon/up-arrow.png")}
                                  className="icon-18px"
                                />
                              </div>
                              <div className="ps-2 col-11">
                                {order_data?.seller?.outlet?.map_address ||
                                order_data?.seller?.outlet?.map_address ? (
                                  <div className="pb-2 border-bottom">
                                    <p className="text-dark fw-bold">
                                      {order_data.seller?.brand_name}{" "}
                                    </p>
                                    <p className="small text-gray pt-1">
                                      {" "}
                                      {order_data?.seller?.outlet?.map_address}
                                    </p>
                                  </div>
                                ) : (
                                  <div className="d-flex">
                                    <p>
                                      {order_data?.seller?.outlet?.door_no}{" "}
                                      {", "}
                                      {order_data?.seller?.outlet?.address}{" "}
                                      {", "}
                                      {order_data?.seller?.outlet?.city} {", "}
                                      {order_data?.seller?.outlet?.state} {", "}
                                      {order_data?.seller?.outlet?.country}{" "}
                                      {"- "}
                                      {order_data?.seller?.outlet?.pincode}
                                    </p>
                                  </div>
                                )}
                                <div className="pt-2 d-flex align-items-center">
                                  <div>
                                    <p className="text-dark fw-bold">
                                      {order_data.seller?.outlet?.name}
                                    </p>
                                    <p className="text-black pt-1">
                                      {order_data?.seller?.outlet?.mobile}
                                    </p>
                                  </div>
                                  <div className="ms-auto">
                                    <div className="call-btn">
                                      <Ionicons
                                        name="md-call"
                                        size={18}
                                        color="#A80032"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="card p-3 mb-3">
                            <div className="d-flex align-items-center mb-3">
                              <h6 className="fw-bold">Delivery Location</h6>
                              <div className="ms-auto">
                                <button
                                  className="btn btn-prime btn-small "
                                  onClick={() => {
                                    openMap(
                                      order_data?.customer?.delivery_address
                                        ?.lat,
                                      order_data?.customer?.delivery_address
                                        ?.lng
                                    );
                                  }}
                                >
                                  <span className="small">Map</span>
                                </button>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-1">
                                <img
                                  src={require("../../assets/icon/up-arrow.png")}
                                  className="icon-18px"
                                />
                              </div>
                              <div className="ps-2 col-11">
                                <div className="pb-2 border-bottom">
                                  <p className="text-dark fw-bold"></p>
                                  {order_data?.customer?.delivery_address
                                    .map_address ||
                                  order_data?.customer?.delivery_address
                                    ?.map_address ? (
                                    <p className="small text-gray">
                                      {
                                        order_data?.customer?.delivery_address
                                          .map_address
                                      }
                                    </p>
                                  ) : (
                                    <p className="small text-gray">
                                      {order_data?.customer?.delivery_address
                                        .door_no +
                                        ", " +
                                        order_data?.customer?.delivery_address
                                          .address +
                                        ", " +
                                        order_data?.customer?.delivery_address
                                          .city +
                                        ", " +
                                        order_data?.customer?.delivery_address
                                          .state +
                                        ", " +
                                        order_data?.customer?.delivery_address
                                          .country +
                                        ", " +
                                        order_data?.customer?.delivery_address
                                          .pincode}
                                    </p>
                                  )}
                                </div>

                                <div className="pt-2 d-flex align-items-center">
                                  <div>
                                    <p className="text-dark fw-bold">
                                      {
                                        order_data.customer?.delivery_address
                                          ?.firstname
                                      }{" "}
                                      {
                                        order_data.customer?.delivery_address
                                          ?.lastname
                                      }
                                    </p>
                                    <p className="text-black pt-1">
                                      {
                                        order_data.customer?.delivery_address
                                          .mobile
                                      }
                                    </p>
                                  </div>
                                  {order_data.customer?.delivery_address
                                    .mobile ? (
                                    <div className="ms-auto">
                                      <div className="call-btn">
                                        <Ionicons
                                          name="md-call"
                                          size={18}
                                          color="#A80032"
                                        />
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>

                        
                          <div className="card p-3 mb-3">
                            {/* <TimelineAccordian> */}
                            <OwnAccordian heading={"Shipment Log"}>
                 
                            <ul className="delivery_status_vertical w-100 pt-2">
                                {ship_ele.status_timeline.map(
                                  (time_ele: any, time_index: any) => (
                                    <li>
                                      <time className="extra-small text-end text-gray">
                                        {time_ele?.time_formatted}
                                      </time>
                                      <div className="d-flex timeline">
                                        {time_ele?.user.profile ? (
                                          <img
                                            src={time_ele?.user.profile}
                                            className="me-1 user-img-timeline"
                                          />
                                        ) : (
                                          <img
                                            src={require("../../assets/img/profile.png")}
                                            className="me-1 user-img-timeline"
                                          />
                                        )}
                                        <div>
                                          <p className="text-dark pb-1">
                                            {time_ele?.status}
                                          </p>
                                          {time_ele?.user.profile != "" && (
                                            <p className="text-gray">
                                              {" "}
                                              {time_ele?.user.name}
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    </li>
                                  )
                                )}
                              </ul>
 
</OwnAccordian>
                            
                           
                          </div>
                        </div>

                        </div>
                      )
                    ):
                    <div>
                    <div className="card p-3 mb-3">
                      <div className="d-flex align-items-center mb-3">
                        <h6 className="fw-bold">Pickup Location</h6>
                       
                      </div>
                   
                         <div className="mt-3">
                                      <p className="text-gray">
                                        No Pickup Location
                                      </p>
                                    </div>
                    </div>
                    <div className="card p-3 mb-3">
                      <div className="d-flex align-items-center mb-3">
                        <h6 className="fw-bold">Delivery Location</h6>
                       
                      </div>
                     
                         <div className="mt-3">
                                      <p className="text-gray">
                                        No Delivery Location
                                      </p>
                                    </div>
                    </div>


                    <div className="card p-3 mb-3">
                      <h6 className="fw-bold">Shipment Details</h6>
                      <div className="mt-3">
                                      <p className="text-gray">
                                        No Shipment Details
                                      </p>
                                    </div>
                      
                    </div>

                    <div className="card p-3 mb-3">
                 
                      <div className="">
                        <h6 className="text-dark fw-bold">
                          Vendor Log
                        </h6>
                        <div className="mt-3">
                                      <p className="text-gray">
                                        No Vendor Log
                                      </p>
                                    </div>
                      
                      </div>
                  
                    </div>
                  </div>
                    }
                  </div>
                )}


{order_data?.refunds && order_data.refunds.length > 0&&
<div className="card p-3 mb-3">

  <h6>
  Refunds
                          </h6>


{order_data?.refunds?.map((refund_item,i) =>(
  <>

  <div className="d-flex">

                       <div>{refund_item.reason}</div>
                    
                       {refund_item.refunded?    <span className="badge-2 w-25 w-auto ms-2">Refunded</span> :<span className="badge-2 w-25 w-auto">pending</span>} 
 </div>

                            <OwnAccordian heading={"₹ "+refund_item.amount}>
               
                     

<ul className="delivery_status_vertical w-100 pt-2">
                            
                                    <li>
                                    <time className="extra-small text-end text-gray">
                                    
                                      </time>
                              
                                      <div className="d-flex timeline">
                                  
                                        <div>
                                          
                                          <p className="text-dark pb-1">
                                        1Superapp has initiated your refund
                                          </p>
                                        
                                            <p className="text-gray">
                                              {" "}
                                          completed
                                            </p>
                                            <p className="text-gray">
                                              {" "}
                                         {refund_item.time}
                                            </p>
                               
                                        </div>
                                      </div>
                                    </li>

                                    <li>
                                    <time className="extra-small text-end text-gray">
                                      
                                      </time>
                              
                                      <div className="d-flex timeline">
                             
                                        <div>
                                          
                                          <p className="text-dark pb-1">
                                        Refund credited to your account
                                          </p>
                                        
                                      
                                            <p className="text-gray">
                                              {" "}
                                         {refund_item.refunded_time}
                                            </p>
                                            <p className="text-gray">
                                              {" "}
                                         {refund_item.txn_id}
                                            </p>
                               
                                        </div>
                                      </div>
                                    </li>
                                  {/* )
                                )} */}
                              </ul>
                                 
                  </OwnAccordian>
                  </>
))}
                         
                          </div>
}

              </div>
     


              <div className="col-md-4 col-sm-6 col-12 md-pe-3  sm-px-1">
             

                {order_data.sector_id == 4 && (
                  <div className="">
                    {order_data?.pickup_drop?.map((item: any) => (
                      <div>
                        <div className="card p-3 mb-3">
                          <h6 className="fw-bold text-dark">
                            Delivery Company Details
                          </h6>
                          <div>
                            <div className="row pt-3">
                              <div className="col-6">
                                <p className="extra-small text-gray">
                                  {item.time}
                                </p>
                              </div>
                              <div className="col-6 end">
                                <div
                                  className={
                                    item.status == "Completed"
                                      ? "color-dot-green"
                                      : item.status == "Cancelled" ||
                                        item.status == "Rejected"
                                      ? "color-dot-red"
                                      : "color-dot-blue"
                                  }
                                ></div>
                                <p
                                  className={
                                    item.status == "Completed"
                                      ? "text-green ps-2"
                                      : item.status == "Cancelled" ||
                                        item.status == "Rejected"
                                      ? "text-red ps-2"
                                      : "text-blue ps-2"
                                  }
                                >
                                  {item.status}
                                </p>
                              </div>
                            </div>
                            <div className="row pt-3">
                              <div className="col-md-4 col-6 border-right d-flex pb-3">
                                <img
                                  src={require("../../assets/icon/store.png")}
                                  className="icon-18px"
                                />
                                <div className="ps-2">
                                  <p className="text-gray">Name</p>
                                  <p className="pt-1 text-dark fw-bold">
                                    {item.brand_name}
                                  </p>
                                </div>
                              </div>
                              <div className="col-md-4 col-6 border-right d-flex pb-3 pe-0">
                                <img
                                  src={require("../../assets/icon/truck.png")}
                                  className="icon-18px"
                                />
                                <div className="ps-2">
                                  <p className="text-gray">Service Type</p>
                                  <p className="pt-1 text-dark fw-bold">
                                    {item.service_name}
                                  </p>
                                </div>
                              </div>
                              <div className="col-md-4 col-6  d-flex pb-3">
                                <img
                                  src={require("../../assets/icon/location.png")}
                                  className="icon-18px"
                                />
                                <div className="ps-2">
                                  <p className="text-gray">Zone</p>
                                  <p className="pt-1 text-dark fw-bold">
                                    {item.zone_name}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="py-2">
                       
                              <p className="text-gray">Zone Description</p>
                              <p className="pt-1 text-dark">
                                {item.zone_description}
                              </p>
                              {/* </div> */}
                            </div>
                            <div className="d-flex align-items-center pt-4">
                              {(item.status_id == 1 ||
                                item.status_id == 2 ||
                                item.status_id == 5) && (
                                <button
                                  className="btn btn-blue btn-small"
                                  onClick={() => {
                                    SetAvailableDeliveryPartnerModal(
                                      (prevalue) => {
                                        prevalue.is_open = true;
                                        prevalue.data = {
                                          ord_id: id_value.order_id,
                                          comp_ord_id: item.id,
                                        };
                                        return { ...prevalue };
                                      }
                                    );
                                  }}
                                >
                                  <span className="small">
                                    Change Delivery Partner
                                  </span>
                                </button>
                              )}
                              {(item.status_id == 1 || item.status_id == 2) && (
                                <div className="ms-auto">
                                  <button
                                    className=" btn btn-outline-prime btn-small"
                                    onClick={() => {
                               

                                      SetDeleteConfirmModalData((prevalue) => {
                                        prevalue.is_open = true;
                                        prevalue.title = "Delete Confirmation";
                                        prevalue.body =
                                          "Confirm Delete Company Order";
                                        prevalue.data = {
                                          id: "delete_delivery_company_order",
                                          pass_data: {
                                            id: item.id,
                                          },
                                        };
                                        return { ...prevalue };
                                      });
                                    }}
                                    disabled={true}
                                  >
                                    <span className="fw-bold small">
                                      Delete
                                    </span>
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="card p-3 mb-3">

                        <OwnAccordian heading={"Delivery Company Timeline"}>
               
            

<ul className="delivery_status_vertical w-100 pt-3">
                              {item?.status_timeline?.map((ele: any) => (
                                <li>
                                  <time className="extra-small text-end text-gray">
                                    {ele?.time_formatted}
                                  </time>
                                  <div className="d-flex timeline">
                                    {ele?.user.profile ? (
                                      <img
                                        src={ele?.user.profile}
                                        className="me-1 user-img-timeline"
                                      />
                                    ) : (
                                      <img
                                        src={require("../../assets/img/profile.png")}
                                        className="me-1 user-img-timeline"
                                      />
                                    )}
                                    <div>
                                      <p className="text-dark pb-1">
                                        {ele?.status}
                                      </p>
                                      {ele?.user.profile != "" && (
                                        <p className="text-gray">
                                          {" "}
                                          {ele?.user.name}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </li>
                              ))}
                            </ul>
          
</OwnAccordian>
                          
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                {order_data.sector_id != 4 && (
                  <div>
                    {order_data?.shipments?.length != 0 ?
                    order_data?.shipments?.map(
                      (ship_ele: any, ship_index: any) =>
                        ship_ele.super_delivery_order.map(
                          (d_ele: any, d_index: any) => (
                            <div key={d_index}>
                               <div className="card p-3 mb-3">
                              <div className="card p-3 mb-3">
                                <h6 className="fw-bold text-dark">
                                  Shipment Delivery Details
                                </h6>
                                <div>
                                  <div className="row pt-3">
                                    <div className="col-6">
                                      <p className="extra-small text-gray">
                                        {d_ele.time}
                                      </p>
                                    </div>
                                    <div className="col-6 end">
                                      <div
                                        className={
                                          d_ele.status == "Completed"
                                            ? "color-dot-green"
                                            : d_ele.status == "Cancelled" ||
                                              d_ele.status == "Rejected"
                                            ? "color-dot-red"
                                            : "color-dot-blue"
                                        }
                                      ></div>
                                      <p
                                        className={
                                          d_ele.status == "Completed"
                                            ? "text-green ps-2"
                                            : d_ele.status == "Cancelled" ||
                                              d_ele.status == "Rejected"
                                            ? "text-red ps-2"
                                            : "text-blue ps-2"
                                        }
                                      >
                                        {d_ele.status}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="row pt-3">
                                    <div className="col-md-4 col-6 border-right d-flex pb-3">
                                      <img
                                        src={require("../../assets/icon/store.png")}
                                        className="icon-18px"
                                      />
                                      <div className="ps-2">
                                        <p className="text-gray">Name</p>
                                        <p className="pt-1 text-dark fw-bold">
                                          {d_ele.brand_name}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="col-md-4 col-6 border-right d-flex pb-3 pe-0">
                                      <img
                                        src={require("../../assets/icon/truck.png")}
                                        className="icon-18px"
                                      />
                                      <div className="ps-2">
                                        <p className="text-gray">
                                          Service Type
                                        </p>
                                        <p className="pt-1 text-dark fw-bold">
                                          {d_ele.service_name}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="col-md-4 col-6  d-flex pb-3">
                                      <img
                                        src={require("../../assets/icon/location.png")}
                                        className="icon-18px"
                                      />
                                      <div className="ps-2">
                                        <p className="text-gray">Zone</p>
                                        <p className="pt-1 text-dark fw-bold">
                                          {d_ele.zone_name}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="py-2">
                               
                                    <p className="text-gray">
                                      Zone Description
                                    </p>
                                    <p className="pt-1 text-dark">
                                      {d_ele.zone_description}
                                    </p>
                                    {/* </div> */}
                                  </div>
                                  <div className="d-flex align-items-center pt-4">
                                    {(order_data.status_id == 1 ||
                                      order_data.status_id == 2 ||
                                      order_data.status_id == 5) && (
                                      <button
                                        className="btn btn-blue btn-small"
                                        onClick={() => {
                                          SetAvailableDeliveryPartnerModal(
                                            (prevalue) => {
                                              prevalue.is_open = true;
                                              prevalue.data = {
                                                ord_id: id_value.order_id,
                                                comp_ord_id: d_ele.id,
                                              };
                                              return { ...prevalue };
                                            }
                                          );
                                        }}
                                      >
                                        <span className="small">
                                          Change Delivery Partner
                                        </span>
                                      </button>
                                    )}
                                    {(d_ele.status_id == 1 ||
                                      d_ele.status_id == 2) && (
                                      <div className="ms-auto">
                                        <button
                                          className=" btn btn-outline-prime btn-small"
                                          onClick={() => {
                                      

                                            SetDeleteConfirmModalData(
                                              (prevalue) => {
                                                prevalue.is_open = true;
                                                prevalue.title =
                                                  "Delete Confirmation";
                                                prevalue.body =
                                                  "Confirm Delete Company Order";
                                                prevalue.data = {
                                                  id: "delete_delivery_company_order",
                                                  pass_data: {
                                                    id: d_ele.id,
                                                  },
                                                };
                                                return { ...prevalue };
                                              }
                                            );
                                          }}
                                          disabled={true}
                                        >
                                          <span className="fw-bold small">
                                            Delete
                                          </span>
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="card p-3 mb-3">
                  <h6 className="fw-bold">Delivery Details</h6>
                  <div className="pt-3 row">
                    <div className="col-md-4 col-6 mb-3">
                      <p className="small pb-2 h-35 text-gray">
                        Forward Distance
                      </p>
                      <p className="text-dark fw-bold pt-2">
                        {order_data?.delivery_details?.forward_distance_kms} Km
                      </p>
                    </div>
                    <div className="col-md-4 col-6 mb-3">
                      <p className="small pb-2 h-35 text-gray">
                        Return Distance
                      </p>
                      <p className="text-dark fw-bold pt-2">
                        {order_data?.delivery_details?.return_distance_kms} Km
                      </p>
                    </div>
                    <div className="col-md-4 col-6 mb-3">
                      <p className="small pb-2 h-35 text-gray">
                        Total Distance
                      </p>
                      <p className="text-dark fw-bold pt-2">
                        {order_data?.delivery_details?.total_distance_kms} Km
                      </p>
                    </div>

                    <div className="col-md-4 col-6 mb-3">
                      <p className="small pb-2 h-35 text-gray">
                        Forward Distance Amount
                      </p>
                      <p className="text-dark fw-bold pt-2">
                        ₹{" "}
                        {order_data?.delivery_details?.forward_distance_amount}
                      </p>
                    </div>
                    <div className="col-md-4 col-6 mb-3">
                      <p className="small pb-2 h-35 text-gray">
                        Return Distance Amount
                      </p>
                      <p className="text-dark fw-bold pt-2">
                        ₹ {order_data?.delivery_details?.return_distance_amount}
                      </p>
                    </div>

                    <div className="col-md-4 col-6 mb-3">
                      <p className="small pb-2 h-35 text-gray">
                        Total Distance Amount
                      </p>
                      <p className="text-dark fw-bold pt-2">
                        ₹ {order_data?.delivery_details?.total_distance_amount}
                      </p>
                    </div>
                    <div className="col-md-4 col-6 mb-3">
                      <p className="small pb-2 h-35 text-gray">
                        Collected Delivery Charges
                      </p>
                      <p className="text-dark fw-bold pt-2">
                        ₹{" "}
                        {
                          order_data?.delivery_details
                            ?.collected_delivery_charges
                        }
                      </p>
                    </div>

                    <div className="col-md-4 col-6 mb-3">
                      <p className="small pb-2 h-35 text-gray">
                        Delivery Partner Amount
                      </p>
                      <p className="text-dark fw-bold pt-2">
                        ₹{" "}
                        {order_data?.delivery_details?.delivery_partner_amount}
                      </p>
                    </div>
                  </div>
                </div>
                              <div className="card p-3 mb-3">
                                {/* <TimelineAccordian> */}

                                <OwnAccordian heading={"Delivery Company Log"}>
                 
                                <ul className="delivery_status_vertical w-100 pt-3">
                                    {d_ele.status_timeline.map(
                                      (time_ele: any, time_index: any) => (
                                        <li>
                                          <time className="extra-small text-end text-gray">
                                            {time_ele?.time_formatted}
                                          </time>
                                          <div className="d-flex timeline">
                                            {time_ele?.user.profile ? (
                                              <img
                                                src={time_ele?.user.profile}
                                                className="me-1 user-img-timeline"
                                              />
                                            ) : (
                                              <img
                                                src={require("../../assets/img/profile.png")}
                                                className="me-1 user-img-timeline"
                                              />
                                            )}
                                            <div>
                                              <p className="text-dark pb-1">
                                                {time_ele?.status}
                                              </p>
                                              {time_ele?.user.profile != "" && (
                                                <p className="text-gray">
                                                  {" "}
                                                  {time_ele?.user.name}
                                                </p>
                                              )}
                                            </div>
                                          </div>
                                        </li>
                                      )
                                    )}
                                  </ul>
                
               </OwnAccordian>
                            
                              </div>
                            </div>
                            </div>

                          )
                        )
                    )
                  :
                  <div >
                  <div className="card p-3 mb-3">
                    <h6 className="fw-bold text-dark">
                      Shipment Delivery Details
                    </h6>
                    <div>
                    <div className="mt-3">
                                      <p className="text-gray">
                                        No Shipment Delivery Details
                                      </p>
                                    </div>
                    </div>
                  </div>

                  <div className="card p-3 mb-3">
                    {/* <TimelineAccordian> */}
                    <div className="">
                      <h6 className="text-dark fw-bold">
                        Delivery Company Log
                      </h6>
                      <div className="mt-3">
                                      <p className="text-gray">
                                        No Delivery Company Log
                                      </p>
                                    </div>
                    </div>
                  </div>
                </div>
                  }
                  </div>
                )}


              </div>
            </div>

            {order_data.sector_id == 4 &&
              order_data?.pickup_drop?.map((item: any) => (
                <div>
                  {item?.delivery_partner?.map(
                    (ele: any, dp_ele_index: any) => (
                      <div className="row">
                        <div className="col-md-6 col-12 md-ps-3  sm-px-1">
                        <div className="card p-3 mb-3">
                          <div className="card p-3 mb-3">
                            <div className="d-flex align-items-center">
                              <h6 className="fw-bold text-dark">
                                Delivery Partner Details
                              </h6>
                              <div className="ms-auto">
                                {ele.status_id != 2 &&
                                  ele.status_id != 9 &&
                                  ele.status_id != 10 &&
                                  ele.status_id != 11 &&
                                  ele.status_id != 12 &&
                                  ele.status_id != 13 && (
                                    <button
                                      className="btn btn-small btn-prime "
                                      onClick={() => {
                                        // let pass_data = {
                                        //     id: ""
                                        // }
                                        // delete_delivery_partner_order(pass_data);
                                        SetDeleteConfirmModalData(
                                          (prevalue) => {
                                            prevalue.is_open = true;
                                            prevalue.title =
                                              "Delete Confirmation";
                                            prevalue.body =
                                              "Confirm Delete Partner Order";
                                            prevalue.data = {
                                              id: "delete_delivery_partner_order",
                                              pass_data: {
                                                id: ele.id,
                                              },
                                            };
                                            return { ...prevalue };
                                          }
                                        );
                                      }}
                                    >
                                      <span className="small">Delete</span>
                                    </button>
                                  )}
                              </div>
                            </div>

                            <div className="row mt-3">
                              <div className="col-md-4 col-sm-6 col-12 border-right d-flex pb-2">
                                <img
                                  src={require("../../assets/icon/profile.png")}
                                  className="icon-18px"
                                />
                                <div className="ps-2">
                                  <p className="text-gray">Name </p>
                                  <p className="text-dark fw-bold pt-1">
                                    {ele?.delivery_partner.name}
                                  </p>
                                </div>
                              </div>

                              {/* delivery type */}
                              <div className="col-md-4 col-sm-6 col-12 border-right d-flex pb-2">
                                <img
                                  src={require("../../assets/icon/truck.png")}
                                  className="icon-18px"
                                />
                                <div className="ps-2">
                                  <p className="text-gray">Delivery Type</p>
                                  <p className="text-dark fw-bold pt-1">
                                    {ele?.hyperlocal
                                      ? "HyperLocal"
                                      : ele?.courier
                                      ? "Courier"
                                      : null}
                                  </p>
                                </div>
                              </div>
                              {/* Status */}
                              <div className="col-md-4 col-sm-6 col-12 d-flex pb-2">
                                <img
                                  src={require("../../assets/icon/status.png")}
                                  className="icon-18px"
                                />
                                <div className="ps-2">
                                  <p className="text-gray">Status</p>
                                  <p className="text-dark fw-bold pt-1">
                                    {ele?.status}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="card p-3 mb-3">
                           

<OwnAccordian heading={"Delivery Partner Log"}>
                 
<ul className="delivery_status_vertical w-100 pt-3">
                                {ele.status_timeline.map((ele: any) => (
                                  <li>
                                    <time className="extra-small text-end text-gray">
                                      {ele?.time_formatted}
                                    </time>
                                    <div className="d-flex timeline">
                                      {ele?.user.profile ? (
                                        <img
                                          src={ele?.user.profile}
                                          className="me-1 user-img-timeline"
                                        />
                                      ) : (
                                        <img
                                          src={require("../../assets/img/profile.png")}
                                          className="me-1 user-img-timeline"
                                        />
                                      )}
                                      <div>
                                        <p className="text-dark pb-1">
                                          {ele?.status}
                                        </p>
                                        {ele?.user.profile != "" && (
                                          <p className="text-gray">
                                            {" "}
                                            {ele?.user.name}
                                          </p>
                                        )}
                                      </div>
                                    </div>

                                    <div className="w-20 center">
                                      {ele.hasOwnProperty("lat") && (
                                        <button
                                          type="button"
                                          className="btn btn-prime btn-small px-3"
                                          onClick={() => {
                                            openMap(ele?.lat, ele?.lng);
                                          }}
                                        >
                                          <span className="small">Map</span>
                                        </button>
                                      )}
                                    </div>
                                  </li>
                                ))}
                              </ul>
                
               </OwnAccordian>
                     
                          </div>
                          <div className="card p-3 mb-3">
                            <h6 className="fw-bold text-dark">
                              Delivery Partner Pickup Details
                            </h6>
                            <div className="row pt-3">
                              <div className="col-5">
                                <div className=" pb-3">
                                  <p className="pb-2 text-gray">
                                    Estimate Distance
                                  </p>
                                  <p className="text-dark fw-bold pt-2">
                                    {ele.etd_pickup_distance} Km
                                  </p>
                                </div>
                                <div className=" pb-3">
                                  <p className="pb-2 text-gray">
                                    Estimate Duration
                                  </p>
                                  <p className="text-dark fw-bold pt-2">
                                    {sec_to_min(ele.etd_pickup_duration)}
                                  </p>
                                </div>
                              </div>

                              <div className="col-7 pb-3">
                                <p className="pb-2 text-gray">Captured Image</p>
                                {ele.hasOwnProperty("package_image") && (
                                  <div className="row">
                                    {ele.package_image.map((img_ele: any) => (
                                      <img
                                        className="w-30"
                                        src={image_base_url + img_ele}
                                      />
                                    ))}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="card p-3 mb-3">
                            <h6 className="fw-bold text-dark">
                              Delivery Partner Delivery Details
                            </h6>
                            <div className="row pt-3">
                              <div className="col-4  pb-3">
                                <p className="pb-2 text-gray">
                                  Estimate Distance
                                </p>
                                <p className="text-dark fw-bold pt-2">
                                  {ele.etd_delivery_distance} Km
                                </p>
                              </div>

                              <div className="col-4  pb-3">
                                <p className="pb-2 text-gray">
                                  Payment Distance
                                </p>
                                <p className="text-dark fw-bold pt-2">
                                  {ele?.payment_distance} Km
                                </p>
                              </div>

                              <div className="col-4  pb-3">
                                <p className="pb-2 text-gray">Partner Amount</p>
                                <p className="text-dark fw-bold pt-2">
                                  ₹ {ele?.partner_amount}
                                </p>
                              </div>

                              <div className="col-4  pb-3">
                                <p className="pb-2 text-gray">
                                  Estimate Duration
                                </p>
                                <p className="text-dark fw-bold pt-2">
                                  {sec_to_min(ele.etd_delivery_duration)}
                                </p>
                              </div>

                              <div className="col-4  pb-3">
                                <p className="pb-2 text-gray">Payment Amount</p>
                                <p className="text-dark fw-bold pt-2">
                                  ₹ {ele?.payment_amount}
                                </p>
                              </div>

                              <div className="col-4  pb-3">
                                <p className="pb-2 text-gray">
                                  COD Order Amount
                                </p>
                                <p className="text-dark fw-bold pt-2">
                                  ₹ {ele?.cod_order_amount}
                                </p>
                              </div>

                              <div className="col-4  pb-3">
                                <p className="pb-2 text-gray">
                                  Collected Amount
                                </p>
                                <p className="text-dark fw-bold pt-2">
                                  ₹ {ele?.collected_amount}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        </div>


                    
                 
                      </div>
                    )
                  )}
                </div>
              ))}

            {order_data.sector_id != 4 && (
              <div>
                {order_data?.shipments?.length !=0 ?
                order_data?.shipments?.map((ship_ele: any, ship_index: any) =>
                  ship_ele.super_delivery_order.map(
                    (d_ele: any, d_index: any) => (
                      <div>
                        {d_ele.delivery_partner.map(
                          (partner_ele: any, partner_index: any) => (
                         
                            <div className="row">
                              <div className="col-md-6 col-12 md-ps-3  sm-px-1">
                              <div className="card p-3 mb-3">
                                <div className="card p-3 mb-3">
                                  <div className="d-flex align-items-center">
                                    <h6 className="fw-bold text-dark">
                                      Delivery Partner Details
                                    </h6>
                                    <div className="ms-auto">
                                      {partner_ele.status_id != 2 &&
                                        partner_ele.status_id != 9 &&
                                        partner_ele.status_id != 10 &&
                                        partner_ele.status_id != 11 &&
                                        partner_ele.status_id != 12 &&
                                        partner_ele.status_id != 13 && (
                                          <button
                                            className="btn btn-small btn-prime "
                                            onClick={() => {
                                         
                                              SetDeleteConfirmModalData(
                                                (prevalue) => {
                                                  prevalue.is_open = true;
                                                  prevalue.title =
                                                    "Delete Confirmation";
                                                  prevalue.body =
                                                    "Confirm Delete Partner Order";
                                                  prevalue.data = {
                                                    id: "delete_delivery_partner_order",
                                                    pass_data: {
                                                      id: partner_ele.id,
                                                    },
                                                  };
                                                  return { ...prevalue };
                                                }
                                              );
                                            }}
                                          >
                                            <span className="small">
                                              Delete
                                            </span>
                                          </button>
                                        )}
                                    </div>
                                  </div>

                                  <div className="row mt-3">
                                    <div className="col-md-3 col-sm-6 col-12 pe-0 border-right d-flex pb-2">
                                      <img
                                        src={require("../../assets/icon/profile.png")}
                                        className="icon-18px"
                                      />
                                      <div className="ps-2">
                                        <p className="text-gray">Name </p>
                                        <p className="text-dark fw-bold pt-1">
                                          {partner_ele?.delivery_partner.name}
                                        </p>
                                      </div>
                                    </div>

                                    {/* delivery type */}
                                    <div className="col-md-3 col-sm-6 col-12 pe-0 border-right d-flex pb-2">
                                      <img
                                        src={require("../../assets/icon/truck.png")}
                                        className="icon-18px"
                                      />
                                      <div className="ps-2">
                                        <p className="text-gray">
                                          Delivery Type
                                        </p>
                                        <p className="text-dark fw-bold pt-1">
                                          {partner_ele?.hyperlocal
                                            ? "HyperLocal"
                                            : partner_ele?.courier
                                            ? "Courier"
                                            : null}
                                        </p>
                                      </div>
                                    </div>
                                    {/* Status */}
                                    <div className="col-md-3 col-sm-6 col-12 pe-0 border-right d-flex pb-2">
                                      <img
                                        src={require("../../assets/icon/status.png")}
                                        className="icon-18px"
                                      />
                                      <div className="ps-2">
                                        <p className="text-gray">Status</p>
                                        <p className="text-dark fw-bold pt-1">
                                          {partner_ele?.status}
                                        </p>
                                      </div>
                                    </div>

                                    {/* Distance */}
                                    <div className="col-md-3 col-sm-6 col-12 d-flex pb-2">
                                      <img
                                        src={require("../../assets/icon/distance.png")}
                                        className="icon-18px"
                                      />
                                      <div className="ps-2">
                                        <p className="text-gray">Distance</p>
                                        <p className="text-dark fw-bold pt-1">
                                          {partner_ele.payment_distance} Km
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="card p-3 mb-3">
                                  {/* <TimelineAccordian> */}
                                  <OwnAccordian heading={"Delivery Partner Log"}>
                 
                    <ul className="delivery_status_vertical w-100 pt-3">
                                      {partner_ele.status_timeline.map(
                                        (time_ele: any, time_index: any) => (
                                          <li>
                                            <time className="extra-small text-end text-gray">
                                              {time_ele?.time_formatted}
                                            </time>
                                            <div className="d-flex timeline">
                                              {time_ele?.user.profile ? (
                                                <img
                                                  src={time_ele?.user.profile}
                                                  className="me-1 user-img-timeline"
                                                />
                                              ) : (
                                                <img
                                                  src={require("../../assets/img/profile.png")}
                                                  className="me-1 user-img-timeline"
                                                />
                                              )}
                                              <div>
                                                <p className="text-dark pb-1">
                                                  {time_ele?.status}
                                                </p>
                                                {time_ele?.user.profile !=
                                                  "" && (
                                                  <p className="text-gray">
                                                    {" "}
                                                    {time_ele?.user.name}
                                                  </p>
                                                )}
                                              </div>
                                            </div>
                                          </li>
                                        )
                                      )}
                                    </ul>
                   
                  </OwnAccordian>
                                
                                </div>
                              
                                <div className="card p-3 mb-3">
                                  <h6 className="text-dark fw-bold">
                                    Delivery Partner Feedback
                                  </h6>
                                  {order_data.hasOwnProperty(
                                    "delivery_partner_feedback"
                                  ) ? (
                                    <div className="mt-3">
                                      <div className="d-flex align-items-center">
                                        <StarRatings
                                          rating={
                                            order_data.delivery_partner_feedback
                                              .rating
                                              ? parseFloat(
                                                  order_data
                                                    .delivery_partner_feedback
                                                    .rating
                                                )
                                              : 0
                                          }
                                          starDimension="20px"
                                          starSpacing="5px"
                                          starEmptyColor="#ccc"
                                          starRatedColor="orange"
                                        />
                                      </div>
                                      <p className="pt-2">
                                        {
                                          order_data.delivery_partner_feedback
                                            .message
                                        }
                                      </p>
                                    </div>
                                  ) : (
                                    <div className="mt-3">
                                      <p className="text-gray">
                                        No Delivery Partner Feedback
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>

                            </div>
                            </div>

                          )
                        )}
                      </div>
                    )
                  )
                ) :    <div className="row">
                <div className="col-md-6 col-12 md-ps-3  sm-px-1">
                  <div className="card p-3 mb-3">
                    <div className="d-flex align-items-center">
                      <h6 className="fw-bold text-dark">
                        Delivery Partner Details
                      </h6>
                    
                    </div>
                    <div className="mt-3">
                      <p className="text-gray">
                        No Delivery Partner Details
                      </p>
                    </div>

                    
                  </div>

                  <div className="card p-3 mb-3">
                    <h6 className="text-dark fw-bold">
                      Platform Feedback
                    </h6>
                    {order_data.hasOwnProperty(
                      "platform_feedback"
                    ) ? (
                      <div className="mt-3">
                        <div className="d-flex align-items-center">
                          <StarRatings
                            rating={
                              order_data.platform_feedback.rating
                                ? parseFloat(
                                    order_data.platform_feedback
                                      .rating
                                  )
                                : 0
                            }
                            starDimension="20px"
                            starSpacing="5px"
                            starEmptyColor="#ccc"
                            starRatedColor="orange"
                          />
                        </div>
                        <p className="pt-2">
                          {order_data.platform_feedback.message}
                        </p>
                      </div>
                    ) : (
                      <div className="mt-3">
                        <p className="text-gray">
                          No Platform Feedback
                        </p>
                      </div>
                    )}
                  </div>

                  <div className="card p-3 mb-3">
                    <h6 className="text-dark fw-bold">
                      Product Feedback
                    </h6>
                    
                    <div className="mt-3">
                      <p className="text-gray">
                        No Product Feedback
                      </p>
                    </div>
                    {/* )} */}
                  </div>

                  <div className="card p-3 mb-3">
                    <h6 className="text-dark fw-bold">
                      Delivery Partner Feedback
                    </h6>
                    {order_data.hasOwnProperty(
                      "delivery_partner_feedback"
                    ) ? (
                      <div className="mt-3">
                        <div className="d-flex align-items-center">
                          <StarRatings
                            rating={
                              order_data.delivery_partner_feedback
                                .rating
                                ? parseFloat(
                                    order_data
                                      .delivery_partner_feedback
                                      .rating
                                  )
                                : 0
                            }
                            starDimension="20px"
                            starSpacing="5px"
                            starEmptyColor="#ccc"
                            starRatedColor="orange"
                          />
                        </div>
                        <p className="pt-2">
                          {
                            order_data.delivery_partner_feedback
                              .message
                          }
                        </p>
                      </div>
                    ) : (
                      <div className="mt-3">
                        <p className="text-gray">
                          No Delivery Partner Feedback
                        </p>
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-6  md-pe-3  sm-px-1">
                  <div className="card p-3 mb-3">
                    {/* <TimelineAccordian> */}
                    <div className="">
                      <h6 className="text-dark fw-bold">
                        Delivery Partner Log
                      </h6>
                      <div className="mt-3">
                      <p className="text-gray">
                        No Delivery Partner Log
                      </p>
                    </div>
                    </div>
                  </div>
                </div>
              </div>}
              </div>
            )}

            {order_data.sector_id == 4 && (
              <div className="row">
                <div className="col-md-3 col-sm-6 col-12 md-px-3 sm-px-1">
                  <div className="card p-3 ">
                    <h6 className="text-dark fw-bold">Platform Feedback</h6>
                    {order_data.hasOwnProperty("platform_feedback") ? (
                      <div className="mt-3">
                        <div className="d-flex align-items-center">
                          <StarRatings
                            rating={
                              order_data.platform_feedback.rating
                                ? parseFloat(
                                    order_data.platform_feedback.rating
                                  )
                                : 0
                            }
                            starDimension="20px"
                            starSpacing="5px"
                            starEmptyColor="#ccc"
                            starRatedColor="orange"
                          />
                        </div>
                        <p className="pt-2">
                          {order_data.platform_feedback.message}
                        </p>
                      </div>
                    ) : (
                      <div className="mt-3">
                        <p className="text-gray">No Platform Feedback</p>
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-3 col-sm-6 col-12 md-pe-3 md-ps-0 sm-px-1">
                  <div className="card p-3 ">
                    <h6 className="text-dark fw-bold">
                      Delivery Partner Feedback
                    </h6>
                    {order_data.hasOwnProperty("delivery_partner_feedback") ? (
                      <div className="mt-3">
                        <div className="d-flex align-items-center">
                          <StarRatings
                            rating={
                              order_data.delivery_partner_feedback.rating
                                ? parseFloat(
                                    order_data.delivery_partner_feedback.rating
                                  )
                                : 0
                            }
                            starDimension="20px"
                            starSpacing="5px"
                            starEmptyColor="#ccc"
                            starRatedColor="orange"
                          />
                        </div>
                        <p className="pt-2">
                          {order_data.delivery_partner_feedback.message}
                        </p>
                      </div>
                    ) : (
                      <div className="mt-3">
                        <p className="text-gray">
                          No Delivery Partner Feedback
                        </p>
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-3 col-sm-6 col-12 md-pe-3 md-ps-0 sm-px-1">
                  <div className="card p-3 ">
                    {order_data?.pickup_drop?.map((item: any) => (
                      <div>
                        <h6 className="text-dark fw-bold">
                          Sellers Feedback from Delivery Partner
                        </h6>
                        {item?.delivery_partner?.map(
                          (ele: any, dp_ele_index: any) => (
                            <div>
                              {ele.hasOwnProperty("seller_rating") ? (
                                <div className="mt-3">
                                  <div className="d-flex align-items-center">
                                    <StarRatings
                                      rating={
                                        ele.seller_rating
                                          ? parseFloat(ele.seller_rating)
                                          : 0
                                      }
                                      starDimension="20px"
                                      starSpacing="5px"
                                      starEmptyColor="#ccc"
                                      starRatedColor="orange"
                                    />
                                  </div>
                                  <p className="pt-2">
                                    {" "}
                                    {ele.seller_feedback_list}
                                  </p>
                                </div>
                              ) : (
                                <div className="mt-3">
                                  <p className="text-gray">
                                    No Delivery Partner Feedback
                                  </p>
                                </div>
                              )}
                            </div>
                          )
                        )}
                      </div>
                    ))}
                  </div>
                </div>

                <div className="col-md-3 col-sm-6 col-12 md-pe-3 md-ps-0 sm-px-1">
                  <div className="card p-3 ">
                    {order_data?.pickup_drop?.map((item: any) => (
                      <div>
                        <h6 className="text-dark fw-bold">
                          Customer Feedback from Delivery Partner
                        </h6>
                        {item?.delivery_partner?.map(
                          (ele: any, dp_ele_index: any) => (
                            <div>
                              <div className="mt-3">
                                <div className="d-flex align-items-center">
                                  <StarRatings
                                    rating={
                                      ele.customer_rating
                                        ? parseFloat(ele.customer_rating)
                                        : 0
                                    }
                                    starDimension="20px"
                                    starSpacing="5px"
                                    starEmptyColor="#ccc"
                                    starRatedColor="orange"
                                  />
                                </div>
                                <p className="pt-2"> {ele.customer_feedback}</p>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}

            {/* Estimation, On accept, Actual, Difference */}
            <div className="row pt-4">
              {/* Estimation */}
              <div className="col-md-6 md-ps-3 sm-px-1">

                
                <div className="card p-3 mb-3">
                  <h6 className="text-dark fw-bold">Estimation</h6>
                  <div className="row pt-3">
                    <div className="col-4 pb-3">
                      <p className="text-gray h-30">Company Name</p>
                      <p className="pt-1 text-dark fw-bold">
                        {order_data?.delivery_details?.company_brand_name
                          ? order_data?.delivery_details?.company_brand_name
                          : "N/A"}{" "}
                      </p>
                    </div>

                    <div className="col-4 pb-3">
                      <p className="text-gray h-30">Executive Name</p>
                      <p className="pt-1 text-dark fw-bold">N/A</p>
                    </div>
                  </div>

                  <OwnAccordian heading={"Pickup Details"}>
                    <div className="row">
                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Distance</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>
                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Distance Payable</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>

                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Rate</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>
                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Charge</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>

                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Start Time</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>
                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">End Time</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>

                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Duration</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>
                    </div>
                  </OwnAccordian>

                  <OwnAccordian heading={"Delivery Details"}>
                    <div className="row">
                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Distance</p>
                        <p className="pt-1 text-dark fw-bold">
                          {order_data?.delivery_details
                            ?.exact_delivery_distance_kms
                            ? order_data?.delivery_details
                                ?.exact_delivery_distance_kms + " kms"
                            : "N/A"}
                        </p>
                      </div>
                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Distance Payable</p>
                        <p className="pt-1 text-dark fw-bold">
                          {order_data?.delivery_details?.total_distance_kms
                            ? order_data?.delivery_details?.total_distance_kms +
                              " kms"
                            : "N/A"}
                        </p>
                      </div>

                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Rate</p>
                        <p className="pt-1 text-dark fw-bold">
                          {order_data?.delivery_details?.company_delivery_rate
                            ? order_data?.delivery_details
                                ?.company_delivery_rate
                            : "N/A"}{" "}
                        </p>
                      </div>
                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Charge</p>
                        <p className="pt-1 text-dark fw-bold">
                          {order_data?.delivery_details?.total_distance_amount
                            ? order_data?.delivery_details
                                ?.total_distance_amount
                            : "N/A"}
                        </p>
                      </div>

                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Start Time</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>
                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">End Time</p>
                        <p className="pt-1 text-dark fw-bold">
                          {order_data?.delivery_details?.order_delivery_time
                            ? order_data?.delivery_details?.order_delivery_time
                            : "N/A"}
                        </p>
                      </div>

                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Duration</p>
                        <p className="pt-1 text-dark fw-bold">
                          {order_data?.delivery_details?.etd
                            ? Math.round(
                                order_data?.delivery_details?.etd / 60
                              ) + " mins"
                            : "N/A"}
                        </p>
                      </div>
                    </div>
                  </OwnAccordian>

                  <OwnAccordian heading={"Return Details"}>
                    <div className="row">
                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Distance</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>
                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Distance Payable</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>

                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Rate</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>
                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Charge</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>

                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Start Time</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>
                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">End Time</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>

                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Duration</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>
                    </div>
                  </OwnAccordian>
                </div>
              </div>

              {/* On accept */}
              <div className="col-md-6 md-pe-3 sm-px-1">
                <div className="card p-3 mb-3">
                  <h6 className="text-dark fw-bold">On Accept</h6>
                  <div className="row pt-3">
                    <div className="col-4 pb-3">
                      <p className="text-gray h-30">Company Name</p>
                      <p className="pt-1 text-dark fw-bold">N/A</p>
                    </div>

                    <div className="col-4 pb-3">
                      <p className="text-gray h-30">Executive Name</p>
                      <p className="pt-1 text-dark fw-bold">N/A</p>
                    </div>
                  </div>
                  <OwnAccordian heading={"Pickup Details"}>
                    <div className="row">
                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Distance</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>
                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Distance Payable</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>

                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Rate</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>
                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Charge</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>

                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Start Time</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>
                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">End Time</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>

                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Duration</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>
                    </div>
                  </OwnAccordian>
                  <OwnAccordian heading={"Delivery Details"}>
                    <div className="row">
                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Distance</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>
                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Distance Payable</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>

                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Rate</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>
                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Charge</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>

                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Start Time</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>
                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">End Time</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>

                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Duration</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>
                    </div>
                  </OwnAccordian>

                  <OwnAccordian heading={"Return Details"}>
                    <div className="row">
                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Distance</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>
                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Distance Payable</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>

                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Rate</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>
                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Charge</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>

                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Start Time</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>
                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">End Time</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>

                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Duration</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>
                    </div>
                  </OwnAccordian>
                </div>
              </div>

              {/* Actual */}
              <div className="col-md-6 md-ps-3 sm-px-1">
                <div className="card p-3 mb-3">
                  <h6 className="text-dark fw-bold">Actual</h6>
                  <div className="row pt-3">
                    <div className="col-4 pb-3">
                      <p className="text-gray h-30">Company Name</p>
                      <p className="pt-1 text-dark fw-bold">N/A</p>
                    </div>

                    <div className="col-4 pb-3">
                      <p className="text-gray h-30">Executive Name</p>
                      <p className="pt-1 text-dark fw-bold">N/A</p>
                    </div>
                  </div>
                  <OwnAccordian heading={"Pickup Details"}>
                    <div className="row">
                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Distance</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>
                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Distance Payable</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>

                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Rate</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>
                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Charge</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>

                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Start Time</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>
                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">End Time</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>

                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Duration</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>
                    </div>
                  </OwnAccordian>
                  <OwnAccordian heading={"Delivery Details"}>
                    <div className="row">
                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Distance</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>
                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Distance Payable</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>

                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Rate</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>
                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Charge</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>

                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Start Time</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>
                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">End Time</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>

                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Duration</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>
                    </div>
                  </OwnAccordian>
                  <OwnAccordian heading={"Return Details"}>
                    <div className="row">
                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Distance</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>
                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Distance Payable</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>

                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Rate</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>
                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Charge</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>

                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Start Time</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>
                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">End Time</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>

                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Duration</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>
                    </div>
                  </OwnAccordian>
                </div>
              </div>

              {/* Difference */}
              <div className="col-md-6 md-pe-3 sm-px-1">
                <div className="card p-3 mb-3">
                  <h6 className="text-dark fw-bold">Difference</h6>
                  <div className="row pt-3">
                    <div className="col-4 pb-3">
                      <p className="text-gray h-30">Company Name</p>
                      <p className="pt-1 text-dark fw-bold">N/A</p>
                    </div>

                    <div className="col-4 pb-3">
                      <p className="text-gray h-30">Executive Name</p>
                      <p className="pt-1 text-dark fw-bold">N/A</p>
                    </div>
                  </div>
                  <OwnAccordian heading={"Pickup Details"}>
                    <div className="row">
                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Distance</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>
                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Distance Payable</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>

                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Rate</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>
                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Charge</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>

                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Start Time</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>
                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">End Time</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>

                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Duration</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>
                    </div>
                  </OwnAccordian>

                  <OwnAccordian heading={"Delivery Details"}>
                    <div className="row">
                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Distance</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>
                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Distance Payable</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>

                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Rate</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>
                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Charge</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>

                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Start Time</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>
                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">End Time</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>

                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Duration</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>
                    </div>
                  </OwnAccordian>

                  <OwnAccordian heading={"Return Details"}>
                    <div className="row">
                      <h6 className="text-dark pb-3"></h6>
                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Distance</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>
                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Distance Payable</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>

                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Rate</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>
                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Charge</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>

                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Start Time</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>
                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">End Time</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>

                      <div className="col-4 pb-3">
                        <p className="text-gray h-30">Duration</p>
                        <p className="pt-1 text-dark fw-bold">N/A</p>
                      </div>
                    </div>
                  </OwnAccordian>
                </div>
              </div>
            </div>
          </>
        )}

<div className="mb-2">
                    <div className="mt-2 card p-3">
                      <div className="py-1">
                      <div className="row px-0">
                          <div className="fw-bold text-black mb-3 fs-7 ps-0">
                          Seller Settlement
                          </div>
                          <div className="timeline-1">
                            <div className="d-flex">
                              <div className={order_data?.seller_settlement?.settlement_eligible?"timeline-dot active":"timeline-dot"}>
                                {order_data?.seller_settlement?.settlement_eligible && (
                                  <img
                                    src={
                                      AppConfig.CDN_Media_url +
                                      "be453854bc89e35e154efa5e26814afa.svg"
                                    }
                                    className="img-fluid"
                                    width={10}
                                  />
                                )}
                              </div>
                              <div className="w-100 ps-2">Settlement Eligible</div>
                            </div>
                            <div className="d-flex">
                              <div className="timeline-line-container"><div className={order_data?.seller_settlement?.settlement_approved?"timeline-line active":"timeline-line"}></div></div>
                              <div className="w-100"></div>
                            </div>
                            <div className="d-flex">
                              <div className={order_data?.seller_settlement?.settlement_approved?"timeline-dot active":"timeline-dot"}>
                                {order_data?.seller_settlement?.settlement_approved && (
                                  <img
                                    src={
                                      AppConfig.CDN_Media_url +
                                      "be453854bc89e35e154efa5e26814afa.svg"
                                    }
                                    className="img-fluid"
                                    width={10}
                                  />
                                )}
                              </div>
                              <div className="w-100 ps-2">Settlement Approved</div>
                            </div>
                            <div className="d-flex">
                              <div className="timeline-line-container"><div className={order_data?.seller_settlement?.settlement_paid?"timeline-line active":"timeline-line"}></div></div>
                              <div className="w-100"></div>
                            </div>
                            <div className="d-flex">
                              <div className={order_data?.seller_settlement?.settlement_paid?"timeline-dot active":"timeline-dot"}>
                                {order_data?.seller_settlement?.settlement_paid && (
                                  <img
                                    src={
                                      AppConfig.CDN_Media_url +
                                      "be453854bc89e35e154efa5e26814afa.svg"
                                    }
                                    className="img-fluid"
                                    width={10}
                                  />
                                )}
                              </div>
                              <div className="w-100 ps-2">
                                Settlement Paid
                                {order_data?.seller_settlement?.settlement_paid && (
                                  <div>
                                    <div>
                                      {order_data?.seller_settlement?.settlement_paid_time}
                                    </div>
                                    <div className="fw-500 fs-15">
                                    &#8377; {order_data?.seller_settlement?.settlement_paid_amount}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          
                        </div>
    </div>
    </div>
    </div>

  {order_data?.hasOwnProperty('pickup_drop')  && order_data?.pickup_drop.length>0 &&

order_data?.pickup_drop[0]?.delivery_partner[0]?.status_id =="10" &&
   ( <div className="mb-2">
                    <div className="mt-2 card p-3">
                      <div className="py-1">
                      <div className="row px-0">
      
                        <div className="fw-bold text-black mb-3 fs-7 ps-0">
                       PickupDrop Delivery Partner Settlement
                          </div>
                          <div className="timeline-1">
                            <div className="d-flex">
                              <div className={order_data?.pickup_drop[0]?.delivery_partner[0]?.settlement?.settlement_eligible? "timeline-dot active":"timeline-dot"}>
                                {order_data?.pickup_drop[0]?.delivery_partner[0]?.settlement?.settlement_eligible && (
                                  <img
                                    src={
                                      AppConfig.CDN_Media_url +
                                      "be453854bc89e35e154efa5e26814afa.svg"
                                    }
                                    className="img-fluid"
                                    width={10}
                                  />
                                )}
                              </div>
                              <div className="w-100 ps-2">Settlement Eligible</div>
                            </div>
                            <div className="d-flex">
                              <div className="timeline-line-container"><div className={order_data?.pickup_drop[0]?.delivery_partner[0]?.settlement?.settlement_approved?"timeline-line active":"timeline-line"}></div></div>
                              <div className="w-100"></div>
                            </div>
                            <div className="d-flex">
                              <div className={order_data?.pickup_drop[0]?.delivery_partner[0]?.settlement?.settlement_approved?"timeline-dot active":"timeline-dot"}>
                                {order_data?.pickup_drop[0]?.delivery_partner[0]?.settlement?.settlement_approved && (
                                  <img
                                    src={
                                      AppConfig.CDN_Media_url +
                                      "be453854bc89e35e154efa5e26814afa.svg"
                                    }
                                    className="img-fluid"
                                    width={10}
                                  />
                                )}
                              </div>
                              <div className="w-100 ps-2">Settlement Approved</div>
                            </div>
                            <div className="d-flex">
                              <div className="timeline-line-container"><div className={order_data?.pickup_drop[0]?.delivery_partner[0]?.settlement?.settlement_paid?"timeline-line active":"timeline-line"}></div></div>
                              <div className="w-100"></div>
                            </div>
                            <div className="d-flex">
                              <div className={order_data?.pickup_drop[0]?.delivery_partner[0]?.settlement?.settlement_paid?"timeline-dot active":"timeline-dot"}>
                                {order_data?.pickup_drop[0]?.delivery_partner[0]?.settlement?.settlement_paid && (
                                  <img
                                    src={
                                      AppConfig.CDN_Media_url +
                                      "be453854bc89e35e154efa5e26814afa.svg"
                                    }
                                    className="img-fluid"
                                    width={10}
                                  />
                                )}
                              </div>
                              <div className="w-100 ps-2">
                                Settlement Paid
                                {order_data?.pickup_drop[0]?.delivery_partner[0]?.settlement?.settlement_paid && (
                                  <div>
                                    <div>
                                      {order_data?.pickup_drop[0]?.delivery_partner[0]?.settlement?.settlement_paid_time}
                                    </div>
                                    <div className="fw-500 fs-15">
                                    &#8377; {order_data?.pickup_drop[0]?.delivery_partner[0]?.settlement?.settlement_paid_amount}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          
                        </div>
    </div>
    </div>
    </div>)

}
{order_data?.hasOwnProperty('shipments')  && order_data?.shipments.length>0 && order_data?.shipments[0]?.super_delivery_order[0].delivery_partner[0]?.settlement  &&

order_data?.shipments[0]?.super_delivery_order[0].delivery_partner[0]?.status_id =="10" &&
   ( <div className="mb-2">
                    <div className="mt-2 card p-3">
                      <div className="py-1">
                      <div className="row px-0">
      
                        <div className="fw-bold text-black mb-3 fs-7 ps-0">
                       Resturant Delivery Partner Settlement
                          </div>
                          <div className="timeline-1">
                            <div className="d-flex">
                              <div className={order_data?.shipments[0]?.super_delivery_order[0].delivery_partner[0]?.settlement?.settlement_eligible? "timeline-dot active":"timeline-dot"}>
                                {order_data?.shipments[0]?.super_delivery_order[0].delivery_partner[0]?.settlement?.settlement_eligible && (
                                  <img
                                    src={
                                      AppConfig.CDN_Media_url +
                                      "be453854bc89e35e154efa5e26814afa.svg"
                                    }
                                    className="img-fluid"
                                    width={10}
                                  />
                                )}
                              </div>
                              <div className="w-100 ps-2">Settlement Eligible</div>
                            </div>
                            <div className="d-flex">
                              <div className="timeline-line-container"><div className={order_data?.pickup_drop[0]?.delivery_partner[0]?.settlement?.settlement_approved?"timeline-line active":"timeline-line"}></div></div>
                              <div className="w-100"></div>
                            </div>
                            <div className="d-flex">
                              <div className={order_data?.shipments[0]?.super_delivery_order[0].delivery_partner[0]?.settlement?.settlement_approved?"timeline-dot active":"timeline-dot"}>
                                {order_data?.shipments[0]?.super_delivery_order[0].delivery_partner[0]?.settlement?.settlement_approved && (
                                  <img
                                    src={
                                      AppConfig.CDN_Media_url +
                                      "be453854bc89e35e154efa5e26814afa.svg"
                                    }
                                    className="img-fluid"
                                    width={10}
                                  />
                                )}
                              </div>
                              <div className="w-100 ps-2">Settlement Approved</div>
                            </div>
                            <div className="d-flex">
                              <div className="timeline-line-container"><div className={order_data?.shipments[0]?.super_delivery_order[0].delivery_partner[0]?.settlement?.settlement_paid?"timeline-line active":"timeline-line"}></div></div>
                              <div className="w-100"></div>
                            </div>
                            <div className="d-flex">
                              <div className={order_data?.shipments[0]?.super_delivery_order[0].delivery_partner[0]?.settlement?.settlement_paid?"timeline-dot active":"timeline-dot"}>
                                {order_data?.shipments[0]?.super_delivery_order[0].delivery_partner[0]?.settlement?.settlement_paid && (
                                  <img
                                    src={
                                      AppConfig.CDN_Media_url +
                                      "be453854bc89e35e154efa5e26814afa.svg"
                                    }
                                    className="img-fluid"
                                    width={10}
                                  />
                                )}
                              </div>
                              <div className="w-100 ps-2">
                                Settlement Paid
                                {order_data?.shipments[0]?.super_delivery_order[0].delivery_partner[0]?.settlement?.settlement_paid && (
                                  <div>
                                    <div>
                                      {order_data?.shipments[0]?.super_delivery_order[0].delivery_partner[0]?.settlement?.settlement_paid_time}
                                    </div>
                                    <div className="fw-500 fs-15">
                                    &#8377; {order_data?.shipments[0]?.super_delivery_order[0].delivery_partner[0]?.settlement?.settlement_paid_amount}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          
                        </div>
    </div>
    </div>
    </div>)

}

      </div>

      {/* Modal */}
      <AvailableDeliveryPartner
        open={available_delivery_partner_modal.is_open}
        data={available_delivery_partner_modal.data}
        on_assign={(data: any) => {
          SetAvailableDeliveryPartnerModal((prevalue) => {
            prevalue.is_open = false;
            return { ...prevalue };
          });
          let pass_data = {
            order_id: order_data.id,
            company_order_id: data.brand_id,
            delivery_partner_id: data.delivery_partner_id,
          };
          assign_delivery_partner(pass_data);
        }}
        close={(data: any) => {
          SetAvailableDeliveryPartnerModal((prevalue) => {
            prevalue.is_open = false;
            return { ...prevalue };
          });
        }}
      />

      <DeleteConfirmModal
        open={delete_confirm_modal_data.is_open}
        title={delete_confirm_modal_data.title}
        body={delete_confirm_modal_data.body}
        data={delete_confirm_modal_data.data}
        on_ok={(data: any) => {
          SetDeleteConfirmModalData((prevalue) => {
            prevalue.is_open = false;
            prevalue.title = "";
            prevalue.body = "";
            prevalue.data = {};
            return { ...prevalue };
          });
          if (data.id == "cancel_store_order") {
            let pass_data = data.pass_data;
            cancel_store_order(pass_data);
          }
          if (data.id == "delete_delivery_company_order") {
            let pass_data = data.pass_data;
            delete_delivery_company_order(pass_data);
          }
          if (data.id == "delete_delivery_partner_order") {
            let pass_data = data.pass_data;
            delete_delivery_partner_order(pass_data);
          }
          if (data.id == "delete_delivery_company_reservation") {
            let pass_data = data.pass_data;
            delete_delivery_company_reservation(pass_data);
          }
          if (data.id == "delete_delivery_partner_reservation") {
            let pass_data = data.pass_data;
            delete_delivery_partner_reservation(pass_data);
          }
        }}
        close={(data: any) => {
          SetDeleteConfirmModalData((prevalue) => {
            prevalue.is_open = false;
            prevalue.title = "";
            prevalue.body = "";
            prevalue.data = {};
            return { ...prevalue };
          });
        }}
      />
    </div>
  );
}
