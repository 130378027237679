"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.calculatePosition = calculatePosition;

var _updateTrace = require("./updateTrace");

var _resolveDirection = require("./resolveDirection");

var _calculateDuration = require("./calculateDuration");

var _calculateVelocity = require("./calculateVelocity");

var _types = require("../types");

function calculatePosition(state, options) {
  var start = state.start,
      x = state.x,
      y = state.y,
      traceX = state.traceX,
      traceY = state.traceY;
  var rotatePosition = options.rotatePosition,
      directionDelta = options.directionDelta;
  var deltaX = rotatePosition.x - x;
  var deltaY = y - rotatePosition.y;
  var absX = Math.abs(deltaX);
  var absY = Math.abs(deltaY);
  (0, _updateTrace.updateTrace)(traceX, deltaX);
  (0, _updateTrace.updateTrace)(traceY, deltaY);
  var directionX = (0, _resolveDirection.resolveDirection)(traceX, _types.Axis.X, directionDelta);
  var directionY = (0, _resolveDirection.resolveDirection)(traceY, _types.Axis.Y, directionDelta);
  var duration = (0, _calculateDuration.calculateDuration)(start, Date.now());
  var velocity = (0, _calculateVelocity.calculateVelocity)(absX, absY, duration);
  return {
    absX: absX,
    absY: absY,
    deltaX: deltaX,
    deltaY: deltaY,
    directionX: directionX,
    directionY: directionY,
    duration: duration,
    positionX: rotatePosition.x,
    positionY: rotatePosition.y,
    velocity: velocity
  };
}