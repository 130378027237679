import React, { useState, useEffect } from "react";

import bootstrap from "../../assets/libraries/bootstrap/js/bootstrap";

import makeAnimated from "react-select/animated";
import { api } from "../../utils/Api";

const animatedComponents = makeAnimated();

const franchise_initial_value = {
  cat_id: "",
  par_id: "",
  selected_roles: [],
  slug: "",

  cat_name: "",
  image_url: "",
};

let myModal: any = {};

export default function ModalEditCategory(props: any) {
  const [close_data, SetCloseData] = useState({
    action: "close",
  });

  const [franchise_data, SetFranchiseData]: any = useState(
    franchise_initial_value
  );
  const [user_role_options, SetUserRoleOptions] = useState({});
  const [text, setText] = useState("");
  const [page_no, SetPageNo] = useState(1);
  const [sector_id, SetSectorId] = useState("");


  const [modal_type, SetModalType] = useState("");

  const [slug_errors, SetSlugErrors] = useState(false);
  const [categories, setCategories] = useState([]);

  //FranchiseData End

  useEffect(() => {
    let myModal1 = document.getElementById("FranchiseUserModal");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      SetFranchiseData(franchise_initial_value);
      setText("");
      SetPageNo(1);
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {

      console.log(props?.data?.value?.sector_id)
      
      SetSectorId(props?.data?.value?.sector_id)
      myModal = new bootstrap.Modal(
        document.getElementById("FranchiseUserModal"),
        {}
      );
      myModal.show();
      SetModalType(props.type);
      get_roles();
      get_categories();

      if (props.type == "edit" || props.type == "view") {
        let set_data = {
          // user_id: props.data.value.user_id,
          cat_id: props.data.value.id,

          par_id: props.data.value.parent_id,
          selected_roles: {
            value: props.data.value.sector_id,
            label: props.data.value.sector_name,
          },
          slug: props.data.value.slug,

          cat_name: props.data.value.name,
          image_url: props.data.value.image,
        };
        SetFranchiseData(set_data);
      }
    }
  }, [props.open]);

  async function get_roles() {
    let data_res = await api("/orders/sectors");

    let role_set_value: any = [];
    data_res.response.sectors.map((role_item: any, role_index: any) => {
      role_set_value.push({
        label: role_item.name,
        value: role_item.id,
      });
    });
    SetUserRoleOptions(role_set_value);
  }

  async function get_categories() {
    let pass_data = {
      get: {
        non_hierarchy: 1,
      },
    };

    let data_res = await api("/orders/categories", pass_data);
    console.log(props?.data?.value?.sector_id)

    let desiredSector = data_res.response.categories.filter(sector => sector.sector_id == props?.data?.value?.sector_id);

    setCategories(desiredSector);
  }

  async function edit_category() {
    if (
      franchise_data.cat_name !== "" &&
      franchise_data.selected_roles !== ""
    ) {
      let pass_data: any = {
        post: {
          name: franchise_data.cat_name,
          sector: franchise_data.selected_roles?.value,
          category_id: franchise_data.cat_id,
          parent: franchise_data.par_id,
          slug: franchise_data.slug,
        },
      };
      if (franchise_data.image_url != "" && franchise_data.image_url != null) {
        pass_data.post.image_url = franchise_data.image_url;
      }
      let data_res = await api("/orders/edit_category", pass_data);
      if (data_res.status_code == 200) {
        let item: any = close_data;
        item.action = "edit";
        // item.value = data_res.response.brand_users[0];
        item.index = props.data.index;
        SetCloseData(item);
        SetFranchiseData(franchise_initial_value);
        myModal.hide();
      }
    }
  }

  function outlet_value_on_change(name: any, value: any) {
    SetFranchiseData((oldValues) => ({
      ...oldValues,
      [name]: value,
    }));
  }

  return (
    <div
      className="modal fade"
      id="FranchiseUserModal"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-fullscreen-sm-down">
        <div className="modal-content">
          {/* header */}
          <div className="modal-header">
            <h5 className="  modal-title" id="ModalLabel">
              {modal_type == "add"
                ? "Add User"
                : modal_type == "edit"
                ? "Choose Parent Category"
                : "View User"}
            </h5>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                let item = close_data;
                item.action = "close";
                SetCloseData(item);
                myModal.hide();
              }}
            ></button>
          </div>
          {/* body */}
          <div className="modal-body">
            <div>
              <div>
                {modal_type == "view" || "edit" ? (
                  <div className="row">
                    <div className="col-12 col-md-6 mt-2">
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        onChange={(e) => {
                          outlet_value_on_change("par_id", e.target.value);
                        }}
                      >
                        <option value="0">Choose as parent</option>
                        {categories?.map((ele, i) => {
                          return (
                            <option
                              value={ele.id}
                              selected={ele.id == franchise_data.par_id}
                            >
                              {ele.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light-outline"
              onClick={() => {
                edit_category();
              }}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
