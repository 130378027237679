import React, { useState, useEffect, useContext } from "react";

import { Link, useParams, } from "react-router-dom";
import { api } from "../../utils/Api";
import {
  Ionicons,
  MaterialIcons,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import AvailableDeliveryPartner from "./AvailableDeliveryPartner";
import DeliveryPartnerSubComponenent from "../../components/DeliveryPartnerSubComponent/DeliveryPartnerSubComponent";
import DeleteConfirmModal from "./DeleteConfirmModal";
import ModalOrderTracking from "./ModalOrderTracking";
import { FontAwesome } from "@expo/vector-icons";
import ModalDemoUser from "./ModalDemoUser";
import ModalSettleApproval from "./ModalSettleApproval";
import ModalSettlePaid from "./ModalSettlePaid";
import { Entypo} from "@expo/vector-icons";
import ModalUserDetails from "./ModalUserDetails";
import EligibleLogsModal from "../SellerSettlements/EligibleLogsModal";
import { useNavigate } from 'react-router-dom';
import AmountModal from "../SellerSettlements/AmountModal";
import ConfirmModal from "../SellerSettlements/ConfirmModal";
import toast, { Toaster } from "react-hot-toast";
import StarRatings from "react-star-ratings";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { AppConfig } from "../../config";
import {

  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import ModalSettleEligible from "./ModalSettleEligible";
import ModalSettleUpdate from "./ModalSettleEligible";
let url_location: any = window.location.host;
let splited_url = url_location.split(".");
export default function OrderDetails1(props: any) {
  const [id_value, SetIdValue] = useState(useParams());
  const [order_data, SetOrderData]: any = useState({});
  const [loader, setLoader] = useState(false);
  const [price,setPrice] = useState("")
  const [eligible_price,setEligiblePrice] = useState("")
  const [confirm_modal, setConfirmModal] = useState({
    is_open: false,
    data: {},
});
const [amount_modal, setAmountModal] = useState({
  is_open: false,
  data: {},
});

  const [order_tracking_modal, SetOrderTrackingModal] = useState({
    is_open: false,
    lat: "",
    lon: "",
    id: "",
    order_details : {},
    delivery_id: "",
    delivery_index: "",
    shipment_index:"",

  });
  const [note, setNote] = useState('');
  const [eligible_note, setEligibleNote] = useState('');
 const [paid_note,setPaidNote] =useState("");
  const [image_base_url, SetImageBaseUrl] = useState("");
  const [available_delivery_partner_modal, SetAvailableDeliveryPartnerModal] =
    useState({
      is_open: false,
      data: {},
    });
    const [user_modal, SetUserModal] = useState({
      is_open: false,
     id:""
    });
    const [log_modal, setLogModal] = useState({
      is_open: false,
      data: {},
  });
  const [delete_confirm_modal_data, SetDeleteConfirmModalData] = useState({
    is_open: false,
    data: {},
    title: "",
    body: "",
  });

  const [is_resta_product_open, SetIsRestaProductOpen] = useState(false);
  const [set_order_details_loading, SetOrderDetailsLoading] = useState(true);
  const [demo_modal, setDemoModal] = useState({
    is_open: false,
    data: {},
  });
  const [set_ele_modal, setSetEleModal] = useState({
    is_open: false,
    data: {},
  });
  const [settle_update_modal, setSettleUpdateModal] = useState({
    is_open: false,
    data: {},
  });


  const [set_approv_modal, setApprovModal] = useState({
    is_open: false,
    data: {},
  });
  const [paid_modal, setPaidModal] = useState({
    is_open: false,
    data: {},
  });

  const [eligible,setEligible] = useState(false)
  const [eligibled,setEligibled] = useState(false)


  const [approve,setApprove] = useState(false)

  const [paid,setPaid] = useState(false)
  const [eligible_paid,setEligiblePaid] = useState(false)

  const [pay,setPay] = useState(false)
  const [update,setUpdate] = useState(false)
  const [eligible_pay,setEligiblePay] = useState(false)
  const [eligible_update,setEligibleUpdate] = useState(false)

  const [input_show,setInputShow] = useState(true)
  const [eligible_input_show,setEligibleInputShow] = useState(true)



  const navigate = useNavigate();



  useEffect(() => {
    console.log(props)
    get_order_detail(id_value.ord_id,false);
  }, [props.id]);

  const handleSubmit =  async (event) => {
    event.preventDefault();
    console.log('Submitted text:', note);
    // Add further actions here (e.g., sending text to a server)

     let pass_data = {
      post: {
       
        order_id: order_data.id,
        user_type: "private",
        message: note
      
      },
    };
    let response: any = await api("/orders/add_internal_notes", pass_data);
        if (response.status_code == 200) {
      setLoader(false)
      SetOrderDetailsLoading(false);
      setNote("")
      let order_value = response.response.data[0];
      if (response.response.data.length > 0) {
        SetOrderData(order_value);
      }
    }
  };

  async function call_func(id: any, del_id: any) {
    let pass_data = {
      get: {
       
        order_id: id,
        callto: del_id,
      
      },
    };
    let data_res: any = await api("/orders/call", pass_data);
    if ((data_res.status_code = 200)) {
      if (data_res?.response?.phone_number) {
        // window.open("tel:" + data_res?.response?.phone_number);
        document.location.href = "tel:" + data_res?.response?.phone_number;
      }
    }
  }
  async function call_del_func(id: any, del_id: any,d_id:any) {
    let pass_data = {
      get: {
       
        order_id: id,
        callto: del_id,
        agent_order_id:d_id
      },
    };
    let data_res: any = await api("/orders/call", pass_data);
    if ((data_res.status_code = 200)) {
      if (data_res?.response?.phone_number) {
        // window.open("tel:" + data_res?.response?.phone_number);
        document.location.href = "tel:" + data_res?.response?.phone_number;

      }
    }
  }
  function priceOnchange(e){
    setPrice(e.target.value)
  }
 
  async function settlementUpdate(p:any) {
    if(p?.order_id){
        let pass_data:any = {
          post: {
            status: p.status,
            order_id: p.order_id
          }
        };
        if(p.hasOwnProperty('amount')){
            pass_data.post.amount = p.amount
        }
        if(p.hasOwnProperty('message')){
            pass_data.post.notes = p.message
        }
        let data_res;
        if(p.action =="eligible"){
            data_res = await api("/orders/update_seller_settlement_eligible", pass_data);
        }else if(p.action =="approved"){
            data_res = await api("/orders/update_seller_settlement_approved", pass_data);
        }else if(p.action =="paid") {
            data_res = await api("/orders/update_seller_settlement_paid", pass_data);
        }
        if (data_res.status_code == 200) {
            let res:any = data_res.response;
            console.log('res', res);
            SetOrderData(res?.data[0])
            // if(res?.data[0]?.id){
            //     SetOrderData((prev:any) => {
            //         prev[p.key] = res?.data[0]
            //         return [...prev];
            //     });
            // }

            // setSelectedOrders([]);
            // setSelectedOrdersObj({});
            // setFirstAction('');
            // setSettlementAmount();
            // setSettlementNotes('')
            // setOrderTotalAmount(0)
            // setThirdCard(false);
            // setSecondCard(false);
        }
    }
}

  async function get_order_detail(get_data: any,ref:any) {
    {ref &&
      setLoader(true)
    }
    if(!ref){
    SetOrderDetailsLoading(true);
    }
    let pass_data = {
      get: {
        order_id: props.id != undefined ? props.id:get_data,
      },
    };
    let response: any = await api("/orders/order_details", pass_data);
    if (response.status_code == 200) {
      setLoader(false)
      SetOrderDetailsLoading(false);
      let order_value = response.response.data[0];
      if (response.response.data.length > 0) {
        SetOrderData(order_value);
        setPrice( order_value.seller_settlement.hasOwnProperty("settlement_eligible_amount") &&
        order_value.seller_settlement.settlement_eligible_amount != 0 && order_value.seller_settlement.settlement_eligible_amount !=null &&
        order_value.seller_settlement.settlement_eligible_amount)
        setEligiblePrice( order_value.seller_settlement.settlement_paid? order_value.seller_settlement.settlement_paid_amount :  order_value.amount)
        setEligible( order_value?.seller_settlement?.settlement_eligible)
        setEligibled(order_value?.seller_settlement?.settlement_eligible)
        setApprove( order_value?.seller_settlement?.settlement_approved)
     setPay(order_value?.seller_settlement?.settlement_paid)
     setEligiblePay(order_value?.seller_settlement?.settlement_paid)
        setPaid( order_value?.seller_settlement?.settlement_paid)
        setEligiblePaid( order_value?.seller_settlement?.settlement_paid)



        // SetTimelineAccrData((prevalue: any) => {
        //   prevalue["status_timeline"] = false;

        //   if (order_value.sector_id == 4) {
        //     if (order_value.pickup_drop.length > 0) {
        //       prevalue["pickup_drop_status_timeline"] = false;
        //     }
        //   }
        //   return { ...prevalue };
        // });
      }
      // SetNextPage(response.response.next_page);
      SetImageBaseUrl(response.response.image_base_url);
    }
  }

  async function delete_delivery_company_order(get_data: any) {
    let pass_data = {
      post: {
        id: get_data.id,
      },
    };

    let response: any = await api("/orders/delete_company_order", pass_data);
    if (response.status_code == 200) {
      get_order_detail(id_value.ord_id,false);
    }
  }

  async function delete_delivery_partner_order(get_data: any) {
    let pass_data = {
      post: {
        id: get_data.id,
      },
    };

    let response: any = await api(
      "/orders/delete_delivery_partner_order",
      pass_data
    );
    if (response.status_code == 200) {
      get_order_detail(id_value.ord_id,false);
    }
  }
  const Spinner = () => <div className="loader-spinner"></div>;


  async function delete_delivery_company_reservation(get_data: any) {
    let pass_data = {
      post: {
        id: get_data.id,
      },
    };

    let response: any = await api(
      "/orders/delete_company_reservation",
      pass_data
    );
    if (response.status_code == 200) {
      get_order_detail(id_value.ord_id,false);
    }
  }

  async function delete_delivery_partner_reservation(get_data: any) {
    let pass_data = {
      post: {
        id: get_data.id,
      },
    };

    let response: any = await api(
      "/orders/delete_delivery_partner_reservation",
      pass_data
    );
    if (response.status_code == 200) {
      get_order_detail(id_value.ord_id,false);
    }
  }

  async function assign_delivery_partner(get_data: any) {
    let pass_data = {
      post: {
        order_id: get_data.order_id,
        company_order_id: available_delivery_partner_modal.data.comp_ord_id,
        delivery_partner_id: get_data.delivery_partner_id,
      },
    };
    let response: any = await api("/orders/assign_delivery_partner", pass_data);
    if (response.status_code == 200) {
      get_order_detail(id_value.ord_id,false);
    }
  }

  async function cancel_store_order(get_data: any) {
    console.log(get_data.id)
    let pass_data = {
      post: {
        id:  get_data.id,
       
      },
    };
    let response: any = await api("/orders/cancel_store_order", pass_data);
    if (response.status_code == 200) {
      get_order_detail(get_data.id,false);
    }
  }

  function openMap(dir_lat: any, dir_lng: any) {
    window.open(
     "https://www.google.com/maps?q=" + dir_lat + "," + dir_lng,
    "_blank"
    );

    // document.location.href =   "https://www.google.com/maps?q=" + dir_lat + "," + dir_lng,
    // "_blank"
  }

  function generate_address(get_data: any) {
    let return_data: any = "";
    if (get_data.door_no != "") {
      return_data = return_data + get_data.door_no + ", ";
    }
    if (get_data.address != "") {
      return_data = return_data + get_data.address + ", ";
    }
    if (get_data.city != "") {
      return_data = return_data + get_data.city + ", ";
    }
    if (get_data.state != "") {
      return_data = return_data + get_data.state + ", ";
    }
    if (get_data.country != "") {
      return_data = return_data + get_data.country + " - ";
    }
    if (get_data.pincode != "") {
      return_data = return_data + get_data.pincode + ".";
    }

    return return_data;
  }

  function sec_to_min(seconds: any) {
    let result = new Date(seconds * 1000).toISOString().slice(11, 19);
    return result;
  }

  async function activate_order(get_data: any) {
    let pass_data = {
      post: {
        order_id: get_data.id,
      },
    };
    let response: any = await api("/orders/activate_store_order", pass_data);
    if (response.status_code == 200) {
      let pass_data = {
        order_id: id_value.ord_id,
      };
      get_order_detail(pass_data.order_id,false);
    }
  }

  const OwnAccordian = (acc_props: any) => {
    const [is_open, SetIsopen] = useState(false);
    return (
      <>
        {acc_props.from == "shipment" ? (
          <>
            <div
              className="d-flex pt-3"
              onClick={() => {
                SetIsopen(!is_open);
              }}
            >
              <h6 className="text-dark ">{acc_props.heading}</h6>

              {is_open ? (
                <MaterialIcons
                  name="keyboard-arrow-down"
                  size={24}
                  color="black"
                />
              ) : (
                <MaterialIcons
                  name="keyboard-arrow-up"
                  size={24}
                  color="black"
                />
              )}
            </div>
            <div className="">{is_open ? acc_props.children : null}</div>
          </>
        ) : (
          <>
            <div
              className="d-flex pt-3"
              onClick={() => {
                SetIsopen(!is_open);
              }}
            >
              <h6 className="text-dark ">{acc_props.heading}</h6>
              <div className="ms-auto">
                {is_open ? (
                  <MaterialIcons
                    name="keyboard-arrow-down"
                    size={24}
                    color="black"
                  />
                ) : (
                  <MaterialIcons
                    name="keyboard-arrow-up"
                    size={24}
                    color="black"
                  />
                )}
              </div>
            </div>
            <div className="">{is_open ? acc_props.children : null}</div>
          </>
        )}{" "}
      </>
    );
  };

  function eligibleOnChange(val){
    if(val==true){
    // setEligible(false)
    // saveEligible(false)

    const set_data = {
      value: order_data.id,
      dm: false,
      from:"details",
      det: "eligible"
    
      
    };
    setSetEleModal({
      is_open: true,
      data: set_data,
    });
    }else{
      setEligible(true)
      setEligibleInputShow(true)
      setEligiblePaid(true)
      // saveEligible(true)

    }
  }
  function approveOnChange(val){
    if(val==true){
    // setApprove(false)
    // saveApprove(false)
    const set_data = {
      value: order_data.id,
      dm: false,
      from:"details",
      det: "approved"
    
      
    };
    setSetEleModal({
      is_open: true,
      data: set_data,
    });

    }else{
      setApprove(true)
    saveApprove(true)


    }
  }

  function paidOnChange(val){
    if(val==true){
      const set_data = {
        value: order_data.id,
        dm: false,
        from:"details",
        det: "paid"
      
        
      };
      setSetEleModal({
        is_open: true,
        data: set_data,
      });
    }else{
      setPaid(true)

    }
  }

  async function saveEligible(val:any) {
    setEligible(val)
console.log(eligible_note,eligible_price)
    let pass_data = {
    
      post: {

        status: val?"1":"2",
        order_id: id_value.ord_id,
        notes: eligible_note,
        amount:eligible_price
    
      }
    };
    console.log(pass_data)
    console.log(id_value)

    // let data_res;
    // if(props.data.from =="ship"){
    //  data_res = await api("/deliverypartners/update_settlement_eligible", pass_data);
    // } else if(props.data.from =="cod"){
    //   data_res = await api("/deliverypartners/update_partner_cod_eligible_orders", pass_data);
    //  }else{
     let data_res = await api("/orders/update_seller_settlement_eligible", pass_data);
    // }
  console.log(data_res)
    if (data_res.status_code == 200) {
setEligibled(data_res.response.data[0].seller_settlement.settlement_eligible)
        setEligibleUpdate(false)
        setEligibleInputShow(false)
        setEligiblePay(data_res.response.data[0].seller_settlement?.settlement_eligible)
      SetOrderData(data_res.response.data[0])
      setPrice( data_res.response.data[0].seller_settlement.settlement_eligible_amount )
  
    // let item = close_data;
    // console.log(item)
    // item.action = "save";
    // item.value =  data_res.response.data.cod_allowed_all_users;
    // if(props.data.from =="details"){
    // item.value =  data_res.response.data[0];
    // }

    // SetCloseData(item);
  
    // myModal.hide();
    }
  }

  async function saveApprove(val) {
    setApprove(val)

    let pass_data = {
    
      post: {

        status: val?"1":"2",
        order_id: id_value.ord_id
    
      }
    };
    console.log(pass_data)
    // let data_res;
    // if(props.data.from =="ship"){
    //  data_res = await api("/deliverypartners/update_settlement_eligible", pass_data);
    // } else if(props.data.from =="cod"){
    //   data_res = await api("/deliverypartners/update_partner_cod_eligible_orders", pass_data);
    //  }else{
    //  let data_res = await api("/orders/update_seller_settlement_eligible", pass_data);

     let data_res = await api("/orders/update_seller_settlement_approved", pass_data);
    // }
  console.log(data_res)
    if (data_res.status_code == 200) {
    // let item = close_data;
    // console.log(item)
    // item.action = "save";
    // item.value =  data_res.response.data.cod_allowed_all_users;
    // if(props.data.from =="details"){
    // item.value =  data_res.response.data[0];
    // }

    // SetCloseData(item);
  
    // myModal.hide();
    }
  }

  async function Save(val:any) {
setPaid(val)
    let pass_data = {
    
      post: {

        status: val?"1":"2",
        order_id: id_value.ord_id,
        amount: price,
        notes:paid_note
      }
    };
    console.log(pass_data)
    // let data_res;
    // if(props.data.from =="ship"){
    //  data_res = await api("/deliverypartners/update_settlement_paid", pass_data);
    // } else if(props.data.from =="cod"){
    //   data_res = await api("/deliverypartners/update_partner_cod_paid_orders", pass_data);
    //  }else{
     let data_res = await api("/orders/update_seller_settlement_paid", pass_data);
    // }
  console.log(data_res)
    if (data_res.status_code == 200) {

      setUpdate(false)
      setInputShow(false)
      setPay(data_res.response.data[0].seller_settlement?.settlement_paid)
    SetOrderData(data_res.response.data[0])


    }
  }
  return (
    <div className="">
      {/* header */}
      <Toaster/>
      {props.id == undefined &&
      <div className="row align-items-center head-bar">
        <div className="col-md-10 col-10 sm-mb-2 ps-0">
          <div className="d-flex align-items-center">
            <div
              className="cursor mx-2"
              onClick={() => {
                // window.history.go(-1);
                // navigate("/orders")
                // navigate(-1);
                history.back()
                // if (window.history.length > 1) {
                //   navigate(-1); // Go back
                // }
              }}
            >
              <MaterialCommunityIcons
                name="arrow-left"
                size={18}
                color="black"
              />
            </div>
            <h5 className="  mb-0">Order Details </h5>
          
          </div>
        
        </div>
        <div className="col-md-2 col-2 end p-0 ">
                                  {/* {d_ele.live_tracking == true && ( */}
                                    <div className="d-flex w-100">
                                      {/* <div
                                        className="text-prime mx-auto w-50 cursor"
                                        onClick={() => {
                                          SetOpenTimeline((prevalue: any) => {
                                            let index_value;
                                            index_value = prevalue.findIndex(
                                              (ele: any) => ele == d_ele.id
                                            );
                                            if (index_value != -1) {
                                              prevalue.splice(index_value, 1);
                                            } else {
                                              prevalue.push(d_ele.id);
                                            }
                                            return [...prevalue];
                                          });
                                        }}
                                      >
                                        {/* <MaterialIcons
                                            name="timeline"
                                            size={20}
                                            color={check_timeline_open(d_ele) ? "#3f41c3" : "#555"}
                                          /> 
                                        <div className="text-center w-100">
                                          <img
                                            src={require("../../../assets/icon/timeline.png")}
                                            className="delivery mx-auto "
                                          />
                                          <p className="extras-small pt-1 text-darkgray text-center">
                                            Timeline
                                          </p>
                                        </div>
                                      </div> */}

{loader?
         <button  className="btn btn-white dropdown-toggle d-flex align-items-cetner ms-auto">
        <Spinner />
        </button>:
                                      <button  className="btn btn-white dropdown-toggle d-flex align-items-cetner ms-auto"
           onClick={()=>{
            get_order_detail(id_value.ord_id,true)}}> 
           <img
                src={require("../../assets/icon/refresh.png")}
                className="icon-20px"
              /></button>
           }
                                    </div>
                                  {/* )} */}
                                </div>
      </div>
}
      {/* body */}
      <div className="mb-5 pt-3 sm-pt-4 sm-mt-6 mt-0">
        {/* Skeleton */}
        {set_order_details_loading ? (
          <div className="row">
           <div className={props.id != undefined ?"col-12":"col-md-4 col-sm-6 col-12 sm-ps-3 md-ps-3"}>
              <div className="card pt-3 px-3 pb-1 mb-3">
                <div className="d-flex align-items-center ">
                  <Skeleton height={10} width={90} />
                  <div className="ms-auto d-flex align-items-center">
                    <Skeleton height={30} width={80} borderRadius={5} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <Skeleton height={10} width={30} />
                    <div className="d-flex align-items-center pt-2">
                      <Skeleton height={18} width={18} />
                      <div className="ps-2">
                        <Skeleton height={10} width={90} />
                      </div>
                    </div>
                  </div>
                  <div className="col-6 text-end">
                    <div className="pb-2">
                      <Skeleton height={10} width={90} />
                    </div>
                    <div className="">
                      <Skeleton height={10} width={90} />
                    </div>
                  </div>
                </div>

                <div className=" pt-2">
                  <hr className="text-gray m-0" />
                </div>

                <div className="d-flex pb-2">
                  <Skeleton height={18} width={18} />
                  <div className="ps-2">
                    <Skeleton height={8} width={60} />
                    <Skeleton height={10} width={120} />
                  </div>
                </div>

                <div className="d-flex pb-2">
                  <Skeleton height={18} width={18} />
                  <div className="ps-2">
                    <Skeleton height={8} width={60} />
                    <Skeleton height={10} width={120} />
                  </div>
                </div>
              </div>

              <div className="card pt-3 px-3 pb-1 mb-3">
                <Skeleton height={10} width={90} />
                <div className="row pt-2">
                  <div className="col-8 d-flex">
                    <Skeleton height={18} width={18} />
                    <div className="ps-2">
                      <Skeleton height={10} width={30} />
                      <div className="pt-1">
                        <Skeleton height={10} width={90} />
                      </div>
                    </div>
                  </div>

                  <div className="col-4 row align-items-center p-0">
                    <div className="col-6 border-right cursor">
                      <Skeleton height={20} width={20} />
                    </div>
                    <div className="col-6 end cursor">
                      <Skeleton height={20} width={20} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="card pt-3 px-3 pb-1 mb-3">
                <Skeleton height={10} width={90} />
                <div className="d-flex pt-2">
                  <Skeleton height={18} width={18} />
                  <div className="ps-2">
                    <Skeleton height={10} width={90} />
                    <div className="pt-1">
                      <Skeleton height={10} width={90} />
                    </div>
                  </div>
                </div>
                <div className="d-flex pt-2">
                  <Skeleton height={15} width={15} />
                  <div className="ps-2">
                    <Skeleton height={10} width={90} />
                  </div>
                </div>
              </div>

              <div className="card pt-3 px-3 pb-1 mb-3">
                <Skeleton height={10} width={90} />
                <div className="d-flex pt-3">
                  <Skeleton height={18} width={18} />
                  <div className="ps-2">
                    <Skeleton height={10} width={90} />
                    <div className="pt-1">
                      <Skeleton height={10} width={90} />
                    </div>
                  </div>
                </div>

                <div className="d-flex pt-3">
                  <Skeleton height={18} width={18} />
                  <div className="ps-2">
                    <Skeleton height={10} width={90} />
                    <div className="pt-1">
                      <Skeleton height={10} width={90} />
                    </div>
                  </div>
                </div>

                <div className="d-flex pt-3">
                  <Skeleton height={18} width={18} />
                  <div className="ps-2">
                    <Skeleton height={10} width={90} />
                    <div className="pt-1">
                      <Skeleton height={10} width={90} />
                    </div>
                  </div>
                </div>

                {order_data.sector_id != 4 && (
                  <div className="row pt-3 border-top">
                    <div className="col-6 ">
                      <Skeleton height={10} width={50} />
                      <div className="pt-1">
                        <Skeleton height={10} width={90} />
                      </div>
                    </div>
                    <div className="col-6 ">
                      <Skeleton height={10} width={50} />
                      <div className="pt-1">
                        <Skeleton height={10} width={90} />
                      </div>
                    </div>
                    <div className="col-6 ">
                      <Skeleton height={10} width={50} />
                      <div className="pt-1">
                        <Skeleton height={10} width={90} />
                      </div>
                    </div>
                    <div className="col-6 ">
                      <Skeleton height={10} width={50} />
                      <div className="pt-1">
                        <Skeleton height={10} width={90} />
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="card pt-3 px-3 pb-1 mb-3">
                <Skeleton height={10} width={120} />
                <div className="row pt-3">
                  <div className="col-6">
                    <Skeleton height={10} width={90} />
                  </div>
                  <div className="col-6 text-end">
                    <Skeleton height={10} width={50} />
                  </div>
                </div>

                <div className="row pt-2">
                  <div className="col-6">
                    <Skeleton height={10} width={100} />
                  </div>
                  <div className="col-6 text-end">
                    <Skeleton height={10} width={50} />
                  </div>
                </div>

                <div className="row pt-2">
                  <div className="col-6">
                    <Skeleton height={10} width={110} />
                  </div>
                  <div className="col-6 text-end">
                    <Skeleton height={10} width={50} />
                  </div>
                </div>

                <div className="row pt-2">
                  <div className="col-6">
                    <Skeleton height={10} width={115} />
                  </div>
                  <div className="col-6 text-end">
                    <Skeleton height={10} width={50} />
                  </div>
                </div>

                <div className="row pt-2">
                  <div className="col-6">
                    <Skeleton height={10} width={120} />
                  </div>
                  <div className="col-6 text-end">
                    <Skeleton height={10} width={50} />
                  </div>
                </div>

                <div className="row pt-2">
                  <div className="col-6">
                    <Skeleton height={10} width={125} />
                  </div>
                  <div className="col-6 text-end">
                    <Skeleton height={10} width={50} />
                  </div>
                </div>
                <div className="row border-top pt-3">
                  <div className="col-6">
                    <Skeleton height={10} width={150} />
                  </div>
                  <div className="col-6 text-end">
                    <Skeleton height={10} width={50} />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-12 px-0 sm-px-1">
              <div className="card pt-3 px-3 pb-1 mb-3">
                <div className="d-flex align-items-center ">
                  <Skeleton height={10} width={90} />
                  <div className="ms-auto">
                    <Skeleton height={30} width={90} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-1">
                    <Skeleton height={18} width={18} />
                  </div>
                  <div className="ps-2 col-11">
                    <div className="pb-2 border-bottom">
                      <Skeleton height={10} width={90} />
                      <div className="pt-1">
                        <Skeleton height={10} width="100%" count={2} />
                      </div>
                    </div>

                    <div className="pt-2 d-flex align-items-center">
                      <div>
                        <Skeleton height={10} width={90} />
                        <div className="pt-1">
                          <Skeleton height={10} width={90} />
                        </div>
                      </div>
                      <div className="ms-auto">
                        <Skeleton height={30} width={30} borderRadius={50} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card pt-3 px-3 pb-1 mb-3">
                <div className="d-flex align-items-center ">
                  <Skeleton height={10} width={90} />
                  <div className="ms-auto">
                    <Skeleton height={30} width={90} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-1">
                    <Skeleton height={18} width={18} />
                  </div>
                  <div className="ps-2 col-11">
                    <div className="pb-2 border-bottom">
                      <Skeleton height={10} width={90} />
                      <div className="pt-1">
                        <Skeleton height={10} width="100%" count={2} />
                      </div>
                    </div>

                    <div className="pt-2 d-flex align-items-center">
                      <div>
                        <Skeleton height={10} width={90} />
                        <div className="pt-1">
                          <Skeleton height={10} width={90} />
                        </div>
                      </div>
                      <div className="ms-auto">
                        <Skeleton height={30} width={30} borderRadius={50} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {order_data.sector_id == 4 && (
                <div className="card pt-3 px-3 pb-1 mb-3">
                  <Skeleton height={10} width={90} />
                  <div className="pt-3">
                    <Skeleton height={10} width={80} />
                    <div className="pt-1">
                      <Skeleton height={10} width={120} />
                    </div>
                  </div>
                  <div className="pt-3">
                    <Skeleton height={10} width={80} />
                    <div className="pt-1">
                      <Skeleton height={10} width={120} />
                    </div>
                  </div>
                  <div className="pt-3">
                    <Skeleton height={10} width={80} />
                    <div className="pt-1">
                      <Skeleton height={10} width={120} />
                    </div>
                  </div>
                </div>
              )}

              {order_data.sector_id != 4 && (
                <div className="card pt-3 px-3 pb-1 mb-3">
                  <Skeleton height={10} width={90} />
                  <div className="pt-3 row align-items-start">
                    <div className="col-7">
                      <div className="pb-2">
                        <Skeleton height={10} width={60} />
                      </div>
                      <Skeleton height={10} width={90} />
                    </div>
                    <div className="col-5 end">
                      <Skeleton height={10} width={80} />
                    </div>
                  </div>
                  <div className="pt-3">
                    <Skeleton height={10} width={120} />
                  </div>

                  <div className="">
                    <div className="pt-2 d-flex align-items-cetner cursor">
                      <Skeleton height={10} width={200} />
                      <div className="ms-auto">
                        <Skeleton height={10} width={10} />
                      </div>
                    </div>

                    <div className="pt-2">
                      <Skeleton height={10} width={90} />
                      <div className="pt-1">
                        <Skeleton height={10} width="100%" count={2} />
                      </div>
                      <div className="pt-2">
                        <Skeleton height={10} width={90} />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="card pt-3 px-3 pb-1 mb-3">
                {/* <TimelineAccordian> */}
                <div className="">
                  <Skeleton height={10} width={90} />
                  <ul className="delivery_status_vertical-skeleton w-100 pt-2">
                    {[1, 2, 3].map(() => (
                      <li>
                        <time className="extra-small text-end text-gray">
                          <Skeleton height={5} width={30} />
                        </time>
                        <div className="d-flex timeline">
                          <div className="pe-1">
                            <Skeleton
                              height={30}
                              width={30}
                              borderRadius={50}
                            />
                          </div>
                          <div>
                            <Skeleton height={10} width={90} />
                            <div className="pb-1">
                              <Skeleton height={8} width={50} />
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                {/* </TimelineAccordian> */}
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-12 md-pe-3  sm-px-1">
              <div className="card pt-3 px-3 pb-1 mb-3">
                <Skeleton height={10} width={90} />
                <div className="pt-3 row">
                  {[1, 2, 3, 4, 5, 6, 7, 8].map(() => (
                    <div className="col-md-4 col-6 mb-3">
                      <div className="pb-2">
                        <Skeleton height={10} width={90} />
                      </div>
                      <div className="pt-2">
                        <Skeleton height={10} width={30} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="card pt-3 px-3 pb-1 mb-3">
                <Skeleton height={10} width={90} />
                <div>
                  <div className="row pt-3">
                    <div className="col-6">
                      <Skeleton height={10} width={90} />
                    </div>
                    <div className="col-6 end">
                      <Skeleton height={10} width={50} />
                    </div>
                  </div>
                  <div className="row pt-3">
                    {[1, 2, 3].map(() => (
                      <div className="col-md-4 col-6 border-right d-flex ">
                        <Skeleton height={18} width={18} />
                        <div className="ps-2">
                          <Skeleton height={10} width={40} />
                          <div className="pt-1">
                            <Skeleton height={10} width={90} />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="py-2">
                    <Skeleton height={10} width={90} />
                    <div className="pt-1">
                      <Skeleton height={10} width={150} />
                    </div>
                  </div>
                  <div className="d-flex align-items-center pt-4">
                    <Skeleton height={30} width={80} />

                    <div className="ms-auto">
                      <Skeleton height={30} width={50} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="card pt-3 px-3 pb-1 mb-3">
                {/* <TimelineAccordian> */}
                <div className="">
                  <Skeleton height={10} width={90} />
                  <ul className="delivery_status_vertical-skeleton w-100 pt-2">
                    {[1, 2, 3].map(() => (
                      <li>
                        <time className="extra-small text-end text-gray">
                          <Skeleton height={5} width={30} />
                        </time>
                        <div className="d-flex timeline">
                          <div className="pe-1">
                            <Skeleton
                              height={30}
                              width={30}
                              borderRadius={50}
                            />
                          </div>
                          <div>
                            <Skeleton height={10} width={90} />
                            <div className="pb-1">
                              <Skeleton height={8} width={50} />
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                {/* </TimelineAccordian> */}
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="row">
              <div className={props.id != undefined ?"col-12":"col-md-4 col-sm-6 col-12 sm-px-1 md-ps-3"}>
                <div className="card p-2 mb-3">
                  <div className="d-flex align-items-center ">
                    <h6 className="fw-bold">Order Details</h6>
                    <div className="ms-auto d-flex align-items-center">
                      {(order_data.status_id != 4 &&
                        order_data.status_id != 5 &&
                        order_data.status_id != 6 &&
                        order_data.status_id != 3
                      ) && (
                        <button
                          className="btn btn-prime btn-sm "
                          onClick={() => {
                            // let pass_data = {
                            //     id: ""
                            // }
                            // cancel_store_order(pass_data);
                            SetDeleteConfirmModalData((prevalue) => {
                              prevalue.is_open = true;
                              prevalue.title = "Cancel Confirmation";
                              prevalue.body = "Confirm Cancel Store Order";
                              prevalue.data = {
                                id: "cancel_store_order",
                                pass_data: {
                                  id: id_value.ord_id,
                                },
                              };
                              return { ...prevalue };
                            });
                          }}
                        >
                          Cancel Order
                        </button>
                      )}
                      {!order_data.active ? (
                        <div className="ms-2">
                          <button
                            className="btn btn-blue btn-sm ms-auto "
                            onClick={() => {
                              activate_order(order_data);
                            }}
                          >
                            Accept
                          </button>
                        </div>
                      ) : null}

{/* { !order_data.dm_order && */}
                    
{/* } */}

                    </div>
                  </div>
                </div>
                {order_data.sector_id != 4 && (
                   order_data?.shipments?.length != 0 &&
                                                    <div className="card pt-3 px-3 pb-1 mb-3">

                                       
                                  
                                                <OwnAccordian
                                                  heading={"Timeline Details"}
                                                >
                                                  <ul className="delivery_status_vertical w-100 pt-2">
                                                  {order_data?.order_timeline?.map((t_ele: any) => (
                              <li>
                                <time className="">{t_ele.time_formatted}</time>
                                <div className="timeline ">
                                  <div className="d-flex align-items-center">
                                    {t_ele.type == "delivery_partner" ? (
                                      <img
                                        src={require("../../assets/icon/delivery-boy.png")}
                                        className="w-10 mx-1"
                                        height="16"
                                        width="16"
                                      />
                                    ) : null}
                                    {t_ele.type == "shipment" ? (
                                      <img
                                        src={require("../../assets/icon/cutlery.png")}
                                        className="w-10 mx-1"
                                        height="16"
                                        width="16"
                                      />
                                    ) : null}
                                    <p className="text-gray small ps-1">
                                      {t_ele.status}
                                    </p>

                                    <div className="w-20 center ms-auto">
                                                          {t_ele.hasOwnProperty(
                                                            "lat"
                                                          ) && (
                                                            <button
                                                              type="button"
                                                              className="btn btn-prime btn-small px-3"
                                                              onClick={() => {
                                                                openMap(
                                                                  t_ele?.lat,
                                                                  t_ele?.lng
                                                                );
                                                              }}
                                                            >
                                                              <span className="small">
                                                                Map
                                                              </span>
                                                            </button>
                                                          )}
                                                        </div>
                                  </div>
                                </div>
                              </li>
                            ))}
                                                  </ul>
                                                </OwnAccordian>
                                           
                                         </div> )}
                                   
                                  
                                    {order_data.sector_id == 4 && (
                                        order_data?.pickup_drop?.length != 0 &&
                                                <div className="card pt-3 px-3 pb-1 mb-3">

<OwnAccordian
                                                  heading={"Timeline Details"}
                                                >
                                                  <ul className="delivery_status_vertical w-100 pt-2">
                                                  {order_data?.order_timeline?.map((t_ele: any) => (
                              <li>
                                <time className="">{t_ele.time_formatted}</time>
                                <div className="timeline ">
                                  <div className="d-flex align-items-center">
                                    {t_ele.type == "delivery_partner" ? (
                                      <img
                                        src={require("../../assets/icon/delivery-boy.png")}
                                        className="w-10 mx-1"
                                        height="16"
                                        width="16"
                                      />
                                    ) : null}
                                    {t_ele.type == "shipment" ? (
                                      <img
                                        src={require("../../assets/icon/cutlery.png")}
                                        className="w-10 mx-1"
                                        height="16"
                                        width="16"
                                      />
                                    ) : null}
                                    <p className="text-gray small ps-1">
                                      {t_ele.status}
                                    </p>

                                    <div className="w-20 center ms-auto">
                                                          {t_ele.hasOwnProperty(
                                                            "lat"
                                                          ) && (
                                                            <button
                                                              type="button"
                                                              className="btn btn-prime btn-small px-3"
                                                              onClick={() => {
                                                                openMap(
                                                                  t_ele?.lat,
                                                                  t_ele?.lng
                                                                );
                                                              }}
                                                            >
                                                              <span className="small">
                                                                Map
                                                              </span>
                                                            </button>
                                                          )}
                                                        </div>
                                  </div>
                                </div>
                              </li>
                            ))}
                                                  </ul>
                                                </OwnAccordian>
                                      </div>
                                    )}
                                    
                <div className="card pt-3 px-3 pb-1 mb-3">
                  <div className="row">
                    <div className="col-6">
                      <p className="text-dark fw-bold pb-2 extra-small">
                        #{order_data.id}
                      </p>
                      <div className="d-flex align-items-center">
                        <p className="text-gray text-end extra-small pb-2">
                          {order_data.date}
                        </p>
                      </div>
                      <div>
                              Order ID:{" "}
                              <span className="fw-500">{order_data?.store_order_id}</span>
                            </div>
                    </div>
                    <div className="col-6">
                      <div className="end">
                        {/* <div
                          className={
                            order_data.status == "Completed"
                              ? "color-dot-green"
                              : order_data.status == "Cancelled" ||
                                order_data.status == "Rejected"
                              ? "color-dot-red"
                              : "color-dot-blue"
                          }
                        ></div>
                        <p
                          className={
                            order_data.status == "Completed"
                              ? "text-green ps-2"
                              : order_data.status == "Cancelled" ||
                                order_data.status == "Rejected"
                              ? "text-red ps-2"
                              : "text-blue ps-2"
                          }
                        >
                          {order_data.status}
                        </p> */}

                        <FontAwesome
                                name="circle"
                                size={10}
                                color={
                                  // ord_ele.status == "Accepted"
                                  //   ? "blue"
                                  //   : ord_ele.status == "Completed"
                                  //   ? "green"
                                  //   : ord_ele.status == "Order Placed"
                                  //   ? "orange"
                                  //   : "red"
                                  order_data.status_color
                                }
                              />
                              <p
                                // className={
                                //   ord_ele.status == "Accepted"
                                //     ? "text-blue ps-2"
                                //     : ord_ele.status == "Completed"
                                //     ? "text-green ps-2"
                                //     : ord_ele.status == "Order Placed"
                                //     ? "text-orange ps-2"
                                //     : "text-red ps-2"
                                // }
                                className="ps-2"
                                style={{color:order_data.status_color}}
                              >
                                {order_data.status}
                              </p>

           
                      </div>
                      <div className="end">
                      {order_data.dm_order &&
                                  <span
                                      className="badge-1 me-2 badge-blue mt-2"
                                      >Demo</span>
}
                    </div>
                    </div>

                  </div>
                </div>

                <div className="card pt-3 px-3 pb-1 mb-3">
                  <div className="row ">
                    <div className="col-md-6 col-6 border-right d-flex ">
                      <img
                        src={require("../../assets/icon/pay.png")}
                        className="type-img"
                      />
                      <div className="ps-2">
                        <p className="text-gray">Sector</p>
                        <p className="pt-1 text-dark fw-bold">
                          {order_data?.sector == "Restaurant"
                            ? "Food"
                            : order_data?.sector}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6 col-6 border-right d-flex  pe-0">
                      <img
                        src={require("../../assets/icon/truck.png")}
                        className="type-img"
                      />
                      <div className="ps-2">
                        <p className="text-gray">Delivery Type</p>
                        <p className="pt-1 text-dark fw-bold">
                          {order_data?.delivery_type}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-2 card pt-3 px-2 shadow">
                  <fieldset className="mb-2">
                    <legend className="legendLeft">Store Details</legend>

                    <div className="pt-3 px-3 pb-1">
                      <div className="row pt-2">
                        <div className="col-11 d-flex">
                          <img
                            src={require("../../assets/icon/store.png")}
                            className="type-img"
                          />
                          <div className="ps-2">
                            <p className="extra-small text-gray">
                              #{order_data?.store_order_id}
                            </p>
                            <p className="pt-1 text-dark fw-bold">
                              {order_data?.store_name}
                            </p>
                          </div>
                        </div>
                        {/* {order_data.customer?.pickup_address.mobile ? ( */}
                        <div className="col-1 row align-items-center p-0">
                          <div className="ms-auto">
                            <div className="call-btn"
                               onClick={() => {
                              
                                call_del_func(
                                  order_data.id,
                                "outlet",
                               
                                );
                              }}>
                              
                              <Ionicons
                                name="md-call"
                                size={18}
                                color="#A80032"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className=" px-3 pb-1">
                      <div className="col-md-6 col-6 d-flex ">
                        <img
                          src={require("../../assets/icon/pay.png")}
                          className="type-img"
                        />
                        <div className="ps-2">
                          <p className="text-gray">Section</p>
                          <p className="pt-1 text-dark fw-bold">
                            {order_data?.section_name}
                          </p>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>

                <div className="mt-2 card pt-3 px-2 shadow">
                  <fieldset className="mb-2">
                    <legend className="legendLeft">Customer Details</legend>
                    <div className="pt-3 px-3 pb-1">
                      <div className="d-flex pt-2">
                        <img
                          src={require("../../assets/icon/profile.png")}
                          className="type-img"
                        />
                        <div className="ps-2">
                          <p className="text-dark fw-bold">
                            {order_data.customer?.name}  #{order_data?.customer?.user_id}
                          </p>
                          <p className="pt-1 extra-small">
                            @{order_data.customer?.username}
                          </p>
                        </div>

                        
                        <button className="btn btn-primary ms-auto"
           onClick={() => {
            if (props.id === undefined) {
              SetUserModal((prevValue: any) => ({
                ...prevValue,
                is_open: true,
                id: order_data.customer.user_id,
              }));
            } else {
              props.sendMessageToParent(order_data.customer.user_id);
            }
          }}>View Customer User Details</button>
                      </div>
                      <hr />
                      <div className="row ">
                        <div className="col-11 d-flex">
                          <div className="d-flex pt-2">
                            <img
                              src={require("../../assets/icon/phone.png")}
                              className="icon-15px"
                            />
                            <div className="ps-2">
                              <p className="text-dark fw-bold">
                                {order_data.customer?.mobile}
                              </p>
                            </div>
                          </div>
                        </div>

                        {/* {order_data.customer?.pickup_address.mobile ? ( */}
                        <div className="col-1 row align-items-center p-0">
                          <div className="ms-auto">
                          {/* <a href={"tel:" +order_data.customer?.delivery_address.mobile} style={{ textDecoration: 'none' }}> */}
                            <div className="call-btn"
                                 onClick={() => {
                              
                                  call_func(
                                    order_data.id,
                                  "customer"
                                  );
                                }}
                            >
                              <Ionicons
                                name="md-call"
                                size={18}
                                color="#A80032"

                           
                              />
                            </div>
                            {/* </a> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>

                {/* Seller Details */}
                {order_data.sector_id != 4 && (
                  <div className="mt-2 card pt-3 px-2 shadow">
                    <fieldset className="mb-2">
                      <legend className="legendLeft">Seller Details</legend>
                      <div className="pt-3 px-3 pb-1">
                        <div className="d-flex pt-2">
                          <img
                            src={require("../../assets/icon/store.png")}
                            className="type-img"
                          />
                          <div className="ps-2">
                            <p className="extra-small">Brand Name</p>
                            <p className=" pt-1 text-dark fw-bold">
                              {order_data.seller?.brand_name}
                            </p>
                          </div>
                        </div>

                        <div className="d-flex ">
                          <img
                            src={require("../../assets/icon/outlet.png")}
                            className="type-img"
                          />
                          <div className="ps-2">
                            <p className="extra-small">Outlet Name</p>
                            <p className=" pt-1 text-dark fw-bold">
                              {order_data.seller?.outlet?.name}
                            </p>
                          </div>
                        </div>

                        {/* <div className="d-flex pt-3">
                      <img
                        src={require("../../assets/icon/indian-rupee.png")}
                        className="type-img"
                      />
                      <div className="ps-2">
                        <p className="extra-small">COD</p>
                        <p className=" pt-1 small text-dark fw-bold">
                          {order_data?.seller?.outlet?.cod
                            ? "Available"
                            : "Not Available"}
                        </p>
                      </div>
                    </div> */}

                        <div className="border-top row">
                     
                          <div className="pt-3 px-3 pb-1">
                            
                              {order_data?.seller?.contacts?.map((ele,i)=>(
                                

                              
                            <div className="row mb-2">

                              <div className="col-11 d-flex">
                                <div className="d-flex pt-2">
                                 
                                   <img
                                   src={
                                    ele.profile != null
                                    ? ele.profile :
                                    require("../../assets/icon/profile.png")}
                                   className="type-img"
                                 />

                              
                                 

                                  <div className="ps-2">
                                    {/* <p className="extra-small">Primary</p> */}
                                    <p className=" pt-1 text-dark fw-bold">
                                      {ele.name}
                                    
                                    </p>
                                    <p className=" pt-1 text-dark fw-bold">
                                      {ele.mobile}
                                    
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-1 row align-items-center p-0">
                                <div className="ms-auto">
                                <a href={"tel:" +ele.mobile} style={{ textDecoration: 'none' }}>
                                  <div className="call-btn"
                                  // onClick={() => {
                              
                                  //   call_func(
                                  //     order_data.id,
                                  //   "outlet"
                                  //   );
                                  // }}
                                  >
                                    <Ionicons
                                      name="md-call"
                                      size={18}
                                      color="#A80032"
                                    />
                                  </div>
                                  </a>
                                </div>
                              </div>

                              {/* <hr className="mt-2" />

                              <div className="col-11 d-flex">
                                <div className="d-flex pt-2">
                                  <img
                                    src={require("../../assets/icon/profile.png")}
                                    className="type-img"
                                  />

                                  <div className="ps-2">
                                    <p className="extra-small">Secondary</p>
                                    <p className=" pt-1 text-dark fw-bold">
                                      Lokesh
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-1 row align-items-center p-0">
                                <div className="ms-auto">
                                  <div className="call-btn">
                                    <Ionicons
                                      name="md-call"
                                      size={18}
                                      color="#A80032"
                                    />
                                  </div>
                                </div>
                              </div> */}
                            </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                )}

                <div className="mt-2 card pt-3 px-2 shadow">
                  <fieldset className="mb-2">
                    <legend className="legendLeft">Order Summary</legend>
                    <div className="pt-3 px-3 pb-1">
                      <div className="row pt-2">
                        <p className="text-gray col-6">Amount </p>
                        <p className="text-dark col-6 text-end">
                          ₹ {order_data?.amount}{" "}
                        </p>
                      </div>
                      <div className="row pt-2">
                        <p className="text-gray col-6">Gst Amount </p>
                        <p className="text-dark col-6 text-end">
                          ₹ {order_data?.gst_amount}
                        </p>
                      </div>

                      <div className="row pt-2">
                        <p className="text-gray col-6">Total Amount </p>
                        <p className="text-dark col-6 text-end">
                          ₹ {order_data?.total_amount}
                        </p>
                      </div>

                      <div className="row pt-2">
                        <p className="text-gray col-6">Delivery Charges </p>
                        <p className="text-dark col-6 text-end">
                          ₹ {order_data?.delivery_charges}
                        </p>
                      </div>

                      <div className="row pt-2">
                        <p className="text-gray col-6">Convenience Fee (%) </p>
                        <p className="text-dark col-6 text-end">
                          {order_data?.convenience_fee_percent}
                        </p>
                      </div>

                      <div className="row pb-2 pt-2">
                        <p className="text-gray col-6">Convenience Fee </p>
                        <p className="text-dark col-6 text-end">
                          ₹ {order_data?.convenience_fee}
                        </p>
                      </div>

                      <div className="row border-top py-2">
                        <p className="text-dark fw-bold col-6 fs-7">
                          Total Amount Payable{" "}
                        </p>
                        <p className="text-dark fw-bold col-6 text-end fs-7">
                          ₹ {order_data?.total_amount_payable}
                        </p>
                      </div>
                    </div>
                  </fieldset>
                </div>
                <div className="mt-2 card pt-3 px-2 shadow">
                <div className="pt-3 px-3 pb-1">
                <button
                            className="btn btn-blue btn-sm ms-2 "
                            onClick={() => {
                              const set_data = {
                                    value: order_data.id,
                                    dm: order_data?.dm_order
                                  
                                    
                                  };
                                  setDemoModal({
                                    is_open: true,
                                    data: set_data,
                                  });
                            }}
                          >
                            Update Demo Order
                          </button>
                          </div>

                              
                    <div className="pt-3 px-3 pb-1">
                    <a
                          className="btn btn-dark btn-radius btn-sm px-3 ms-2 w-50"
                          href={
                            splited_url[0] == "1sdev"
                              ? "https://1sdev.sellers.1superapp.com/brand/" +
                              order_data?.seller?.brand_id +
                              "/dashboard"
                              : "https://sellers.1superapp.com/brand/" +
                              order_data?.seller?.brand_id +
                              "/dashboard"
                          } 
                          target="_blank"
                          >
                            Go To Seller
                          </a>
                    </div>
                    <div className="pt-3 px-3 pb-1">
                    <a
                          className="btn btn-dark btn-radius btn-sm px-3 ms-2 w-50"
                          href={
                            splited_url[0] == "1sdev"
                              ? "https://1sdev.sellers.1superapp.com/outlet/" +
                              order_data?.seller?.brand_id + "/" +
                              order_data?.seller?.outlet?.outlet_id +
                              "/dashboard"
                              : "https://sellers.1superapp.com/outlet/" +
                              order_data?.seller?.brand_id + "/" +
                              order_data?.seller?.outlet?.outlet_id +
                              "/dashboard"
                          } 
                          target="_blank"
                          >
                            Go To Seller Outlet
                          </a>
                    </div>
                    <div className="pt-3 px-3 pb-1">
                    <a
                          className="btn btn-dark btn-radius btn-sm px-3 ms-2 w-50"
                          href={
                            splited_url[0] == "1sdev"
                              ? "https://1sdev.sellers.1superapp.com/outlet/" +
                              order_data?.seller?.brand_id + "/" +
                              order_data?.seller?.outlet?.outlet_id +
                              "/order_details/" + order_data?.id
                              : "https://sellers.1superapp.com/outlet/" +
                              order_data?.seller?.brand_id + "/" +
                              order_data?.seller?.outlet?.outlet_id +
                              "/order_details/" + order_data?.id
                          } 
                          target="_blank"
                          >
                            Go To Seller Outlet Order Details
                          </a>
                    </div>
                  
              </div>
                 
              </div>

         <div className={props.id != undefined ?"col-12":"col-md-4 col-sm-6 col-12 px-0 sm-px-1"}>
                <div className="mt-2 card pt-3 px-2 shadow">
                  <fieldset className="mb-2">
                    <legend className="legendLeft">Payment</legend>
                    <div className="pt-3 px-3 pb-1">
                      <div className="d-flex pt-2">
                        <img
                          src={require("../../assets/icon/store.png")}
                          className="type-img"
                        />
                        <div className="ps-2">
                          <p className="extra-small">Method</p>
                          <p className=" pt-1 text-dark fw-bold">
                            {order_data?.payment_details?.method}
                          </p>
                        </div>
                      </div>

                      <div className="d-flex pt-3">
                        <img
                          src={require("../../assets/icon/outlet.png")}
                          className="type-img"
                        />
                        <div className="ps-2">
                          <p className="extra-small">Mode</p>
                          <p className=" pt-1 text-dark fw-bold">
                            {order_data?.payment_details?.mode}
                          </p>
                        </div>
                      </div>
                      <div className="d-flex pt-3">
                        <img
                          src={require("../../assets/icon/outlet.png")}
                          className="type-img"
                        />
                        <div className="ps-2">
                          <p className="extra-small">Payable</p>
                          <p className=" pt-1 text-dark fw-bold">
                            ₹ {order_data?.payment_details?.payable}
                          </p>
                        </div>
                      </div>
                      <div className="d-flex pt-3">
                        <img
                          src={require("../../assets/icon/outlet.png")}
                          className="type-img"
                        />
                        <div className="ps-2">
                          <p className="extra-small">Paid</p>
                          <p className=" pt-1 text-dark fw-bold">
                            ₹ {order_data?.payment_details?.paid}
                          </p>
                        </div>
                      </div>
                      <div className="d-flex pt-3">
                        <img
                          src={require("../../assets/icon/outlet.png")}
                          className="type-img"
                        />
                        <div className="ps-2">
                          <p className="extra-small">Pending</p>
                          <p className=" pt-1 text-dark fw-bold">
                            ₹ {order_data?.payment_details?.pending}
                          </p>
                        </div>
                      </div>
                      <div className="d-flex pt-3">
                        <img
                          src={require("../../assets/icon/outlet.png")}
                          className="type-img"
                        />
                        <div className="ps-2">
                          <p className="extra-small">Status</p>
                          <p className=" pt-1 text-dark fw-bold">
                            {order_data?.payment_details?.status}
                          </p>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
                {order_data.sector_id == 4 && (
                  <div>
                    <div className="card pt-3 px-3 pb-1 mb-3 mt-2 ">
                      <div className="card pt-3 px-3 pb-1 mb-2">
                        <h6 className="fw-bold">Package Details</h6>
                        <div className="pt-3">
                          <p className="extra-small text-gray">
                            Package Content
                          </p>
                          <p className="pt-1 text-dark fw-bold">
                            {order_data.package_content}
                          </p>
                        </div>
                        <div className="pt-3">
                          <p className="extra-small text-gray">Package Value</p>
                          <p className="pt-1 text-dark fw-bold">
                            {" "}
                            {order_data.package_value_currency_symbol}{" "}
                            {order_data.package_value_amount}
                          </p>
                        </div>
                        <div className="pt-3">
                          <p className="extra-small text-gray">Instructions</p>
                          <p className="pt-1 text-dark fw-bold">
                            {order_data.instructions}
                          </p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center pb-2">
                        <div className="card pt-3 px-3 pb-1 mb-2">
                          <div className="d-flex align-items-center pb-2">
                            <h6 className="fw-bold">Pickup Location</h6>
                            <div className="ms-auto">
                              <button
                                className="btn btn-prime btn-small "
                                onClick={() => {
                                  openMap(
                                    order_data?.customer?.pickup_address?.lat,
                                    order_data?.customer?.pickup_address.lng
                                  );
                                }}
                              >
                                <span className="small">Map</span>
                              </button>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-2">
                              <img
                                src={require("../../assets/icon/up-arrow.png")}
                                className="type-img"
                              />
                            </div>
                            <div className="ps-2 col-10">
                              <div className="pb-2 border-bottom">
                                <p className="text-dark fw-bold"></p>
                                <p className="small text-gray pt-1">
                                  {order_data.customer?.pickup_address
                                    .door_no &&
                                  order_data.customer?.pickup_address.address
                                    ? generate_address(
                                        order_data.customer?.pickup_address
                                      )
                                    : order_data.customer?.pickup_address
                                        .map_address}
                                </p>
                              </div>

                              <div className="pt-2 d-flex align-items-center">
                                <div>
                                  <p className="text-dark fw-bold">
                                    {" "}
                                    {
                                      order_data.customer?.pickup_address
                                        ?.firstname
                                    }{" "}
                                    {
                                      order_data.customer?.pickup_address
                                        ?.lastname
                                    }
                                  </p>
                                  <p className="text-black pt-1">
                                    {order_data.customer?.pickup_address.mobile}
                                  </p>
                                </div>
                                {order_data.customer?.pickup_address.mobile ? (
                                  <div className="ms-auto">
                                           <a href={"tel:" +order_data.customer?.pickup_address.mobile} style={{ textDecoration: 'none' }}>
                                    <div className="call-btn"
                                    
                                    // onClick={() => {
                              
                                    //   call_func(
                                    //     order_data.id,
                                    //   "customer_pickup"
                                    //   );
                                    // }}
                                    >
                                      <Ionicons
                                        name="md-call"
                                        size={18}
                                        color="#A80032"
                                      />
                                    </div>
                                    </a>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card pt-3 px-3 pb-1 mb-2">
                        <div className="d-flex align-items-center pb-2">
                          <h6 className="fw-bold">Drop Location</h6>
                          <div className="ms-auto">
                            <button
                              className="btn btn-prime btn-small "
                              onClick={() => {
                                openMap(
                                  order_data?.customer?.delivery_address?.lat,
                                  order_data?.customer?.delivery_address?.lng
                                );
                              }}
                            >
                              <span className="small">Map</span>
                            </button>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-2">
                            <img
                              src={require("../../assets/icon/up-arrow.png")}
                              className="type-img"
                            />
                          </div>
                          <div className="ps-2 col-10">
                            <div className="pb-2 border-bottom">
                              <p className="text-dark fw-bold"></p>
                              <p className="small text-gray">
                                {order_data.customer?.delivery_address
                                  .door_no &&
                                order_data.customer?.delivery_address.address
                                  ? generate_address(
                                      order_data.customer?.delivery_address
                                    )
                                  : order_data.customer?.delivery_address
                                      .map_address}
                              </p>
                            </div>

                            <div className="pt-2 d-flex align-items-center">
                              <div>
                                <p className="text-dark fw-bold">
                                  {
                                    order_data.customer?.delivery_address
                                      ?.firstname
                                  }{" "}
                                  {
                                    order_data.customer?.delivery_address
                                      ?.lastname
                                  }
                                </p>
                                <p className="text-black pt-1">
                                  {order_data.customer?.delivery_address.mobile}
                                </p>
                              </div>
                              {order_data.customer?.delivery_address.mobile ? (
                                <div className="ms-auto">
                                           <a href={"tel:" +order_data.customer?.delivery_address.mobile} style={{ textDecoration: 'none' }}>

                                  <div className="call-btn"
                                    onClick={() => {
                              
                                      call_func(
                                        order_data.id,
                                      "customer_delivery"
                                      );
                                    }}
                                  >
                                    <Ionicons
                                      name="md-call"
                                      size={18}
                                      color="#A80032"
                                    />
                                  </div>
                                </a>

                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>

                      {order_data.sector_id == 4 && (
                        <div className="">
                          {order_data?.pickup_drop?.map((item: any,p_in:any) => (
                            <>
                              <div className="card px-3 pb-1 ">
                                <OwnAccordian
                                  heading={
                                    <div 
                                    // className="px-3"
                                    >
                                      <b>Delivery Company Details</b>
                                      <div className="d-flex">
                                      <div >
                                        <p className="text-dark fw-bold pb-2 mt-3 extra-small">
                                          #{item.id}
                                        </p>
                                        <div className="d-flex align-items-center">
                                          {/* <img
                      src={require("../../assets/icon/Delivery.png")}
                      className="type-img"
                    /> */}
                                          <p className="text-gray text-end extra-small  pb-2">
                                            {item.time}
                                          </p>
                                        </div>
                                        <div className="d-flex mb-2">
                                          <img
                                            src={require("../../assets/icon/store.png")}
                                            className="type-img"
                                          />
                                          <div className="ps-2">
                                            <p className="text-gray">Name</p>
                                            <p className="pt-1 text-dark fw-bold">
                                              {item.brand_name}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="ms-auto">
                                        <div className="end mt-3">
                                          <div
                                            className={
                                              item.status == "Completed"
                                                ? "color-dot-green"
                                                : item.status == "Cancelled" ||
                                                  item.status == "Rejected"
                                                ? "color-dot-red"
                                                : "color-dot-blue"
                                            }
                                          ></div>
                                          <p
                                            className={
                                              item.status == "Completed"
                                                ? "text-green ps-2"
                                                : item.status == "Cancelled" ||
                                                  item.status == "Rejected"
                                                ? "text-red ps-2"
                                                : "text-blue ps-2"
                                            }
                                          >
                                            {item.status}
                                          </p>
                                        </div>

                                        <div className="d-flex align-items-center pt-4">
                                        {(item.status_id == 1 ||
                                          item.status_id == 2) && (
                                          <button
                                            className="btn btn-blue btn-small"
                                            onClick={() => {

                                              if (props.id === undefined) {
                                              SetAvailableDeliveryPartnerModal(
                                                (prevalue) => {
                                                  prevalue.is_open = true;
                                                  prevalue.data = {
                                                    ord_id: props.id != undefined ? props.id:id_value.ord_id,
                                                    comp_ord_id: item.id,
                                                  };
                                                  return { ...prevalue };
                                                }
                                              );
                                      }else{
                                              props.sendMessageToParent({ord_id: props.id,comp_ord_id:item.id});

                                            }}}
                                          >
                                            <span className="small">
                                              Change Delivery Partner
                                            </span>
                                          </button>
                                        )}
                                    
                                      </div>
                                      </div>
                                      </div>
                                    </div>
                                  }
                                >
                                  {/* <OwnAccordian from={"shipment"} heading=    {   */}
                                  <>
                                    <div className="  px-3  pb-2">
                                      <div className="col-md-4 col-6  d-flex  pe-0">
                                        <img
                                          src={require("../../assets/icon/truck.png")}
                                          className="type-img"
                                        />
                                        <div className="ps-2">
                                          <p className="text-gray">
                                            Service Type
                                          </p>
                                          <p className="pt-1 text-dark fw-bold">
                                            {item.service_name}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="col-md-4 col-6  d-flex ">
                                        <img
                                          src={require("../../assets/icon/location.png")}
                                          className="type-img"
                                        />
                                        <div className="ps-2">
                                          <p className="text-gray">Zone</p>
                                          <p className="pt-1 text-dark fw-bold">
                                            {item.zone_name}
                                          </p>
                                        </div>
                                      </div>

                                      <div className="py-2">
                                        <p className="text-gray">
                                          Zone Description
                                        </p>
                                        <p className="pt-1 text-dark">
                                          {item.zone_description}
                                        </p>
                                        {/* </div> */}
                                      </div>
                                      {(item.status_id == 1 ||
                                          item.status_id == 2) && (
                                          <div className="ms-2">
                                            <button
                                              className=" btn btn-outline-prime btn-sm"
                                              onClick={() => {
                                                // let pass_data = {
                                                //     id: ""
                                                // }
                                                // delete_delivery_company_order(pass_data);

                                                SetDeleteConfirmModalData(
                                                  (prevalue) => {
                                                    prevalue.is_open = true;
                                                    prevalue.title =
                                                      "Delete Confirmation";
                                                    prevalue.body =
                                                      "Confirm Delete Company Order";
                                                    prevalue.data = {
                                                      id: "delete_delivery_company_order",
                                                      pass_data: {
                                                        id: item.id,
                                                      },
                                                    };
                                                    return { ...prevalue };
                                                  }
                                                );
                                              }}
                                              disabled={true}
                                            >
                                              <span className="fw-bold small">
                                                Delete
                                              </span>
                                            </button>
                                          </div>
                                        )}
                                      {/* Estimation */}

                                      <DeliveryPartnerSubComponenent
                                        data={order_data?.order_estimation}
                                        from={"Delivery Company Estimation"}
                                      />

                                      <h6 className="fw-bold">
                                        Delivery Details
                                      </h6>
                                      <div className="pt-3 row">
                                        <div className="col-md-4 col-6 mb-2">
                                          <p className="small  h-35 text-gray">
                                            Forward Distance
                                            <p className="text-dark fw-bold ">
                                              {
                                                order_data?.delivery_details
                                                  ?.forward_distance_kms
                                              }{" "}
                                              Km
                                            </p>
                                          </p>
                                        </div>
                                        <div className="col-md-4 col-6 mb-2">
                                          <p className="small  h-35 text-gray">
                                            Return Distance
                                            <p className="text-dark fw-bold ">
                                              {
                                                order_data?.delivery_details
                                                  ?.return_distance_kms
                                              }{" "}
                                              Km
                                            </p>
                                          </p>
                                        </div>
                                        <div className="col-md-4 col-6 mb-2">
                                          <p className="small h-35 text-gray">
                                            Total Distance
                                            <p className="text-dark fw-bold ">
                                              {
                                                order_data?.delivery_details
                                                  ?.total_distance_kms
                                              }{" "}
                                              Km
                                            </p>
                                          </p>
                                        </div>

                                        <div className="col-md-4 col-6 mb-2">
                                          <p className="small  h-35 text-gray">
                                            Forward Distance Amount
                                            <p className="text-dark fw-bold ">
                                              ₹{" "}
                                              {
                                                order_data?.delivery_details
                                                  ?.forward_distance_amount
                                              }
                                            </p>
                                          </p>
                                        </div>
                                        <div className="col-md-4 col-6 mb-2">
                                          <p className="small  h-35 text-gray">
                                            Return Distance Amount
                                            <p className="text-dark fw-bold ">
                                              ₹{" "}
                                              {
                                                order_data?.delivery_details
                                                  ?.return_distance_amount
                                              }
                                            </p>
                                          </p>
                                        </div>

                                        <div className="col-md-4 col-6 mb-2">
                                          <p className="small pb-2 h-35 text-gray">
                                            Total Distance Amount
                                            <p className="text-dark fw-bold ">
                                              ₹{" "}
                                              {
                                                order_data?.delivery_details
                                                  ?.total_distance_amount
                                              }
                                            </p>
                                          </p>
                                        </div>
                                        <div className="col-md-4 col-6 mb-2">
                                          <p className="small  h-35 text-gray">
                                            Collected Delivery Charges
                                            <p className="text-dark fw-bold ">
                                              ₹{" "}
                                              {
                                                order_data?.delivery_details
                                                  ?.collected_delivery_charges
                                              }
                                            </p>
                                          </p>
                                        </div>

                                        <div className="col-md-4 col-6 mb-2">
                                          <p className="small  h-35 text-gray">
                                            Delivery Partner Amount
                                            <p className="text-dark fw-bold ">
                                              ₹{" "}
                                              {
                                                order_data?.delivery_details
                                                  ?.delivery_partner_amount
                                              }
                                            </p>
                                          </p>
                                        </div>
                                       
                                      </div>
                                    </div>
                                    <div className="card pt-3 px-3 pb-1 mb-3">
                                      <OwnAccordian
                                        heading={"Delivery company log"}
                                      >
                                        {/* <time className="extra-small text-end text-gray">
{time_ele?.time_formatted}
</time> */}

                                        <ul className="delivery_status_vertical w-100 pt-3">
                                          {item?.status_timeline?.map(
                                            (ele: any) => (
                                              <li>
                                                <time className="extra-small text-end text-gray">
                                                  {ele?.time_formatted}
                                                </time>
                                                <div className="d-flex timeline">
                                                  {ele?.user.profile ? (
                                                    <img
                                                      src={ele?.user.profile}
                                                      className="me-1 user-img-timeline"
                                                    />
                                                  ) : (
                                                    <img
                                                      src={require("../../assets/img/profile.png")}
                                                      className="me-1 user-img-timeline"
                                                    />
                                                  )}
                                                  <div>
                                                    <p className="text-dark pb-1">
                                                      {ele?.status}
                                                    </p>
                                                    {ele?.user.profile !=
                                                      "" && (
                                                      <p className="text-gray">
                                                        {" "}
                                                        {ele?.user.name}
                                                      </p>
                                                    )}
                                                  </div>
                                                </div>
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      </OwnAccordian>
                                    </div>
                                  </>
                                </OwnAccordian>
                              </div>

                           
                                  <div>
                                    {item?.delivery_partner?.map(
                                      (ele: any, dp_ele_index: any) => (
                                        <>
                                          <div className="card pt-3 px-3 pb-1 mb-3">
                                            <OwnAccordian
                                              heading={
                                                <div className="row">
                                                  <b>
                                                    {`Delivery Partner Details  #` +
                                                      (dp_ele_index + 1)}
                                                  </b>
                                                  <div className="col-7">
                                                    <p className="text-dark fw-bold pb-2 mt-3 extra-small">
                                                      #{ele.id}
                                                    </p>
                                                    <div className="d-flex align-items-center">
                                                      <p className="text-gray text-end extra-small  pb-2">
                                                        {ele.date_time}
                                                      </p>
                                                    </div>
                                                
                                  
                                               
                                                  </div>
                                                  <div className="col-5">
                                                    <div className="end">
                                                      <div
                                                        className={
                                                          ele?.status ==
                                                          "Completed"
                                                            ? "color-dot-green"
                                                            : ele.status ==
                                                                "Cancelled" ||
                                                              ele.status ==
                                                                "Rejected"
                                                            ? "color-dot-red"
                                                            : "color-dot-blue"
                                                        }
                                                      ></div>
                                                      <p
                                                        className={
                                                          ele?.status ==
                                                          "Completed"
                                                            ? "text-green ps-2"
                                                            : ele?.status ==
                                                                "Cancelled" ||
                                                              ele?.status ==
                                                                "Rejected"
                                                            ? "text-red ps-2"
                                                            : "text-blue ps-2"
                                                        }
                                                      >
                                                        {ele?.status}
                                                      </p>
                                                    </div>

                                                    <div className="ms-auto end">
                                                  {ele.status_id != 2 &&
                                                    ele.status_id != 9 &&
                                                    ele.status_id != 10 &&
                                                    ele.status_id != 11 &&
                                                    ele.status_id != 12 &&
                                                    ele.status_id != 13 && (
                                                      <button
                                                        className="btn btn-small btn-prime "
                                                        onClick={() => {
                                                          // let pass_data = {
                                                          //     id: ""
                                                          // }
                                                          // delete_delivery_partner_order(pass_data);
                                                          SetDeleteConfirmModalData(
                                                            (prevalue) => {
                                                              prevalue.is_open =
                                                                true;
                                                              prevalue.title =
                                                                "Delete Confirmation";
                                                              prevalue.body =
                                                                "Confirm Delete Partner Order";
                                                              prevalue.data = {
                                                                id: "delete_delivery_partner_order",
                                                                pass_data: {
                                                                  id: ele.id,
                                                                },
                                                              };
                                                              return {
                                                                ...prevalue,
                                                              };
                                                            }
                                                          );
                                                        }}
                                                      >
                                                        <span className="small">
                                                          Delete
                                                        </span>
                                                      </button>
                                                    )}
                                                </div>
                                                    </div>
                                                    

                                                    <div className="col-7">
                                                    <div class="d-flex pt-2">
                                                      <img
                                                        src={require("../../assets/icon/profile.png")}
                                                        class="type-img"
                                                      />
                                                      <div class="ps-2">
                                                        <p class="extra-small">
                                                          Name
                                                        </p>
                                                        <p class=" pt-1 text-dark fw-bold">
                                                          {" "}
                                                          {
                                                            ele
                                                              ?.delivery_partner
                                                              .name
                                                          }
                                                        </p>
                                                      </div>
                                                     
                                                      </div>
                                                      </div>
                                                      <div className="col-5">
                                                    <div className="end">
                                                    <div className="ms-auto d-flex">
                                                    <div
                                        className="center  w-50 cursor"
                                        onClick={() => {
                                    
                               
                                          SetOrderTrackingModal(
                                            (prevalue: any) => {
                                              prevalue.is_open = true;
                                              prevalue.delivery_index = dp_ele_index;
                                              prevalue.delivery_id = ele.id;
                                              prevalue.shipment_index = p_in;
                                              return { ...prevalue };
                                            }
                                          );
                                        }}
                                      >
                                        
                                   
                                        <div>
                                          <img
                                            src={require("../../assets/icon/pin.png")}
                                            className="delivery"
                                          />

                                          <p className="extras-small pt-1 text-darkgray">
                                            Track
                                          </p>
                                        </div>
                                      </div>
                                                      
                                        <div className="call-btn"
                                            onClick={() => {
                                              call_del_func(
                                                order_data.id,
                                              "delivery_partner",
                                              ele.id
                                              );
                                            
                                            }}
                                        >
                                          <Ionicons
                                            name="md-call"
                                            size={18}
                                            color="#A80032"
                                          />
                                        </div>
                                      </div>
                                                      </div>
                                                      </div>
                                                      </div>

                                                     
                                                  

                                                      
                                                      

                                              
                                              }
                                            >
                                              <div className="d-flex align-items-center">
                                                {/* <h6 className="fw-bold text-dark">
                                Delivery Partner Details
                              </h6> */}
                                              
                                              </div>

                                              <div className="row mt-3">
                                                {/* delivery type */}
                                                <div className="col-md-4 col-sm-6 col-12 border-right d-flex pb-2">
                                                  <img
                                                    src={require("../../assets/icon/truck.png")}
                                                    className="type-img"
                                                  />
                                                  <div className="ps-2">
                                                    <p className="text-gray">
                                                      Delivery Type
                                                    </p>
                                                    <p className="text-dark fw-bold pt-1">
                                                      {ele?.hyperlocal
                                                        ? "HyperLocal"
                                                        : ele?.courier
                                                        ? "Courier"
                                                        : null}
                                                    </p>
                                                  </div>
                                                </div>
                                                {/* Status */}
                                                <div className="col-md-4 col-sm-6 col-12 d-flex pb-2">
                                                  <img
                                                    src={require("../../assets/icon/status.png")}
                                                    className="type-img"
                                                  />
                                                  <div className="ps-2">
                                                    <p className="text-gray">
                                                      Status
                                                    </p>
                                                    <p className="text-dark fw-bold pt-1">
                                                      {ele?.status}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>

                                              <DeliveryPartnerSubComponenent
                                                data={ele?.estimate_onaccept}
                                                from={"On Pickup Accept"}
                                              />
                                              <DeliveryPartnerSubComponenent
                                                data={ele?.estimate_onpickedup}
                                                from={"On Delivery Accept"}
                                              />
                                              <DeliveryPartnerSubComponenent
                                                data={ele?.estimate_actual}
                                                from={"Actual"}
                                              />

                                              {/* Difference */}
                                              <div className="col-md-6 md-pe-3 sm-px-1">
                                                <div className="card pt-3 px-3 pb-1 mb-3">
                                                  <h6 className="text-dark fw-bold">
                                                    Difference
                                                  </h6>
                                                  <div className="row pt-3">
                                                    <div className="col-4 ">
                                                      <p className="text-gray h-30">
                                                        Company Name
                                                      </p>
                                                      <p className="pt-1 text-dark fw-bold">
                                                        N/A
                                                      </p>
                                                    </div>

                                                    <div className="col-4 ">
                                                      <p className="text-gray h-30">
                                                        Executive Name
                                                      </p>
                                                      <p className="pt-1 text-dark fw-bold">
                                                        N/A
                                                      </p>
                                                    </div>
                                                  </div>
                                                  <OwnAccordian
                                                    heading={"Pickup Details"}
                                                  >
                                                    <div className="row">
                                                      <div className="col-4 ">
                                                        <p className="text-gray h-30">
                                                          Distance
                                                        </p>
                                                        <p className="pt-1 text-dark fw-bold">
                                                          N/A
                                                        </p>
                                                      </div>
                                                      <div className="col-4 ">
                                                        <p className="text-gray h-30">
                                                          Distance Payable
                                                        </p>
                                                        <p className="pt-1 text-dark fw-bold">
                                                          N/A
                                                        </p>
                                                      </div>

                                                      <div className="col-4 ">
                                                        <p className="text-gray h-30">
                                                          Rate
                                                        </p>
                                                        <p className="pt-1 text-dark fw-bold">
                                                          N/A
                                                        </p>
                                                      </div>
                                                      <div className="col-4 ">
                                                        <p className="text-gray h-30">
                                                          Charge
                                                        </p>
                                                        <p className="pt-1 text-dark fw-bold">
                                                          N/A
                                                        </p>
                                                      </div>

                                                      <div className="col-4 ">
                                                        <p className="text-gray h-30">
                                                          Start Time
                                                        </p>
                                                        <p className="pt-1 text-dark fw-bold">
                                                          N/A
                                                        </p>
                                                      </div>
                                                      <div className="col-4 ">
                                                        <p className="text-gray h-30">
                                                          End Time
                                                        </p>
                                                        <p className="pt-1 text-dark fw-bold">
                                                          N/A
                                                        </p>
                                                      </div>

                                                      <div className="col-4 ">
                                                        <p className="text-gray h-30">
                                                          Duration
                                                        </p>
                                                        <p className="pt-1 text-dark fw-bold">
                                                          N/A
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </OwnAccordian>

                                                  <OwnAccordian
                                                    heading={"Delivery Details"}
                                                  >
                                                    <div className="row">
                                                      <div className="col-4 ">
                                                        <p className="text-gray h-30">
                                                          Distance
                                                        </p>
                                                        <p className="pt-1 text-dark fw-bold">
                                                          N/A
                                                        </p>
                                                      </div>
                                                      <div className="col-4 ">
                                                        <p className="text-gray h-30">
                                                          Distance Payable
                                                        </p>
                                                        <p className="pt-1 text-dark fw-bold">
                                                          N/A
                                                        </p>
                                                      </div>

                                                      <div className="col-4 ">
                                                        <p className="text-gray h-30">
                                                          Rate
                                                        </p>
                                                        <p className="pt-1 text-dark fw-bold">
                                                          N/A
                                                        </p>
                                                      </div>
                                                      <div className="col-4 ">
                                                        <p className="text-gray h-30">
                                                          Charge
                                                        </p>
                                                        <p className="pt-1 text-dark fw-bold">
                                                          N/A
                                                        </p>
                                                      </div>

                                                      <div className="col-4 ">
                                                        <p className="text-gray h-30">
                                                          Start Time
                                                        </p>
                                                        <p className="pt-1 text-dark fw-bold">
                                                          N/A
                                                        </p>
                                                      </div>
                                                      <div className="col-4 ">
                                                        <p className="text-gray h-30">
                                                          End Time
                                                        </p>
                                                        <p className="pt-1 text-dark fw-bold">
                                                          N/A
                                                        </p>
                                                      </div>

                                                      <div className="col-4 ">
                                                        <p className="text-gray h-30">
                                                          Duration
                                                        </p>
                                                        <p className="pt-1 text-dark fw-bold">
                                                          N/A
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </OwnAccordian>

                                                  <OwnAccordian
                                                    heading={"Return Details"}
                                                  >
                                                    <div className="row">
                                                      <h6 className="text-dark "></h6>
                                                      <div className="col-4 ">
                                                        <p className="text-gray h-30">
                                                          Distance
                                                        </p>
                                                        <p className="pt-1 text-dark fw-bold">
                                                          N/A
                                                        </p>
                                                      </div>
                                                      <div className="col-4 ">
                                                        <p className="text-gray h-30">
                                                          Distance Payable
                                                        </p>
                                                        <p className="pt-1 text-dark fw-bold">
                                                          N/A
                                                        </p>
                                                      </div>

                                                      <div className="col-4 ">
                                                        <p className="text-gray h-30">
                                                          Rate
                                                        </p>
                                                        <p className="pt-1 text-dark fw-bold">
                                                          N/A
                                                        </p>
                                                      </div>
                                                      <div className="col-4 ">
                                                        <p className="text-gray h-30">
                                                          Charge
                                                        </p>
                                                        <p className="pt-1 text-dark fw-bold">
                                                          N/A
                                                        </p>
                                                      </div>

                                                      <div className="col-4 ">
                                                        <p className="text-gray h-30">
                                                          Start Time
                                                        </p>
                                                        <p className="pt-1 text-dark fw-bold">
                                                          N/A
                                                        </p>
                                                      </div>
                                                      <div className="col-4 ">
                                                        <p className="text-gray h-30">
                                                          End Time
                                                        </p>
                                                        <p className="pt-1 text-dark fw-bold">
                                                          N/A
                                                        </p>
                                                      </div>

                                                      <div className="col-4 ">
                                                        <p className="text-gray h-30">
                                                          Duration
                                                        </p>
                                                        <p className="pt-1 text-dark fw-bold">
                                                          N/A
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </OwnAccordian>
                                                </div>
                                              </div>
                                              <div className="card pt-3 px-3 pb-1 mb-3">
                                                <h6 className="fw-bold text-dark">
                                                  Delivery Partner Pickup
                                                  Details
                                                </h6>
                                                <div className="row pt-3">
                                                  <div className="col-5">
                                                    <div className=" ">
                                                      <p className="pb-2 text-gray">
                                                        Estimate Distance
                                                      </p>
                                                      <p className="text-dark fw-bold pt-2">
                                                        {
                                                          ele.etd_pickup_distance
                                                        }{" "}
                                                        Km
                                                      </p>
                                                    </div>
                                                    <div className=" ">
                                                      <p className="pb-2 text-gray">
                                                        Estimate Duration
                                                      </p>
                                                      <p className="text-dark fw-bold pt-2">
                                                        {sec_to_min(
                                                          ele.etd_pickup_duration
                                                        )}
                                                      </p>
                                                    </div>
                                                  </div>

                                                  <div className="col-7 ">
                                                    <p className="pb-2 text-gray">
                                                      Captured Image
                                                    </p>
                                                    {ele.hasOwnProperty(
                                                      "package_image"
                                                    ) && (
                                                      <div className="row">
                                                        {ele.package_image.map(
                                                          (img_ele: any) => (
                                                            <img
                                                              className="w-30"
                                                              src={
                                                                image_base_url +
                                                                img_ele
                                                              }
                                                            />
                                                          )
                                                        )}
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="card pt-3 px-3 pb-1 mb-3">
                                                <h6 className="fw-bold text-dark">
                                                  Delivery Partner Delivery
                                                  Details
                                                </h6>
                                                <div className="row pt-3">
                                                  <div className="col-4  ">
                                                    <p className="pb-2 text-gray">
                                                      Estimate Distance
                                                    </p>
                                                    <p className="text-dark fw-bold pt-2">
                                                      {
                                                        ele.etd_delivery_distance
                                                      }{" "}
                                                      Km
                                                    </p>
                                                  </div>

                                                  <div className="col-4  ">
                                                    <p className="pb-2 text-gray">
                                                      Payment Distance
                                                    </p>
                                                    <p className="text-dark fw-bold pt-2">
                                                      {ele?.payment_distance} Km
                                                    </p>
                                                  </div>

                                                  <div className="col-4  ">
                                                    <p className="pb-2 text-gray">
                                                      Partner Amount
                                                    </p>
                                                    <p className="text-dark fw-bold pt-2">
                                                      ₹ {ele?.partner_amount}
                                                    </p>
                                                  </div>

                                                  <div className="col-4  ">
                                                    <p className="pb-2 text-gray">
                                                      Estimate Duration
                                                    </p>
                                                    <p className="text-dark fw-bold pt-2">
                                                      {sec_to_min(
                                                        ele.etd_delivery_duration
                                                      )}
                                                    </p>
                                                  </div>

                                                  <div className="col-4  ">
                                                    <p className="pb-2 text-gray">
                                                      Payment Amount
                                                    </p>
                                                    <p className="text-dark fw-bold pt-2">
                                                      ₹ {ele?.payment_amount}
                                                    </p>
                                                  </div>

                                                  <div className="col-4  ">
                                                    <p className="pb-2 text-gray">
                                                      COD Order Amount
                                                    </p>
                                                    <p className="text-dark fw-bold pt-2">
                                                      ₹ {ele?.cod_order_amount}
                                                    </p>
                                                  </div>

                                                  <div className="col-4  ">
                                                    <p className="pb-2 text-gray">
                                                      Collected Amount
                                                    </p>
                                                    <p className="text-dark fw-bold pt-2">
                                                      ₹ {ele?.collected_amount}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>

                                              <OwnAccordian
                                                heading={"Delivery Partner Log"}
                                              >
                                                <ul className="delivery_status_vertical w-100 ">
                                                  {ele.status_timeline.map(
                                                    (ele: any) => (
                                                      <li>
                                                        <time className="extra-small text-end text-gray">
                                                          {ele?.time_formatted}
                                                        </time>
                                                        <div className="d-flex timeline">
                                                          {ele?.user.profile ? (
                                                            <img
                                                              src={
                                                                ele?.user
                                                                  .profile
                                                              }
                                                              className="me-1 user-img-timeline"
                                                            />
                                                          ) : (
                                                            <img
                                                              src={require("../../assets/img/profile.png")}
                                                              className="me-1 user-img-timeline"
                                                            />
                                                          )}
                                                          <div>
                                                            <p className="text-dark pb-1">
                                                              {ele?.status}
                                                            </p>
                                                            {ele?.user
                                                              .profile !=
                                                              "" && (
                                                              <p className="text-gray">
                                                                {" "}
                                                                {ele?.user.name}
                                                              </p>
                                                            )}
                                                          </div>
                                                        </div>

                                                        <div className="w-20 center">
                                                          {ele.hasOwnProperty(
                                                            "lat"
                                                          ) && (
                                                            <button
                                                              type="button"
                                                              className="btn btn-prime btn-small px-3"
                                                              onClick={() => {
                                                                openMap(
                                                                  ele?.lat,
                                                                  ele?.lng
                                                                );
                                                              }}
                                                            >
                                                              <span className="small">
                                                                Map
                                                              </span>
                                                            </button>
                                                          )}
                                                        </div>
                                                      </li>
                                                    )
                                                  )}
                                                </ul>
                                              </OwnAccordian>

                                              <div className="mb-2">
                                                <div className="mt-2 card pt-3 px-3 pb-1">
                                                  <div className="py-1">
                                                    <div className="row px-0">
                                                      <div className="fw-bold text-black mb-3 fs-7 ps-0">
                                                        PickupDrop Delivery
                                                        Partner Settlement
                                                      </div>
                                                      <div className="timeline-1">
                                                        <div className="d-flex">
                                                          <div
                                                            className={
                                                              ele.settlement
                                                                ?.settlement_eligible
                                                                ? "timeline-dot active"
                                                                : "timeline-dot"

                                                                
                                                            }
                                                          >
                                                            {ele.settlement
                                                                ?.settlement_eligible &&  (
                                                              <img
                                                                src={
                                                                  AppConfig.CDN_Media_url +
                                                                  "be453854bc89e35e154efa5e26814afa.svg"
                                                                }
                                                                className="img-fluid"
                                                                width={10}
                                                              />
                                                            )}
                                                          </div>
                                                          <div className="w-100 ps-2">
                                                            Settlement Eligible
                                                          </div>
                                                        </div>
                                                        <div className="d-flex">
                                                          <div className="timeline-line-container">
                                                            <div
                                                              className={
                                                                ele.settlement
                                                                  ?.settlement_approved
                                                                  ? "timeline-line active"
                                                                  : "timeline-line"
                                                              }
                                                            ></div>
                                                          </div>
                                                          <div className="w-100"> </div>
                                                        </div>
                                                        <div className="d-flex">
                                                          <div
                                                            className={
                                                              ele.settlement
                                                                ?.settlement_approved
                                                                ? "timeline-dot active"
                                                                : "timeline-dot"
                                                            }
                                                          >
                                                            {ele.settlement
                                                              ?.settlement_approved && (
                                                              <img
                                                                src={
                                                                  AppConfig.CDN_Media_url +
                                                                  "be453854bc89e35e154efa5e26814afa.svg"
                                                                }
                                                                className="img-fluid"
                                                                width={10}
                                                              />
                                                            )}
                                                          </div>
                                                          <div className="w-100 ps-2">
                                                            Settlement Approved
                                                          </div>
                                                        </div>
                                                        <div className="d-flex">
                                                          <div className="timeline-line-container">
                                                            <div
                                                              className={
                                                                ele.settlement
                                                                  ?.settlement_paid
                                                                  ? "timeline-line active"
                                                                  : "timeline-line"
                                                              }
                                                            ></div>
                                                          </div>
                                                          <div className="w-100"></div>
                                                        </div>
                                                        <div className="d-flex">
                                                          <div
                                                            className={
                                                              ele.settlement
                                                                ?.settlement_paid
                                                                ? "timeline-dot active"
                                                                : "timeline-dot"
                                                            }
                                                          >
                                                            {ele.settlement
                                                              ?.settlement_paid && (
                                                              <img
                                                                src={
                                                                  AppConfig.CDN_Media_url +
                                                                  "be453854bc89e35e154efa5e26814afa.svg"
                                                                }
                                                                className="img-fluid"
                                                                width={10}
                                                              />
                                                            )}
                                                          </div>
                                                          <div className="w-100 ps-2">
                                                            Settlement Paid
                                                            {ele.settlement
                                                              ?.settlement_paid && (
                                                              <div>
                                                                <div>
                                                                  {
                                                                    ele
                                                                      .settlement
                                                                      ?.settlement_paid_time
                                                                  }
                                                                </div>
                                                                <div className="fw-500 fs-15">
                                                                  &#8377;{" "}
                                                                  {
                                                                    ele
                                                                      .settlement
                                                                      ?.settlement_paid_amount
                                                                  }
{", "}   {
                                                                    ele
                                                                      .settlement
                                                                      ?.settlement_paid_amount
                                                                  }

                                                                </div>
                                                              </div>
                                                            )}

                                                            
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </OwnAccordian>
                                          </div>
                                        </>
                                      )
                                    )}
                                  </div>
                           </>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                  {/* Order Details */}
        {/* <div className="card px-3 pt-2 pb-1 mb-3 mt-3">
          <h6>Order Details</h6>
          <div className="d-flex">
            <p>Store Name : {order_data?.store_name}</p>
            <button
              className="ms-auto"
              onClick={() => {
                SetDeleteConfirmModalData((prevalue) => {
                  prevalue.is_open = true;
                  prevalue.title = "Delete Confirmation";
                  prevalue.body = "Confirm Cancel Store Order";
                  prevalue.data = {
                    id: "cancel_store_order",
                    pass_data: {
                      id: "",
                    },
                  };
                  return { ...prevalue };
                });
              }}
            >
              Cancel Order
            </button>
          </div>
          <p>Delivery Type : {order_data?.delivery_type}</p>
          <p>Payment Type : {order_data?.payment_type} </p>
          <p>amount : {order_data?.amount} </p>
          <p>gst_amount : {order_data?.gst_amount} </p>
          <p>total_amount : {order_data?.total_amount} </p>
          <p>delivery_charges : {order_data?.delivery_charges} </p>
          <p>
            convenience_fee_percent : {order_data?.convenience_fee_percent}{" "}
          </p>
          <p>convenience_fee : {order_data?.convenience_fee} </p>
          <p>total_amount_payable : {order_data?.total_amount_payable} </p>
          <h6>Ordered Products : </h6>
          {order_data?.ordered_products?.map(
            (prod_ele: any, prod_index: any) => (
              <div className="mt-2" key={prod_index}>
                <p>SI No : {prod_index + 1}</p>
                <p>product_name : {prod_ele.product_name}</p>
                <p>product_description : {prod_ele.product_description}</p>
                <p>variant_name : {prod_ele.variant_name}</p>
                <p>variant_description : {prod_ele.variant_description}</p>
                <p>quantity : {prod_ele.quantity}</p>
                <p>Amount : {prod_ele.total_amount_payable}</p>
              </div>
            )
          )}
        </div> */}

         {/* product */}
         {/* <div className="py-2 order_detail"> */}
    
         {order_data.hasOwnProperty("ordered_products") && order_data.ordered_products.length !=0 &&
         <div className=" mt-2 card  px-2 shadow">

         <div className="card px-3 pt-2 pb-1 mb-3 mt-3">
                                <h6 className="fw-bold">Products</h6>
                          {order_data.hasOwnProperty("ordered_products") &&
                          
                            order_data?.ordered_products?.map((p_ele: any) => (
                              <Accordion>
                                <AccordionSummary
                                  // className="my-0 px-3 py-0"
                                  // expandIcon={<ExpandMoreIcon />}
                                  // aria-controls="panel1d-content"
                                >
                                  <div className="row w-100 align-items-center">
                                    <div className="col-7 ps-0">
                                      <div className="d-flex align-items-center cursor">
                                        <MaterialIcons
                                          name="keyboard-arrow-right"
                                          size={14}
                                          color="black"
                                        />
                                        {/* <Entypo name="dot-single" size={24} color="#555" /> */}
                                        <p className="oneline ps-1">
                                          {p_ele.variant_name}
                                          {/* {p_ele.product_name == "" ||
                                      p_ele.product_name == null
                                        ? p_ele.variant_name
                                        : p_ele.product_name} */}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="col-2 py-0 px-1">
                                      <p className="">× {p_ele?.quantity}</p>
                                    </div>
                                    <div className="col-3 pe-0">
                                      <p className="text-right text-black ">
                                        ₹{" " + p_ele?.total_amount_with_qty_without_gst}
                                      </p>
                                    </div>
                                  </div>
                                </AccordionSummary>
                                <AccordionDetails className="px-2 w-100">
                                  {/* <div className="d-flex align-items-center cursor w-100 ps-3">
                                {p_ele.price.gst_amount != 0 ? (
                                  <p className="oneline ps-1 extra-small text-darkgray">
                                    GST
                                  </p>
                                ) : (
                                  <p className="oneline ps-1 extra-small text-darkgray">
                                    Price with GST
                                  </p>
                                )}
                                <div className="ms-auto">
                                  {p_ele.price.gst_amount != 0 ? (
                                    <p className="text-right text-darkgray v-small">
                                      ₹{" " + p_ele.price.gst_amount}
                                    </p>
                                  ) : (
                                    <p className="text-right text-darkgray v-small">
                                      ₹{" " + p_ele.price.price_with_gst}
                                    </p>
                                  )}
                                </div>
                              </div> */}
                                  <div className=" w-100">
                                    {p_ele.product_name == "" ? (
                                      <div className="row align-items-center pb-2 px-0">
                                        <p className="col-7 text-darkgray pe-1 ps-0">
                                          {p_ele.variant_name}{" "}
                                          {"  ( ₹" +
                                            p_ele?.price_without_gst +
                                            ")"}{" "}
                                        </p>
                                        <p className="col-2 text-darkgray extra-small">
                                          X {p_ele.quantity}
                                        </p>
                                        <p className="text-end col-3 text-darkgray p-0">
                                          hi
                                          {
                                            p_ele?.total_amount_with_qty_without_gst
                                          }
                                        </p>
                                      </div>
                                    ) : (
                                      <div className="row align-items-center pb-2 px-0">
                                        <p className="col-7 text-darkgray pe-1 ps-0">
                                          {p_ele.product_name || p_ele.variant_name}{" "}
                                          {"  ( ₹" +
                                            // p_ele.price.price_without_gst +
                                            ")"}{" "}
                                        </p>
                                        <p className="col-2 text-darkgray extra-small">
                                          X {p_ele.quantity}
                                        </p>
                                        <p className="col-3 text-end text-darkgray  p-0">
                                          ₹{" "}
                                          {
                                            p_ele?.total_amount_with_qty_without_gst
                                          }
                                        </p>
                                      </div>
                                    )}
                                    <div className="row align-items-center pb-2 px-0">
                                      <p className="col-7 text-darkgray ps-0">
                                        GST Amount{" "}
                                        {/* {"%" + p_ele.price.gst_percent} /{" "} */}
                                        {/* {"  ( ₹" + p_ele.gst_amount + ")"}{" "} */}
                                      </p>
                                      <p className="col-2 text-darkgray extra-small">
                                        X {p_ele.quantity}
                                      </p>
                                      <p className="col-3 text-end text-darkgray p-0">
                                        ₹{" "}
                                        {p_ele?.total_gst_amount_with_qty}
                                      </p>
                                    </div>
                                    {/* addon */}
                                    {p_ele.addons.length > 0 ? (
                                      <div className="py-2  px-0">
                                        <p className="fw-bold text-black mb-2 fs-7">
                                          Addons
                                        </p>
                                        {p_ele?.addons?.map(
                                          (a_ele: any, a_index: any) => (
                                            <div className="mb-2" key={a_index}>
                                              <div className="row align-items-center pb-2  px-0">
                                                <p className="text-darkgray col-7 pe-1 ps-0 ">
                                                  {a_ele.name}{" "}
                                                  {"(₹" +
                                                    a_ele.price?.price_without_gst +
                                                    ")"}{" "}
                                                </p>
                                                <p className="text-darkgray col-2 extra-small">
                                                  X {a_ele.quantity}
                                                </p>
                                                <p className="col-3 text-end text-darkgray p-0">
                                                  ₹{" "}
                                                  {
                                                    a_ele.price?.total_amount_with_qty_without_gst
                                                  }
                                                </p>
                                              </div>
                                              <div className="row align-items-center mb-2">
                                                <p className="text-darkgray col-7 pe-1 ps-0">
                                                  GST Amount{" "}
                                                  {"%" +
                                                    a_ele.price?.gst_percent}{" "}
                                                  /{" "}
                                                  {"(₹" +
                                                    a_ele.price?.gst_amount +
                                                    ")"}{" "}
                                                </p>
                                                <p className="text-darkgray col-2 extra-small">
                                                  X {a_ele.quantity}
                                                </p>
                                                <p className="col-3 text-end text-darkgray p-0">
                                                  ₹{" "}
                                                  {
                                                    a_ele.price?.total_gst_amount_with_qty
                                                  }
                                                </p>
                                              </div>
                                            </div>
                                          )
                                        )}
                                        <div className="row align-items-center py-2">
                                          <p className="text-darkgray col-8 ps-0">
                                            Sub Total Addon Amount
                                          </p>
                                          <p className="col-4 text-end ms-auto text-darkgray pe-0">
                                            ₹{" "}
                                            {
                                              p_ele.price?.total_addon_amount_without_gst
                                            }
                                          </p>
                                        </div>
                                        <div className="row align-items-center pb-2">
                                          <p className="text-darkgray col-8 ps-0">
                                            Total Addon GST Amount
                                          </p>
                                          <p className="col-4 text-end ms-auto text-darkgray pe-0">
                                            ₹{" "}
                                            {p_ele.price?.total_addon_gst_amount}
                                          </p>
                                        </div>
                                        <div className="row align-items-center pb-2">
                                          <p className="text-darkgray col-8 ps-0">
                                            Total Addon Amount
                                          </p>
                                          <p className="col-4 text-end ms-auto text-darkgray pe-0">
                                            ₹{" "}
                                            {
                                              p_ele.price?.total_addon_amount_with_gst
                                            }
                                          </p>
                                        </div>
                                      </div>
                                    ) : null}
                                    {/* Sub Total Product Amount */}
                                    <div className="row align-items-center pb-2 pt-3 border-top-dot">
                                      <p className="text-darkgray col-8 ps-0">
                                        Sub Total Product Amount
                                      </p>
                                      <p className="col-4 text-end ms-auto text-darkgray pe-0">
                                        ₹{" "}
                                        {
                                          p_ele?.total_amount_with_qty_without_gst
                                        }
                                      </p>
                                    </div>
                                    {/* Total Product GST Amount */}
                                    <div className="row align-items-center pb-2">
                                      <p className="text-darkgray col-8 ps-0">
                                        Total Product GST Amount
                                      </p>
                                      <p className="col-4 text-end ms-auto text-darkgray pe-0">
                                        ₹{" "}
                                        {p_ele?.total_gst_amount_with_qty}
                                      </p>
                                    </div>
                                    {/* Total Product Amount */}
                                    {/* <div className="row align-items-center pb-2">
                                      <p className="text-darkgray col-8 ps-0">
                                        Total Product Amount
                                      </p>
                                      <p className="col-4 text-end ms-auto text-darkgray pe-0">
                                        ₹{" "}
                                        {
                                          p_ele.total_amount_with_qty_with_gst
                                        }
                                      </p>
                                    </div> */}
                                    {/* Total Payable Amount */}
                                    <div className="row align-items-center py-2">
                                      <p className="text-dark col-8 ps-0">
                                        Total Payable Amount
                                      </p>
                                      <p className="col-4 text-end ms-auto text-dark pe-0">
                                        ₹ {p_ele?.total_amount_payable}
                                      </p>
                                    </div>
                                  </div>
                                </AccordionDetails>
                              </Accordion>
                            ))}
                        </div>
                        </div>
}

                <div className="mt-2 card  px-2 shadow">
                  {order_data.sector_id != 4 && (
                    <div>
                      {order_data?.shipments?.length != 0 ? (
                        order_data?.shipments?.map(
                          (ship_ele: any, ship_index: any) => (
                            <div>
                              <div className="card px-3 pt-2 pb-1 mb-3 mt-3">
                                <h6 className="fw-bold">Shipment Details</h6>
                                <div className="pt-3 row align-items-start">
                                  <div className="col-7">
                                    <p className="text-black extra-small pb-2">
                                      SI No: {ship_index + 1}
                                    </p>
                                    <p className="text-gray small">
                                      {ship_ele.date}
                                    </p>
                                  </div>
                                  <div className="col-5">
                                  <div className="end">
                          
                                    <FontAwesome
                                name="circle"
                                size={10}
                                color={
                                  // ord_ele.status == "Accepted"
                                  //   ? "blue"
                                  //   : ord_ele.status == "Completed"
                                  //   ? "green"
                                  //   : ord_ele.status == "Order Placed"
                                  //   ? "orange"
                                  //   : "red"
                                  ship_ele.status_color
                                }
                              />
                              <p
                                // className={
                                //   ord_ele.status == "Accepted"
                                //     ? "text-blue ps-2"
                                //     : ord_ele.status == "Completed"
                                //     ? "text-green ps-2"
                                //     : ord_ele.status == "Order Placed"
                                //     ? "text-orange ps-2"
                                //     : "text-red ps-2"
                                // }
                                className="ps-2"
                                style={{color:ship_ele.status_color}}
                              >
                                {ship_ele.status}
                              </p>
                              </div>

                              <div className="end">

                              {ship_ele.dm_order &&
                                  <span
                                      className="badge-1 me-2 badge-blue mt-2"
                                      >Demo</span>
}
                                  </div>
                                  </div>

                                </div>
                                <p className="small pt-3 text-gray">
                                  Products Name
                                </p>
                                {ship_ele.items.map(
                                  (item_ele: any, item_index: any) => (
                                    <div className="">
                                      <div
                                        className="pt-2 d-flex align-items-cetner cursor"
                                        onClick={() => {
                                          SetIsRestaProductOpen(
                                            !is_resta_product_open
                                          );
                                        }}
                                      >
                                        <p className="text-dark fw-bold">
                                          {item_ele.ordered_product.hasOwnProperty(
                                            "product_name"
                                          )
                                            ? item_ele.ordered_product
                                                .product_name
                                            : item_ele.ordered_product
                                                .variant_name}
                                        </p>
                                        <div className="ms-auto">
                                          {is_resta_product_open &&
                                          item_index == item_index ? (
                                            <MaterialIcons
                                              name="keyboard-arrow-down"
                                              size={20}
                                              color="black"
                                            />
                                          ) : (
                                            <MaterialIcons
                                              name="keyboard-arrow-up"
                                              size={20}
                                              color="black"
                                            />
                                          )}
                                        </div>
                                      </div>

                                      {is_resta_product_open ? (
                                        <div className="pt-2">
                                          <p className=" text-dark">
                                            Product Description
                                          </p>
                                          <p className="pt-1 small text-gray">
                                            {item_ele.ordered_product.hasOwnProperty(
                                              "product_description"
                                            )
                                              ? item_ele.ordered_product
                                                  .product_description
                                              : item_ele.ordered_product
                                                  .variant_name}
                                          </p>
                                          <p className="pt-2 extra-small text-dark">
                                            Added User :{" "}
                                            {item_ele.added_user.name}{" "}
                                          </p>
                                        </div>
                                      ) : null}
                                    </div>
                                  )
                                )}
                              </div>

                              <div className="card pt-2 px-3 pb-1 mb-2">
                                <div className="d-flex align-items-center pb-2">
                                  <h6 className="fw-bold">Pickup Location</h6>
                                  <div className="ms-auto">
                                    <button
                                      className="btn btn-prime btn-small "
                                      onClick={() => {
                                        openMap(
                                          order_data?.seller?.outlet?.lat,
                                          order_data?.seller?.outlet?.lng
                                        );
                                      }}
                                    >
                                      <span className="small">Map</span>
                                    </button>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-2 ">
                                    <img
                                      src={require("../../assets/icon/up-arrow.png")}
                                      className="type-img"
                                    />
                                  </div>
                                  <div className="ps-2 col-10">
                                    {order_data?.seller?.outlet?.map_address ||
                                    order_data?.seller?.outlet?.map_address ? (
                                      <div className="pb-2 border-bottom">
                                        <p className="text-dark fw-bold">
                                          {order_data.seller?.brand_name}{" "}
                                        </p>
                                        <p className="small text-gray pt-1">
                                          {" "}
                                          {
                                            order_data?.seller?.outlet
                                              ?.map_address
                                          }
                                        </p>
                                      </div>
                                    ) : (
                                      <div className="d-flex">
                                        <p>
                                          {order_data?.seller?.outlet?.door_no}{" "}
                                          {", "}
                                          {
                                            order_data?.seller?.outlet?.address
                                          }{" "}
                                          {", "}
                                          {
                                            order_data?.seller?.outlet?.city
                                          }{" "}
                                          {", "}
                                          {
                                            order_data?.seller?.outlet?.state
                                          }{" "}
                                          {", "}
                                          {
                                            order_data?.seller?.outlet?.country
                                          }{" "}
                                          {"- "}
                                          {order_data?.seller?.outlet?.pincode}
                                        </p>
                                      </div>
                                    )}
                                    <div className="pt-2 d-flex align-items-center">
                                      <div>
                                        <p className="text-dark fw-bold">
                                          {order_data.seller?.outlet?.name}
                                        </p>
                                        <p className="text-black pt-1">
                                          {order_data?.seller?.outlet?.mobile}
                                        </p>
                                      </div>
                                      <div className="ms-auto">
                                      <a href={"tel:" +order_data?.seller?.outlet?.mobile} style={{ textDecoration: 'none' }}>
                                        
                                        <div className="call-btn"
                                            // onClick={() => {
                              
                                            //   call_func(
                                            //     order_data.id,
                                            //   "customer_pickup"
                                            //   );
                                            // }}
                                        >
                                          <Ionicons
                                            name="md-call"
                                            size={18}
                                            color="#A80032"
                                          />
                                        </div>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="card pt-2 px-3 pb-1 mb-2">
                                <div className="d-flex align-items-center pb-2">
                                  <h6 className="fw-bold">Delivery Location</h6>
                                  <div className="ms-auto">
                                    <button
                                      className="btn btn-prime btn-small "
                                      onClick={() => {
                                        openMap(
                                          order_data?.customer?.delivery_address
                                            ?.lat,
                                          order_data?.customer?.delivery_address
                                            ?.lng
                                        );
                                      }}
                                    >
                                      <span className="small">Map</span>
                                    </button>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-2">
                                    <img
                                      src={require("../../assets/icon/up-arrow.png")}
                                      className="type-img"
                                    />
                                  </div>
                                  <div className="ps-2 col-10">
                                    <div className="pb-2 border-bottom">
                                      <p className="text-dark fw-bold"></p>
                                      {order_data?.customer?.delivery_address
                                        .map_address ||
                                      order_data?.customer?.delivery_address
                                        ?.map_address ? (
                                        <p className="small text-gray">
                                          {
                                            order_data?.customer
                                              ?.delivery_address.map_address
                                          }
                                        </p>
                                      ) : (
                                        <p className="small text-gray">
                                          {order_data?.customer
                                            ?.delivery_address.door_no +
                                            ", " +
                                            order_data?.customer
                                              ?.delivery_address.address +
                                            ", " +
                                            order_data?.customer
                                              ?.delivery_address.city +
                                            ", " +
                                            order_data?.customer
                                              ?.delivery_address.state +
                                            ", " +
                                            order_data?.customer
                                              ?.delivery_address.country +
                                            ", " +
                                            order_data?.customer
                                              ?.delivery_address.pincode}
                                        </p>
                                      )}
                                    </div>

                                    <div className="pt-2 d-flex align-items-center">
                                      <div>
                                        <p className="text-dark fw-bold">
                                          {
                                            order_data.customer
                                              ?.delivery_address?.firstname
                                          }{" "}
                                          {
                                            order_data.customer
                                              ?.delivery_address?.lastname
                                          }
                                        </p>
                                        <p className="text-black pt-1">
                                          {
                                            order_data.customer
                                              ?.delivery_address.mobile
                                          }
                                        </p>
                                      </div>
                                      {order_data.customer?.delivery_address
                                        .mobile ? (
                                        <div className="ms-auto">
                          <a href={"tel:" +order_data.customer?.delivery_address.mobile} style={{ textDecoration: 'none' }}>
                                          
                                          <div className="call-btn"
                                                  // onClick={() => {
                              
                                          //   call_func(
                                          //     order_data.id,
                                          //   "customer_delivery"
                                          //   );
                                          // }}
                                  
                                          >
                                            <Ionicons
                                              name="md-call"
                                              size={18}
                                              color="#A80032"
                                            />
                                          </div>
                                          </a>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        )
                      ) : (
                        <div>
                            <div className="card pt-2 px-3 pb-1 mb-2">
                                <div className="d-flex align-items-center pb-2">
                                  <h6 className="fw-bold">Pickup Location</h6>
                                  <div className="ms-auto">
                                    <button
                                      className="btn btn-prime btn-small "
                                      onClick={() => {
                                        openMap(
                                          order_data?.seller?.outlet?.lat,
                                          order_data?.seller?.outlet?.lng
                                        );
                                      }}
                                    >
                                      <span className="small">Map</span>
                                    </button>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-2 ">
                                    <img
                                      src={require("../../assets/icon/up-arrow.png")}
                                      className="type-img"
                                    />
                                  </div>
                                  <div className="ps-2 col-10">
                                    {order_data?.seller?.outlet?.map_address ||
                                    order_data?.seller?.outlet?.map_address ? (
                                      <div className="pb-2 border-bottom">
                                        <p className="text-dark fw-bold">
                                          {order_data.seller?.brand_name}{" "}
                                        </p>
                                        <p className="small text-gray pt-1">
                                          {" "}
                                          {
                                            order_data?.seller?.outlet
                                              ?.map_address
                                          }
                                        </p>
                                      </div>
                                    ) : (
                                      <div className="d-flex">
                                        <p>
                                          {order_data?.seller?.outlet?.door_no}{" "}
                                          {", "}
                                          {
                                            order_data?.seller?.outlet?.address
                                          }{" "}
                                          {", "}
                                          {
                                            order_data?.seller?.outlet?.city
                                          }{" "}
                                          {", "}
                                          {
                                            order_data?.seller?.outlet?.state
                                          }{" "}
                                          {", "}
                                          {
                                            order_data?.seller?.outlet?.country
                                          }{" "}
                                          {"- "}
                                          {order_data?.seller?.outlet?.pincode}
                                        </p>
                                      </div>
                                    )}
                                    <div className="pt-2 d-flex align-items-center">
                                      <div>
                                        <p className="text-dark fw-bold">
                                          {order_data.seller?.outlet?.name}
                                        </p>
                                        <p className="text-black pt-1">
                                          {order_data?.seller?.outlet?.mobile}
                                        </p>
                                      </div>
                                      <div className="ms-auto">
                                      <a href={"tel:" +order_data?.seller?.outlet?.mobile} style={{ textDecoration: 'none' }}>
                                        
                                        <div className="call-btn"
                                            // onClick={() => {
                              
                                            //   call_func(
                                            //     order_data.id,
                                            //   "customer_pickup"
                                            //   );
                                            // }}
                                        >
                                          <Ionicons
                                            name="md-call"
                                            size={18}
                                            color="#A80032"
                                          />
                                        </div>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                          <div className="card pt-2 px-3 pb-1 mb-2">
                                <div className="d-flex align-items-center pb-2">
                                  <h6 className="fw-bold">Delivery Location</h6>
                                  <div className="ms-auto">
                                    <button
                                      className="btn btn-prime btn-small "
                                      onClick={() => {
                                        openMap(
                                          order_data?.customer?.delivery_address
                                            ?.lat,
                                          order_data?.customer?.delivery_address
                                            ?.lng
                                        );
                                      }}
                                    >
                                      <span className="small">Map</span>
                                    </button>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-2">
                                    <img
                                      src={require("../../assets/icon/up-arrow.png")}
                                      className="type-img"
                                    />
                                  </div>
                                  <div className="ps-2 col-10">
                                    <div className="pb-2 border-bottom">
                                      <p className="text-dark fw-bold"></p>
                                      {order_data?.customer?.delivery_address
                                        .map_address ||
                                      order_data?.customer?.delivery_address
                                        ?.map_address ? (
                                        <p className="small text-gray">
                                          {
                                            order_data?.customer
                                              ?.delivery_address.map_address
                                          }
                                        </p>
                                      ) : (
                                        <p className="small text-gray">
                                          {order_data?.customer
                                            ?.delivery_address.door_no +
                                            ", " +
                                            order_data?.customer
                                              ?.delivery_address.address +
                                            ", " +
                                            order_data?.customer
                                              ?.delivery_address.city +
                                            ", " +
                                            order_data?.customer
                                              ?.delivery_address.state +
                                            ", " +
                                            order_data?.customer
                                              ?.delivery_address.country +
                                            ", " +
                                            order_data?.customer
                                              ?.delivery_address.pincode}
                                        </p>
                                      )}
                                    </div>

                                    <div className="pt-2 d-flex align-items-center">
                                      <div>
                                        <p className="text-dark fw-bold">
                                          {
                                            order_data.customer
                                              ?.delivery_address?.firstname
                                          }{" "}
                                          {
                                            order_data.customer
                                              ?.delivery_address?.lastname
                                          }
                                        </p>
                                        <p className="text-black pt-1">
                                          {
                                            order_data.customer
                                              ?.delivery_address.mobile
                                          }
                                        </p>
                                      </div>
                                      {order_data.customer?.delivery_address
                                        .mobile ? (
                                        <div className="ms-auto">
                          <a href={"tel:" +order_data.customer?.delivery_address.mobile} style={{ textDecoration: 'none' }}>
                                          
                                          <div className="call-btn"
                                                  // onClick={() => {
                              
                                          //   call_func(
                                          //     order_data.id,
                                          //   "customer_delivery"
                                          //   );
                                          // }}
                                  
                                          >
                                            <Ionicons
                                              name="md-call"
                                              size={18}
                                              color="#A80032"
                                            />
                                          </div>
                                          </a>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>

                          <div className="card pt-3 px-3 pb-1 mb-3">
                            <h6 className="fw-bold">Shipment Details</h6>
                            <div className="mt-3">
                              <p className="text-gray">No Shipment Details</p>
                            </div>
                            {/* <div className="pt-3 row align-items-start">
                        <div className="col-7">
                          <p className="text-black extra-small pb-2">
                            SI No: {ship_index + 1}
                          </p>
                          <p className="text-gray small">
                            {ship_ele.date}
                          </p>
                        </div>
                        <div className="col-5 end">
                          <div
                            className={
                              ship_ele.status == "Completed" ||
                              ship_ele.status == "Delivered"
                                ? "color-dot-green"
                                : ship_ele.status == "Cancelled" ||
                                  ship_ele.status == "Rejected"
                                ? "color-dot-red"
                                : "color-dot-blue"
                            }
                          ></div>
                          <p
                            className={
                              ship_ele.status == "Completed" ||
                              ship_ele.status == "Delivered"
                                ? "text-green ps-2"
                                : ship_ele.status == "Cancelled" ||
                                  ship_ele.status == "Rejected"
                                ? "text-red ps-2"
                                : "text-blue ps-2"
                            }
                          >
                            {ship_ele.status}
                          </p>
                        </div>
                      </div> */}
                            {/* <p className="small pt-3 text-gray">
                        Products Name
                      </p> */}
                            {/* {ship_ele.items.map(
                        (item_ele: any, item_index: any) => (
                          <div className="">
                            <div
                              className="pt-2 d-flex align-items-cetner cursor"
                              onClick={() => {
                                SetIsRestaProductOpen(
                                  !is_resta_product_open
                                );
                              }}
                            >
                              <p className="text-dark fw-bold">
                                {item_ele.ordered_product.product_name}
                              </p>
                              <div className="ms-auto">
                                {is_resta_product_open &&
                                item_index == item_index ? (
                                  <MaterialIcons
                                    name="keyboard-arrow-down"
                                    size={20}
                                    color="black"
                                  />
                                ) : (
                                  <MaterialIcons
                                    name="keyboard-arrow-up"
                                    size={20}
                                    color="black"
                                  />
                                )}
                              </div>
                            </div>

                            {is_resta_product_open ? (
                              <div className="pt-2">
                                <p className=" text-dark">
                                  Product Description
                                </p>
                                <p className="pt-1 small text-gray">
                                  {
                                    item_ele.ordered_product
                                      .product_description
                                  }
                                </p>
                                <p className="pt-2 extra-small text-dark">
                                  Added User : {item_ele.added_user.name}{" "}
                                </p>
                              </div>
                            ) : null}
                          </div>
                        )
                      )} */}
                          </div>

                          <div className="card pt-3 px-3 pb-1 mb-3">
                            {/* <TimelineAccordian> */}
                            <div className="">
                              <h6 className="text-dark fw-bold">Vendor Log</h6>
                              <div className="mt-3">
                                <p className="text-gray">No Vendor Log</p>
                              </div>
                              {/* <ul className="delivery_status_vertical w-100 pt-2">
                          {ship_ele.status_timeline.map(
                            (time_ele: any, time_index: any) => (
                              <li>
                                <time className="extra-small text-end text-gray">
                                  {time_ele?.time_formatted}
                                </time>
                                <div className="d-flex timeline">
                                  {time_ele?.user.profile ? (
                                    <img
                                      src={time_ele?.user.profile}
                                      className="me-1 user-img-timeline"
                                    />
                                  ) : (
                                    <img
                                      src={require("../../assets/img/profile.png")}
                                      className="me-1 user-img-timeline"
                                    />
                                  )}
                                  <div>
                                    <p className="text-dark pb-1">
                                      {time_ele?.status}
                                    </p>
                                    {time_ele?.user.profile != "" && (
                                      <p className="text-gray">
                                        {" "}
                                        {time_ele?.user.name}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </li>
                            )
                          )}
                        </ul> */}
                            </div>
                            {/* </TimelineAccordian> */}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {order_data.sector_id != 4 && (
                    <div>
                      {order_data?.shipments?.length != 0 ? (
                        order_data?.shipments?.map(
                          (ship_ele: any, ship_index: any) =>
                            ship_ele.super_delivery_order.map(
                              (d_ele: any, d_index: any) => (
                                <div key={d_index}>
                                  <div className="card px-3 pb-1 ">
                                    <OwnAccordian
                                      heading={
                                        <div 
                                        // className="row px-3"
                                        >
                                          <b>Delivery Company Details</b>
                                          <div className="d-flex justify-content-between">
                                          <div >
                                            <p className="text-dark fw-bold pb-2 mt-3 extra-small">
                                              #{d_ele.id}
                                            </p>
                                            <div className="d-flex align-items-center">
                                              <p className="text-gray text-end extra-small  pb-2">
                                                {d_ele.time}
                                              </p>
                                            </div>
                                            <div className="d-flex mb-2">
                                              <img
                                                src={require("../../assets/icon/store.png")}
                                                className="type-img"
                                              />
                                              <div className="ps-2">
                                                <p className="text-gray">
                                                  Name
                                                </p>
                                                <p className="pt-1 text-dark fw-bold">
                                                  {d_ele.brand_name}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          <div className=" ms-auto">
                                            <div className="d-flex align-items-center mt-3 end">
                                              <div
                                                className={
                                                  d_ele.status == "Completed"
                                                    ? "color-dot-green"
                                                    : d_ele.status ==
                                                        "Cancelled" ||
                                                      d_ele.status == "Rejected"
                                                    ? "color-dot-red"
                                                    : "color-dot-blue"
                                                }
                                              ></div>
                                              <p
                                                className={
                                                  d_ele.status == "Completed"
                                                    ? "text-green ps-2"
                                                    : d_ele.status ==
                                                        "Cancelled" ||
                                                      d_ele.status == "Rejected"
                                                    ? "text-red ps-2"
                                                    : "text-blue ps-2"
                                                }
                                              >
                                                {d_ele.status}
                                              </p>
                                            </div>

                                                <div className="">
                                             
                                            {(d_ele.status_id == 1 ||
                                              d_ele.status_id == 2) && (
                                              <button
                                                className="btn btn-blue btn-small ms-2"
                                                onClick={() => {
                                                  if (props.id === undefined) {

                                                  SetAvailableDeliveryPartnerModal(
                                                    (prevalue) => {
                                                      prevalue.is_open = true;
                                                      prevalue.data = {
                                                        ord_id:props.id != undefined ? props.id:id_value.ord_id,
                                                        comp_ord_id: d_ele.id,
                                                      };
                                                      return { ...prevalue };
                                                    }
                                                  );
                                                }else{
                                                  props.sendMessageToParent({ord_id: props.id,comp_ord_id:d_ele.id});

                                                }}}
                                              >
                                                <span className="small">
                                                  Change Delivery Partner
                                                </span>
                                              </button>
                                            )}
                                        
                                    
                              {d_ele.dm_order &&
                                  <span
                                      className="badge-1 me-2 badge-blue mt-2"
                                      >Demo</span>
}
                                  </div>
                                          </div>
                                          </div>
                                        </div>
                                      }
                                    >
                                      <>
                                        <div className="px-3  pb-2">
                                          <div className="row ">
                                            <div className="col-md-4  border-right d-flex  pe-0 mb-2">
                                              <img
                                                src={require("../../assets/icon/truck.png")}
                                                className="type-img"
                                              />
                                              <div className="ps-2">
                                                <p className="text-gray">
                                                  Service Type
                                                </p>
                                                <p className="pt-1 text-dark fw-bold">
                                                  {d_ele.service_name}
                                                </p>
                                              </div>
                                            </div>
                                            <div className="col-md-4 col-6  d-flex mb-2">
                                              <img
                                                src={require("../../assets/icon/location.png")}
                                                className="type-img"
                                              />
                                              <div className="ps-2">
                                                <p className="text-gray">
                                                  Zone
                                                </p>
                                                <p className="pt-1 text-dark fw-bold">
                                                  {d_ele.zone_name}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                      
                                          <div className="py-2">
                                            <p className="text-gray">
                                              Zone Description
                                            </p>
                                            <p className="pt-1 text-dark">
                                              {d_ele.zone_description}
                                            </p>
                                            {/* </div> */}
                                          </div>
                                          {(d_ele.status_id == 1 ||
                                              d_ele.status_id == 2) && (
                                              <div className="ms-auto">
                                                <button
                                                  className=" btn btn-outline-prime btn-small"
                                                  onClick={() => {
                                                    SetDeleteConfirmModalData(
                                                      (prevalue) => {
                                                        prevalue.is_open = true;
                                                        prevalue.title =
                                                          "Delete Confirmation";
                                                        prevalue.body =
                                                          "Confirm Delete Company Order";
                                                        prevalue.data = {
                                                          id: "delete_delivery_company_order",
                                                          pass_data: {
                                                            id: d_ele.id,
                                                          },
                                                        };
                                                        return { ...prevalue };
                                                      }
                                                    );
                                                  }}
                                                  disabled={true}
                                                >
                                                  <span className="fw-bold small">
                                                    Delete
                                                  </span>
                                                </button>
                                              </div>
                                            )}
                                        </div>

                                        {/* Estimation */}
                                        <DeliveryPartnerSubComponenent
                                          data={order_data?.order_estimation}
                                          from={"Delivery Company Estimation"}
                                        />
                                        <div className="card pt-2 px-3 pb-1 ">
                                          <h6 className="fw-bold">
                                            Delivery Details
                                          </h6>
                                          <div className="pt-3 row">
                                            <div className="col-md-4 col-6 mb-2">
                                              <p className="small  h-35 text-gray">
                                                Forward Distance
                                                <p className="text-dark fw-bold ">
                                                  {
                                                    order_data?.delivery_details
                                                      ?.forward_distance_kms
                                                  }{" "}
                                                  Km
                                                </p>
                                              </p>
                                            </div>
                                            <div className="col-md-4 col-6 mb-2">
                                              <p className="small  h-35 text-gray">
                                                Return Distance
                                                <p className="text-dark fw-bold ">
                                                  {
                                                    order_data?.delivery_details
                                                      ?.return_distance_kms
                                                  }{" "}
                                                  Km
                                                </p>
                                              </p>
                                            </div>
                                            <div className="col-md-4 col-6 mb-2">
                                              <p className="small h-35 text-gray">
                                                Total Distance
                                                <p className="text-dark fw-bold ">
                                                  {
                                                    order_data?.delivery_details
                                                      ?.total_distance_kms
                                                  }{" "}
                                                  Km
                                                </p>
                                              </p>
                                            </div>

                                            <div className="col-md-4 col-6 mb-2">
                                              <p className="small  h-35 text-gray">
                                                Forward Distance Amount
                                                <p className="text-dark fw-bold ">
                                                  ₹{" "}
                                                  {
                                                    order_data?.delivery_details
                                                      ?.forward_distance_amount
                                                  }
                                                </p>
                                              </p>
                                            </div>
                                            <div className="col-md-4 col-6 mb-2">
                                              <p className="small  h-35 text-gray">
                                                Return Distance Amount
                                                <p className="text-dark fw-bold ">
                                                  ₹{" "}
                                                  {
                                                    order_data?.delivery_details
                                                      ?.return_distance_amount
                                                  }
                                                </p>
                                              </p>
                                            </div>

                                            <div className="col-md-4 col-6 mb-2">
                                              <p className="small pb-2 h-35 text-gray">
                                                Total Distance Amount
                                                <p className="text-dark fw-bold ">
                                                  ₹{" "}
                                                  {
                                                    order_data?.delivery_details
                                                      ?.total_distance_amount
                                                  }
                                                </p>
                                              </p>
                                            </div>
                                            <div className="col-md-4 col-6 mb-2">
                                              <p className="small  h-35 text-gray">
                                                Collected Delivery Charges
                                                <p className="text-dark fw-bold ">
                                                  ₹{" "}
                                                  {
                                                    order_data?.delivery_details
                                                      ?.collected_delivery_charges
                                                  }
                                                </p>
                                              </p>
                                            </div>

                                            <div className="col-md-4 col-6 mb-2">
                                              <p className="small  h-35 text-gray">
                                                Delivery Partner Amount
                                                <p className="text-dark fw-bold ">
                                                  ₹{" "}
                                                  {
                                                    order_data?.delivery_details
                                                      ?.delivery_partner_amount
                                                  }
                                                </p>
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="card  px-3 pb-1 ">
                                          {/* <TimelineAccordian> */}

                                          <OwnAccordian
                                            heading={"Delivery Company Log"}
                                          >
                                            <ul className="delivery_status_vertical w-100 ">
                                              {d_ele.status_timeline.map(
                                                (
                                                  time_ele: any,
                                                  time_index: any
                                                ) => (
                                                  <li>
                                                    <time className="extra-small text-end text-gray">
                                                      {time_ele?.time_formatted}
                                                    </time>
                                                    <div className="d-flex timeline">
                                                      {time_ele?.user
                                                        .profile ? (
                                                        <img
                                                          src={
                                                            time_ele?.user
                                                              .profile
                                                          }
                                                          className="me-1 user-img-timeline"
                                                        />
                                                      ) : (
                                                        <img
                                                          src={require("../../assets/img/profile.png")}
                                                          className="me-1 user-img-timeline"
                                                        />
                                                      )}
                                                      <div>
                                                        <p className="text-dark pb-1">
                                                          {time_ele?.status}
                                                        </p>
                                                        {time_ele?.user
                                                          .profile != "" && (
                                                          <p className="text-gray">
                                                            {" "}
                                                            {
                                                              time_ele?.user
                                                                .name
                                                            }
                                                          </p>
                                                        )}
                                                      </div>
                                                    </div>
                                                  </li>
                                                )
                                              )}
                                            </ul>
                                          </OwnAccordian>
                                        </div>
                                      </>
                                    </OwnAccordian>
                                  </div>
                                </div>
                              )
                            )
                        )
                      ) : (
                        <div>
                          <div className="card pt-3 px-3 pb-2 mb-3">
                            <h6 className="fw-bold text-dark">
                              Delivery Company Details
                            </h6>
                            <div>
                              <div className="mt-3">
                                <p className="text-gray">
                                  No Delivery Company Details
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="card px-3 pb-1 ">
                            {/* <TimelineAccordian> */}
                            <div className="">
                              <h6 className="text-dark fw-bold">
                                Delivery Company Log
                              </h6>
                              <div className="mt-3">
                                <p className="text-gray">
                                  No Delivery Company Log
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  {order_data.sector_id != 4 && (
                    <div>
                      {order_data?.shipments?.length != 0 ? (
                        order_data?.shipments?.map(
                          (ship_ele: any, ship_index: any) =>
                            ship_ele.super_delivery_order.map(
                              (d_ele: any, d_index: any) => (
                                <div>
                                  {d_ele.delivery_partner.map(
                                    (partner_ele: any, partner_index: any) => (
                                      <>
                                        <div className="card pt-3 px-3 pb-1 mb-3">
                                          <OwnAccordian
                                            heading={
                                              <div className="row">
                                                <b>
                                                  {`Delivery Partner Details  #` +
                                                    (partner_index + 1)}
                                                </b>
                                                <div className="col-7">
                                                  <p className="text-dark fw-bold pb-2 mt-3 extra-small">
                                                    #{partner_ele.id}
                                                  </p>
                                                  <div className="d-flex align-items-center">
                                                    <p className="text-gray text-end extra-small  pb-2">
                                                      {partner_ele.date_time}
                                                    </p>
                                                  </div>
                                                 
                                                </div>
                                                <div className="col-5">
                                                  <div className="end">
                                                  <div className="ms-auto">
                                                {partner_ele.status_id != 2 &&
                                                  partner_ele.status_id != 9 &&
                                                  partner_ele.status_id != 10 &&
                                                  partner_ele.status_id != 11 &&
                                                  partner_ele.status_id != 12 &&
                                                  partner_ele.status_id !=
                                                    13 && (
                                                    <button
                                                      className="btn btn-small btn-prime "
                                                      onClick={() => {
                                                        SetDeleteConfirmModalData(
                                                          (prevalue) => {
                                                            prevalue.is_open =
                                                              true;
                                                            prevalue.title =
                                                              "Delete Confirmation";
                                                            prevalue.body =
                                                              "Confirm Delete Partner Order";
                                                            prevalue.data = {
                                                              id: "delete_delivery_partner_order",
                                                              pass_data: {
                                                                id: partner_ele.id,
                                                              },
                                                            };
                                                            return {
                                                              ...prevalue,
                                                            };
                                                          }
                                                        );
                                                      }}
                                                    >
                                                      <span className="small">
                                                        Delete
                                                      </span>
                                                    </button>
                                                  )}
                                              </div>
                                                    <div
                                                      className={
                                                        partner_ele?.status ==
                                                        "Completed"
                                                          ? "color-dot-green"
                                                          : d_ele.status ==
                                                              "Cancelled" ||
                                                            d_ele.status ==
                                                              "Rejected"
                                                          ? "color-dot-red"
                                                          : "color-dot-blue"
                                                      }
                                                    ></div>
                                                    <p
                                                      className={
                                                        partner_ele?.status ==
                                                        "Completed"
                                                          ? "text-green ps-2"
                                                          : partner_ele?.status ==
                                                              "Cancelled" ||
                                                            partner_ele?.status ==
                                                              "Rejected"
                                                          ? "text-red ps-2"
                                                          : "text-blue ps-2"
                                                      }
                                                    >
                                                      {partner_ele?.status}
                                                    </p>
                                                  </div>
                                                  <div className="end">            
                              {d_ele.dm_order &&
                                  <span
                                      className="badge-1 me-2 badge-blue mt-2"
                                      >Demo</span>
                                                
}
</div>
</div>



                                                <div className="col-7">
                                                <div class="d-flex pt-2">
                                                    <img
                                                      src={require("../../assets/icon/profile.png")}
                                                      class="type-img"
                                                    />
                                                    <div class="ps-2">
                                                      <p class="extra-small">
                                                        Name
                                                      </p>
                                                      <p class=" pt-1 text-dark fw-bold">
                                                        {" "}
                                                        {
                                                          partner_ele
                                                            ?.delivery_partner
                                                            .name
                                                        }
                                                      </p>
                                                    </div>
                                                  </div>
                                              </div>
                                              <div className="col-5">
                                                    <div className="end">
                                                    <div className="ms-auto d-flex">
                                                    <div
                                        className="center  w-50 cursor"
                                        onClick={() => {
                                          // SetMapDriverModal({
                                          //   is_open: true,
                                          //   lat: ele.delivery[0]?.hyperlocal_pickup
                                          //     ?.pickup_lat,
                                          //   lon: ele.delivery[0]?.hyperlocal_pickup
                                          //     ?.pickup_lng,
                                          //   id: ele.delivery[0].id,
                                          // });
                                          // {console.log(order_data?.id)}
                                          SetOrderTrackingModal(
                                            (prevalue: any) => {
                                              prevalue.is_open = true;
                                              prevalue.delivery_index = partner_index;
                                              prevalue.delivery_id = ship_ele.id;
                                              prevalue.shipment_index = ship_index;
                                              return { ...prevalue };
                                            }
                                          );
                                        }}
                                      >
                                        
                                        {/* <Entypo
                                        
                                            name="location-pin"
                                            size={14}
                                            color="#666"
                                          /> */}
                                        <div>
                                          <img
                                            src={require("../../assets/icon/pin.png")}
                                            className="delivery"
                                          />

                                          <p className="extras-small pt-1 text-darkgray">
                                            Track
                                          </p>
                                        </div>
                                      </div>

                                        <div className="call-btn"
                                            onClick={() => {
                              
                                              call_del_func(
                                                order_data.id,
                                              "delivery_partner",
                                              partner_ele.id
                                              );
                                            }}
                                        >
                                          <Ionicons
                                            name="md-call"
                                            size={18}
                                            color="#A80032"
                                          />
                                        </div>
                                      </div>
                                                      </div>
                                                      </div>
                                                      </div>

                                            }
                                          >
                                            <div className="d-flex align-items-center">
                                            
                                            </div>

                                            <div className="row mt-3">
                                              {/* delivery type */}
                                              <div className="col-md-3 col-sm-6 col-12 pe-0 border-right d-flex pb-2">
                                                <img
                                                  src={require("../../assets/icon/truck.png")}
                                                  className="type-img"
                                                />
                                                <div className="ps-2">
                                                  <p className="text-gray">
                                                    Delivery Type
                                                  </p>
                                                  <p className="text-dark fw-bold pt-1">
                                                    {partner_ele?.hyperlocal
                                                      ? "HyperLocal"
                                                      : partner_ele?.courier
                                                      ? "Courier"
                                                      : null}
                                                  </p>
                                                </div>
                                              </div>

                                              {/* Distance */}
                                              <div className="col-md-3 col-sm-6 col-12 d-flex pb-2">
                                                <img
                                                  src={require("../../assets/icon/distance.png")}
                                                  className="type-img"
                                                />
                                                <div className="ps-2">
                                                  <p className="text-gray">
                                                    Distance
                                                  </p>
                                                  <p className="text-dark fw-bold pt-1">
                                                    {
                                                      partner_ele.payment_distance
                                                    }{" "}
                                                    Km
                                                  </p>
                                                </div>
                                              </div>

                                              <DeliveryPartnerSubComponenent
                                                data={
                                                  partner_ele?.estimate_onaccept
                                                }
                                                from={"On Pickup Accept"}
                                              />
                                              <DeliveryPartnerSubComponenent
                                                data={
                                                  partner_ele?.estimate_onpickedup
                                                }
                                                from={"On Delivery Accept"}
                                              />
                                              <DeliveryPartnerSubComponenent
                                                data={
                                                  partner_ele?.estimate_actual
                                                }
                                                from={"Actual"}
                                              />

                                              {/* Difference */}
                                              <div className="col-md-6 md-pe-3 sm-px-1">
                                                <div className="card pt-3 px-3 pb-1 mb-3">
                                                  <h6 className="text-dark fw-bold">
                                                    Difference
                                                  </h6>
                                                  <div className="row pt-3">
                                                    <div className="col-4 ">
                                                      <p className="text-gray h-30">
                                                        Company Name
                                                      </p>
                                                      <p className="pt-1 text-dark fw-bold">
                                                        N/A
                                                      </p>
                                                    </div>

                                                    <div className="col-4 ">
                                                      <p className="text-gray h-30">
                                                        Executive Name
                                                      </p>
                                                      <p className="pt-1 text-dark fw-bold">
                                                        N/A
                                                      </p>
                                                    </div>
                                                  </div>
                                                  <OwnAccordian
                                                    heading={"Pickup Details"}
                                                  >
                                                    <div className="row">
                                                      <div className="col-4 ">
                                                        <p className="text-gray h-30">
                                                          Distance
                                                        </p>
                                                        <p className="pt-1 text-dark fw-bold">
                                                          N/A
                                                        </p>
                                                      </div>
                                                      <div className="col-4 ">
                                                        <p className="text-gray h-30">
                                                          Distance Payable
                                                        </p>
                                                        <p className="pt-1 text-dark fw-bold">
                                                          N/A
                                                        </p>
                                                      </div>

                                                      <div className="col-4 ">
                                                        <p className="text-gray h-30">
                                                          Rate
                                                        </p>
                                                        <p className="pt-1 text-dark fw-bold">
                                                          N/A
                                                        </p>
                                                      </div>
                                                      <div className="col-4 ">
                                                        <p className="text-gray h-30">
                                                          Charge
                                                        </p>
                                                        <p className="pt-1 text-dark fw-bold">
                                                          N/A
                                                        </p>
                                                      </div>

                                                      <div className="col-4 ">
                                                        <p className="text-gray h-30">
                                                          Start Time
                                                        </p>
                                                        <p className="pt-1 text-dark fw-bold">
                                                          N/A
                                                        </p>
                                                      </div>
                                                      <div className="col-4 ">
                                                        <p className="text-gray h-30">
                                                          End Time
                                                        </p>
                                                        <p className="pt-1 text-dark fw-bold">
                                                          N/A
                                                        </p>
                                                      </div>

                                                      <div className="col-4 ">
                                                        <p className="text-gray h-30">
                                                          Duration
                                                        </p>
                                                        <p className="pt-1 text-dark fw-bold">
                                                          N/A
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </OwnAccordian>

                                                  <OwnAccordian
                                                    heading={"Delivery Details"}
                                                  >
                                                    <div className="row">
                                                      <div className="col-4 ">
                                                        <p className="text-gray h-30">
                                                          Distance
                                                        </p>
                                                        <p className="pt-1 text-dark fw-bold">
                                                          N/A
                                                        </p>
                                                      </div>
                                                      <div className="col-4 ">
                                                        <p className="text-gray h-30">
                                                          Distance Payable
                                                        </p>
                                                        <p className="pt-1 text-dark fw-bold">
                                                          N/A
                                                        </p>
                                                      </div>

                                                      <div className="col-4 ">
                                                        <p className="text-gray h-30">
                                                          Rate
                                                        </p>
                                                        <p className="pt-1 text-dark fw-bold">
                                                          N/A
                                                        </p>
                                                      </div>
                                                      <div className="col-4 ">
                                                        <p className="text-gray h-30">
                                                          Charge
                                                        </p>
                                                        <p className="pt-1 text-dark fw-bold">
                                                          N/A
                                                        </p>
                                                      </div>

                                                      <div className="col-4 ">
                                                        <p className="text-gray h-30">
                                                          Start Time
                                                        </p>
                                                        <p className="pt-1 text-dark fw-bold">
                                                          N/A
                                                        </p>
                                                      </div>
                                                      <div className="col-4 ">
                                                        <p className="text-gray h-30">
                                                          End Time
                                                        </p>
                                                        <p className="pt-1 text-dark fw-bold">
                                                          N/A
                                                        </p>
                                                      </div>

                                                      <div className="col-4 ">
                                                        <p className="text-gray h-30">
                                                          Duration
                                                        </p>
                                                        <p className="pt-1 text-dark fw-bold">
                                                          N/A
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </OwnAccordian>

                                                  <OwnAccordian
                                                    heading={"Return Details"}
                                                  >
                                                    <div className="row">
                                                      <h6 className="text-dark "></h6>
                                                      <div className="col-4 ">
                                                        <p className="text-gray h-30">
                                                          Distance
                                                        </p>
                                                        <p className="pt-1 text-dark fw-bold">
                                                          N/A
                                                        </p>
                                                      </div>
                                                      <div className="col-4 ">
                                                        <p className="text-gray h-30">
                                                          Distance Payable
                                                        </p>
                                                        <p className="pt-1 text-dark fw-bold">
                                                          N/A
                                                        </p>
                                                      </div>

                                                      <div className="col-4 ">
                                                        <p className="text-gray h-30">
                                                          Rate
                                                        </p>
                                                        <p className="pt-1 text-dark fw-bold">
                                                          N/A
                                                        </p>
                                                      </div>
                                                      <div className="col-4 ">
                                                        <p className="text-gray h-30">
                                                          Charge
                                                        </p>
                                                        <p className="pt-1 text-dark fw-bold">
                                                          N/A
                                                        </p>
                                                      </div>

                                                      <div className="col-4 ">
                                                        <p className="text-gray h-30">
                                                          Start Time
                                                        </p>
                                                        <p className="pt-1 text-dark fw-bold">
                                                          N/A
                                                        </p>
                                                      </div>
                                                      <div className="col-4 ">
                                                        <p className="text-gray h-30">
                                                          End Time
                                                        </p>
                                                        <p className="pt-1 text-dark fw-bold">
                                                          N/A
                                                        </p>
                                                      </div>

                                                      <div className="col-4 ">
                                                        <p className="text-gray h-30">
                                                          Duration
                                                        </p>
                                                        <p className="pt-1 text-dark fw-bold">
                                                          N/A
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </OwnAccordian>
                                                </div>
                                              </div>
                                              {/* </div> */}
                                              <div className="card  px-3 pb-1 ">
                                                {/* <TimelineAccordian> */}
                                                <OwnAccordian
                                                  heading={
                                                    "Delivery Partner Log"
                                                  }
                                                >
                                                  <ul className="delivery_status_vertical w-100 ">
                                                    {partner_ele.status_timeline.map(
                                                      (
                                                        time_ele: any,
                                                        time_index: any
                                                      ) => (
                                                        <li>
                                                          <time className="extra-small text-end text-gray">
                                                            {
                                                              time_ele?.time_formatted
                                                            }
                                                          </time>
                                                          <div className="d-flex timeline">
                                                            {time_ele?.user
                                                              .profile ? (
                                                              <img
                                                                src={
                                                                  time_ele?.user
                                                                    .profile
                                                                }
                                                                className="me-1 user-img-timeline"
                                                              />
                                                            ) : (
                                                              <img
                                                                src={require("../../assets/img/profile.png")}
                                                                className="me-1 user-img-timeline"
                                                              />
                                                            )}
                                                            <div>
                                                              <p className="text-dark pb-1">
                                                                {
                                                                  time_ele?.status
                                                                }
                                                              </p>
                                                              {time_ele?.user
                                                                .profile !=
                                                                "" && (
                                                                <p className="text-gray">
                                                                  {" "}
                                                                  {
                                                                    time_ele
                                                                      ?.user
                                                                      .name
                                                                  }
                                                                </p>
                                                              )}
                                                            </div>
                                                            <div className="w-20 ms-auto">
                                                          {time_ele.hasOwnProperty(
                                                            "lat"
                                                          ) && (
                                                            <button
                                                              type="button"
                                                              className="btn btn-prime btn-small px-3"
                                                              onClick={() => {
                                                                openMap(
                                                                  time_ele?.lat,
                                                                  time_ele?.lng
                                                                );
                                                              }}
                                                            >
                                                              <span className="small">
                                                                Map
                                                              </span>
                                                            </button>
                                                          )}
                                                        </div>
                                                          </div>
                                                        </li>
                                                      )
                                                    )}
                                                  </ul>
                                                </OwnAccordian>
                                              </div>
                                              <div className="card  px-3 pb-1 ">
                                                <h6 className="text-dark fw-bold">
                                                  Delivery Partner Feedback
                                                </h6>
                                                {order_data.hasOwnProperty(
                                                  "delivery_partner_feedback"
                                                ) ? (
                                                  <div className="mt-3">
                                                    <div className="d-flex align-items-center">
                                                      <StarRatings
                                                        rating={
                                                          order_data
                                                            .delivery_partner_feedback
                                                            .rating
                                                            ? parseFloat(
                                                                order_data
                                                                  .delivery_partner_feedback
                                                                  .rating
                                                              )
                                                            : 0
                                                        }
                                                        starDimension="20px"
                                                        starSpacing="5px"
                                                        starEmptyColor="#ccc"
                                                        starRatedColor="orange"
                                                      />
                                                    </div>
                                                    <p className="pt-2">
                                                      {
                                                        order_data
                                                          .delivery_partner_feedback
                                                          .message
                                                      }
                                                    </p>
                                                  </div>
                                                ) : (
                                                  <p className="text-gray">
                                                    No Delivery Partner Feedback
                                                  </p>
                                                )}
                                              </div>
                                              {order_data?.hasOwnProperty(
                                                "shipments"
                                              ) &&
                                                order_data?.shipments.length >
                                                  0 &&
                                                order_data?.shipments[0]
                                                  ?.super_delivery_order[0]
                                                  .delivery_partner[0]
                                                  ?.settlement &&
                                                order_data?.shipments[0]
                                                  ?.super_delivery_order[0]
                                                  .delivery_partner[0]
                                                  ?.status_id == "10" && (
                                                  <div className="mt-2 card pt-3 px-3 pb-1">
                                                    <div className="py-1">
                                                      <div className="row px-0">
                                                        <div className="fw-bold text-black mb-3 fs-7 ps-0">
                                                          Resturant Delivery
                                                          Partner Settlement
                                                        </div>
                                                        <div className="timeline-1">
                                                          <div className="d-flex">
                                                            <div
                                                              className={
                                                                order_data
                                                                  ?.shipments[0]
                                                                  ?.super_delivery_order[0]
                                                                  .delivery_partner[0]
                                                                  ?.settlement
                                                                  ?.settlement_eligible
                                                                  ? "timeline-dot active"
                                                                  : "timeline-dot"
                                                              }
                                                            >
                                                              {order_data
                                                                ?.shipments[0]
                                                                ?.super_delivery_order[0]
                                                                .delivery_partner[0]
                                                                ?.settlement
                                                                ?.settlement_eligible && (
                                                                <img
                                                                  src={
                                                                    AppConfig.CDN_Media_url +
                                                                    "be453854bc89e35e154efa5e26814afa.svg"
                                                                  }
                                                                  className="img-fluid"
                                                                  width={10}
                                                                />
                                                              )}
                                                            </div>
                                                            <div className="w-100 ps-2">
                                                              Settlement
                                                              Eligible
                                                            </div>
                                                          </div>
                                                          <div className="d-flex">
                                                            <div className="timeline-line-container">
                                                              <div
                                                                className={
                                                                  order_data
                                                                    ?.pickup_drop[0]
                                                                    ?.delivery_partner[0]
                                                                    ?.settlement
                                                                    ?.settlement_approved
                                                                    ? "timeline-line active"
                                                                    : "timeline-line"
                                                                }
                                                              ></div>
                                                            </div>
                                                            <div className="w-100"></div>
                                                          </div>
                                                          <div className="d-flex">
                                                            <div
                                                              className={
                                                                order_data
                                                                  ?.shipments[0]
                                                                  ?.super_delivery_order[0]
                                                                  .delivery_partner[0]
                                                                  ?.settlement
                                                                  ?.settlement_approved
                                                                  ? "timeline-dot active"
                                                                  : "timeline-dot"
                                                              }
                                                            >
                                                              {order_data
                                                                ?.shipments[0]
                                                                ?.super_delivery_order[0]
                                                                .delivery_partner[0]
                                                                ?.settlement
                                                                ?.settlement_approved && (
                                                                <img
                                                                  src={
                                                                    AppConfig.CDN_Media_url +
                                                                    "be453854bc89e35e154efa5e26814afa.svg"
                                                                  }
                                                                  className="img-fluid"
                                                                  width={10}
                                                                />
                                                              )}
                                                            </div>
                                                            <div className="w-100 ps-2">
                                                              Settlement
                                                              Approved
                                                            </div>
                                                          </div>
                                                          <div className="d-flex">
                                                            <div className="timeline-line-container">
                                                              <div
                                                                className={
                                                                  order_data
                                                                    ?.shipments[0]
                                                                    ?.super_delivery_order[0]
                                                                    .delivery_partner[0]
                                                                    ?.settlement
                                                                    ?.settlement_paid
                                                                    ? "timeline-line active"
                                                                    : "timeline-line"
                                                                }
                                                              ></div>
                                                            </div>
                                                            <div className="w-100"></div>
                                                          </div>
                                                          <div className="d-flex">
                                                            <div
                                                              className={
                                                                order_data
                                                                  ?.shipments[0]
                                                                  ?.super_delivery_order[0]
                                                                  .delivery_partner[0]
                                                                  ?.settlement
                                                                  ?.settlement_paid
                                                                  ? "timeline-dot active"
                                                                  : "timeline-dot"
                                                              }
                                                            >
                                                              {order_data
                                                                ?.shipments[0]
                                                                ?.super_delivery_order[0]
                                                                .delivery_partner[0]
                                                                ?.settlement
                                                                ?.settlement_paid && (
                                                                <img
                                                                  src={
                                                                    AppConfig.CDN_Media_url +
                                                                    "be453854bc89e35e154efa5e26814afa.svg"
                                                                  }
                                                                  className="img-fluid"
                                                                  width={10}
                                                                />
                                                              )}
                                                            </div>
                                                            <div className="w-100 ps-2">
                                                              Settlement Paid
                                                              {order_data
                                                                ?.shipments[0]
                                                                ?.super_delivery_order[0]
                                                                .delivery_partner[0]
                                                                ?.settlement
                                                                ?.settlement_paid && (
                                                                <div>
                                                                  <div>
                                                                    {
                                                                      order_data
                                                                        ?.shipments[0]
                                                                        ?.super_delivery_order[0]
                                                                        .delivery_partner[0]
                                                                        ?.settlement
                                                                        ?.settlement_paid_time
                                                                    }
                                                                  </div>
                                                                  <div className="fw-500 fs-15">
                                                                    &#8377;{" "}
                                                                    {
                                                                      order_data
                                                                        ?.shipments[0]
                                                                        ?.super_delivery_order[0]
                                                                        .delivery_partner[0]
                                                                        ?.settlement
                                                                        ?.settlement_paid_amount
                                                                    }
                                                                  </div>
                                                                </div>
                                                              )}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                )}
                                            </div>
                                          </OwnAccordian>
                                        </div>
                                      </>
                                    )
                                  )}
                                  <div className="mt-2 card  px-2 shadow">
                                    {order_data.sector_id != 4 && (
                                      <div>
                                        {order_data?.shipments?.length != 0 &&
                                          order_data?.shipments?.map(
                                            (
                                              ship_ele: any,
                                              ship_index: any
                                            ) => (
                                              <>
                                                {/* <TimelineAccordian> */}
                                                <OwnAccordian
                                                  heading={"Shipment Log"}
                                                >
                                                  <ul className="delivery_status_vertical w-100 pt-2">
                                                    {ship_ele.status_timeline.map(
                                                      (
                                                        time_ele: any,
                                                        time_index: any
                                                      ) => (
                                                        <li>
                                                          <time className="extra-small text-end text-gray">
                                                            {
                                                              time_ele?.time_formatted
                                                            }
                                                          </time>
                                                          <div className="d-flex timeline">
                                                            {time_ele?.user
                                                              .profile ? (
                                                              <img
                                                                src={
                                                                  time_ele?.user
                                                                    .profile
                                                                }
                                                                className="me-1 user-img-timeline"
                                                              />
                                                            ) : (
                                                              <img
                                                                src={require("../../assets/img/profile.png")}
                                                                className="me-1 user-img-timeline"
                                                              />
                                                            )}
                                                            <div>
                                                              <p className="text-dark pb-1">
                                                                {
                                                                  time_ele?.status
                                                                }
                                                              </p>
                                                              {time_ele?.user
                                                                .profile !=
                                                                "" && (
                                                                <p className="text-gray">
                                                                  {" "}
                                                                  {
                                                                    time_ele
                                                                      ?.user
                                                                      .name
                                                                  }
                                                                </p>
                                                              )}
                                                            </div>
                                                          </div>
                                                        </li>
                                                      )
                                                    )}
                                                  </ul>
                                                </OwnAccordian>
                                              </>
                                            )
                                          )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )
                            )
                        )
                      ) : (
                        <div className="row">
                          <div className="card pt-3 px-3 pb-1 mb-3">
                            <div className="d-flex align-items-center">
                              <h6 className="fw-bold text-dark">
                                Delivery Partner Details
                              </h6>
                            </div>
                            <div className="mt-3">
                              <p className="text-gray">
                                No Delivery Partner Details
                              </p>
                            </div>
                          </div>

                          <div className="card pt-3 px-3 pb-1 mb-3">
                            <h6 className="text-dark fw-bold">
                              Platform Feedback
                            </h6>
                            {order_data.hasOwnProperty("platform_feedback") ? (
                              <div className="mt-3">
                                <div className="d-flex align-items-center">
                                  <StarRatings
                                    rating={
                                      order_data.platform_feedback.rating
                                        ? parseFloat(
                                            order_data.platform_feedback.rating
                                          )
                                        : 0
                                    }
                                    starDimension="20px"
                                    starSpacing="5px"
                                    starEmptyColor="#ccc"
                                    starRatedColor="orange"
                                  />
                                </div>
                                <p className="pt-2">
                                  {order_data.platform_feedback.message}
                                </p>
                              </div>
                            ) : (
                              <div className="mt-3">
                                <p className="text-gray">
                                  No Platform Feedback
                                </p>
                              </div>
                            )}
                          </div>

                          <div className="card pt-3 px-3 pb-1 mb-3">
                            <h6 className="text-dark fw-bold">
                              Product Feedback
                            </h6>

                            <div className="mt-3">
                              <p className="text-gray">No Product Feedback</p>
                            </div>
                            {/* )} */}
                          </div>

                          <div className="card px-3 pb-1 mb-3">
                            <h6 className="text-dark fw-bold">
                              Delivery Partner Feedback
                            </h6>
                            {order_data.hasOwnProperty(
                              "delivery_partner_feedback"
                            ) ? (
                              <div className="mt-3">
                                <div className="d-flex align-items-center">
                                  <StarRatings
                                    rating={
                                      order_data.delivery_partner_feedback
                                        .rating
                                        ? parseFloat(
                                            order_data.delivery_partner_feedback
                                              .rating
                                          )
                                        : 0
                                    }
                                    starDimension="20px"
                                    starSpacing="5px"
                                    starEmptyColor="#ccc"
                                    starRatedColor="orange"
                                  />
                                </div>
                                <p className="pt-2">
                                  {order_data.delivery_partner_feedback.message}
                                </p>
                              </div>
                            ) : (
                              <p className="text-gray">
                                No Delivery Partner Feedback
                              </p>
                            )}
                          </div>

                          <div className="col-md-6  md-pe-3  sm-px-1">
                            <div className="card  px-3 pb-1 ">
                              {/* <TimelineAccordian> */}
                              <div className="">
                                <h6 className="text-dark fw-bold">
                                  Delivery Partner Log
                                </h6>
                                <div className="mt-3">
                                  <p className="text-gray">
                                    No Delivery Partner Log
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>

                {order_data?.reservations &&
                <div className="mt-2 card pt-3 px-2 shadow">
                    <fieldset className="mb-2">
                      <legend className="legendLeft">Reservations</legend>
                      <div className="row px-3">
                                  
                                      <div className="col-7">
                                        <p className="text-dark fw-bold pb-2 mt-3 extra-small">
                                      
                                          #{order_data?.reservations[0]?.brand_id}
                                        </p>
                                   
                                 
                                            <p className="pt-1 text-dark fw-bold">
                                              {/* {item.brand_name} */}
                                        {order_data?.reservations[0]?.brand_name}

                                            </p>
                                       
                                        </div>
                              
                                      <div className="col-5">
                                        <div className="end mt-3">
                                          {/* <div
                                            className={
                                              item.status == "Completed"
                                                ? "color-dot-green"
                                                : item.status == "Cancelled" ||
                                                  item.status == "Rejected"
                                                ? "color-dot-red"
                                                : "color-dot-blue"
                                            }
                                          ></div> */}
                                          <p
                                            className={
                                              order_data?.reservations[0]?.status == "Assigned"
                                                && "text-green ps-2"
                                                // : item.status == "Cancelled" ||
                                                //   item.status == "Rejected"
                                                // ? "text-red ps-2"
                                                // : "text-blue ps-2"
                                            }
                                          >
                                            {order_data?.reservations[0]?.status}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                
                     
                     <div className="pt-3 px-3 pb-1">
                                    {order_data?.reservations[0]?.delivery_partners &&
                                    <div className="row mb-2">

<div className="col-7 d-flex">
  <div className="d-flex pt-2">
   
     <img
     src={
      order_data?.reservations[0]?.delivery_partners[0]?.delivery_partner.profile != null
      ? order_data?.reservations[0]?.delivery_partners[0]?.delivery_partner.profile :
      require("../../assets/icon/profile.png")}
     className="type-img"
   />


   

    <div className="ps-2">
    <p className=" pt-1 text-dark fw-bold">
       # {order_data?.reservations[0]?.delivery_partners[0]?.id}
      
      </p>
      <p className=" pt-1 text-dark fw-bold">
        {order_data?.reservations[0]?.delivery_partners[0]?.delivery_partner.name}
      
      </p>

    </div>
  </div>
</div>
<div className="col-5 p-0">
  <div className="end">
  <p
                                            className={
                                              order_data?.reservations[0]?.delivery_partners[0]?.status == "Assigned"
                                                && "text-green ps-2"
                                                // : item.status == "Cancelled" ||
                                                //   item.status == "Rejected"
                                                // ? "text-red ps-2"
                                                // : "text-blue ps-2"
                                            }
                                          >
                                          {order_data?.reservations[0]?.delivery_partners[0]?.status}
                                          </p>
  </div>
</div>


</div>
}
</div>



                    </fieldset>
                  </div>
        }

<div className="mt-2 card pt-3 px-2 shadow">
<OwnAccordian
                                                  heading={"Order Log"}
                                                >
                                                  <ul className="delivery_status_vertical w-100 pt-2">
                                                    {order_data?.status_timeline?.map(
                                                      (
                                                        time_ele: any,
                                                        time_index: any
                                                      ) => (
                                                        <li>
                                                          <time className="extra-small text-end text-gray">
                                                            {
                                                              time_ele?.time_formatted
                                                            }
                                                          </time>
                                                          <div className="d-flex timeline">
                                                            {time_ele?.user
                                                              .profile ? (
                                                              <img
                                                                src={
                                                                  time_ele?.user
                                                                    .profile
                                                                }
                                                                className="me-1 user-img-timeline"
                                                              />
                                                            ) : (
                                                              <img
                                                                src={require("../../assets/img/profile.png")}
                                                                className="me-1 user-img-timeline"
                                                              />
                                                            )}
                                                            <div>
                                                              <p className="text-dark pb-1">
                                                                {
                                                                  time_ele?.status
                                                                }
                                                              </p>
                                                              {time_ele?.user
                                                                .profile !=
                                                                "" && (
                                                                <p className="text-gray">
                                                                  {" "}
                                                                  {
                                                                    time_ele
                                                                      ?.user
                                                                      .name
                                                                  }
                                                                </p>
                                                              )}
                                                            </div>
                                                          </div>
                                                        </li>
                                                      )
                                                    )}
                                                  </ul>
                                                </OwnAccordian>
                                                </div>

                                                {order_data?.reminder_call_logs &&
                <div className="mt-2 card pt-3 px-2 shadow">
                    <fieldset className="mb-2">
                      <legend className="legendLeft">Reminder Call Logs</legend>
                      <div className="pt-3 px-3 pb-1">
                      {order_data?.reminder_call_logs?.map((ele,i)=>(
                                

                              
                                <div className="row mb-2">
    
                                  <div className="col-11 d-flex">
                                    <div className="d-flex pt-2">
                                     
                                       <img
                                       src={
                                        ele.profile != null
                                        ? ele.profile :
                                        require("../../assets/icon/profile.png")}
                                       className="type-img"
                                     />
    
                                  
                                     
    
                                      <div className="ps-2">
                                        {/* <p className="extra-small">Primary</p> */}
                                        <p className=" pt-1 text-dark fw-bold">
                                          {ele.name}
                                        
                                        </p>
                                        <p className=" pt-1 text-dark fw-bold">
                                          {ele.mobile}
                                        
                                        </p>
                                        <p className=" pt-1 text-dark fw-bold">
                                          {ele.time}
                                        
                                        </p>

                                        
                                      </div>
                                    </div>
                                  </div>
                         
    
                                  {/* <hr className="mt-2" />
    
                                  <div className="col-11 d-flex">
                                    <div className="d-flex pt-2">
                                      <img
                                        src={require("../../assets/icon/profile.png")}
                                        className="type-img"
                                      />
    
                                      <div className="ps-2">
                                        <p className="extra-small">Secondary</p>
                                        <p className=" pt-1 text-dark fw-bold">
                                          Lokesh
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-1 row align-items-center p-0">
                                    <div className="ms-auto">
                                      <div className="call-btn">
                                        <Ionicons
                                          name="md-call"
                                          size={18}
                                          color="#A80032"
                                        />
                                      </div>
                                    </div>
                                  </div> */}
                                </div>
                                  ))}

</div>


                    </fieldset>
                  </div>
        }

                {order_data?.refunds && order_data.refunds.length > 0 && (
                  <div className="card pt-3 px-3 pb-1 mb-3">
                    <h6>Refunds</h6>

                    {order_data?.refunds?.map((refund_item, i) => (
                      <>
                        <div className="d-flex">
                          <div>{refund_item.reason}</div>

                          {refund_item.refunded ? (
                            <span className="badge-2 w-25 w-auto ms-2">
                              Refunded
                            </span>
                          ) : (
                            <span className="badge-2 w-25 w-auto">pending</span>
                          )}
                        </div>

                        <OwnAccordian heading={"₹ " + refund_item.amount}>
                          <ul className="delivery_status_vertical w-100 pt-2">
                            <li>
                              <time className="extra-small text-end text-gray"></time>

                              <div className="d-flex timeline">
                                <div>
                                  <p className="text-dark pb-1">
                                    1Superapp has initiated your refund
                                  </p>

                            
                                  <p className="text-gray">
                                    {" "}
                                    {refund_item.time}
                                  </p>
                                </div>
                              </div>
                            </li>

                            <li>
                              <time className="extra-small text-end text-gray"></time>

                              <div className="d-flex timeline">
                                <div>
                                  <p className="text-dark pb-1">
                                    Refund credited to your account
                                  </p>

                                  <p className="text-gray">
                                    {" "}
                                    {refund_item.refunded_time}
                                  </p>
                                  <p className="text-gray">
                                    {" "}
                                    {refund_item.txn_id}
                                  </p>
                                </div>
                              </div>
                            </li>
                            {/* )
                                )} */}
                          </ul>

                          <h6>Refund Eligible Reason</h6>
                          <ul >
                          {refund_item.refund_eligible_reason.map((ele,i)=>(
                            <li>
                              {ele}
                            </li>
                            ))}
                            </ul>
                        </OwnAccordian>
                      </>
                    ))}
                    {/* </TimelineAccordian> */}
                  </div>
                )}
              </div>

            <div className={props.id != undefined ?"col-12":"col-md-4 col-sm-6 col-12 md-pe-3  sm-px-1"}>

              <div className="mb-2">
          <div className="mt-2 card pt-3 px-3 pb-1">
            <div className="py-1">
              <div className="row px-0">
                <div className="d-flex">
                <div className="fw-bold text-black mb-3 fs-7 ps-0">
                  Seller Settlement
                </div>

                {/* <div className="ms-2">
                    <b
                         style={{ color: "blue", cursor: "pointer" }}
                            onClick={() => {
                                const set_data = {
                                    value: order_data.id,
                                    eligible: order_data?.seller_settlement?.settlement_eligible,
                                    approve: order_data?.seller_settlement?.settlement_approved,
                                    paid: order_data?.seller_settlement?.settlement_paid,
                                    from:"details",
                                      price : order_data.seller_settlement.settlement_paid? order_data.seller_settlement.settlement_paid_amount :  order_data.total_amount_payable
                             
                                  
                                    
                                  };
                                  setSettleUpdateModal({
                                    is_open: true,
                                    data: set_data,
                                  });

                                  
                            }}
                          >
                            Update
                          </b>
                          </div> */}
                          </div>


                <div className="timeline-1">
                  <div className="d-flex">
                 
                     <div className={order_data?.seller_settlement?.settlement_eligible ? "timeline-dot cursor active" : "timeline-dot cursor"} 
                     onClick={() => {
                         if(order_data?.seller_settlement?.settlement_eligible){
                             setConfirmModal({
                                 is_open: true,
                                 data: {
                                     action: 'eligible',
                                     title: 'Attention!!!',
                                     desc: 'Are you sure want to make it not eligible',
                                     button: 'Confirm',
                                     order: order_data,
                                     key: 0
                                 },
                             });
                         } else {
                             setAmountModal({
                                 is_open: true,
                                 data: {
                                     action: 'eligible',
                                     title: 'Make as eligible',
                                     button: 'Submit',
                                     order: order_data,
                                     askMessage: true,
                                     key: 0
                                 },
                             });
                         }
                     }}
                     >
                      {order_data?.seller_settlement?.settlement_eligible && (
                        <img
                          src={
                            AppConfig.CDN_Media_url +
                            "be453854bc89e35e154efa5e26814afa.svg"
                          }
                          className="img-fluid"
                          width={10}
                        />
                      )}
                    </div>
                    <div className="d-flex">
                    <div >
                    <div className="w-100 ps-2">Settlement Eligible</div>
                
                          
                          </div>
                          <div className="d-flex">
                    
                 
                    <span className="fs-11px"> <i className="fas fa-info-circle fs-7 text-primary cursor mx-2" onClick={() => {setLogModal({ is_open: true, data: {order: order_data}, });}}></i></span>
                                {order_data?.seller_settlement?.settlement_paid ? (
                                    <div className="fw-500 fs-15">&#8377; {order_data?.seller_settlement?.settlement_eligible_amount} <div className="fs-11px">Balance: &#8377; {(order_data?.seller_settlement?.settlement_eligible_amount - order_data?.seller_settlement?.settlement_paid_amount > 0) ? order_data?.seller_settlement?.settlement_eligible_amount - order_data?.seller_settlement?.settlement_paid_amount : 0}</div></div>
                                ) : (
                                    <div className="fw-500 fs-15">&#8377; {order_data?.seller_settlement?.settlement_eligible_amount}</div>
                                )}
                                {(order_data?.seller_settlement?.settlement_eligible) && (
                                    <button type="button" className="btn btn-sm p-0 text-primary mx-2" 
                                        onClick={() => {
                                            setAmountModal({
                                                is_open: true,
                                                data: {
                                                    action: 'eligible',
                                                    title: 'Update eligible amount',
                                                    button: 'Submit',
                                                    order: order_data,
                                                    askMessage: true,
                                                    key: 0
                                                },
                                            });
                                        }}
                                    >
                                        Update
                                    </button>
                                )}
                                {order_data?.seller_settlement?.settlement_eligible_notes && (
                                    <div className="text-muted fs-10px">Notes: {order_data?.seller_settlement?.settlement_eligible_notes}</div>
                                )}
                    {/* <b
                         style={{ color: "blue", cursor: "pointer" }}
                            onClick={() => {
                                const set_data = {
                                    value: order_data.id,
                                    dm: order_data?.seller_settlement?.settlement_eligible,
                                    from:"details",
                                  
                                    
                                  };
                                  setSetEleModal({
                                    is_open: true,
                                    data: set_data,
                                  });

                                  
                            }}
                          >
                            Update
                          </b> */}
                        
{/*                       
                    Eligible Amount: ₹ {order_data?.seller_settlement?.settlement_eligible_amount}
                  {", " + order_data?.seller_settlement?.settlement_eligible_notes}
                   

 */}


             

                    {/* <b
                         style={{ color: "blue", cursor: "pointer" }}
                            onClick={() => {
                              const set_data = {
                                    value: order_data.id,
                                    dm: order_data?.seller_settlement?.settlement_paid,
                                  from:"details",
                                    price : order_data.seller_settlement.settlement_paid? order_data.seller_settlement.settlement_paid_amount :  order_data.total_amount_payable
                                  };
                                  setPaidModal({
                                    is_open: true,
                                    data: set_data,
                                  });

                                  
                            }}
                          >
                            Update
                          </b> */}

</div>   </div>
                  </div>
                  

                  <div className="d-flex">
                    <div className="timeline-line-container">
                      <div
                        className={
                          order_data?.seller_settlement?.settlement_approved
                            ? "timeline-line active"
                            : "timeline-line"
                        }
                      ></div>
                    </div>
                  
                  </div>
                  <div className="d-flex">
                  <div className={order_data?.seller_settlement?.settlement_approved ? "timeline-dot cursor active" : "timeline-dot cursor"}
                            onClick={() => {
                                if(order_data?.seller_settlement?.settlement_approved){
                                    setConfirmModal({
                                        is_open: true,
                                        data: {
                                            action: 'approved',
                                            title: 'Attention!!!',
                                            desc: 'Are you sure want to make it not approved',
                                            button: 'Confirm',
                                            order: order_data,
                                            key: 0
                                        },
                                    });
                                } else {
                                    if(order_data?.seller_settlement?.settlement_eligible){
                                        settlementUpdate({action: 'approved', status: 1, order_id: order_data?.id, key: 0});
                                    } else {
                                        toast.error("This order is not eligible for approved");
                                    }
                                }
                            }}
                            >
                                {order_data?.seller_settlement?.settlement_approved && (<img src={AppConfig.CDN_Media_url + "be453854bc89e35e154efa5e26814afa.svg"} className="img-fluid" width={10} />)}
                            </div>
                            <div className="w-100 ps-2">
                                                            Settlement Approved
                                                          </div>
                  </div>
                  <div className="d-flex">
                    <div className="timeline-line-container">
                      <div
                        className={
                          paid
                            ? "timeline-line active"
                            : "timeline-line"
                        }
                      ></div>
                    </div>
                    <div className="w-100"></div>
                  </div>
                  <div className="d-flex">
                  <div className={order_data?.seller_settlement?.settlement_paid ? "timeline-dot cursor active" : "timeline-dot cursor"}
                            onClick={() => {
                                if(order_data?.seller_settlement?.settlement_paid){
                                    setConfirmModal({
                                        is_open: true,
                                        data: {
                                            action: 'paid',
                                            title: 'Attention!!!',
                                            desc: 'Are you sure want to make it unpaid',
                                            button: 'Confirm',
                                            order: order_data,
                                            key: 0
                                        },
                                    });
                                } else {
                                    if(order_data?.seller_settlement?.settlement_eligible && order_data?.seller_settlement?.settlement_approved){
                                        setAmountModal({
                                            is_open: true,
                                            data: {
                                                action: 'paid',
                                                title: 'Pay Settlement',
                                                button: 'Submit',
                                                order: order_data,
                                                key: 0
                                            },
                                        });
                                    } else if(order_data?.seller_settlement?.settlement_eligible == false){
                                        toast.error("This order is not eligible to pay settlement");
                                    } else if(order_data?.seller_settlement?.settlement_approved == false){
                                        toast.error("This order is not approved to pay settlement");
                                    }
                                }
                            }}
                            >
                                {order_data?.seller_settlement?.settlement_paid && (<img src={AppConfig.CDN_Media_url + "be453854bc89e35e154efa5e26814afa.svg"} className="img-fluid" width={10} />)}
                            </div>


                                                          <div className="w-100 ps-2">
                                                          Settlement Paid
                                {order_data?.seller_settlement?.settlement_paid && (
                                    <div className="fw-500 fs-15">
                                        &#8377; {order_data?.seller_settlement?.settlement_paid_amount}
                                    </div>
                                )}
                                {(order_data?.seller_settlement?.settlement_paid) && (
                                    <button type="button" className="btn btn-sm p-0 text-primary" 
                                        onClick={() => {
                                            setAmountModal({
                                                is_open: true,
                                                data: {
                                                    action: 'paid',
                                                    title: 'Update paid amount',
                                                    button: 'Submit',
                                                    order: order_data,
                                                    askMessage: true,
                                                    key: 0
                                                },
                                            });
                                        }}
                                    >
                                        Update
                                    </button>
                                )}
                            </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-2">
          <div className="mt-2 card pt-3 px-3 pb-1">
            <div className="py-1">
              <div className="row px-0">
            
<b>Seller Instruction</b>

<div>{order_data?.seller_instructions?.length>0?
order_data.seller_instructions.map((ele,i) =>(
  <>
<p>{ele.message}</p>
  <span
  className={
    ele.deleted &&
      "badge-1 me-2 badge-red-2"
  }
>
  {ele?.status}
</span>
</>
)):"-"
}</div>

<b>Delivery Instruction</b>

<div>{order_data?.delivery_instructions?.length>0?
order_data.delivery_instructions.map((ele,i) =>(
  <>
<p>{ele.message}</p>
{ele.deleted &&
  <span
  className={
    ele.deleted &&
      "badge-1 me-2 badge-red-2"
  }
>
 Removed
</span>}
</>
)):"-"
}</div>




              
              </div>
            </div>
          </div>
          <div className="mt-2 card pt-3 px-3 pb-1">
            <div className="py-1">
              <div className="row px-0">
            
<b>Admin Internal Notes</b>

<div className="mt-3">{order_data?.admin_internal_notes?.length>0?
order_data.admin_internal_notes.map((ele,i) =>(
  <div>
<div>{ele.message}</div>
<p>{ele.time}</p>
<div className="row mb-2">

<div className="col-11 d-flex">
  <div className="d-flex pt-2">
   
     <img
     src={
      ele.profile != null
      ? ele.user.profile :
      require("../../assets/icon/profile.png")}
     className="type-img"
   />


   

    <div className="ps-2">
      {/* <p className="extra-small">Primary</p> */}
      <p className=" pt-1 text-dark fw-bold">
        {ele.user.name}
      
      </p>
      <p className=" pt-1 text-dark fw-bold">
        {ele.user.mobile}
      
      </p>
    </div>
  </div>
</div>
<div className="col-1 row align-items-center p-0">
  <div className="ms-auto">
  <a href={"tel:" +ele.user.mobile} style={{ textDecoration: 'none' }}>
    <div className="call-btn"
    // onClick={() => {

    //   call_func(
    //     order_data.id,
    //   "outlet"
    //   );
    // }}
    >
      <Ionicons
        name="md-call"
        size={18}
        color="#A80032"
      />
    </div>
    </a>
  </div>
</div>

{/* <hr className="mt-2" />

<div className="col-11 d-flex">
  <div className="d-flex pt-2">
    <img
      src={require("../../assets/icon/profile.png")}
      className="type-img"
    />

    <div className="ps-2">
      <p className="extra-small">Secondary</p>
      <p className=" pt-1 text-dark fw-bold">
        Lokesh
      </p>
    </div>
  </div>
</div>
<div className="col-1 row align-items-center p-0">
  <div className="ms-auto">
    <div className="call-btn">
      <Ionicons
        name="md-call"
        size={18}
        color="#A80032"
      />
    </div>
  </div>
</div> */}
</div>
  {/* <span
  className={
    ele.deleted &&
      "badge-1 me-2 badge-red-2"
  }
>
  {ele?.status}
</span> */}
<hr></hr></div>
)):"-"
}</div>


<form onSubmit={handleSubmit}>
      <div>
        <label htmlFor="textarea">Add Internal Note:</label>
        <br/>
        <textarea
          id="textarea"
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />
      </div>
      <button type="submit">Submit</button>
    </form>







              
              </div>
            </div>
          </div>
          <div className="mt-2 card pt-3 px-3 pb-1">
          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col">Duration</th>
                                <th scope="col">Estimated</th>
                                <th scope="col">Actual</th>
                              </tr>
                            </thead>
                            <tbody>
                            

                              {/* Length */}
                       
                                <tr>
                                  <td>Delivery</td>
                                  <td>
                                    {order_data.etd_delivery_duration}{" "}
                                  </td>
                                  <td>
                                    {order_data.actual_delivery_duration}{" "}
                                
                                  </td>
                                </tr>
                         
                                <tr>
                                  <td>Pickup</td>
                                  <td>
                                    {order_data.etd_pickup_duration}{" "}
                                  </td>
                                  <td>
                                    {order_data.actual_pickup_duration}{" "}
                                 
                                  </td>
                                </tr>
                                <tr>
                                  <td>Total</td>
                                  <td>
                                    {order_data.etd_total_duration}{" "}
                                  </td>
                                  <td>
                                    {order_data.actual_total_duration}{" "}
                              
                                  </td>
                                </tr>
                          
                            </tbody>
                          </table>
                          </div>
        </div>
              </div>
            </div>

            {order_data.sector_id == 4 && (
              <div className="row">
                <div className="col-md-3 col-sm-6 col-12 md-px-3 sm-px-1">
                  <div className="card pt-3 px-3 pb-1 ">
                    <h6 className="text-dark fw-bold">Platform Feedback</h6>
                    {order_data.hasOwnProperty("platform_feedback") ? (
                      <div className="mt-3">
                        <div className="d-flex align-items-center">
                          <StarRatings
                            rating={
                              order_data.platform_feedback.rating
                                ? parseFloat(
                                    order_data.platform_feedback.rating
                                  )
                                : 0
                            }
                            starDimension="20px"
                            starSpacing="5px"
                            starEmptyColor="#ccc"
                            starRatedColor="orange"
                          />
                        </div>
                        <p className="pt-2">
                          {order_data.platform_feedback.message}
                        </p>
                      </div>
                    ) : (
                      <div className="mt-3">
                        <p className="text-gray">No Platform Feedback</p>
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-3 col-sm-6 col-12 md-pe-3 md-ps-0 sm-px-1">
                  <div className="card  px-3 pb-1 ">
                    <h6 className="text-dark fw-bold">
                      Delivery Partner Feedback
                    </h6>
                    {order_data.hasOwnProperty("delivery_partner_feedback") ? (
                      <div className="mt-3">
                        <div className="d-flex align-items-center">
                          <StarRatings
                            rating={
                              order_data.delivery_partner_feedback.rating
                                ? parseFloat(
                                    order_data.delivery_partner_feedback.rating
                                  )
                                : 0
                            }
                            starDimension="20px"
                            starSpacing="5px"
                            starEmptyColor="#ccc"
                            starRatedColor="orange"
                          />
                        </div>
                        <p className="pt-2">
                          {order_data.delivery_partner_feedback.message}
                        </p>
                      </div>
                    ) : (
                      <p className="text-gray">No Delivery Partner Feedback</p>
                    )}
                  </div>
                </div>

                <div className="col-md-3 col-sm-6 col-12 md-pe-3 md-ps-0 sm-px-1">
                  <div className="card pt-3 px-3 pb-1 ">
                    {order_data?.pickup_drop?.map((item: any) => (
                      <div>
                        <h6 className="text-dark fw-bold">
                          Sellers Feedback from Delivery Partner
                        </h6>
                        {item?.delivery_partner?.map(
                          (ele: any, dp_ele_index: any) => (
                            <div>
                              {ele.hasOwnProperty("seller_rating") ? (
                                <div className="mt-3">
                                  <div className="d-flex align-items-center">
                                    <StarRatings
                                      rating={
                                        ele.seller_rating
                                          ? parseFloat(ele.seller_rating)
                                          : 0
                                      }
                                      starDimension="20px"
                                      starSpacing="5px"
                                      starEmptyColor="#ccc"
                                      starRatedColor="orange"
                                    />
                                  </div>
                                  <p className="pt-2">
                                    {" "}
                                    {ele.seller_feedback_list}
                                  </p>
                                </div>
                              ) : (
                                <p className="text-gray">
                                  No Delivery Partner Feedback
                                </p>
                              )}
                            </div>
                          )
                        )}
                      </div>
                    ))}
                  </div>
                </div>

                <div className="col-md-3 col-sm-6 col-12 md-pe-3 md-ps-0 sm-px-1">
                  <div className="card pt-3 px-3 pb-1 ">
                    {order_data?.pickup_drop?.map((item: any) => (
                      <div>
                        <h6 className="text-dark fw-bold">
                          Customer Feedback from Delivery Partner
                        </h6>
                        {item?.delivery_partner?.map(
                          (ele: any, dp_ele_index: any) => (
                            <div>
                              <div className="mt-3">
                                <div className="d-flex align-items-center">
                                  <StarRatings
                                    rating={
                                      ele.customer_rating
                                        ? parseFloat(ele.customer_rating)
                                        : 0
                                    }
                                    starDimension="20px"
                                    starSpacing="5px"
                                    starEmptyColor="#ccc"
                                    starRatedColor="orange"
                                  />
                                </div>
                                <p className="pt-2"> {ele.customer_feedback}</p>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}

            {/* Estimation, On accept, Actual, Difference */}
            <div className="row pt-4"></div>
          </>
        )}

<ModalSettleEligible
        open={set_ele_modal.is_open}
        data={set_ele_modal.data}
     
        close={(data: any) => {
          console.log("Edit Outlet Modal On Close :", data);
          setSetEleModal({
            is_open: false,
            data: {},
          
          });
          if (data.action == "save") {
console.log(data.action)
   
      SetOrderData(data.value)

      // setPrice( data.value.seller_settlement.settlement_paid? data.value.seller_settlement.settlement_paid_amount :  data.value.amount)
      if(data.det == "eligible"){
      setEligible(false);
      setEligibled(false);

    console.log(approve)
      // if(approve==true){
      saveApprove(false);
      // }
      
      // if(paid){
      Save(false)
      // }
      }else if(data.det == "approved"){
        setApprove(false);
        // {paid == true &&
          Save(false)
          // }
      }else if(data.det == "paid"){
        setPaid(false)
        setPay(false)
        setUpdate(false)
      }
            


            // SetDefaultCheckout(data.value)
            // get_users();
            // SetUsersData((prevValue) => {
            //   prevValue[data.index] = data.value;
            //   return [...prevValue];
            // });
          }
        }}
      />

      </div>

      {/* Modal */}
      {(props.id === undefined) && 
      <AvailableDeliveryPartner
        open={available_delivery_partner_modal.is_open}
        data={available_delivery_partner_modal.data}
      
        close={(data: any) => {

          SetAvailableDeliveryPartnerModal((prevalue) => {
            prevalue.is_open = false;
            return { ...prevalue };
          });
          
        if (data.action == "save") {
          console.log(data)
          let pass_data = {
            order_id: order_data.id,
            company_order_id: data.data.brand_id,
            delivery_partner_id: data.data.delivery_partner_id,
          };
          assign_delivery_partner(pass_data);
        }
       
        }}
      />
}

<DeleteConfirmModal
        open={delete_confirm_modal_data.is_open}
        title={delete_confirm_modal_data.title}
        body={delete_confirm_modal_data.body}
        data={delete_confirm_modal_data.data}
        on_ok={(data: any) => {
          SetDeleteConfirmModalData((prevalue) => {
            prevalue.is_open = false;
            prevalue.title = "";
            prevalue.body = "";
            prevalue.data = {};
            return { ...prevalue };
          });
          if (data.id == "cancel_store_order") {
            let pass_data = data.pass_data;
            cancel_store_order(pass_data);
          }
          if (data.id == "delete_delivery_company_order") {
            let pass_data = data.pass_data;
            delete_delivery_company_order(pass_data);
          }
          if (data.id == "delete_delivery_partner_order") {
            let pass_data = data.pass_data;
            delete_delivery_partner_order(pass_data);
          }
          if (data.id == "delete_delivery_company_reservation") {
            let pass_data = data.pass_data;
            delete_delivery_company_reservation(pass_data);
          }
          if (data.id == "delete_delivery_partner_reservation") {
            let pass_data = data.pass_data;
            delete_delivery_partner_reservation(pass_data);
          }
        }}
        close={(data: any) => {
          SetDeleteConfirmModalData((prevalue) => {
            prevalue.is_open = false;
            prevalue.title = "";
            prevalue.body = "";
            prevalue.data = {};
            return { ...prevalue };
          });
        }}
      />
  
  {/* {order_data && Object.keys(order_data).length > 0 ? (

    console.log(order_tracking_modal),
    console.log(order_data) */}

        <ModalOrderTracking
          open={order_tracking_modal.is_open}
          delivery_id={order_tracking_modal.delivery_id}
          delivery_index={order_tracking_modal.delivery_index}
          order_details={order_data}
          shipment_index={order_tracking_modal.shipment_index}
          
          close={() => {
            SetOrderTrackingModal({
              is_open : false,
             delivery_index : "",
              shipment_index : "",
              order_details : {},


              delivery_id : "",
              
            });
          }}
        />

        

<ModalDemoUser
        open={demo_modal.is_open}
        data={demo_modal.data}
     
        close={(data: any) => {
          console.log("Edit Outlet Modal On Close :", data);
          setDemoModal({
            is_open: false,
            data: {},
          
          });
          if (data.action == "save") {

      
            SetOrderData(data.value)


            // SetDefaultCheckout(data.value)
            // get_users();
            // SetUsersData((prevValue) => {
            //   prevValue[data.index] = data.value;
            //   return [...prevValue];
            // });
          }
        }}
      />
        { (props.id === undefined) && 
<ModalUserDetails
open={user_modal.is_open}
id={user_modal.id}
close={(data: any) => {
  SetUserModal({
      is_open: false,
      id  :"",
  });
 }}
 />
}

{/* <ModalSettleApproval
        open={set_approv_modal.is_open}
        data={set_approv_modal.data}
     
        close={(data: any) => {
          console.log("Edit Outlet Modal On Close :", data);
          setApprovModal({
            is_open: false,
            data: {},
          
          });
          if (data.action == "save") {

      
            SetOrderData(data.value)

            setApprove(false);

            // SetDefaultCheckout(data.value)
            // get_users();
            // SetUsersData((prevValue) => {
            //   prevValue[data.index] = data.value;
            //   return [...prevValue];
            // });
          }
        }}
      /> */}


{/* <ModalSettlePaid
        open={paid_modal.is_open}
        data={paid_modal.data}
     
        close={(data: any) => {
          console.log("Edit Outlet Modal On Close :", data);
          setPaidModal({
            is_open: false,
            data: {},
          
          });
          if (data.action == "save") {
            console.log(data.value)
SetOrderData(data.value)
      setPaid(false)
      setInputShow(true)
              // get_order_detail(id_value.ord_id,false);

            // SetDefaultCheckout(data.value)
            // get_users();
            // SetUsersData((prevValue) => {
            //   prevValue[data.index] = data.value;
            //   return [...prevValue];
            // });
          }
        }}
      /> */}
{/* <ModalSettleUpdate
        open={settle_update_modal.is_open}
        data={settle_update_modal.data}
     
        close={(data: any) => {
          console.log("Edit Outlet Modal On Close :", data);
          setSettleUpdateModal({
            is_open: false,
            data: {},
          
          });
          if (data.action == "save") {

      // console.log("hi")
      //       SetOrderData(data.value)

      get_order_detail(id_value.ord_id,false);
            // SetDefaultCheckout(data.value)
            // get_users();
            // SetUsersData((prevValue) => {
            //   prevValue[data.index] = data.value;
            //   return [...prevValue];
            // });
          }
        }}
      /> */}
  <AmountModal
                open={amount_modal.is_open}
                data={amount_modal.data}
                done={(data: any) => {
                    if (data?.status == true && data?.data?.action == 'eligible') {
                        settlementUpdate({action: 'eligible', status: 1, order_id: data?.data?.order?.id, key: data?.data?.key, amount: data?.amount, message: data?.message});
                    } else if (data?.status == true && data?.data?.action == 'paid') {
                        settlementUpdate({action: 'paid', status: 1, order_id: data?.data?.order?.id, key: data?.data?.key, amount: data?.amount});
                    }
                }}
                close={(data: any) => {
                    setAmountModal({
                        is_open: false,
                        data: {},
                    });
                }}
            />
            <ConfirmModal
                open={confirm_modal.is_open}
                data={confirm_modal.data}
                done={(data: any) => {
                    if (data?.status == true && data?.data?.action == 'eligible') {
                        settlementUpdate({action: 'eligible', status: 2, order_id: data?.data?.order?.id, key: data?.data?.key});
                    } else if (data?.status == true && data?.data?.action == 'approved') {
                        settlementUpdate({action: 'approved', status: 2, order_id: data?.data?.order?.id, key: data?.data?.key});
                    } else if (data?.status == true && data?.data?.action == 'paid') {
                        settlementUpdate({action: 'paid', status: 2, order_id: data?.data?.order?.id, key: data?.data?.key});
                    }
                }}
                close={(data: any) => {
                    setConfirmModal({
                        is_open: false,
                        data: {},
                    });
                }}
            />

<EligibleLogsModal
                open={log_modal.is_open}
                data={log_modal.data}
                close={(data: any) => {
                    setLogModal({
                        is_open: false,
                        data: {},
                    });
                }}
            />
       {/* ) : null} */}


    </div>
  );
}

