import React,{useEffect,useState} from 'react'
import { AppConfig } from "../../config";
import { api } from "../../utils/Api";
import { Link, useParams, useOutletContext } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import DeliveryPartnerRequest from '../DeliveryPartnerRequest/DeliveryPartnerRequest';
import SellerUserRolesAdd from './SellerUserRolesAdd';
import ModalDeleteUserRole from "./ModalDeleteUserRole";
import ShortNotification from "../../components/Toast/ShortNotification";




const initial_value = {
  page_type:"",
  role_type:"",
  data:[],
}


function SellerUserRole() {
    const [brand_active_roles, SetBrandActiveRoles] = useState([]);
  const [toastData, SetToastData] = useState({ message: "", style: "" });
  const [width, SetWidth] = useState(innerWidth);

    const [franchies_active_roles, SetFranchiesActiveRoles] = useState([]);
    const [outlet_active_roles, SetOutletActiveRoles] = useState([]);
  const [is_loading, SetIsLoading] = useState(false);
  const [transaction_able, SetTransactionAble] = useState(false);
  const [save_data, SetSaveData] = useState(initial_value);
  const [openToast, SetOpenToast] = useState(false);

  const [openModal, SetopenModal] = useState({
    is_open: false,
    data: {},
  });
  function ModalOnClose(data: any) {
    console.log(data)
    SetopenModal({
      is_open: false,
      data: {},
    });
    // SetToastData({ message: "User Role Deleted", style: "" });
    // SetOpenToast(true);
    if (data.action == "delete") {
      console.log("on confirm delete :", data.value);
      // delete_role(
      //   data.value.element,
      //   data.value.index,
      //   data.value.type,
      //   data.value.status
      // );
    }
  }

  function ToastOnClose(data: any) {
    SetOpenToast(false);
  }

    useEffect(() => {
        console.log("AdminUserRoles On mount :");
        get_custom_roles();
      }, []);

      function enable(ele: any,role_type:any,page_type:any) {
        // SetShowDetailsLoader(true);
        // const timer = setTimeout(() => {
        //   SetShowDetailsLoader(false);
        // }, 500);
    
        SetTransactionAble(true);
        // SetTransactionDetails(ele);
    
        const newValues = {
          role_type:role_type,
          page_type:page_type,
          data:ele,
        };
        SetSaveData(newValues);
        // return () => clearTimeout(timer);
      }

      async function get_custom_roles() {
        SetIsLoading(true)
        // let pass_data = {
        //   get: {
        //     brand: id_value.brand_id,
        //   },
        // };
        let data: any = await api("/sellers/user_roles");
        console.log("get_custom_roles response :", data);
        if (data.status_code == 200) {
        //   data.response.roles.map((role_element: any, role_index: any) => {
            // if (role_element.name == "Brand") {
            //   if (role_element.hasOwnProperty("roles")) {

            const brandRoles = filterRolesByModule(data.response.roles, "Brand");
            SetBrandActiveRoles(brandRoles);

const franchiseRoles = filterRolesByModule(data.response.roles, "Franchise");
SetFranchiesActiveRoles(franchiseRoles);

const outletRoles = filterRolesByModule(data.response.roles, "Outlet");
SetOutletActiveRoles(outletRoles);
            console.log(data)
                // SetBrandActiveRoles(data.response.roles);
            //   } else {
            //     SetBrandActiveRoles([]);
            //   }
            // } 
            // else if (role_element.name == "Outlet") {
            //   if (role_element.hasOwnProperty("roles")) {
            //     SetOutletActiveRoles(role_element.roles);
            //   } else {
            //     SetOutletActiveRoles([]);
            //   }
            // } else if (role_element.name == "Franchise") {
            //   if (role_element.hasOwnProperty("roles")) {
            //     SetFranchiesActiveRoles(role_element.roles);
            //   } else {
            //     SetFranchiesActiveRoles([]);
            //   }
            // }
        //   });
        SetIsLoading(false)
    
        }
      }

      function filterRolesByModule(data, module) {
        return data.filter(role => role.module === module);
      }
      const handleChildData = (data) => {
        // Handle data received from child component here
        console.log("Received data from child component:", data);
        // You can update state or perform other actions with received data
        SetTransactionAble(data);
        get_custom_roles()
      };


   

      const ViewPanelSection = () => {
        return (
            <div className="col-lg-6 sm-pb-5 sm-mt-6">
                <div className="card p-12 mb-2 right-panel">
                                     <div className="py-1 mb-3 d-flex align-items-center cursor" 
                                     onClick={()=>SetTransactionAble(false)}
                                     >
                        <img src={AppConfig.CDN_Media_url + '9d9ebfad431d9a9ea17ffba00d3c6ca1.svg'} className="img-fluid me-2" width="15" />
                        <h6 className="mb-0">User Role</h6>
                    </div>
                <SellerUserRolesAdd val={save_data} onData={handleChildData}/>
                    
                </div>
            </div>
        );
    }

  return (
    <div>
    <div className="head-bar row">
    {/* style={OutletContext == false ? { width: width } : {}} */}
    <div className="col-9">
      <h5 className="mb-1">Seller User Roles</h5>
    </div>
    <div className="col-3"></div>

  
  </div>
  <div className="mt-3 px-md-3 px-sm-0 ">
  <div className="row">
  <div className="col-md-6  sm-pb-5 sm-mt-6">

          {/* <div className="col-lg-4 mb-3"> */}
            <div className="bg-fff box-3 p-3 br-3 mb-3">
              <div className="d-flex align-items-center mb-3">
                <h6 className="mb-0">Brand User Roles</h6>
                <div className="cursor py-1 px-2 ms-auto">
                  {/* <Link
                    to="add/brand/new"> */}
                    <div className="bg-prime btn-radius cursor py-1 px-3 ms-auto"         onClick={() => {
                    enable("","brand","add");
           
                    // SetSelectedDetails(i);
                  }}>
                      {/* <Entypo name="plus" size={18} color="#663fc3" /> */}
                      <p className="text-white fw-bold">Add</p>
                    </div>
                  {/* </Link> */}
                </div>
              </div>
              <div>
              {is_loading?
               <div>
               {Array.from({ length: 6 }).map((_, index) => (
                 <div key={index} className="p-2 row list border-bottom">
                   <div className="col-6">
                     <div className="mb-1">
                       <Skeleton width={150} />
                     </div>
                   </div>
                   <div className="col-6 d-flex align-items-center justify-content-end">
                     {/* <div className="cursor p-2">
                       <Skeleton circle={true} width={20} height={20} />
                     </div> */}
                     <div className="cursor p-2">
                       <Skeleton circle={true} width={14} height={14} />
                     </div>
                     <div className="cursor p-2">
                       <Skeleton circle={true} width={14} height={14} />
                     </div>
                   </div>
                 </div>
               ))}
             </div>
                :
                 brand_active_roles.length > 0 ? (
                  brand_active_roles.map((ele: any, index: any) => (

                    <div className="p-2 row list border-bottom">
                      <div className="col-6">
                        <div className="mb-1">{ele.name}</div>
                      </div>
                      <div className="col-6 d-flex align-items-center justify-content-end">
                        {/* <Link
                          className="cursor p-2"
                          to={
                            "/seller_user_roles/view/brand/" +ele.id
                          }
                        >
                          <img src={AppConfig.CDN_Media_url+"dea875fb200efe26145c4ccc2a582fb0.svg"} className="w-14px" />
                       
                        </Link> */}

                        {/* <Link
                          className="cursor p-2"
                          to={
                           "/seller_user_roles/edit/brand/" +ele.id
                          }
                        > */}
                          <div onClick={()=>  enable(ele,"brand","edit")}>
                 <img src={AppConfig.CDN_Media_url+"ab30fda22ff8a0d7814600c1a192acd6.svg"} className="w-14px" />
                        {/* </Link> */}
                        </div>

                        <div
                          className="cursor p-2"
                          onClick={() => {
                            SetopenModal({
                              is_open: true,
                              data: {
                                element: ele,
                                index: index,
                                type: "brand",
                                status: "active",
                              },
                            });
                          }}
                        >
                          <img src={AppConfig.CDN_Media_url+"d73710484da46cc16fb3af1f3ab50cab.svg"} className="w-14px" />
                         
                        </div>
                      </div>
                    </div>


                  ))

                ) : (
                  <div className="text-center">
                     <img
                     src={
                      AppConfig.CDN_Image_url +
                      "e38cbe608dba40c81ec58d266c09055f.png"
                    }
                    className="empty-img pb-2"
                    />
                    <p className="v-small mb-0">No Data, Please Add Some Roles</p>
                  </div>
                )}
              </div>
            </div>
          {/* </div> */}

          {/* <div className="col-lg-4 mb-3"> */}
            <div className="bg-fff  box-3 p-3 br-3 mb-3">
              <div className="d-flex align-items-center mb-3">
                <h6 className="mb-0">Franchise User Roles</h6>
                <div className="cursor py-1 px-2 ms-auto">
                  {/* <Link
                    to="add/franchise/new"> */}
                    <div className="bg-prime btn-radius cursor py-1 px-3 ms-auto"         onClick={() => {
                      enable("","franchise","add");
           
                    // SetSelectedDetails(i);
                  }}>
                      {/* <Entypo name="plus" size={18} color="#663fc3" /> */}
                      <p className="text-white fw-bold">Add</p>
                    </div>
                  {/* </Link> */}
                </div>
              </div>


              <div>
                {is_loading? <div>
      {Array.from({ length: 6 }).map((_, index) => (
        <div key={index} className="p-2 row list border-bottom">
          <div className="col-6">
            <div className="mb-1">
              <Skeleton width={150} />
            </div>
          </div>
          <div className="col-6 d-flex align-items-center justify-content-end">
            {/* <div className="cursor p-2">
              <Skeleton circle={true} width={20} height={20} />
            </div> */}
            <div className="cursor p-2">
              <Skeleton circle={true} width={14} height={14} />
            </div>
            <div className="cursor p-2">
              <Skeleton circle={true} width={14} height={14} />
            </div>
          </div>
        </div>
      ))}
    </div>:
                brand_active_roles.length > 0 ? (

                  franchies_active_roles.map((ele: any, index: any) => (
                    <div className="p-2 row list border-bottom">

                      <div className="col-6">
                        <div className="mb-1">{ele.name}</div>
                      </div>

                      <div className="col-6 d-flex align-items-center justify-content-end">
                        {/* <Link
                          className="cursor p-2"
                          to={
                            "/seller_user_roles/view/franchise/" +
                            ele.id
                          }
                        >
                          <img src={AppConfig.CDN_Media_url+"dea875fb200efe26145c4ccc2a582fb0.svg"} className="w-14px" />
                     
                        </Link> */}

                        {/* <Link
                          className="cursor p-2"
                          to={
                           
                            "/seller_user_roles/edit/franchise/" +
                            ele.id
                          }
                        > */}

                 
                   <div onClick={()=>  enable(ele,"franchise","edit")}>
                          <img src={AppConfig.CDN_Media_url+"ab30fda22ff8a0d7814600c1a192acd6.svg"} className="w-14px" />
                    </div>
                        {/* </Link> */}

                        <div
                          className="cursor p-2"
                          // onClick={() => {
                          //   SetopenModal({
                          //     is_open: true,
                          //     data: {
                          //       element: ele,
                          //       index: index,
                          //       type: "franchise",
                          //       status: "active",
                          //     },
                          //   });
                          // }}
                        >
                          <img src={AppConfig.CDN_Media_url+"d73710484da46cc16fb3af1f3ab50cab.svg"} className="w-14px" />
                    
                        </div>
                      </div>
                    </div>
                  ))


                ) : (
                  <div className="text-center">
                    <img
                     src={
                      AppConfig.CDN_Image_url +
                      "e38cbe608dba40c81ec58d266c09055f.png"
                    }
                    className="empty-img pb-2"
                    />
                    <p className="v-small mb-0">No Data, Please Add Some Roles</p>
                  </div>
                )}
              </div>
            </div>
          {/* </div> */}

          {/* <div className="col-lg-4 mb-3"> */}
            <div className="bg-fff box-3 p-3 br-3 mb-3">
              <div className="d-flex align-items-center mb-3">
                <h6 className="mb-0">Outlet User Roles</h6>
                <div className="cursor py-1 px-2 ms-auto">
                  {/* <Link
                    to="add/outlet/new" > */}
                    <div className="bg-prime btn-radius cursor py-1 px-3 ms-auto"         onClick={() => {
                   enable("","outlet","add");
           
                    // SetSelectedDetails(i);
                  }}>
                      {/* <Entypo name="plus" size={18} color="#663fc3" /> */}
                      <p className="text-white fw-bold">Add</p>
                    </div>
                  {/* </Link> */}
                </div>
              </div>

              <div>
              {is_loading? <div>
      {Array.from({ length: 6 }).map((_, index) => (
        <div key={index} className="p-2 row list border-bottom">
          <div className="col-6">
            <div className="mb-1">
              <Skeleton width={150} />
            </div>
          </div>
          <div className="col-6 d-flex align-items-center justify-content-end">
            {/* <div className="cursor p-2">
              <Skeleton circle={true} width={20} height={20} />
            </div> */}
            <div className="cursor p-2">
              <Skeleton circle={true} width={14} height={14} />
            </div>
            <div className="cursor p-2">
              <Skeleton circle={true} width={14} height={14} />
            </div>
          </div>
        </div>
      ))}
    </div>:outlet_active_roles.length > 0 ? (
                  outlet_active_roles.map((ele: any, index: any) => (
                    <div className="p-2 row list border-bottom">
                      <div className="col-6">
                        <div className="mb-1">{ele.name}</div>
                      </div>
                      <div className="col-6 d-flex align-items-center justify-content-end">
                        {/* <Link
                          className="cursor p-2"
                          to={
                            "/brand/" +
                            id_value.brand_id +
                            "/userrole/view/outlet/" +
                            ele.id
                          }
                        > */}
                          {/* <img src={AppConfig.CDN_Media_url+"dea875fb200efe26145c4ccc2a582fb0.svg"} className="w-14px" />/ */}
                         
                        {/* </Link> */}

                        {/* <Link
                          className="cursor p-2"
                          to={
                            "/brand/" +
                            id_value.brand_id +
                            "/userrole/edit/outlet/" +
                            ele.id
                          }
                        > */}
                   <div onClick={()=>  enable(ele,"outlet","edit")}>

                          <img src={AppConfig.CDN_Media_url+"ab30fda22ff8a0d7814600c1a192acd6.svg"} className="w-14px" />
                       </div>
                        {/* </Link> */}

                        <div
                          className="cursor p-2"
                          onClick={() => {
                            SetopenModal({
                              is_open: true,
                              data: {
                                element: ele,
                                index: index,
                                type: "outlet",
                                status: "active",
                              },
                            });
                          }}
                        >
                          <img src={AppConfig.CDN_Media_url+"d73710484da46cc16fb3af1f3ab50cab.svg"} className="w-14px" />
                        
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-center">
                   <img
                     src={
                      AppConfig.CDN_Image_url +
                      "e38cbe608dba40c81ec58d266c09055f.png"
                    }
                    className="empty-img pb-2"
                    />
                    <p className="v-small mb-0">No Data, Please Add Some Roles</p>
                  </div>
                )}
              </div>
            </div>


            <ModalDeleteUserRole
              open={openModal.is_open}
              data={openModal.data}
              close={(data: any) => {
                console.log("data ", data);
                SetopenModal((prevValue: any) => {
                  prevValue.is_open = false;
                  return { ...prevValue };
                });
                if (data.action == "delete") {
                  SetTransactionAble(false)
                  get_custom_roles();
                } 
              }}
            />
            <ShortNotification
              open={openToast}
              close={ToastOnClose}
              data={toastData}
            />
          {/* </div> */}
  </div> 

   {/* {width < 768 && transaction_able == false ? (
     <SellerUserRolesAdd val={save_data} onData={handleChildData}/>
          ) : width > 768 ? (
            <SellerUserRolesAdd val={save_data} onData={handleChildData}/>
          ) : null}  */}
  {transaction_able &&

          <ViewPanelSection />

 

} 
  </div> 

  </div> 

  </div>

  )
}

export default SellerUserRole