import React, { useState, useEffect, useRef } from "react";
import bootstrap from "../../assets/libraries/bootstrap/js/bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { api } from "../../utils/Api";
let myModal: any;
export default function ChooseBrandOutletModal(props: any) {
  const [close_data, SetCloseData] = useState({
    action: "close",
    value: "",
    index: "",
    demo_order: false
  });
  let newTimeoutId: any;
  const searchInp: any = useRef(null);

  const [brands, setBrands]: any = useState([]);
  const [sort, setSort]: any = useState('');
  const [search, setSearch]: any = useState('');
  const [searchIn, setSearchIn]: any = useState('');
  const [next_page, setNextPage]: any = useState(false);
  const [visibleContent, setVisibleContent]: any = useState(false);
  const [page_no, setPageNo]: any = useState(1);




  useEffect(() => {
    let myModal1: any = document.getElementById("ChooseBrandOutlet");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      console.log("event :", event);
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      initialLoad();
      getBrands({ page_no: 1 });
      myModal = new bootstrap.Modal(
        document.getElementById("ChooseBrandOutlet"),
        {}
      );
      myModal.show();
      setTimeout(() => {
        if (searchInp.current) {
          searchInp.current.focus();
        }
      }, 1000)
    }
  }, [props.open]);

  useEffect(() => {
    if (props.open) {
      setBrands([]);
      setSort('');
      setVisibleContent(false);
      setPageNo(1);
      getBrands({ page_no: 1 });
    }
  }, [search]);
  useEffect(() => {
    if (sort) {
      setSearch('');
      setSearchIn('');
      setBrands([]);
      setVisibleContent(false);
      setPageNo(1);
      getBrands({ page_no: 1 });
    }
  }, [sort]);

  function initialLoad() {
    setBrands([]);
    setSort('');
    setSearch('');
    setSearchIn('');
    setPageNo(1);
    setVisibleContent(false);
  }
  async function getBrands(get_data: any = {}) {
    let pass_data = {
      get: {
        sort: sort,
        search: sort != ''?'':search,
        page: get_data.page_no
      }
    };
    let data_res = await api("/sellers/get_brand_outlets", pass_data);
    if (data_res.status_code == 200) {
      if (data_res?.response?.data) {
        if (get_data?.load_more) {
          setBrands((prevValue: any) => {
            data_res?.response?.data?.map((ele: any) => {
              prevValue.push(ele);
            });
            return [...prevValue];
          });
        } else {
          setBrands(data_res.response.data);
        }
      }
      setPageNo((prev: any) => { return prev + 1 });
      if (data_res?.response?.next_page) {
        setNextPage(true);
      } else {
        setNextPage(false)
      }
      setVisibleContent(true);
    }
  }

  const handleSearchInputChange = (e: any) => {
    setSearchIn(e.target.value);
    if (newTimeoutId) {
      clearTimeout(newTimeoutId);
    }
    newTimeoutId = setTimeout(() => {
      setSearch(e.target.value);
    }, 1000);
  };

  return (
    <div
      className="modal fade"
      id="ChooseBrandOutlet"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-dialog-scrollable">
        <div className="modal-content seller-choose-modal">
          <div className="modal-header">
            <h6 className="  modal-title" id="ModalLabel">Choose Seller</h6>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body" id="ChooseBrandOutletBody">
            <div className="d-flex mb-3">
              <input type="search" className="form-control" value={searchIn} ref={searchInp} placeholder="Search by brand name, outlet name, id" onChange={handleSearchInputChange} />
              <div className="dropdown ms-auto">
                <button
                  className="btn btn-white dropdown-toggle d-flex align-items-cetner"
                  type="button"
                  id="dropdownMenuButton1os"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src={require("../../assets/icon/filter.png")}
                    className="icon-14px"
                  />
                </button>
                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1os">
                  <li>
                    <button className="dropdown-item" type="button" onClick={() => setSort("low_balance")}>Low balance</button>
                  </li>
                  <li>
                    <button className="dropdown-item" type="button" onClick={() => setSort("high_balance")}>High balance</button>
                  </li>
                </ul>
              </div>
            </div>
            {props.open && (
              <InfiniteScroll
                dataLength={brands.length}
                next={() => {
                  let pass_data = {
                    load_more: true,
                    page_no: page_no
                  };
                  getBrands(pass_data);
                }}
                hasMore={next_page}
                loader={
                  <div className="center m-5">
                    <div className="lds-dual-ring"></div>
                    <p className=" small ps-2">Just a second ...</p>
                  </div>
                }
                scrollableTarget="ChooseBrandOutletBody"
              >
                {visibleContent ? (
                  brands.length != 0 ? (
                    brands?.map((brand: any, ord_index: any) => (
                      <div
                        className="border mb-2 list-item cursor p-12 br-8"
                        key={ord_index}
                        onClick={() => {
                          let item: any = {
                            action: "close",
                            value: "",
                            index: "",
                            demo_order: false
                          };
                          item.action = "save";
                          item.data = brand;
                          props.close(item);

                          myModal.hide();
                        }}
                      >
                        <div className="d-flex mb-2 border-bottom pb-2">
                          <div>
                            <div className="mb-1 fw-600">{brand.brand_name}</div>
                            <div className="text-muted">{brand.outlet_name}</div>
                            {/* <div className="text-muted fs-11px">{brand.status}</div> */}
                          </div>
                          <div className="ms-auto text-end text-muted fs-11px">
                            <div className="mb-1">Brand ID: {brand.brand_id}</div>
                            <div>Outlet ID: {brand.outlet_id}</div>
                          </div>
                        </div>
                        <div className="d-flex text-center">
                          <div className="flex-fill px-1">
                            <div>&#x20b9;{brand?.order_statistics?.order_completed}</div>
                            <div className="fs-11px text-muted">Order Completed</div>
                          </div>
                          <div className="flex-fill px-1">
                            <div>&#x20b9;{brand?.order_statistics?.settlement_eligible}</div>
                            <div className="fs-11px text-muted">Eligible</div>
                          </div>
                          <div className="flex-fill px-1">
                            <div>&#x20b9;{brand?.order_statistics?.settlement_approved}</div>
                            <div className="fs-11px text-muted">Approved</div>
                          </div>
                          <div className="flex-fill px-1">
                            <div>&#x20b9;{brand?.order_statistics?.settlement_paid}</div>
                            <div className="fs-11px text-muted">Paid</div>
                          </div>
                          <div className="flex-fill px-1">
                            <div>&#x20b9;{brand?.order_statistics?.pending_eligible}</div>
                            <div className="fs-11px text-muted">Remaining</div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="center p-3">
                      <div className="card p-3 w-100">
                        <div className="w-100 text-center">
                          <img
                            src={require("../../assets/img/not-found.png")}
                            className="w-20"
                          />
                        </div>
                        <p className="center pt-3">No Sellers</p>
                      </div>
                    </div>
                  )
                ) : (
                  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((ele: any) => (
                    <div className="card mb-2 p-3">
                      <Skeleton height={10} width={80} />
                    </div>
                  ))

                )}
              </InfiniteScroll>
            )}


          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
