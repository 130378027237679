import React, { useState, useEffect } from "react";
import { api } from "../../utils/Api";
import {
  Ionicons,
  MaterialCommunityIcons,
  FontAwesome,
} from "@expo/vector-icons";
import toast, { Toaster } from "react-hot-toast";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ModalCreateSettlement from "./ModalCreateSettlement";
import ModalDeleteComponent from "../../components/ModalDeleteComponent/ModalDeleteComponent";

export default function DeliveryPartnerCodSettlement(props: any) {
  const [width, SetWidth] = useState(innerWidth);
  const tab_value = [
    { id: 1, name: "All", value: "all" },
    { id: 1, name: "Active", value: "active" },
    { id: 1, name: "Floating Cash", value: "floating_cash" },
    { id: 1, name: "Low Cod Balance", value: "low_cod_balance" },
    { id: 1, name: "Suspended", value: "suspended" },
    { id: 1, name: "Deleted", value: "deleted" },
  ];

  const details_tab_value = [
    { id: 1, name: "COD Handover", value: "cod_handover" },
    { id: 2, name: "Settlement", value: "delivery_partner_settlement" },

  ];
  const [selected_tab, SetSelectedTab] = useState({
    id: 1,
    name: "Active",
    value: "active",
  });

  const [details_selected_tab, SetDetilsSelectedTab] = useState({
    id: 1,
    name: "COD Handover",
    value: "cod_handover",
  });
  const [selected_partner_data, SetSelectedPartnerData] = useState({});
  const [show_side_tab, SetShowSideTab] = useState(false);

  const [dp_list_loading, SetDPListLoading] = useState(true);
  const [next_page, SetNextPage] = useState(false);
  const [page_no, SetPageNo] = useState(1);
  const [partner_list, SetPartnerList] = useState([]);

  const [settlement_list_loading, SetSettelementListLoading] = useState(true);
  const [sl_next_page, SetSLNextPage] = useState(false);
  const [sl_page_no, SetSLPageNo] = useState(1);
  const [sl_list, SetSLList] = useState([]);
  const sl_filter_options = [
    {
      id: 1,
      value: "all",
      name: "All",
    },
    {
      id: 2,
      value: "active",
      name: "Active",
    },
    {
      id: 3,
      value: "deleted",
      name: "Deleted",
    },
  ];
  const [selected_sl_filter, SetSelectedSLFilter] = useState("active");
  const sl_sort_options = [
    {
      id: 1,
      value: "asc",
      name: "Oldest",
    },
    {
      id: 2,
      value: "desc",
      name: "Latest",
    },
  ];
  const [selected_sl_sort, SetSelectedSLSort] = useState("desc");
  const [modal_create_settlement, SetModalCreateSettlement] = useState({
    is_open: false,
    data: {},
    title:""
  });
  const [delete_modal, SetDeleteModal] = useState({
    is_open: false,
    data:{},
    title: "",
   
  
  });

  useEffect(() => {
    SetPageNo((prevalue: any) => {
      let pass_data = {
        page_no: prevalue,
        filter: selected_tab,
      };
      get_deliverypartner_list(pass_data);
      return prevalue;
    });
  }, []);

  async function get_deliverypartner_list(get_data: any) {
    if (!get_data.load_more) {
      SetDPListLoading(true);
    }
    let pass_data = {
      get: {
        page: get_data.page_no,
        filter: get_data.filter.value,
      },
    };

    let response = await api("/deliverypartners/list", pass_data);
    if (response.status_code == 200) {
      SetDPListLoading(false);
      if (get_data.load_more) {
        SetPartnerList((prevValue) => {
          response.response.data.map((item: any) => {
            prevValue.push(item);
          });
          return [...prevValue];
        });
      } else {
        SetPartnerList(response.response.data);
      }

      if (response.response.hasOwnProperty("next_page")) {
        SetNextPage(response.response.next_page);
      }
    }
  }

  async function get_settlements_data(get_data: any) {

    console.log(get_data)
    if (!get_data.load_more) {
      SetSettelementListLoading(true);
    }


    let pass_data = {
      get: {
        page: get_data.page_no,
        agent_id: get_data.agent_id,
        filter: get_data.filter,
        sort: get_data.sort,
      },
    };
let response 

if(get_data.title.value =="delivery_partner_settlement"){
    response = await api(
      "/deliverypartners/settlements",
      pass_data
    );
  }else{
    response = await api(
      "/deliverypartners/get_partners_cod_settlements",
      pass_data
    );
  }
    if (response.status_code == 200) {
      SetSettelementListLoading(false);
      if (get_data.load_more) {
        SetSLList((prevValue) => {
          response.response.data.map((item: any) => {
            prevValue.push(item);
          });
          return [...prevValue];
        });
      } else {
        SetSLList(response.response.data);
      }

      if (response.response.hasOwnProperty("next_page")) {
        SetSLNextPage(response.response.next_page);
      }
    }
  }

  async function delete_settlements(get_data: any) {
    let pass_data 
    {details_selected_tab.id== 1?
    pass_data= {
      post: {
        agent_id: get_data.agent_id,
        transaction_id: get_data.settlement_id
      },
    }:
    pass_data= {
      post: {
        settlement_id: get_data.settlement_id,
      },
    }}

    console.log(details_selected_tab)
    let response;
    {get_data.id.id== 1?
     response = await api("/deliverypartners/delete_partner_cod_paid", pass_data)
     :
     response = await api("/deliverypartners/delete_settlement", pass_data)
    }
    if (response.status_code == 200) {
      toast.success("Settlement Deleted");

      let send_data = {
        agent_id: selected_partner_data.agent_id,
        page_no: 1,
        filter: selected_sl_filter,
        sort: selected_sl_sort,
        title: details_selected_tab,

      };
      get_settlements_data(send_data);
    } else {
      toast.error("Somthing went wrong, please try again.");
    }
  }

  const DeliveryPartnerCard = () => {
    return (
      <div className="col-md-6 sm-mt-6 sm-pb-5 md-ps-2">
        {dp_list_loading ? (
          [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((ele, i) => (
            <div className="card mb-2 p-3" key={i}>
              <div className="row align-items-center border-bottom">
                <div className="col-6 pb-3">
                  <Skeleton height={10} width={50} />
                </div>
                <div className="col-6 text-end pb-3">
                  <Skeleton height={30} width={100} />
                </div>
                <div className="col-6 pb-2">
                  <Skeleton height={10} width={100} count={2} />
                </div>
                <div className="col-6 pb-2 text-end">
                  <Skeleton height={10} width={80} count={2} />
                </div>
              </div>
              <div className="pt-2">
                <Skeleton height={10} width={80} />
                <div className="pt-2">
                  <Skeleton height={10} width={180} />
                </div>
              </div>
            </div>
          ))
        ) : partner_list.length > 0 ? (
          <>
            <InfiniteScroll
              dataLength={partner_list.length}
              next={() => {
                SetPageNo((prevalue) => {
                  prevalue = prevalue + 1;
                  let pass_data: any = {
                    filter: selected_tab,
                    load_more: true,
                    page_no: prevalue,
                  };
                  get_deliverypartner_list(pass_data);
                  return prevalue;
                });
              }}
              hasMore={next_page}
              loader={
                <div className="center m-5">
                  <div className="lds-dual-ring"></div>
                  <p className=" small ps-2">Just a second ...</p>
                </div>
              }
            >
              {partner_list.map((ele: any, i: any) => (
                <div
                  key={i}
                  className={
                    show_side_tab == true &&
                    selected_partner_data.agent_id == ele.agent_id
                      ? "active-shadow-layout radius  mb-2 p-3"
                      : "card mb-2 p-3"
                  }
                  onClick={() => {
                    SetShowSideTab(true);
                    SetSelectedPartnerData(ele);
                    SetSLPageNo((prevalue: any) => {
                      let send_data = {
                        agent_id: ele.agent_id,
                        page_no: prevalue,
                        filter: selected_sl_filter,
                        sort: selected_sl_sort,
                        title: details_selected_tab,
                      };
                      get_settlements_data(send_data);
                      return prevalue;
                    });
                  }}
                >
                  <div className="row align-items-center">
                    <div className="col-6 pb-3">
                      <p className="text-black">Agent ID: #{ele.agent_id}</p>
                    </div>
                    {/* joining time */}
                    <div className="col-6 end pb-3">
                      <div className="ms-auto"> {ele.join_time}</div>
                    </div>
                    {/* name */}
                    <div className="col-6 pb-2">
                      <p className="text-dark fw-bold fs-7">{ele?.name}</p>
                    </div>
                    {/* status */}
                    <div className="col-6 end pb-2">
                      <FontAwesome
                        name="circle"
                        size={10}
                        color={ele.status == "Rejected" ? "red" : "green"}
                      />
                      <p
                        className={
                          ele.status == "Rejected"
                            ? "ps-2 text-red"
                            : "ps-2 text-green"
                        }
                      >
                        {ele.status}
                      </p>
                    </div>
                    {/* cash data */}
                    <div className="row pb-2">
                      {/* deposited_amount */}
                      <div className="col-6 pb-2 d-flex align-items-center">
                        <p className="ps-1 text-dark">
                          Deposited Cash :   <span className="fw-300 fs-16">
                                  &#8377;
                                </span>{" "} {ele.deposited_amount}
                        </p>
                      </div>
                      {/* balance_amount */}
                      <div className="col-6 pb-2 d-flex align-items-center">
                        <p className="ps-1 text-dark">
                          Balance Cash :   <span className="fw-300 fs-16">
                                  &#8377;
                                </span>{" "} {ele.balance_amount}
                        </p>
                      </div>
                      {/* threshold_amount */}
                      <div className="col-6 pb-2 d-flex align-items-center">
                        <p className="ps-1 text-dark">
                          Threshold Cash :   <span className="fw-300 fs-16">
                                  &#8377;
                                </span>{" "}{ele.threshold_amount}
                        </p>
                      </div>
                      {/* floating_cash */}
                      <div className="col-6 pb-2 d-flex align-items-center">
                        <p className="ps-1 text-dark">
                          Floating Cash :   <span className="fw-300 fs-16">
                                  &#8377;
                                </span>{" "}{ele.floating_cash}
                        </p>
                      </div>
                    </div>
                    {/* phone number */}
                    <div className="col-6 pb-2 d-flex align-items-center">
                      <Ionicons name="call-outline" size={16} color="#aaa" />
                      <p className="ps-1 text-dark">{ele.full_mobile}</p>
                    </div>
                  </div>
                </div>
              ))}
            </InfiniteScroll>
          </>
        ) : (
          <div className="center p-3">
            <div className="card p-3 w-100">
              <div className="w-100 text-center">
                <img
                  src={require("../../assets/img/not-found.png")}
                  className="no-data-img"
                />
              </div>
              <p className="center pt-3">No Data</p>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="mb-5">
      <Toaster />
      {/* Header */}
      <div className="head-bar row">
        <div className="col-md-6">
          <h5 className="  mb-2">Delivery Partners</h5>
        </div>

        <div className="col-md-6 sm-pt-2 end">
          {/* search */}
          {/* <div className="input-icons form-group p-2">
            <input
              type="text"
              className="form-control search-bar p-0"
              placeholder="Search here..."
              onChange={handleInputChange}
            />
            <div className="cursor px-2 border-left">
              <Ionicons name="md-search" size={16} color="#ccc" />
            </div>
          </div> */}
          {/* filter */}
          {/* <div className="dropdown">
            <button
              className="btn btn-white dropdown-toggle d-flex align-items-cetner"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src={require("../../assets/icon/filter.png")}
                className="icon-14px"
              />
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <a className="dropdown-item" href="#">
                  <div onClick={() => sort("asc")}>
                    <p className="mb-0 p-2">Oldest</p>
                  </div>
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  <div onClick={() => sort("desc")}>
                    <p className="mb-0 p-2">Newest</p>
                  </div>
                </a>
              </li>
            </ul>
          </div> */}
        </div>
        {/* tab */}
        <div className="d-flex">
          <div className="mt-3">
            {tab_value.map((ele, i) => (
              <button
                key={i}
                type="button"
                className={
                  ele.value == selected_tab.value
                    ? "btn btn-text header-tap me-1 px-3 "
                    : "btn btn-text text-black me-1 px-3"
                }
                onClick={() => {
                  // console.log("ele on tab select :",ele)
                  SetSelectedTab(ele);
                  SetPageNo(1);
                  let pass_data = {
                    page_no: 1,
                    filter: ele,
                  };
                  get_deliverypartner_list(pass_data);
                }}
              >
                {ele.name}
              </button>
            ))}
          </div>
        </div>
      </div>

      <div className="mt-3 px-md-3 px-sm-0 ">
        <div className="row">
          {width < 768 && show_side_tab == false ? (
            <DeliveryPartnerCard />
          ) : width > 768 ? (
            <DeliveryPartnerCard />
          ) : null}

          {show_side_tab && (
            <div className="col-md-6  sm-pb-5 sm-mt-6">
              <div className="overflowY-90vh pb-5 position-sticky-top-50px">
                <div className="card bg-fff p-3">
                  {/* Header */}
                  <div className="d-flex align-items-center mb-4">
                    <div
                      className="cursor pe-3"
                      onClick={() => {
                        SetShowSideTab(false);
                      }}
                    >
                      <MaterialCommunityIcons
                        name="arrow-left"
                        size={18}
                        color="black"
                      />
                    </div>
                    <h6 className="mb-0">Delivery Partner Details</h6>
                  </div>
                  {/* Body */}
                  <div className="">
                    {/* User Data */}
                    <div className="row">
                      <div className="col-6 col-md-4 pb-3">
                        <div className="heading pb-2">Agent Id</div>
                        <div className="value">
                          #{selected_partner_data.agent_id}
                        </div>
                      </div>

                      <div className="col-6 col-md-4 pb-3">
                        <div className="heading pb-2">Name</div>
                        <div className="value">
                          {selected_partner_data?.name}
                        </div>
                      </div>
                      <div className="col-6 col-md-4 pb-3">
                        <div className="heading pb-2">User Name</div>
                        <div className="value">
                          @{selected_partner_data?.user_name}
                        </div>
                      </div>
                    </div>
                    {/* Settlements */}
                    <div className="border-top">
                          {/* tab */}
        <div className="d-flex">
          <div className="mt-3">
            {details_tab_value.map((ele, i) => (
              <button
                key={i}
                type="button"
                className={
                  ele.value == details_selected_tab.value
                    ? "btn btn-text header-tap me-1 px-3 "
                    : "btn btn-text text-black me-1 px-3"
                }
                onClick={() => {
                  // console.log("ele on tab select :",ele)
                  SetDetilsSelectedTab(ele);
                  SetPageNo(1);
           

                  let send_data = {
                    agent_id: selected_partner_data.agent_id,
                    page_no: 1,
                    filter: selected_sl_filter,
                    sort: selected_sl_sort,
                    title: ele,
                  };
                  get_settlements_data(send_data);
                }}
              >
                {ele.name}
              </button>
            ))}
          </div>
        </div>
                      <div className="d-flex align-item-center">
                      <h5>{details_selected_tab.name}</h5>
                        {/* filter */}
                        <label htmlFor="">
                          Filter
                          <select
                            className="form-select"
                            value={selected_sl_filter}
                            onChange={(e: any) => {
                              SetSelectedSLFilter(e.target.value);
                              let send_data = {
                                agent_id: selected_partner_data.agent_id,
                                page_no: 1,
                                filter: e.target.value,
                                sort: selected_sl_sort,
                        title: details_selected_tab,

                              };
                              SetSLPageNo(1);
                              get_settlements_data(send_data);
                            }}
                          >
                            {sl_filter_options.map((ele, i) => (
                              <option key={i} value={ele.value}>
                                {ele.name}
                              </option>
                            ))}
                          </select>
                        </label>

                        {/* sort */}
                        <label htmlFor="">
                          Sort
                          <select
                            className="form-select"
                            value={selected_sl_sort}
                            onChange={(e: any) => {
                              SetSelectedSLSort(e.target.value);
                              let send_data = {
                                agent_id: selected_partner_data.agent_id,
                                page_no: 1,
                                filter: selected_sl_filter,
                                sort: e.target.value,
                        title: details_selected_tab,

                              };
                              SetSLPageNo(1);
                              get_settlements_data(send_data);
                            }}
                          >
                            {sl_sort_options.map((ele, i) => (
                              <option key={i} value={ele.value}>
                                {ele.name}
                              </option>
                            ))}
                          </select>
                        </label>

                        {/* create new */}
                        <button
                          className="btn btn-primary ms-auto"
                          onClick={() => {
                            SetModalCreateSettlement((prevValue: any) => {
                              prevValue.is_open = true;
                              prevValue.data = selected_partner_data;
                              prevValue.title = details_selected_tab.value;

                              return { ...prevValue };
                            });
                          }}
                        >
                          New
                        </button>
                      </div>
                      {settlement_list_loading ? (
                        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((ele, i) => (
                          <div className="card mb-2 p-3" key={i}>
                            <div className="row align-items-center border-bottom">
                              <div className="col-6 pb-3">
                                <Skeleton height={10} width={50} />
                              </div>
                              <div className="col-6 text-end pb-3">
                                <Skeleton height={30} width={100} />
                              </div>
                              <div className="col-6 pb-2">
                                <Skeleton height={10} width={100} count={2} />
                              </div>
                              <div className="col-6 pb-2 text-end">
                                <Skeleton height={10} width={80} count={2} />
                              </div>
                            </div>
                            <div className="pt-2">
                              <Skeleton height={10} width={80} />
                              <div className="pt-2">
                                <Skeleton height={10} width={180} />
                              </div>
                            </div>
                          </div>
                        ))
                      ) : sl_list.length == 0 ? (
                        <div className="center p-3">
                          <div className="card p-3 w-100">
                            <div className="w-100 text-center">
                              <img
                                src={require("../../assets/img/not-found.png")}
                                className="no-data-img"
                              />
                            </div>
                            <p className="center pt-3">No Settlement Data</p>
                          </div>
                        </div>
                      ) : (
                        <div className="">
                          <InfiniteScroll
                            dataLength={sl_list.length}
                            next={() => {
                              SetSLPageNo((prevalue) => {
                                prevalue = prevalue + 1;
                                let send_data = {
                                  agent_id: selected_partner_data.agent_id,
                                  page_no: prevalue,
                                  filter: selected_sl_filter,
                                  sort: selected_sl_sort,
                        title: details_selected_tab,

                                };
                                get_settlements_data(send_data);
                                return prevalue;
                              });
                            }}
                            hasMore={sl_next_page}
                            loader={
                              <div className="center m-5">
                                <div className="lds-dual-ring"></div>
                                <p className=" small ps-2">Just a second ...</p>
                              </div>
                            }
                          >
                            {sl_list.map((ele: any, i: any) => (
                              <div className="p-2" key={i}>
                                <div className="card p-3 w-100" key={i}>
                                  {/* top */}
                                  <div className="row align-items-center">
                                    {/* id */}
                                    <div className="col-6">
                                      <p className="text-dark fw-bold fs-7">
                                        ID: #{ele.id}
                                      </p>
                                    </div>
                                    {/* time / delete*/}
                                    <div className="col-6 end">
                                      <div className="">{ele.time}</div>
                                      {!ele.deleted ? (
                                        <div className="ms-auto">
                                          <button
                                            className="btn btn-danger"
                                            onClick={() => {
                                              // let send_data = {
                                              //   settlement_id: ele.id,
                                              //   agent_id:ele.agent_id,
                                              // };
                                              // delete_settlements(send_data);

                                              SetDeleteModal((prevalue) => {
                                                prevalue.is_open = true;
                                                prevalue.title = details_selected_tab.name;
                                                prevalue.data = {
                                                  id: details_selected_tab,
                                                  settlement_id: ele.id,
                                                agent_id:ele.agent_id,
                                                };
                                            
                                                return { ...prevalue };
                                              });
                                            }}
                                          >
                                            Delete
                                          </button>
                                        </div>
                                      ) : null}
                                    </div>
                                    {/* Amount / notes*/}
                                    <div className="col-6 pb-2">
                                      <p className="text-dark fw-bold fs-7">
                                        Amount :   <span className="fw-300 fs-16">
                                  &#8377;
                                </span>{" "}{ele?.amount}
                                      </p>
                                      <p className="text-dark fw-bold fs-7">
                                        Notes : {ele?.notes}
                                      </p>
                                    </div>
                                  </div>
                                  {/* Order data */}
                                  <div className="row pb-2">
                                    <h6>Orders</h6>
                                    {ele.orders.map(
                                        (o_ele: any, o_index: any) => (
                                        <div
                                          className="border pb-2 pt-2 align-items-center border-bottom"
                                          key={o_index}
                                        >
                                          <p className="col-6 ps-1 text-dark">
                                            Agent Order Id :{" "}
                                            {o_ele.agent_order_id}
                                          </p>
                                          <p className="col-6 ps-1 text-dark">
                                            Settlement Order Id :{" "}
                                            {o_ele.settlement_order_id}
                                          </p>
                                          <p className="col-6 ps-1 text-dark">
                                            Notes : {o_ele.notes}
                                          </p>
                                          <p className="col-6 ps-1 text-dark">
                                            Amount :   <span className="fw-300 fs-16">
                                  &#8377;
                                </span>{" "}{o_ele.amount}
                                          </p>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </InfiniteScroll>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <ModalCreateSettlement
        open={modal_create_settlement.is_open}
        data={modal_create_settlement.data}
        title={modal_create_settlement.title}
        from={"dp_cod_settlement"}
        close={(data: any) => {
          console.log("on create settlement close data :", data);
          SetModalCreateSettlement((prevValue: any) => {
            prevValue.is_open = false;
            prevValue.data = {};
            return { ...prevValue };
          });

          if (data.action == "save") {
            let send_data = {
              agent_id: data.data.agent_id,
              page_no: 1,
              filter: selected_sl_filter,
              sort: selected_sl_sort,
              title: data.title == "delivery_partner_settlement"? { id: 2, name: "Settlement", value: "delivery_partner_settlement" }:{ id: 1, name: "COD Handover", value: "cod_handover" },

            };
            get_settlements_data(send_data);
          }
        }}
      />
      <ModalDeleteComponent
        open={delete_modal.is_open}
        title={delete_modal.title}
      
        data={delete_modal.data}
        on_ok={(data: any) => {

          console.log(data)
          SetDeleteModal((prevalue) => {
            prevalue.is_open = false;
            prevalue.title = "";
          prevalue.data = {};
          
            return { ...prevalue };
          });
          
            
            delete_settlements(data);
          
       
        }}
        close={(data: any) => {
          SetDeleteModal((prevalue) => {
            prevalue.is_open = false;
            prevalue.title = "";
            return { ...prevalue };
          });
        }}
      />
    </div>
  );
}
