import React, { useState, useEffect, useRef } from "react";
import bootstrap from "../../assets/libraries/bootstrap/js/bootstrap";
import "react-loading-skeleton/dist/skeleton.css";

let myModal: any;
export default function AmountModal(props: any) {
  const [close_data, SetCloseData] = useState({
    action: "close",
    value: "",
    index: "",
    demo_order: false
  });
  const amountInp: any = useRef(null);

  const [amountIn, setAmountIn]: any = useState('');
  const [messageIn, setMessageIn]: any = useState('');




  useEffect(() => {
    let myModal1: any = document.getElementById("AmountModal");
    myModal1.addEventListener("hidden.bs.modal", function (event:any) {
      props.close(close_data);
      setAmountIn('');
      setMessageIn('');
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      myModal = new bootstrap.Modal(
        document.getElementById("AmountModal"),
        {}
      );
      myModal.show();
      setTimeout(() => {
        if (amountInp.current) {
          amountInp.current.focus();
        }
      }, 1000)
    }
  }, [props.open]);

  useEffect(() => {
    if (props?.data) {
        if(amountIn == ''){
            if(props?.data?.action == 'eligible'){
                if(props?.data.order.seller_settlement.settlement_eligible){
                  setAmountIn(props?.data.order.seller_settlement.settlement_eligible_amount);
                } else {
                  setAmountIn(props?.data.order.amount);
                }
                if(props?.data?.askMessage && messageIn == ''){
                  if(props?.data.order?.seller_settlement?.settlement_eligible_notes){
                    setMessageIn(props?.data.order?.seller_settlement?.settlement_eligible_notes);
                  } else {
                    setMessageIn('');
                  }
                }
            } else if(props?.data?.action == 'paid'){
              if(props?.data.order.seller_settlement.settlement_paid){
                setAmountIn(props?.data.order.seller_settlement.settlement_paid_amount);
              } else {
                setAmountIn(props?.data.order.seller_settlement.settlement_eligible_amount);
              }
            }
        }
    }
  }, [props?.data]);


  return (
    <div
      className="modal fade"
      id="AmountModal"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog  modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title" id="ModalLabel">{props?.data?.title}</h6>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body" id="AmountModalBody">
            <div className="mb-3">
                <label>Amount</label>
              <input type="number" className="form-control" value={amountIn} ref={amountInp} onChange={(e) => setAmountIn(e.target.value)} />
            </div>
            {props?.data?.askMessage && (
                <div className="mb-3">
                    <label>Notes</label>
                    <textarea className="form-control" onChange={(e) => setMessageIn(e.target.value)} value={messageIn} />
                </div>
            )}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-primary" onClick={() => {
              let close_data_t:any = {};
              close_data_t.status = true;
              close_data_t.data = props?.data;
              close_data_t.amount = amountIn;
              close_data_t.message = messageIn;
              props.done(close_data_t);
              myModal.hide();
            }}>{props?.data?.button?props?.data?.button:'Done'}</button>
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
