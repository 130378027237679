"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.calculateDirection = calculateDirection;

var _types = require("../types");

function calculateDirection(trace) {
  var direction;
  var negative = _types.TraceDirectionKey.NEGATIVE;
  var positive = _types.TraceDirectionKey.POSITIVE;
  var current = trace[trace.length - 1];
  var previous = trace[trace.length - 2] || 0;

  if (trace.every(function (i) {
    return i === 0;
  })) {
    return _types.TraceDirectionKey.NONE;
  }

  direction = current > previous ? positive : negative;

  if (current === 0) {
    direction = previous < 0 ? positive : negative;
  }

  return direction;
}