import React ,{useState,useEffect} from 'react'
import { useOutletContext } from "react-router-dom";
import { api } from "../../utils/Api";
import InfiniteScroll from "react-infinite-scroll-component";

import {
  Ionicons,
  MaterialIcons,
  MaterialCommunityIcons,
} from "@expo/vector-icons";

function AllowedReservation() {

  const [statistics_data, SetStatisticsData]: any = useState([]);
  const OutletContext = useOutletContext();
  const [timeoutId, setTimeoutId] = useState(null);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [sort, setSort] = useState("");
  const [filter_data, setFilterData] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [btnDisable, setBtnDisable] = useState(true);

 
    const [startDateShow, setStartDateShow] = useState("");
  const [endDateShow, setEndDateShow] = useState("");
  const [page_no, SetPageNo] = useState(1);
  const [next_page, SetNextPage] = useState(false);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [width, SetWidth] = useState(innerWidth);
  const [table_heading, SetTableHeading] = useState([]);
  const [table_data, SetTableData] = useState([]);
  const [card_data, SetCardData] = useState([]);
  const [companyZones, SetCompanyZones] = useState([]);
  const [slots, setSlots] = useState([]);
  const [id, setId] = useState("");
  const [date, setDate] = useState("");



  const [selectedValue, setSelectedValue] = useState("");
  const innitialTime = [
    { start: "12:00 AM", end: "01:00 AM", total_reservation: "" },
    { start: "01:00 AM", end: "02:00 AM", total_reservation: "" },
    { start: "02:00 AM", end: "03:00 AM", total_reservation: "" },
    { start: "03:00 AM", end: "04:00 AM", total_reservation: "" },
    { start: "04:00 AM", end: "05:00 AM", total_reservation: "" },
    { start: "05:00 AM", end: "06:00 AM", total_reservation: "" },
    { start: "06:00 AM", end: "07:00 AM", total_reservation: "" },
    { start: "07:00 AM", end: "08:00 AM", total_reservation: "" },
    { start: "08:00 AM", end: "09:00 AM", total_reservation: "" },
    { start: "09:00 AM", end: "10:00 AM", total_reservation: "" },
    { start: "10:00 AM", end: "11:00 AM", total_reservation: "" },
    { start: "11:00 AM", end: "12:00 PM", total_reservation: "" },
    { start: "12:00 PM", end: "01:00 PM", total_reservation: "" },
    { start: "01:00 PM", end: "02:00 PM", total_reservation: "" },
    { start: "02:00 PM", end: "03:00 PM", total_reservation: "" },
    { start: "03:00 PM", end: "04:00 PM", total_reservation: "" },
    { start: "04:00 PM", end: "05:00 PM", total_reservation: "" },
    { start: "05:00 PM", end: "06:00 PM", total_reservation: "" },
    { start: "06:00 PM", end: "07:00 PM", total_reservation: "" },
    { start: "07:00 PM", end: "08:00 PM", total_reservation: "" },
    { start: "08:00 PM", end: "09:00 PM", total_reservation: "" },
    { start: "09:00 PM", end: "10:00 PM", total_reservation: "" },
    { start: "10:00 PM", end: "11:00 PM", total_reservation: "" },
    { start: "11:00 PM", end: "12:00 AM", total_reservation: "" },
  ];
  const [timeSlots, setTimeSlots] = useState(innitialTime);



  useEffect(() => {

    const fetchData = async () => {
      setBtnDisable(true); // Disable buttons during API call

      let send_data = {
        startDate:startDate,
        endDate:endDate,
        // page_no: 1,
        // search:filter_data
      };

      await get_overview(send_data);
      await get_company_zones();

      setBtnDisable(false); // Re-enable buttons after API call
    };

    fetchData();
  }, [sort,selectedDate]);


  
  function convertDateTime(dateTimeStr) {
    const date = new Date(dateTimeStr);
    
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-11
    const year = date.getFullYear();
    
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = '00'; // Set seconds to 00
    
    const formattedDateTime = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    return formattedDateTime;
  }

  const handleStartChange = (e) => {
    //  let start:any = moment(date).format('DD-MM-YYYY HH:mm:ss')
    console.log(e.target.value)
    const convertedDateTime = convertDateTime(e.target.value);
      console.log(convertedDateTime);
      setStartDateShow(e.target.value)
     setStartDate(convertedDateTime);
    //  getSuperappStatistics(start,endDate)
    //  get_order_statistics(start,endDate)
    //  get_order_price_statistics(start,endDate)
    
    };
  
    const handleEndChange = (e) => {
      const convertedDateTime = convertDateTime(e.target.value);
      setEndDateShow(e.target.value)
    
    
        setEndDate(convertedDateTime);
        
      // getSuperappStatistics(startDate,end)
      // get_order_statistics(startDate,end)
      // get_order_price_statistics(startDate,end)
   
     
     
     };

     const handleApply = () => {

      const startTimestamp = new Date(startDateShow).getTime();
      const endTimestamp = new Date(endDateShow).getTime();
  
      console.log(startTimestamp)
      console.log(endTimestamp)
  
     
      console.log("hi")
      if (startTimestamp < endTimestamp) {
      //     getSuperappStatistics(startDate,endDate)
      // get_order_statistics(startDate,endDate,activeOrTab)
      // get_order_price_statistics(startDate,endDate,activeTab)
          // Add your logic for applying the selected dates

          let send_data = {
            page_no: 1,
            have_more: false,
            startDate:startDate,
            endDate:endDate,
      search:filter_data

          };
        
          get_overview(send_data);
      } else {
        toast.error("End date cannot be earlier than start date.");
      }

      SetPageNo(1)
  };

  async function get_company_zones() {

    let response: any = await api("/deliverycompany/delivery_company_zone_services");
    console.log("/qr/overview response :", response.response.data[0]);
    if (response.status_code == 200) {
SetCompanyZones(response.response.data)
setSelectedValue(response.response.data[0])
get_slots(response.response.data[0]);
setId(response.response.data[0].id)
     
    }
  }

  async function handleSave() {
console.log(id)
const timing = timeSlots.map((ele, i) => {
  return {
    ...ele,
    start: ele.start.replace(/\s+/g, ""), // Remove spaces from start time
    end: ele.end.replace(/\s+/g, ""), // Remove spaces from end time
    date: date, // Add the date key
  };
});

let pass_data = {
  post: {
    // date:formattedDate,
    timing:timing,

    id: id,
  },
};
    let response: any = await api("/deliverycompany/create_booking_slot",pass_data);
    console.log("/qr/overview response :", response.response.data[0]);
    if (response.status_code == 200) {
SetCompanyZones(response.response.data)
setSelectedValue(response.response.data[0])
get_slots(response.response.data[0]);
     
    }
  }


  async function get_slots(get_data) {
    // setBtnDisable(true)
    console.log(selectedDate)


    const date = new Date(selectedDate);

const day = String(date.getDate()).padStart(2, '0');
const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
const year = date.getFullYear();

const formattedDate = `${day}-${month}-${year}`;
console.log(formattedDate)
setDate(formattedDate)
    console.log(get_data)


    let pass_data = {
      get: {
        // date:formattedDate,
        date: formattedDate,

        id: get_data.id,
      },
    };
console.log(pass_data)
    let response: any = await api("/deliverycompany/get_booking_slots",pass_data);
    console.log("/deliverycompany/get_booking_slots:", response.response);
    if (response.status_code == 200) {

    console.log("/deliverycompany/get_booking_slots:", response.response.status);
    if(response.response.status){
    const updatedTimeSlots = timeSlots.map((slot) => {
      // Find matching data from responseData using start and end time comparison
      const matchingData = response.response.data.find(
        (data) =>
    
          data.start_time_12.trim().toUpperCase() === slot.start.trim().toUpperCase() &&
          data.end_time_12.trim().toUpperCase() === slot.end.trim().toUpperCase()
      );
 
      // If matching data is found, update the slot with the total_reservation value
      if (matchingData) {
        return {
          ...slot,
          total_reservation: matchingData.total_reservation,
        };
      }
    
      // Return the slot as it is if no matching data is found
      return slot;
    });
   
    console.log(updatedTimeSlots);
    
    console.log("Updated Time Slots:", updatedTimeSlots);
    
        setTimeSlots(updatedTimeSlots);
        // setBtnDisable(false)
    
  }else{
    setTimeSlots(innitialTime);
  }
  setBtnDisable(false)
// SetCompanyZones(response.response.data)
// setSelectedValue(response.response.data[0])
     
    }
  }
  async function get_overview(get_data: any) {

 
     let pass_data = {
       get: {
         filter_from:get_data.startDate,
         filter_to: get_data.endDate,
         data_type:"1"
        //  order_by: sort,

// search:get_data.search,
         
//         page: get_data.page_no,
       },
     };
     let response: any = await api("/orders/dynamic_datas", pass_data);
     console.log("/qr/overview response :", response.response);
     if (response.status_code == 200) {

      SetTableHeading(response.response.table_heading)
      SetTableData(response.response.table_data)

      SetCardData(response.response.card_data)

      //  if (response.response.hasOwnProperty("data")) {

        
      //   //  let statistics_data = response.response.data;
 
      //    if (get_data.load_more) {
      //     SetStatisticsData((prevValue) => {
      //       response?.response?.data?.map((ele: any) => {
      //         prevValue.push(ele);
      //       });
      //       return [...prevValue];
      //     });
      //   } else {
      //     SetStatisticsData(response.response.data);

      //   }
    
 
  
      //  }
  
      // // if(!get_data.hasOwnProperty("type")){
      // if (response.response.hasOwnProperty("next_page")) {
      //   SetNextPage(response.response.next_page);
      //   SetPageNo((prevalue) => {
      //     prevalue = prevalue + 1;
      //     return prevalue;
      //   });
      // }
 
      
     }
   }

   const handleInputChange = (e: any) => {
    const searchValue = e.target.value;
    setFilterData(searchValue);
    // Clear previous timeout
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // Set a new timeout
    const newTimeoutId = setTimeout(() => {
      // Call your API with the search value
      let send_data = {
      startDate:startDate,
      endDate:endDate,
      page_no: 1,
      search:   searchValue
    };
    get_overview(send_data);
      SetPageNo(1);
    }, 1000); // 5 milliseconds delay

    setTimeoutId(newTimeoutId);
  };

  const formatDate = (date: Date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const year = date.getFullYear();
    return `${year}-${month}-${day}`; // Return YYYY-MM-DD for the input type="date"
  };

  // Convert input value to Date object
  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = new Date(e.target.value);
    setSelectedDate(newDate);
  };

  const handlePrevDate = () => {
    const prevDate = new Date(selectedDate);
    prevDate.setDate(selectedDate.getDate() - 1);
    setSelectedDate(prevDate);
  };

  const handleNextDate = () => {
    const nextDate = new Date(selectedDate);
    nextDate.setDate(selectedDate.getDate() + 1);
    setSelectedDate(nextDate);
  };

  return (
    <div>
    <div
    className={OutletContext ? "head-bar sticky-sm " : "head-bar "}
    style={OutletContext == false ? { width: width } : {}}
  >

<div>
      {/* <label htmlFor="dropdown1">Choose an option:</label> */}
      <select id="dropdown1" value={selectedValue} className="form-control mb-2"
      // onChange={handleChange}
      >
        {companyZones.map((option,i) => (
          <option key={i} >
            {option.brand_name} , {option.service_name} , {option.zone_name} 
          </option>
        ))}
      </select>
      </div>
        <div className="d-flex align-items-center justify-content-center">
      {/* Previous Date Button */}
      <button
        className="btn btn-secondary btn-sm me-2"
        onClick={handlePrevDate}
        aria-label="Previous Date"
        disabled={btnDisable}
      >
        &#8592;
      </button>

      {/* Date Input */}
      <input
        type="date"
        className="form-control text-center mx-auto"
        value={formatDate(selectedDate)} // Use YYYY-MM-DD for the input
        onChange={handleDateChange}
        style={{ maxWidth: "150px" }}
      />

      {/* Next Date Button */}
      <button
        className="btn btn-secondary btn-sm ms-2"
        onClick={handleNextDate}
        aria-label="Next Date"
        disabled={btnDisable}
      >
        &#8594;
      </button>
    </div>

    

        </div>

       
    <div className=" sm-mt-6 sm-mb-6 sm-pb-6 card p-3 mx-2 mt-2 small-card">
    <div className="container sm-mb-6 ">
      
      {/* Header */}
      <div className="row mb-3">
        <div className="col-7">
          <h5>Time</h5>
        </div>
        <div className="col-5">
          <h5>Allowed Reservation</h5>
        </div>
      </div>

      {/* Render time slots */}
      {timeSlots.map((slot, index) => (
        <div className="row mb-2" key={index}>
          <div className="col-7">
            <span>{slot.start} - {slot.end}</span>
          </div>
          <div className="col-5">
            <input 
              type="number" 
              value={slot.total_reservation} 
              onChange={(e) => {
                const newTimeSlots = [...timeSlots];
                newTimeSlots[index].total_reservation = e.target.value;
                setTimeSlots(newTimeSlots); // Update the state with the new value
              }}
              className="form-control"
            />
          </div>
        </div>
      ))}

         {/* Save Button */}
         <div className="row mt-3">
  <div className="col-12 text-center">
    <button
      className="btn btn-primary w-100 "
      // style={{ fontWeight: 'bold' }}
      onClick={handleSave}
    >
      Save
    </button>
  </div>
</div>
    </div>

   
    </div>


</div>
  )
}

export default AllowedReservation;