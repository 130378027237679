import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate, useOutletContext } from "react-router-dom";
import { api } from "../../utils/Api";

import "react-checkbox-tree/lib/react-checkbox-tree.css";
import CheckboxTree from "react-checkbox-tree";
// import ModalImportRoles from "./ModalImportRoles";
// import { omit } from "lodash";
import { Entypo, MaterialCommunityIcons } from "@expo/vector-icons";
import { AppConfig } from "../../config";



// const nodes = [
//   {
//     value: "brand",
//     label: "Brand",
//     children: [
//       { value: "BrandDashboard", label: "Dashboard" },

//       {
//         value: "BrandOutlets",
//         label: "Outlets",
//         children: [
//           { value: "OutletList", label: "Outlet List" },
//           { value: "CreateOutlet", label: "Create Outlet" },
//           { value: "EditOutlet", label: "Edit Outlet" },
//           { value: "DeleteOutlet", label: "Delete Outlet" },
//         ],
//       },

//       {
//         value: "UserRoles",
//         label: "User Roles",
//         children: [
//           { value: "UserRolesList", label: "User Roles List" },
//           { value: "CreateUserRoles", label: "Create User Roles" },
//           { value: "EditUserRoles", label: "Edit User Roles" },
//           { value: "DeleteUserRoles", label: "Delete User Roles" },
//         ],
//       },
//     ],
//   },

//   {
//     value: "outlet",
//     label: "Outlet",
//     children: [
//       { value: "OutletDashboard", label: "Dashboard" },
//       {
//         value: "Services",
//         label: "Services",
//         children: [
//           { value: "ServiceList", label: "Services List" },
//           { value: "CreateService", label: "Create Service" },
//           { value: "EditService", label: "Edit Service" },
//           { value: "DeleteService", label: "Delete Service" },
//         ],
//       },
//       {
//         value: "Events",
//         label: "Events",
//         children: [
//           { value: "EventList", label: "Events List" },
//           { value: "CreateEvent", label: "Create Event" },
//           { value: "EditEvent", label: "Edit Event" },
//           { value: "DeleteEvent", label: "Delete Event" },
//         ],
//       },
//       {
//         value: "Company",
//         label: "Company",
//         children: [
//           { value: "CompanyList", label: "Company List" },
//           { value: "CreateCompany", label: "Create Company" },
//           { value: "EditCompany", label: "Edit Company" },
//           { value: "DeleteCompany", label: "Delete Company" },
//         ],
//       },
//     ],
//   },
// ];

export default function SellerUserRolesAdd(props: any) {
  const [id_value, SerIdValue] = useState(useParams());
  const [role_name, SetRoleName] = useState("");
  const [page_type, SetPageType] = useState("");
  const [error, SetError] = useState(false);
  const [page_data, SetPageData] = useState([]);
  const [checkedNode, SetCheckedNode]: any = useState([]);
  const [expandedNode, SetExpandedNode] = useState(["brand", "outlet"]);


  useEffect(() => {
    SetPageType(props.val.page_type)
    console.log(props)
    get_custom_roles()
    return(SetCheckedNode([]))
},[props])

async function create_user_role() {
  if (role_name !== "" && checkedNode !== "") {
    let pass_data = {
    
      post: {
        name: role_name,
        module_id:
        props.val.role_type == "brand"
            ? 1
            : props.val.role_type == "franchise"
            ? 2
            : 3,
        pages: checkedNode,
      },
    };
    console.log("Pass data :", pass_data);
    let data: any = await api("/sellers/create_role", pass_data);
    console.log("create_user_role response :", data);
    if (data.status_code == 200) {
      console.log("201 created response :", data.response);
      // navigate("/brand/" + id_value.brand_id + "/userrole");
      props.onData(false); 
    }
  } else {
    SetError(true);
  }
}

async function edit_user_role() {
  if (role_name !== "" && checkedNode !== "") {
    console.log(props)
    let pass_data = {
      // get: {
      //   brand: id_value.brand_id,
      // },
      post: {
        id: props.val.data.id,
        name: role_name,
        // type: parms.role_type == "brand" ? 1 : 2,
        pages: checkedNode,
      },
    };
    console.log("Pass data :", pass_data);
    let data = await api("/sellers/edit_role", pass_data);
    if (data.status_code == 200) {
      console.log("201 created response :", data.response);
      // navigate("/brand/" + id_value.brand_id + "/userrole");
      props.onData(false); 
    }
  } else {
    SetError(true);
  }

}


  function get_header() {
    if (props.val.page_type == "add") {
      if (props.val.role_type == "brand") {
        return "Create Brand User Role";
      } else if (props.val.role_type == "franchise") {
        return "Create franchise User Role";
      } else if (props.val.role_type == "outlet") {
        return "Create outlet User Role";
      }
    } else if (props.val.page_type == "edit") {
      if (props.val.role_type == "brand") {
        return "Edit Brand User Role";
      } else if (props.val.role_type == "franchise") {
        return "Edit franchise User Role";
      } else if (props.val.role_type == "outlet") {
        return "Edit outlet User Role";
      }
    }
    
    // else if (page_type == "view") {
    //   if (role_type == "brand") {
    //     return "View Brand User Role";
    //   } else if (role_type == "franchise") {
    //     return "View franchise User Role";
    //   } else if (role_type == "outlet") {
    //     return "View outlet User Role";
    //   }
    // }
 
}

  
  async function get_custom_roles() {
    // SetIsLoading(true)
    // let pass_data = {
    //   get: {
    //     brand: id_value.brand_id,
    //   },
    // };
    let data: any = await api("/sellers/user_roles");
    console.log("get_custom_roles response :", data);
    if (data.status_code == 200) {
    //   data.response.roles.map((role_element: any, role_index: any) => {
        // if (role_element.name == "Brand") {
        //   if (role_element.hasOwnProperty("roles")) {
        console.log(id_value)
if(props.val.role_type == "brand"){
        let brandRoles = filterRolesByModule(data.response.brand_pages, "Brand");
        let franchiseRoles = filterRolesByModule(data.response.franchise_pages, "Franchise");
        let outletRoles = filterRolesByModule(data.response.outlet_pages, "Outlet");

        // SetBrandActiveRoles(brandRoles);
        console.log(brandRoles)

       let parent=[{
        name: "Brand",
        id:101,
        children: brandRoles,
      },{
        name: "Franchise",
        id:102,
        children: franchiseRoles,
      },{
        name: "Outlet",
        id:103,
        children: outletRoles,
      },]
        SetPageData(parent)
        if(props.val.page_type == "edit"){
          console.log(props.val.data)
          SetCheckedNode(props.val.data.pages)
          SetRoleName(props.val.data.name)
        }
}

if(props.val.role_type == "franchise"){
  const franchiseRoles = filterRolesByModule(data.response.franchise_pages, "Franchise");
  // SetBrandActiveRoles(brandRoles);
  console.log(franchiseRoles)
  SetPageData(franchiseRoles)
  if(props.val.page_type == "edit"){
    console.log(props.val.data)
    SetCheckedNode(props.val.data.pages)
    SetRoleName(props.val.data.name)
    
  }
}
if(props.val.role_type == "outlet"){
  const outletRoles = filterRolesByModule(data.response.outlet_pages, "Outlet");
  // SetBrandActiveRoles(brandRoles);
  console.log(outletRoles)
  SetPageData(outletRoles)
  if(props.val.page_type == "edit"){
    console.log(props.val.data)
    SetCheckedNode(props.val.data.pages)
    SetRoleName(props.val.data.name)

  }
}
// const franchiseRoles = filterRolesByModule(data.response.roles, "Franchise");
// SetFranchiesActiveRoles(franchiseRoles);

// const outletRoles = filterRolesByModule(data.response.roles, "Outlet");
// SetOutletActiveRoles(outletRoles);
        console.log(data)
            // SetBrandActiveRoles(data.response.roles);
        //   } else {
        //     SetBrandActiveRoles([]);
        //   }
        // } 
        // else if (role_element.name == "Outlet") {
        //   if (role_element.hasOwnProperty("roles")) {
        //     SetOutletActiveRoles(role_element.roles);
        //   } else {
        //     SetOutletActiveRoles([]);
        //   }
        // } else if (role_element.name == "Franchise") {
        //   if (role_element.hasOwnProperty("roles")) {
        //     SetFranchiesActiveRoles(role_element.roles);
        //   } else {
        //     SetFranchiesActiveRoles([]);
        //   }
        // }
    //   });
    // SetIsLoading(false)

    }
  }
  function on_page_check(target_data: any) {
    SetError(false);
    console.log("on_page_check target_data :", target_data);
    let old_page_data: any = page_data;
    if (target_data.checked) {
      console.log("On Check");
      if (target_data.treeDepth == 0) {
        console.log(
          "target_data.treeDepth == 0 :",
          old_page_data[target_data.index]
        );
        old_page_data.map((ele: any, ele_index: any) => {
          if (ele_index == target_data.index) {
            console.log("ele : ", ele);
            ele.checked = true;
            ele.children.map((ele_child: any, ele_child_index: any) => {
              ele_child.checked = true;
              if (ele_child.hasOwnProperty("children")) {
                ele_child.children.map((child: any, child_index: any) => {
                  child.checked = true;
                });
              }
            });
          }
        });
        console.log("old_page_data :", old_page_data);
      }
      //
      if (target_data.treeDepth == 1) {
        let parent_index = find_top_parent_index(target_data.parent.value);
        console.log(
          "target_data.treeDepth == 1 :",
          old_page_data[parent_index].children[target_data.index]
        );
        old_page_data[parent_index].children.map(
          (ele_child: any, ele_child_index: any) => {
            if (target_data.index == ele_child_index) {
              ele_child.checked = true;
              if (ele_child.hasOwnProperty("children")) {
                ele_child.children.map((child, child_index) => {
                  child.checked = true;
                });
              }
            }
          }
        );

        let all_checked: any = old_page_data[parent_index].children.every(
          (p) => p.checked == true
        );
        console.log("all_checked :", all_checked);
        if (all_checked) {
          old_page_data[parent_index].checked = true;
        } else {
          old_page_data[parent_index].checked = false;
        }
        console.log("old_page_data :", old_page_data);
      }
      //
      if (target_data.treeDepth == 2) {
        let top_parent_index = find_top_parent_index(
          target_data.parent.parent_value
        );
        let parent_index = find_parent_index(
          target_data.parent.value,
          top_parent_index
        );
        console.log(
          "target_data.treeDepth == 2 :",
          old_page_data[top_parent_index].children[parent_index].children[
            target_data.index
          ]
        );
        old_page_data[top_parent_index].children[parent_index].children.map(
          (child: any, child_index: any) => {
            if (target_data.index == child_index) {
              child.checked = true;
            }
          }
        );
        let all_checked = old_page_data[top_parent_index].children[
          parent_index
        ].children.every((p) => p.checked == true);

        if (all_checked) {
          old_page_data[top_parent_index].children[parent_index].checked = true;
        } else {
          old_page_data[top_parent_index].children[parent_index].checked =
            false;
        }

        let all_checked1 = old_page_data[top_parent_index].children.every(
          (p) => p.checked == true
        );

        if (all_checked1) {
          old_page_data[top_parent_index].checked = true;
        } else {
          old_page_data[top_parent_index].checked = false;
        }
        console.log("old_page_data :", old_page_data);
      }
    } else {
      console.log("On UnCheck");
      if (target_data.treeDepth == 0) {
        console.log(
          "target_data.treeDepth == 0 :",
          old_page_data[target_data.index]
        );
        old_page_data.map((ele, ele_index) => {
          if (ele_index == target_data.index) {
            console.log("ele : ", ele);
            ele.checked = false;
            ele.children.map((ele_child, ele_child_index) => {
              ele_child.checked = false;
              if (ele_child.hasOwnProperty("children")) {
                ele_child.children.map((child, child_index) => {
                  child.checked = false;
                });
              }
            });
          }
        });
        console.log("old_page_data :", old_page_data);
      }
      //
      if (target_data.treeDepth == 1) {
        let parent_index = find_top_parent_index(target_data.parent.value);
        console.log(
          "target_data.treeDepth == 1 :",
          old_page_data[parent_index].children[target_data.index]
        );
        old_page_data[parent_index].children.map(
          (ele_child, ele_child_index) => {
            if (target_data.index == ele_child_index) {
              ele_child.checked = false;
              if (ele_child.hasOwnProperty("children")) {
                ele_child.children.map((child, child_index) => {
                  child.checked = false;
                });
              }
            }
          }
        );
        let all_checked = old_page_data[parent_index].children.every(
          (p: any) => p.checked == true
        );
        console.log("all_checked :", all_checked);
        if (all_checked) {
          old_page_data[parent_index].checked = true;
        } else {
          old_page_data[parent_index].checked = false;
        }
        console.log("old_page_data :", old_page_data);
      }
      //
      if (target_data.treeDepth == 2) {
        let top_parent_index = find_top_parent_index(
          target_data.parent.parent_value
        );

        let parent_index = find_parent_index(
          target_data.parent.value,
          top_parent_index
        );
        console.log(
          "target_data.treeDepth == 2 :",
          old_page_data[top_parent_index].children[parent_index].children[
            target_data.index
          ]
        );
        old_page_data[top_parent_index].children[parent_index].children.map(
          (child: any, child_index: any) => {
            if (target_data.index == child_index) {
              child.checked = false;
            }
          }
        );
        let all_checked = old_page_data[top_parent_index].children[
          parent_index
        ].children.every((p: any) => p.checked == true);

        if (all_checked) {
          old_page_data[top_parent_index].children[parent_index].checked = true;
        } else {
          old_page_data[top_parent_index].children[parent_index].checked =
            false;
        }

        let all_checked1 = old_page_data[top_parent_index].children.every(
          (p) => p.checked == true
        );

        if (all_checked1) {
          old_page_data[top_parent_index].checked = true;
        } else {
          old_page_data[top_parent_index].checked = false;
        }
        console.log("old_page_data :", old_page_data);
      }
    }
    SetPageData(old_page_data);
  }

  function find_top_parent_index(find_value: any) {
    let index = page_data.findIndex((p) => p.value == find_value);
    return index;
  }
  function find_parent_index(find_value: any, parent_index: any) {
    let index = page_data[parent_index].children.findIndex(
      (p: any) => p.value == find_value
    );
    return index;
  }
  console.log(page_data)

  const treeData = page_data.map(transformNode);

  function transformNode(node) {
    const transformed = {
      value: node.id,
      label: node.name
    };
  
    if (node.children && node.children.length > 0) {
      transformed.children = node.children.map(transformNode);
    }
  
    return transformed;
  }
  function filterRolesByModule(data, module) {
    return data.filter(role => role.module === module);
  }


  return(<div>
      {/* <div className="head-bar sticky-sm"
        > */}
        <div className="d-flex align-items-center pb-2">
          <div className="d-flex">
            {/* <Link to={"/seller_user_roles"} className="">
              <img src={AppConfig.CDN_Media_url+"d947fdcc659e0c41edb4b5ea9e8c2396.svg"} className="type-img" />
          
            </Link> */}
            <h6 className="ps-2 mb-0 text-dark">{get_header()}</h6>
          </div>
          {/* {props.val.page_type == "add" ? ( */}
            {/* <div className="ms-auto cursor">
              <button
                className="btn-sm btn btn-white btn-radius d-flex align-items-center py-1 pe-3 ps-2"
                onClick={() => {
                  let set_data = {
                    is_open: true,
                    data: role_data,
                  };
                  SetImportRoleModal(set_data);
                }}
              >
                <img src={AppConfig.CDN_Media_url+"b46cd3178b77de8701ffd6a260168290.svg"} className="w-14px" />
             
                <p className="ps-1 text-prime fw-bold">Import</p>
              </button>
            </div> */}

          {/* ) : null} */}
        </div>
      {/* </div> */}

      <div className="mt-3 px-md-3 px-sm-0 mb-5"
        // style={OutletContext == false ? { width: width } : {}}
         >
        <div className="card p-4">
          <div className="row align-items-center">
            <div className="col-md-8 mb-3">
              <label className="mb-2 form-label ">Role Name</label>
              <input
                type="text"
                name="role"
                className="form-control"
                placeholder="Your Role Name"
                value={role_name}
                required
                disabled={
                  page_type == "add" || page_type == "edit" ? false : true
                }
                onChange={(e) => {
                  SetRoleName(e.target.value);
                  if (e.target.value.length != 0) {
                    SetError(false);
                  }
                }}
              />
              {/* {error == true ? (
                <small className="text-red">Enter role name</small>
              ) : null} */}
            </div>
            {/* <div className="col-md-4 ps-md-4 mb-3 d-flex">
              <label className="me-3 mb-2 ">Role Type :</label>
              {/* {page_type == "add" ? (
                <div>
                  <div className="md-radio md-radio-inline">
                    <Link
                      to={
                        "/brand/" +
                        id_value.brand_id +
                        "/userrole/" +
                        props.val.page_type +
                        "/brand/" +
                        id_value.role_id
                      }
                    >
                      <input
                        id="radiobrand"
                        type="radio"
                        name="type"
                        value="brand"
                        disabled={true}
                        checked={role_type == "brand" ? true : false}
                      />
                      <label htmlFor="radiobrand">Brand</label>
                    </Link>
                  </div>
                  <div className="md-radio md-radio-inline">
                    <Link
                      to={
                        "/brand/" +
                        id_value.brand_id +
                        "/userrole/" +
                        props.val.page_type +
                        "/outlet/" +
                        id_value.role_id
                      }
                    >
                      <input
                        id="radiooutlet"
                        type="radio"
                        name="type"
                        value="outlet"
                        disabled={true}
                        checked={role_type == "outlet" ? true : false}
                      />
                      <label htmlFor="radiooutlet">Outlet</label>
                    </Link>
                  </div>
                </div>
              ) : (
                <div>
                  <p>{role_type == "brand" ? "Brand" : "Outlet"} </p>
                </div>
              )} 
              <p className="text-dark fs-6 fw-bold">
                {role_type == "brand"
                  ? "Brand"
                  : role_type == "franchise"
                  ? "Franchise"
                  : "Outlet"}{" "}
              </p>
            </div> */}
            {/* --------------------------------------------------------------------------------------------------------------------------(Premission)*/}
            <div className="col-lg-12">
              <p className="mb-3 mt-2 text-dark fw-bold">
                Permission Pages &amp; Actions
              </p>
              {console.log(page_data)}
              {console.log(treeData)}
   
            

              <CheckboxTree
                //   nodes={nodes}
                nodes={treeData}
                checked={checkedNode}
                expanded={expandedNode}
                onCheck={(checked, targetNode) => {
                  console.log("Checked :", checked);
                  SetCheckedNode(checked);
                  on_page_check(targetNode);
                }}
                disabled={
                  page_type == "add" || page_type == "edit" ? false : true
                }
                onClick={(targetNode) => {
                  console.log("onClick targetNode :", targetNode);
                }}
                onExpand={(expanded) => {
                  // console.log("expanded :", expanded);
                  SetExpandedNode(expanded);
                }}
                icons={{
                  check: <i className="fas fa-check-square" />,
                  uncheck: <span className="fas fa-square" />,
                  halfCheck: <span className="fas fa-minus-square" />,
                  expandClose: <span className="fas fa-plus" />,
                  expandOpen: <span className="fas fa-minus" />,
                  expandAll: <span className="fas fa-minus-square" />,
                  collapseAll: <span className="fas fa-minus-square" />,
                  // parentClose: <span className="fas fa-folder" />,
                  // parentOpen: <span className="fas fa-folder-open" />,
                  // leaf: <span className="fas fa-file" />,
                }}
              />

              {error == true ? (
                <small className="text-red">Select any one</small>
              ) : null}
            </div>
          </div>
          <hr />
          {props.val.page_type != "view" ? (
            <div className="row pt-2">
              <div className="col-6">
                {/* {/* <Link
                  to={"/brand/" + id_value.brand_id + "/userrole"}
                  className="btn btn-light-outline px-3 ms-auto"
                >
                  Cancel
                </Link>  */}
              </div>
              <div className="col-6 text-end">
                {props.val.page_type == "add" ? (
                  <button
                    className="btn bg-prime text-white btn-radius px-3"
                    onClick={() => {
                      create_user_role();
                    }}
                  >
                   
                    Create
                  </button>
                ) : (
                  <button
                    className="btn bg-prime text-white btn-radius px-3"
                    onClick={() => {
                      edit_user_role();
                    }}
                  >
                 
                    Update
                  </button>
                )}
              </div>
            </div>
          ) : null}
        </div>
      </div>

    
  </div>)
}