import React, { useState, useEffect, useContext } from "react";
import DataContext from "../../utils/Context/DataContext";
import Chart from "react-apexcharts";
import { Link, useParams, useOutletContext } from "react-router-dom";
import { api } from "../../utils/Api";
import Datetime from 'react-datetime';
import toast, { Toaster } from "react-hot-toast";
import 'react-datetime/css/react-datetime.css';
import Skeleton from "react-loading-skeleton";
import {
  Ionicons,
  MaterialIcons,
  MaterialCommunityIcons,
} from "@expo/vector-icons";

import moment from 'moment';

export default function Dashboard(props: any) {
  const context = useContext(DataContext);
  const [data,setData] = useState([])
  const [order_data,setOrderData] = useState([])
  const [order_price_data,setOrderPriceData] = useState([])
  const [tabs, setTabs] = useState([]);
  const [or_tabs, setOrTabs] = useState([]);

  const [activeTab, setActiveTab] = useState("");
  const [activeOrTab, setActiveOrTab] = useState("");

  const [superapp_is_loading,SetSuperappIsLoading] = useState(false)
  const [order_is_loading,SetOrderIsLoading] = useState(false)
  const [order_price_is_loading,SetOrderPriceIsLoading] = useState(false)
  const todayStart = moment(moment().startOf('day')).format('DD-MM-YYYY HH:mm:ss');  // Today's date at 00:00:00
  const todayEnd =  moment(moment().endOf('day')).format('DD-MM-YYYY HH:mm:ss');      // Today's date at 23:59:59


  const now = new Date();

  const startOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
  const endOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
  const [startDateShow, setStartDateShow] = useState(getFormattedDateTime(startOfToday));
  const [endDateShow, setEndDateShow] = useState(getFormattedDateTime(endOfToday));
  const [startDate, setStartDate] = useState(convertDateTime(getFormattedDateTime(startOfToday)));
  const [endDate, setEndDate] = useState(convertDateTime(getFormattedDateTime(endOfToday)));


  const [incomeseries, SetIncomeSeries] = useState([
    {
      name: "Income",
      data: [5000, 8000, 10000, 25000, 40000, 50000, 100000],
    },
  ]);
  const [expenseseries, SetExpenseSeries] = useState([
    {
      name: "Expenses",
      data: [5000, 8000, 10000, 5000, 4000, 50000, 10000],
    },
  ]);
  const [incomeptions, SetIncomeOptions] = useState({
    chart: {
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    title: {
      text: "Income Details",
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yaxis: {
      // max: 100,
      count: 12,
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        gradientToColors: ["#0267ff"],
        // shadeIntensity: 1,
        type: "horizontal",
        // opacityFrom: 1,
        // opacityTo: 1,
        stops: [0, 100, 100, 100],
      },
    },
    stroke: {
      curve: "smooth",
    },

    markers: {
      size: 0,
    },
  });
  const [expenseoptions, SetExpenseOptions] = useState({
    chart: {
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    title: {
      text: "Expense Details",
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yaxis: {
      // max: 100,
      count: 12,
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        gradientToColors: ["#d90318"],
        // shadeIntensity: 1,
        type: "horizontal",
        // opacityFrom: 1,
        // opacityTo: 1,
        stops: [50, 10, 4, 40],
      },
    },
    stroke: {
      curve: "smooth",
    },

    markers: {
      size: 0,
    },
  });
  const OutletContext = useOutletContext();
  const [width, SetWidth] = useState(innerWidth);

  useEffect(() => {
    const today = new Date();

// Get individual date components
const day = String(today.getDate()).padStart(2, '0');
const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
const year = today.getFullYear();

// Format the date as DD-MM-YYYY
// const formattedDate = `${day}-${month}-${year}`;
getSuperappStatistics(startDate,endDate)
get_order_price_statistics(startDate,endDate,"")
get_order_statistics(startDate,endDate,"")


get_sector()


  }, []);

  function getFormattedDateTime(date) {
    
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-11
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }
  async function getSuperappStatistics( start_date,end_date) {
    // if (!get_data.load_more) {
    //   SetVisibleContent(false);
    // }
    SetSuperappIsLoading(true);
let start = start_date;
let end = end_date
  
    let pass_data = {
      post: {
        total_users: 1,
        total_user_signup:1,
        total_user_signup_from : start,
        total_user_signup_to : end,
        total_app_user_signup :1,
        total_app_user_signup_from :start,
        total_app_user_signup_to : end,
        total_app_users:1,
        total_app_users_from:start,
        total_app_users_to:end,

        total_loggedin_push_app_users:1,
        total_loggedin_push_app_users_from:start,
        total_loggedin_push_app_users_to:end,
        total_guset_push_app_users:1,
        total_guset_push_app_users_from : start,
        total_guset_push_app_users_to : end
      },
    };
    let response: any = await api("/dashboard/superapp_statistics", pass_data);
    if (response.status_code == 200) {
      
console.log(response)
      // SetVisibleContent(true);

      if (response.response.hasOwnProperty("data")) {
       setData(response.response.data)
      }
      SetSuperappIsLoading(false);
    }
  }
  const OwnAccordian = (acc_props: any) => {
    const [is_open, SetIsopen] = useState(false);
    return (
      <>
        {acc_props.from == "shipment" ? (
          <>
            <div
              className="d-flex pt-3"
              onClick={() => {
                SetIsopen(!is_open);
              }}
            >
              <h6 className="text-dark ">{acc_props.heading}</h6>

              {is_open ? (
                <MaterialIcons
                  name="keyboard-arrow-down"
                  size={24}
                  color="black"
                />
              ) : (
                <MaterialIcons
                  name="keyboard-arrow-up"
                  size={24}
                  color="black"
                />
              )}
            </div>
            <div className="">{is_open ? acc_props.children : null}</div>
          </>
        ) : (
          <>
            <div
              className="d-flex pt-3"
              onClick={() => {
                SetIsopen(!is_open);
              }}
            >
              <h6 className="text-dark ">{acc_props.heading}</h6>
              <div className="ms-auto">
                {is_open ? (
                  <MaterialIcons
                    name="keyboard-arrow-down"
                    size={24}
                    color="black"
                  />
                ) : (
                  <MaterialIcons
                    name="keyboard-arrow-up"
                    size={24}
                    color="black"
                  />
                )}
              </div>
            </div>
            <div className="">{is_open ? acc_props.children : null}</div>
          </>
        )}{" "}
      </>
    );
  };

  async function get_order_statistics(start_date,end_date,id) {
    // if (!get_data.load_more) {
    //   SetVisibleContent(false);
    // }
    // SetOrderIsLoading(true)
    setActiveOrTab(id)

let start = startDate
let end = endDate
   
    let pass_data = {
      post: {
        data: [
          "all",
          "placed",
          "cancelled",
          "customer_cancelled",
          "seller_cancelled",
          "admin_cancelled",
          "auto_cancelled",
          "seller_accepted",
          "seller_rejected",
          "order_completed",
          "order_failed",
          "settlement_approved",
          "settlement_not_approved",
          "settlement_paid",
          "settlement_not_paid",
          "settlement_approved_not_paid"
      ],
      from:start,
      to: end,
      sector_id:id
      },
    };
    let response: any = await api("/dashboard/order_statistics", pass_data);
    if (response.status_code == 200) {
      
console.log(response)
      // SetVisibleContent(true);

      if (response.response.hasOwnProperty("data")) {
        setOrderData(response.response.data)


      }
      SetOrderIsLoading(false)
    }
  }

  async function get_sector() {
    // if (!get_data.load_more) {
    //   SetVisibleContent(false);
    // }
//     SetOrderIsLoading(true)
// let start = startDate
// let end = endDate
   
    let pass_data = {
      get: {
      
      },
    };
    let response: any = await api("/dashboard/sectors", pass_data);
    if (response.status_code == 200) {
      
console.log(response)
      // SetVisibleContent(true);

      if (response.response.hasOwnProperty("data")) {
        setTabs(response.response.data);
        setOrTabs(response.response.data);



      }
      SetOrderIsLoading(false)
    }
  }

  async function get_order_price_statistics( start_date,end_date,id) {
    SetOrderPriceIsLoading(true)
    setActiveTab(id)
    // if (!get_data.load_more) {
    //   SetVisibleContent(false);
    // }
    // SetOrderIsLoading(true)
let start = startDate
let end = endDate
    
    let pass_data = {
      post: {
        data: [
          "all",
    "order_placed",
    "cancelled",
    "customer_cancelled",
    "seller_cancelled",
    "admin_cancelled",
    "auto_cancelled",
    "seller_accepted",
    "seller_rejected",
    "order_completed",
    "order_failed",
    "settlement_approved",
    "settlement_not_approved",
    "settlement_paid",
    "settlement_not_paid",
    "settlement_approved_not_paid"
      ],
      from:start,
      to: end,
      sector_id:id
      },
    };
    let response: any = await api("/dashboard/order_price_statistics", pass_data);
    if (response.status_code == 200) {
      
console.log(response)
      // SetVisibleContent(true);

      if (response.response.hasOwnProperty("data")) {
        setOrderPriceData(response.response.data)


      }
      SetOrderPriceIsLoading(false)

    }
  }

  function convertDateTime(dateTimeStr) {
    const date = new Date(dateTimeStr);
    
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-11
    const year = date.getFullYear();
    
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = '00'; // Set seconds to 00
    
    const formattedDateTime = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    return formattedDateTime;
  }
  const handleStartChange = (e) => {
  //  let start:any = moment(date).format('DD-MM-YYYY HH:mm:ss')
  console.log(e.target.value)
  const convertedDateTime = convertDateTime(e.target.value);
    console.log(convertedDateTime);
    setStartDateShow(e.target.value)
   setStartDate(convertedDateTime);
  //  getSuperappStatistics(start,endDate)
  //  get_order_statistics(start,endDate)
  //  get_order_price_statistics(start,endDate)
  
  };

  const handleEndChange = (e) => {
    const convertedDateTime = convertDateTime(e.target.value);
    setEndDateShow(e.target.value)
  
  
      setEndDate(convertedDateTime);
      
    // getSuperappStatistics(startDate,end)
    // get_order_statistics(startDate,end)
    // get_order_price_statistics(startDate,end)
 
   
   
   };

    const handleTabClick = (tab) => {
    setActiveTab(tab.id);
    get_order_price_statistics(startDate,endDate,tab.id)
    // Example of the data to be passed, adjust as necessary
    // let passData = {
    //   order_status: tab.order_status,
    //   seller_brand_id: "",
    //   seller_outlet_id: "",
    //   sort: "",
    //   page_no: 1,
    // };

    // Call your get_orders function with passData and filterData
    // get_orders(passData, filterData);
  };

  const handleOrTabClick = (tab) => {
    setActiveOrTab(tab.id);
   
    get_order_statistics(startDate,endDate,tab.id)
    // Example of the data to be passed, adjust as necessary
    // let passData = {
    //   order_status: tab.order_status,
    //   seller_brand_id: "",
    //   seller_outlet_id: "",
    //   sort: "",
    //   page_no: 1,
    // };

    // Call your get_orders function with passData and filterData
    // get_orders(passData, filterData);
  };


  const dateFormat = 'DD-MM-YYYY';
  const timeFormat = 'HH:mm';

  const handleApply = () => {

    const startTimestamp = new Date(startDateShow).getTime();
    const endTimestamp = new Date(endDateShow).getTime();

    console.log(startTimestamp)
    console.log(endTimestamp)

    
    console.log()
    if (startTimestamp < endTimestamp) {
        getSuperappStatistics(startDate,endDate)
    get_order_statistics(startDate,endDate,activeOrTab)
    get_order_price_statistics(startDate,endDate,activeTab)
        // Add your logic for applying the selected dates
    } else {
      toast.error("End date cannot be earlier than start date.");
    }
};
  return (
    <div>
        <Toaster />
    <div className="head-bar d-flex-lg">
        {/* style={OutletContext == false ? { width: width } : {}} */}
        <div >
          <h5 className="mb-1">Dashboard</h5>
        </div>
        <div className="ms-auto">  
<div className="d-flex-lg">
        <div className="me-2">
          {console.log(startDate)}
        <input type="datetime-local"
          onChange={handleStartChange}
        id="startDateTime"
        className="form-control"
        value={startDateShow}
        name="startDateTime" required/>

                {/* <Datetime 
                    id="start"
                    // value={startDate}
                    onChange={handleStartChange}
                    dateFormat={dateFormat}
                    timeFormat={timeFormat}
                    inputProps={{ placeholder: 'Select start date and time' }}
                /> */}
            </div>
          
            <div>
            <input type="datetime-local"
          onChange={handleStartChange}
        id="endDateTime"
        className="form-control"
        value={endDateShow}
        onChange={handleEndChange}
        name="endDateTime" required/>
{/*         
                <Datetime 
                    id="end"
                    value={endDateShow}
                    onChange={handleEndChange}
                    dateFormat={dateFormat}
                    timeFormat={timeFormat}
                    inputProps={{ placeholder: 'Select end date and time' }}
                /> */}
            </div>
            <button className="btn btn-primary btn-sm ms-2 h-10" 
            onClick={handleApply}
            >Apply</button>
            </div>
        </div>
      </div>
   
      <div className="px-2 sm-mt-3 sm-pt-4">
    {/* <div className="card p-3">
      Dashboard content
    </div> */}
        </div>
      
        <div className="container mt-4">
        <h4>Superapp Statistics</h4>
        {superapp_is_loading?
         <div className="row">
            {[1, 2, 3, 4, 5, 6].map(() => (
          <div className="col-lg-3">
          <div className="card p-3 cursor my-2">
            <div>
              <Skeleton height={20} width={100} />
            </div>
            <div>
              <Skeleton height={20} width={150} />
            </div>
          </div>
          </div>
            ))}
        </div>:
        <div className="row w-lg-50">
          
          <div className = "col-lg-12">
          <div className="card p-3 cursor my-2">

      <div>{data?.total_users}</div>
      <div>Total Users</div>

                        </div>
          </div>
          <div className = "col-lg-12">
          <div className="card p-3 cursor my-2">

      <div>{data?.total_user_signup}</div>
      <div>User Signup</div>

                        </div>
          </div>
       
        {/* <div className="col-lg-12">
             <div className="card-lg">
             <div className="container-lg">
              
             <div className="row-lg"> */}

             
          <div className = "col-lg-4">
          <div className="card p-3 cursor my-2">

      <div>{data?.total_app_user_signup_android}</div>
      <div>Android User Signup</div>

                        </div>
          </div>

          <div className = "col-lg-4">
          <div className="card p-3 cursor my-2">
      <div>{data?.total_app_user_signup_ios}</div>
      <div>IOS User Signup</div>

                        </div>
          </div>
          <div className = "col-lg-4">
          <div className="card p-3 cursor my-2">

      <div>{data?.total_app_user_signup_web}</div>
      <div>Web User Signup</div>

                        </div>
          </div>
          {/* </div>
          </div>

          </div>


          </div> */}

          <div className = "col-lg-12">
          <div className="card p-3 cursor my-2">

      <div>{data?.total_app_users}</div>
      <div>App Users</div>

                        </div>
          </div>
          <div className = "col-lg-6">
          <div className="card p-3 cursor my-2">

      <div>{data?.total_app_users_android}</div>
      <div>Android App Users</div>

                        </div>
          </div>
          <div className = "col-lg-6">
          <div className="card p-3 cursor my-2">

      <div>{data?.total_app_users_ios}</div>
      <div>Ios App Users</div>

                        </div>
          </div>
          <div className = "col-lg-6">
          <div className="card p-3 cursor my-2">

      <div>{data?.total_loggedin_push_app_users}</div>
      <div>Loggedin Push App Users</div>

                        </div>
          </div>
          <div className = "col-lg-6">
          <div className="card p-3 cursor my-2">

      <div>{data?.total_guset_push_app_users}</div>
      <div>Guset Push App Users</div>

                        </div>
          </div>
        </div>
}
        </div>

        <div className="container mt-4">
        <h4>Order Statistics</h4>
        <div className="mt-1 d-flex align-items-center bg-fff p-2 md-px-3 mb-2">
        <button
         
            type="button"
            className={
              activeOrTab === ""
                ? "btn btn-text header-tap me-1 px-3"
                : "btn btn-text text-black me-1 px-3"
            }
            onClick={() => get_order_statistics(startDate,endDate,"")}
          >
            All
          </button>
          {or_tabs.map(tab => (
          <button
            key={tab?.id}
            type="button"
            className={
              activeOrTab === tab.id
                ? "btn btn-text header-tap me-1 px-3"
                : "btn btn-text text-black me-1 px-3"
            }
            onClick={() => handleOrTabClick(tab)}
          >
            {tab.name}
          </button>
        ))}
        </div>
    
        {order_is_loading?
         <div className="row">
            {[1, 2, 3, 4, 5, 6].map(() => (
          <div className="col-lg-3">
          <div className="card p-3 cursor my-2">
            <div>
              <Skeleton height={20} width={100} />
            </div>
            <div>
              <Skeleton height={20} width={150} />
            </div>
          </div>
          </div>
            ))}
        </div>:
        <div className="row">
          <div className = "col-lg-3">
          <div className="card p-3 cursor my-2">

      <div>{order_data?.all}</div>
      <div>All Orders</div>

                        </div>
          </div>
          <div className = "col-lg-3">
          <div className="card p-3 cursor my-2">

      <div>{order_data?.placed}</div>
      <div>Placed Orders</div>

                        </div>
          </div> <div className = "col-lg-3">
          <div className="card p-3 cursor my-2">

      <div>{order_data?.cancelled}</div>
      <div>cancelled Orders</div>

                        </div>
          </div> <div className = "col-lg-3">
          <div className="card p-3 cursor my-2">

      <div>{order_data?.customer_cancelled}</div>
      <div>Customer Cancelled Orders</div>

                        </div>
          </div> <div className = "col-lg-3">
          <div className="card p-3 cursor my-2">

      <div>{order_data?.seller_cancelled}</div>
      <div>Seller Cancelled Orders</div>

                        </div>
          </div> <div className = "col-lg-3">
          <div className="card p-3 cursor my-2">

      <div>{order_data?.admin_cancelled}</div>
      <div>Admin Cancelled Orders</div>

                        </div>
          </div> <div className = "col-lg-3">
          <div className="card p-3 cursor my-2">

      <div>{order_data?.auto_cancelled}</div>
      <div>Auto Cancelled Orders</div>

                        </div>
          </div> <div className = "col-lg-3">
          <div className="card p-3 cursor my-2">

      <div>{order_data?.seller_accepted}</div>
      <div>Seller Accepted Orders</div>

                        </div>
          </div> <div className = "col-lg-3">
          <div className="card p-3 cursor my-2">

      <div>{order_data?.seller_rejected}</div>
      <div>Seller Rejected Orders</div>

                        </div>
          </div> <div className = "col-lg-3">
          <div className="card p-3 cursor my-2">

      <div>{order_data?.order_completed}</div>
      <div>Order Completed Orders</div>

                        </div>
          </div> <div className = "col-lg-3">
          <div className="card p-3 cursor my-2">

      <div>{order_data?.order_failed}</div>
      <div>Order Failed Orders</div>

                        </div>
          </div> <div className = "col-lg-3">
          <div className="card p-3 cursor my-2">

      <div>{order_data?.settlement_approved}</div>
      <div>Settlement Approved Orders</div>

                        </div>
          </div>
          <div className = "col-lg-3">
          <div className="card p-3 cursor my-2">

      <div>{order_data?.settlement_not_approved}</div>
      <div>Settlement Not Approved</div>

                        </div>
          </div>
          <div className = "col-lg-3">
          <div className="card p-3 cursor my-2">

      <div>{order_data?.settlement_paid}</div>
      <div>Settlement Paid Orders</div>

                        </div>
          </div>
          <div className = "col-lg-3">
          <div className="card p-3 cursor my-2">

      <div>{order_data?.settlement_not_paid}</div>
      <div>Settlement Not Paid Orders</div>

                        </div>
          </div>
          <div className = "col-lg-3">
          <div className="card p-3 cursor my-2">

      <div>{order_data?.settlement_approved_not_paid}</div>
      <div>Settlement Approved Not Paid Orders</div>

                        </div>
          </div>
          
          </div>}
          </div>

          <div className="container mt-4">
        <h4>Order Price Statistics</h4>
   
        <div className="mt-1 d-flex align-items-center bg-fff p-2 md-px-3 mb-2">
        <button
         
         type="button"
         className={
           activeTab === ""
             ? "btn btn-text header-tap me-1 px-3"
             : "btn btn-text text-black me-1 px-3"
         }
         onClick={() => get_order_price_statistics(startDate,endDate,"")}
        

       >
         All
       </button>
        {tabs.map(tab => (
        <button
          key={tab?.id}
          type="button"
          className={
            activeTab === tab.id
              ? "btn btn-text header-tap me-1 px-3"
              : "btn btn-text text-black me-1 px-3"
          }
          onClick={() => handleTabClick(tab)}
        >
          {tab.name}
        </button>
      ))}
      </div>
        {order_price_is_loading?
         <div className="row">
            {[1, 2].map(() => (
          <div className="col-lg-6">
          <div className="card p-3 cursor my-2">
            <div>
              <Skeleton height={20} width={100} />
            </div>
      
          </div>
          </div>
            ))}
        </div>:
        
         <div className="row">
          <div className="col-lg-6">
          <div className="card px-3 pb-1 mb-2">
                                <OwnAccordian
                                  heading={
                   <div>Order Placed</div>
                                  }
                                >
                                  {/* <OwnAccordian from={"shipment"} heading=    {   */}
                                  <>
                                  <div className="card p-3 cursor my-2">

      <div>{order_price_data?.order_placed__gross_total_amount}</div>
      <div>Gross Total Amount</div>

                        </div>
                        <div className="card p-3 cursor my-2">

<div>{order_price_data?.order_placed__product_amount}</div>
<div>Product Amount</div>

                  </div>
                  <div className="card p-3 cursor my-2">

<div>{order_price_data?.order_placed__gst_amount}</div>
<div>GST Amount</div>

                  </div>
                  <div className="card p-3 cursor my-2">

<div>{order_price_data?.order_placed__product_total_amount_with_gst}</div>
<div>Product Total Amount With GST</div>

                  </div>
                  <div className="card p-3 cursor my-2">

<div>{order_price_data?.order_placed__delivery_charges}</div>
<div>Delivery Charges</div>

                  </div>
                  <div className="card p-3 cursor my-2">

<div>{order_price_data?.order_placed__convenience_fee}</div>
<div>Convenience Fee</div>

                  </div>
                                  
                                  </>
                                </OwnAccordian>
                                </div>
                                </div>
                                <div className="col-lg-6">
          <div className="card px-3 pb-1 ">
                                <OwnAccordian
                                  heading={
                   <div>Cancelled</div>
                                  }
                                >
                                  {/* <OwnAccordian from={"shipment"} heading=    {   */}
                                  <>
                                  <div className="card p-3 cursor my-2">

      <div>{order_price_data?.cancelled__gross_total_amount}</div>
      <div>Gross Total Amount</div>

                        </div>
                        <div className="card p-3 cursor my-2">

<div>{order_price_data?.cancelled__product_amount}</div>
<div>Product Amount</div>

                  </div>
                  <div className="card p-3 cursor my-2">

<div>{order_price_data?.cancelled__gst_amount}</div>
<div>GST Amount</div>

                  </div>
                  <div className="card p-3 cursor my-2">

<div>{order_price_data?.cancelled__product_total_amount_with_gst}</div>
<div>Product Total Amount With GST</div>

                  </div>
                  <div className="card p-3 cursor my-2">

<div>{order_price_data?.cancelled__delivery_charges}</div>
<div>Delivery Charges</div>

                  </div>
                  <div className="card p-3 cursor my-2">

<div>{order_price_data?.cancelled__convenience_fee}</div>
<div>Convenience Fee</div>

                  </div>
                                  
                                  </>
                                </OwnAccordian>
                                </div>
                                </div>
                                <div className="col-lg-6">
          <div className="card px-3 pb-1 my-2">
                                <OwnAccordian
                                  heading={
                   <div>Order Completed</div>
                                  }
                                >
                                  {/* <OwnAccordian from={"shipment"} heading=    {   */}
                                  <>
                                  <div className="card p-3 cursor my-2">

      <div>{order_price_data?.order_completed__gross_total_amount}</div>
      <div>Gross Total Amount</div>

                        </div>
                        <div className="card p-3 cursor my-2">

<div>{order_price_data?.order_completed__product_amount}</div>
<div>Product Amount</div>

                  </div>
                  <div className="card p-3 cursor my-2">

<div>{order_price_data?.order_completed__gst_amount}</div>
<div>GST Amount</div>

                  </div>
                  <div className="card p-3 cursor my-2">

<div>{order_price_data?.order_completed__product_total_amount_with_gst}</div>
<div>Product Total Amount With GST</div>

                  </div>
                  <div className="card p-3 cursor my-2">

<div>{order_price_data?.order_completed__delivery_charges}</div>
<div>Delivery Charges</div>

                  </div>
                  <div className="card p-3 cursor my-2">

<div>{order_price_data?.order_completed__convenience_fee}</div>
<div>Convenience Fee</div>

                  </div>
                                  
                                  </>
                                </OwnAccordian>
                                </div>
                                </div>
                                <div className="col-lg-6">
          <div className="card px-3 pb-1 my-2">
                                <OwnAccordian
                                  heading={
                   <div>Seller Rejected</div>
                                  }
                                >
                                  {/* <OwnAccordian from={"shipment"} heading=    {   */}
                                  <>
                                  <div className="card p-3 cursor my-2">

      <div>{order_price_data?.seller_rejected__gross_total_amount}</div>
      <div>Gross Total Amount</div>

                        </div>
                        <div className="card p-3 cursor my-2">

<div>{order_price_data?.seller_rejected__product_amount}</div>
<div>Product Amount</div>

                  </div>
                  <div className="card p-3 cursor my-2">

<div>{order_price_data?.seller_rejected__gst_amount}</div>
<div>GST Amount</div>

                  </div>
                  <div className="card p-3 cursor my-2">

<div>{order_price_data?.seller_rejected__product_total_amount_with_gst}</div>
<div>Product Total Amount With GST</div>

                  </div>
                  <div className="card p-3 cursor my-2">

<div>{order_price_data?.seller_rejected__delivery_charges}</div>
<div>Delivery Charges</div>

                  </div>
                  <div className="card p-3 cursor my-2">

<div>{order_price_data?.seller_rejected__convenience_fee}</div>
<div>Convenience Fee</div>

                  </div>
                                  
                                  </>
                                </OwnAccordian>
                                </div>
                                </div>
                                

                              </div>
                              

          } 
          </div>

          <div className="container mt-4">
        <h4>Settlement Paid</h4>
        {order_price_is_loading?
         <div className="row">
            {[1, 2, 3, 4, 5, 6].map(() => (
          <div className="col-lg-3">
          <div className="card p-3 cursor my-2">
            <div>
              <Skeleton height={20} width={100} />
            </div>
            <div>
              <Skeleton height={20} width={150} />
            </div>
          </div>
          </div>
            ))}
        </div>:
        <div className="row">
          <div className = "col-lg-3">
          <div className="card p-3 cursor my-2">

      <div>{order_price_data?.settlement_paid__gross_total_amount}</div>
      <div>Gross Total Amount</div>

                        </div>
          </div>
          <div className = "col-lg-3">
          <div className="card p-3 cursor my-2">

      <div>{order_price_data?.settlement_paid__product_amount}</div>
      <div>Product Amount</div>

                        </div>
          </div>
          <div className = "col-lg-3">
          <div className="card p-3 cursor my-2">

      <div>{order_price_data?.settlement_paid__gst_amount}</div>
      <div>GST Amount</div>

                        </div>
          </div>
          <div className = "col-lg-3">
          <div className="card p-3 cursor my-2">

      <div>{order_price_data?.settlement_paid__product_total_amount_with_gst}</div>
      <div>Product Total Amount With GST</div>

                        </div>
          </div>
          <div className = "col-lg-3">
          <div className="card p-3 cursor my-2">

      <div>{order_price_data?.settlement_paid__delivery_charges}</div>
      <div>Delivery Charges</div>

                        </div>
          </div>
          <div className = "col-lg-3">
          <div className="card p-3 cursor my-2">

      <div>{order_price_data?.settlement_paid__convenience_fee}</div>
      <div>Convenience Fee</div>

                        </div>
          </div>
        </div>
} 
        </div>

        <div className="container mt-4">
        <h4>Settlement Not Paid</h4>
        {order_price_is_loading?
         <div className="row">
            {[1, 2, 3, 4, 5, 6].map(() => (
          <div className="col-lg-3">
          <div className="card p-3 cursor my-2">
            <div>
              <Skeleton height={20} width={100} />
            </div>
            <div>
              <Skeleton height={20} width={150} />
            </div>
          </div>
          </div>
            ))}
        </div>:
        <div className="row">
          <div className = "col-lg-3">
          <div className="card p-3 cursor my-2">

      <div>{order_price_data?.settlement_not_paid__gross_total_amount}</div>
      <div>Gross Total Amount</div>

                        </div>
          </div>
          <div className = "col-lg-3">
          <div className="card p-3 cursor my-2">

      <div>{order_price_data?.settlement_not_paid__product_amount}</div>
      <div>Product Amount</div>

                        </div>
          </div>
          <div className = "col-lg-3">
          <div className="card p-3 cursor my-2">

      <div>{order_price_data?.settlement_not_paid__gst_amount}</div>
      <div>GST Amount</div>

                        </div>
          </div>
          <div className = "col-lg-3">
          <div className="card p-3 cursor my-2">

      <div>{order_price_data?.settlement_not_paid__product_total_amount_with_gst}</div>
      <div>Product Total Amount With GST</div>

                        </div>
          </div>
          <div className = "col-lg-3">
          <div className="card p-3 cursor my-2">

      <div>{order_price_data?.settlement_not_paid__delivery_charges}</div>
      <div>Delivery Charges</div>

                        </div>
          </div>
          <div className = "col-lg-3">
          <div className="card p-3 cursor my-2">

      <div>{order_price_data?.settlement_not_paid__convenience_fee}</div>
      <div>Convenience Fee</div>

                        </div>
          </div>
        </div>
} 
        </div>

      

      {/* <div className="container-fluid py-3">
        <div className="row">
          <div className="col-md-6">
            <div className="card p-3">
              <div id="linechart">
                <Chart
                  options={incomeptions}
                  series={incomeseries}
                  type="line"
                  curve="smooth"
                  height={350}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card p-3">
              <div id="linechart">
                <Chart
                  options={expenseoptions}
                  series={expenseseries}
                  type="line"
                  curve="smooth"
                  height={350}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row p-2">
          <div className="pt-4 col-md-5">
            <h5 className="  mb-0">
              <b>Recent Orders</b>
            </h5>
            <small className="text-gray">Project reports</small>
            <div className="card mt-3 p-3">
              <div className="row align-items-center">
                <div className="p-2 col-2 col-md-1 col-lg-2">
             
                </div>
                <div className="col-8 col-md-9 col-lg-8">
                  <h6 className="mb-0">videos</h6>
                  <small className="text-dark">3 files</small>
                </div>
                <div className="col-2 text-end">
                  <i
                    className="fas fa-ellipsis-v cursor"
                    style={{ color: "gray" }}
                  ></i>
                </div>
              </div>
            </div>

            <div className="card mt-3 p-3">
              <div className="row align-items-center">
                <div className="p-2 col-2 col-md-1 col-lg-2">
              
                </div>
                <div className="col-8 col-md-9 col-lg-8">
                  <h6 className="mb-0">Images</h6>
                  <small className="text-dark">150 files</small>
                </div>
                <div className="col-2 text-end">
                  <i
                    className="fas fa-ellipsis-v cursor"
                    style={{ color: "gray" }}
                  ></i>
                </div>
              </div>
            </div>

            <div className="card mt-3 p-3">
              <div className="row align-items-center">
                <div className="p-2 col-2 col-md-1 col-lg-2">
             
                </div>
                <div className="col-8 col-md-9 col-lg-8">
                  <h6 className="mb-0">audio</h6>
                  <small className="text-dark">3 files</small>
                </div>
                <div className="col-2 text-end">
                  <i
                    className="fas fa-ellipsis-v cursor"
                    style={{ color: "gray" }}
                  ></i>
                </div>
              </div>
            </div>
          </div>
          <div className="pt-4 col-md-7">
            <div className="card mt-3 p-3">
              <div className="d-flex align-items-end border-bottom pb-2 px-2 mb-2">
                <div>
                  <h5 className="  mb-0">
                    <b>Outlets</b>
                  </h5>
                  <small className="text-gray">Outlet reports</small>
                </div>
                <div className="text-end ms-auto cursor">
                  <small>View more</small>
                </div>
              </div>
              <div className="mt-2 mx-3">
                <h6>Outlet Name</h6>
                <p className="v-small">Album</p>
              </div>
              <div className="mt-2 mx-3">
                <h6>Outlet Name</h6>
                <p className="v-small">Album</p>
              </div>
              <div className="mt-2 mx-3">
                <h6>Outlet Name</h6>
                <p className="v-small">Album</p>
              </div>
              <div className="mt-2 mx-3">
                <h6>Outlet Name</h6>
                <p className="v-small">Album</p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}
