import React, { useState, useEffect, useRef } from "react";
import bootstrap from "../../assets/libraries/bootstrap/js/bootstrap";
import { api } from "../../utils/Api";

export default function ModalReject(props: any) {
  const [closeData, setCloseData] = useState({ action: "close" });
  const [reason, setReason] = useState("");
  const myModalRef = useRef<any>(null);

  useEffect(() => {
    const myModalElement = document.getElementById("reqModal");
    myModalElement?.addEventListener("hidden.bs.modal", function (event) {
      props.close(closeData);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      myModalRef.current = new bootstrap.Modal(document.getElementById("reqModal"), {});
      myModalRef.current.show();
    }
  }, [props.open]);

  async function deleteUserRole() {
    const passData = {
      post: {
        id: props.data.id,
        status: "2",
        reason: reason,
      },
    };
    const dataRes = await api("/sellers/outlet_request_status", passData);
    if (dataRes.status_code === 200) {
      const item = closeData;
      item.action = "save";
      item.value = dataRes.response.data;
      setCloseData(item);

      if (myModalRef.current) {
        myModalRef.current.hide();
      }
    }
  }

  return (
    <div
      className="modal fade"
      id="reqModal"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="ModalLabel">
              Reject Changes
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <p>Are you sure you want to reject changes?</p>
            <div className="mb-3">
              <label htmlFor="reasonInput" className="form-label">
                Reason for Rejection
              </label>
              <input
                type="text"
                className="form-control"
                id="reasonInput"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger"
              onClick={deleteUserRole}
            >
              Reject
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}