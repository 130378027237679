import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { api } from "../../utils/Api";
import DataContext from "../../utils/Context/DataContext";
import {
  Ionicons,
  MaterialCommunityIcons,
  FontAwesome,
} from "@expo/vector-icons";

import InfiniteScroll from "react-infinite-scroll-component";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function Payment(props: any) {
  const [next_page, SetNextPage] = useState(false);

  const [page_no, SetPageNo] = useState(1);
  const [transaction_able, SetTransactionAble] = useState(false);
  const [filter_data, setFilterData] = useState("");

  const [selected_details, SetSelectedDetails] = useState();
  // State to store the timeout ID
  const [timeoutId, setTimeoutId] = useState(null);

  const [payments, setPayments] = useState([]);
  const [appliction, SetApplication] = useState([]);
  const [visible_content, SetVisibleContent] = useState(false);

  const [transaction_details, SetTransactionDetails]: any = useState({});

  const [width, SetWidth] = useState(innerWidth);
  const [selectedApplication, setSelectedApplication] = useState("");
  const [show_details_loader, SetShowDetailsLoader] = useState(true);
  const [payment_loading, SetPaymentLoading] = useState(true);

  const handleApplicationChange = (event: any) => {
    SetPaymentLoading(true);
    setSelectedApplication(event.target.value);
    let pass_data = {
      sort: "",
      page_no: 1,
    };
    SetPageNo(1)
    setPayments([]);
    SetTransactionAble(false);
    get_categories(pass_data, filter_data, event.target.value);
  };

  useEffect(() => {
    let pass_data = {
      sort: "",
      page_no: page_no,
    };
    get_categories(pass_data, filter_data, selectedApplication);
  }, []);

  async function get_categories(get_data: any, search: any, app: any) {
    if (!get_data.load_more) {
      SetPaymentLoading(true);
    }
    let pass_data = {
      get: {
        page: get_data.page_no,
        search: search,
        application: app,
      },
    };

    let response = await api("/payments/list", pass_data);
    if (response.status_code == 200) {
      SetPaymentLoading(false);
      SetApplication(response.response.applications);

      SetVisibleContent(true);

      if (response.response.hasOwnProperty("data")) {
        if (get_data.load_more) {
          setPayments((prevValue) => {
            response?.response?.data?.map((ele: any) => {
              prevValue.push(ele);
            });
            return [...prevValue];
          });
        } else {
          setPayments(response.response.data);
        }
      }

      if (response.response.hasOwnProperty("next_page")) {
        SetNextPage(response.response.next_page);
        SetPageNo((prevalue) => {
          prevalue = prevalue + 1;
          return prevalue;
        });
      }
      SetApplication((prevValue) => {
        response.response.data.map((item: any) => {
          prevValue.push(item);
        });
        return [...prevValue];
      });
      SetNextPage(response.response.next_page);
    }
  }

  function enable(ele: any) {
    SetShowDetailsLoader(true);
    const timer = setTimeout(() => {
      SetShowDetailsLoader(false);
    }, 500);
    SetTransactionAble(true);
    SetTransactionDetails(ele);
    return () => clearTimeout(timer);
  }

  function disable() {
    SetShowDetailsLoader(false);
    SetTransactionAble(false);
  }

  const handleInputChange = (e: any) => {
    const searchValue = e.target.value;
    setFilterData(searchValue);
    // Clear previous timeout
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // Set a new timeout
    const newTimeoutId: any = setTimeout(() => {
      // Call your API with the search value
      let pass_data = {
        sort: "",
        page_no: 1,
      };
      setPayments([]);
      get_categories(pass_data, searchValue, selectedApplication);
    }, 1000); // 5 milliseconds delay

    // Save the timeout ID
    setTimeoutId(newTimeoutId);
  };

  function loadMore() {
    SetPageNo((prevalue: any) => {
      let pass_data = {
        sort: "",
        page_no: prevalue + 1,
      };
      get_categories(pass_data, filter_data, selectedApplication);
      return prevalue + 1;
    });
  }

  const PaymentCard = () => {
    return (
      <div className="col-md-6 sm-mt-6 sm-pb-5">
        {payment_loading ? (
          [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((ele: any) => (
            <div className="card mb-2 p-3">
              <div className="row">
                <div className="col-6 pb-2">
                  <Skeleton height={10} width={80} />
                </div>
                <div className="col-6 text-end pb-2">
                  <Skeleton height={30} width={100} />
                </div>
                <div className="col-6 pb-2">
                  <Skeleton height={10} width={50} />
                </div>
                <div className="col-6 pb-2 text-end">
                  <Skeleton height={10} width={120} />
                </div>
                <div className="col-6 pb-3 d-flex">
                  <div className="pe-2">
                    <Skeleton height={34} width={34} borderRadius={50} />
                  </div>
                  <div className="">
                    <Skeleton height={10} width={100} count={2} />
                  </div>
                </div>
                <div className="col-6 end pb-3">
                  <Skeleton height={10} width={80} />
                </div>
                <div className="px-2 pb-3">
                  <hr className="text-gray m-0" />
                </div>
                <div className="col-3 pb-2">
                  <Skeleton height={10} width={80} />
                  <Skeleton height={10} width={40} />
                </div>

                <div className="col-3 pb-2">
                  <Skeleton height={10} width={80} />
                  <Skeleton height={10} width={40} />
                </div>

                <div className="col-3 pb-2">
                  <Skeleton height={10} width={80} />
                  <Skeleton height={10} width={40} />
                </div>

                <div className="col-3 pb-2">
                  <Skeleton height={10} width={80} />
                  <Skeleton height={10} width={40} />
                </div>
              </div>
            </div>
          ))
        ) : (
          <>
            <InfiniteScroll
              dataLength={payments.length}
              next={() => {
                let send_data = {
                  page_no: page_no,

                  load_more: true,
                };
                get_categories(send_data, filter_data, selectedApplication);
              }}
              hasMore={next_page}
              loader={
                <div className="center m-5">
                  <div className="lds-dual-ring"></div>
                  <p className=" small ps-2">Just a second ...</p>
                </div>
              }
            >
              {payments.length != 0 ? (
                <div>
                  {payments.map((ele, i) => (
                    <div
                      onClick={() => {
                        enable(ele);
                      
                        SetSelectedDetails(i);
                      }}
                    >
                      {/*  key={i} */}
                      <div
                        className={
                          transaction_able == true && selected_details == i
                            ? "active-shadow-layout radius  mb-2 p-3"
                            : "card mb-2 p-3"
                        }
                      >
                        {visible_content ? (
                          <div className="row">
                            <div className="col-6 pb-2">
                              <p className="text-black">
                                Cart Id: #{ele.ref_id}
                              </p>
                            </div>

                            <div className="col-6 end pb-2"> {ele.date}</div>

                            <div className="col-6 pb-2">
                              <p className="text-dark">#{ele.payment_id}</p>
                            </div>
                            <div className="col-6 end pb-2">
                              <FontAwesome
                                name="circle"
                                size={10}
                                color={
                                  ele.status == "Success" ? "green" : "red"
                                }
                              />
                              <p
                                className={
                                  ele.status == "Success"
                                    ? "ps-2 text-green"
                                    : "ps-2 text-red"
                                }
                              >
                                {ele.status}
                              </p>
                            </div>
                            <div className="col-6 pb-3 d-flex">
                              {ele.user.profile ? (
                                <div className="pe-2">
                                  <img
                                    src={ele.user.profile}
                                    alt="16.a1d4f3f3"
                                    className="rounded-float-start"
                                    height="34"
                                    width="34"
                                    style={{ borderRadius: "50px" }}
                                  ></img>
                                </div>
                              ) : (
                                <div className="pe-2">
                                  <img
                                    src={require("../../assets/icon/avatar.png")}
                                    className="rounded-float-start"
                                    height="34"
                                    width="34"
                                  />
                                </div>
                              )}
                              <div className="">
                                <p className="text-dark fw-bold fs-7">
                                  {ele?.user?.name}
                                </p>
                                <p>{ele?.user?.user_name}</p>
                              </div>
                            </div>

                            <div className="col-6 end pb-3">
                              <p className="text-dark fw-bold fs-7">
                                ₹ {ele.amount}
                              </p>
                            </div>

                            <div className="px-2 pb-3">
                              <hr className="text-gray m-0" />
                            </div>

                            <div className="col-3 pb-2">
                              <p className="text-black pb-3">Total Orders</p>
                              <p className="text-dark fw-bold">
                                {ele.total_orders}
                              </p>
                            </div>

                            <div className="col-3 pb-2">
                              <p className="text-black pb-3">Total Refunds</p>
                              <p className="text-dark fw-bold">
                                {ele.total_refunds}
                              </p>
                            </div>

                            <div className="col-3 pb-2">
                              <p className="text-black pb-3">
                                Total Refund amount
                              </p>
                              <p className="text-dark fw-bold">
                                ₹ {ele.total_refunded_amount}
                              </p>
                            </div>
                            <div className="col-3 pb-2">
                              <p className="text-black pb-3">Total balance</p>
                              <p className="text-dark fw-bold">
                                ₹ {ele.total_balance}
                              </p>
                            </div>
                          </div>
                        ) : (
                          [1, 2, 3, 4].map(() => (
                            <div className="col-md-6 p-1">
                              <OrderSkeleton />
                            </div>
                          ))
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="center p-3">
                  <div className="card p-3 w-100">
                    <div className="w-100 text-center">
                      <img
                        src={require("../../assets/img/not-found.png")}
                        className="no-data-img"
                      />
                    </div>
                    <p className="center pt-3">No Payments data found</p>
                  </div>
                </div>
              )}
            </InfiniteScroll>{" "}
          </>
        )}
      </div>
    );
  };

  return (
    <div className="mb-5">
      {/* Header */}
      <div className="head-bar row">
        <div className="col-md-4">
          <h5 className="  mb-2">Payments</h5>
        </div>

        <div className="col-md-6 sm-pt-2 end mb-2">
          {/* search */}
          <div className="input-icons form-group p-2">
            <input
              type="text"
              className="form-control search-bar p-0"
              placeholder="Search here..."
              onChange={handleInputChange}
            />
            <div className="cursor px-2 border-left">
              <Ionicons name="md-search" size={16} color="#ccc" />
            </div>
          </div>
        </div>
        <div className="col-md-2">
          <div>
       
            <select
              className="form-select p-2 border-radius"
              value={selectedApplication}
              onChange={handleApplicationChange}
            >
               <option value="" disabled hidden>Select Application</option>
              {appliction?.map((ele, i) => (
                <option key={i} value={ele?.id}>
                  {ele?.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="mt-3 px-md-3 px-sm-0 mb-5">
        <div className="row">
          {width < 768 && transaction_able == false ? (
            <PaymentCard />
          ) : width > 768 ? (
            <PaymentCard />
          ) : null}

          {transaction_able ? (
            <div className="col-md-6  sm-pb-5 sm-mt-6">
              <div className="overflowY-90vh pb-5 position-sticky-top-50px">
                {show_details_loader ? (
                  <div className="card bg-fff p-3 mb-3">
                    <div className="mb-4">
                      <Skeleton height={10} width={120} />
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-4 pb-3">
                        <Skeleton height={10} width={80} />
                        <div className="pt-2 d-flex">
                          <div className="pe-2">
                            <Skeleton
                              height={34}
                              width={34}
                              borderRadius={50}
                            />
                          </div>
                          <div className="">
                            <Skeleton height={10} width={100} count={2} />
                          </div>
                        </div>
                      </div>
                      <div className="col-6 col-md-4 pb-3">
                        <div className="pb-2">
                          <Skeleton height={10} width={80} />
                        </div>
                        <Skeleton height={10} width={30} />
                      </div>
                      <div className="col-6 col-md-4 pb-3">
                        <div className="pb-2">
                          <Skeleton height={10} width={80} />
                        </div>
                        <Skeleton height={10} width={30} />
                      </div>
                      <div className="col-6 col-md-4 pb-3">
                        <div className="pb-2">
                          <Skeleton height={10} width={80} />
                        </div>
                        <Skeleton height={10} width={30} />
                      </div>
                      <div className="col-6 col-md-4 pb-3">
                        <div className="pb-2">
                          <Skeleton height={10} width={80} />
                        </div>
                        <Skeleton height={10} width={30} />
                      </div>
                      <div className="col-6 col-md-4 pb-3">
                        <div className="pb-2">
                          <Skeleton height={10} width={80} />
                        </div>
                        <Skeleton height={10} width={30} />
                      </div>
                      <div className="col-6 col-md-4 pb-3">
                        <div className="pb-2">
                          <Skeleton height={10} width={80} />
                        </div>
                        <Skeleton height={10} width={30} />
                      </div>
                      <div className="col-6 col-md-4 pb-3">
                        <div className="pb-2">
                          <Skeleton height={10} width={80} />
                        </div>
                        <Skeleton height={10} width={30} />
                      </div>
                      <div className="col-6 col-md-4 pb-3">
                        <div className="pb-2">
                          <Skeleton height={10} width={80} />
                        </div>
                        <Skeleton height={10} width={30} />
                      </div>
                      <div className="col-6 col-md-4 pb-3">
                        <div className="pb-2">
                          <Skeleton height={10} width={80} />
                        </div>
                        <Skeleton height={10} width={30} />
                      </div>
                      <div className="col-6 col-md-4 pb-3">
                        <div className="pb-2">
                          <Skeleton height={10} width={80} />
                        </div>
                        <Skeleton height={10} width={30} />
                      </div>
                      <div className="col-6 col-md-4 pb-3">
                        <div className="pb-2">
                          <Skeleton height={10} width={80} />
                        </div>
                        <Skeleton height={10} width={30} />
                      </div>
                      <div className="col-6 col-md-4 pb-3">
                        <div className="pb-2">
                          <Skeleton height={10} width={80} />
                        </div>
                        <Skeleton height={10} width={30} />
                      </div>
                    </div>
                    <div className="border-top pt-3 mt-2">
                      <div className="mb-4">
                        <Skeleton height={10} width={120} />
                      </div>
                      <div className="row">
                        <div className="col-6 col-md-4 pb-3">
                          <div className="pb-2">
                            <Skeleton height={10} width={80} />
                          </div>
                          <Skeleton height={10} width={30} />
                        </div>
                        <div className="col-6 col-md-4 pb-3">
                          <div className="pb-2">
                            <Skeleton height={10} width={80} />
                          </div>
                          <Skeleton height={10} width={30} />
                        </div>
                        <div className="col-6 col-md-4 pb-3">
                          <div className="pb-2">
                            <Skeleton height={10} width={80} />
                          </div>
                          <Skeleton height={10} width={30} />
                        </div>
                        <div className="col-6 col-md-4 pb-3">
                          <div className="pb-2">
                            <Skeleton height={10} width={80} />
                          </div>
                          <Skeleton height={10} width={30} />
                        </div>
                        <div className="col-6 col-md-4 pb-3">
                          <div className="pb-2">
                            <Skeleton height={10} width={80} />
                          </div>
                          <Skeleton height={10} width={30} />
                        </div>
                        <div className="col-6 col-md-4 pb-3">
                          <div className="pb-2">
                            <Skeleton height={10} width={80} />
                          </div>
                          <Skeleton height={10} width={30} />
                        </div>
                        <div className="col-12 pb-3">
                          <div className="pb-2">
                            <Skeleton height={10} width={80} />
                          </div>
                          <Skeleton height={10} width="100%" count={2} />
                        </div>

                        <div className="col-6 col-md-4 pb-3">
                          <div className="pb-2">
                            <Skeleton height={10} width={80} />
                          </div>
                          <Skeleton height={10} width={30} />
                        </div>
                        <div className="col-6 col-md-4 pb-3">
                          <div className="pb-2">
                            <Skeleton height={10} width={80} />
                          </div>
                          <Skeleton height={10} width={30} />
                        </div>
                        <div className="col-6 col-md-4 pb-3">
                          <div className="pb-2">
                            <Skeleton height={10} width={80} />
                          </div>
                          <Skeleton height={10} width={30} />
                        </div>
                      </div>
                    </div>
                    <div className="border-top pt-3 mt-2">
                      <div className="mb-4">
                        <Skeleton height={10} width={120} />
                      </div>
                      <div className="row">
                        <div className="col-6 col-md-4 pb-3">
                          <div className="pb-2">
                            <Skeleton height={10} width={80} />
                          </div>
                          <Skeleton height={10} width={30} />
                        </div>
                        <div className="col-6 col-md-4 pb-3">
                          <div className="pb-2">
                            <Skeleton height={10} width={80} />
                          </div>
                          <Skeleton height={10} width={30} />
                        </div>
                        <div className="col-6 col-md-4 pb-3">
                          <div className="pb-2">
                            <Skeleton height={10} width={80} />
                          </div>
                          <Skeleton height={10} width={30} />
                        </div>
                        <div className="col-6 col-md-4 pb-3">
                          <div className="pb-2">
                            <Skeleton height={10} width={80} />
                          </div>
                          <Skeleton height={10} width={30} />
                        </div>
                        <div className="col-12 pb-3">
                          <div className="pb-2">
                            <Skeleton height={10} width={80} />
                          </div>
                          <Skeleton height={10} width="100%" count={2} />
                        </div>
                        <div className="col-12 pb-3">
                          <div className="pb-2">
                            <Skeleton height={10} width={80} />
                          </div>
                          <Skeleton height={10} width="100%" count={2} />
                        </div>
                      </div>
                      <div className="border-top pt-3 mt-2">
                        <div className="mb-4">
                          <Skeleton height={10} width={120} />
                        </div>
                        <div className="row">
                          <div className="col-6 col-md-4 pb-3">
                            <div className="pb-2">
                              <Skeleton height={10} width={80} />
                            </div>
                            <Skeleton height={10} width={30} />
                          </div>
                          <div className="col-6 col-md-4 pb-3">
                            <div className="pb-2">
                              <Skeleton height={10} width={80} />
                            </div>
                            <Skeleton height={10} width={30} />
                          </div>
                          <div className="col-6 col-md-4 pb-3">
                            <div className="pb-2">
                              <Skeleton height={10} width={80} />
                            </div>
                            <Skeleton height={10} width={30} />
                          </div>
                          <div className="col-6 col-md-4 pb-3">
                            <div className="pb-2">
                              <Skeleton height={10} width={80} />
                            </div>
                            <Skeleton height={10} width={30} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="card bg-fff p-3">
                    <div className="d-flex align-items-center mb-4">
                      <div
                        className="cursor pe-3"
                        onClick={() => {
                          disable();
                        }}
                      >
                        <MaterialCommunityIcons
                          name="arrow-left"
                          size={20}
                          color="black"
                        />
                      </div>
                      <h6 className="mb-0">Payment Details</h6>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-4 pb-3">
                        <div className="heading pb-2">Customer Name</div>
                        <div className="value d-flex">
                          {transaction_details.user.profile ? (
                            <div className="pe-2">
                              <img
                                src={transaction_details.user.profile}
                                alt="16.a1d4f3f3"
                                className="rounded-float-start"
                                height="34"
                                width="34"
                                style={{ borderRadius: "50px" }}
                              />
                            </div>
                          ) : (
                            <div className="pe-2">
                              <img
                                src={require("../../assets/icon/avatar.png")}
                                className="rounded-float-start"
                                height="34"
                                width="34"
                              />
                              {/* <MaterialIcons className="AccountBox" /> */}
                            </div>
                          )}
                          <div className="">
                            <p className="text-dark fw-bold fs-7">
                              {transaction_details?.user?.name}
                            </p>
                            {/* <p className="text-dark fw-bold fs-7">{ele?.user?.user_name}</p> */}
                            <p>{transaction_details?.user?.user_name}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-6 col-md-4 pb-3">
                        <div className="heading pb-2">Cart Id</div>
                        <div className="value">
                          {transaction_details.ref_id}
                        </div>
                      </div>
                      <div className="col-6 col-md-4 pb-3">
                        <div className="heading pb-2">Date</div>
                        <div className="value">{transaction_details.date}</div>
                      </div>

                      <div className="col-6 col-md-4 pb-3">
                        <div className="heading pb-2">Payment Id</div>
                        <div className="value">
                          #{transaction_details.payment_id}
                        </div>
                      </div>
                      <div className="col-6 col-md-4 pb-3">
                        <div className="heading pb-2">Payment Status</div>
                        <div className="value">
                          {transaction_details.status}
                        </div>
                      </div>
                      <div className="col-6 col-md-4 pb-3">
                        <div className="heading pb-2">Payment Amount</div>
                        <div className="value">
                          ₹ {transaction_details.amount}
                        </div>
                      </div>

                      <div className="col-6 col-md-4 pb-3">
                        <div className="heading pb-2">Application Name</div>
                        <div className="value">
                          {transaction_details?.application_name}
                        </div>
                      </div>

                      <div className="col-6 col-md-4 pb-3">
                        <div className="heading pb-2">Purpose</div>
                        <div className="value">
                          {transaction_details.purpose}
                        </div>
                      </div>

                      <div className="col-6 col-md-4 pb-3">
                        <div className="heading pb-2">
                          Order Success Timestamp
                        </div>
                        <div className="value">
                          {transaction_details.order_success_timestamp}
                        </div>
                      </div>
                      <div className="col-6 col-md-4 pb-3">
                        <div className="heading pb-2">Total Orders</div>
                        <div className="value">
                          {transaction_details.total_orders}
                        </div>
                      </div>

                      <div className="col-6 col-md-4 pb-3">
                        <div className="heading pb-2">Total Refunds</div>
                        <div className="value">
                          {transaction_details.total_refunds}
                        </div>
                      </div>
                      <div className="col-6 col-md-4 pb-3">
                        <div className="heading pb-2">
                          Total Refunded Amount
                        </div>
                        <div className="value">
                          ₹ {transaction_details.total_refunded_amount}
                        </div>
                      </div>
                      <div className="col-6 col-md-4 pb-3">
                        <div className="heading pb-2">Total balance</div>
                        <div className="value">
                          ₹ {transaction_details.total_balance}
                        </div>
                      </div>
                    </div>

                    <div className="border-top pt-3 mt-2">
                      <h6 className="pb-4 ">Transaction Details</h6>
                      <div className="mb-2">
                        <div className="row">
                          <div className="col-6 col-md-4 pb-3">
                            <div className="heading  pb-2">Transaction ID</div>
                            <div className="value">
                              {transaction_details?.transaction
                                ?.transaction_id || "N/A"}
                            </div>
                          </div>

                          <div className="col-6 col-md-4 pb-3">
                            <div className="heading  pb-2">Status ID</div>
                            <div className="value">
                              {transaction_details?.transaction?.status_id ||
                                "N/A"}
                            </div>
                          </div>

                          <div className="col-6 col-md-4 pb-3">
                            <div className="heading  pb-2">Status</div>
                            <div className="value">
                              {transaction_details?.transaction?.status ||
                                "N/A"}
                            </div>
                          </div>

                          <div className="col-6 col-md-4 pb-3">
                            <div className="heading  pb-2">Bank Ref ID</div>
                            <div className="value">
                              {transaction_details?.transaction?.bank_ref_id ||
                                "N/A"}
                            </div>
                          </div>

                          <div className="col-6 col-md-4 pb-3">
                            <div className="heading  pb-2">Payment Gateway</div>
                            <div className="value">
                              {transaction_details?.transaction
                                ?.payment_gateway || "N/A"}
                            </div>
                          </div>

                          <div className="col-6 col-md-4 pb-3">
                            <div className="heading  pb-2">
                              PhonePe Transaction ID
                            </div>
                            <div className="value">
                              {transaction_details?.transaction
                                ?.phonepe_transaction_id || "N/A"}
                            </div>
                          </div>

                          <div className="col-12 pb-3">
                            <div className="heading  pb-2">
                              Phonepe Response Data
                            </div>
                            <div className="value">
                              {transaction_details?.transaction
                                ?.phonepe_response_data || "N/A"}
                            </div>
                          </div>

                          <div className="col-6 col-md-4 pb-3">
                            <div className="heading  pb-2">
                              Razorpay Error Message
                            </div>
                            <div className="value">
                              {transaction_details?.transaction
                                ?.razorpay_error_msg || "N/A"}
                            </div>
                          </div>

                          <div className="col-6 col-md-4 pb-3">
                            <div className="heading  pb-2">
                              Razorpay Order ID
                            </div>
                            <div className="value">
                              {transaction_details?.transaction
                                ?.razorpay_order_id || "N/A"}
                            </div>
                          </div>

                          <div className="col-6 col-md-4 pb-3">
                            <div className="heading  pb-2">
                              Razorpay Payment ID
                            </div>
                            <div className="value">
                              {transaction_details?.transaction
                                ?.razorpay_payment_id || "N/A"}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {transaction_details?.order_result?.id && (
                      <div className="border-top pt-3 mt-2">
                        <h6 className="mb-4">Order Transaction</h6>
                        <div className="row">
                          <div className="col-6 col-md-4 pb-3">
                            <div className="heading  pb-2">Id</div>
                            <div className="value">
                              {transaction_details?.order_result?.id}
                            </div>
                          </div>

                          <div className="col-6 col-md-4 pb-3">
                            <div className="heading  pb-2">Status</div>
                            <div className="value">
                              {transaction_details?.order_result?.status}
                            </div>
                          </div>

                          <div className="col-6 col-md-4 pb-3">
                            <div className="heading  pb-2">Time</div>
                            <div className="value">
                              {transaction_details?.order_result?.time}
                            </div>
                          </div>

                          <div className="col-6 col-md-4 pb-3">
                            <div className="heading  pb-2">Amount</div>
                            <div className="value">
                              ₹ {transaction_details?.order_result?.amount}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {transaction_details?.order_result?.order_items?.length >
                      0 && (
                      <div className="border-top pt-3 mt-2">
                        <h6 className="mb-3">Orders</h6>
                        {transaction_details?.order_result?.order_items?.map(
                          (data: any, i: any) => (
                            <div className="border radius p-3 mb-2">
                              <div className="row">
                                <div className="col-6 col-md-4 pb-3">
                                  <div className="heading pb-2">Id</div>
                                  <div className="value">{data?.id}</div>
                                </div>

                                <div className="col-6 col-md-4 pb-3">
                                  <div className="heading pb-2">
                                    Order Failed
                                  </div>
                                  <div className="value">
                                    {data?.order_failed ? "Yes" : "No"}
                                  </div>
                                </div>

                                <div className="col-6 col-md-4 pb-3">
                                  <div className="heading pb-2">
                                    Order Failed Time
                                  </div>
                                  <div className="value">
                                    {data?.order_failed_time}
                                  </div>
                                </div>
                                <div className="col-6 col-md-4 pb-3">
                                  <div className="heading pb-2">
                                    Order Placed
                                  </div>
                                  <div className="value">
                                    {data?.order_placed ? "Yes" : "No"}
                                  </div>
                                </div>
                                <div className="col-6 col-md-4 pb-3">
                                  <div className="heading pb-2">
                                    Order Placed Time
                                  </div>
                                  <div className="value">
                                    {data?.order_placed_time}
                                  </div>
                                </div>

                                <div className="col-6 col-md-4 pb-3">
                                  <div className="heading pb-2">Sector</div>
                                  <div className="value">{data?.sector}</div>
                                </div>
                                <div className="col-6 col-md-4 pb-3">
                                  <div className="heading pb-2">Status</div>
                                  <div className="value">{data?.status}</div>
                                </div>
                                <div className="col-6 col-md-4 pb-3">
                                  <div className="heading pb-2">
                                    Total Amount Payable
                                  </div>
                                  <div className="value">
                                    ₹ {data?.total_amount_payable}
                                  </div>
                                </div>

                                <div className="col-12 pb-3">
                                  <div className="heading pb-2">
                                    Delivery Address
                                  </div>
                                  <div className="value pb-1">
                                    {
                                      data?.pickup_delivery_details
                                        ?.delivery_address?.map_address
                                    }
                                  </div>
                                  <div className="value pb-1">
                                    {
                                      data?.pickup_delivery_details
                                        ?.delivery_address?.pincode
                                    }
                                  </div>
                                  <div className="value">{`${data?.pickup_delivery_details?.delivery_address?.city}, ${data?.pickup_delivery_details?.delivery_address?.state}, ${data?.pickup_delivery_details?.delivery_address?.country}`}</div>
                                </div>

                                <div className="col-12 pb-3">
                                  <div className="heading pb-2">
                                    Pickup Address
                                  </div>
                                  <div className="value pb-1">
                                    {
                                      data?.pickup_delivery_details
                                        ?.pickup_address?.map_address
                                    }
                                  </div>
                                  <div className="value pb-1">
                                    {
                                      data?.pickup_delivery_details
                                        ?.pickup_address?.pincode
                                    }
                                  </div>
                                  <div className="value">{`${data?.pickup_delivery_details?.pickup_address?.city}, ${data?.pickup_delivery_details?.pickup_address?.state}, ${data?.pickup_delivery_details?.pickup_address?.country}`}</div>
                                </div>
                              </div>

                              <div className="row border-top pt-3">
                                <div>
                                  <h6 className="mt-2 mb-3">
                                    {" "}
                                    Refund Requests
                                  </h6>

                                  {data.refund_requests?.map((ele, i) => (
                                    <div className="row">
                                      <div className="col-6 col-md-4 pb-3">
                                        <div className="heading pb-2">
                                          Amount
                                        </div>
                                        <div className="value">
                                          ₹ {ele.amount}
                                        </div>
                                      </div>

                                      <div className="col-6 col-md-4 pb-3">
                                        <div className="heading pb-2">
                                          Created User
                                        </div>
                                        <div className="value">
                                          {ele.created_user.name}
                                        </div>
                                      </div>

                                      <div className="col-6 col-md-4 pb-3">
                                        <div className="heading pb-2">
                                          Deleted
                                        </div>
                                        <div className="value">
                                          {ele.deleted ? "Yes" : "No"}
                                        </div>
                                      </div>

                                      <div className="col-6 col-md-4 pb-3">
                                        <div className="heading pb-2">
                                          Deleted Time
                                        </div>
                                        <div className="value">
                                          {ele.deleted_time}
                                        </div>
                                      </div>

                                      <div className="col-6 col-md-4 pb-3">
                                        <div className="heading pb-2">
                                          Deleted User
                                        </div>
                                        <div className="value">
                                          {ele.deleted_user.name}
                                        </div>
                                      </div>

                                      <div className="col-6 col-md-4 pb-3">
                                        <div className="heading pb-2">ID</div>
                                        <div className="value">{ele.id}</div>
                                      </div>

                                      <div className="col-6 col-md-4 pb-3">
                                        <div className="heading pb-2">
                                          Reason
                                        </div>
                                        <div className="value">
                                          {ele.reason}
                                        </div>
                                      </div>

                                      <div className="col-6 col-md-4 pb-3">
                                        <div className="heading pb-2">
                                          Refunded
                                        </div>
                                        <div className="value">
                                          {ele.refunded ? "Yes" : "No"}
                                        </div>
                                      </div>

                                      <div className="col-6 col-md-4 pb-3">
                                        <div className="heading pb-2">
                                          Refunded Time
                                        </div>
                                        <div className="value">
                                          {ele.refunded_time}
                                        </div>
                                      </div>

                                      <div className="col-6 col-md-4 pb-3">
                                        <div className="heading pb-2">
                                          Refunded User
                                        </div>
                                        <div className="value">
                                          {ele.refunded_user.name}
                                        </div>
                                      </div>
                                      <div className="col-6 col-md-4 pb-3">
                                        <div className="heading pb-2">
                                          Superapp Refund Request ID
                                        </div>
                                        <div className="value">
                                          {ele.superapp_refund_request_id}
                                        </div>
                                      </div>
                                      <div className="col-6 col-md-4 pb-3">
                                        <div className="heading pb-2">
                                          Superapp Refunded ID
                                        </div>
                                        <div className="value">
                                          {ele.superapp_refunded_id}
                                        </div>
                                      </div>
                                      <div className="col-6 col-md-4 pb-3">
                                        <div className="heading pb-2">Time</div>
                                        <div className="value">{ele.time}</div>
                                      </div>
                                      <div className="col-6 col-md-4 pb-3">
                                        <div className="heading pb-2">Type</div>
                                        <div className="value">{ele.type}</div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    )}

                    {transaction_details?.refund_request.length > 0 && (
                      <div className="border-top pt-3 mt-2">
                        <h6 className="mb-3">Refund Request</h6>
                        {/* Content inside the Accordion */}
                        {transaction_details?.refund_request?.map((data, i) => (
                          <div className="border radius p-3 mb-2">
                            <div className="row ">
                              <div className="col-6 col-md-4 pb-3">
                                <div className="heading pb-2">ID</div>
                                <div className="value">{data.id}</div>
                              </div>
                              <div className="col-6 col-md-4 pb-3">
                                <div className="heading pb-2">Created User</div>
                                <div className="value">
                                  {data.created_user.name}
                                </div>
                              </div>
                              <div className="col-6 col-md-4 pb-3">
                                <div className="heading pb-2">Amount</div>
                                <div className="value">₹ {data.amount}</div>
                              </div>

                              <div className="col-6 col-md-4 pb-3">
                                <div className="heading pb-2">Deleted</div>
                                <div className="value">
                                  {data.deleted ? "Yes" : "No"}
                                </div>
                              </div>
                              <div className="col-6 col-md-4 pb-3">
                                <div className="heading pb-2">Deleted User</div>
                                <div className="value">
                                  {data.deleted_user.name}
                                </div>
                              </div>
                              <div className="col-6 col-md-4 pb-3">
                                <div className="heading pb-2">Deleted Time</div>
                                <div className="value">{data.deleted_time}</div>
                              </div>
                              <div className="col-6 col-md-4 pb-3">
                                <div className="heading pb-2">Reason</div>
                                <div className="value">{data.reason}</div>
                              </div>

                              <div className="col-6 col-md-4 pb-3">
                                <div className="heading pb-2">Refunded</div>
                                <div className="value">
                                  {data.refunded ? "Yes" : "No"}
                                </div>
                              </div>

                              <div className="col-6 col-md-4 pb-3">
                                <div className="heading pb-2">
                                  Refunded Time
                                </div>
                                <div className="value">
                                  {data.refunded_time}
                                </div>
                              </div>

                              <div className="col-6 col-md-4 pb-3">
                                <div className="heading pb-2">
                                  Refunded User
                                </div>
                                <div className="value">
                                  {data.refunded_user.name}
                                </div>
                              </div>

                              <div className="col-6 col-md-4 pb-3">
                                <div className="heading pb-2">
                                  Requested Time
                                </div>
                                <div className="value">
                                  {data.requested_time}
                                </div>
                              </div>

                              <div className="col-6 col-md-4 pb-3">
                                <div className="heading pb-2">Timeout</div>
                                <div className="value">
                                  {data.timeout ? "Yes" : "No"}
                                </div>
                              </div>

                              <div className="col-6 col-md-4 pb-3">
                                <div className="heading pb-2">Timeout Time</div>
                                <div className="value">{data.timeout_time}</div>
                              </div>

                              <div className="col-6 col-md-4 pb-3">
                                <div className="heading pb-2">Timeout User</div>
                                <div className="value">
                                  {data.timeout_user.name}
                                </div>
                              </div>
                            </div>

                            {data?.refunds && (
                              <h6 className="border-top fw-500 py-3">
                                Refund Attempts
                              </h6>
                            )}

                            {data?.refunds.map((r_ele, i) => (
                              <div className="border-bottom py-3">
                                <div className="row" key={i}>
                                  <div className="col-6 col-md-4 pb-3">
                                    <div className="heading pb-2">ID</div>
                                    <div className="value">{r_ele?.id}</div>
                                  </div>
                                  <div className="col-6 col-md-4 pb-3">
                                    <div className="heading pb-2">
                                      Created User
                                    </div>
                                    <div className="value">
                                      {r_ele.created_user?.name || "N/A"}
                                    </div>
                                  </div>
                                  <div className="col-6 col-md-4 pb-3">
                                    <div className="heading pb-2">Amount</div>
                                    <div className="value">
                                      ₹ {r_ele?.amount}
                                    </div>
                                  </div>

                                  <div className="col-6 col-md-4 pb-3">
                                    <div className="heading pb-2">Deleted</div>
                                    <div className="value">
                                      {r_ele?.deleted}
                                    </div>
                                  </div>
                                  <div className="col-6 col-md-4 pb-3">
                                    <div className="heading pb-2">
                                      Deleted User
                                    </div>
                                    <div className="value">
                                      {r_ele.deleted_user?.name || "N/A"}
                                    </div>
                                  </div>
                                  <div className="col-6 col-md-4 pb-3">
                                    <div className="heading pb-2">
                                      Deleted Time
                                    </div>
                                    <div className="value">
                                      {r_ele.deleted_time}
                                    </div>
                                  </div>

                                  <div className="col-6 col-md-4 pb-3">
                                    <div className="heading pb-2">
                                      PhonePe Transaction ID
                                    </div>
                                    <div className="value">
                                      {r_ele?.phonepe_transaction_id || "N/A"}
                                    </div>
                                  </div>
                                  <div className="col-6 col-md-4 pb-3">
                                    <div className="heading pb-2">
                                      PhonePe Response Data
                                    </div>
                                    <div className="value">
                                      {r_ele?.phonepe_response_data || "N/A"}
                                    </div>
                                  </div>

                                  <div className="col-6 col-md-4 pb-3">
                                    <div className="heading pb-2">Status</div>
                                    <div className="value">
                                      {r_ele?.status || "N/A"}
                                    </div>
                                  </div>
                                  <div className="col-6 col-md-4 pb-3">
                                    <div className="heading pb-2">
                                      Status Updated Time
                                    </div>
                                    <div className="value">
                                      {r_ele?.status_updated_time || "N/A"}
                                    </div>
                                  </div>
                                  <div className="col-6 col-md-4 pb-3">
                                    <div className="heading pb-2">Time</div>
                                    <div className="value">
                                      {r_ele?.time || "N/A"}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
