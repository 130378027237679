import React, { useState, useEffect } from "react";
import bootstrap from "../../assets/libraries/bootstrap/js/bootstrap";
import { api } from "../../utils/Api";
let myModal : any;
export default function ModalCod(props : any) {
  const [close_data, SetCloseData] = useState({
    action: "close",
    value:"",
  });
  const [id,setId] = useState("")
  

  useEffect(() => {
    console.log("ModelDeleteUserRoles On mount :", props);
    let myModal1 = document.getElementById("DelUserRole");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      console.log("event :", event);
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      console.log("ModelDeleteUserRoles On props.open :", props.data.value);
      if(props.data.value =="Online"){
setId("1")
      }else{
setId("2")

      }
      myModal = new bootstrap.Modal(
        document.getElementById("DelUserRole"),
        {}
      );
      myModal.show();
    }
  }, [props.open]);



  function StatusOnChange(val){
    setId(val)
  }
  async function Save() {

    let pass_data = {
    
      post: {
        field: "default_checkout_payment_type",
        value: id,
    
      }
    };
    console.log(pass_data)
    let data_res = await api("/settings/save_general", pass_data);
    console.log("add_brand_user response :", data_res.response.data.default_checkout_payment_type);
    if (data_res.status_code == 200) {
    let item = close_data;
    console.log(item)
    item.action = "save";
    item.value =  data_res.response.data.default_checkout_payment_type;
    SetCloseData(item);
  
    myModal.hide();
    }
  }

  return (
    <div
      className="modal fade"
      id="DelUserRole"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="  modal-title" id="ModalLabel">
            Default Checkout Payment Type
            </h6>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
          {/* <div className="form-check form-check-inline">
  <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" checked={id=="1"} onClick={()=>{StatusOnChange("1")}}/>
  <label className="form-check-label" for="inlineRadio1">Online</label>
</div> */}
{/* <div className="form-check form-check-inline">
  <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" checked={id=="2"} onClick={()=>{StatusOnChange("2")}}/>
  <label className="form-check-label" for="inlineRadio2">COD</label>
</div> */}

<div className="form-check form-check-inline">
        <input 
          className="form-check-input" 
          type="radio" 
          name="inlineRadioOptions" 
          id="inlineRadio13" 
          // value="1" 
          checked={id === "1"} 
          onChange={() => StatusOnChange("1")}
        />
        <label className="form-check-label" htmlFor="inlineRadio13">Online</label>
      </div>
      <div className="form-check form-check-inline">
        <input 
          className="form-check-input" 
          type="radio" 
          name="inlineRadioOptions" 
          id="inlineRadio23" 
       
          checked={id === "2"} 
          onChange={() => StatusOnChange("2")}
        />
        <label className="form-check-label" htmlFor="inlineRadio23">COD</label>
      </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-success"
            
              onClick={
                // myModal.hide();
                Save}
                  // data-bs-dismiss="modal"
            >
              Save
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
