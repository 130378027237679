import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { api } from "../../utils/Api";
import DataContext from "../../utils/Context/DataContext";
import { Entypo, MaterialIcons } from "@expo/vector-icons";

import {
  Ionicons,
  MaterialCommunityIcons,
  FontAwesome,
} from "@expo/vector-icons";

import toast, { Toaster } from "react-hot-toast";
import InfiniteScroll from "react-infinite-scroll-component";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
let url_location: any = window.location.host;
let splited_url = url_location.split(".");
const initial_value = {
  id: "",
  status: "",
  profile_img: "",
  driving_license: "",
  aadhaar: "",
  pan: "",
  rc_book: "",
  reason: "",
  profile_img_reason: "",
  driving_license_reason: "",
  aadhaar_reason: "",
  pan_reason: "",
  rc_book_reason: "",
  active_account: true,
};

export default function SellerBrands(props: any) {
  const [modal_variants, SetModalVariants] = useState({
    is_open: false,
    data: {},
  });
  const [save_data, SetSaveData] = useState(initial_value);
  const [data, SetData] = useState([]);

  const [next_page, SetNextPage] = useState(false);

  const [page_no, SetPageNo] = useState(1);
  const [transaction_able, SetTransactionAble] = useState(false);

  const [filter_data, setFilterData] = useState("");

  const [selected_details, SetSelectedDetails] = useState();
  const [width, SetWidth] = useState(innerWidth);
  const [sort_val, SetSort] = useState("")

  // State to store the timeout ID
  const [timeoutId, setTimeoutId] = useState(null);

  const [payments, setPayments] = useState([]);
  const [transaction_details, SetTransactionDetails] = useState({});

  const [view, SetView] = useState(1);

  const [order_status, SetOrderStatus] = useState(1);

  const [show_details_loader, SetShowDetailsLoader] = useState(true);
  const [payment_loading, SetPaymentLoading] = useState(true);
  const [visible_content, SetVisibleContent] = useState(false);

  useEffect(() => {
    // SetPageNo((prevalue)=>{
    let pass_data = {
      order_status: 1,
      seller_brand_id: "",
      seller_outlet_id: "",
      sort: "",
      page_no: page_no,
    };
    get_brand_sellers(pass_data, filter_data, view);
    // get_products(page_no, "1");
    // return prevalue;
    // })
  }, []);

  function outlet_value_on_change(name: any, value: any) {
    SetSaveData((oldValues) => ({
      ...oldValues,
      [name]: value,
    }));
  }

  async function get_brand_sellers(
    get_data: any,
    search: any,
    view: any
  ) {
    if (!get_data.load_more) {
      SetPaymentLoading(true);
    }
    let pass_data = {
      get: {
        page: get_data.page_no,
        sort: get_data.sort,
        search: search,
        status: view,
      },
    };

    let response = await api(
      "/sellers/brands",
      pass_data
    );
    if (response.status_code == 200) {
      SetPaymentLoading(false);
      if (get_data.load_more) {
        SetData(response.response)
        setPayments((prevValue) => {
          response.response.data.map((item) => {
            prevValue.push(item);
          });
          return [...prevValue];
        });
      } else {
        setPayments(response.response.data);
        SetData(response.response)
      }

      if (response.response.hasOwnProperty("data")) {
        SetVisibleContent(true);
      }
      if (response.response.hasOwnProperty("next_page")) {
        SetNextPage(response.response.next_page);
        SetPageNo((prevalue) => {
          prevalue = prevalue + 1;
          return prevalue;
        });
      }
    }
  }







  const handleButtonClick = (status: any) => {
    // Handle button click logic based on the status (New, Active, Rejected)
    SetView(status);
    SetTransactionAble(false);
    let pass_data: any = {
      sort: "",
      // page_no: 1,
    };
    setPayments([]);
    // SetNextPage(false);
    SetPageNo((prevalue) => {
      prevalue = 1;
      pass_data.page_no = prevalue;
      get_brand_sellers(pass_data, filter_data, status);
      return prevalue;
    });
  };

  const handleInputChange = (e: any) => {
    const searchValue = e.target.value;
    setFilterData(searchValue);
    // Clear previous timeout
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // Set a new timeout
    const newTimeoutId = setTimeout(() => {
      // Call your API with the search value
      let pass_data = {
        sort: "",
        page_no: 1,
      };
      setPayments([]);
      get_brand_sellers(pass_data, searchValue, view);
    }, 1000); // 5 milliseconds delay

    // Save the timeout ID
    setTimeoutId(newTimeoutId);
  };

  const sort = (val: any) => {

    SetSort(val);

    let pass_data = {
      sort: val,
      page_no: 1,
    };
    setPayments([]);
    get_brand_sellers(pass_data, filter_data, view);

  }
  const DeliveryPartnerCard = () => {
    return (
      <div className="col-md-6 sm-mt-6 sm-pb-5 md-ps-2">
        {payment_loading ? (
          [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((ele) => (
            <div className="card mb-2 p-3">
              <div className="row align-items-center border-bottom">
                <div className="col-6 pb-3">
                  <Skeleton height={10} width={50} />
                </div>
                <div className="col-6 text-end pb-3">
                  <Skeleton height={30} width={100} />
                </div>
                <div className="col-6 pb-2">
                  <Skeleton height={10} width={100} count={2} />
                </div>
                <div className="col-6 pb-2 text-end">
                  <Skeleton height={10} width={80} count={2} />
                </div>
              </div>
              <div className="pt-2">
                <Skeleton height={10} width={80} />
                <div className="pt-2">
                  <Skeleton height={10} width={180} />
                </div>
              </div>
            </div>
          ))
        ) : payments.length > 0 ? (
          <>
            <InfiniteScroll
              dataLength={payments.length}
              next={() => {
                let pass_data: any = {
                  order_status: order_status,
                  seller_brand_id: "",
                  seller_outlet_id: "",
                  sort: "",
                  load_more: true,

                  page_no: page_no,
                };

                get_brand_sellers(pass_data, filter_data, view);
              }}
              // hasMore={get_next_page()}
              hasMore={next_page}
              loader={
                <div className="center m-5">
                  <div className="lds-dual-ring"></div>
                  <p className=" small ps-2">Just a second ...</p>
                </div>
              }
            >
              {payments.map((ele, i) => (
                <div
                  key={i}
                  className={
                    transaction_able == true && selected_details == i
                      ? "active-shadow-layout radius  mb-2 p-3"
                      : "card mb-2 p-3"
                  }
                // onClick={() => {
                //   enable(ele);

                //   SetSelectedDetails(i);
                // }}
                >
                  <div className="row ">
                    {/* <div className="col-6 pb-3">
                      <p className="text-black">Agent ID: #{ele.agent_id}</p>
                    </div>

                    <div className="col-6 end pb-3"></div> */}
                    <div className="col-12  pb-3">
                      <div className="d-flex mb-3">
                        <img
                          src={
                            ele.logo != null
                              ? data.image_base_url + ele.logo + "?width=100"
                              : require("../../assets/img/no-data.png?width=100")
                          }

                          className={props.level == "child" ? "cat-child category-img" : "category-img"}

                        />
                        <div className=" ms-2">
                          <h6 className="mb-1">{ele?.name} </h6>
                          <p className="text-muted">{ele?.slug}</p>
                        </div>
                        <div className="ms-auto text-end">
                          <div>#{ele?.brand_id}</div>
                          {ele?.status_id == 2 && (
                            <div className="badge bg-danger">Deleted</div>
                          )}
                          </div>
                      </div>
                      <div className="d-flex">
                        <div>
                          <div>Owned By</div>
                          <div className="text-muted">
                            <p className="">{ele?.owner.name}  </p>
                            <p className="">@{ele?.owner.user_name}</p>
                          </div>
                        </div>
                        <div className="ms-auto text-end">
                          <div>Created At</div>
                          <div className="text-muted">{ele?.created_at}</div>
                        </div>
                      </div>

                    </div>
                    <div className="col-12">
                      <div className="d-flex">
                        <Link
                          type="button"
                          className="btn btn-dark btn-radius btn-sm px-3 w-50"
                          to={
                            "/seller_brands/" +
                            ele.brand_id
                          }
                        >
                          View
                        </Link> 
                        <a
                          className="btn btn-dark btn-radius btn-sm px-3 ms-2 w-50"
                          href={
                            splited_url[0] == "1sdev"
                              ? "https://1sdev.sellers.1superapp.com/brand/" +
                              ele.brand_id +
                              "/dashboard"
                              : "https://sellers.1superapp.com/brand/" +
                              ele.brand_id +
                              "/dashboard"
                          } 
                          target="_blank"
                          >
                            Go To Seller
                          </a>
                      </div>
                    </div>

                    {ele.status == "Rejected" &&
                      <div className="col-4 end pb-2">
                        <FontAwesome
                          name="circle"
                          size={10}
                          color={ele.status == "Rejected" && "red"}
                        />
                        <p
                          className={
                            ele.status == "Rejected"
                            && "ps-2 text-red"

                          }
                        >
                          {ele.status}
                        </p>
                      </div>
                    }

                    {ele.status == "Rejected" ? (
                      <div className="">
                        <div className="pt-1 pb-2">
                          <hr className="text-gray m-0" />
                        </div>

                        <p className="pb-2 text-dark fw-500">Rejected Reason</p>
                        <p className="text-gray">
                          {ele.rejected_reason != ""
                            ? ele.rejected_reason
                            : "-"}
                        </p>
                      </div>
                    ) : null}
                  </div>
                </div>
              ))}
            </InfiniteScroll>
          </>
        ) : (
          <div className="center p-3">
            <div className="card p-3 w-100">
              <div className="w-100 text-center">
                <img
                  src={require("../../assets/img/not-found.png")}
                  className="no-data-img"
                />
              </div>
              <p className="center pt-3">No Brands</p>
            </div>
          </div>
        )}
      </div>
    );
  };


  return (
    <div className="mb-5">
      <Toaster />
      {/* Header */}
      <div className="head-bar row">
        <div className="col-md-6">
          <h5 className="  mb-2">Seller Brands</h5>
        </div>

        <div className="col-md-6 sm-pt-2 end">
          {/* search */}
          <div className="input-icons form-group p-2">
            <input
              type="text"
              className="form-control search-bar p-0"
              placeholder="Search here..."
              onChange={handleInputChange}
            />
            <div className="cursor px-2 border-left">
              <Ionicons name="md-search" size={16} color="#ccc" />
            </div>
          </div>
          <div className="dropdown">
            <button
              className="btn btn-white dropdown-toggle d-flex align-items-cetner"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src={require("../../assets/icon/filter.png")}
                className="icon-14px"
              />

            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <a className="dropdown-item" href="#">
                  <div
                    onClick={() =>
                      sort("asc")
                    }
                  >
                    <p className="mb-0 p-2">Oldest</p>
                  </div>
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  <div
                    onClick={() => sort("desc")}
                  >
                    <p className="mb-0 p-2">Newest</p>
                  </div>
                </a>
              </li>

            </ul>
          </div>

        </div>

        <div className="mt-3">
          <button
            type="button"
            className={
              view == "1"
                ? "btn btn-text header-tap me-1 px-3 "
                : "btn btn-text text-black me-1 px-3"
            }
            onClick={() => handleButtonClick("1")}
          >
            Active
          </button>
          <button
            type="button"
            className={
              view == "2"
                ? "btn btn-text header-tap me-1 px-3 "
                : "btn btn-text text-black me-1 px-3"
            }
            onClick={() => handleButtonClick("2")}
          >
            Deleted
          </button>

        </div>
      </div>

      <div className="mt-3 px-md-3 px-sm-0 ">
        <div className="row">
          {width < 768 && transaction_able == false ? (
            <DeliveryPartnerCard />
          ) : width > 768 ? (
            <DeliveryPartnerCard />
          ) : null}
        </div>
      </div>


    </div>
  );
}
