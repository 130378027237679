import React, { useState, useEffect, useContext } from "react";
import { Outlet, Link, useLocation } from "react-router-dom";
// import Footer from "../Footer/Footer";

import DataContext from "../../utils/Context/DataContext";
import { usePromiseTracker } from "react-promise-tracker";
import Loader from "../../utils/Loader";
// import { Toaster } from "react-hot-toast";
import { api } from "../../utils/Api";
import Header from "../Header/Header";
import SideMenu from "../SideMenu/SideMenu";
import Footer from "../Footer/Footer";

export default function AdminLayout(props: any) {
  const context = useContext(DataContext);
  const location = useLocation();
  const [width, SetWidth] = useState(innerWidth);
  const [is_menu_open, SetIsMenuOpen] = useState(width < 786 ? false : true);
  const [outlet_context_data, SetOutletContextData] = useState({
    have_back: false,
    have_back_value: false,
    back_value: "",
    title: "",
    have_footer: true,
    link: {},
    open_header_address_modal: false,
    auth: {},
    have_menu: width < 786 ? false : true
  });


  useEffect(() => {
    
    // get_init();
  }, []);

  //   async function get_init() {
  //     let response = await api("/init");

  //     if (response.status_code == 200) {
  //       if (response.response.hasOwnProperty("user_details")) {
  //         localStorage.setItem(
  //           "user_data",
  //           JSON.stringify(response.response.user_details)
  //         );
  //       }
  //     }
  //   }

  // useEffect(() => {
  //   context.SetSampleData("hai");
  // }, [location]);

  function on_menu_click(data: any) {
   
    SetIsMenuOpen(data);
    SetOutletContextData((prevalue:any)=>{
      prevalue.have_menu = data;
      return {...prevalue}
    })
  }

  
  return (
    <>
      {/* <Loader promiseTracker={usePromiseTracker} /> */}
      <div className="page-body">
        {outlet_context_data.have_menu ? (
          <SideMenu
            active={outlet_context_data.have_menu}
            menu_click={(data: any) => {
              console.log("menu_click :",data)
              on_menu_click(data);
            }}
          />
        ) : null}
        <div className={outlet_context_data.have_menu ? "page-content" : ""}>
          <Header
            is_menu_open={outlet_context_data.have_menu}
            menu_click={(data: any) => {
              on_menu_click(data);
            }}
          />
          <div
            className={outlet_context_data.have_menu == false && width > 786 ? "page-main" : ""}
          >
            <Outlet context={{ SetOutletContextData }}/>
            {/* <Toaster
              position="top-center"
              reverseOrder={false}
              toastOptions={{
                className: "",
                style: {
                  border: "1px solid #171546",
                  padding: "10px",
                },
                duration: 1500,
              }}
            /> */}
          </div>
        </div>
      </div>
      <Footer
        menu_click={(data:any) => {
          on_menu_click(data);
        }}
        is_menu_open={is_menu_open}
        // from="brand"
      />
    </>
  );
}
