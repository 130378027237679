import React ,{useState,useEffect} from 'react'
import { useOutletContext } from "react-router-dom";
import { api } from "../../utils/Api";
import InfiniteScroll from "react-infinite-scroll-component";
import ModalReward from './ModalReward';
import ChooseBrandOutletModal from './ChooseBrandOutletModal';
import ModalUsers from "./ModalUsers";


import {
  Ionicons,
  MaterialIcons,
  MaterialCommunityIcons,
} from "@expo/vector-icons";

function Rewards() {

  const [statistics_data, SetStatisticsData]: any = useState([]);
  const OutletContext = useOutletContext();
  const [timeoutId, setTimeoutId] = useState(null);
  const [brand_id,setBrandId] = useState("")
  const [user_id,setUserId] = useState("")
  const [sort, setSort] = useState("");
  const [filter_data, setFilterData] = useState("");
  const [reward_modal, setRewardModal] = useState({
    is_open: false,
  });
  const [selectedOption, setSelectedOption] = useState("");
  const [seller_modal, setSellerModal] = useState({
    is_open: false,
  
  });
  
  const [user_modal, setUserModal] = useState({
    is_open: false,
  
  });
  
    const [startDateShow, setStartDateShow] = useState("");
  const [endDateShow, setEndDateShow] = useState("");
  const [page_no, SetPageNo] = useState(1);
  const [next_page, SetNextPage] = useState(false);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [width, SetWidth] = useState(innerWidth);
  

  useEffect(() => {
   
    let send_data = {
      startDate:startDate,
      endDate:endDate,
      page_no: 1,
      search:filter_data
    };
    get_overview(send_data);
SetPageNo(1)
    // get_outlets_and_channels();
  }, [sort]);
  async function Save(data) {
    console.log(user_id,brand_id)
        let pass_data = {
        
          post: {
    
            to_user_id:user_id,
            to_brand_id:brand_id,
        points:data.reward,
        from_admin:"1",
        to_dial_code:"",
        to_mobile:data.mobile,
          }
        };
        if (data.mobile != "") {
          pass_data.post.to_dial_code = data.dial;
          pass_data.post.to_mobile = data.mobile;
        }
        // if (selectedOption === 'inApp') {
        //   pass_data.post.to_brand_id = brand_id;
        // }
        // if (selectedOption === 'mobileNumber') {
        //   pass_data.post.to_dial_code = dialCode;
        //   pass_data.post.to_mobile = mobileNumber;
        // }
    
      
        console.log(pass_data)
        let data_res = await api("/rewardpoints/add_reward_points", pass_data);
      console.log(data_res)
        if (data_res.status_code == 200) {
       setBrandId("");
       setUserId("");
             let send_data = {
                startDate:startDate,
                endDate:endDate,
                page_no: 1,
                search:filter_data
              };
              get_overview(send_data);
          SetPageNo(1)

        }
      }
    
  const handleChildData = (data: string) => {
    console.log(data)
if(data=="brand"){
      setSellerModal((prevValue: any) => ({
                  ...prevValue,
                  is_open: true,
                })
              )
            }else if(data=="user"){
  setUserModal((prevValue: any) => ({
                  ...prevValue,
                  is_open: true,
                }))

            }
    // setSelectedOption(data);
  };
  
  function convertDateTime(dateTimeStr) {
    const date = new Date(dateTimeStr);
    
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-11
    const year = date.getFullYear();
    
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = '00'; // Set seconds to 00
    
    const formattedDateTime = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    return formattedDateTime;
  }

  const handleStartChange = (e) => {
    //  let start:any = moment(date).format('DD-MM-YYYY HH:mm:ss')
    console.log(e.target.value)
    const convertedDateTime = convertDateTime(e.target.value);
      console.log(convertedDateTime);
      setStartDateShow(e.target.value)
     setStartDate(convertedDateTime);
    //  getSuperappStatistics(start,endDate)
    //  get_order_statistics(start,endDate)
    //  get_order_price_statistics(start,endDate)
    
    };
  
    const handleEndChange = (e) => {
      const convertedDateTime = convertDateTime(e.target.value);
      setEndDateShow(e.target.value)
    
    
        setEndDate(convertedDateTime);
        
      // getSuperappStatistics(startDate,end)
      // get_order_statistics(startDate,end)
      // get_order_price_statistics(startDate,end)
   
     
     
     };

     const handleApply = () => {

      const startTimestamp = new Date(startDateShow).getTime();
      const endTimestamp = new Date(endDateShow).getTime();
  
      console.log(startTimestamp)
      console.log(endTimestamp)
  
     
      console.log("hi")
      if (startTimestamp < endTimestamp) {
      //     getSuperappStatistics(startDate,endDate)
      // get_order_statistics(startDate,endDate,activeOrTab)
      // get_order_price_statistics(startDate,endDate,activeTab)
          // Add your logic for applying the selected dates

          let send_data = {
            page_no: 1,
            have_more: false,
            startDate:startDate,
            endDate:endDate,
      search:filter_data

          };
        
          get_overview(send_data);
      } else {
        toast.error("End date cannot be earlier than start date.");
      }

      SetPageNo(1)
  };

  const handleModalClose = (data: any, modalType: any) => {
    // console.log(data.data.brand_id)
        if (data.action === "save") {
          if (modalType === 'user') {
            setUserId(data.data.id);
            console.log(data.data.id);
    
          } else if (modalType === 'brand') {
            setBrandId(data.data.brand_id);
            console.log(data.data.brand_id);
    
          }
        }
      };
  async function get_overview(get_data: any) {

 
     let pass_data = {
       get: {
         filter_from:get_data.startDate,
         filter_to: get_data.endDate,
         order_by: sort,

search:get_data.search,
         
        page: get_data.page_no,
       },
     };
     let response: any = await api("/rewardpoints/accounts", pass_data);
     console.log("/qr/overview response :", response.response);
     if (response.status_code == 200) {
       if (response.response.hasOwnProperty("data")) {

        
        //  let statistics_data = response.response.data;
 
         if (get_data.load_more) {
          SetStatisticsData((prevValue) => {
            response?.response?.data?.map((ele: any) => {
              prevValue.push(ele);
            });
            return [...prevValue];
          });
        } else {
          SetStatisticsData(response.response.data);

        }
    
 
  
       }
  
      // if(!get_data.hasOwnProperty("type")){
      if (response.response.hasOwnProperty("next_page")) {
        SetNextPage(response.response.next_page);
        SetPageNo((prevalue) => {
          prevalue = prevalue + 1;
          return prevalue;
        });
      }
 
      
     }
   }

   const handleInputChange = (e: any) => {
    const searchValue = e.target.value;
    setFilterData(searchValue);
    // Clear previous timeout
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // Set a new timeout
    const newTimeoutId = setTimeout(() => {
      // Call your API with the search value
      let send_data = {
      startDate:startDate,
      endDate:endDate,
      page_no: 1,
      search:   searchValue
    };
    get_overview(send_data);
      SetPageNo(1);
    }, 1000); // 5 milliseconds delay

    setTimeoutId(newTimeoutId);
  };

  return (
    <div>
    <div
    className={OutletContext ? "head-bar sticky-sm py-3" : "head-bar py-3"}
    style={OutletContext == false ? { width: width } : {}}
  >
    <div className="d-flex">
        <h6 className="fs-5  mb-0">Reward Points</h6>
      <button className='btn btn-prime ms-auto mb-4'   onClick={() => {
                                
                                  setRewardModal({
                                    is_open: true,
                                  });
                                }}>Add Reward Points</button>
        </div>
    <div className="d-flex align-items-center">
    <div className="input-icons form-group p-2">
                            <input
                                type="text"
                                className="form-control search-bar p-0"
                                placeholder="Search by user, brand, organization, outlet"
                                onChange={handleInputChange}
                            />
                            <div className="cursor px-2 border-left">

                            </div>
                        </div>
 


        </div>

    

        </div>

        
        <div className="container mt-5">
       
      
      <InfiniteScroll
            dataLength={statistics_data.length}
            next={() => {
              let send_data = {
                startDate:startDate,
                endDate:endDate,
                load_more: true,

                page_no: page_no,
      search:filter_data

              };
              get_overview(send_data);
            }}
            hasMore={next_page}
            loader={
              <div className="center m-5">
                <div className="lds-dual-ring"></div>
                <p className=" small ps-2">Just a second ...</p>
              </div>
            }
          >
            <div className="table-responsive" >
      <table class="table table-striped table-bordered">
            <thead class="table-dark">
                <tr style={{ cursor: 'pointer' }}>
                {/* <th scope="row"  onClick={()=>{
    // Toggle sorting state
    const newSort = sort === 'asc_id' ? 'desc_id' : 'asc_id';
    setSort(newSort);
  }}><div className='d-flex'> <div>ID</div>   {sort=="desc_id" ? (
    <MaterialIcons
      name="keyboard-arrow-down"
      size={24}
      color="white"
    />
  ) : sort=="asc_id"?(
    <MaterialIcons
      name="keyboard-arrow-up"
      size={24}
      color="white"
    />
  ):<div> </div>}</div></th>
              */}
           
           <th scope="row" onClick={() => {
        const newSort = sort === 'asc_id' ? 'desc_id' : 'asc_id';
        setSort(newSort);
      }}>
        <div className='d-flex'>
          <div>ID</div>
          {sort === "desc_id" ? (
            <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
          ) : sort === "asc_id" ? (
            <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
          ) : <div> </div>}
        </div>
      </th>

      {/* Points */}
      <th scope="row" onClick={() => {
        const newSort = sort === 'asc_points' ? 'desc_points' : 'asc_points';
        setSort(newSort);
      }}>
        <div className='d-flex'>
          <div>Points</div>
          {sort === "desc_points" ? (
            <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
          ) : sort === "asc_points" ? (
            <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
          ) : <div> </div>}
        </div>
      </th>


      {/* Brand ID */}
      <th scope="row" onClick={() => {
        const newSort = sort === 'asc_brand_id' ? 'desc_brand_id' : 'asc_brand_id';
        setSort(newSort);
      }}>
        <div className='d-flex'>
          <div>Brand ID</div>
          {sort === "desc_brand_id" ? (
            <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
          ) : sort === "asc_brand_id" ? (
            <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
          ) : <div> </div>}
        </div>
      </th>

      {/* Brand Name */}
      <th scope="row" onClick={() => {
        const newSort = sort === 'asc_brand_name' ? 'desc_brand_name' : 'asc_brand_name';
        setSort(newSort);
      }}>
        <div className='d-flex'>
          <div>Brand Name</div>
          {sort === "desc_brand_name" ? (
            <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
          ) : sort === "asc_brand_name" ? (
            <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
          ) : <div> </div>}
        </div>
      </th>

      {/* Outlet ID */}
      <th scope="row" onClick={() => {
        const newSort = sort === 'asc_outlet_id' ? 'desc_outlet_id' : 'asc_outlet_id';
        setSort(newSort);
      }}>
        <div className='d-flex'>
          <div>Outlet ID</div>
          {sort === "desc_outlet_id" ? (
            <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
          ) : sort === "asc_outlet_id" ? (
            <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
          ) : <div> </div>}
        </div>
      </th>

      {/* Outlet Name */}
      <th scope="row" onClick={() => {
        const newSort = sort === 'asc_outlet_name' ? 'desc_outlet_name' : 'asc_outlet_name';
        setSort(newSort);
      }}>
        <div className='d-flex'>
          <div>Outlet Name</div>
          {sort === "desc_outlet_name" ? (
            <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
          ) : sort === "asc_outlet_name" ? (
            <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
          ) : <div> </div>}
        </div>
      </th>
{/* Account Type column */}
<th scope="row" onClick={() => {
        const newSort = sort === 'asc_account_type' ? 'desc_account_type' : 'asc_account_type';
        setSort(newSort);
      }}>
  <div className='d-flex'>
    <div>Account Type</div>
    {sort === "desc_account_type" ? (
      <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
    ) : sort === "asc_account_type" ? (
      <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
    ) : <div> </div>}
  </div>
</th>
      {/* User */}
      <th scope="row" onClick={() => {
        const newSort = sort === 'asc_user' ? 'desc_user' : 'asc_user';
        setSort(newSort);
      }}>
        <div className='d-flex'>
          <div>User</div>
          {sort === "desc_user" ? (
            <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
          ) : sort === "asc_user" ? (
            <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
          ) : <div> </div>}
        </div>
      </th>


      {/* Organization */}
      <th scope="row" onClick={() => {
        const newSort = sort === 'asc_organization' ? 'desc_organization' : 'asc_organization';
        setSort(newSort);
      }}>
        <div className='d-flex'>
          <div>Organization</div>
          {sort === "desc_organization" ? (
            <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
          ) : sort === "asc_organization" ? (
            <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
          ) : <div> </div>}
        </div>
      </th>

      {/* Org Username */}
      <th scope="row" onClick={() => {
        const newSort = sort === 'asc_org_username' ? 'desc_org_username' : 'asc_org_username';
        setSort(newSort);
      }}>
        <div className='d-flex'>
          <div>Org Username</div>
          {sort === "desc_org_username" ? (
            <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
          ) : sort === "asc_org_username" ? (
            <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
          ) : <div> </div>}
        </div>
      </th>

      {/* Org Profile */}
      <th scope="row" onClick={() => {
        const newSort = sort === 'asc_org_profile' ? 'desc_org_profile' : 'asc_org_profile';
        setSort(newSort);
      }}>
        <div className='d-flex'>
          <div>Org Profile</div>
          {sort === "desc_org_profile" ? (
            <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
          ) : sort === "asc_org_profile" ? (
            <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
          ) : <div> </div>}
        </div>
      </th>

      {/* App Name */}
      <th scope="row" onClick={() => {
        const newSort = sort === 'asc_app_name' ? 'desc_app_name' : 'asc_app_name';
        setSort(newSort);
      }}>
        <div className='d-flex'>
          <div>App Name</div>
          {sort === "desc_app_name" ? (
            <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
          ) : sort === "asc_app_name" ? (
            <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
          ) : <div> </div>}
        </div>
      </th>

      {/* User ID */}
      <th scope="row" onClick={() => {
        const newSort = sort === 'asc_user_id' ? 'desc_user_id' : 'asc_user_id';
        setSort(newSort);
      }}>
        <div className='d-flex'>
          <div>User ID</div>
          {sort === "desc_user_id" ? (
            <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
          ) : sort === "asc_user_id" ? (
            <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
          ) : <div> </div>}
        </div>
      </th>

      {/* Org ID */}
      <th scope="row" onClick={() => {
        const newSort = sort === 'asc_org_id' ? 'desc_org_id' : 'asc_org_id';
        setSort(newSort);
      }}>
        <div className='d-flex'>
          <div>Org ID</div>
          {sort === "desc_org_id" ? (
            <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
          ) : sort === "asc_org_id" ? (
            <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
          ) : <div> </div>}
        </div>
      </th>

      
      {/* Last Updated Time */}
      <th scope="row" onClick={() => {
        const newSort = sort === 'asc_last_updated_time' ? 'desc_last_updated_time' : 'asc_last_updated_time';
        setSort(newSort);
      }}>
        <div className='d-flex'>
          <div>Last Updated Time</div>
          {sort === "desc_last_updated_time" ? (
            <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
          ) : sort === "asc_last_updated_time" ? (
            <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
          ) : <div> </div>}
        </div>
      </th>

      {/* Created At */}
      <th scope="row" onClick={() => {
        const newSort = sort === 'asc_created_at' ? 'desc_created_at' : 'asc_created_at';
        setSort(newSort);
      }}>
        <div className='d-flex'>
          <div>Created At</div>
          {sort === "desc_created_at" ? (
            <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
          ) : sort === "asc_created_at" ? (
            <MaterialIcons name="keyboard-arrow-up" size={24} color="white" />
          ) : <div> </div>}
        </div>
      </th>

              
                </tr>
            </thead>
       
            <tbody>
           
              {statistics_data?.map((ele,i)=>(
                <tr>
                <td>{ele.id}</td>

{/* Points */}
<td>{ele.points}</td>


{/* Brand ID */}
<td>{ele.brand_id}</td>

{/* Brand Name */}
<td>{ele.brand_name}</td>
{/* Brand Name */}
<td>{ele.account_type}</td>
{/* Outlet ID */}
<td>{ele.outlet_id ? ele.outlet_id : ""}</td>

{/* Outlet Name */}
<td>{ele.outlet_name ? ele.outlet_name : ""}</td>
<td>
{ele.user_id != null&& (
          <div className=" pb-3 d-flex">
          {ele.hasOwnProperty("user_profile") && ele.user_profile != null
           ? (
            <div className="pe-2">
              <img
                src={ele.user_profile}
                alt="16.a1d4f3f3"
                className="rounded-float-start"
                height="34"
                width="34"
                style={{ borderRadius: "50px" }}
              ></img>
            </div>
          ) : (
            <div className="pe-2">
              <img
                src={require("../../assets/icon/avatar.png")}
                className="rounded-float-start"
                height="34"
                width="34"
              />
            </div>
          )}
          <div className="">
            <p className="text-dark fw-bold fs-7">
              {ele.user}
            </p>
            <p>{ele.username}</p>

            
          </div>
        </div>
  )  }
  </td>

{/* Organization */}
<td>{ele.organization ? ele.organization : ""}</td>

{/* Org Username */}
<td>{ele.org_username ? ele.org_username : ""}</td>

{/* Org Profile */}
<td>{ele.org_profile ? ele.org_profile : ""}</td>

{/* App Name */}
<td>{ele.app_name ? ele.app_name : ""}</td>

{/* User ID */}
<td>{ele.user_id ? ele.user_id : ""}</td>

{/* Org ID */}
<td>{ele.org_id ? ele.org_id : ""}</td>
{/* Last Updated Time */}
<td>{ele.last_updated_time}</td>

{/* Created At */}
<td>{ele.created_at ? ele.created_at : ""}</td>

                </tr>
                  ))}
            </tbody>
     
        </table>
        
        </div>
        </InfiniteScroll>

        </div>
        <ModalReward
          open={reward_modal.is_open}
          sendMessageToParent={handleChildData}
          close={(data: any) => {
            console.log("Edit Outlet Modal On Close :", data);
            setRewardModal({
              is_open: false,

            });
            if (data.action == "save") {
              console.log(data)
     let send_data = {
                dial:data.data.dial,
                mobile:data.data.mobile,
              
                reward:data.data.reward
              };
              Save(send_data)
          //     let send_data = {
          //       startDate:startDate,
          //       endDate:endDate,
          //       page_no: 1,
          //       search:filter_data
          //     };
          //     get_overview(send_data);
          // SetPageNo(1)
          //     console.log(data.index)
          //     console.log(data.data)
              // const filteredItems = data.data.filter(item => item?.customer?.id === data.index);


              // SetCartData((prevValue) => {
              //   const newValue = [...prevValue];

              //   filteredItems.forEach(item => {
              //     item.ecommerce_cart_response = data.demo_order;
              //   });
              //   return newValue;
              // });


            }
          }}
        />


<ChooseBrandOutletModal
open={seller_modal.is_open}
close={(data: any) => {
  setSellerModal({
      is_open: false,
  });
  // if (data.action == "save") {
  // console.log(data.data.brand_id);
  //     setBrandId(data.data.brand_id);
  //     // setUserId("");
   
  handleModalClose(data, 'brand');
  // }
}}
      />

<ModalUsers
open={user_modal.is_open}
close={(data: any) => {
  setUserModal({
      is_open: false,
  });
  // if (data.action == "save") {
    // console.log(data.data.id);

    //   setUserId(data.data.id);
    //   // setBrandId("");

  handleModalClose(data, 'user');
   
                  
  // }
}}
      />
</div>
  )
}

export default Rewards