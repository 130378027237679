import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { api } from "../../utils/Api";
import DataContext from "../../utils/Context/DataContext";
import ModalCreateCategory from "./ModalCreateCategories";
import ModalDeleteCategories from "./ModalDeleteCategories";
import ModalEditCategory from "./ModalEditCategory";
import SwipeableBottomSheet from "@geomatico/react-swipeable-bottom-sheet";
import ModalAddGallery from "./ModalAddGallery";

import { Entypo, MaterialIcons } from "@expo/vector-icons";
import ModalDelete from "../../components/ModalDelete/ModalDelete";
import Skeleton from "react-loading-skeleton";

import "react-loading-skeleton/dist/skeleton.css";
import {
  Ionicons,
  MaterialCommunityIcons,
  FontAwesome,
} from "@expo/vector-icons";
let width = innerWidth;

export default function Categories(props: any) {
  const [categories, setCategories] = useState([]);
  const [org_category_data, SetOrgCategoryData] = useState([])
  // const [categoriesChild, setCategoriesChild] = useState(null); // For storing child categories of a selected parent
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [add_data, setAddData] = useState({});
  const [user_errors, SetUserErrors] = useState(false);

  const [image_data, setImageData] = useState({});
  const [image_url, setImageUrl] = useState("");


  const [modal_image, SetModalImage] = useState({
    is_open: false,
    selected_images: [],
  });
  const [edit_user_modal, SetEditUserModal] = useState({
    is_open: false,
    data: {},

    type: "edit",
  });
  const [category_modal, SetCategoryModal] = useState({
    is_open: false,
    type: "add",
    data: {},
  });
  const [cat_search_data, SetCatSearchData] = useState('')

  const [modal_delete, SetModalDelete] = useState({
    is_open: false,
    delete_type: "single",
    data: {
      title: "",
      body: "",
    },
    have_option: false,
    options: {},
  });
  const [openModal, SetopenModal] = useState({
    is_open: false,
    data: {},
  });

  const [action_sheet_open, SetActionSheetOpen] = useState({
    is_open: false,
    data: {},
  });
  const [visible_content, SetVisibleContent] = useState(false);

  useEffect(() => {
    get_categories();
  }, []);

  useEffect(() => {
    if (cat_search_data != '') {
      const filter_array = org_category_data.filter((ele) =>
        ele.name.toLowerCase().match(cat_search_data.toLowerCase()),
      )
      setCategories(filter_array)
      setSelectedCategories([])

    } else {

      setCategories(org_category_data)
      setSelectedCategories([])

    }
  }, [cat_search_data])
  const handleCategorySelect = (category, level) => {
    setSelectedCategories((prevSelected) => {
      const updatedSelected = prevSelected.slice(0, level); // Only keep the selection up to the current level
      return [...updatedSelected, category];
    });
  };

  const handleChildData = (data: string) => {
    SetModalImage((prevValue: any) => {
      prevValue.is_open = !prevValue.is_open;
      // prevValue.selected_images = data.selected_img;
      return { ...prevValue };
    });
    // setSelectedOption(data);
  };
  
  async function add_category(props) {
    console.log(props);
    console.log(props.type);


    // if (outlet_data.cat_name !== "" && outlet_data.selected_roles !== "") {
      let post_value = {
        post: {
          name: props.name,
          sector: props.sector,
          // image_url: image_data?.image_url,
          // parent :props.parent,
        },
      };
    //   if (outlet_data.image_url != "") {
    //     post_value.post.image_url = outlet_data.image_url;
    //   }
      // if ( props.type === "add" ) {
      //   post_value.post.parent = props.parent;
      // }
   
      if ( props.type === "add" ) {
        image_data?.image_url != undefined && 
        ( post_value.post.image_url = image_data?.image_url)
        post_value.post.parent = props.id;


      }
      if ( props.type === "edit" ) {
        post_value.post.category_id = props.id;
        post_value.post.slug = props.slug;
        post_value.post.parent = props.parent;
        image_data?.image_url != undefined ?
        (post_value.post.image_url = image_data?.image_url) : props.image !=null && 
        (post_value.post.image_url =props.image)
        
        // image_data?.image_url != undefined || props.image !=null &&
        // (post_value.post.image_url = image_data?.image_url != undefined? image_data?.image_url : props.image !=null && props.image);


      }
    const endpoint =
    props.type === "add" ? "/orders/create_category" : "/orders/edit_category";

    console.log(endpoint)
   console.log(post_value)
       let data_res = await api(endpoint, post_value)
       console.log(data_res)
   if (data_res.status_code == 200) {
        get_categories();
        setImageData({})
        // window.location.reload();
      
    } else {
      SetUserErrors(true);
    }
  }

  const RenderCategories = (props: any) => {
    const [is_open, SetIsOpen] = useState(false);
    console.log(props)
    return props.categoryArray.map((category: any, i: any) => (
      <>
        <tr key={i}>
          <td>
            {/* {props.level != "child" ? ( */}
              <img
                src={
                  category.image != null
                    ? category.image+"?width=100"
                    : require("../../assets/img/no-data.png?width=100")
                }
                
                className={props.level == "child" ? "cat-child category-img" : "category-img"}
              
              />
            {/* ) : null} */}
          </td>
          <td>
            <div
              // className={props.level == "child" ? "cat-child" : ""}
              // onClick={() => {
              //   SetIsOpen((prevalue) => {
              //     prevalue = !prevalue;
              //     return prevalue;
              //   });
              // }}
            // onClick={() => setCategoriesChild(category)}
            className={props.level > 0 ? "cat-child" : ""}
            onClick={() => handleCategorySelect(category, props.level)}

            >
              <p
                className={
                  category.exists
                    ? "text-gray mb-0 fs-6 fw-bolder"
                    : "fs-6 text-dark mb-0"
                }
              >
                {category.name} #{category.id}
              </p>
              <p className="small pt-1 text-gray">{category.slug}</p>
              <div>{category.sector_name}
              </div>
            </div>
          </td>
          {/* arrow */}
          <td>
            {category.children && category.children.length > 0 ? (
              <div
              onClick={() => handleCategorySelect(category, props.level)}
                // onClick={() => {

                
                //   SetIsOpen((prevalue) => {
                //     prevalue = !prevalue;
                //     return prevalue;
                //   });
                // }}
              >
                {/* {is_open ? (
                  <MaterialIcons
                    name="keyboard-arrow-down"
                    size={24}
                    color="black"
                  />
                ) : (
                  <MaterialIcons
                    name="keyboard-arrow-up"
                    size={24}
                    color="black"
                  />
                )} */}
                   <MaterialIcons
                    name="keyboard-arrow-right"
                    size={24}
                    color="black"
                  />
              </div>
            ) : null}
          </td>
          {/* button 3 dor */}
          <td>
            {width > 768 ? (
             
              
              <div className="dropdown">
                <button
                  className="btn btn-white dropdown-toggle d-flex align-items-cetner"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <Entypo name="dots-three-vertical" size={14} color="#333" />
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  {/* <li>
                    <a className="dropdown-item" href="#">
                      <div className="cursor">
                       
                        <Link
                          to={"/categories/add_category/" + category.id +"/" +category.sector_id}
                          className=" d-flex align-items-center"
                        >
                          <MaterialIcons name="add" size={18} color="#333" />
                          <p className="mb-0 p-2">Add</p>
                        </Link>
                      </div>
                    </a>
                  </li> */}
                  <li>
                    <a className="dropdown-item" href="#">
                    
                      <div className="cursor d-flex align-items-center"
                              onClick={(event) => {
                                // let set_data = {
                                //   value: category,
                                // };
                                {console.log(category)}
                                let pass_data = {
                                  id: category.id,
                                  sector_id: category.sector_id,
                                  image: category.image,
                                  cat_name: category.name,
                                  parent_id:category.parent_id,
                                  slug : category.slug,
                                  level:props.level,
                             
                                };
                                SetCategoryModal({
                                  is_open: true,
                                  type: "edit",
                                  data: pass_data,
                                });
                              }}
                      >
                        {/* <Link
                          to={"/categories/edit_category/" + category.id}
                          className=" d-flex align-items-center"
                        > */}
                          <MaterialIcons name="edit" size={18} color="#333" />
                          <p className="mb-0 p-2">Edit</p>
                        {/* </Link> */}
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      <div
                        className="cursor d-flex align-items-center"
                        onClick={() => {
                          SetopenModal({
                            is_open: true,
                            data: {
                              id: category.id,
                            },
                          });
                        }}
                      >
                        <MaterialIcons
                          name="delete-outline"
                          size={18}
                          color="#333"
                        />
                        <p className="mb-0 p-2">Delete</p>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      <div
                        className="cursor d-flex align-items-center"
                        onClick={(event) => {
                          let set_data = {
                            value: category,
                          };
                          SetEditUserModal({
                            is_open: true,
                            type: "edit",
                            data: set_data,
                          });
                        }}
                      >
                        <MaterialIcons name="link" size={18} color="#333" />
                        <p className="mb-0 p-2">Link a Parent</p>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            
            ) : (
              <button
                className="btn btn-white d-flex align-items-cetner"
                type="button"
                onClick={() => {
                  SetActionSheetOpen({
                    is_open: true,
                    data: category,
                  });
                }}
              >
                <Entypo name="dots-three-vertical" size={14} color="#333" />
              </button>
            )}
          </td>
        </tr>
        {is_open
          ? category.children &&
            category.children.length > 0 && (
              <RenderCategories
                key={i}
                categoryArray={category.children}
                level={"child"}
              />
            )
          : null}
      </>
    ));
  };

  async function get_categories() {
console.log(selectedCategories)

    let response = await api("/orders/categories");
    if (response.status_code == 200) {

      const fetchedCategories = response.response.categories;
      setCategories(response.response.categories);
      SetOrgCategoryData(response.response.categories)
      if (cat_search_data != '') {
        const filter_array = response.response.categories.filter((ele) =>
          ele.name.toLowerCase().match(cat_search_data.toLowerCase()),
        )
        setCategories(filter_array)
      }
//       setSelectedCategories((prevSelected) => {
//         // Validate existing `selectedCategories` with the fetched data
//         const updatedSelected = prevSelected.filter((selected) =>
//           fetchedCategories.some((category) => category.id === selected.id)
//         );
// console.log(fetchedCategories)
//         // // Optionally, you can pre-select a default category (if needed)
//         // if (updatedSelected.length === 0 && fetchedCategories.length > 0) {
//         //   updatedSelected.push(fetchedCategories[0]); // Select the first category by default
//         // }

//         console.log("Updated Selected Categories:", updatedSelected);
//         return updatedSelected;
//       });
setSelectedCategories((prevSelected) => {
  const firstSelected = prevSelected[0]; 
  const filteredFirst = fetchedCategories.find(
    (category) => category.id === firstSelected?.id
  ); 
  console.log(firstSelected)
  console.log(filteredFirst)

 let secondSelected;
 let filteredSecond 
  if(firstSelected != null && filteredFirst!=undefined){
  secondSelected = prevSelected[1]; 
  filteredFirst.hasOwnProperty('children') &&(
  filteredSecond = filteredFirst.children.find(
    (category) => category.id === secondSelected?.id
  ) 
)
}
console.log(secondSelected)
let thirdSelected;
let filteredThird
if(secondSelected != null && filteredSecond!=undefined){
  thirdSelected = prevSelected[2];
  filteredSecond.hasOwnProperty('children') &&(
 filteredThird = filteredSecond.children.find(
  (category) => category.id === thirdSelected?.id
)
  )
}


// console.log(filteredFirst,filteredSecond,filteredThird)
  const updatedSelected = [filteredFirst,filteredSecond,filteredThird]; // Keep it or reset to empty

  console.log("Filtered Selected Categories:", updatedSelected);
  return updatedSelected;
});
console.log(selectedCategories)
      SetVisibleContent(true);

      if (response.response.hasOwnProperty("data")) {
      }
    }
  }

  return (
    <div className="">
      {/* Header */}
      <div className="head-bar row">
        <div className="col-lg-6">
          <h5 className="  mb-2">Categories</h5>
        </div>
        <div className="col-lg-6 pe-2 text-end d-flex">
        <div className="input-icons form-group p-2 me-2">
                <input
                  type="text"
                  className="form-control search-bar p-0"
                  placeholder="Search here..."
                  value={cat_search_data}
                  onChange={(e) => {
                    
                    SetCatSearchData(e.target.value)
                  }}
                />
                <div className="cursor px-2 border-left">
                  <Ionicons name="md-search" size={16} color="#ccc" />
                </div>
              </div>
          {/* <Link
            to={"/categories/add_category/0/0"}
            className="btn btn-prime btn-radius btn-sm px-3 ms-auto"
          >
            Add
          </Link> */}
        </div>
      </div>

      <div className="px-2 sm-mt-3 sm-pt-4">
        <div className="border-top fixed-sub-header">
          <div className="mt-3 px-md-3 px-sm-0">
            {visible_content ? (
              <div className="row">
              <div className="col-3">
                <div className="card p-2">
                  <div className="d-flex">
                <div className=""><h6 className="mb-0 pb-3">Category</h6></div>
                <div
            // to={"/categories/add_category/0/0"}
            className="btn btn-prime btn-radius btn-sm px-3 ms-auto"
            onClick={() => {
              let pass_data = {
                id: 0,
                sector_id: "",
                image: "",
              };
              SetCategoryModal({
                is_open: true,
                type: "add",
                data: pass_data,
              });
            }}
          >
            Add
          </div>
          </div>
                <div className="overflowY">

                  <table className="table  ">
                    <tbody>
                      <RenderCategories categoryArray={categories} level={"parent"} />
                    </tbody>
                  </table>
                </div>

                </div>
              </div>
              {/* {categoriesChild?.children?.length > 0 && (
                <div className="col-3">
                  <div className="card p-2">
                    <table className="table">
                      <tbody>
                        <RenderCategories categoryArray={categoriesChild.children} level={"child"} />
                      </tbody>
                    </table>
                  </div>
                </div>
              )} */}
                   {/* {selectedCategories.map((category, index) =>
                  category.children && category.children.length > 0 ? (
                    <div className="col-3" key={index}>
                      <div className="card p-2">
                        {console.log(category)}
                        <div className="d-flex">
                <div className=""><h6 className="mb-0 pb-3">{category.name}</h6></div>
                {console.log(image_data)}
                <div
                onClick={()=>{
                  console.log(image_data)
                  let pass_data={id:category.id,sector_id:category.sector_id,image:image_data}
                 
                   SetCategoryModal({
                    is_open: true,
                    type: "add",
                    data: {pass_data},
                  });
                }
                }
                          // to={"/categories/add_category/" + category.id +"/" +category.sector_id}
                         className="btn btn-prime btn-radius btn-sm px-3 ms-auto"
                        >
                         Add
                        </div>
                        </div>
                <div className="overflowY">

                      <table className="table">
                      <tbody>
                        <RenderCategories categoryArray={category.children} level={index + 1} />
                        </tbody>
                        </table> </div>
                    </div>
                    </div>

                    
                  ) : null
                )} */}
                {/* <div className="container"> */}
  {/* <div className="row"> */}
    {Array.from({ length: 3 }).map((_, index) => {
      const category = selectedCategories[index]; // Get category if it exists
console.log(category)
      return (
        <div className="col-3" key={index}>
          <div className="card p-2">
            {category ? (
              <>
                <div className="d-flex">
                  <div>
                    <h6 className="mb-0 pb-3">{category.name}</h6>
                  </div>
                  <div
                    onClick={() => {
                      let pass_data = {
                        id: category.id,
                        sector_id: category.sector_id,
                        image: "",
                      };
                      SetCategoryModal({
                        is_open: true,
                        type: "add",
                        data: pass_data,
                      });
                    }}
                    className="btn btn-prime btn-radius btn-sm px-3 ms-auto"
                  >
                    Add
                  </div>
                </div>
                <div className="overflowY">
                  <table className="table">
                    <tbody>
                    {category.children?.length > 0 ?
                      <RenderCategories
                        categoryArray={category.children}
                        level={index + 1}
                      /> :<p className="text-muted">no subcategory</p>

                    }
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              // Placeholder for an empty card
              <div className="text-center py-4">
                {/* <h6>No Sub Category</h6> */}
                <p className="text-muted">subcategories come here.</p>
              </div>
            )}
          </div>
        </div>
      );
    })}
  {/* </div> */}
{/* </div> */}
            </div>
            ) : (
              <div className="card p-2">
                <div className="">
                  <table className="table">
                    <tbody>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((ele) => (
                        <tr>
                          <td>
                            <Skeleton height={25} width={25} />
                          </td>
                          <td>
                            <Skeleton height={10} width={20} />
                          </td>
                          <td>
                            <Skeleton height={10} width={90} count={2} />
                          </td>
                          <td>
                            <Skeleton height={15} width={20} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <ModalDelete
        open={modal_delete.is_open}
        data={modal_delete.data}
        delete_type={modal_delete.delete_type}
        have_option={modal_delete.have_option}
        options={modal_delete.options}
        close={(data: any) => {
          SetModalDelete((prevValue: any) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });

          if (data.action == "save") {
          }
        }}
      />

      <ModalCreateCategory
        open={category_modal.is_open}
        sendMessageToParent={handleChildData}
        data={category_modal.data}
        type={category_modal.type}
        close={(data) => {
          SetCategoryModal({
            is_open: false,
            type: "add",
            data: {},
          });
          if (data.action == "save") {

            console.log(data)
            // get_categories();
            let send_data = {
              name:data.data.name,
              sector:data.data.sector,
            
              id:data.data.id,
              parent:data.data.parent,

              type: data.type,
              slug:data.data.slug,
              image:data.data.image,
            };
            // add_user();

            add_category(send_data)
          }
          if (data.action == "edit") {
            setCategories((prevValue) => {
              prevValue[data.index] = data.value;
              return [...prevValue];
            });
          }
        }}
      />
       {modal_image.is_open ? (
            <ModalAddGallery
              open={modal_image.is_open}
              type={modal_image.type}
              data={modal_image.data}
              selected={modal_image.selected_images}
              from={"create_outlet"}
              close={(data: any) => {
                SetModalImage((prevValue: any) => {
                  prevValue.is_open = false;
                  return { ...prevValue };
                });

                if (data.action == "save") {
                  console.log(data.value[0])
                  setImageData((prevValue: any) => {
                    prevValue.image = data.value[0].url;
                    prevValue.image_url = data.value[0].full_url;
                    prevValue.image_id = data.value[0].id;

                    return { ...prevValue };
                  });
                  SetCategoryModal((prevValue: any) => {
                    prevValue.data.image = data.value[0].full_url;
                    return { ...prevValue };
                  });
          
                }
              }}
            />
          ) : null}

      <ModalDeleteCategories
        open={openModal.is_open}
        data={openModal.data}
        close={(data: any) => {
          SetopenModal((prevValue: any) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });
          if (data.action == "save") {
            get_categories();
          }
        }}
      />

      <ModalEditCategory
        open={edit_user_modal.is_open}
        data={edit_user_modal.data}
        type={edit_user_modal.type}
        close={(data: any) => {
          SetEditUserModal({
            is_open: false,
            data: {},
            type: "",
            index: "",
          });
          if (data.action == "edit") {
            get_categories();
          }
        }}
      />

      {/* swipe sheet code */}

      {width < 768 ? (
        <SwipeableBottomSheet
          overflowHeight={0}
          style={{ zIndex: 1300 }}
          open={action_sheet_open.is_open}
          onClose={() => {
            SetActionSheetOpen({
              is_open: false,
              data: "",
            });
          }}
          onChange={() => {
            SetActionSheetOpen({
              is_open: false,
              data: "",
            });
          }}
        >
          <div className="bg-fff pb-3">
            <div className="bg-fff border-top-radius p-3 pb-1 center w-100">
              <div className="bottom-sheet-hr "></div>
            </div>

            <div className="mb-5">
              <div>
                <a className="dropdown-item" href="#">
                  <div className="cursor">
                    <Link
                      to={
                        "/categories/add_category/" + action_sheet_open.data.id
                      }
                      className=" d-flex align-items-center"
                    >
                      <MaterialIcons name="add" size={18} color="#333" />
                      <p className="mb-0 p-2">Add</p>
                    </Link>
                  </div>
                </a>
              </div>
              <div>
                <a className="dropdown-item" href="#">
                  <div
                    className="cursor d-flex align-items-center"
                    onClick={(event) => {
                      let set_data = {
                        value: action_sheet_open.data,
                      };
                      SetEditUserModal({
                        is_open: true,
                        type: "edit",
                        data: set_data,
                      });
                      SetActionSheetOpen({
                        is_open: false,
                        data: "",
                      });
                    }}
                  >
                    <Link
                      to={
                        "/categories/edit_category/" + action_sheet_open.data.id
                      }
                      className=" d-flex align-items-center"
                    >
                      <MaterialIcons name="edit" size={18} color="#333" />
                      <p className="mb-0 p-2">Edit</p>
                    </Link>
                  </div>
                </a>
              </div>
              <div>
                <a className="dropdown-item" href="#">
                  <div
                    className="cursor d-flex align-items-center"
                    onClick={() => {
                      SetopenModal({
                        is_open: true,
                        data: {
                          id: action_sheet_open.data.id,
                        },
                      });
                      SetActionSheetOpen({
                        is_open: false,
                        data: "",
                      });
                    }}
                  >
                    <MaterialIcons
                      name="delete-outline"
                      size={18}
                      color="#333"
                    />
                    <p className="mb-0 p-2">Delete</p>
                  </div>
                </a>
              </div>
              <div>
                <a className="dropdown-item" href="#">
                  <div
                    className="cursor d-flex align-items-center"
                    onClick={(event) => {
                      let set_data = {
                        value: action_sheet_open.data,
                      };
                      SetEditUserModal({
                        is_open: true,
                        type: "edit",
                        data: set_data,
                      });
                      SetActionSheetOpen({
                        is_open: false,
                        data: "",
                      });
                    }}
                  >
                    <MaterialIcons name="link" size={18} color="#333" />
                    <p className="mb-0 p-2">Link a Parent</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </SwipeableBottomSheet>
      ) : null}
    </div>
  );
}