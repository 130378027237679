import { AntDesign, FontAwesome, Ionicons  } from "@expo/vector-icons";
import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import DataContext from "../../utils/Context/DataContext";
import { BottomSheet } from "../BottomSheet/BottomSheet";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Entypo } from "@expo/vector-icons";
import { AppConfig } from "../../config";
import { AppHelper } from "../../utils/Helper";
import ImageContainer from "../ImageContainer/ImageContainer";
import SwipeableDrawer from "../Drawer/SwipeableDrawer";

export default function Footer(props: any) {
  const context: any = useContext(DataContext);
  const [id_value, SerIdValue] = useState(useParams());
  const [sidemenu_open, SetSideMenuOpen] = useState(true);
  const [bottom_sheet_is_loading, SetBottomSheetIsLoading] = useState(true);
  const swipeableDrawer: any = {
    settings: { freeMode: true },
    events: {},
    open: false,
    clickBottomOpen: false,
  };
  const [bottom_sheet_data, SetBottomSheetData]: any = useState({
    ...swipeableDrawer,
    customData: {
      subMenu: null,
      subMenuTitle: "",
      style: {
        zIndex: 2,
      },
    },
    settings: {
      breaks: {
        top: { enabled: true, height: window.screen.height },
        middle: { enabled: true, height: 180 },
        bottom: false,
      },
      backdrop: false,
      bottomClose: true,
    },
    overflowHeight: 0,
    data: {
      page: "main",
    },
  });
  const [is_loading, SetIsLoading] = useState(true);
  const [is_first_load, SetIsFirstLoad] = useState(true);
  const [props1BtnData, Set1BtnPropsData]: any = useState({
    ...swipeableDrawer,
    customData: {
      subMenu: null,
      subMenuTitle: "",
      style: {
        zIndex: 2,
      },
    },
    settings: {
      breaks: {
        top: { enabled: true, height: window.screen.height },
        middle: { enabled: true, height: 290 },
        // bottom: { enabled: false },
      },
      backdrop: true,
      backdropOpacity: 0,
      bottomClose: true,
    },
  });

  let section_data = [
    {
      id: 68,
      name: "Food",
      slug: "food",
      sector: "2",
      sector_name: "Resturant",
      url: "3fd44bb8c8c4ec8666c83e92f6e90b6f.png",
      order_type: "Vendors",
    },
    {
      id: 71,
      name: "Pickup Drop",
      slug: "pickupdrop",
      sector: "4",
      sector_name: "Pickup Drop",
      url: "ae17567a390713152eb322fc4f15c8d9.png",
      order_type: "Products",
    },
    {
      id: 69,
      name: "Groceries",
      slug: "groceries",
      sector: "1",
      sector_name: "Groceries",
      url: "2bd8ff574a746aa2195f1e5aa884be15.png",
      order_type: "Vendors",
    },
    {
      id: 70,
      name: "Nearby Stores",
      slug: "nearby-stores",
      sector: "3",
      sector_name: "Ecommerce",
      url: "ea060b2a62dd087a79cbbadc09c142e1.png",
      order_type: "Vendors",
    },
    {
      id: 67,
      name: "Market",
      slug: "market",
      sector: "3",
      sector_name: "Ecommerce",
      url: "ab72cd83c426dfb078fa9aa21eff38f0.png",
      order_type: "Products",
    },
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      SetIsLoading(false);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (props1BtnData.open) {
      context.SetAppData((prevValue: any) => {
        prevValue.scroll_locked++;
        return { ...prevValue };
      });
    } else {
      context.SetAppData((prevValue: any) => {
        if (prevValue.scroll_locked > 0) {
          prevValue.scroll_locked--;
        }
        return { ...prevValue };
      });
    }
  }, [props1BtnData.open]);

  function setHidden(value: any) {
    if (value == true) {
      console.log(document.body.style.overflow);
      if (document.body.style.overflow !== "hidden") {
        document.body.style.overflow = "hidden";
        console.log(
          "document.body.style.overflow",
          document.body.style.overflow
        );
        // infinite-scroll-component
      } else {
        document.body.style.overflow = "scroll";
        console.log(
          "document.body.style.overflow",
          document.body.style.overflow
        );
      }
    }
  }

  function close1IconDrawer() {
    Set1BtnPropsData({
      ...props1BtnData,
      open: false,
      customData: {
        subMenu: null,
        subMenuTitle: "",
      },
    });
  }

  return (
    <div>
      <div className="navfooter">
        {/* {bottom_sheet_data.open ? (
        <div
        // className="bottom-sheet-backOption-fixed"
        >
          {bottom_sheet_data.data.page == "earn" && (
            <div>
              <div
                className=" cursor bg-fff footer-back d-flex align-center"
                onClick={() => {
                  SetBottomSheetData((prevalue: any) => {
                    // prevalue.open = open;
                    // prevalue.overflowHeight = 210;
                    prevalue.data = {
                      page: "main",
                    };
                    return { ...prevalue };
                  });
                }}
              >
                <Entypo name="chevron-left" size={18} color="#343a40" />{" "}
                <p className="text-black fs-7">Earn</p>
              </div>
            </div>
          )}
          {bottom_sheet_data.data.page == "business_suite" && (
            <div>
              <div
                className="bottom cursor bg-fff footer-back d-flex align-center"
                onClick={() => {
                  SetBottomSheetData((prevalue: any) => {
                    // prevalue.open = open;
                    // prevalue.overflowHeight = 210;
                    prevalue.data = {
                      page: "main",
                    };
                    return { ...prevalue };
                  });
                }}
              >
                <Entypo name="chevron-left" size={18} color="#343a40" />{" "}
                <p className="text-black fs-7">Business Suite</p>
              </div>
            </div>
          )}
        </div>
      ) : null} */}
        {props1BtnData?.customData?.subMenu != null && (
          <div
            className="cursor bg-fff footer-back d-flex align-center"
            onClick={() => {
              Set1BtnPropsData((prevalue: any) => {
                prevalue.customData.subMenu = null;
                prevalue.customData.subMenuTitle = "";
                return { ...prevalue };
              });
            }}
          >
            <Entypo name="chevron-left" size={20} color="#343a40" />{" "}
            <p className="text-black fs-7">
              {props1BtnData.customData.subMenuTitle}
            </p>
          </div>
        )}
        <div className="bottom-tabs">
          {is_loading && is_first_load ? (
            <div className="row">
              <div className="col-2 center">
                <div className="">
                  <Skeleton height={20} width={20} />
                </div>
              </div>
              <div className="col-2 center">
                <div className="">
                  <Skeleton height={20} width={20} />
                </div>
              </div>

              <div className="col-2 center">
                <div className="">
                  <Skeleton height={25} width={25} borderRadius={50} />
                </div>
              </div>

              <div className="col-2 center">
                <div className="">
                  <Skeleton height={20} width={20} />
                </div>
              </div>

              <div className="col-2 center">
                <div className="">
                  <Skeleton height={20} width={20} />
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              {/* home */}
              <div
                className="col-2"
                onClick={() => {
                  props.menu_click(false);
                }}
              >
                <Link to="/">
                  <AntDesign name="home" size={20} color="#222" />
                </Link>
              </div>
              {/* setting */}
              <div className="col-2">
                {props.from == "outlet" ? (
                  <div
                    onClick={() => {
                      props.menu_click(false);
                    }}
                  >
                    <Link
                      className=" p-2 link-loader"
                      to={
                        "/outlet/" +
                        id_value.brand_id +
                        "/" +
                        id_value.outlet_id +
                        "/profile"
                      }
                    >
                      <FontAwesome name="user-o" size={20} color="#222" />
                    </Link>
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      props.menu_click(false);
                    }}
                  >
                    <Link
                      className=" p-2 link-loader"
                      to={"/brand/" + id_value.brand_id + "/account"}
                    >
                      <Ionicons
                        name="settings-outline"
                        size={20}
                        color="#222"
                      />
                    </Link>
                  </div>
                )}
              </div>
              {/* 1superapp */}
              <div
                className="col-2"
                onClick={() => {
                  console.log("1 icon click");
                  // SetBottomSheetData((prevalue: any) => {
                  //   // prevalue.open = true;
                  //   if (prevalue.overflowHeight > 0) {
                  //     prevalue.overflowHeight = 0;
                  //   } else {
                  //     prevalue.overflowHeight = 210;
                  //   }
                  //   return { ...prevalue };
                  // });
                  props.menu_click(false);
                  // SetBottomSheetData((prevalue: any) => {
                  //   console.log("SetBottomSheetData prevalue :", prevalue);
                  //   if (prevalue.open) {
                  //     window.location.href = "https://1superapp.com/dashboard";
                  //   } else {
                  //     // prevalue.open = true;
                  //     if (prevalue.overflowHeight > 0) {
                  //       prevalue.overflowHeight = 0;
                  //     } else {
                  //       prevalue.overflowHeight = 210;
                  //     }
                  //     prevalue.open = true;
                  //     // props.on_bottom_sheet_change(true);
                  //     prevalue.customData = {
                  //       subMenu: null,
                  //       subMenuTitle: "",
                  //       style: {
                  //         zIndex: 1032,
                  //       },
                  //     };
                  //   }
                  //   console.log("SetBottomSheetData return prevalue :", prevalue);
                  //   return { ...prevalue };
                  // });
                  // SetBottomSheetIsLoading(true);
                  // const timer = setTimeout(() => {
                  //   SetBottomSheetIsLoading(false);
                  // }, 800);
                  // setHidden(bottom_sheet_data.open);
                  if (props1BtnData.open) {
                    window.location.href = "https://1superapp.com/dashboard";
                  } else {
                    console.log("props1BtnData", props1BtnData);

                    Set1BtnPropsData((prevalue: any) => {
                      prevalue.open = true;
                      prevalue.settings.breaks.top.height = window.innerHeight;
                      prevalue.customData.subMenu = null;
                      prevalue.customData.subMenuTitle = "";
                      return { ...prevalue };
                    });
                    // props.on_bottom_sheet_change(true);
                  }
                  return () => clearTimeout(timer);
                  // SetPropsData({
                  //   ...propsData,
                  //   open: true,
                  //   customData: {
                  //     subMenu: null,
                  //     subMenuTitle: "",
                  //     style: {
                  //       zIndex: 1032,
                  //     },
                  //   },
                  // });
                }}
              >
                {/* <a
              className=" p-2 link-loader"
              href="https://1superapp.com/dashboard"
            > */}
                <img
                  src="https://1superapp.com/assets/images/1superapp-logo-plain.png"
                  width="25"
                />
                {/* </a> */}
              </div>
              {/* notification */}
              <div
                className="col-2"
                onClick={() => {
                  props.menu_click(false);
                }}
              >
                <a
                  className=" p-2 link-loader"
                  href="https://1studio.1superapp.com/notification/view"
                >
                  <Ionicons
                    name="notifications-outline"
                    size={20}
                    color="#222"
                  />
                </a>
              </div>
              {/* menu */}
              <div className="col-2">
                <div
                  className="sidebar-toggler navbar-toggler p-2 cursor"
                  onClick={() => {
                    // props.menu_click(true);
                    // SetSideMenuOpen(!sidemenu_open);
                    // return props.menu_click(sidemenu_open);
                    props.menu_click(!props.is_menu_open);
                  }}
                >
                  {props.is_menu_open ? (
                    <Ionicons name="ios-close" size={20} color="#222" />
                  ) : (
                    <Ionicons name="md-menu-sharp" size={20} color="#222" />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* bottom sheet */}
      {/* <BottomSheet
        title="weeee "
        subtitle="wwwweeeee"
        isDebugMode={false}
        isOpen={bottom_sheet_data.open}
        onClose={() => {
          // setIsOpen(false);
          SetBottomSheetData((prevalue: any) => {
            prevalue.open = false;
            prevalue.overflowHeight = 0;
            // prevalue.data = {
            //   page: "main",
            // };
            return { ...prevalue };
          });
          // props.on_bottom_sheet_change(false);
        }}
      >
        <div style={{ height: "260px", overflow: "hidden" }}>
          <div className=" drawer-fixed">
              <div
                className="w-100 center"
                onClick={() => {
                  // SetBottomSheetData((prevalue: any) => {
                  //   prevalue.open = false;
                  //   prevalue.overflowHeight = 0;
                  //   return { ...prevalue };
                  // });
                }}
              >
                <hr className="hr-drawer" />
              </div>
            </div>
          <div className="px-1 pt-3">
            <div className="content">
              {bottom_sheet_is_loading ? (
                <div className="dashboard-app-btns superapp_menu_content_home">
                  Skeleton Start
                  {[1, 2, 3, 4, 5, 6, 7, 8].map(() => (
                    <div className="dashboard-app-btn-col">
                      <div className="pt-2 center">
                        <Skeleton height={32} width={40} />
                      </div>

                      <div className="mt-2 center">
                        <Skeleton height={10} width={60} />
                      </div>
                    </div>
                  ))}
                  Skeleton end
                </div>
              ) : (
                <>
                  Pickup Drop / Blood /  Earn / Business Suite
                  {bottom_sheet_data.data.page == "main" && (
                    <div className="dashboard-app-btns superapp_menu_content_home">
                      {section_data?.map((sections: any, s_index: any) => (
                        <>
                          {(sections.sector == 1 ||
                            sections.sector == 2 ||
                            (sections.sector == 3 &&
                              sections.order_type == "Vendors") ||
                            sections.sector == 4) && (
                            <div className="dashboard-app-btn-col">
                              
                              <a
                                className="dashboard-app-btn"
                                href={
                                  "https://shop.1superapp.com/" + sections.slug
                                }
                              >
                                {sections.url != null ? (
                                  <ImageContainer
                                    src={AppHelper.imageSrc(
                                      "https://cdn2.1superapp.com/images/",
                                      sections.url,
                                      { format: "webp", width: 100 }
                                    )}
                                    parentClass=""
                                    thumb={AppHelper.imageSrc(
                                      context.app_data.image_base_url,
                                      sections.url,
                                      {
                                        format: "webp",
                                        width: 100,
                                        quality: 10,
                                      }
                                    )}
                                    class_name="img-fluid lazy-hw-40 pt-2"
                                    onIsVisible={() => {
                                      console.log(
                                        "ImageContainer On Is visible"
                                      );
                                    }}
                                    thumb_class_name="thumb-img-fluid lazy-hw-40 pt-2"
                                  />
                                ) : (
                                  <img
                                    src={
                                      AppConfig.CDN_Image_url +
                                      "87283f26129d8fb1d64900caf09f2be9.png"
                                    }
                                    className="img-fluid lazy-hw-40 pt-2"
                                  />
                                )}
                                <div className="mt-2 text-dark dashboard-app-btn-title">
                                  {sections.name}
                                </div>
                              </a>
                            </div>
                          )}
                        </>
                      ))}

                      Order Food
                      <div className="dashboard-app-btn-col">
                        <a
                          className="dashboard-app-btn link-loader lazy-img"
                          href="https://shop.1superapp.com/app/login?r=https://shop.1superapp.com/restaurant"
                        >
                          <img
                            className="img-fluid lazy-hw-40 pt-2"
                            width=""
                            src="https://cdn3.1superapp.com/superapp/assets/images/soup.svg"
                            // src={require("../../assets/img/soup-bowl.png")}
                          />
                          <div className="mt-2 text-dark dashboard-app-btn-title">
                            Food
                          </div>
                          <span className="beta-badge">Beta</span>
                        </a>
                      </div>

                      Pickup Drop
                      <div className="dashboard-app-btn-col">
                        <a
                          className="dashboard-app-btn link-loader lazy-img"
                          href="https://shop.1superapp.com/app/login?r=https://shop.1superapp.com/pickupdrop"
                        >
                          <img
                            className="img-fluid lazy-hw-40 pt-2"
                            width=""
                            src="https://cdn3.1superapp.com/superapp/assets/images/delivery-pickupdrop.png"
                          />
                          <div className="mt-2 text-dark dashboard-app-btn-title">
                            Pickup Drop
                          </div>
                          <span className="beta-badge">Beta</span>
                        </a>
                      </div>

                      Blood
                      <div className="dashboard-app-btn-col">
                        <a
                          className="dashboard-app-btn link-loader lazy-img"
                          href="https://1blood.1superapp.com/login"
                        >
                          <img
                            className="img-fluid lazy-hw-40"
                            width=""
                            src="https://cdn3.1superapp.com/superapp/assets/images/dashboard-1blood.png"
                          />
                          <div className="mt-2 text-dark dashboard-app-btn-title">
                            Blood
                          </div>
                        </a>
                      </div>

                      Events
                      <div className="dashboard-app-btn-col">
                        <a
                          className="dashboard-app-btn link-loader lazy-img"
                          href="https://events.1superapp.com"
                        >
                          <img
                            className="img-fluid lazy-hw-40"
                            width=""
                            src="https://cdn3.1superapp.com/superapp/assets/images/dance.png"
                          />
                          <div className="mt-2 text-dark dashboard-app-btn-title">
                            Events
                          </div>
                        </a>
                      </div>

                      Earn
                      <div className="dashboard-app-btn-col">
                        <a
                          className="dashboard-app-btn link-loader lazy-img"
                          onClick={() => {
                            SetBottomSheetData((prevalue: any) => {
                              prevalue.open = true;
                              prevalue.data = {
                                page: "earn",
                              };
                              return { ...prevalue };
                            });
                          }}
                        >
                          <img
                            className="img-fluid lazy-hw-40 pt-1"
                            width=""
                            src="https://cdn3.1superapp.com/superapp/assets/images/money-bill-wave-green.svg"
                          />
                          <div className="mt-2 text-dark dashboard-app-btn-title">
                            Earn
                          </div>
                        </a>
                      </div>

                      Business Suite
                      <div className="dashboard-app-btn-col">
                        <a
                          className="dashboard-app-btn superapp_menu_business lazy-img"
                          onClick={() => {
                            SetBottomSheetData((prevalue: any) => {
                              prevalue.open = true;
                              prevalue.data = {
                                page: "business_suite",
                              };
                              return { ...prevalue };
                            });
                          }}
                        >
                          <img
                            className="img-fluid lazy-hw-40 pt-2"
                            width=""
                            src="https://cdn3.1superapp.com/superapp/assets/images/briefcase.svg"
                          />
                          <div className="mt-2 text-dark dashboard-app-btn-title">
                            Business Suite
                          </div>
                        </a>
                      </div>

                      My orders
                      <div className="dashboard-app-btn-col">
                        <a
                          className="dashboard-app-btn link-loader lazy-img"
                          href="/order"
                        >
                          <img
                            src="https://cdn3.1superapp.com/superapp/assets/images/shopping-bag.png"
                            // src={require("../../assets/img/shopping-bag.png")}
                            className="img-fluid lazy-hw-40 pt-1"
                          />
                          <div className="mt-2 text-dark dashboard-app-btn-title">
                            My Orders
                          </div>
                        </a>
                      </div>

                      cart
                      <div className="dashboard-app-btn-col">
                        <a
                          className="dashboard-app-btn link-loader lazy-img"
                          href="/cart?v=all"
                        >
                          <img
                            src="https://cdn3.1superapp.com/superapp/assets/images/cart-shopping-fast.svg"
                            // src={require("../../assets/img/cart-shopping-fast.png")}

                            className="img-fluid lazy-hw-40 pt-1"
                          />
                          <div className="mt-2 text-dark dashboard-app-btn-title">
                            Cart
                          </div>
                        </a>
                      </div>
                    </div>
                  )}

                  Delivery Partner / Service Providers
                  {bottom_sheet_data.data.page == "earn" && (
                    <div className="dashboard-app-btns superapp_menu_content_earn">
                      Delivery Partner
                      <div className="dashboard-app-btn-col">
                        <a
                          className="dashboard-app-btn link-loader lazy-img"
                          href="https://deliverypartner.1superapp.com/app/login"
                        >
                          <img
                            className="img-fluid lazy-hw-40 pt-1"
                            width=""
                            src="https://cdn3.1superapp.com/superapp/assets/images/delivery-man.png"
                          />
                          <div className="mt-2 text-dark dashboard-app-btn-title">
                            Delivery Partner
                          </div>
                          <span className="beta-badge">Beta</span>
                        </a>
                      </div>

                      Service Providers
                      <div className="dashboard-app-btn-col">
                        <a
                          className="dashboard-app-btn link-loader"
                          href="https://serviceprovider.1superapp.com"
                        >
                          <img
                            className="img-fluid lazy-hw-40"
                            width=""
                            src="https://cdn3.1superapp.com/superapp/assets/images/dashboard-sp.png"
                          />
                          <div className="mt-2 text-dark dashboard-app-btn-title">
                            Service Providers
                          </div>
                        </a>
                      </div>
                    </div>
                  )}

                  Seller /Studio / Post Production
                  {bottom_sheet_data.data.page == "business_suite" && (
                    <div className="dashboard-app-btns superapp_menu_content_business">
                      Seller
                      <div className="dashboard-app-btn-col">
                        <a
                          className="dashboard-app-btn link-loader lazy-img"
                          href="https://sellers.1superapp.com/app/login"
                        >
                          <img
                            className="img-fluid lazy-hw-40 pt-1"
                            width=""
                            src="https://cdn3.1superapp.com/superapp/assets/images/seller.png"
                          />
                          <div className="mt-2 text-dark dashboard-app-btn-title">
                            Sellers
                          </div>
                          <span className="beta-badge">Beta</span>
                        </a>
                      </div>

                      Studio
                      <div className="dashboard-app-btn-col">
                        <a
                          className="dashboard-app-btn link-loader"
                          href="https://1studio.1superapp.com/dashboard"
                        >
                          <img
                            className="img-fluid lazy-hw-40"
                            width=""
                            src="https://cdn3.1superapp.com/superapp/assets/images/dashboard-studio.png"
                          />
                          <div className="mt-2 dashboard-app-btn-title">
                            Studio
                          </div>
                        </a>
                      </div>

                      Post Production
                      <div className="dashboard-app-btn-col">
                        <a
                          className="dashboard-app-btn link-loader"
                          href="https://postproduction.1superapp.com"
                        >
                          <img
                            className="img-fluid lazy-hw-40"
                            width=""
                            src="https://cdn3.1superapp.com/superapp/assets/images/dashboard-postproduction.png"
                          />
                          <div className="mt-2 dashboard-app-btn-title">
                            Post Production
                          </div>
                        </a>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </BottomSheet> */}
      <div className="swipeable-drawer-container-footer">
        <SwipeableDrawer
          refreshToken={AppHelper.randomStr(10)}
          propsData={props1BtnData}
          parentClass={".swipeable-drawer-container-footer"}
          drawerEvent={(data: any) => {
            if (data == "closed") {
              close1IconDrawer();
            }
          }}
        >
          <div className="swipeable-drawer-content btn-1drawer-content rounded-corner pb-3 px-1">
            <div className="bar btn-1drawer-bar"></div>
            <div>
              {/* Pickup Drop / Blood /  Earn / Business Suite*/}
              {props1BtnData?.customData?.subMenu == null && (
                <div className="dashboard-app-btns superapp_menu_content_home">
                  {section_data?.map((sections: any, s_index: any) => (
                    <>
                      {(sections.sector == 1 ||
                        sections.sector == 2 ||
                        (sections.sector == 3 &&
                          sections.order_type == "Vendors") ||
                        sections.sector == 4) && (
                        <div className="dashboard-app-btn-col">
                          {/* <Link
                              to={"https://shop.1superapp.com/" + sections.slug}
                              className="dashboard-app-btn"
                              key={s_index}
                              onClick={() => {
                                // if (
                                //   sections.sector == 1 ||
                                //   sections.sector == 2 ||
                                //   (sections.sector == 3 &&
                                //     sections.order_type == "Vendors") ||
                                //   sections.sector == 4
                                // ) {
                                //   SaveContext(sections);
                                // }
                                close1IconDrawer();
                              }}
                            > */}
                          <a
                            className="dashboard-app-btn"
                            href={"https://shop.1superapp.com/" + sections.slug}
                          >
                            {sections.url != null ? (
                              <ImageContainer
                                src={AppHelper.imageSrc(
                                  "https://cdn2.1superapp.com/images/",
                                  sections.url,
                                  { format: "webp", width: 100 }
                                )}
                                parentClass=""
                                thumb={AppHelper.imageSrc(
                                  context.app_data.image_base_url,
                                  sections.url,
                                  { format: "webp", width: 100, quality: 10 }
                                )}
                                class_name="img-fluid lazy-hw-40 pt-2"
                                onIsVisible={() => {
                                  console.log("ImageContainer On Is visible");
                                }}
                                thumb_class_name="thumb-img-fluid lazy-hw-40 pt-2"
                              />
                            ) : (
                              <img
                                src={
                                  AppConfig.CDN_Image_url +
                                  "87283f26129d8fb1d64900caf09f2be9.png"
                                }
                                className="img-fluid lazy-hw-40 pt-2"
                              />
                            )}
                            <div className="mt-2 text-dark dashboard-app-btn-title">
                              {sections.name}
                            </div>
                          </a>
                          {/* </Link> */}
                        </div>
                      )}
                    </>
                  ))}

                  {/* Order Food */}
                  {/* <div className="dashboard-app-btn-col">
                    <a
                      className="dashboard-app-btn"
                      href="https://shop.1superapp.com/app/login?r=https://shop.1superapp.com/food"
                    >
                      <ImageContainer
                        src={AppHelper.imageSrc(
                          "",
                          "https://cdn3.1superapp.com/superapp/assets/images/soup.svg"
                        )}
                        thumb={AppHelper.imageSrc(
                          "",
                          "https://cdn3.1superapp.com/superapp/assets/images/soup.svg"
                        )}
                        class_name="img-fluid lazy-hw-40 pt-2"
                        onIsVisible={() => {
                          console.log("ImageContainer On Is visible");
                        }}
                        thumb_class_name="thumb-img-fluid lazy-hw-40 pt-2"
                      />
                      <div className="mt-2 text-dark dashboard-app-btn-title">
                        Food
                      </div>
                      <span className="beta-badge">Beta</span>
                    </a>
                  </div> */}

                  {/* Pickup Drop */}
                  {/* <div className="dashboard-app-btn-col">
                    <a
                      className="dashboard-app-btn"
                      href="https://shop.1superapp.com/app/login?r=https://shop.1superapp.com/pickupdrop"
                    >
                      <ImageContainer
                        src={AppHelper.imageSrc(
                          "",
                          "https://cdn3.1superapp.com/superapp/assets/images/delivery-pickupdrop.png"
                        )}
                        thumb={AppHelper.imageSrc(
                          "",
                          "https://cdn3.1superapp.com/superapp/assets/images/delivery-pickupdrop.png"
                        )}
                        class_name="img-fluid lazy-hw-40 pt-2"
                        onIsVisible={() => {
                          console.log("ImageContainer On Is visible");
                        }}
                        thumb_class_name="thumb-img-fluid lazy-hw-40 pt-2"
                      />
                      <div className="mt-2 text-dark dashboard-app-btn-title">
                        Pickup Drop
                      </div>
                      <span className="beta-badge">Beta</span>
                    </a>
                  </div> */}

                  {/* Blood */}
                  <div className="dashboard-app-btn-col">
                    <a
                      className="dashboard-app-btn"
                      href="https://1blood.1superapp.com/login"
                    >
                      {/* <img
                          className="img-fluid lazy-hw-40"
                          width=""
                          src="https://cdn3.1superapp.com/superapp/assets/images/dashboard-1blood.png"
                        /> */}
                      <ImageContainer
                        src={AppHelper.imageSrc(
                          "",
                          "https://cdn3.1superapp.com/superapp/assets/images/dashboard-1blood.png"
                        )}
                        thumb={AppHelper.imageSrc(
                          "",
                          "https://cdn3.1superapp.com/superapp/assets/images/dashboard-1blood.png"
                        )}
                        class_name="img-fluid lazy-hw-40"
                        onIsVisible={() => {
                          console.log("ImageContainer On Is visible");
                        }}
                        thumb_class_name="thumb-img-fluid lazy-hw-40"
                      />
                      <div className="mt-2 text-dark dashboard-app-btn-title">
                        Blood
                      </div>
                    </a>
                  </div>

                  {/* Events */}
                  <div className="dashboard-app-btn-col">
                    <a
                      className="dashboard-app-btn"
                      href="https://events.1superapp.com"
                    >
                      {/* <img
                          className="img-fluid lazy-hw-40"
                          width=""
                          src="https://cdn3.1superapp.com/superapp/assets/images/dance.png"
                        /> */}
                      <ImageContainer
                        src={AppHelper.imageSrc(
                          "",
                          "https://cdn3.1superapp.com/superapp/assets/images/dance.png"
                        )}
                        thumb={AppHelper.imageSrc(
                          "",
                          "https://cdn3.1superapp.com/superapp/assets/images/dance.png"
                        )}
                        class_name="img-fluid lazy-hw-40"
                        onIsVisible={() => {
                          console.log("ImageContainer On Is visible");
                        }}
                        thumb_class_name="thumb-img-fluid lazy-hw-40"
                      />
                      <div className="mt-2 text-dark dashboard-app-btn-title">
                        Events
                      </div>
                    </a>
                  </div>

                  {/* Earn */}
                  <div className="dashboard-app-btn-col">
                    <a
                      className="dashboard-app-btn"
                      onClick={() => {
                        Set1BtnPropsData((prevalue: any) => {
                          prevalue.customData.subMenu = "earn";
                          prevalue.customData.subMenuTitle = "Earn";
                          return { ...prevalue };
                        });
                      }}
                    >
                      {/* <img
                          className="img-fluid lazy-hw-40 pt-1"
                          width=""
                          src="https://cdn3.1superapp.com/superapp/assets/images/money-bill-wave-green.svg"
                        /> */}
                      <ImageContainer
                        src={AppHelper.imageSrc(
                          "",
                          "https://cdn3.1superapp.com/superapp/assets/images/money-bill-wave-green.svg"
                        )}
                        thumb={AppHelper.imageSrc(
                          "",
                          "https://cdn3.1superapp.com/superapp/assets/images/money-bill-wave-green.svg"
                        )}
                        class_name="img-fluid lazy-hw-40 pt-1"
                        onIsVisible={() => {
                          console.log("ImageContainer On Is visible");
                        }}
                        thumb_class_name="thumb-img-fluid lazy-hw-40 pt-1"
                      />
                      <div className="mt-2 text-dark dashboard-app-btn-title">
                        Earn
                      </div>
                    </a>
                  </div>

                  {/* Business Suite */}
                  <div className="dashboard-app-btn-col">
                    <a
                      className="dashboard-app-btn superapp_menu_business"
                      onClick={() => {
                        Set1BtnPropsData((prevalue: any) => {
                          prevalue.customData.subMenu = "business_suite";
                          prevalue.customData.subMenuTitle = "Business Suite";
                          return { ...prevalue };
                        });
                      }}
                    >
                      {/* <img
                          className="img-fluid lazy-hw-40 pt-2"
                          width=""
                          src="https://cdn3.1superapp.com/superapp/assets/images/briefcase.svg"
                        /> */}
                      <ImageContainer
                        src={AppHelper.imageSrc(
                          "",
                          "https://cdn3.1superapp.com/superapp/assets/images/briefcase.svg"
                        )}
                        thumb={AppHelper.imageSrc(
                          "",
                          "https://cdn3.1superapp.com/superapp/assets/images/briefcase.svg"
                        )}
                        class_name="img-fluid lazy-hw-40 pt-2"
                        onIsVisible={() => {
                          console.log("ImageContainer On Is visible");
                        }}
                        thumb_class_name="thumb-img-fluid lazy-hw-40 pt-2"
                      />
                      <div className="mt-2 text-dark dashboard-app-btn-title">
                        Business Suite
                      </div>
                    </a>
                  </div>

                  {/* My orders */}
                  <div className="dashboard-app-btn-col">
                    <a
                      className="dashboard-app-btn"
                      href="https://shop.1superapp.com/order"
                    >
                      {/* <img
                          src="https://cdn3.1superapp.com/superapp/assets/images/shopping-bag.png"
                          // src={require("../../assets/img/shopping-bag.png")}
                          className="img-fluid lazy-hw-40 pt-1"
                        /> */}
                      <ImageContainer
                        src={AppHelper.imageSrc(
                          "",
                          "https://cdn3.1superapp.com/superapp/assets/images/shopping-bag.png"
                        )}
                        thumb={AppHelper.imageSrc(
                          "",
                          "https://cdn3.1superapp.com/superapp/assets/images/shopping-bag.png"
                        )}
                        class_name="img-fluid lazy-hw-40 pt-1"
                        onIsVisible={() => {
                          console.log("ImageContainer On Is visible");
                        }}
                        thumb_class_name="thumb-img-fluid lazy-hw-40 pt-1"
                      />
                      <div className="mt-2 text-dark dashboard-app-btn-title">
                        My Orders
                      </div>
                    </a>
                  </div>

                  {/* cart */}
                  <div className="dashboard-app-btn-col">
                    <a
                      className="dashboard-app-btn"
                      href="https://shop.1superapp.com/cart?v=all"
                    >
                      {/* <img
                          src="https://cdn3.1superapp.com/superapp/assets/images/cart-shopping-fast.svg"
                          // src={require("../../assets/img/cart-shopping-fast.png")}

                          className="img-fluid lazy-hw-40 pt-1"
                        /> */}
                      <ImageContainer
                        src={AppHelper.imageSrc(
                          "",
                          "https://cdn3.1superapp.com/superapp/assets/images/cart-shopping-fast.svg"
                        )}
                        thumb={AppHelper.imageSrc(
                          "",
                          "https://cdn3.1superapp.com/superapp/assets/images/cart-shopping-fast.svg"
                        )}
                        class_name="img-fluid lazy-hw-40 pt-1"
                        onIsVisible={() => {
                          console.log("ImageContainer On Is visible");
                        }}
                        thumb_class_name="thumb-img-fluid lazy-hw-40 pt-1"
                      />
                      <div className="mt-2 text-dark dashboard-app-btn-title">
                        Cart
                      </div>
                    </a>
                  </div>
                </div>
              )}

              {/* Delivery Partner / Service Providers */}
              {props1BtnData?.customData?.subMenu == "earn" && (
                <div className="dashboard-app-btns superapp_menu_content_earn">
                  {/* Delivery Partner */}
                  <div className="dashboard-app-btn-col">
                    <a
                      className="dashboard-app-btn"
                      href="https://deliverypartner.1superapp.com/app/login"
                    >
                      {/* <img
                          className="img-fluid lazy-hw-40 pt-1"
                          width=""
                          src="https://cdn3.1superapp.com/superapp/assets/images/delivery-man.png"
                        /> */}
                      <ImageContainer
                        src={AppHelper.imageSrc(
                          "",
                          "https://cdn3.1superapp.com/superapp/assets/images/delivery-man.png"
                        )}
                        thumb={AppHelper.imageSrc(
                          "",
                          "https://cdn3.1superapp.com/superapp/assets/images/delivery-man.png"
                        )}
                        thumb_class_name="thumb-img-fluid lazy-hw-40 pt-1"
                        class_name="img-fluid lazy-hw-40 pt-1"
                        onIsVisible={() => {
                          console.log("ImageContainer On Is visible");
                        }}
                      />
                      <div className="mt-2 text-dark dashboard-app-btn-title">
                        Delivery Partner
                      </div>
                      <span className="beta-badge">Beta</span>
                    </a>
                  </div>

                  {/* Service Providers */}
                  <div className="dashboard-app-btn-col">
                    <a
                      className="dashboard-app-btn link-loader"
                      href="https://serviceprovider.1superapp.com"
                    >
                      {/* <img
                          className="img-fluid lazy-hw-40"
                          width=""
                          src="https://cdn3.1superapp.com/superapp/assets/images/dashboard-sp.png"
                        /> */}
                      <ImageContainer
                        src={AppHelper.imageSrc(
                          "",
                          "https://cdn3.1superapp.com/superapp/assets/images/dashboard-sp.png"
                        )}
                        thumb={AppHelper.imageSrc(
                          "",
                          "https://cdn3.1superapp.com/superapp/assets/images/dashboard-sp.png"
                        )}
                        thumb_class_name="thumb-img-fluid lazy-hw-40"
                        class_name="img-fluid lazy-hw-40"
                        onIsVisible={() => {
                          console.log("ImageContainer On Is visible");
                        }}
                      />
                      <div className="mt-2 text-dark dashboard-app-btn-title">
                        Service Providers
                      </div>
                    </a>
                  </div>
                </div>
              )}

              {/* Seller /Studio / Post Production */}
              {props1BtnData?.customData?.subMenu == "business_suite" && (
                <div className="dashboard-app-btns superapp_menu_content_business">
                  {/* Seller */}
                  <div className="dashboard-app-btn-col">
                    <a
                      className="dashboard-app-btn"
                      href="https://sellers.1superapp.com/app/login"
                    >
                      {/* <img
                          className="img-fluid lazy-hw-40 pt-1"
                          width=""
                          src="https://cdn3.1superapp.com/superapp/assets/images/seller.png"
                        /> */}
                      <ImageContainer
                        src={AppHelper.imageSrc(
                          "",
                          "https://cdn3.1superapp.com/superapp/assets/images/seller.png"
                        )}
                        thumb={AppHelper.imageSrc(
                          "",
                          "https://cdn3.1superapp.com/superapp/assets/images/seller.png"
                        )}
                        thumb_class_name="thumb-img-fluid lazy-hw-40 pt-1"
                        class_name="img-fluid lazy-hw-40 pt-1"
                        onIsVisible={() => {
                          console.log("ImageContainer On Is visible");
                        }}
                      />
                      <div className="mt-2 text-dark dashboard-app-btn-title">
                        Sellers
                      </div>
                      <span className="beta-badge">Beta</span>
                    </a>
                  </div>

                  {/* Studio */}
                  <div className="dashboard-app-btn-col">
                    <a
                      className="dashboard-app-btn link-loader"
                      href="https://1studio.1superapp.com/dashboard"
                    >
                      {/* <img
                          className="img-fluid lazy-hw-40"
                          width=""
                          src="https://cdn3.1superapp.com/superapp/assets/images/dashboard-studio.png"
                        /> */}
                      <ImageContainer
                        src={AppHelper.imageSrc(
                          "",
                          "https://cdn3.1superapp.com/superapp/assets/images/dashboard-studio.png"
                        )}
                        thumb={AppHelper.imageSrc(
                          "",
                          "https://cdn3.1superapp.com/superapp/assets/images/dashboard-studio.png"
                        )}
                        thumb_class_name="thumb-img-fluid lazy-hw-40"
                        class_name="img-fluid lazy-hw-40"
                        onIsVisible={() => {
                          console.log("ImageContainer On Is visible");
                        }}
                      />
                      <div className="mt-2 dashboard-app-btn-title">Studio</div>
                    </a>
                  </div>

                  {/* Post Production */}
                  <div className="dashboard-app-btn-col">
                    <a
                      className="dashboard-app-btn link-loader"
                      href="https://postproduction.1superapp.com"
                    >
                      {/* <img
                          className="img-fluid lazy-hw-40"
                          width=""
                          src="https://cdn3.1superapp.com/superapp/assets/images/dashboard-postproduction.png"
                        /> */}
                      <ImageContainer
                        src={AppHelper.imageSrc(
                          "",
                          "https://cdn3.1superapp.com/superapp/assets/images/dashboard-postproduction.png"
                        )}
                        thumb={AppHelper.imageSrc(
                          "",
                          "https://cdn3.1superapp.com/superapp/assets/images/dashboard-postproduction.png"
                        )}
                        thumb_class_name="thumb-img-fluid lazy-hw-40"
                        class_name="img-fluid lazy-hw-40"
                        onIsVisible={() => {
                          console.log("ImageContainer On Is visible");
                        }}
                      />
                      <div className="mt-2 dashboard-app-btn-title">
                        Post Production
                      </div>
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </SwipeableDrawer>
      </div>
    </div>
  );
}

// import React, { useState, useEffect, useContext } from "react";
// import { Redirect, Link, useHistory } from "react-router-dom";
// import DataContext from "../../utils/Context/DataContext";
// import {
//     MaterialIcons,
//     FontAwesome5,
//     AntDesign,
//     Feather,
//     Ionicons,
//     Entypo,
//     Fontisto,
//     FontAwesome,
//     MaterialCommunityIcons,
// } from "@expo/vector-icons";
// import Modal from "@material-ui/core/Modal";
// import SideMenu from "../SideMenu/SideMenu";
// import OrderPage from "../../Pages/Common/OrderPage/OrderPage";
// import Cart from "../../Pages/Common/Cart/Cart";
// import Notifications from "../../Pages/Common/Notifications/Notifications";
// import OfferPage from "../../Pages/Common/OfferPage/OfferPage";
// import WishList from "../../Pages/Common/WishList/WishList";
// import Address from "../../Pages/Common/Address/Address";
// import Account from "../../Pages/Common/Account/Account";
// import { Badge } from "@material-ui/core";

// export default function Footer(props) {
//     const { t } = props;
//     const context = useContext(DataContext);
//     const [side_menu, SetSideMenu] = useState(false);
//     const [menu_type, SetMenuType] = useState("");
//     const [active_type, SetActiveType] = useState("home");
//     const [type, SetType] = useState("all");

//     useEffect(() => {
//

//         let url = window.location.pathname;
//         let domain = url.split("/");
//         let type_value = domain[domain.length - 1];
//         if (type_value == "") {
//             SetMenuType("sector");
//         }
//         if (type_value == "restaurant") {
//             SetMenuType("food");
//         }
//         if (type_value == "ecommerce") {
//             SetMenuType("ecommerce");
//         }
//         if (type_value == "grocery") {
//             SetMenuType("grocery");
//         }
//
//     }, []);

//     function onSelect(type) {
//
//         SetType(type);
//         window.location = type;
//     }

//     return (
//         <div>
//             {/* Mobile Footer */}
//             <div className="navfooter">
//                 <div className="bottom-tabs">
//                     <div className="row align-center">
//                         {/* Home icon */}
//                         <div className="col-2 cursor">
//                             {menu_type == "sector" || "category" ? (
//                                 <Link to="/">
//                                     <div className="home-menu">
//                                         <AntDesign name="home" size={21} color="#333" />
//                                     </div>
//                                 </Link>
//                             ) : (
//                                 <span>
//                                     {menu_type == "food" ? (
//                                         <Link
//                                             to="/restaurant"
//                                             onClick={() => {
//                                                 // active("home");
//                                                 SetActiveType("home");
//
//                                             }}
//                                         >
//                                             <div className="home-menu">
//                                                 {active_type == "home" ? (
//                                                     <AntDesign name="home" size={21} color="#333" />
//                                                 ) : (
//                                                     <AntDesign name="home" size={21} color="#333" />
//                                                 )}
//                                             </div>
//                                         </Link>
//                                     ) : null}

//                                     {menu_type == "ecommerce" ? (
//                                         <Link
//                                             to="/ecommerce"
//                                             onClick={() => {
//                                                 // active("home");
//                                                 SetActiveType("home");
//                                             }}
//                                         >
//                                             <div className="home-menu">
//                                                 {active_type == "home" ? (
//                                                     <AntDesign name="home" size={21} color="#333" />
//                                                 ) : (
//                                                     <AntDesign name="home" size={21} color="#333" />
//                                                 )}
//                                             </div>
//                                         </Link>
//                                     ) : (
//                                         ""
//                                     )}

//                                     {menu_type == "grocery" ? (
//                                         <Link
//                                             to="/grocery"
//                                             onClick={() => {
//                                                 SetActiveType("home");
//                                             }}
//                                         >
//                                             <div className="home-menu">
//                                                 {active_type == "home" ? (
//                                                     <AntDesign name="home" size={21} color="#333" />
//                                                 ) : (
//                                                     <AntDesign name="home" size={21} color="#333" />
//                                                 )}
//                                             </div>
//                                         </Link>
//                                     ) : (
//                                         ""
//                                     )}
//                                 </span>
//                             )}
//                         </div>

//                         {/* Category icon */}
//                         <div className="col-2 cursor">
//                             <div
//                                 className="flex border-right cursor"
//                                 onClick={() => {
//                                     // active("category");
//                                     SetActiveType("category");
//                                 }}
//                             >
//                                 <div className="category-menu">
//                                     {active_type == "category" ? (
//                                         <AntDesign name="appstore-o" size={21} color="#333" />
//                                     ) : (
//                                         <AntDesign name="appstore-o" size={21} color="#333" />
//                                     )}
//                                 </div>
//                             </div>

//                             {menu_type == "grocery" ? (
//                                 <Link
//                                     to="/grocery/grocery_category"
//                                     onClick={() => {
//                                         SetActiveType("category");
//                                     }}
//                                 >
//                                     <div className="category-menu">
//                                         {active_type == "category" ? (
//                                             <AntDesign name="appstore1" size={21} color="#333" />
//                                         ) : (
//                                             <AntDesign name="appstore-o" size={21} color="#333" />
//                                         )}
//                                     </div>
//                                 </Link>
//                             ) : (
//                                 ""
//                             )}

//                             {menu_type == "food" ? (
//                                 <Link
//                                     to="/grocery/grocery_category"
//                                     onClick={() => {
//                                         SetActiveType("category");
//                                     }}
//                                 >
//                                     <div className="category-menu">
//                                         {active_type == "category" ? (
//                                             <AntDesign name="appstore-o" size={21} color="#333" />
//                                         ) : (
//                                             <AntDesign name="appstore-o" size={21} color="#333" />
//                                         )}
//                                     </div>
//                                 </Link>
//                             ) : (
//                                 ""
//                             )}

//                             {menu_type == "ecommerce" ? (
//                                 <Link
//                                     to="/grocery/grocery_category"
//                                     onClick={() => {
//                                         SetActiveType("category");
//                                     }}
//                                 >
//                                     <div className="category-menu">
//                                         {active_type == "category" ? (
//                                             <AntDesign name="appstore-o" size={21} color="#333" />
//                                         ) : (
//                                             <AntDesign name="appstore-o" size={21} color="#333" />
//                                         )}
//                                     </div>
//                                 </Link>
//                             ) : (
//                                 ""
//                             )}
//                         </div>

//                         {/* 1Super icon */}
//                         <div className="col-2 cursor">
//                             <a href="https://1superapp.com/dashboard">
//                                 <img
//                                     src={require("../../assets/img/logo.png")}
//                                     style={{ width: 30, height: 30, margin: 5 }}
//                                     className="logo"
//                                 />
//                             </a>
//                         </div>

//                         {/* Notification icon */}
//                         <div className="col-2 cursor">
//                             <div className="notification-menu">
//                                 <Link
//                                     to="/"
//                                     onClick={() => {
//                                         // active("search");
//                                         SetActiveType("search");
//                                     }}
//                                 >
//                                     {/* {context.app_data.new_notifications > 0 ? (
//                     <Badge
//                       color="error"
//                       badgeContent={context.app_data.new_notifications}
//                       className="cursor mr-3"
//                     >
//                       <Ionicons
//                         name="md-notifications-outline"
//                         size={21}
//                         color="#333"
//                       />
//                     </Badge>
//                   ) : (
//                     <Ionicons
//                       name="md-notifications-outline"
//                       size={21}
//                       color="#333"
//                     />
//                   )} */}

//                                     <Badge
//                                         color={
//                                             context.app_data.new_notifications > 0 ? "error" : ""
//                                         }
//                                         badgeContent={
//                                             context.app_data.new_notifications > 0
//                                                 ? context.app_data.new_notifications
//                                                 : ""
//                                         }
//                                         className="cursor mr-3"
//                                     >
//                                         <Ionicons
//                                             name="md-notifications-outline"
//                                             size={21}
//                                             color="#333"
//                                         />
//                                     </Badge>

//                                     {/* {active_type == 'search' ? (
//                     <Ionicons
//                       name="md-notifications-outline"
//                       size={21}
//                       color="#333"
//                     />
//                   ) : (
//                     <Ionicons
//                       name="md-notifications-outline"
//                       size={21}
//                       color="#333"
//                     />
//                   )} */}
//                                 </Link>
//                             </div>
//                         </div>

//                         {/* Menu icon */}
//                         <div className="col-2 cursor">
//                             <div
//                                 className="md-none cursor pr-1"
//                                 onClick={() => {
//                                     SetSideMenu(true);
//                                 }}
//                             >
//                                 <Feather name="menu" size={20} color="black" />
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>

//             {/* sidemenu modal */}
//             <Modal
//                 open={side_menu}
//                 className="menu-scroll"
//             // onClose={sidemenuClose}
//             >
//                 <div className="flex">
//                     <div className="w-75">
//                         {/* <SideMenu
//                             from="mobile_header"
//                             onSelect={(data) => {
//
//                                 let path_value = "";
//                                 // if (data == 'all') {
//
//                                 //   path_value = '/sidemenuopen/all'
//                                 // } else if (data == 'cart') {
//                                 //   path_value = '/cart'
//                                 // } else if (data == 'order') {
//                                 //   path_value = '/order'
//                                 // } else if (data == 'notifications') {
//                                 //   path_value = '/notification'
//                                 // } else if (data == 'offer') {
//                                 //   path_value = '/offer'
//                                 // } else if (data == 'wishlist') {
//                                 //   path_value = '/wishlist'
//                                 // } else if (data == 'seller') {
//                                 //   path_value = '/seller'
//                                 // } else if (data == 'service') {
//                                 //   path_value = '/service'
//                                 // } else if (data == 'terms') {
//                                 //   path_value = '/terms'
//                                 // } else if (data == 'sector') {
//                                 //   path_value = '/'
//                                 // } else if (data == 'account') {
//                                 //   path_value = '/account'
//                                 // } else if (data == 'address') {
//                                 //   path_value = '/address'
//                                 // } else if (data == 'login') {
//                                 //   path_value = '/signup'
//                                 // } else if (data == 'logout') {
//                                 //   alert('Do you want to Logout!')
//                                 // }
//                                 // onSelect(path_value)
//                                 SetSideMenu(false);
//                             }}
//                             type={menu_type}
//                         /> */}
//                         <SideMenu
//                             active={true}
//                             menu_click={(data) => {
//                                 on_menu_click(data);
//                             }}
//                         />
//                     </div>
//                     <div className="text-right p-4 w-25">
//                         <Ionicons
//                             name="ios-close"
//                             size={30}
//                             color="white"
//                             onClick={() => {
//                                 SetSideMenu(false);
//                             }}
//                         />
//                     </div>
//                 </div>
//             </Modal>
//         </div>
//     );
// }
