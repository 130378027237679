import React, { useState, useEffect } from "react";
import bootstrap from "../../assets/libraries/bootstrap/js/bootstrap";

export default function ModalRefund(props: any) {

  const [close_data, SetCloseData] = useState({
    action: "close",
  });
  let myModal: any;

  const [data, SetData] = useState([]);
  const [refund_data, SetRefundData] = useState([]);

  useEffect(() => {
    let myModal1 = document.getElementById("AddServiceModal");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      SetData(props.data);

      myModal = new bootstrap.Modal(document.getElementById("refundModal"), {});
      myModal.show();
    }
  }, [props.open]);

  function Refund(ele: any) {
    SetRefundData(ele);
  }
  function Back() {
    SetRefundData([]);
  }

  return (
    <div
      className="modal fade"
      id="refundModal"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="  modal-title" id="ModalLabel">
              {refund_data.length == 0 ? (
                "Refund Request"
              ) : (
                <>
                  <i
                    class="fa fa-arrow-left me-2"
                    aria-hidden="true"
                    onClick={() => {
                      Back();
                    }}
                  ></i>
                  Refunds
                </>
              )}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            {refund_data.length == 0 && (
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">amount</th>
                    <th scope="col">created_user</th>
                    <th scope="col">deleted</th>
                    <th scope="col">deleted_time</th>
                    <th scope="col">deleted_user</th>
                    <th scope="col">id</th>
                    <th scope="col">reason</th>
                    <th scope="col">refunded</th>
                    <th scope="col">refunded_time</th>
                    <th scope="col">refunded_user</th>

                    <th scope="col">requested_time</th>
                    <th scope="col">timeout</th>
                    <th scope="col">timeout_time</th>
                    <th scope="col">timeout_user</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((ele, i) => (
                    <tr>
                      <td>{ele?.amount}</td>
                      <td>{ele.created_user.name}</td>
                      <td>{ele?.deleted}</td>
                      <td>{ele.deleted_time}</td>
                      <td>{ele.deleted_user.name}</td>
                      <td>{ele.id}</td>
                      <td>{ele.reason}</td>
                      <td>{ele.refunded}</td>
                      <td>{ele?.refunded_time}</td>
                      <td>{ele?.refunded_user.name}</td>
                      <td>{ele?.requested_time}</td>
                      <td>{ele?.timeout}</td>
                      <td>{ele?.timeout_time}</td>

                      <td>
                        <button
                          className="btn-primary"
                          onClick={() => {
                            Refund(ele?.refunds);
                          }}
                        >
                          Refund
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}

            {refund_data.length > 0 && (
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">amount</th>
                    <th scope="col">created_user</th>
                    <th scope="col">deleted</th>
                    <th scope="col">deleted_time</th>
                    <th scope="col">deleted_user</th>
                    <th scope="col">id</th>
                    <th scope="col">phonepe_response_data</th>
                    <th scope="col">phonepe_transaction_id</th>
                    <th scope="col">status</th>
                    <th scope="col">status_updated_time</th>

                    <th scope="col">time</th>

                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {refund_data?.map((ele, i) => (
                    <tr>
                      <td>{ele?.amount}</td>
                      <td>{ele.created_user.name}</td>
                      <td>{ele?.deleted}</td>
                      <td>{ele.deleted_time}</td>
                      <td>{ele.deleted_user.name}</td>
                      <td>{ele.id}</td>
                      <td>{ele.phonepe_response_data}</td>
                      <td>{ele.phonepe_transaction_id}</td>
                      <td>{ele?.status}</td>
                      <td>{ele?.status_updated_time}</td>
                      <td>{ele?.time}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
