
import React, { useState, useEffect, useContext } from "react";

import InfiniteScroll from "react-infinite-scroll-component";
import { api } from "../../utils/Api";
import Skeleton from "react-loading-skeleton";
import { Ionicons, FontAwesome } from "@expo/vector-icons";

import ModalCart from "./ModalCart";




function CartDetails() {
  const [visible_content, SetVisibleContent] = useState(false);
  const [filter_data, setFilterData] = useState("");
  const [cart_data, SetCartData] = useState([]);
  const [page_no, SetPageNo] = useState(1);
  const [order_status, SetOrderStatus] = useState("all");
  const [all, SetAll] = useState(true);
  const [pending, SetPending] = useState(false);
  const [process, SetProcess] = useState(false);
  const [shipped, SetShipped] = useState(false);
  const [loader, setLoader] = useState(false);

  const [cart_modal, setCartModal] = useState({
    is_open: false,
    data: {},
  });
  const [next_page, SetNextPage] = useState(false);
  const Spinner = () => <div className="loader-spinner"></div>;

  // const handleCall = (e,fullMobile) => {
  //   e.stopPropagation()
  //   if (fullMobile) {
  //     window.location.href = `tel:${fullMobile}`;
  //   }
  // };



  useEffect(() => {
    let pass_data = {
      order_status: "all",
      seller_brand_id: "",
      seller_outlet_id: "",
      sort: "",
      page_no: 1,
    };
    get_cart_details(pass_data, filter_data);
  }, []);


  async function get_cart_details(get_data: any, search: any) {

    if (!get_data.load_more && !get_data.refresh) {
      SetVisibleContent(false);
    }

    {
      get_data.refresh &&
        setLoader(true)
    }
    let pass_data = {
      get: {
        filter: get_data.order_status,
        page: get_data.page_no,

      },
    };
    let response: any = await api("/orders/get_cart_products", pass_data);
    console.log(response)
    if (response.status_code == 200) {
      setLoader(false)


      SetVisibleContent(true);

      if (response.response.hasOwnProperty("data")) {
        if (get_data.load_more) {
          SetCartData((prevValue) => {
            response?.response?.data?.map((ele: any) => {
              prevValue.push(ele);
            });
            return [...prevValue];
          });
        } else {
          SetCartData(response.response.data);

        }
      }
      // if(!get_data.hasOwnProperty("type")){
      if (response.response.hasOwnProperty("next_page")) {
        SetNextPage(response.response.next_page);
        SetPageNo((prevalue) => {
          prevalue = prevalue + 1;
          return prevalue;
        });
      }
      // }
    }
  }


  const OrderSkeleton = () => {
    return (
      <div className="card p-3 cursor">
        <div className="row align-items-center">
          <div className="col-6 pb-3">
            <Skeleton height={10} width={50} />
          </div>
          <div className="col-6 text-end">
            <Skeleton height={30} width={100} />
          </div>
        </div>

        <div className="row pt-2">
          <div className="col-6 pb-2">
            <Skeleton height={10} width={50} />
            <Skeleton height={10} width={120} />
          </div>
          <div className="col-6 pb-2 text-end">
            <Skeleton height={10} width={120} />
            <Skeleton height={10} width={100} />
          </div>
        </div>

        <div className="pb-3 pt-2">
          <hr className="text-gray m-0" />
        </div>

        <div className=" pb-2">
          <Skeleton height={10} width={120} />
        </div>

        <div className="py-2">
          <div className="d-flex pb-3">
            <Skeleton height={18} width={18} />
            <div className="ps-2">
              <Skeleton height={10} width={120} />
              <Skeleton height={10} width={180} />
            </div>
          </div>
          <div className="d-flex pb-3">
            <Skeleton height={18} width={18} />
            <div className="ps-2">
              <Skeleton height={10} width={120} />
              <Skeleton height={10} width={180} />
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="">
      {/* Header */}
      <div className="head-bar row">
        <div className="col-md-6">
          <h5 className="  mb-2">Product Cart Details</h5>
        </div>
        {/* <div className="col-md-6 col-12">
        <div className="input-icons form-group p-2">
          <input
            type="text"
            className="form-control search-bar p-0"
            placeholder="Search here ..."
            onChange={handleInputChange}
          />
          <div className="cursor px-2 border-left">
            <Ionicons name="md-search" size={16} color="#ccc" />
          </div>
        </div>
      </div> */}

        <div className="mt-1 d-flex align-items-center bg-fff p-2 md-px-3 ">
          <div className="d-flex align-items-center scrollX">
            <button
              type="button"
              className={
                all
                  ? "btn btn-text header-tap me-1 px-3 "
                  : "btn btn-text text-black me-1 px-3"
              }
              onClick={() => {
                SetAll(!all);
                SetPending(false);
                SetProcess(false);
                SetShipped(false);

                SetOrderStatus("all");
                SetPageNo(1);
                let pass_data = {
                  order_status: 1,
                  seller_brand_id: "",
                  seller_outlet_id: "",
                  sort: "",
                  page_no: 1,
                  type: ""
                };
                get_cart_details(pass_data, filter_data);
              }}
            >
              All
            </button>

            <button
              type="button"
              className={
                pending
                  ? "btn btn-text header-tap me-1 px-3 "
                  : "btn btn-text text-black me-1 px-3"
              }
              onClick={() => {
                SetAll(false);
                SetPending(!pending);
                SetProcess(false);
                SetShipped(false);

                SetOrderStatus("abandoned");
                SetPageNo(1);
                let pass_data = {
                  order_status: "abandoned",
                  seller_brand_id: "",
                  seller_outlet_id: "",
                  sort: "",
                  page_no: 1,
                  type: "",
                };
                get_cart_details(pass_data, filter_data);
              }}
            >
              Abandoned
            </button>

            <button
              type="button"
              className={
                process
                  ? "btn btn-text header-tap me-1 px-3 "
                  : "btn btn-text text-black me-1 px-3"
              }
              onClick={() => {
                SetAll(false);
                SetProcess(!process);
                SetShipped(false);
                SetOrderStatus("order_converted");
                SetPageNo(1);

                SetPending(false);
                let pass_data = {
                  order_status: "order_converted",
                  seller_brand_id: "",
                  seller_outlet_id: "",
                  sort: "",
                  page_no: 1,
                  type: "",
                };
                get_cart_details(pass_data, filter_data);


              }}
            >
              Order Converted
            </button>
            {loader ?
              <button className="ms-auto btn btn-white dropdown-toggle d-flex align-items-cetner">
                <Spinner />
              </button> :
              <button className="ms-auto btn btn-white dropdown-toggle d-flex align-items-cetner"
                onClick={() => {


                  let pass_data = {
                    order_status: order_status,
                    seller_brand_id: "",
                    seller_outlet_id: "",
                    sort: "",
                    page_no: 1,
                    refresh: true,

                  };

                  SetPageNo(1)
                  get_cart_details(pass_data, filter_data);

                }}>
                <img
                  src={require("../../assets/icon/refresh.png")}
                  className="icon-20px"
                /></button>
            }

          </div>
          {/* filter */}
          {/* <div className="dropdown">
          <button
            className="btn btn-white dropdown-toggle d-flex align-items-cetner"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img
              src={require("../../assets/icon/filter.png")}
              className="icon-14px"
            />
            <p className="mb-0 text-dark d-md-block d-sm-none ps-2">Filter</p>
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <a className="dropdown-item" href="#">
                <div
                  onClick={() => {
                    SetAll(false);
              
                    SetPending(false);
                    SetProcess(false);
                    SetShipped(false);
                    SetOrderStatus(5);
                    SetPageNo(1);
                    let pass_data = {
                      order_status: 5,
                      seller_brand_id: "",
                      seller_outlet_id: "",
                      sort: "",
                      page_no: 1,
                    };
                    get_cart_details(pass_data, filter_data);
                  }}
                >
                  <p className="mb-0 p-2">Accepted</p>
                </div>
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#">
                <div
                  onClick={() => {
                    SetAll(false);
              
                    SetPending(false);
                    SetProcess(false);
                    SetShipped(false);
                    SetOrderStatus(6);
                    SetPageNo(1);
                    let pass_data = {
                      order_status: 6,
                      seller_brand_id: "",
                      seller_outlet_id: "",
                      sort: "",
                      page_no: 1,
                    };
                    get_cart_details(pass_data, filter_data);
                  }}
                >
                  <p className="mb-0 p-2">Rejected</p>
                </div>
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#">
                <div
                  onClick={() => {
                    SetAll(false);
                   
                    SetPending(false);
                    SetProcess(false);
                    SetShipped(false);
                    SetOrderStatus(7);
                    SetPageNo(1);
                    let pass_data = {
                      order_status: 7,
                      seller_brand_id: "",
                      seller_outlet_id: "",
                      sort: "",
                      page_no: 1,
                    };
                    get_cart_details(pass_data, filter_data);
                  }}
                >
                  <p className="mb-0 p-2">Active</p>
                </div>
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#">
                <div
                  onClick={() => {
                    SetAll(false);
             
                    SetPending(false);
                    SetProcess(false);
                    SetShipped(false);
                    SetOrderStatus(8);
                    SetPageNo(1);
                    let pass_data = {
                      order_status: 8,
                      seller_brand_id: "",
                      seller_outlet_id: "",
                      sort: "",
                      page_no: 1,
                    };
                    get_cart_details(pass_data, filter_data);
                  }}
                >
                  <p className="mb-0 p-2">Inavtive</p>
                </div>
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#">
                <div
                  onClick={() => {
                    SetAll(false);
              
                    SetPending(false);
                    SetProcess(false);
                    SetShipped(false);
                    SetOrderStatus(9);
                    SetPageNo(1);
                    let pass_data = {
                      order_status: 9,
                      seller_brand_id: "",
                      seller_outlet_id: "",
                      sort: "",
                      page_no: 1,
                    };
                    get_cart_details(pass_data, filter_data);
                  }}
                >
                  <p className="mb-0 p-2">Success</p>
                </div>
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#">
                <div
                  onClick={() => {
                    SetAll(false);
                 
                    SetPending(false);
                    SetProcess(false);
                    SetShipped(false);
                    SetOrderStatus(10);
                    SetPageNo(1);
                    let pass_data = {
                      order_status: 10,
                      seller_brand_id: "",
                      seller_outlet_id: "",
                      sort: "",
                      page_no: 1,
                    };
                    get_cart_details(pass_data, filter_data);
                  }}
                >
                  <p className="mb-0 p-2">Failed</p>
                </div>
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#">
                <div
                  onClick={() => {
                    SetAll(false);
                
                    SetPending(false);
                    SetProcess(false);
                    SetShipped(false);
                    SetOrderStatus(11);
                    SetPageNo(1);
                    let pass_data = {
                      order_status: 11,
                      seller_brand_id: "",
                      seller_outlet_id: "",
                      sort: "",
                      page_no: 1,
                    };
                    get_cart_details(pass_data, filter_data);
                  }}
                >
                  <p className="mb-0 p-2">Cancelled</p>
                </div>
              </a>
            </li>
          </ul>
        </div> */}
        </div>
      </div>

      {/* body */}
      <div className="px-3 sm-mt-2 sm-pt-4">
        <div className="mt-4">
          {console.log(page_no)}
          <InfiniteScroll
            dataLength={cart_data.length}
            next={() => {
              let pass_data = {
                order_status: order_status,

                seller_brand_id: "",
                seller_outlet_id: "",
                sort: "",
                load_more: true,

                page_no: page_no,
              };
              get_cart_details(pass_data, filter_data);
            }}
            hasMore={next_page}
            loader={
              <div className="center m-5">
                <div className="lds-dual-ring"></div>
                <p className=" small ps-2">Just a second ...</p>
              </div>
            }
          >
            {
              visible_content ? (
                cart_data.length != 0 ? (
                  <div className="row">
                    {cart_data?.map((ord_ele: any, ord_index: any) => (
                      <div className="col-md-4 col-12 mb-3" key={ord_index}>
                        {/* <Link to={"/orders/" + ord_ele.id}> */}


                        <div className="card p-3 cursor">
                          {/* <div className="row align-items-center">
                          <div className="col-6 pb-3">
                            <p className="text-dark fw-bold">
                              {ord_ele.sector}
                            </p>
                          </div>
                          <div className="col-6 end pb-3">
                            <button
                              className="btn btn-prime btn-sm"
                           
                            >
                              View Details
                            </button>
                          </div>
                        </div> */}
                          <div className="row">
                            <div className="col-6 pb-2">
                              <p className="text-dark fw-500">#{ord_ele.id}</p>
                            </div>
                            <div className="col-6 end pb-2">
                              <p className="extra-small">{ord_ele.date}</p>
                            </div>
                            <div className="col-6 pb-2 d-flex align-items-center">
                              {/* <img
                              src={require("../../assets/icon/scooter.png")}
                              className="icon-14px"
                            />
                            <p className="ps-2 text-gray">
                              {ord_ele.delivery_type}
                            </p> */}
                              <div className=" pb-2">


                                <div className="d-flex">
                                  {ord_ele.hasOwnProperty("customer") && ord_ele.customer.hasOwnProperty("profile") && ord_ele.customer.profile != null
                                    ? (
                                      <div className="pe-2">
                                        <img
                                          src={ord_ele.customer.profile}
                                          alt="16.a1d4f3f3"
                                          className="rounded-float-start"
                                          height="34"
                                          width="34"
                                          style={{ borderRadius: "50px" }}
                                        ></img>
                                      </div>
                                    ) : (
                                      <div className="pe-2">
                                        <img
                                          src={require("../../assets/icon/avatar.png")}
                                          className="rounded-float-start"
                                          height="34"
                                          width="34"
                                        />
                                      </div>
                                    )}
                                  <div className="">
                                    <p className="text-dark fw-bold fs-7">
                                      {ord_ele?.customer?.name}
                                    </p>
                                    <p>{ord_ele?.customer?.username}</p>
                                    <div className="d-flex">
                                      
                                      <p>{ord_ele?.customer?.mobile}   </p>



                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-6 text-end pb-2">
                              <div>{ord_ele?.customer?.gender}</div>
                              {/* <FontAwesome
                              name="circle"
                              size={10}
                              color={
                         
                                ord_ele.status_color
                              }
                            /> */}


                              {/* <div className="call-btn m-2" onClick={(e)=>handleCall(e,ord_ele?.customer?.full_mobile)} style={{ cursor: 'pointer' }}>
        <Ionicons
          name="md-call"
          size={18}
          color="#A80032"
        />
      </div> */}
                              <p


                                className={
                                  ord_ele.status == "Abandoned" ? "text-orange v-small ps-0" : ord_ele.status == "Removed"
                                    ? "text-grey v-small ps-0"
                                    : ord_ele.status ==
                                    "Order Placed"
                                    && "text-green v-small ps-0"



                                }
                              >
                                {ord_ele.status}
                              </p>
                            </div>
                          </div>

                              <div className="mb-2">
                              {ord_ele.hasOwnProperty("customer") &&
                                        <a href={"tel:" + ord_ele?.customer?.mobile} className="btn btn-primary w-100">

                                          <Ionicons
                                            name="md-call"
                                            size={15}
                                            color="white"
                                          />
                                          <span className="ms-1">Call</span>
                                        </a>
                                      }
                              </div>

                          {(ord_ele?.statistics?.previously_ordered) && (
                            <div className="badge-2 text-success">Last Order Time: {ord_ele?.statistics?.previously_ordered}</div>
                          )}

                          {/* <div className="pb-3 ">
                            <hr className="text-gray m-0" />
                          </div> */}

                          <div>

                            <div className="text-dark pb-1"> {ord_ele.brand_name}</div>
                            <div className="text-grey pb-1">{ord_ele.outlet_name}</div>
                            <div className="text-dark pb-1">{ord_ele.quantity} x {ord_ele.product_name}</div>
                            {ord_ele.hasOwnProperty("product_description") &&

                              <div>{ord_ele.product_description}</div>}
                            <div className="text-dark pb-1">{ord_ele.product_group_name}</div>
                            {ord_ele.hasOwnProperty("product_group_description") &&

                              <div> {ord_ele.product_group_description}</div>
                            }

                            {ord_ele?.addons.length > 0 &&
                              <div className="text-dark pb-1">Addons : {ord_ele?.addons.map((ele) => (<span className="fw-300 fs-16">{ele.addon_name}  {" "}</span>))} </div>
                            }

                            <div className="text-dark pb-1">Total : <span className="fw-300 fs-16">₹</span> {ord_ele.total_amount_payable}</div>
                            {ord_ele?.ecommerce_cart_response.length > 0 &&
                              <div>
                                <hr />
                                <b> Ecommerce Cart Response:{" "}</b>
                                <ul>
                                  <div>{ord_ele?.ecommerce_cart_response.map((ele, i) => (

                                    <li>{ele.response}<p>{ele.notes}</p><p>{ele.create_time}</p></li>
                                  ))}</div>
                                </ul>
                              </div>
                            }
                          </div>


                          {ord_ele.hasOwnProperty("customer") &&
                            (ord_ele.ecommerce_cart_response.length > 0 ?

                              <button
                                className="btn btn-secondary btn-sm mt-2"
                                onClick={() => {
                                  let set_data = {
                                    value: ord_ele.customer.id,
                                    dm: ord_ele.cod_allowed,
                                    data: cart_data,




                                  };
                                  setCartModal({
                                    is_open: true,
                                    data: set_data,
                                  });
                                }}
                              >
                                Update Cart Response
                              </button> :
                              <button
                                className="btn btn-blue btn-sm mt-2"
                                onClick={() => {
                                  let set_data = {
                                    value: ord_ele.customer.id,
                                    dm: ord_ele.cod_allowed,
                                    data: cart_data,




                                  };
                                  setCartModal({
                                    is_open: true,
                                    data: set_data,
                                  });
                                }}
                              >
                                Update Cart Response
                              </button>)}
                          {/* <button
                            className="btn btn-blue btn-sm  mt-2"
                            onClick={() => {
                                let set_data = {
                                    value: ord_ele.customer.id,
                                    dm: ord_ele.cod_allowed,
                                    data:cart_data,
                                   
                                    
                                  
                                    
                                  };
                                  setCartModal({
                                    is_open: true,
                                    data: set_data,
                                  });
                            }}
                          >
                            Update Cart Response
                          </button>     */}
                        </div>
                        {/* </Link> */}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="center p-3">
                    <div className="card p-3 w-100">
                      <div className="w-100 text-center">
                        <img
                          src={require("../../assets/img/not-found.png")}
                          className="w-20"
                        />
                      </div>
                      <p className="center pt-3">No Cart Details data</p>
                    </div>
                  </div>
                )
              ) : (
                <div className="row">
                  {[1, 2, 3, 4, 5, 6].map(() => (
                    <div className="col-md-4 col-12 mb-3">
                      <OrderSkeleton />
                    </div>
                  ))}
                </div>
              )
            }
          </InfiniteScroll>
        </div>
        <ModalCart
          open={cart_modal.is_open}
          data={cart_modal.data}

          close={(data: any) => {
            console.log("Edit Outlet Modal On Close :", data);
            setCartModal({
              is_open: false,
              data: {},

            });
            if (data.action == "save") {
              console.log(data.index)
              console.log(data.data)
              const filteredItems = data.data.filter(item => item?.customer?.id === data.index);


              SetCartData((prevValue) => {
                const newValue = [...prevValue];

                filteredItems.forEach(item => {
                  item.ecommerce_cart_response = data.demo_order;
                });
                return newValue;
              });


            }
          }}
        />
      </div>

    </div>
  )
}

export default CartDetails