import React, { useState, useEffect } from "react";
import bootstrap from "../../assets/libraries/bootstrap/js/bootstrap";

export default function ShortNotification(props : any) {
  const stylePrefix = 'toast align-items-center border-0 position-fixed bottom-0 start-50 translate-middle-x mb-3 ';
  let toast:any;
  const [message, SetMessage] = useState('');
  const [style, SetStyle] = useState(stylePrefix+'text-white bg-dark');
  const [styleClose, SetStyleClose] = useState('btn-close btn-close-white me-2 m-auto');

  useEffect(() => {
    let toast_event:any = document.getElementById("toast");
    toast_event.addEventListener("hidden.bs.toast", function (event : any) {
      props.close();
    });
  }, []);

  useEffect(() => {
    if (props.data) {
        SetMessage(props.data.message);
        if(props.data.style){
            if(props.data.style == 'primary'){
                SetStyle(stylePrefix+'text-white bg-primary');
            } else if(props.data.style == 'secondary'){
                SetStyle(stylePrefix+'text-white bg-secondary');
            } else if(props.data.style == 'success'){
                SetStyle(stylePrefix+'text-white bg-success');
            } else if(props.data.style == 'danger'){
                SetStyle(stylePrefix+'text-white bg-danger');
            } else if(props.data.style == 'warning'){
                SetStyle(stylePrefix+'text-dark bg-warning');
                SetStyleClose('btn-close btn-close-dark me-2 m-auto');
            } else if(props.data.style == 'info'){
                SetStyle(stylePrefix+'text-dark bg-info');
                SetStyleClose('btn-close btn-close-dark me-2 m-auto');
            } else if(props.data.style == 'info'){
                SetStyle(stylePrefix+'text-dark bg-light');
                SetStyleClose('btn-close btn-close-dark me-2 m-auto');
            }
        }
    }
    if (props.open) {

      toast = new bootstrap.Toast(
        document.getElementById("toast"),
        {}
      );
      toast.show();
    }
  }, [props.open, props.data]);

  

  return (
    <div id="toast" className={style} role="alert" aria-live="assertive" aria-atomic="true">
        <div className="d-flex">
            <div className="toast-body">
            {message}
            </div>
            <button type="button" className={styleClose} data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
    </div>
  );
}
