import React, { useState, useEffect } from "react";
import bootstrap from "../../assets/libraries/bootstrap/js/bootstrap";


export default function ModalDelete(props: any) {

  const [close_data, SetCloseData] = useState({
    action: "close",
  });
  let myModal: any;

  useEffect(() => {
    let myModal2 : any = document.getElementById("reqDeleteModal");
    myModal2.addEventListener("hidden.bs.modal", function (event) {
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      myModal = new bootstrap.Modal(document.getElementById("reqDeleteModal"), {});
      myModal.show();
    }
  }, [props.open]);


  return (
    <div
      className="modal fade"
      id="reqDeleteModal"
      tabIndex={-1}
      aria-labelledby="ModalLabel1"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="  modal-title" id="ModalLabel1">
              {props.data.title}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">{props.data.message}</div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger"
              name="id"
              value={props.id}
              onClick={(e) => {
                // deleteUserRole(e.target.name, e.target.value);
                let item = close_data;
                item.action = "save";
                item.value = props.brand_id;
                item.data_value = props.data;
                SetCloseData(item);

                myModal.hide();
              }}
            >
              Delete
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
