"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.calculateTraceDirections = calculateTraceDirections;

var _types = require("../types");

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function calculateTraceDirections() {
  var trace = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var ticks = [];
  var positive = _types.TraceDirectionKey.POSITIVE;
  var negative = _types.TraceDirectionKey.NEGATIVE;
  var i = 0;
  var tick = [];
  var direction = _types.TraceDirectionKey.NONE;

  for (; i < trace.length; i++) {
    var current = trace[i];
    var prev = trace[i - 1];

    if (tick.length) {
      var currentDirection = current > prev ? positive : negative;

      if (direction === _types.TraceDirectionKey.NONE) {
        direction = currentDirection;
      }

      if (currentDirection === direction) {
        tick.push(current);
      } else {
        ticks.push(_defineProperty({}, direction, tick.slice()));
        tick = [];
        tick.push(current);
        direction = currentDirection;
      }
    } else {
      if (current !== 0) {
        direction = current > 0 ? positive : negative;
      }

      tick.push(current);
    }
  }

  if (tick.length) {
    ticks.push(_defineProperty({}, direction, tick));
  }

  return ticks;
}