"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resolveDirection = resolveDirection;

var _calculateDirection = require("./calculateDirection");

var _calculateTraceDirections = require("./calculateTraceDirections");

var _calculateDirectionDelta = require("./calculateDirectionDelta");

var _common = require("./common");

var _types = require("../types");

function resolveDirection(trace) {
  var axis = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _types.Axis.X;
  var directionDelta = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;

  if (directionDelta) {
    var directions = (0, _calculateTraceDirections.calculateTraceDirections)(trace);

    var _direction = (0, _calculateDirectionDelta.calculateDirectionDelta)(directions, directionDelta);

    return (0, _common.resolveAxisDirection)(axis, _direction);
  }

  var direction = (0, _calculateDirection.calculateDirection)(trace);
  return (0, _common.resolveAxisDirection)(axis, direction);
}