import React, { useState, useEffect, useRef } from "react";
import bootstrap from "../../../assets/libraries/bootstrap/js/bootstrap";
import { api } from "../../../utils/Api";
import { useParams } from "react-router-dom";
import QRCodeStyling from "qr-code-styling";
import toast from "react-hot-toast";

let myModal = {};

export default function ModalUserScanned(props: any) {
  const [close_data, SetCloseData] = useState({
    action: "close",
  });
  const [id_value, SerIdValue] = useState(useParams());
  const [users, SetUsers] = useState([]);

  const ref = useRef(null);
  let qr_initial_data: any = {
    data: props.data.qr_link,
    width: 100,
    height: 100,
    // data: " ",
    margin: 0,
    image: "",
    dotsOptions: {
      type: "square",
      color: "#000",
      gradient: null,
    },
    dotsOptionsHelper: {
      colorType: {
        single: true,
        gradient: false,
      },
      gradient: {
        linear: true,
        radial: false,
        color1: "#000",
        color2: "#000",
        rotation: "0",
        colorStops: [
          { offset: 0, color: "#000" },
          { offset: 1, color: "#000" },
        ],
        type: "linear",
      },
    },
    cornersSquareOptions: {
      type: "",
      color: "#000",
      gradient: null,
    },
    cornersSquareOptionsHelper: {
      colorType: {
        single: true,
        gradient: false,
      },
      gradient: {
        linear: true,
        radial: false,
        color1: "#000",
        color2: "#000",
        rotation: "0",
        colorStops: [
          { offset: 0, color: "#000" },
          { offset: 1, color: "#000" },
        ],
        type: "linear",
      },
    },
    cornersDotOptions: {
      type: "",
      color: "#000",
      gradient: null,
    },
    cornersDotOptionsHelper: {
      colorType: {
        single: true,
        gradient: false,
      },
      gradient: {
        linear: true,
        radial: false,
        color1: "#000",
        color2: "#000",
        rotation: "0",
        colorStops: [
          { offset: 0, color: "#000" },
          { offset: 1, color: "#000" },
        ],
        type: "linear",
      },
    },
    backgroundOptions: {
      color: "#fff",
      gradient: null,
    },
    backgroundOptionsHelper: {
      colorType: {
        single: true,
        gradient: false,
      },
      gradient: {
        linear: true,
        radial: false,
        color1: "#ffffff",
        color2: "#ffffff",
        rotation: "0",
        colorStops: [
          { offset: 0, color: "#ffffff" },
          { offset: 1, color: "#ffffff" },
        ],
        type: "linear",
      },
    },
    imageOptions: {
      hideBackgroundDots: true,
      imageSize: 0.4,
      margin: 0,
      crossOrigin: "anonymous",
      // crossOrigin: "use-credentials",
    },
    qrOptions: {
      typeNumber: "0",
      mode: "Byte",
      errorCorrectionLevel: "Q",
    },
  };
  let qrCode: any;

  useEffect(() => {
    console.log("ModalViewQr On mount :", props);
    let myModal1 = document.getElementById("UserScannedModal");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      // qr_initial_data.data = "";
    
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      console.log("ModalViewQr On Open :", props);
      get_users()
      myModal = new bootstrap.Modal(document.getElementById("UserScannedModal"), {});
      myModal.show();
    }
    // qr_initial_data.data = props.data.qr_link;
    qrCode = new QRCodeStyling(qr_initial_data);
    qrCode.append(ref.current);
    qrCode.update({ data: props.data.qr_link });

    // }, [props.open]);
  }, [props]);

  async function get_users() {
    let pass_data = {
      get: {
        // brand: id_value.brand_id,
        // page: get_data.page_no,
        id: props.data.id,
      },
    };
    let response: any = await api("/qr/seller_qr_scanned", pass_data);
    console.log("/qr/seller_qr_scanned :", response.response);
    SetUsers(response.response.data)
  }

  async function download_qrcode() {
    let pass_data = {
      post: {
        // brand: id_value.brand_id,
        // page: get_data.page_no,
        id: [props.data.id],
      },
    };
    let response: any = await api("/qr/seller_qr_downloaded", pass_data);
    console.log("/qr/seller_qr_downloaded response :", response.response);
    if (response.status_code == 200) {
      if (response.response.status) {
      }
      toast.success("Qr code downloaded");
    }
  }

  return (
    <div
      className="modal"
      id="UserScannedModal"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-fullscreen-sm-down modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header shadow-bottom">
            <h6 className="  modal-title" id="ModalLabel">
              User Scanned
            </h6>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                let item = close_data;
                item.action = "close";
                SetCloseData(item);
                myModal.hide();
              }}
            ></button>
          </div>

          <div className="modal-body">
            {console.log(users)}
           <div>{users?.length > 0 ? 
           
           users?.map((ele:any,i)=>(
            <div className="d-flex border-bottom">
           <div><p>{ele.user_details.name}</p>
           <p>{ele.user_details.username}</p>
           
           <p>{ele.already_scanned}</p>




<p>{ele.signup_platform}</p>

<p>{ele.superapp_signup}</p></div>
           <div className="ms-auto"><p># {ele.id}</p><p># {ele.user_details.id}</p><p>{ele.date}</p></div>






</div>

           )) : "No User Scanned"}
           
           </div>


            <div ref={ref} className="text-center p-3" />

            <div className="">
              <button
                type="button"
                className="btn btn-secondary-outline btn-radius px-3 me-2"
                onClick={() => {
                  let item = close_data;
                  item.action = "close";
                  SetCloseData(item);
                  // SetSaveData(initial_value);
                  myModal.hide();
                }}
              >
                Close
              </button>
        
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
