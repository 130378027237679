import React, { useState, useEffect } from "react";
import bootstrap from "../../assets/libraries/bootstrap/js/bootstrap";
import { api } from "../../utils/Api";
let myModal : any;
export default function ModalRemarks(props : any) {
  const [close_data, SetCloseData] = useState({
    action: "close",
    value:"",
    index:"",
    demo_order:false
  });

  const [allow,setAllow] = useState("");
  const [desc,setDesc] = useState("")


  const handleInputChange = (e: any) => {
    const searchValue = e.target.value;
    setDesc(searchValue);
  }

  useEffect(() => {
    console.log("ModelDeleteUserRoles On mount :", props);
    let myModal1 = document.getElementById("remark");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      console.log("event :", event);
      setDesc("");
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      console.log("ModelDeleteUserRoles On props.open :", props.data.value);
      if(props.data.dm ==true){
        setAllow("on")
      }else{
        setAllow("off")

      }
      myModal = new bootstrap.Modal(
        document.getElementById("remark"),
        {}
      );
      myModal.show();
    }
  }, [props.open]);



  function StatusOnChange(val){
    setAllow(val)
  }
  async function Save() {

    let pass_data = {
    
      post: {

        message: desc,
        user_id: props.data.value
    
      }
    };
    console.log(pass_data)
    let data_res = await api("/settings/update_user_remarks", pass_data);
  console.log(data_res)
    if (data_res.status_code == 200) {
    let item = close_data;
    console.log(item)
    item.action = "save";
    item.index= props.data.index;
    item.demo_order=data_res.response.ecommerce_remarks
setDesc("");
    // item.value =  data_res.response.data.cod_allowed_all_users;
    SetCloseData(item);
  
    myModal.hide();
    }
  }

  return (
    <div
      className="modal fade"
      id="remark"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="  modal-title" id="ModalLabel">
            Update User Remarks
            </h6>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
          
          <textarea
        id="description"
        className="form-control"
        rows="4"
        value={desc}
        onChange={handleInputChange}
          // Set 4 rows for the textarea
      />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-success"
            
              onClick={
                // myModal.hide();
                Save}
                  // data-bs-dismiss="modal"
            >
              Save
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
