import React, { useState, useEffect } from "react";
import bootstrap from "../../assets/libraries/bootstrap/js/bootstrap";

export default function ModalPgRequest(props: any) {
  const [close_data, SetCloseData] = useState({
    action: "close",
  });
  let myModal: any;

  const [data, SetData] = useState("");

  useEffect(() => {
    let myModal1 = document.getElementById("AddServiceModal");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      SetData(props.data);

      myModal = new bootstrap.Modal(
        document.getElementById("AddServiceModal"),
        {}
      );
      myModal.show();
    }
  }, [props.open]);

  return (
    <div
      className="modal fade"
      id="AddServiceModal"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="  modal-title" id="ModalLabel">
              Paying Gateway Response
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">{data}</div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
