import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ModalAccept from "./ModalAccept";
import ModalReject from "./ModalReject";
import { api } from "../../utils/Api";
import {
  Ionicons,
  MaterialCommunityIcons,
  FontAwesome,
} from "@expo/vector-icons";
import ModalDelete from "./ModalDelete";
import ModalCreateSettlement from "./ModalCreateSettlement";

let url_location: any = window.location.host;
let splited_url = url_location.split(".");

function BrandDetails() {
  const [id_value, SetIdValue] = useState(useParams());
  const [brand, SetBrand]: any = useState([]);
  const [data, SetData]: any = useState([]);
  const [view, SetView] = useState(1);
  const [width, SetWidth] = useState(innerWidth);
  const [timeoutId, setTimeoutId] = useState(null);
  const [sort_val, SetSort] = useState("");
  const [filter_data, setFilterData] = useState("");
  const [openModal, SetopenModal] = useState({
    is_open: false,
    data: {},
  });
  const [openRejModal, SetopenRejModal] = useState({
    is_open: false,
    data: {},
  });
  const [openDeleteModal, SetOpenDeleteModal] = useState({
    is_open: false,
    data: {},
  });
  const [payment_loading, SetPaymentLoading] = useState(true);
  const [payments, setPayments] = useState([]);
  const [visible_content, SetVisibleContent] = useState(false);
  const [next_page, SetNextPage] = useState(false);
  const [page_no, SetPageNo] = useState(1);
  const navigate = useNavigate();
  const [brand_data, SetBrandData] = useState({});

  // ///
  const [settlement_list_loading, SetSettelementListLoading] = useState(true);
  const [sl_next_page, SetSLNextPage] = useState(false);
  const [sl_page_no, SetSLPageNo] = useState(1);
  const [sl_list, SetSLList] = useState([]);
  const sl_filter_options = [
    {
      id: 1,
      value: "all",
      name: "All",
    },
    {
      id: 2,
      value: "active",
      name: "Active",
    },
    {
      id: 3,
      value: "deleted",
      name: "Deleted",
    },
  ];
  const [selected_sl_filter, SetSelectedSLFilter] = useState("active");
  const sl_sort_options = [
    {
      id: 1,
      value: "asc",
      name: "Oldest",
    },
    {
      id: 2,
      value: "desc",
      name: "Latest",
    },
  ];
  const [selected_sl_sort, SetSelectedSLSort] = useState("desc");
  const [modal_create_settlement, SetModalCreateSettlement] = useState({
    is_open: false,
    data: {},
    title:""
  });
  const [selected_outlet_data, SetSelectedOutletData]: any = useState({});
  const [show_side_tab, SetShowSideTab] = useState(false);

  const sort = (val: any) => {
    SetSort(val);

    let pass_data = {
      sort: val,
      page_no: 1,
    };
    setPayments([]);
    get_brand_sellers(pass_data, filter_data, view);
  };

  function openMap(dir_lat: any, dir_lng: any) {
    window.open(
      "https://www.google.com/maps?q=" + dir_lat + "," + dir_lng + "",
      "_blank"
    );
  }

  useEffect(() => {
    get_brand_details();
    let pass_data = {
      order_status: 1,
      seller_brand_id: "",
      seller_outlet_id: "",
      sort: "",
      page_no: page_no,
    };
    get_brand_sellers(pass_data, filter_data, view);
  }, []);

  async function get_brand_details() {
    let pass_data: any = {
      get: {
        brand_id: id_value.brand_id,
      },
    };

    let response = await api("/sellers/brands", pass_data);
    console.log("response :", response);
    if (response.status_code == 200) {
      SetBrand(response.response.data);
      SetData(response.response);
      SetBrandData(response.response.data);
    }
  }

  async function get_brand_sellers(get_data: any, search: any, view: any) {
    if (!get_data.load_more) {
      SetPaymentLoading(true);
    }
    let pass_data = {
      get: {
        page: get_data.page_no,
        search: search,
        status: view,
        brand_id: id_value.brand_id,
        sort: get_data.sort,
      },
    };

    let response = await api("/sellers/outlets", pass_data);
    if (response.status_code == 200) {
      SetPaymentLoading(false);
      if (get_data.load_more) {
        SetData(response.response);
        setPayments((prevValue: any) => {
          response.response.data.map((item: any) => {
            prevValue.push(item);
          });
          return [...prevValue];
        });
      } else {
        setPayments(response.response.data);
        SetData(response.response);
      }

      if (response.response.hasOwnProperty("data")) {
        SetVisibleContent(true);
      }
      if (response.response.hasOwnProperty("next_page")) {
        SetNextPage(response.response.next_page);
        SetPageNo((prevalue) => {
          prevalue = prevalue + 1;
          return prevalue;
        });
      }
    }
  }

  async function delete_brand(get_data: any) {
    let pass_data = {
      post: {
        brand_id: get_data.brand_id,
        status: 1,
      },
    };

    let response = await api("/sellers/delete_brand", pass_data);
    if (response.status_code == 200) {
      if (response.response.status) {
        navigate(-1);
      }
    }
  }

  async function delete_outlet(get_data: any) {
    let pass_data = {
      post: {
        outlet_id: get_data.outlet_id,
        status: 1,
      },
    };

    let response = await api("/sellers/delete_outlet", pass_data);
    if (response.status_code == 200) {
      if (response.response.status) {
        // navigate(-1);
        let pass_data = {
          order_status: 1,
          seller_brand_id: "",
          seller_outlet_id: "",
          sort: "",
          page_no: "1",
        };
        get_brand_sellers(pass_data, filter_data, view);
      }
    }
  }

  const OutletCard = () => {
    return (
      <div className="col-md-6 sm-pb-5 md-ps-2">
        {payment_loading ? (
          [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((ele) => (
            <div className="card mb-2 p-3">
              <div className="row align-items-center border-bottom">
                <div className="col-6 pb-3">
                  <Skeleton height={10} width={50} />
                </div>
                <div className="col-6 text-end pb-3">
                  <Skeleton height={30} width={100} />
                </div>
                <div className="col-6 pb-2">
                  <Skeleton height={10} width={100} count={2} />
                </div>
                <div className="col-6 pb-2 text-end">
                  <Skeleton height={10} width={80} count={2} />
                </div>
              </div>
              <div className="pt-2">
                <Skeleton height={10} width={80} />
                <div className="pt-2">
                  <Skeleton height={10} width={180} />
                </div>
              </div>
            </div>
          ))
        ) : payments.length > 0 ? (
          <>
            <InfiniteScroll
              dataLength={payments.length}
              next={() => {
                let pass_data: any = {
                  order_status: 1,
                  seller_brand_id: "",
                  seller_outlet_id: "",
                  sort: "",
                  load_more: true,
                  page_no: page_no,
                };

                get_brand_sellers(pass_data, filter_data, view);
              }}
              // hasMore={get_next_page()}
              hasMore={next_page}
              loader={
                <div className="center m-5">
                  <div className="lds-dual-ring"></div>
                  <p className=" small ps-2">Just a second ...</p>
                </div>
              }
            >
              {payments.map((ele: any, i) => (
                <div
                  key={i}
                  className={
                    show_side_tab == true &&
                    selected_outlet_data.agent_id == ele.agent_id
                      ? "active-shadow-layout radius  mb-2 p-3"
                      : "card mb-2 p-3"
                  }
                >
                  <div className="row ">
                    {/* top */}
                    <div className="col-12  pb-3">
                      <div className="d-flex">
                        <img
                          src={
                            ele.logo != null
                              ? data.image_base_url + ele.logo + "?width=100"
                              : require("../../assets/img/no-data.png?width=100")
                          }
                          className={"category-img"}
                        />
                        <div className=" ms-2">
                          <p className="text-dark fw-bold fs-7">{ele?.name} </p>
                          <p className=" fw-bold fs-7">{ele?.slug}</p>
                        </div>

                        <div className="ms-auto text-end">
                          {ele.status == "Rejected" && (
                            <>
                              <FontAwesome
                                name="circle"
                                size={10}
                                color={
                                  ele.status == "Rejected" ? "red" : "green"
                                }
                              />
                              <p
                                className={
                                  ele.status == "Rejected"
                                    ? "ps-2 me-2 text-red"
                                    : "ps-2 me-2 text-green"
                                }
                              >
                                {ele.status}
                              </p>
                            </>
                          )}
                          <div>
                            {" #"}
                            {ele?.outlet_id}
                          </div>
                          {ele?.status_id == 2 && (
                            <div className={"ps-2 me-2 badge bg-danger"}>
                              Deleted
                            </div>
                          )}
                          <div
                            className={
                              !ele.active
                                ? "ps-2 badge bg-danger"
                                : "ps-2 badge bg-success"
                            }
                          >
                            {ele.active ? "Approved" : "Unapproved"}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 d-flex mb-3">
                      {/* upprove */}
                      {ele.active == false && (
                        <button
                          className="btn btn-success btn-radius btn-sm px-3 w-50"
                          onClick={() => {
                            SetopenModal({
                              is_open: true,
                              data: {
                                id: ele?.outlet_id,
                              },
                            });
                          }}
                        >
                          Approve
                        </button>
                      )}
                      {/* unapprove */}
                      {ele.active == true && (
                        //  <button className="btn btn-prime btn-radius btn-sm px-3 ms-2"

                        <button
                          className="btn btn-danger btn-radius btn-sm px-3 w-50"
                          onClick={() => {
                            SetopenRejModal({
                              is_open: true,
                              data: {
                                id: ele?.outlet_id,
                              },
                            });
                          }}
                        >
                          Unapprove{" "}
                        </button>
                      )}
                      {/* go to seller */}
                      <a
                        className="btn btn-dark btn-radius btn-sm px-3 ms-2 w-50"
                        href={
                          splited_url[0] == "1sdev"
                            ? "https://1sdev.sellers.1superapp.com/outlet/" +
                              id_value.brand_id +
                              "/" +
                              ele.outlet_id +
                              "/dashboard"
                            : "https://sellers.1superapp.com/outlet/" +
                              id_value.brand_id +
                              "/" +
                              ele.outlet_id +
                              "/dashboard"
                        }
                        target="_blank"
                      >
                        {" "}
                        Go To Seller
                      </a>
                    </div>
                    <div className="row">
                      {/* delete outlet */}
                      <button
                        className="btn col-6 btn-danger btn-radius btn-sm px-3 w-50"
                        onClick={() => {
                          SetOpenDeleteModal({
                            is_open: true,
                            data: {
                              title: "Delete Outlet",
                              message: "Are you sure want to delete outlet ?",
                              from: " delete_outlet",
                              outlet_id: ele?.outlet_id,
                            },
                          });
                        }}
                      >
                        Delete Outlet
                      </button>
                      {/* view settlement */}
                      <button
                        className="btn col-6 btn-success btn-radius btn-sm px-3 w-50"
                        onClick={() => {
                          SetShowSideTab(true);
                          SetSelectedOutletData(ele);
                          SetSLPageNo((prevalue: any) => {
                            let send_data = {
                              brand_id: ele.brand_id,
                              outlet_id: ele.outlet_id,
                              page_no: prevalue,
                              filter: selected_sl_filter,
                              sort: selected_sl_sort,
                            };
                            get_settlements_data(send_data);
                            return prevalue;
                          });
                        }}
                      >
                        View Settlement
                      </button>
                    </div>

                    <div className="col-6  pb-3">
                      <p className="text-dark mt-2 fw-bold fs-7">
                        Approx Preparation Duration:
                      </p>

                      <p className=" fw-bold fs-7">
                        {ele?.approx_preparation_duration
                          ? ` ${Math.floor(
                              ele?.approx_preparation_duration / 60
                            )} mins`
                          : "-"}
                      </p>
                      <p className="text-dark mt-2 fw-bold fs-7">COD:</p>
                    </div>
                    <div className="col-6 end pb-2">
                      {/* </Link>  */}
                      {/* <a
                      className="link-loader"
                      href={
                        splited_url[0] == "1sdev"
                          ? "https://1sdev.sellers.1superapp.com/brand/" +
                            ele.brand_id +
                            "/dashboard"
                          : "https://sellers.1superapp.com/brand/" +
                            ele.brand_id +
                            "/dashboard"
                        // AppConfig.environment == "Development"
                        //   ? "https://1sdev.products.1superapp.com/seller/" +
                        //     id_value.brand_id +
                        //     "/dashboard"
                        //   : "https://products.1superapp.com/seller/" +
                        //     id_value.brand_id +
                        //     "/dashboard"
                      } 
                      target="_blank">  */}
                    </div>

                    <div className="col-6  pb-2">
                      <p className=" fw-bold fs-7">{ele?.cod ? "Yes" : "No"}</p>
                      <p className="text-dark mt-2 fw-bold fs-7">mobile:</p>
                      <p className=" fw-bold fs-7">
                        {ele?.mobile ? ele?.mobile : "-"}
                      </p>
                      <p className="text-dark mt-2 fw-bold fs-7">Email:</p>
                      <p className=" fw-bold fs-7">
                        {ele?.email ? ele?.email : "-"}
                      </p>

                      <p className="text-dark mt-2 fw-bold fs-7">Gstin:</p>
                      <p className=" fw-bold fs-7">
                        {ele?.gstin ? ele?.gstin : "-"}
                      </p>

                      <p className="text-dark mt-2 fw-bold fs-7">Website:</p>

                      <p className=" fw-bold fs-7">
                        {ele?.website ? ele?.website : "-"}
                      </p>
                      <p className="text-dark mt-2 fw-bold fs-7">Whatsapp:</p>

                      <p className=" fw-bold fs-7">
                        {ele?.whatsapp ? ele?.whatsapp : "-"}
                      </p>

                      <p className=" fw-bold fs-7 mt-2">
                        {ele?.door_no},{ele?.address},{ele?.city},{ele?.state},
                        {ele?.country},{ele?.pincode}
                      </p>
                    </div>
                    <div className="col-6  pb-2"></div>
                    <div className="col-6  pb-2">
                      <p className="text-dark mt-2 fw-bold fs-7">
                        Map Address:
                      </p>
                      <div className="d-flex">
                        <p className=" fw-bold fs-7">{ele?.map_address}</p>
                      </div>

                      <p>{ele?.created_at}</p>
                    </div>
                    <div className="col-6 end pb-2">
                      <button
                        className="btn btn-prime btn-radius btn-sm px-3  h-10"
                        onClick={() => {
                          openMap(ele?.lat, ele?.lng);
                        }}
                      >
                        Map
                      </button>
                    </div>
                    {ele.status == "Rejected" ? (
                      <div className="">
                        <div className="pt-1 pb-2">
                          <hr className="text-gray m-0" />
                        </div>

                        <p className="pb-2 text-dark fw-500">Rejected Reason</p>
                        <p className="text-gray">
                          {ele.rejected_reason != ""
                            ? ele.rejected_reason
                            : "-"}
                        </p>
                      </div>
                    ) : null}
                  </div>
                </div>
              ))}
            </InfiniteScroll>
          </>
        ) : (
          <div className="center p-3">
            <div className="card p-3 w-100">
              <div className="w-100 text-center">
                <img
                  src={require("../../assets/img/not-found.png")}
                  className="no-data-img"
                />
              </div>
              <p className="center pt-3">No Outlets</p>
            </div>
          </div>
        )}
      </div>
    );
  };

  const handleInputChange = (e: any) => {
    const searchValue = e.target.value;
    setFilterData(searchValue);
    // Clear previous timeout
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // Set a new timeout
    const newTimeoutId = setTimeout(() => {
      // Call your API with the search value
      let pass_data = {
        sort: "",
        page_no: 1,
      };
      setPayments([]);
      get_brand_sellers(pass_data, searchValue, view);
    }, 1000); // 5 milliseconds delay

    // Save the timeout ID
    setTimeoutId(newTimeoutId);
  };

  const handleButtonClick = (status: any) => {
    // Handle button click logic based on the status (New, Active, Rejected)
    SetView(status);

    let pass_data: any = {
      sort: "",
      // page_no: 1,
    };
    setPayments([]);
    // SetNextPage(false);
    SetPageNo((prevalue) => {
      prevalue = 1;
      pass_data.page_no = prevalue;
      get_brand_sellers(pass_data, filter_data, status);
      return prevalue;
    });
  };

  async function get_settlements_data(get_data: any) {
    if (!get_data.load_more) {
      SetSettelementListLoading(true);
    }
    let pass_data = {
      get: {
        page: get_data.page_no,
        brand_id: get_data.brand_id,
        outlet_id: get_data.outlet_id,
        filter: get_data.filter,
        sort: get_data.sort,
      },
    };

    let response = await api("/sellers/settlements", pass_data);
    if (response.status_code == 200) {
      SetSettelementListLoading(false);
      if (get_data.load_more) {
        SetSLList((prevValue) => {
          response.response.data.map((item: any) => {
            prevValue.push(item);
          });
          return [...prevValue];
        });
      } else {
        SetSLList(response.response.data);
      }

      if (response.response.hasOwnProperty("next_page")) {
        SetSLNextPage(response.response.next_page);
      }
    }
  }

  return (
    <div className="mb-5">
      {/* Header */}
      <div className="head-bar row d-flex">
        <div className="col-md-6">
          <h5 className="  mb-2">Seller Brand Details</h5>
        </div>
        <div className="col-md-6 ms-auto"></div>
      </div>
      {/* Brand Details */}
      <div className="mt-5 mt-lg-3 px-md-3 px-sm-0 ">
        <div
          className={"card mb-2 p-3"}
          // onClick={() => {
          //   enable(ele);

          //   SetSelectedDetails(i);
          // }}
        >
          <div className="row ">
            <div className="row pb-3">
              {/* <div className="d-flex mb-3"> */}
              <div className="col-6  pb-3">
                <img
                  src={
                    brand[0]?.logo != null
                      ? data.image_base_url + brand[0]?.logo + "?width=100"
                      : require("../../assets/img/no-data.png?width=100")
                  }
                  className={"category-img"}
                />
                <div className=" ms-2">
                  <h6 className="mb-1">{brand[0]?.name} </h6>
                  <p className="text-muted">{brand[0]?.slug}</p>
                </div>
              </div>
              <div className="col-6  pb-3">
                <div className="ms-auto text-end">
                  <div>#{brand[0]?.brand_id}</div>
                  {brand[0]?.status_id == 2 && (
                    <div className="badge bg-danger">Deleted</div>
                  )}
                  <button
                    className="btn btn-danger btn-radius btn-sm px-3 w-50"
                    onClick={() => {
                      SetOpenDeleteModal({
                        is_open: true,
                        data: {
                          title: "Delete Brand",
                          message: "Are you sure want to delete band ?",
                          from: " delete_brand",
                        },
                      });
                    }}
                  >
                    Delete Brand
                  </button>
                </div>
              </div>
              <div className="d-flex">
                <div>
                  <div>Owned By</div>
                  <div className="text-muted">
                    <p className="">{brand[0]?.owner.name} </p>
                    <p className="">@{brand[0]?.owner.user_name}</p>
                  </div>
                </div>
                <div className="ms-auto text-end">
                  <div>Created At</div>
                  <div className="text-muted">{brand[0]?.created_at}</div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <a
                className="btn btn-dark btn-radius btn-sm px-3 w-100"
                href={
                  splited_url[0] == "1sdev"
                    ? "https://1sdev.sellers.1superapp.com/brand/" +
                      brand[0]?.brand_id +
                      "/dashboard"
                    : "https://sellers.1superapp.com/brand/" +
                      brand[0]?.brand_id +
                      "/dashboard"
                }
                target="_blank"
              >
                Go To Seller
              </a>
            </div>

            <div className="col-6 end pb-2">
              {brand[0]?.status == "Rejected" && (
                <>
                  <FontAwesome
                    name="circle"
                    size={10}
                    color={brand[0]?.status == "Rejected" ? "red" : "green"}
                  />
                  <p
                    className={
                      brand[0]?.status == "Rejected"
                        ? "ps-2 text-red"
                        : "ps-2 text-green"
                    }
                  >
                    {brand[0]?.status}
                  </p>
                </>
              )}
            </div>

            {brand[0]?.status == "Rejected" ? (
              <div className="">
                <div className="pt-1 pb-2">
                  <hr className="text-gray m-0" />
                </div>

                <p className="pb-2 text-dark fw-500">Rejected Reason</p>
                <p className="text-gray">
                  {brand[0]?.rejected_reason != ""
                    ? brand[0]?.rejected_reason
                    : "-"}
                </p>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {/* Outlet Details */}
      <div className="mt-3 px-md-3 px-sm-0 ">
        <div className={"card mb-2 p-3"}>
          <div className="row">
            <div className="col-md-6">Outlets</div>
            <div className="col-md-6 sm-pt-2 end">
              {/* search */}
              <div className="input-icons form-group p-2">
                <input
                  type="text"
                  className="form-control search-bar p-0"
                  placeholder="Search here..."
                  onChange={handleInputChange}
                />
                <div className="cursor px-2 border-left">
                  <Ionicons name="md-search" size={16} color="#ccc" />
                </div>
              </div>
              {/* filter */}
              <div className="dropdown">
                <button
                  className="btn btn-white dropdown-toggle d-flex align-items-cetner"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src={require("../../assets/icon/filter.png")}
                    className="icon-14px"
                  />
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <a className="dropdown-item" href="#">
                      <div onClick={() => sort("asc")}>
                        <p className="mb-0 p-2">Oldest</p>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      <div onClick={() => sort("desc")}>
                        <p className="mb-0 p-2">Newest</p>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {/* tab */}
            <div className="mt-3">
              <button
                type="button"
                className={
                  view == "1"
                    ? "btn btn-text header-tap me-1 px-3 "
                    : "btn btn-text text-black me-1 px-3"
                }
                onClick={() => handleButtonClick("1")}
              >
                Active
              </button>
              <button
                type="button"
                className={
                  view == "2"
                    ? "btn btn-text header-tap me-1 px-3 "
                    : "btn btn-text text-black me-1 px-3"
                }
                onClick={() => handleButtonClick("2")}
              >
                Deleted
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          {width < 768 && show_side_tab == false ? (
            <OutletCard />
          ) : width > 768 ? (
            <OutletCard />
          ) : null}

          {show_side_tab && (
            <div className="col-md-6  sm-pb-5 sm-mt-6">
              <div className="overflowY-90vh pb-5 position-sticky-top-50px">
                <div className="card bg-fff p-3">
                  {/* Header */}
                  <div className="d-flex align-items-center mb-4">
                    <div
                      className="cursor pe-3"
                      onClick={() => {
                        SetShowSideTab(false);
                      }}
                    >
                      <MaterialCommunityIcons
                        name="arrow-left"
                        size={18}
                        color="black"
                      />
                    </div>
                    <h6 className="mb-0">Outlet Settlement Details</h6>
                  </div>
                  {/* Body */}
                  <div className="">
                    {/* User Data */}
                    <div className="row">
                      <div className="col-6 col-md-4 pb-3">
                        <div className="heading pb-2">Outlet Id</div>
                        <div className="value">
                          #{selected_outlet_data.outlet_id}
                        </div>
                      </div>

                      <div className="col-6 col-md-4 pb-3">
                        <div className="heading pb-2">Brand Name</div>
                        <div className="value">
                          {selected_outlet_data?.brand_name}
                        </div>
                      </div>
                      <div className="col-6 col-md-4 pb-3">
                        <div className="heading pb-2">Outlet Name</div>
                        <div className="value">
                          {selected_outlet_data?.name}
                        </div>
                      </div>
                    </div>
                    {/* Settlements */}
                    <div className="border-top">
                      <div className="d-flex align-item-center">
                        <h5>Seller Settlement</h5>
                        {/* filter */}
                        <label htmlFor="">
                          Filter
                          <select
                            className="form-select"
                            value={selected_sl_filter}
                            onChange={(e: any) => {
                              SetSelectedSLFilter(e.target.value);
                              let send_data = {
                                brand_id: selected_outlet_data.brand_id,
                                outlet_id: selected_outlet_data.outlet_id,
                                page_no: 1,
                                filter: e.target.value,
                                sort: selected_sl_sort,
                              };
                              SetSLPageNo(1);
                              get_settlements_data(send_data);
                            }}
                          >
                            {sl_filter_options.map((ele, i) => (
                              <option key={i} value={ele.value}>
                                {ele.name}
                              </option>
                            ))}
                          </select>
                        </label>

                        {/* sort */}
                        <label htmlFor="">
                          Sort
                          <select
                            className="form-select"
                            value={selected_sl_sort}
                            onChange={(e: any) => {
                              SetSelectedSLSort(e.target.value);
                              let send_data = {
                                // agent_id: selected_outlet_data.agent_id,
                                brand_id: selected_outlet_data.brand_id,
                                outlet_id: selected_outlet_data.outlet_id,
                                page_no: 1,
                                filter: selected_sl_filter,
                                sort: e.target.value,
                              };
                              SetSLPageNo(1);
                              get_settlements_data(send_data);
                            }}
                          >
                            {sl_sort_options.map((ele, i) => (
                              <option key={i} value={ele.value}>
                                {ele.name}
                              </option>
                            ))}
                          </select>
                        </label>

                        {/* create new */}
                        <button
                          className="btn btn-primary ms-auto"
                          onClick={() => {
                            SetModalCreateSettlement((prevValue: any) => {
                              prevValue.is_open = true;
                              prevValue.data = selected_outlet_data;
                              prevValue.title = "Seller Settlement";

                              return { ...prevValue };
                            });
                          }}
                        >
                          New
                        </button>
                      </div>
                      {settlement_list_loading ? (
                        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((ele, i) => (
                          <div className="card mb-2 p-3" key={i}>
                            <div className="row align-items-center border-bottom">
                              <div className="col-6 pb-3">
                                <Skeleton height={10} width={50} />
                              </div>
                              <div className="col-6 text-end pb-3">
                                <Skeleton height={30} width={100} />
                              </div>
                              <div className="col-6 pb-2">
                                <Skeleton height={10} width={100} count={2} />
                              </div>
                              <div className="col-6 pb-2 text-end">
                                <Skeleton height={10} width={80} count={2} />
                              </div>
                            </div>
                            <div className="pt-2">
                              <Skeleton height={10} width={80} />
                              <div className="pt-2">
                                <Skeleton height={10} width={180} />
                              </div>
                            </div>
                          </div>
                        ))
                      ) : sl_list.length == 0 ? (
                        <div className="center p-3">
                          <div className="card p-3 w-100">
                            <div className="w-100 text-center">
                              <img
                                src={require("../../assets/img/not-found.png")}
                                className="no-data-img"
                              />
                            </div>
                            <p className="center pt-3">No Settlement Data</p>
                          </div>
                        </div>
                      ) : (
                        <div className="">
                          <InfiniteScroll
                            dataLength={sl_list.length}
                            next={() => {
                              SetSLPageNo((prevalue) => {
                                prevalue = prevalue + 1;
                                let send_data = {
                                  // agent_id: selected_partner_data.agent_id,
                                  brand_id: selected_outlet_data.brand_id,
                                  outlet_id: selected_outlet_data.outlet_id,
                                  page_no: prevalue,
                                  filter: selected_sl_filter,
                                  sort: selected_sl_sort,
                                };
                                get_settlements_data(send_data);
                                return prevalue;
                              });
                            }}
                            hasMore={sl_next_page}
                            loader={
                              <div className="center m-5">
                                <div className="lds-dual-ring"></div>
                                <p className=" small ps-2">Just a second ...</p>
                              </div>
                            }
                          >
                            {sl_list.map((ele: any, i: any) => (
                              <div className="p-2" key={i}>
                                <div className="card p-3 w-100" key={i}>
                                  {/* top */}
                                  <div className="row align-items-center">
                                    {/* id */}
                                    <div className="col-6">
                                      <p className="text-dark fw-bold fs-7">
                                        ID: #{ele.id}
                                      </p>
                                    </div>
                                    {/* time / delete*/}
                                    <div className="col-6 end">
                                      <div className="">{ele.time}</div>
                                      {!ele.deleted ? (
                                        <div className="ms-auto">
                                          <button
                                            className="btn btn-danger"
                                            onClick={() => {
                                              let send_data = {
                                                settlement_id: ele.id,
                                              };
                                              // delete_settlements(send_data);
                                            }}
                                          >
                                            Delete
                                          </button>
                                        </div>
                                      ) : null}
                                    </div>
                                    {/* Amount / notes*/}
                                    <div className="col-6 pb-2">
                                      <p className="text-dark fw-bold fs-7">
                                        Amount : {ele?.amount}
                                      </p>
                                      <p className="text-dark fw-bold fs-7">
                                        Notes : {ele?.notes}
                                      </p>
                                    </div>
                                  </div>
                                  {/* Order data */}
                                  <div className="row pb-2">
                                    <h6>Orders</h6>
                                    {ele.orders.map(
                                      (o_ele: any, o_index: any) => (
                                        <div
                                          className="border pb-2 pt-2 align-items-center border-bottom"
                                          key={o_index}
                                        >
                                          <p className="col-6 ps-1 text-dark">
                                            Agent Order Id :{" "}
                                            {o_ele.agent_order_id}
                                          </p>
                                          <p className="col-6 ps-1 text-dark">
                                            Settlement Order Id :{" "}
                                            {o_ele.settlement_order_id}
                                          </p>
                                          <p className="col-6 ps-1 text-dark">
                                            Notes : {o_ele.notes}
                                          </p>
                                          <p className="col-6 ps-1 text-dark">
                                            Amount : {o_ele.amount}
                                          </p>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </InfiniteScroll>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <ModalAccept
        open={openModal.is_open}
        data={openModal.data}
        close={(data: any) => {
          SetopenModal((prevValue: any) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });
          if (data.action == "save") {
            let pass_data = {
              order_status: 1,
              seller_brand_id: "",
              seller_outlet_id: "",
              sort: "",
              page_no: "1",
            };
            get_brand_sellers(pass_data, filter_data, view);
          }
        }}
      />

      <ModalReject
        open={openRejModal.is_open}
        data={openRejModal.data}
        close={(data: any) => {
          SetopenRejModal((prevValue: any) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });
          if (data.action == "save") {
            let pass_data = {
              order_status: 1,
              seller_brand_id: "",
              seller_outlet_id: "",
              sort: "",
              page_no: "1",
            };
            get_brand_sellers(pass_data, filter_data, view);
          }
        }}
      />

      <ModalDelete
        open={openDeleteModal.is_open}
        data={openDeleteModal.data}
        brand_id={brand[0]?.brand_id}
        close={(data: any) => {
          console.log("data ", data);
          SetOpenDeleteModal((prevValue: any) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });
          if (data.action == "save") {
            if (data.data_value.from == " delete_brand") {
              let pass_data = {
                brand_id: data.value,
              };
              delete_brand(pass_data);
            }

            if (data.data_value.from == " delete_outlet") {
              let pass_data = {
                outlet_id: data.data_value.outlet_id,
              };
              delete_outlet(pass_data);
            }
          }
        }}
      />

      <ModalCreateSettlement
        open={modal_create_settlement.is_open}
        data={modal_create_settlement.data}
        title={modal_create_settlement.title}
        from={"sellers"}
        close={(data: any) => {
          console.log("on create settlement close data :", data);
          SetModalCreateSettlement((prevValue: any) => {
            prevValue.is_open = false;
            prevValue.data = {};
            return { ...prevValue };
          });

          if (data.action == "save") {
            let send_data = {
              // agent_id: data.data.agent_id,
              brand_id: data.data.brand_id,
              outlet_id: data.data.outlet_id,
              page_no: 1,
              filter: selected_sl_filter,
              sort: selected_sl_sort,
            };
            get_settlements_data(send_data);
          }
        }}
      />
    </div>
  );
}

export default BrandDetails;
