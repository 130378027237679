import React, { useState, useEffect } from "react";
import bootstrap from "../../assets/libraries/bootstrap/js/bootstrap";
import { api } from "../../utils/Api";
let myModal : any;
export default function ModalDeleteComponent(props : any) {
  const [close_data, SetCloseData] = useState({
    action: "close",
  });

  

  useEffect(() => {
    console.log("ModelDeleteUserRoles On mount :", props);
    let myModal1 = document.getElementById("DeleteModal");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      console.log("event :", event);
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
  

      myModal = new bootstrap.Modal(
        document.getElementById("DeleteModal"),
        {}
      );
      myModal.show();
    }
  }, [props.open]);



  return (
    <div
      className="modal fade"
      id="DeleteModal"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="  modal-title" id="ModalLabel">
              Delete {props.title}
            </h6>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            Are you sure want to delete {props.title}?
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger"
            
              onClick={() => {
                myModal.hide();
                props.on_ok(props.data);
              }}
                  // data-bs-dismiss="modal"
            >
              Delete
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"

              onClick={() => {
                let item = close_data;
                item.action = "close";
                SetCloseData(item);
                myModal.hide();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
