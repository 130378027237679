import React, { useState, useEffect } from "react";
import bootstrap from "../../assets/libraries/bootstrap/js/bootstrap";
import { api } from "../../utils/Api";
let myModal : any;
export default function ModalDemoUser(props : any) {
  const [close_data, SetCloseData] = useState({
    action: "close",
    value:{},
  });

  const [allow,setAllow] = useState("")

  

  useEffect(() => {
    console.log("ModelDeleteUserRoles On mount :", props);
    let myModal1 = document.getElementById("demo");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      console.log("event :", event);
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      console.log("ModelDeleteUserRoles On props.open :", props.data.value);
      if(props.data.dm ==true){
        setAllow("demo")
      }else{
        setAllow("live")

      }
      myModal = new bootstrap.Modal(
        document.getElementById("demo"),
        {}
      );
      myModal.show();
    }
  }, [props.open]);



  function StatusOnChange(val){
    setAllow(val)
  }
  async function Save() {

    let pass_data = {
    
      post: {

        status: allow,
        order_id: props.data.value
    
      }
    };
    console.log(pass_data)
    let data_res = await api("/orders/update_demo_order", pass_data);
  console.log(data_res)
    if (data_res.status_code == 200) {
    let item = close_data;
    console.log(item)
    item.action = "save";
    // item.value =  data_res.response.data.cod_allowed_all_users;
    item.value =  data_res.response.data[0];
    SetCloseData(item);
  
    myModal.hide();
    }
  }

  return (
    <div
      className="modal fade"
      id="demo"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="  modal-title" id="ModalLabel">
            Update Demo Order
            </h6>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
          <div className="form-check form-check-inline">
  <input className="form-check-input" type="radio"
   name="inlineRadioOptions12" id="inlineRadio12" value="option12"
    checked={allow=="demo"} onClick={()=>{StatusOnChange("demo")}}/>
  <label className="form-check-label" for="inlineRadio12">Make demo order</label>
</div>
<div className="form-check form-check-inline">
  <input className="form-check-input" type="radio" 
  name="inlineRadioOptions21" id="inlineRadio21" value="option21" 
  checked={allow=="live"} onClick={()=>{StatusOnChange("live")}}/>
  <label className="form-check-label" for="inlineRadio21">Remove from demo order</label>
</div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-success"
            
              onClick={
                // myModal.hide();
                Save}
                  // data-bs-dismiss="modal"
            >
              Save
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
