import React, { useState, useEffect } from "react";
import bootstrap from "../../assets/libraries/bootstrap/js/bootstrap";

let myModal = {};

export default function TrackOrder(props: any) {
  const [close_data, SetCloseData] = useState({
    action: "close",
  });

  useEffect(() => {
    let myModal1 = document.getElementById("TrackModal");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      myModal = new bootstrap.Modal(document.getElementById("TrackModal"), {});
      myModal.show();
    }
  }, [props.open]);

  return (
    <div
      className="modal fade"
      id="TrackModal"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <div className="  modal-title" id="ModalLabel">
              <h5>Track Orders</h5>
            </div>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                let item = close_data;
                item.action = "close";
                SetCloseData(item);
                myModal.hide();
              }}
            ></button>
          </div>
          <div className="modal-body">
            <img src={require("../../assets/img/map.jpg")} className="w-100" />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-sm btn-secondary me-3"
              onClick={() => {
                let item = close_data;
                item.action = "close";
                SetCloseData(item);
                myModal.hide();
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
