import React, { useState, useEffect } from "react";
import bootstrap from "../../assets/libraries/bootstrap/js/bootstrap";

import { api } from "../../utils/Api";

const initial_value = {
  id: "",
  status: "",
  profile_img: "",
  driving_license: "",
  aadhaar: "",
  pan: "",
  rc_book: "",
  reason: "",
  profile_img_reason: "",
  driving_license_reason: "",
  aadhaar_reason: "",
  pan_reason: "",
  rc_book_reason: "",
};

export default function ModalVariants(props: any) {
  const [save_data, SetSaveData] = useState(initial_value);
  const [close_data, SetCloseData] = useState({
    action: "close",
  });
  const [myModal, setMyModal] = useState<any>(null); // State to store the modal instance
  const [text, setText] = useState("");

  useEffect(() => {
    let modalInstance = new bootstrap.Modal(
      document.getElementById("DelUser"),
      {}
    );
    setMyModal(modalInstance); // Save the modal instance to the state

    let myModal1 = document.getElementById("DelUser");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
   

      // Replace the comment below with your data initialization logic
      const newValues = {
        id: props.data.id,
        status: props.data.status,
        profile_img: props.data.profile_img,
        driving_license: props.data.driving_license,
        aadhaar: props.data.aadhaar,
        pan: props.data.pan,
        rc_book: props.data.rc_book,
        reason: props.data.reason,
        profile_img_reason: props.data.profile_img_reason,
        driving_license_reason: props.data.driving_license_reason,
        aadhaar_reason: props.data.aadhaar_reason,
        pan_reason: props.data.pan_reason,
        rc_book_reason: props.data.rc_book_reason,
      };

      SetSaveData(newValues);

      myModal.show();
    }
  }, [props.open]);

  async function post_accept() {
    let pass_data = {
      post: {
        id: save_data.id,
        status: 2,
        profile_img: save_data.profile_img,
        driving_license: save_data.driving_license,
        aadhaar: save_data.aadhaar,
        pan: save_data.pan,
        rc_book: save_data.rc_book,
        reason: text,
        profile_img_reason: save_data.profile_img_reason,
        driving_license_reason: save_data.driving_license_reason,
        aadhaar_reason: save_data.aadhaar_reason,
        pan_reason: save_data.pan_reason,
        rc_book_reason: save_data.rc_book_reason,
      },
    };

    let response = await api(
      "/orders/delivery_partner_request_status",
      pass_data
    );

    if (response.status_code === 200) {
      let item = close_data;
      item.action = "save";
      setText("");
      SetSaveData(initial_value);
      SetCloseData(item);

      myModal.hide(); // Hide the modal using the modal instance
    }
  }

  function onTextChanged(e: any) {
    const value = e.target.value;
    setText(value);
  }

  return (
    <div
      className="modal fade"
      id="DelUser"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered ">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="ModalLabel">
              Reject
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <h6>Reason</h6>{" "}
            <input
              type="text"
              className="form-control"
              placeholder="Select User Name"
              defaultValue={text}
              onChange={(e) => {
                onTextChanged(e);
              }}
            />
          </div>
          <div className="end p-3 pt-1">
            <button
              type="button"
              className="btn text-danger btn-sm btn-radius px-3"
              onClick={(e) => {
                post_accept();
              }}
            >
              Reject
            </button>
            <button
              type="button"
              className="btn btn-outline-secondary btn-sm btn-radius ms-3 px-3"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
