import React, { useState, useEffect, useContext } from "react";
import { api } from "../../utils/Api";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { AppConfig } from "../../config";



function SellerSettlements() {
    const [settlements, setSettlements] = useState([]);
    const [filter_data, setFilterData] = useState("");
    const [page_no, SetPageNo] = useState(1);
    const [width, SetWidth] = useState(innerWidth);

    const [next_page, SetNextPage] = useState(false);
    const [viewpanel_index, setViewPanelIndex] = useState(null);

    const [sort_val, SetSort] = useState("")
    const [visible_content, SetVisibleContent] = useState(false);
    const [viewPanelData, setViewPanelData]: any = useState();
    const [viewPanel, setViewPanel] = useState(false);
    const [transaction_details, SetTransactionDetails]: any = useState({});
    const Spinner = () => <div className="loader-spinner"></div>;
    let newTimeoutId: any;


    useEffect(() => {
        let pass_data = {
            order_status: 1,
            seller_brand_id: "",
            seller_outlet_id: "",
            sort: "",
            page_no: page_no,
        };
        getSettlements(pass_data, filter_data);
    }, []);

    const sort = (val: any) => {
        disable();
        SetSort(val);
        SetPageNo(1)

        let pass_data = {
            sort: val,
            page_no: 1,
        };

        getSettlements(pass_data, filter_data);


    }

    function enable(ele: any, i: any) {
        setViewPanel(true);
        setViewPanelIndex(i);
        setViewPanelData(ele);
    }
    function disable() {
        setViewPanel(false);
        setViewPanelIndex(null);
        setViewPanelData();
    }

    async function getSettlements(get_data: any, search: any) {

        if (!get_data.load_more && !get_data.refresh) {
            SetVisibleContent(false);
        }

        let pass_data = {
            get: {
                sort: get_data.sort,
                page: get_data.page_no,
                search: search,
            },
        };
        let response: any = await api("/sellers/settlements", pass_data);
        if (response.status_code == 200) {
            disable()
            SetVisibleContent(true);

            if (response.response.hasOwnProperty("data")) {
                if (get_data.load_more) {
                    setSettlements((prevValue) => {
                        response?.response?.data?.map((ele: any) => {
                            prevValue.push(ele);
                        });
                        return [...prevValue];
                    });
                } else {
                    setSettlements(response.response.data);
                }
            }

            if (response.response.hasOwnProperty("next_page")) {
                SetNextPage(response.response.next_page);
                SetPageNo((prevalue) => {
                    prevalue = prevalue + 1;
                    return prevalue;
                });
            }
        }
    }

    const handleInputChange = (e: any) => {

        const searchValue = e.target.value;

        // Clear previous timeout
        if (newTimeoutId) {
            clearTimeout(newTimeoutId);
        }

        // Set a new timeout
        newTimeoutId = setTimeout(() => {
            SetPageNo(1)
            // Call your API with the search value
            setFilterData(searchValue);
            let pass_data = {
                sort: "",
                page_no: 1,
                // order_status:order_status
            };
            console.log("hi")
            getSettlements(pass_data, searchValue);
            disable();
        }, 1000); // 5 milliseconds delay
    };


    const SettlementsSection = () => {
        return (
            <div className="col-lg-6 sm-mt-6 sm-pb-5">
                <InfiniteScroll
                    dataLength={settlements.length}
                    next={() => {
                        let pass_data = {
                            seller_brand_id: "",
                            seller_outlet_id: "",
                            sort: "",
                            load_more: true,

                            page_no: page_no,
                        };
                        getSettlements(pass_data, filter_data);
                    }}
                    hasMore={next_page}
                    loader={
                        <div className="center m-5">
                            <div className="lds-dual-ring"></div>
                            <p className=" small ps-2">Just a second ...</p>
                        </div>
                    }
                >
                    {visible_content ? (
                        settlements.length != 0 ? (
                            settlements?.map((settlement: any, ord_index: any) => (
                                <div
                                    className="card cursor p-12 mb-2"
                                    key={ord_index}
                                    onClick={() => {
                                        enable(settlement, ord_index);
                                    }}
                                >
                                    <div className="d-flex mb-2">
                                        <div>
                                            <div>{settlement.brand_name}</div>
                                            <div className="text-muted">{settlement.outlet_name}</div>
                                            <div className="text-muted fs-11px">{settlement.time}</div>
                                        </div>
                                        <div className="ms-auto text-end">
                                            <div className="mb-1">Settlement ID: {settlement.id}</div>
                                            <div className="fw-600 text-success">&#x20b9;{settlement.amount}</div>
                                        </div>
                                    </div>
                                    <div className="mb-2">
                                        <div>Notes:</div>
                                        <div className="fs-11px text-muted">{settlement.notes ? settlement.notes : '-'}</div>
                                    </div>
                                    <div>Brand ID: {settlement.brand_id}, Outlet ID: {settlement.brand_id},   Shipment IDs: {settlement?.orders.map((order: any, i: number) => {
    return i === 0 ? order.shipment_ids.join(', ') : ', ' + order.shipment_ids.join(', ');
  })}
</div>
                                </div>
                            ))
                        ) : (
                            <div className="center p-3">
                                <div className="card p-3 w-100">
                                    <div className="w-100 text-center">
                                        <img
                                            src={require("../../assets/img/not-found.png")}
                                            className="w-20"
                                        />
                                    </div>
                                    <p className="center pt-3">No Settlements</p>
                                </div>
                            </div>
                        )
                    ) : (
                        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((ele: any) => (
                            <div className="card mb-2 p-3">
                                <div className="row">
                                    <div className="col-6 pb-2">
                                        <Skeleton height={10} width={80} />
                                    </div>
                                    <div className="col-6 text-end pb-2">
                                        <Skeleton height={30} width={100} />
                                    </div>
                                    <div className="col-6 pb-2">
                                        <Skeleton height={10} width={50} />
                                    </div>
                                    <div className="col-6 pb-2 text-end">
                                        <Skeleton height={10} width={120} />
                                    </div>
                                    <div className="col-6 pb-3 d-flex">
                                        <div className="pe-2">
                                            <Skeleton height={34} width={34} borderRadius={50} />
                                        </div>
                                        <div className="">
                                            <Skeleton height={10} width={100} count={2} />
                                        </div>
                                    </div>
                                    <div className="col-6 end pb-3">
                                        <Skeleton height={10} width={80} />
                                    </div>
                                    <div className="px-2 pb-3">
                                        <hr className="text-gray m-0" />
                                    </div>
                                    <div className="col-3 pb-2">
                                        <Skeleton height={10} width={80} />
                                        <Skeleton height={10} width={40} />
                                    </div>

                                    <div className="col-3 pb-2">
                                        <Skeleton height={10} width={80} />
                                        <Skeleton height={10} width={40} />
                                    </div>

                                    <div className="col-3 pb-2">
                                        <Skeleton height={10} width={80} />
                                        <Skeleton height={10} width={40} />
                                    </div>

                                    <div className="col-3 pb-2">
                                        <Skeleton height={10} width={80} />
                                        <Skeleton height={10} width={40} />
                                    </div>
                                </div>
                            </div>
                        ))

                    )}
                </InfiniteScroll>
            </div>
        );
    };

    const ViewPanelSection = () => {
        return (
            <div className="col-lg-6 sm-pb-5 sm-mt-6">
                <div className="card p-12 mb-2 right-panel">
                    <div className="py-1 mb-3 d-flex align-items-center cursor" onClick={disable}>
                        <img src={AppConfig.CDN_Media_url + '9d9ebfad431d9a9ea17ffba00d3c6ca1.svg'} className="img-fluid me-2" width="15" />
                        <h6 className="mb-0">Settlement Details</h6>
                    </div>
                    <div className="d-flex mb-2">
                        <div>
                            <div>{viewPanelData.brand_name}</div>
                            <div className="text-muted">{viewPanelData.outlet_name}</div>
                            <div className="text-muted fs-11px">{viewPanelData.time}</div>
                        </div>
                        <div className="ms-auto text-end">
                            <div className="mb-1">Settlement ID: {viewPanelData.id}</div>
                            <div className="fw-600 text-success">&#x20b9;{viewPanelData.amount}</div>
                        </div>
                    </div>
                    <div className="mb-3">
                        <div>Notes:</div>
                        <div className="fs-11px text-muted">{viewPanelData.notes ? viewPanelData.notes : '-'}</div>
                    </div>

                    <div className="mb-3">Brand ID: {viewPanelData.brand_id}, Outlet ID: {viewPanelData.brand_id}, Shipment IDs: {viewPanelData?.orders.map((order: any, i: number) => {
    return i === 0 ? order.shipment_ids.join(', ') : ', ' + order.shipment_ids.join(', ');
  })}</div>

                    <div className="mb-2">
                        <div className="mb-1 fw-600">ORDERS</div>
                        {viewPanelData?.orders.map((order: any, i: any) => (
                            <div className="border p-3 mb-2">
                                <div className="d-flex mb-2">
                                    <div>
                                    <div> Shipment IDs:{order?.shipment_ids.join(', ') }</div>
                                        <div>Order ID: {order?.order_item_id}</div>
                                        <div className="text-muted fs-11px">{order?.order_time}</div>
                                    </div>
                                    <div className="ms-auto text-end">
                                        <div className="mb-1">Paid Amount: <span className="fw-600">&#x20b9;{order?.amount}</span></div>
                                        <div>Eligible Amount: &#x20b9;{order?.settlement_eligible_amount}</div>
                                    </div>
                                </div>
                                <div className="mb-2">
                                    <div>Notes:</div>
                                    <div className="fs-11px text-muted">{order?.notes ? order?.notes : '-'}</div>
                                </div>
                                <div className="text-uppercase mb-1">Customer Details</div>
                                <div className="mb-2">
                                    <div className="d-flex">
                                        <div className="d-flex">
                                            {order?.customer?.profile_picture && (
                                                <img className="img-fluid me-2" width={35} src={order?.customer?.profile_picture} />
                                            )}
                                            <div>
                                                <div>{order?.customer?.name} #{order?.customer?.id}</div>
                                                <div className="text-muted">{order?.customer?.user_name}</div>
                                            </div>
                                        </div>
                                        <div className="ms-auto text-end">
                                            <div>Customer Bill Amount</div>
                                            <div>&#x20b9;{order?.total_amount_payable}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-2">
                                    <div>Sector</div>
                                    <div>{order?.sector}</div>
                                </div>

                            </div>
                        ))}
                    </div>

                    
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="head-bar row">
                <div className="col-md-6">
                    <h5 className="  mb-2">Seller Settlements</h5>
                </div>
                <div className="col-md-6 col-12">
                    <div className="d-flex">
                        <div className="input-icons form-group p-2">
                            <input
                                type="text"
                                className="form-control search-bar p-0"
                                placeholder="Search here ..."
                                onChange={handleInputChange}
                            />
                            <div className="cursor px-2 border-left">

                            </div>
                        </div>
                        <div className="dropdown">
                            <button
                                className="btn btn-white dropdown-toggle d-flex align-items-cetner"
                                type="button"
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <img
                                    src={require("../../assets/icon/filter.png")}
                                    className="icon-14px"
                                />

                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li>
                                    <a className="dropdown-item" href="#">
                                        <div
                                            onClick={() =>
                                                sort("asc")
                                            }
                                        >
                                            <p className="mb-0 p-2">Oldest</p>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item" href="#">
                                        <div
                                            onClick={() => sort("desc")}
                                        >
                                            <p className="mb-0 p-2">Newest</p>
                                        </div>
                                    </a>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="px-2 ps-lg-4 mt-2 py-2 sm-mt-3 mb-5 mb-lg-0">
                <div className="row">
                    <SettlementsSection />
                    {viewPanel && (
                        <ViewPanelSection />
                    )}
                </div>
            </div>
        </>
    )
}

export default SellerSettlements