import React, { useState, useEffect } from "react";
import bootstrap from "../../assets/libraries/bootstrap/js/bootstrap";
import { api } from "../../utils/Api";
let myModal : any;
export default function ModalCod(props : any) {
  const [close_data, SetCloseData] = useState({
    action: "close",
    value:"",
    index:"",
    demo_order:false
  });

  const [allow,setAllow] = useState("")

  

  useEffect(() => {
    console.log("ModelDeleteUserRoles On mount :", props);
    let myModal1 = document.getElementById("codAllowed");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      console.log("event :", event);
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      console.log("ModelDeleteUserRoles On props.open :", props.data.value);
      if(props.data.dm =="undefined"){
        setAllow("undefined")
      }else if(props.data.dm =="allowed"){
        setAllow("allowed")

      }else if(props.data.dm =="force_allowed"){
        setAllow("force_allowed")

      }else{
        setAllow("suspended")
      }
      myModal = new bootstrap.Modal(
        document.getElementById("codAllowed"),
        {}
      );
      myModal.show();
    }
  }, [props.open]);



  function StatusOnChange(val){
    setAllow(val)
  }
  async function Save() {

    let pass_data = {
    
      post: {

        status: allow,
        user_id: props.data.value
    
      }
    };
    console.log(pass_data)
    let data_res = await api("/settings/update_user_cod_order", pass_data);
  console.log(data_res)
    if (data_res.status_code == 200) {
    let item = close_data;
    console.log(item)
    item.action = "save";
    item.index= props.data.index;
    item.demo_order=data_res.response.cod_allowed

    // item.value =  data_res.response.data.cod_allowed_all_users;
    SetCloseData(item);
  
    myModal.hide();
    }
  }

  return (
    <div
      className="modal fade"
      id="codAllowed"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="  modal-title" id="ModalLabel">
            Update User COD Order
            </h6>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
          <div className="form-check form-check-inline">
  <input className="form-check-input" type="radio" name="inlineRadio61" id="inlineRadio61" value="option1" checked={allow=="undefined"} onClick={()=>{StatusOnChange("undefined")}}/>
  <label className="form-check-label" for="inlineRadio61">Undefined</label>
</div>
<div className="form-check form-check-inline">
  <input className="form-check-input" type="radio" name="inlineRadio62" id="inlineRadio62" value="option2" checked={allow=="allowed"} onClick={()=>{StatusOnChange("allowed")}}/>
  <label className="form-check-label" for="inlineRadio62">Allowed</label>
</div>
<div className="form-check form-check-inline">
  <input className="form-check-input" type="radio" name="inlineRadio63" id="inlineRadio63" value="option3" checked={allow=="suspended"} onClick={()=>{StatusOnChange("suspended")}}/>
  <label className="form-check-label" for="inlineRadio63">Suspended</label>
</div>
<div className="form-check form-check-inline">
  <input className="form-check-input" type="radio" name="inlineRadio64" id="inlineRadio64" value="option3" checked={allow=="force_allowed"} onClick={()=>{StatusOnChange("force_allowed")}}/>
  <label className="form-check-label" for="inlineRadio64">Force Allowed</label>
</div>


          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-success"
            
              onClick={
                // myModal.hide();
                Save}
                  // data-bs-dismiss="modal"
            >
              Save
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
