
import React, { useState, useEffect, useContext } from "react";

import InfiniteScroll from "react-infinite-scroll-component";
import { api } from "../../utils/Api";
import Skeleton from "react-loading-skeleton";
import { Ionicons, FontAwesome } from "@expo/vector-icons";
import ModalUserDetails from "../Orders/ModalUserDetails";
import DataContext from "../../utils/Context/DataContext";
import {

  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import {

  MaterialIcons,
  MaterialCommunityIcons,
} from "@expo/vector-icons";

import ModalCart from "./ModalCart";




function Cart() {
  const [visible_content, SetVisibleContent] = useState(false);
  const [filter_data, setFilterData] = useState("");
  const [cart_data, SetCartData] = useState([]);
  const [page_no, SetPageNo] = useState(1);
  const [order_status, SetOrderStatus] = useState("all");
  const [all, SetAll] = useState(true);
  const [pending, SetPending] = useState(false);
  const [process, SetProcess] = useState(false);
  const [shipped, SetShipped] = useState(false);
  const [loader, setLoader] = useState(false);
  const context: any = useContext(DataContext);

  const [user_modal, SetUserModal] = useState({
    is_open: false,
   id:""
  });
  const [cart_modal, setCartModal] = useState({
    is_open: false,
    data: {},
  });
  const [next_page, SetNextPage] = useState(false);
  const Spinner = () => <div className="loader-spinner"></div>;

  // const handleCall = (e,fullMobile) => {
  //   e.stopPropagation()
  //   if (fullMobile) {
  //     window.location.href = `tel:${fullMobile}`;
  //   }
  // };

  const sort = (val:any) =>{
   
  
    SetPageNo(1)
    let pass_data = {
      order_status: "order_converted",
      seller_brand_id: "",
      seller_outlet_id: "",
      sort: val,
      page_no: 1,
      type: "",
    };
    get_cart_details(pass_data, filter_data);


  }

  useEffect(() => {
    let pass_data = {
      order_status: "all",
      seller_brand_id: "",
      seller_outlet_id: "",
      sort: "",
      page_no: 1,
    };
    get_cart_details(pass_data, filter_data);
  }, []);


  async function get_cart_details(get_data: any, search: any) {

    if (!get_data.load_more && !get_data.refresh) {
      SetVisibleContent(false);
    }

    {
      get_data.refresh &&
        setLoader(true)
    }
    let pass_data = {
      get: {
        filter: get_data.order_status,
        page: get_data.page_no,
        sort:get_data.sort,

      },
    };
    let response: any = await api("/orders/get_cart", pass_data);
    console.log(response.response.hasOwnProperty("data"))
    if (response.status_code == 200) {
      setLoader(false)


      SetVisibleContent(true);

      if (response.response.hasOwnProperty("data")) {
        if (get_data.load_more) {
          SetCartData((prevValue) => {
            response?.response?.data?.map((ele: any) => {
              prevValue.push(ele);
            });
            return [...prevValue];
          });
        } else {
          SetCartData(response.response.data);

        }
      }
      // if(!get_data.hasOwnProperty("type")){
      if (response.response.hasOwnProperty("next_page")) {
        SetNextPage(response.response.next_page);
        SetPageNo((prevalue) => {
          prevalue = prevalue + 1;
          return prevalue;
        });
      }
      // }
    }
  }
  async function cus_number(mobile:any) {

    if (context?.app_data.application?.platform == "android") {
      window.open("tel:" + mobile);
    } else {
      window.location.href = "tel:" + mobile;
    }

}
  function openMap(dir_lat: any, dir_lng: any) {
    // window.open(
    //  "https://www.google.com/maps?q=" + dir_lat + "," + dir_lng,
    // "_blank"
    // );
  
    document.location.href =   "https://www.google.com/maps?q=" + dir_lat + "," + dir_lng,
    "_blank"
  }

  function generate_address(get_data: any) {
    let return_data: any = "";
    if (get_data.door_no != "") {
      return_data = return_data + get_data.door_no + ", ";
    }
    if (get_data.address != "") {
      return_data = return_data + get_data.address + ", ";
    }
    if (get_data.city != "") {
      return_data = return_data + get_data.city + ", ";
    }
    if (get_data.state != "") {
      return_data = return_data + get_data.state + ", ";
    }
    if (get_data.country != "") {
      return_data = return_data + get_data.country + " - ";
    }
    if (get_data.pincode != "") {
      return_data = return_data + get_data.pincode + ".";
    }

    return return_data;
  }

  
  const OrderSkeleton = () => {
    return (
      <div className="card p-3 cursor">
        <div className="row align-items-center">
          <div className="col-6 pb-3">
            <Skeleton height={10} width={50} />
          </div>
          <div className="col-6 text-end">
            <Skeleton height={30} width={100} />
          </div>
        </div>

        <div className="row pt-2">
          <div className="col-6 pb-2">
            <Skeleton height={10} width={50} />
            <Skeleton height={10} width={120} />
          </div>
          <div className="col-6 pb-2 text-end">
            <Skeleton height={10} width={120} />
            <Skeleton height={10} width={100} />
          </div>
        </div>

        <div className="pb-3 pt-2">
          <hr className="text-gray m-0" />
        </div>

        <div className=" pb-2">
          <Skeleton height={10} width={120} />
        </div>

        <div className="py-2">
          <div className="d-flex pb-3">
            <Skeleton height={18} width={18} />
            <div className="ps-2">
              <Skeleton height={10} width={120} />
              <Skeleton height={10} width={180} />
            </div>
          </div>
          <div className="d-flex pb-3">
            <Skeleton height={18} width={18} />
            <div className="ps-2">
              <Skeleton height={10} width={120} />
              <Skeleton height={10} width={180} />
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="">
      {/* Header */}
      <div className="head-bar row">
        <div className="d-flex">
        <div className="col-md-6">
          <h5 className="  mb-2">Cart</h5>
        </div>
        <div className="dropdown ms-auto">
            <button
              className="btn btn-white dropdown-toggle d-flex align-items-cetner"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src={require("../../assets/icon/filter.png")}
                className="icon-14px"
              />
          
            </button>
        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">

        <li>
                <a className="dropdown-item" href="#">
                  <div
                    onClick={() => sort("desc") }
                  >
                    <p className="mb-0 p-2">Newest</p>
                  </div>
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  <div
                    onClick={() => 
                      sort("asc")
                    }
                  >
                    <p className="mb-0 p-2">Oldest</p>
                  </div>
                </a>
              </li>
             
          
            </ul>
      </div>
          </div>
        {/* <div className="col-md-6 col-12">
        <div className="input-icons form-group p-2">
          <input
            type="text"
            className="form-control search-bar p-0"
            placeholder="Search here ..."
            onChange={handleInputChange}
          />
          <div className="cursor px-2 border-left">
            <Ionicons name="md-search" size={16} color="#ccc" />
          </div>
        </div>
      </div> */}

        <div className="mt-1 d-flex align-items-center bg-fff p-2 md-px-3 ">
          <div className="d-flex align-items-center scrollX">
            <button
              type="button"
              className={
                all
                  ? "btn btn-text header-tap me-1 px-3 "
                  : "btn btn-text text-black me-1 px-3"
              }
              onClick={() => {
                SetAll(!all);
                SetPending(false);
                SetProcess(false);
                SetShipped(false);

                SetOrderStatus("all");
                SetPageNo(1);
                let pass_data = {
                  order_status: 1,
                  seller_brand_id: "",
                  seller_outlet_id: "",
                  sort: "",
                  page_no: 1,
                  type: ""
                };
                get_cart_details(pass_data, filter_data);
              }}
            >
              All
            </button>

            <button
              type="button"
              className={
                pending
                  ? "btn btn-text header-tap me-1 px-3 "
                  : "btn btn-text text-black me-1 px-3"
              }
              onClick={() => {
                SetAll(false);
                SetPending(!pending);
                SetProcess(false);
                SetShipped(false);

                SetOrderStatus("abandoned");
                SetPageNo(1);
                let pass_data = {
                  order_status: "abandoned",
                  seller_brand_id: "",
                  seller_outlet_id: "",
                  sort: "",
                  page_no: 1,
                  type: "",
                };
                get_cart_details(pass_data, filter_data);
              }}
            >
              Abandoned
            </button>

            <button
              type="button"
              className={
                process
                  ? "btn btn-text header-tap me-1 px-3 "
                  : "btn btn-text text-black me-1 px-3"
              }
              onClick={() => {
                SetAll(false);
                SetProcess(!process);
                SetShipped(false);
                SetOrderStatus("order_converted");
                SetPageNo(1);

                SetPending(false);
                let pass_data = {
                  order_status: "order_converted",
                  seller_brand_id: "",
                  seller_outlet_id: "",
                  sort: "",
                  page_no: 1,
                  type: "",
                };
                get_cart_details(pass_data, filter_data);


              }}
            >
              Order Converted
            </button>
         
        
            {loader ?
              <button className="ms-auto btn btn-white dropdown-toggle d-flex align-items-cetner">
                <Spinner />
              </button> :
              <button className="ms-auto btn btn-white dropdown-toggle d-flex align-items-cetner"
                onClick={() => {


                  let pass_data = {
                    order_status: order_status,
                    seller_brand_id: "",
                    seller_outlet_id: "",
                    sort: "",
                    page_no: 1,
                    refresh: true,

                  };

                  SetPageNo(1)
                  get_cart_details(pass_data, filter_data);

                }}>
                <img
                  src={require("../../assets/icon/refresh.png")}
                  className="icon-20px"
                /></button>
            }

          </div>
          {/* filter */}
          {/* <div className="dropdown">
          <button
            className="btn btn-white dropdown-toggle d-flex align-items-cetner"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img
              src={require("../../assets/icon/filter.png")}
              className="icon-14px"
            />
            <p className="mb-0 text-dark d-md-block d-sm-none ps-2">Filter</p>
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <a className="dropdown-item" href="#">
                <div
                  onClick={() => {
                    SetAll(false);
              
                    SetPending(false);
                    SetProcess(false);
                    SetShipped(false);
                    SetOrderStatus(5);
                    SetPageNo(1);
                    let pass_data = {
                      order_status: 5,
                      seller_brand_id: "",
                      seller_outlet_id: "",
                      sort: "",
                      page_no: 1,
                    };
                    get_cart_details(pass_data, filter_data);
                  }}
                >
                  <p className="mb-0 p-2">Accepted</p>
                </div>
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#">
                <div
                  onClick={() => {
                    SetAll(false);
              
                    SetPending(false);
                    SetProcess(false);
                    SetShipped(false);
                    SetOrderStatus(6);
                    SetPageNo(1);
                    let pass_data = {
                      order_status: 6,
                      seller_brand_id: "",
                      seller_outlet_id: "",
                      sort: "",
                      page_no: 1,
                    };
                    get_cart_details(pass_data, filter_data);
                  }}
                >
                  <p className="mb-0 p-2">Rejected</p>
                </div>
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#">
                <div
                  onClick={() => {
                    SetAll(false);
                   
                    SetPending(false);
                    SetProcess(false);
                    SetShipped(false);
                    SetOrderStatus(7);
                    SetPageNo(1);
                    let pass_data = {
                      order_status: 7,
                      seller_brand_id: "",
                      seller_outlet_id: "",
                      sort: "",
                      page_no: 1,
                    };
                    get_cart_details(pass_data, filter_data);
                  }}
                >
                  <p className="mb-0 p-2">Active</p>
                </div>
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#">
                <div
                  onClick={() => {
                    SetAll(false);
             
                    SetPending(false);
                    SetProcess(false);
                    SetShipped(false);
                    SetOrderStatus(8);
                    SetPageNo(1);
                    let pass_data = {
                      order_status: 8,
                      seller_brand_id: "",
                      seller_outlet_id: "",
                      sort: "",
                      page_no: 1,
                    };
                    get_cart_details(pass_data, filter_data);
                  }}
                >
                  <p className="mb-0 p-2">Inavtive</p>
                </div>
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#">
                <div
                  onClick={() => {
                    SetAll(false);
              
                    SetPending(false);
                    SetProcess(false);
                    SetShipped(false);
                    SetOrderStatus(9);
                    SetPageNo(1);
                    let pass_data = {
                      order_status: 9,
                      seller_brand_id: "",
                      seller_outlet_id: "",
                      sort: "",
                      page_no: 1,
                    };
                    get_cart_details(pass_data, filter_data);
                  }}
                >
                  <p className="mb-0 p-2">Success</p>
                </div>
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#">
                <div
                  onClick={() => {
                    SetAll(false);
                 
                    SetPending(false);
                    SetProcess(false);
                    SetShipped(false);
                    SetOrderStatus(10);
                    SetPageNo(1);
                    let pass_data = {
                      order_status: 10,
                      seller_brand_id: "",
                      seller_outlet_id: "",
                      sort: "",
                      page_no: 1,
                    };
                    get_cart_details(pass_data, filter_data);
                  }}
                >
                  <p className="mb-0 p-2">Failed</p>
                </div>
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#">
                <div
                  onClick={() => {
                    SetAll(false);
                
                    SetPending(false);
                    SetProcess(false);
                    SetShipped(false);
                    SetOrderStatus(11);
                    SetPageNo(1);
                    let pass_data = {
                      order_status: 11,
                      seller_brand_id: "",
                      seller_outlet_id: "",
                      sort: "",
                      page_no: 1,
                    };
                    get_cart_details(pass_data, filter_data);
                  }}
                >
                  <p className="mb-0 p-2">Cancelled</p>
                </div>
              </a>
            </li>
          </ul>
        </div> */}
        </div>
      </div>

      {/* body */}
      <div className="px-3 sm-mt-2 sm-pt-4">
        <div className="mt-4">
          {console.log(page_no)}
          <InfiniteScroll
            dataLength={cart_data.length}
            next={() => {
              let pass_data = {
                order_status: order_status,

                seller_brand_id: "",
                seller_outlet_id: "",
                sort: "",
                load_more: true,

                page_no: page_no,
              };
              get_cart_details(pass_data, filter_data);
            }}
            hasMore={next_page}
            loader={
              <div className="center m-5">
                <div className="lds-dual-ring"></div>
                <p className=" small ps-2">Just a second ...</p>
              </div>
            }
          >
            {
              visible_content ? (
                cart_data.length != 0 ? (
                  <div className="row">
                    {cart_data?.map((ord_ele: any, ord_index: any) => (
                      <div className="col-md-4 col-12 mb-3" key={ord_index}>
                        {/* <Link to={"/orders/" + ord_ele.id}> */}


                        <div className="card p-3 cursor">
                          {/* <div className="row align-items-center">
                          <div className="col-6 pb-3">
                            <p className="text-dark fw-bold">
                              {ord_ele.sector}
                            </p>
                          </div>
                          <div className="col-6 end pb-3">
                            <button
                              className="btn btn-prime btn-sm"
                           
                            >
                              View Details
                            </button>
                          </div>
                        </div> */}
                          <div className="row">
                            <div className="col-6 pb-2">
                              <p className="text-dark fw-500">#{ord_ele.id}</p>
                            </div>
                            <div className="col-6 end pb-2">
                              <p className="extra-small">{ord_ele.date}</p>
                            </div>
                            <div className="col-6 pb-2 d-flex align-items-center">
                              {/* <img
                              src={require("../../assets/icon/scooter.png")}
                              className="icon-14px"
                            />
                            <p className="ps-2 text-gray">
                              {ord_ele.delivery_type}
                            </p> */}
                              <div className=" pb-2">


                                <div className="d-flex">
                                  {ord_ele.hasOwnProperty("customer") && ord_ele.customer.hasOwnProperty("profile") && ord_ele.customer.profile != null
                                    ? (
                                      <div className="pe-2">
                                        <img
                                          src={ord_ele.customer.profile}
                                          alt="16.a1d4f3f3"
                                          className="rounded-float-start"
                                          height="34"
                                          width="34"
                                          style={{ borderRadius: "50px" }}
                                        ></img>
                                      </div>
                                    ) : (
                                      <div className="pe-2">
                                        <img
                                          src={require("../../assets/icon/avatar.png")}
                                          className="rounded-float-start"
                                          height="34"
                                          width="34"
                                        />
                                      </div>
                                    )}
                                  <div className="">
                                    <p className="text-dark fw-bold fs-7">
                                      {ord_ele?.customer?.name}
                                    </p>
                                    <p>{ord_ele?.customer?.username}</p>
                                    <div className="d-flex">
                                      
                                      <p>{ord_ele?.customer?.mobile}   </p>



                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-6 text-end pb-2">
                              <div>{ord_ele?.customer?.gender}</div>
                              {/* <FontAwesome
                              name="circle"
                              size={10}
                              color={
                         
                                ord_ele.status_color
                              }
                            /> */}


                              {/* <div className="call-btn m-2" onClick={(e)=>handleCall(e,ord_ele?.customer?.full_mobile)} style={{ cursor: 'pointer' }}>
        <Ionicons
          name="md-call"
          size={18}
          color="#A80032"
        />
      </div> */}
                              <p


                                className={
                                  ord_ele.status == "Abandoned" ? "text-orange v-small ps-0" : ord_ele.status == "Removed"
                                    ? "text-grey v-small ps-0"
                                    : ord_ele.status ==
                                    "Order Placed"
                                    && "text-green v-small ps-0"



                                }
                              >
                                {ord_ele.status}
                              </p>
                              {ord_ele?.vendor_order &&
                          <p className="badge-2">
                                    Vendor Order
                                    </p>
                                 }
                            </div>
                    
                          </div>

                              <div className="mb-2">
                              {ord_ele.hasOwnProperty("customer") &&
                                        <a href={"tel:" + ord_ele?.customer?.mobile} className="btn btn-primary w-100">

                                          <Ionicons
                                            name="md-call"
                                            size={15}
                                            color="white"
                                          />
                                          <span className="ms-1">Call</span>
                                        </a>
                                      }
                              </div>

                          {(ord_ele?.statistics?.previously_ordered) && (
                            <div className="badge-2 text-success">Last Order Time: {ord_ele?.statistics?.previously_ordered}</div>
                          )}

<button className="btn btn-primary w-100 my-2"
           onClick={() => {
              SetUserModal((prevValue: any) => ({
                ...prevValue,
                is_open: true,
                id: ord_ele?.customer?.id,
              }));
           
          }}>View Customer User Details</button>

                          {/* <div className="pb-3 ">
                            <hr className="text-gray m-0" />
                          </div> */}

                          <div>
<div className="d-flex">
  <div>
                            <div className="text-dark pb-1"> {ord_ele.brand_name}</div>
                            <div className="text-grey pb-1">{ord_ele.outlet_name}</div>
                            </div>  
                            <div className="ms-auto">
                            <div className="text-grey pb-1">{ord_ele.section_name}</div>
{/* <div className="text-grey pb-1">{ord_ele.sector}</div> */}
</div>
</div>



{ord_ele.sector_id != "4" ?
<>
<h6>Temporary Address</h6>



<div className="d-flex">

<div className="text-grey pb-1">{ord_ele.map_address}</div>
<div className="ms-auto">
                              {/* <button
                                className="btn btn-prime btn-small "
                            
                              >  */}
                               {/* <span className="small">Map</span> */}
                               <a
  href={`https://www.google.com/maps?q=${ord_ele.lat},${ord_ele.lng}`}
  target="_blank" // Opens the link in a new tab
  rel="noopener noreferrer" // Improves security for external links
>
  <img
    src={require("../../assets/icon/map-arrow.png")}
    className="icon-24px"
    alt="Open Map"
  />
</a>

                                 {/* <img
                                
                                     onClick={() => {
                                      openMap(
                                        ord_ele.lat,
                                        ord_ele.lng
                                      );
                                    }}
                                      // src={require("../../assets/icon/map-arrow.png")}
                                      className="icon-24px"
                                    /> */}
                              {/* </button> */}
                            </div>
                            </div>

<div className="text-grey pb-1">{ord_ele.short_map_address}</div>
<hr/>                       
                              <div>
                        
                        


                        {      ord_ele.delivery_address.hasOwnProperty("map_address")    && 
              <div className="card p-3 mt-2">
  <h6>Delivery Address</h6>
  {ord_ele.delivery_address.name && (
    <div className="text-grey pb-1">{ord_ele.delivery_address.name}</div>
  )}
    {ord_ele.delivery_address.type && (
    <div className="text-grey pb-1">{ord_ele.delivery_address.type}</div>
  )}
     {ord_ele.delivery_address.mobile && (
      <div className="d-flex">
    <div className="text-grey pb-1">{ord_ele.delivery_address.mobile}</div>
    <div className=" ms-2 mb-1 cursor center ">

   
        <img
        onClick={() => {cus_number(ord_ele.delivery_address.mobile)}}
          src={require("../../assets/icon/phone.png")}
          className="bank-img"
        />
 
  </div>
  </div>
  )}
<div>
{ord_ele.delivery_address.door_no && 
<span>Door No: {ord_ele.delivery_address.door_no}</span> }
  { ord_ele.delivery_address.map_address && (
    <div className="d-flex">
    <div className="text-grey pb-1">
      {ord_ele.delivery_address.map_address}
    </div>
    <div className="ms-auto">
                              {/* <button
                                className="btn btn-prime btn-small "
                            
                              >  */}
                               {/* <span className="small">Map</span> */}
                               <a
  href={`https://www.google.com/maps?q=${ord_ele.delivery_address.lat},${ord_ele.delivery_address.lng}`}
  target="_blank" // Opens the link in a new tab
  rel="noopener noreferrer" // Improves security for external links
>
  <img
    src={require("../../assets/icon/map-arrow.png")}
    className="icon-24px"
    alt="Open Map"
  />
</a>
                                 {/* <img
                                
                                     onClick={() => {
                                      openMap(
                                        ord_ele.delivery_address.lat,
                                        ord_ele.delivery_address.lng
                                      );
                                    }}
                                      src={require("../../assets/icon/map-arrow.png")}
                                      className="icon-24px"
                                    /> */}
                              {/* </button> */}
                            </div>
    </div>

  )}
  </div>
  {ord_ele.delivery_address.landmark && (
    <div className="text-grey pb-1">{ord_ele.delivery_address.landmark}</div>
  )}
  {ord_ele.delivery_address.city && (
    <div className="text-grey pb-1">{ord_ele.delivery_address.city}</div>
  )}
  {ord_ele.delivery_address.state && (
    <div className="text-grey pb-1">{ord_ele.delivery_address.state}</div>
  )}
  {ord_ele.delivery_address.pincode && (
    <div className="text-grey pb-1">{ord_ele.delivery_address.pincode}</div>
  )}
</div>
}
{      ord_ele.changed_delivery_address.hasOwnProperty("map_address")    && 
              <div className="card p-3 mt-2">
  <h6>Changed Delivery Address</h6>
  {ord_ele.changed_delivery_address.name && (
    <div className="text-grey pb-1">{ord_ele.changed_delivery_address.name}</div>
  )}
    {ord_ele.changed_delivery_address.type && (
    <div className="text-grey pb-1">{ord_ele.changed_delivery_address.type}</div>
  )}
     {ord_ele.changed_delivery_address.mobile && (
      <div className="d-flex">
    <div className="text-grey pb-1">{ord_ele.changed_delivery_address.mobile}</div>
    <div className=" ms-2 mb-1 cursor center ">
   
        <img
        onClick={() => {cus_number(ord_ele.changed_delivery_address.mobile)}}
          src={require("../../assets/icon/phone.png")}
          className="bank-img"
        />
 
  </div>
  </div>
  )}
  {ord_ele.changed_delivery_address.door_no && 
    <span>Door No: {ord_ele.changed_delivery_address.door_no},</span> }
  { ord_ele.changed_delivery_address.map_address && (
    <div className="d-flex">
    <div className="text-grey pb-1">
 {ord_ele.changed_delivery_address.map_address}
    </div>
    <div className="ms-auto">
                              {/* <button
                                className="btn btn-prime btn-small "
                            
                              >  */}
                               {/* <span className="small">Map</span> */}
                               <a
  href={`https://www.google.com/maps?q=${ord_ele.changed_delivery_address.lat},${ord_ele.changed_delivery_address.lng}`}
  target="_blank" // Opens the link in a new tab
  rel="noopener noreferrer" // Improves security for external links
>
  <img
    src={require("../../assets/icon/map-arrow.png")}
    className="icon-24px"
    alt="Open Map"
  />
</a>
                                 {/* <img
                                
                                     onClick={() => {
                                      openMap(
                                        ord_ele.changed_delivery_address.lat,
                                        ord_ele.changed_delivery_address.lng
                                      );
                                    }}
                                      src={require("../../assets/icon/map-arrow.png")}
                                      className="icon-24px"
                                    /> */}
                              {/* </button> */}
                            </div>
    </div>

  )}
  {ord_ele.changed_delivery_address.landmark && (
    <div className="text-grey pb-1">{ord_ele.changed_delivery_address.landmark}</div>
  )}
  {ord_ele.changed_delivery_address.city && (
    <div className="text-grey pb-1">{ord_ele.changed_delivery_address.city}</div>
  )}
  {ord_ele.changed_delivery_address.state && (
    <div className="text-grey pb-1">{ord_ele.changed_delivery_address.state}</div>
  )}
  {ord_ele.changed_delivery_address.pincode && (
    <div className="text-grey pb-1">{ord_ele.changed_delivery_address.pincode}</div>
  )}
</div>
}

<div className=" mt-2 card  px-2 shadow">

         <div className="card px-3 pt-2 pb-1 mb-3 mt-3">
                                <h6 className="fw-bold">Products</h6>
                          {ord_ele.hasOwnProperty("products") &&
                          
                          ord_ele?.products?.map((p_ele: any) => (
                              <Accordion>
                                <AccordionSummary
                                  // className="my-0 px-3 py-0"
                                  // expandIcon={<ExpandMoreIcon />}
                                  // aria-controls="panel1d-content"
                                >
                                  <div className="row w-100 align-items-center">
                                    <div className="col-lg-7 col-5 ps-0">
                                      <div className="d-flex align-items-center cursor">
                                        <MaterialIcons
                                          name="keyboard-arrow-right"
                                          size={14}
                                          color="black"
                                        />
                                        {/* <Entypo name="dot-single" size={24} color="#555" /> */}
                                        <p className="oneline ps-1">
                                          {p_ele.product_name}
                                          {/* {p_ele.product_name == "" ||
                                      p_ele.product_name == null
                                        ? p_ele.variant_name
                                        : p_ele.product_name} */}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="col-lg-2 col-2 py-0 px-1">
                                      <p className="">× {p_ele?.quantity}</p>
                                    </div>
                                    <div className="col-lg-3 col-4 pe-0 ms-auto">
                                      <p className="text-right text-black ">
                                        ₹{" " + p_ele?.total_amount_with_qty_without_gst}
                                      </p>
                                      {p_ele?.status &&
                                      <div className="badge-2 text-success">
                                    
                                        {p_ele?.status}
                                        </div>
}
                                    </div>
                                  <p>{p_ele.addons.map(addon => `${addon.addon_name} (${addon.quantity})`).join(", ")}</p> 
                                  </div>
                               
                                </AccordionSummary>
                                <AccordionDetails className="px-2 w-100">
                                  {/* <div className="d-flex align-items-center cursor w-100 ps-3">
                                {p_ele.price.gst_amount != 0 ? (
                                  <p className="oneline ps-1 extra-small text-darkgray">
                                    GST
                                  </p>
                                ) : (
                                  <p className="oneline ps-1 extra-small text-darkgray">
                                    Price with GST
                                  </p>
                                )}
                                <div className="ms-auto">
                                  {p_ele.price.gst_amount != 0 ? (
                                    <p className="text-right text-darkgray v-small">
                                      ₹{" " + p_ele.price.gst_amount}
                                    </p>
                                  ) : (
                                    <p className="text-right text-darkgray v-small">
                                      ₹{" " + p_ele.price.price_with_gst}
                                    </p>
                                  )}
                                </div>
                              </div> */}


                                  <div className=" w-100">
                                    {p_ele.product_name == "" ? (
                                      <div className="row align-items-center pb-2 px-0">
                                        <p className="col-7 text-darkgray pe-1 ps-0">
                                          {p_ele.variant_name}{" "}
                                          {"  ( ₹" +
                                            p_ele?.price_without_gst +
                                            ")"}{" "}
                                        </p>
                                        <p className="col-2 text-darkgray extra-small">
                                          X {p_ele.quantity}
                                        </p>
                                        <p className="text-end col-3 text-darkgray p-0">
                                          hi
                                          {
                                            p_ele?.total_amount_with_qty_without_gst
                                          }
                                        </p>
                                      </div>
                                    ) : (
                                      <div className="row align-items-center pb-2 px-0">
                                        <p className="col-7 text-darkgray pe-1 ps-0">
                                          {p_ele.product_name || p_ele.variant_name}{" "}
                                          {"  ( ₹" +
                                            // p_ele.price.price_without_gst +
                                            ")"}{" "}
                                        </p>
                                        <p className="col-2 text-darkgray extra-small">
                                          X {p_ele.quantity}
                                        </p>
                                        <p className="col-3 text-end text-darkgray  p-0">
                                          ₹{" "}
                                          {
                                            p_ele?.total_amount_with_qty_without_gst
                                          }
                                        </p>
                                      </div>
                                    )}
                                    <div className="row align-items-center pb-2 px-0">
                                      <p className="col-7 text-darkgray ps-0">
                                        GST Amount{" "}
                                        {/* {"%" + p_ele.price.gst_percent} /{" "} */}
                                        {/* {"  ( ₹" + p_ele.gst_amount + ")"}{" "} */}
                                      </p>
                                      <p className="col-2 text-darkgray extra-small">
                                        X {p_ele.quantity}
                                      </p>
                                      <p className="col-3 text-end text-darkgray p-0">
                                        ₹{" "}
                                        {p_ele?.total_gst_amount_with_qty}
                                      </p>
                                    </div>
                                  
                                    {/* Sub Total Product Amount */}
                                    <div className="row align-items-center pb-2 pt-3 border-top-dot">
                                      <p className="text-darkgray col-8 ps-0">
                                        Sub Total Product Amount
                                      </p>
                                      <p className="col-4 text-end ms-auto text-darkgray pe-0">
                                        ₹{" "}
                                        {
                                          p_ele?.total_amount_with_qty_without_gst
                                        }
                                      </p>
                                    </div>
                                    {/* Total Product GST Amount */}
                                    <div className="row align-items-center pb-2">
                                      <p className="text-darkgray col-8 ps-0">
                                        Total Product GST Amount
                                      </p>
                                      <p className="col-4 text-end ms-auto text-darkgray pe-0">
                                        ₹{" "}
                                        {p_ele?.total_gst_amount_with_qty}
                                      </p>
                                    </div>
                                    {/* Total Product Amount */}
                                    {/* <div className="row align-items-center pb-2">
                                      <p className="text-darkgray col-8 ps-0">
                                        Total Product Amount
                                      </p>
                                      <p className="col-4 text-end ms-auto text-darkgray pe-0">
                                        ₹{" "}
                                        {
                                          p_ele.total_amount_with_qty_with_gst
                                        }
                                      </p>
                                    </div> */}
                                    {/* Total Payable Amount */}
                                    <div className="row align-items-center py-2">
                                      <p className="text-dark col-8 ps-0">
                                        Total Payable Amount
                                      </p>
                                      <p className="col-4 text-end ms-auto text-dark pe-0">
                                        ₹ {p_ele?.total_amount_payable}
                                      </p>
                                    </div>
                                  </div>
                                  <h6>Addons</h6>
                                  {p_ele?.addons?.map((addon: any) => (
                                  <Accordion>
                                    
                                <AccordionSummary
                                  // className="my-0 px-3 py-0"
                                  // expandIcon={<ExpandMoreIcon />}
                                  // aria-controls="panel1d-content"
                                >
                                  <div className="row w-100 align-items-center">
                                    <div className="col-lg-7 col-5 ps-0">
                                      <div className="d-flex align-items-center cursor">
                                        <MaterialIcons
                                          name="keyboard-arrow-right"
                                          size={14}
                                          color="black"
                                        />
                                        {/* <Entypo name="dot-single" size={24} color="#555" /> */}
                                        <p className="oneline ps-1">
                                          {addon.addon_name}
                                          {/* {p_ele.product_name == "" ||
                                      p_ele.product_name == null
                                        ? p_ele.variant_name
                                        : p_ele.product_name} */}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="col-lg-2 col-2 py-0 px-1">
                                      <p className="">× {addon?.quantity}</p>
                                    </div>
                                    <div className="col-lg-3 col-4 pe-0 ms-auto">
                                      <p className="text-right text-black ">
                                        ₹{" " + addon?.total_amount_with_qty_without_gst}
                                      </p>
                                
                                    </div>
                                  </div>
                                </AccordionSummary>
                                <AccordionDetails className="px-2 w-100">
                                  {/* <div className="d-flex align-items-center cursor w-100 ps-3">
                                {p_ele.price.gst_amount != 0 ? (
                                  <p className="oneline ps-1 extra-small text-darkgray">
                                    GST
                                  </p>
                                ) : (
                                  <p className="oneline ps-1 extra-small text-darkgray">
                                    Price with GST
                                  </p>
                                )}
                                <div className="ms-auto">
                                  {p_ele.price.gst_amount != 0 ? (
                                    <p className="text-right text-darkgray v-small">
                                      ₹{" " + p_ele.price.gst_amount}
                                    </p>
                                  ) : (
                                    <p className="text-right text-darkgray v-small">
                                      ₹{" " + p_ele.price.price_with_gst}
                                    </p>
                                  )}
                                </div>
                              </div> */}
                                  <div className=" w-100">
                                    {addon.product_name == "" ? (
                                      <div className="row align-items-center pb-2 px-0">
                                        <p className="col-7 text-darkgray pe-1 ps-0">
                                          {addon.variant_name}{" "}
                                          {"  ( ₹" +
                                            addon?.price_without_gst +
                                            ")"}{" "}
                                        </p>
                                        <p className="col-2 text-darkgray extra-small">
                                          X {addon.quantity}
                                        </p>
                                        <p className="text-end col-3 text-darkgray p-0">
                                          hi
                                          {
                                            addon?.total_amount_with_qty_without_gst
                                          }
                                        </p>
                                      </div>
                                    ) : (
                                      <div className="row align-items-center pb-2 px-0">
                                        <p className="col-7 text-darkgray pe-1 ps-0">
                                          {addon.product_name || addon.variant_name}{" "}
                                          {"  ( ₹" +
                                            // p_ele.price.price_without_gst +
                                            ")"}{" "}
                                        </p>
                                        <p className="col-2 text-darkgray extra-small">
                                          X {addon.quantity}
                                        </p>
                                        <p className="col-3 text-end text-darkgray  p-0">
                                          ₹{" "}
                                          {
                                            addon?.total_amount_with_qty_without_gst
                                          }
                                        </p>
                                      </div>
                                    )}
                                    <div className="row align-items-center pb-2 px-0">
                                      <p className="col-7 text-darkgray ps-0">
                                        GST Amount{" "}
                                        {/* {"%" + p_ele.price.gst_percent} /{" "} */}
                                        {/* {"  ( ₹" + p_ele.gst_amount + ")"}{" "} */}
                                      </p>
                                      <p className="col-2 text-darkgray extra-small">
                                        X {addon.quantity}
                                      </p>
                                      <p className="col-3 text-end text-darkgray p-0">
                                        ₹{" "}
                                        {addon?.total_gst_amount_with_qty}
                                      </p>
                                    </div>
                                  
                                    {/* Sub Total Product Amount */}
                                    <div className="row align-items-center pb-2 pt-3 border-top-dot">
                                      <p className="text-darkgray col-8 ps-0">
                                        Sub Total Product Amount
                                      </p>
                                      <p className="col-4 text-end ms-auto text-darkgray pe-0">
                                        ₹{" "}
                                        {
                                          addon?.total_amount_with_qty_without_gst
                                        }
                                      </p>
                                    </div>
                                    {/* Total Product GST Amount */}
                                    <div className="row align-items-center pb-2">
                                      <p className="text-darkgray col-8 ps-0">
                                        Total Product GST Amount
                                      </p>
                                      <p className="col-4 text-end ms-auto text-darkgray pe-0">
                                        ₹{" "}
                                        {addon?.total_gst_amount_with_qty}
                                      </p>
                                    </div>
                                    {/* Total Product Amount */}
                                    {/* <div className="row align-items-center pb-2">
                                      <p className="text-darkgray col-8 ps-0">
                                        Total Product Amount
                                      </p>
                                      <p className="col-4 text-end ms-auto text-darkgray pe-0">
                                        ₹{" "}
                                        {
                                          p_ele.total_amount_with_qty_with_gst
                                        }
                                      </p>
                                    </div> */}
                                    {/* Total Payable Amount */}
                                    <div className="row align-items-center py-2">
                                      <p className="text-dark col-8 ps-0">
                                        Total Payable Amount
                                      </p>
                                      <p className="col-4 text-end ms-auto text-dark pe-0">
                                        ₹ {addon?.total_amount_payable}
                                      </p>
                                    </div>
                                  </div>
                                </AccordionDetails>
                              </Accordion>
                                 ) )}
                                </AccordionDetails>
                              </Accordion>
                            ))}
                        </div>
                        </div>
           
     
                          
                              </div>
                              </>

                              :
                              <div>
                             {ord_ele.pickupdrop.map((pickupdrop,i) =>(
<div>
  {/* <div>{pickupdrop.calculated_forward_distance_kms}</div> */}
  {/* <div>{pickupdrop.convenience_fee}</div>


  <div>{pickupdrop.delivery_charges}</div> */}
  <p className="extra-small text-gray">
  Forward Distance Duration
                          </p>
                          <p className="pt-1 text-dark fw-bold">
                          {pickupdrop.forward_distance_duration}
                          </p>
                          <p className="extra-small text-gray">
  Id
                          </p>
                          <p className="pt-1 text-dark fw-bold">
                          {pickupdrop.id}
                          </p>
    <p className="extra-small text-gray">
    Status
                            </p>
                          <p className="pt-1 text-dark fw-bold">

                            {pickupdrop.status}
                            </p>
  {/* <div>{pickupdrop.package_content}</div> */}

  {/* <div>{pickupdrop.total_amount}</div> */}



  <div className="card pt-3 px-3 pb-1 mb-2">
                        <h6 className="fw-bold">Package Details</h6>
                        <div className="pt-3">
                          <p className="extra-small text-gray">
                            Package Content
                          </p>
                          <p className="pt-1 text-dark fw-bold">
                            {pickupdrop.package_content}
                          </p>
                        </div>
                        <div className="pt-3">
                          <p className="extra-small text-gray">Package Value</p>
                          <p className="pt-1 text-dark fw-bold">
                            {" "}
                            ₹{" "}
                            {pickupdrop.total_amount}
                          </p>
                        </div>
                        <div className="pt-3">
                          <p className="extra-small text-gray">Calculated Forward Distance kms</p>
                          <p className="pt-1 text-dark fw-bold">
                            {pickupdrop.calculated_forward_distance_kms} km
                          </p>
                        </div>
                        <div className="pt-3">
                          <p className="extra-small text-gray">delivery_charges</p>
                          <p className="pt-1 text-dark fw-bold">
                            {pickupdrop.delivery_charges}
                          </p>
                        </div>
                        <div className="pt-3">
                          <p className="extra-small text-gray">convenience_fee</p>
                          <p className="pt-1 text-dark fw-bold">
                            {pickupdrop.convenience_fee}
                          </p>
                        </div>
                        <div className="pt-3">
                          <p className="extra-small text-gray">Calculated Forward Distance kms</p>
                          <p className="pt-1 text-dark fw-bold">
                            {pickupdrop.calculated_forward_distance_kms}
                          </p>
                        </div>
                        <div className="pt-3">
                          <p className="extra-small text-gray">Calculated Forward Distance kms</p>
                          <p className="pt-1 text-dark fw-bold">
                            {pickupdrop.calculated_forward_distance_kms} km
                          </p>
                        </div>
                        <div className="pt-3">
                          <p className="extra-small text-gray">Calculated Forward Distance kms</p>
                          <p className="pt-1 text-dark fw-bold">
                            {pickupdrop.calculated_forward_distance_kms} km
                          </p>
                        </div>
                        <div className="pt-3">
                          <p className="extra-small text-gray">forward_distance_duration</p>
                          <p className="pt-1 text-dark fw-bold">
                            {pickupdrop.forward_distance_duration} min
                          </p>
                        </div>
                      </div>
                        <div className="card pt-3 px-3 pb-1 mb-2">
                          <div className="d-flex align-items-center pb-2">
                            <h6 className="fw-bold">Pickup Location</h6>
                            <div className="ms-auto">

                            <a
  href={`https://www.google.com/maps?q=${pickupdrop?.pickup?.lat},${pickupdrop?.pickup?.lng}`}
  target="_blank" // Opens the link in a new tab
  rel="noopener noreferrer" // Improves security for external links
>
<span className="small">Map</span>

</a>

                              {/* <button
                                className="btn btn-prime btn-small "
                                
                                onClick={() => {
                                  openMap(
                                    pickupdrop?.pickup?.lat,
                                    pickupdrop?.pickup?.lng
                                  );
                                }}
                              >

                                <span className="small">Map</span>
                              </button> */}
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-2">
                              <img
                                src={require("../../assets/icon/up-arrow.png")}
                                className="type-img"
                              />
                            </div>
                            <div className="ps-2 col-10">
                              <div className="pb-2 border-bottom">
                                <p className="text-dark fw-bold"></p>
                                <p className="small text-gray pt-1">
                                  {pickupdrop.pickup
                                    .door_no &&
                                    pickupdrop.pickup.address
                                    ? generate_address(
                                      pickupdrop.pickup
                                      )
                                    : pickupdrop.pickup.map_address}
                                </p>
                              </div>

                              <div className="pt-2 d-flex align-items-center">
                                <div>
                                  <p className="text-dark fw-bold">
                                    {" "}
                                    {
                                      pickupdrop.pickup.name
                                    }{" "}
                                   
                                  </p>
                                  <p className="text-black pt-1">
                                    {pickupdrop.pickup.mobile}
                                  </p>
                                </div>
                                {pickupdrop.pickup.mobile ? (
                                  <div className="ms-auto">
                                           <a href={"tel:" +pickupdrop.pickup.mobile} style={{ textDecoration: 'none' }}>
                                    <div className="call-btn"
                                    
                                    // onClick={() => {
                              
                                    //   call_func(
                                    //     order_data.id,
                                    //   "customer_pickup"
                                    //   );
                                    // }}
                                    >
                                      <Ionicons
                                        name="md-call"
                                        size={18}
                                        color="#A80032"
                                      />
                                    </div>
                                    </a>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                    
                      <div className="card pt-3 px-3 pb-1 mb-2">
                        <div className="d-flex align-items-center pb-2">
                          <h6 className="fw-bold">Drop Location</h6>
                          <div className="ms-auto">
                          <a
  href={`https://www.google.com/maps?q=${pickupdrop?.delivery?.lat},${pickupdrop?.delivery?.lng}`}
  target="_blank" // Opens the link in a new tab
  rel="noopener noreferrer" // Improves security for external links
>
<span className="small">Map</span>

</a>
                            {/* <button
                              className="btn btn-prime btn-small "
                              onClick={() => {
                                openMap(
                                  pickupdrop?.delivery?.lat,
                                  pickupdrop?.delivery?.lng
                                );
                              }}
                            >
                              <span className="small">Map</span>
                            </button> */}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-2">
                            <img
                              src={require("../../assets/icon/up-arrow.png")}
                              className="type-img"
                            />
                          </div>
                          <div className="ps-2 col-10">
                            <div className="pb-2 border-bottom">
                              <p className="text-dark fw-bold"></p>
                              <p className="small text-gray">
                                {pickupdrop?.delivery?.door_no &&
                                pickupdrop?.delivery?.address
                                  ? generate_address(
                                    pickupdrop?.delivery
                                    )
                                  : pickupdrop?.delivery?.map_address}
                              </p>
                            </div>

                            <div className="pt-2 d-flex align-items-center">
                              <div>
                                <p className="text-dark fw-bold">
                                  {
                                  pickupdrop?.delivery?.name
                                  }{" "}
                                 
                                </p>
                                <p className="text-black pt-1">
                                  {pickupdrop?.delivery?.mobile}
                                </p>
                              </div>
                              {pickupdrop?.delivery?.mobile ? (
                                <div className="ms-auto">
                                           <a href={"tel:" +pickupdrop?.delivery?.mobile} style={{ textDecoration: 'none' }}>

                                  <div className="call-btn"
                              
                                  >
                                    <Ionicons
                                      name="md-call"
                                      size={18}
                                      color="#A80032"
                                    />
                                  </div>
                                </a>

                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                      </div>
))}
</div>

}
                          
                          </div>
                          <div className="mb-2">
          <div className="mt-2 card pt-3 px-3 pb-1">
            <div className="py-1">
              <div className="row px-0">
            
<b>Seller Instruction</b>

<div>{ord_ele?.seller_instructions?.length>0?
ord_ele.seller_instructions.map((ele,i) =>(
  <>
<p>{ele.message}</p>
  <span
  className={
    ele.deleted &&
      "badge-1 me-2 badge-red-2"
  }
>
  {ord_ele?.status}
</span>
</>
)):"-"
}</div>

<b>Delivery Instruction</b>

<div>{ord_ele?.delivery_instructions?.length>0?
ord_ele.delivery_instructions.map((ele,i) =>(
  <>
<p>{ele.message}</p>
{ele.deleted &&
  <span
  className={
    ele.deleted &&
      "badge-1 me-2 badge-red-2"
  }
>
 Removed
</span>}
</>
)):"-"
}</div>




              
              </div>
            </div>
          </div>
        </div>
                          <hr />
                                <b> Ecommerce Cart Response:{" "}</b>
                                <ul>
                                  <div>{ord_ele?.ecommerce_cart_response.map((ele, i) => (

                                    <li>{ele.response}<p>{ele.notes}</p><p>{ele.create_time}</p></li>
                                  ))}</div>
                                </ul>
                                
                          {ord_ele.hasOwnProperty("customer") &&
                            (ord_ele.ecommerce_cart_response.length > 0 ?

                              <button
                                className="btn btn-secondary btn-sm mt-2"
                                onClick={() => {
                                  let set_data = {
                                    value: ord_ele.customer.id,
                                    dm: ord_ele.cod_allowed,
                                    data: cart_data,




                                  };
                                  setCartModal({
                                    is_open: true,
                                    data: set_data,
                                  });
                                }}
                              >
                                Update Cart Response
                              </button> :
                              <button
                                className="btn btn-blue btn-sm mt-2"
                                onClick={() => {
                                  let set_data = {
                                    value: ord_ele.customer.id,
                                    dm: ord_ele.cod_allowed,
                                    data: cart_data,




                                  };
                                  setCartModal({
                                    is_open: true,
                                    data: set_data,
                                  });
                                }}
                              >
                                Update Cart Response
                              </button>)}

<hr/>
                              <div className="d-flex">

<div
className={
!ord_ele.inshop_order 
  ? "p-2 bg-fff radius light-shadow"
  : "p-2"
}
>
<img
src="https://cdn2.1superapp.com/images/f20407a212488dc88d921b7dacd70e5d.png"
className={
  !ord_ele.inshop_order 
    ? "img-opacity cart-deli-img"
    : "cart-deli-img"
}
/>
<p className="pt-2 text-gray fs-7px capitalize">
Inshop order 
</p>
</div>
<div
className={
!ord_ele.hyperlocal_order 
  ? "p-2 bg-fff radius light-shadow"
  : "p-2"
}
>
<img
src="https://cdn2.1superapp.com/images/6e745f7dbe2d92dcab600140650843b6.png"
className={
  !ord_ele.hyperlocal_order 
    ? "img-opacity cart-deli-img"
    : "cart-deli-img"
}
/>
<p className="pt-2 text-gray fs-7px capitalize">
Hyperlocal Order 
</p>
</div>
<div
className={
!ord_ele.takeaway_order 
  ? "p-2 bg-fff radius light-shadow"
  : "p-2"
}
>
<img
src="https://cdn2.1superapp.com/images/dbbbaf218748024fc60be0c19d4d6fb1.png"
className={
  !ord_ele.takeaway_order 
    ? "img-opacity cart-deli-img"
    : "cart-deli-img"
}
/>
<p className="pt-2 text-gray fs-7px capitalize">
Takeaway order
</p>
</div>
<div
className={
!ord_ele.courier_order 
  ? "p-2 bg-fff radius light-shadow"
  : "p-2"
}
>
<img
src="https://cdn2.1superapp.com/images/95baaabf0277cc4ce7630810a0485cdf.png"
className={
  !ord_ele.courier_order 
    ? "img-opacity cart-deli-img"
    : "cart-deli-img"
}
/>
<p className="pt-2 text-gray fs-7px capitalize">
Courier order
</p>
</div>
</div>
                          {/* <button
                            className="btn btn-blue btn-sm  mt-2"
                            onClick={() => {
                                let set_data = {
                                    value: ord_ele.customer.id,
                                    dm: ord_ele.cod_allowed,
                                    data:cart_data,
                                   
                                    
                                  
                                    
                                  };
                                  setCartModal({
                                    is_open: true,
                                    data: set_data,
                                  });
                            }}
                          >
                            Update Cart Response
                          </button>     */}
                        </div>
                        {/* </Link> */}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="center p-3">
                    <div className="card p-3 w-100">
                      <div className="w-100 text-center">
                        <img
                          src={require("../../assets/img/not-found.png")}
                          className="w-20"
                        />
                      </div>
                      <p className="center pt-3">No Cart Details data</p>
                    </div>
                  </div>
                )
              ) : (
                <div className="row">
                  {[1, 2, 3, 4, 5, 6].map(() => (
                    <div className="col-md-4 col-12 mb-3">
                      <OrderSkeleton />
                    </div>
                  ))}
                </div>
              )
            }
          </InfiniteScroll>
        </div>
        <ModalCart
          open={cart_modal.is_open}
          data={cart_modal.data}

          close={(data: any) => {
            console.log("Edit Outlet Modal On Close :", data);
            setCartModal({
              is_open: false,
              data: {},

            });
            if (data.action == "save") {
              console.log(data.index)
              console.log(data.data)
              const filteredItems = data.data.filter(item => item?.customer?.id === data.index);


              SetCartData((prevValue) => {
                const newValue = [...prevValue];

                filteredItems.forEach(item => {
                  item.ecommerce_cart_response = data.demo_order;
                });
                return newValue;
              });


            }
          }}
        />
        <ModalUserDetails
open={user_modal.is_open}
id={user_modal.id}
close={(data: any) => {
  SetUserModal({
      is_open: false,
      id  :"",
  });
 }}
 />
      </div>

    </div>
  )
}

export default Cart