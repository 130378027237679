import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import DataContext from "../../utils/Context/DataContext";
import "./Header.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { AntDesign, Entypo, Ionicons, MaterialIcons } from "@expo/vector-icons";

// import { withTranslation } from "react-i18next";

import en from "../../utils/locales/en.json";
import ta from "../../utils/locales/ta.json";

const animatedComponents = makeAnimated();

export default function Header(props: any) {
  const { t } = props;

  const context = useContext(DataContext);
  const [selected_language, SetSelectedLanguage] = useState({
    label: "English",
    value: "en",
  });
  const language_option = [
    {
      label: "English",
      value: "en",
    },
    {
      label: "Tamil",
      value: "ta",
    },
  ];

  useEffect(() => {
  
  }, []);

  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar fixed-top navbar-light navbar-custom ">
        <div className="row w-100 align-items-center">
          <div className="col-md-6">
            <div className="d-flex align-items-center py-2 pe-1">
              <div
                className="p-2 me-1 cursor center d-sm-none d-md-block"
                onClick={() => {
                  // if (props.menu_click(true)) {
                  //   props.menu_click(false);
         
                  // } else {
                  //   props.menu_click(true);
                  // }
               
                  props.menu_click(!props.is_menu_open);
                }}
              >
                <MaterialIcons name="menu" size={22} color="#000" />
              </div>
              <h5 className="mb-0 fw-bold">Ecommerce Admin</h5>
            </div>
          </div>

          <div className="col-md-6 pe-0 d-lg-block d-md-none text-end">
            <div className="sidebar-options dropdown">
              <div className="">
                <Select
                  components={animatedComponents}
                  placeholder="Select Language"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  options={language_option}
                  value={selected_language}
                  onChange={(e) => {
        
                    if (e.value == "en") {
                 

                      localStorage.setItem("language_data", JSON.stringify(en));
                    } else {
                     
                      localStorage.setItem("language_data", JSON.stringify(ta));
                    }

                    // //
                    SetSelectedLanguage(e);
                    let language = e.value;
                    props.i18n.changeLanguage(language);
                  }}
                />
              </div>
              <Link
                to="/"
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                title="Home"
              >
                <img
                  src={require("../../assets/icon/svg/home1.svg")}
                  className="header-icon-img"
                />
                {/* <i className="fas fa-home"></i> */}
              </Link>

              <button
                type="button"
                className="remove-after dropdown-toggle"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown tooltip"
                aria-expanded="false"
                data-bs-placement="bottom"
                title="Notification"
              >
                <img
                  src={require("../../assets/icon/svg/bell1.svg")}
                  className="header-icon-img"
                />
                {/* <i className="far fa-bell"></i> */}
              </button>
              <Link
                to=""
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                title="Logout"
              >
                <img
                  src={require("../../assets/icon/svg/log-out1.svg")}
                  className="header-icon-img"
                />
                {/* <i className="fas fa-sign-out-alt"></i>  */}
              </Link>
              <div
                className="nav-notification p-3 dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                <div className="nav-notification-content">
                  <a href="#" className="notification-text">
                    Nirmal Durai just sent a new comment!
                  </a>
                  <a href="#" className="notification-text">
                    Nirmal Durai just sent a new comment!
                  </a>
                  <a href="#" className="notification-text">
                    Nirmal Durai just sent a new comment!
                  </a>
                  <a href="#" className="notification-text">
                    Nirmal Durai just sent a new comment!
                  </a>
                  <a href="#" className="notification-text">
                    Nirmal Durai just sent a new comment!
                  </a>
                  <a href="#" className="notification-text">
                    Nirmal Durai just sent a new comment!
                  </a>
                  <a href="#" className="notification-text">
                    Nirmal Durai just sent a new comment!
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

// export default withTranslation()(Header);
