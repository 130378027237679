import React, { useState, useEffect } from "react";
import bootstrap from "../../assets/libraries/bootstrap/js/bootstrap";
import { api } from "../../utils/Api";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
let myModal : any;
export default function ModalViewAll(props : any) {
  const [close_data, SetCloseData] = useState({
    action: "close",
    value:"",
    index:"",
    demo_order:false
  });
  const [visible_content, SetVisibleContent] = useState(false);
  const [next_page, SetNextPage] = useState(false);

  const [allow,setAllow] = useState("");
  const [desc,setDesc] = useState("")
  const [notes,setNotes] = useState([])
  const [all_deatils,setAllDetails] = useState([])
  const [page_no, SetPageNo] = useState(1);

  const [note_id,setNoteId] = useState("")




  const handleInputChange = (e: any) => {
    const searchValue = e.target.value;
    setDesc(searchValue);
  }

  useEffect(() => {
    console.log("ModelDeleteUserRoles On mount :", props);
    let myModal1 = document.getElementById("view");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      console.log("event :", event);
      setDesc("");
      SetPageNo(1);
      setAllDetails([])
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      // console.log("ModelDeleteUserRoles On props.open :", props.data.value);
      // if(props.data.dm ==true){
      //   setAllow("on")
      // }else{
      //   setAllow("off")

      // }
      let pass_data = {
        load_more: true,

        page_no: page_no,

      };
      get_view(pass_data)
      myModal = new bootstrap.Modal(
        document.getElementById("view"),
        {}
      );
      myModal.show();
    }
  }, [props.open]);

  async function get_view(get_data: any) {
 console.log(props)
    let pass_data = {
    
      get: {

       id:props.id,
       page: get_data.page_no,

    
      }
    };
    let response: any = await api("/settings/superapp_user_device_all_details",pass_data);
    if (response.status_code == 200) {
      SetVisibleContent(true);
      console.log(response)
      // setAllDetails(response.response.data)

      if (response.response.hasOwnProperty("data")) {
        if (get_data.load_more) {
          setAllDetails((prevValue) => {
            response?.response?.data?.map((ele: any) => {
              prevValue.push(ele);
            });
            return [...prevValue];
          });
        } else {
          setAllDetails(response.response.data);
        }
      }
      if (response.response.hasOwnProperty("next_page")) {
        SetNextPage(response.response.next_page);
        SetPageNo((prevalue) => {
          prevalue = prevalue + 1;
          return prevalue;
        });
      }
    }
  }


  async function Save() {

    let pass_data = {
    
      post: {

        notes: desc,
        user_id: props.data.value,
        response: note_id
    
      }
    };
    console.log(pass_data)
    let data_res = await api("/settings/update_user_cart_response", pass_data);
  console.log(data_res)
    if (data_res.status_code == 200) {
    let item = close_data;
    console.log(item)
    item.action = "save";
    item.index= props.data.index;
    item.demo_order=data_res.response.ecommerce_cart_response
    setDesc("");
    // item.value =  data_res.response.data.cod_allowed_all_users;
    SetCloseData(item);
  
    myModal.hide();
    }
  }

  const OrderSkeleton = () => {
    return (
      <div className="card p-3 cursor">
        <div className="row align-items-center">
          <div className="col-6 pb-3">
            <Skeleton height={10} width={50} />
          </div>
          <div className="col-6 text-end">
            <Skeleton height={30} width={100} />
          </div>
        </div>

        <div className="row pt-2">
          <div className="col-6 pb-2">
            <Skeleton height={10} width={50} />
            <Skeleton height={10} width={120} />
          </div>
          <div className="col-6 pb-2 text-end">
            <Skeleton height={10} width={120} />
            <Skeleton height={10} width={100} />
          </div>
        </div>

        <div className="pb-3 pt-2">
          <hr className="text-gray m-0" />
        </div>

        <div className=" pb-2">
          <Skeleton height={10} width={120} />
        </div>

        <div className="py-2">
          <div className="d-flex pb-3">
            <Skeleton height={18} width={18} />
            <div className="ps-2">
              <Skeleton height={10} width={120} />
              <Skeleton height={10} width={180} />
            </div>
          </div>
          <div className="d-flex pb-3">
            <Skeleton height={18} width={18} />
            <div className="ps-2">
              <Skeleton height={10} width={120} />
              <Skeleton height={10} width={180} />
            </div>
          </div>
        </div>
      </div>
    );
  };
  const handleApplicationChange = (event: any) => {
    console.log(event.target.value)
    setNoteId(event.target.value)
  };

  return (
    <div
      className="modal fade"
      id="view"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="  modal-title" id="ModalLabel">
           All Details
            </h6>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
          {/* <InfiniteScroll
            dataLength={all_deatils.length}
            next={() => {
              let pass_data = {
                load_more: true,

                page_no: page_no,

              };
              get_view(pass_data);
            }}
            hasMore={next_page}
            loader={
              <div className="center m-5">
                <div className="lds-dual-ring"></div>
                <p className=" small ps-2">Just a second ...</p>
              </div>
            }
          > */}
            {visible_content ? (
          all_deatils?.map((ord_ele: any, ord_index: any) => (
         <div className="card p-3 cursor mb-2 border-bottom">
                          <div className="row ">
                          <div className="col-6">
    <p className="extra-small text-gray">App Version</p>
    <p className="pt-1 text-dark fw-bold">
      {ord_ele.app_version}
    </p>
  </div>

  <div className="col-6">
    <p className="extra-small text-gray">Date</p>
    <p className="pt-1 text-dark fw-bold">
      {ord_ele.time}
    </p>
  </div>

  <div className="col-6">
  <p className="extra-small text-gray">Platform API Level</p>
  <p className="pt-1 text-dark fw-bold">
    {ord_ele.platformApiLevel != null ? ord_ele.platformApiLevel : "-"}
  </p>
</div>

<div className="col-6">
  <p className="extra-small text-gray">Platform Features</p>
  {ord_ele.platformFeatures != null ?
  <textarea 
    className="form-control pt-1 text-dark fw-bold" 
    readOnly 
    rows="4"
  >
 { ord_ele.platformFeatures}
  </textarea>:

 <p> -</p>
}
</div>
<h6 className="mb-3">Google</h6>
<div className="col-6">
  <p className="extra-small text-gray">Firebase App Instance ID</p>
  <p className="pt-1 text-dark fw-bold">
    {ord_ele.firebase_analytics_appInstanceId != null ? ord_ele.firebase_analytics_appInstanceId : "-"}
  </p>
</div>

<div className="col-6">
  <p className="extra-small text-gray">Firebase Session ID</p>
  <p className="pt-1 text-dark fw-bold">
    {ord_ele.firebase_analytics_sessionId != null ? ord_ele.firebase_analytics_sessionId : "-"}
  </p>
</div>
<div className="col-6">
    <p className="extra-small text-gray">Google Analytics Client ID</p>
    <p className="pt-1 text-dark fw-bold">
      {ord_ele.google_analytics_client_id !== null ? ord_ele.google_analytics_client_id : "-"}
    </p>
  </div>

  <h6 className="mb-3">Meta</h6>

  <div className="col-6">
    <p className="extra-small text-gray">Meta Pixel Client ID</p>
    <p className="pt-1 text-dark fw-bold">
      {ord_ele.metaPixelClientId !== null ? ord_ele.metaPixelClientId : "-"}
    </p>
  </div>

  <h6 className="mb-3">Browser/Web View</h6>

  <div className="col-6">
    <p className="extra-small text-gray">User Agent</p>
    <p className="pt-1 text-dark fw-bold">
      {ord_ele.user_agent !== "" ? ord_ele.user_agent : "-"}
    </p>
  </div>
  <div className="col-6">
    <p className="extra-small text-gray">Web Platform</p>
    <p className="pt-1 text-dark fw-bold">
      {ord_ele.web_platform !== null ? ord_ele.web_platform : "-"}
    </p>
  </div>
  <div className="col-6">
    <p className="extra-small text-gray">Robot</p>
    <p className="pt-1 text-dark fw-bold">
      {ord_ele.robot !== "" ? ord_ele.robot : "-"}
    </p>
  </div>
  <div className="col-6">
    <p className="extra-small text-gray">Browser</p>
    <p className="pt-1 text-dark fw-bold">
      {ord_ele.browser !== "" ? ord_ele.browser : "-"}
    </p>
  </div>
  <div className="col-6">
    <p className="extra-small text-gray">Browser Version</p>
    <p className="pt-1 text-dark fw-bold">
      {ord_ele.browser_version !== "" ? ord_ele.browser_version : "-"}
    </p>
  </div>
  <h6 className="mb-3">Expo SDK</h6>
<div className="col-6">
  <p className="extra-small text-gray">Max Memory</p>
  <p className="pt-1 text-dark fw-bold">
    {ord_ele.maxMemory != null ? ord_ele.maxMemory : "-"}
  </p>
</div>
<div className="col-6">
  <p className="extra-small text-gray">OS Build ID</p>
  <p className="pt-1 text-dark fw-bold">
    {ord_ele.osBuildId != null ? ord_ele.osBuildId : "-"}
  </p>
</div>
<div className="col-6">
  <p className="extra-small text-gray">OS Name</p>
  <p className="pt-1 text-dark fw-bold">
    {ord_ele.osName != null ? ord_ele.osName : "-"}
  </p>
</div>

<div className="col-6">
  <p className="extra-small text-gray">OS Version</p>
  <p className="pt-1 text-dark fw-bold">
    {ord_ele.osVersion != null ? ord_ele.osVersion : "-"}
  </p>
</div>

<div className="col-6">
  <p className="extra-small text-gray">OS Internal Build ID</p>
  <p className="pt-1 text-dark fw-bold">
    {ord_ele.osInternalBuildId != null ? ord_ele.osInternalBuildId : "-"}
  </p>
</div>

<div className="col-6">
  <p className="extra-small text-gray">Total Memory</p>
  <p className="pt-1 text-dark fw-bold">
    {ord_ele.totalMemory != null ? ord_ele.totalMemory : "-"}
  </p>
</div>

<div className="col-6">
  <p className="extra-small text-gray">Uptime</p>
  <p className="pt-1 text-dark fw-bold">
    {ord_ele.uptime != null ? ord_ele.uptime : "-"}
  </p>
</div>


 
  <div className="col-6">
    <p className="extra-small text-gray">Referrer</p>
    <p className="pt-1 text-dark fw-bold">
      {ord_ele.referrer !== "" ? ord_ele.referrer : "-"}
    </p>
  </div>





                             </div> 
                             </div> 

          ))
         
        ) : (
          <div className="row">
            {[1, 2, 3, 4, 5, 6].map(() => (
              <div className="col-md-4 col-12 mb-3">
                <OrderSkeleton />
              </div>
            ))}
         
          </div>
        )}

{next_page && (
                            
                            <button onClick={() => {
                              const pass_data = {
                                load_more: true,
                        
                                page_no: page_no,
                        
                              };
                              get_view(pass_data)
                            }}
                             className="btn btn-primary mt-3"
                             >
                              Load More
                            </button>
                          )}
      {/* </InfiniteScroll> */}
          </div>
          <div className="modal-footer">
            {/* <button
              type="button"
              className="btn btn-success"
            
              onClick={
                // myModal.hide();
                Save}
                  // data-bs-dismiss="modal"
            >
              Save
            </button> */}
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
