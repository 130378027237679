import React ,{useState,useEffect} from 'react'
import { useOutletContext } from "react-router-dom";
import { api } from "../../utils/Api";
import InfiniteScroll from "react-infinite-scroll-component";

import {
  Ionicons,
  MaterialIcons,
  MaterialCommunityIcons,
} from "@expo/vector-icons";

function DynamicDatas() {

  const [statistics_data, SetStatisticsData]: any = useState([]);
  const OutletContext = useOutletContext();
  const [timeoutId, setTimeoutId] = useState(null);

  const [sort, setSort] = useState("");
  const [filter_data, setFilterData] = useState("");


    const [startDateShow, setStartDateShow] = useState("");
  const [endDateShow, setEndDateShow] = useState("");
  const [page_no, SetPageNo] = useState(1);
  const [next_page, SetNextPage] = useState(false);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [width, SetWidth] = useState(innerWidth);
  const [table_heading, SetTableHeading] = useState([]);
  const [table_data, SetTableData] = useState([]);
  const [card_data, SetCardData] = useState([]);




  useEffect(() => {
   
    let send_data = {
      startDate:startDate,
      endDate:endDate,
      // page_no: 1,
      // search:filter_data
    };
    get_overview(send_data);
// SetPageNo(1)
    // get_outlets_and_channels();
  }, [sort]);


  
  function convertDateTime(dateTimeStr) {
    const date = new Date(dateTimeStr);
    
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-11
    const year = date.getFullYear();
    
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = '00'; // Set seconds to 00
    
    const formattedDateTime = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    return formattedDateTime;
  }

  const handleStartChange = (e) => {
    //  let start:any = moment(date).format('DD-MM-YYYY HH:mm:ss')
    console.log(e.target.value)
    const convertedDateTime = convertDateTime(e.target.value);
      console.log(convertedDateTime);
      setStartDateShow(e.target.value)
     setStartDate(convertedDateTime);
    //  getSuperappStatistics(start,endDate)
    //  get_order_statistics(start,endDate)
    //  get_order_price_statistics(start,endDate)
    
    };
  
    const handleEndChange = (e) => {
      const convertedDateTime = convertDateTime(e.target.value);
      setEndDateShow(e.target.value)
    
    
        setEndDate(convertedDateTime);
        
      // getSuperappStatistics(startDate,end)
      // get_order_statistics(startDate,end)
      // get_order_price_statistics(startDate,end)
   
     
     
     };

     const handleApply = () => {

      const startTimestamp = new Date(startDateShow).getTime();
      const endTimestamp = new Date(endDateShow).getTime();
  
      console.log(startTimestamp)
      console.log(endTimestamp)
  
     
      console.log("hi")
      if (startTimestamp < endTimestamp) {
      //     getSuperappStatistics(startDate,endDate)
      // get_order_statistics(startDate,endDate,activeOrTab)
      // get_order_price_statistics(startDate,endDate,activeTab)
          // Add your logic for applying the selected dates

          let send_data = {
            page_no: 1,
            have_more: false,
            startDate:startDate,
            endDate:endDate,
      search:filter_data

          };
        
          get_overview(send_data);
      } else {
        toast.error("End date cannot be earlier than start date.");
      }

      SetPageNo(1)
  };


  async function get_overview(get_data: any) {

 
     let pass_data = {
       get: {
         filter_from:get_data.startDate,
         filter_to: get_data.endDate,
         data_type:"1"
        //  order_by: sort,

// search:get_data.search,
         
//         page: get_data.page_no,
       },
     };
     let response: any = await api("/orders/dynamic_datas", pass_data);
     console.log("/qr/overview response :", response.response);
     if (response.status_code == 200) {

      SetTableHeading(response.response.table_heading)
      SetTableData(response.response.table_data)

      SetCardData(response.response.card_data)

      //  if (response.response.hasOwnProperty("data")) {

        
      //   //  let statistics_data = response.response.data;
 
      //    if (get_data.load_more) {
      //     SetStatisticsData((prevValue) => {
      //       response?.response?.data?.map((ele: any) => {
      //         prevValue.push(ele);
      //       });
      //       return [...prevValue];
      //     });
      //   } else {
      //     SetStatisticsData(response.response.data);

      //   }
    
 
  
      //  }
  
      // // if(!get_data.hasOwnProperty("type")){
      // if (response.response.hasOwnProperty("next_page")) {
      //   SetNextPage(response.response.next_page);
      //   SetPageNo((prevalue) => {
      //     prevalue = prevalue + 1;
      //     return prevalue;
      //   });
      // }
 
      
     }
   }

   const handleInputChange = (e: any) => {
    const searchValue = e.target.value;
    setFilterData(searchValue);
    // Clear previous timeout
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // Set a new timeout
    const newTimeoutId = setTimeout(() => {
      // Call your API with the search value
      let send_data = {
      startDate:startDate,
      endDate:endDate,
      page_no: 1,
      search:   searchValue
    };
    get_overview(send_data);
      SetPageNo(1);
    }, 1000); // 5 milliseconds delay

    setTimeoutId(newTimeoutId);
  };

  return (
    <div>
    <div
    className={OutletContext ? "head-bar sticky-sm py-3" : "head-bar py-3"}
    style={OutletContext == false ? { width: width } : {}}
  >
        <h6 className="fs-5  mb-0">Dynamic Data</h6>
        <div className="ms-lg-auto">  
<div className="d-flex-lg">
  <div className="m-2"> Statistics Filter :</div>
        <div className="me-2">
          {console.log(startDate)}
        <input type="datetime-local"
          onChange={handleStartChange}
        id="startDateTime"
        className="form-control"
        value={startDateShow}
        name="startDateTime" required/>

                {/* <Datetime 
                    id="start"
                    // value={startDate}
                    onChange={handleStartChange}
                    dateFormat={dateFormat}
                    timeFormat={timeFormat}
                    inputProps={{ placeholder: 'Select start date and time' }}
                /> */}
            </div>
          
            <div>
            <input type="datetime-local"
          onChange={handleStartChange}
        id="endDateTime"
        className="form-control"
        value={endDateShow}
        onChange={handleEndChange}
        name="endDateTime" required/>
{/*         
                <Datetime 
                    id="end"
                    value={endDateShow}
                    onChange={handleEndChange}
                    dateFormat={dateFormat}
                    timeFormat={timeFormat}
                    inputProps={{ placeholder: 'Select end date and time' }}
                /> */}
            </div>
            <button className="btn btn-primary btn-sm ms-2 h-10" 
            onClick={handleApply}
            >Apply</button>
            </div>
        </div>
    {/* <div className="d-flex align-items-center">
    <div className="input-icons form-group p-2">
                            <input
                                type="text"
                                className="form-control search-bar p-0"
                                placeholder="Search here ..."
                                onChange={handleInputChange}
                            />
                            <div className="cursor px-2 border-left">

                            </div>
                        </div>
 


        </div> */}

    

        </div>

        {card_data.map((d) => (
    <div className="card p-3 mx-2 mt-2">
        <div>
            {d.label}
        </div>
        <div>
            {d.value}
        </div>
    </div>
))}
        <div className="container mt-5">
       
        <div className="table-responsive" >
      <table className="table table-striped table-bordered">
            <thead className="table-dark">
                <tr style={{ cursor: 'pointer' }}>
                {/* <th scope="row"  onClick={()=>{
    // Toggle sorting state
    const newSort = sort === 'asc_id' ? 'desc_id' : 'asc_id';
    setSort(newSort);
  }}><div className='d-flex'> <div>ID</div>   {sort=="desc_id" ? (
    <MaterialIcons
      name="keyboard-arrow-down"
      size={24}
      color="white"
    />
  ) : sort=="asc_id"?(
    <MaterialIcons
      name="keyboard-arrow-up"
      size={24}
      color="white"
    />
  ):<div> </div>}</div></th> */}
             
             {table_heading.map((d:any) => (
    <th>
        {d.label}
    </th>
))}
              
              
              
                </tr>
            </thead>
       
            <tbody>
           
              {/* {statistics_data?.map((ele,i)=>( */}

                {/* <tr> */}
                {table_data.map((row, rowIndex) => (
            <tr key={rowIndex}>
                {table_heading.map((col, colIndex) => (
                    <td key={colIndex}>{row[col.value]}</td>
                ))}
            </tr>
        ))}

                {/* table_heading.map(d) => (

                <td>{ele.id}</td>

                {table_data.map((td) => (
    <>
        {table_heading.map((d) => (
        <td>
            {td[d.value]}
        </td>
        ))}
    </>
)} */}
                
                {/* <td>{ele.linked_date}</td>
                <td>{ele.brand_id}</td>
            <td>{ele.brand_name}</td>

                <td>{ele.outlet_id}</td>
            
            <td>{ele.outlet_name}</td>
           
         
          
               
            
                <td>{ele.total_scanned}</td>
                <td>{ele.total_new_scanned}</td>
                <td>{ele.total_re_scanned}</td>
                <td>{ele.total_signup}</td>
           
                <td>{ele.total_app_signup}</td>
             
                <td>{ele.total_android_signup}</td>
           
           
                <td>{ele.total_ios_signup}</td>
                <td>{ele.total_scanned_app}</td>

                <td>{ele.total_scanned_android}</td>
                <td>{ele.total_scanned_ios}</td>
                <td>{ele.total_rescanned_app}</td>

                <td>{ele.total_rescanned_android}</td>
                <td>{ele.total_rescanned_ios}</td>
                <td>{ele.total_newscanned_app}</td>



                <td>{ele.total_newscanned_android}</td>
                <td>{ele.total_newscanned_ios}</td>
                <td>{ele.token}</td> */}
            
        {/* //     <td>
        //   <a href={ele.short_link} target="_blank" rel="noopener noreferrer">
        //     {ele.short_link}
        //   </a>
        // </td>
        // <td><a href={ele.actual_link} target="_blank">{ele.actual_link}</a></td>


        // <td>{ele.downloaded}</td> */}
           
                {/* </tr> */}
                  {/* // ))} */}
            </tbody>
     
        </table>
        
        </div>
      {/* <InfiniteScroll
            dataLength={statistics_data.length}
            next={() => {
              let send_data = {
                startDate:startDate,
                endDate:endDate,
                load_more: true,

                page_no: page_no,
      search:filter_data

              };
              get_overview(send_data);
            }}
            hasMore={next_page}
            loader={
              <div className="center m-5">
                <div className="lds-dual-ring"></div>
                <p className=" small ps-2">Just a second ...</p>
              </div>
            }
          >
            <div className="table-responsive" >
      <table class="table table-striped table-bordered">
            <thead class="table-dark">
                <tr style={{ cursor: 'pointer' }}>
                <th scope="row"  onClick={()=>{
    // Toggle sorting state
    const newSort = sort === 'asc_id' ? 'desc_id' : 'asc_id';
    setSort(newSort);
  }}><div className='d-flex'> <div>ID</div>   {sort=="desc_id" ? (
    <MaterialIcons
      name="keyboard-arrow-down"
      size={24}
      color="white"
    />
  ) : sort=="asc_id"?(
    <MaterialIcons
      name="keyboard-arrow-up"
      size={24}
      color="white"
    />
  ):<div> </div>}</div></th>
             
           
                <th scope="row" onClick={()=>{
    // Toggle sorting state
    const newSort = sort === 'asc_linked_date' ? 'desc_linked_date' : 'asc_linked_date';
    setSort(newSort);
  }}><div className='d-flex'> <div>Linked Date</div>   {sort=="desc_linked_date" ? (
                <MaterialIcons
                  name="keyboard-arrow-down"
                  size={24}
                  color="white"
                />
              ) : sort=="asc_linked_date"?(
                <MaterialIcons
                  name="keyboard-arrow-up"
                  size={24}
                  color="white"
                />
              ):<div>   </div>}</div></th>
                <th scope="row" onClick={()=>{
    // Toggle sorting state
    const newSort = sort === 'asc_brand_id' ? 'desc_brand_id' : 'asc_brand_id';
    setSort(newSort);

  }}><div className='d-flex'> <div>Brand ID</div>   {sort=="desc_brand_id" ? (
    <MaterialIcons
      name="keyboard-arrow-down"
      size={24}
      color="white"
    />
  ) : sort=="asc_brand_id"?(
    <MaterialIcons
      name="keyboard-arrow-up"
      size={24}
      color="white"
    />
  ):<div>   </div>}</div></th>
                <th scope="row"  onClick={()=>{
    // Toggle sorting state
    const newSort = sort === 'asc_brand_name' ? 'desc_brand_name' : 'asc_brand_name';
    setSort(newSort);

  }}><div className='d-flex'> <div>Brand Name</div>   {sort=="desc_brand_name" ? (
    <MaterialIcons
      name="keyboard-arrow-down"
      size={24}
      color="white"
    />
  ) : sort=="asc_brand_name"?(
    <MaterialIcons
      name="keyboard-arrow-up"
      size={24}
      color="white"
    />
  ):<div>   </div>}</div></th>
                <th scope="row"onClick={()=>{
    // Toggle sorting state
    const newSort = sort === 'asc_outlet_id' ? 'desc_outlet_id' : 'asc_outlet_id';
    setSort(newSort);

  }}><div className='d-flex'> <div>Outlet ID</div>   {sort=="desc_outlet_id" ? (
    <MaterialIcons
      name="keyboard-arrow-down"
      size={24}
      color="white"
    />
  ) : sort=="asc_outlet_id"?(
    <MaterialIcons
      name="keyboard-arrow-up"
      size={24}
      color="white"
    />
  ):<div>   </div>}</div></th>
                <th scope="row"onClick={()=>{
    // Toggle sorting state
    const newSort = sort === 'asc_outlet_name' ? 'desc_outlet_name' : 'asc_outlet_name';
    setSort(newSort);

  }}><div className='d-flex'> <div>Outlet Name</div>   {sort=="desc_outlet_name" ? (
    <MaterialIcons
      name="keyboard-arrow-down"
      size={24}
      color="white"
    />
  ) : sort=="asc_outlet_name"?(
    <MaterialIcons
      name="keyboard-arrow-up"
      size={24}
      color="white"
    />
  ):<div>   </div>}</div></th>

                <th scope="row"onClick={()=>{
    // Toggle sorting state
    const newSort = sort === 'asc_total_scanned' ? 'desc_total_scanned' : 'asc_total_scanned';
    setSort(newSort);

  }}><div className='d-flex'> <div>Total Scanned</div>   {sort=="desc_total_scanned" ? (
    <MaterialIcons
      name="keyboard-arrow-down"
      size={24}
      color="white"
    />
  ) : sort=="asc_total_scanned"?(
    <MaterialIcons
      name="keyboard-arrow-up"
      size={24}
      color="white"
    />
  ):<div>   </div>}</div> </th>
                <th scope="row"onClick={()=>{
    // Toggle sorting state
    const newSort = sort === 'asc_total_new_scanned' ? 'desc_total_new_scanned' : 'asc_total_new_scanned';
    setSort(newSort);

  }}><div className='d-flex'> <div>Total New Scanned</div>   {sort=="desc_total_new_scanned" ? (
    <MaterialIcons
      name="keyboard-arrow-down"
      size={24}
      color="white"
    />
  ) : sort=="asc_total_new_scanned"?(
    <MaterialIcons
      name="keyboard-arrow-up"
      size={24}
      color="white"
    />
  ):<div>   </div>}</div></th>
                <th scope="row"onClick={()=>{
    // Toggle sorting state
    const newSort = sort === 'asc_total_re_scanned' ? 'desc_total_re_scanned' : 'asc_total_re_scanned';
    setSort(newSort);

  }}><div className='d-flex'> <div>Total Re-Scanned</div>   {sort=="desc_total_re_scanned" ? (
    <MaterialIcons
      name="keyboard-arrow-down"
      size={24}
      color="white"
    />
  ) : sort=="asc_total_re_scanned"?(
    <MaterialIcons
      name="keyboard-arrow-up"
      size={24}
      color="white"
    />
  ):<div>   </div>}</div></th>
                <th scope="row"onClick={()=>{
    // Toggle sorting state
    const newSort = sort === 'asc_total_signup' ? 'desc_total_signup' : 'asc_total_signup';
    setSort(newSort);

  }}><div className='d-flex'> <div>Total Signup</div>   {sort=="desc_total_signup" ? (
    <MaterialIcons
      name="keyboard-arrow-down"
      size={24}
      color="white"
    />
  ) : sort=="asc_total_signup"?(
    <MaterialIcons
      name="keyboard-arrow-up"
      size={24}
      color="white"
    />
  ):<div>   </div>}</div></th>
                <th scope="row"onClick={()=>{
    // Toggle sorting state
    const newSort = sort === 'asc_total_app_signup' ? 'desc_total_app_signup' : 'asc_total_app_signup';
    setSort(newSort);

  }}><div className='d-flex'> <div>Total App Signup</div>   {sort=="desc_total_app_signup" ? (
    <MaterialIcons
      name="keyboard-arrow-down"
      size={24}
      color="white"
    />
  ) : sort=="asc_total_app_signup"?(
    <MaterialIcons
      name="keyboard-arrow-up"
      size={24}
      color="white"
    />
  ):<div>   </div>}</div></th>

               
                <th scope="row"onClick={()=>{
    // Toggle sorting state
    const newSort = sort === 'asc_total_android_signup' ? 'desc_total_android_signup' : 'asc_total_android_signup';
    setSort(newSort);

  }}><div className='d-flex'> <div>Total Android Signup</div>   {sort=="desc_total_android_signup" ? (
    <MaterialIcons
      name="keyboard-arrow-down"
      size={24}
      color="white"
    />
  ) : sort=="asc_total_android_signup"?(
    <MaterialIcons
      name="keyboard-arrow-up"
      size={24}
      color="white"
    />
  ):<div>   </div>}</div></th>
                <th scope="row " onClick={()=>{
    // Toggle sorting state
    const newSort = sort === 'asc_total_ios_signup' ? 'desc_total_ios_signup' : 'asc_total_ios_signup';
    setSort(newSort);

  }}><div className='d-flex'> <div>Total iOS Signup</div>   {sort=="desc_total_ios_signup" ? (
    <MaterialIcons
      name="keyboard-arrow-down"
      size={24}
      color="white"
    />
  ) : sort=="asc_total_ios_signup"?(
    <MaterialIcons
      name="keyboard-arrow-up"
      size={24}
      color="white"
    />
  ):<div>   </div>}</div></th>
                <th scope="row"onClick={()=>{
    // Toggle sorting state
    const newSort = sort === 'asc_total_scanned_app' ? 'desc_total_scanned_app' : 'asc_total_scanned_app';
    setSort(newSort);

  }}><div className='d-flex'> <div>Total Scanned (App)</div>   {sort=="desc_total_scanned_app" ? (
    <MaterialIcons
      name="keyboard-arrow-down"
      size={24}
      color="white"
    />
  ) : sort=="asc_total_scanned_app"?(
    <MaterialIcons
      name="keyboard-arrow-up"
      size={24}
      color="white"
    />
  ):<div>   </div>}</div></th>
                <th scope="row"onClick={()=>{
    // Toggle sorting state
    const newSort = sort === 'asc_total_scanned_android' ? 'desc_total_scanned_android' : 'asc_total_scanned_android';
    setSort(newSort);

  }}><div className='d-flex'> <div>Total Scanned (Android)</div>   {sort=="desc_total_scanned_android" ? (
    <MaterialIcons
      name="keyboard-arrow-down"
      size={24}
      color="white"
    />
  ) : sort=="asc_total_scanned_android"?(
    <MaterialIcons
      name="keyboard-arrow-up"
      size={24}
      color="white"
    />
  ):<div>   </div>}</div></th>
                <th scope="row"onClick={()=>{
    // Toggle sorting state
    const newSort = sort === 'asc_total_scanned_ios' ? 'desc_total_scanned_ios' : 'asc_total_scanned_ios';
    setSort(newSort);

  }}><div className='d-flex'> <div>Total Scanned (iOS)</div>   {sort=="desc_total_scanned_ios" ? (
    <MaterialIcons
      name="keyboard-arrow-down"
      size={24}
      color="white"
    />
  ) : sort=="asc_total_scanned_ios"?(
    <MaterialIcons
      name="keyboard-arrow-up"
      size={24}
      color="white"
    />
  ):<div>   </div>}</div></th>

                <th scope="row"onClick={()=>{
    // Toggle sorting state
    const newSort = sort === 'asc_total_rescanned_app' ? 'desc_total_rescanned_app' : 'asc_total_rescanned_app';
    setSort(newSort);

  }}><div className='d-flex'> <div>Total Re-Scanned (App)</div>   {sort=="desc_total_rescanned_app" ? (
    <MaterialIcons
      name="keyboard-arrow-down"
      size={24}
      color="white"
    />
  ) : sort=="asc_total_rescanned_app"?(
    <MaterialIcons
      name="keyboard-arrow-up"
      size={24}
      color="white"
    />
  ):<div>   </div>}</div></th>

                <th scope="row"onClick={()=>{
    // Toggle sorting state
    const newSort = sort === 'asc_total_rescanned_android' ? 'desc_total_rescanned_android' : 'asc_total_rescanned_android';
    setSort(newSort);

  }}><div className='d-flex'> <div>Total Re-Scanned (Android)</div>   {sort=="desc_total_rescanned_android" ? (
    <MaterialIcons
      name="keyboard-arrow-down"
      size={24}
      color="white"
    />
  ) : sort=="asc_total_rescanned_android"?(
    <MaterialIcons
      name="keyboard-arrow-up"
      size={24}
      color="white"
    />
  ):<div>   </div>}</div></th>
                <th scope="row"onClick={()=>{
    // Toggle sorting state
    const newSort = sort === 'asc_total_rescanned_ios' ? 'desc_total_rescanned_ios' : 'asc_total_rescanned_ios';
    setSort(newSort);

  }}><div className='d-flex'> <div>Total Re-Scanned (iOS)</div>   {sort=="desc_total_rescanned_ios" ? (
    <MaterialIcons
      name="keyboard-arrow-down"
      size={24}
      color="white"
    />
  ) : sort=="asc_total_rescanned_ios"?(
    <MaterialIcons
      name="keyboard-arrow-up"
      size={24}
      color="white"
    />
  ):<div>   </div>}</div></th>
                <th scope="row"onClick={()=>{
    // Toggle sorting state
    const newSort = sort === 'asc_total_newscanned_app' ? 'desc_total_newscanned_app' : 'asc_total_newscanned_app';
    setSort(newSort);

  }}><div className='d-flex'> <div>Total New Scanned (App)</div>   {sort=="desc_total_newscanned_app" ? (
    <MaterialIcons
      name="keyboard-arrow-down"
      size={24}
      color="white"
    />
  ) : sort=="asc_total_newscanned_app"?(
    <MaterialIcons
      name="keyboard-arrow-up"
      size={24}
      color="white"
    />
  ):<div>   </div>}</div></th>

                <th scope="row"onClick={()=>{
    // Toggle sorting state
    const newSort = sort === 'asc_total_newscanned_android' ? 'desc_total_newscanned_android' : 'asc_total_newscanned_android';
    setSort(newSort);

  }}><div className='d-flex'> <div>Total New Scanned (Android)</div>   {sort=="desc_total_newscanned_android" ? (
    <MaterialIcons
      name="keyboard-arrow-down"
      size={24}
      color="white"
    />
  ) : sort=="asc_total_newscanned_android"?(
    <MaterialIcons
      name="keyboard-arrow-up"
      size={24}
      color="white"
    />
  ):<div>   </div>}</div></th>
                <th scope="row"onClick={()=>{
    // Toggle sorting state
    const newSort = sort === 'asc_total_newscanned_ios' ? 'desc_total_newscanned_ios' : 'asc_total_newscanned_ios';
    setSort(newSort);

  }}><div className='d-flex'> <div>Total New Scanned (iOS)</div>   {sort=="desc_total_newscanned_ios" ? (
    <MaterialIcons
      name="keyboard-arrow-down"
      size={24}
      color="white"
    />
  ) : sort=="asc_total_newscanned_ios"?(
    <MaterialIcons
      name="keyboard-arrow-up"
      size={24}
      color="white"
    />
  ):<div>   </div>}</div></th>

<th scope="row"onClick={()=>{
    // Toggle sorting state
    const newSort = sort === 'asc_token' ? 'desc_token' : 'asc_token';
    setSort(newSort);

  }}><div className='d-flex'> <div>Token</div>   {sort=="desc_token" ? (
    <MaterialIcons
      name="keyboard-arrow-down"
      size={24}
      color="white"
    />
  ) : sort=="asc_token"?(
    <MaterialIcons
      name="keyboard-arrow-up"
      size={24}
      color="white"
    />
  ):<div>   </div>}</div></th>
                <th scope="row"onClick={()=>{
    // Toggle sorting state
    const newSort = sort === 'asc_short_link' ? 'desc_short_link' : 'asc_short_link';
    setSort(newSort);

  }}><div className='d-flex'> <div>Short Link</div>   {sort=="desc_short_link" ? (
    <MaterialIcons
      name="keyboard-arrow-down"
      size={24}
      color="white"
    />
  ) : sort=="asc_short_link"?(
    <MaterialIcons
      name="keyboard-arrow-up"
      size={24}
      color="white"
    />
  ):<div>   </div>}</div></th>
                <th scope="row"onClick={()=>{
    // Toggle sorting state
    const newSort = sort === 'asc_actual_link' ? 'desc_actual_link' : 'asc_actual_link';
    setSort(newSort);

  }}><div className='d-flex'> <div>Actual Link</div>   {sort=="desc_actual_link" ? (
    <MaterialIcons
      name="keyboard-arrow-down"
      size={24}
      color="white"
    />
  ) : sort=="asc_actual_link"?(
    <MaterialIcons
      name="keyboard-arrow-up"
      size={24}
      color="white"
    />
  ):<div>   </div>}</div></th>
               
                <th scope="row"onClick={()=>{
    // Toggle sorting state
    const newSort = sort === 'asc_downloaded' ? 'desc_downloaded' : 'asc_downloaded';
    setSort(newSort);

  }}><div className='d-flex'> <div>Downloaded</div>   {sort=="desc_downloaded" ? (
    <MaterialIcons
      name="keyboard-arrow-down"
      size={24}
      color="white"
    />
  ) : sort=="asc_downloaded"?(
    <MaterialIcons
      name="keyboard-arrow-up"
      size={24}
      color="white"
    />
  ):<div>   </div>}</div></th>
              
              
                </tr>
            </thead>
       
            <tbody>
           
              {statistics_data?.map((ele,i)=>(
                <tr>
                <td>{ele.id}</td>
                <td>{ele.linked_date}</td>
                <td>{ele.brand_id}</td>
            <td>{ele.brand_name}</td>

                <td>{ele.outlet_id}</td>
            
            <td>{ele.outlet_name}</td>
           
         
          
               
            
                <td>{ele.total_scanned}</td>
                <td>{ele.total_new_scanned}</td>
                <td>{ele.total_re_scanned}</td>
                <td>{ele.total_signup}</td>
           
                <td>{ele.total_app_signup}</td>
             
                <td>{ele.total_android_signup}</td>
           
           
                <td>{ele.total_ios_signup}</td>
                <td>{ele.total_scanned_app}</td>

                <td>{ele.total_scanned_android}</td>
                <td>{ele.total_scanned_ios}</td>
                <td>{ele.total_rescanned_app}</td>

                <td>{ele.total_rescanned_android}</td>
                <td>{ele.total_rescanned_ios}</td>
                <td>{ele.total_newscanned_app}</td>



                <td>{ele.total_newscanned_android}</td>
                <td>{ele.total_newscanned_ios}</td>
                <td>{ele.token}</td>
            
            <td>
          <a href={ele.short_link} target="_blank" rel="noopener noreferrer">
            {ele.short_link}
          </a>
        </td>
        <td><a href={ele.actual_link} target="_blank">{ele.actual_link}</a></td>


        <td>{ele.downloaded}</td>
           
                </tr>
                  ))}
            </tbody>
     
        </table>
        
        </div>
        </InfiniteScroll> */}

        </div>

</div>
  )
}

export default DynamicDatas;