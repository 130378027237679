import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { api } from "../../utils/Api";
import ModalPgRequest from "./ModalPgRequest";
import ModalRefund from "./ModalRefund";

export default function Payments(props: any) {
  const [next_page, SetNextPage] = useState(false);

  const [page_no, SetPageNo] = useState(1);

  const [payments, setPayments] = useState([]);

  const [edit_user_modal, SetEditUserModal] = useState({
    is_open: false,
    data: {},

    type: "edit",
  });

  const [pgModal, SetPgModal] = useState({
    is_open: false,
    data: {},
  });

  const [refundModal, SetRefundModal] = useState({
    is_open: false,
    data: [],
  });

  useEffect(() => {}, []);

  useEffect(() => {
    let pass_data = {
      sort: "",
      page_no: page_no,
    };
    get_categories(pass_data);
  }, []);

  async function get_categories(get_data) {
    let pass_data = {
      get: {
        page: get_data.page_no,
      },
    };

    let response = await api("/payments/list", pass_data);
    if (response.status_code == 200) {
    

      setPayments((prevValue) => {
        response.response.data.map((item) => {
          prevValue.push(item);
        });
        return [...prevValue];
      });
      SetNextPage(response.response.next_page);
    }
  }

  return (
    <div className="">
      {/* Header */}
      <div className="head-bar">
        <div className="d-flex">
          <h5 className="  mb-0">Payments</h5>
          <Link
            to={"/categories/add_category/0"}
            className="btn btn-primary btn-radius btn-sm px-3 ms-auto"
          >
            Add
          </Link>
        </div>
      </div>

      <div className="border-top fixed-sub-header">
        <div className="mt-3 px-md-3 px-sm-0 mb-5">
          <div className="card p-4">
            <h2>Payments</h2>
            <div className="w-70">
              <div class="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Payment Id</th>
                      <th scope="col">Date</th>
                      <th scope="col">Purpose</th>
                      <th scope="col">Ref Id</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Status</th>
                      <th scope="col">Order Success</th>
                      <th scope="col">Order Success Timestamp</th>
                      <th scope="col">Application Name</th>
                      <th scope="col">User</th>
                      <th scope="col">Transaction</th>
                      <th scope="col"></th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {payments.map((ele, i) => (
                      <tr>
                        <td>{ele.payment_id}</td>
                        <td>{ele.date}</td>
                        <td>{ele.purpose}</td>
                        <td>{ele.ref_id}</td>
                        <td>{ele.amount}</td>
                        <td>{ele.status}</td>
                        <td>{ele.order_success}</td>
                        <td>{ele.order_success_timestamp}</td>
                        <td>{ele?.application_name}</td>
                        <td>{ele?.user?.name}</td>
                        <td>{ele?.transaction?.status}</td>

                        <td>
                          <button
                            className="btn-primary"
                            onClick={() => {
                              SetPgModal({
                                is_open: true,
                                data: ele?.transaction?.phonepe_response_data,
                              });
                            }}
                          >
                            PG Response
                          </button>
                        </td>
                        <td>
                          <button
                            className="btn-primary"
                            onClick={() => {
                              SetRefundModal({
                                is_open: true,
                                data: ele?.refund_request,
                              });
                            }}
                          >
                            Refunded
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  {next_page ? (
                    <button
                      className="btn btn-primary ms-auto"
                      onClick={() => {
                        SetPageNo((prevalue: any) => {
                          let pass_data = {
                            sort: "",
                            page_no: prevalue + 1,
                          };
                          get_categories(pass_data);
                          return prevalue + 1;
                        });
                      }}
                    >
                      Next
                    </button>
                  ) : null}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalPgRequest
        open={pgModal.is_open}
        data={pgModal.data}
        type={edit_user_modal.type}
        close={(data: any) => {
          SetPgModal({
            is_open: false,
            data: {},
          });
        }}
      />

      <ModalRefund
        open={refundModal.is_open}
        data={refundModal.data}
        close={(data: any) => {
          SetRefundModal({
            is_open: false,
            data: [],
          });
        }}
      />
    </div>
  );
}
