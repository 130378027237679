import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { api } from "../../utils/Api";
import DataContext from "../../utils/Context/DataContext";
import ModalVariants from "./ModalVariants";
import {
  Ionicons,
  MaterialCommunityIcons,
  FontAwesome,
} from "@expo/vector-icons";

import toast, { Toaster } from "react-hot-toast";
import InfiniteScroll from "react-infinite-scroll-component";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const initial_value = {
  id: "",
  status: "",
  profile_img: "",
  driving_license: "",
  aadhaar: "",
  pan: "",
  rc_book: "",
  reason: "",
  profile_img_reason: "",
  driving_license_reason: "",
  aadhaar_reason: "",
  pan_reason: "",
  rc_book_reason: "",
  active_account: true,
};

export default function DeliveryPartnerRequest(props: any) {
  const [modal_variants, SetModalVariants] = useState({
    is_open: false,
    data: {},
  });
  const [sort_val, SetSort] = useState("desc")

  const [save_data, SetSaveData] = useState(initial_value);

  const [next_page, SetNextPage] = useState(false);

  const [page_no, SetPageNo] = useState(1);
  const [transaction_able, SetTransactionAble] = useState(false);

  const [filter_data, setFilterData] = useState("");

  const [selected_details, SetSelectedDetails] = useState();
  const [width, SetWidth] = useState(innerWidth);

  // State to store the timeout ID
  const [timeoutId, setTimeoutId] = useState(null);

  const [payments, setPayments] = useState([]);
  const [transaction_details, SetTransactionDetails] = useState({});

  const [view, SetView] = useState("new");

  const [order_status, SetOrderStatus] = useState(1);

  const [show_details_loader, SetShowDetailsLoader] = useState(true);
  const [payment_loading, SetPaymentLoading] = useState(true);
  const [visible_content, SetVisibleContent] = useState(false);

  useEffect(() => {
    // SetPageNo((prevalue)=>{
    let pass_data = {
      order_status: 1,
      seller_brand_id: "",
      seller_outlet_id: "",
      sort: sort_val,
      page_no: page_no,
    };
    get_deliveryprtner_request(pass_data, filter_data, view);
    // get_products(page_no, "1");
    // return prevalue;
    // })
  }, []);

  function outlet_value_on_change(name: any, value: any) {
    SetSaveData((oldValues) => ({
      ...oldValues,
      [name]: value,
    }));
  }

  async function get_deliveryprtner_request(
    get_data: any,
    search: any,
    view: any
  ) {
    if (!get_data.load_more) {
      SetPaymentLoading(true);
    }

   
    let pass_data = {
      get: {
        page: get_data.page_no,
        search: search,
        view: view,
        sort: get_data.sort,
      },
    };

    let response = await api(
      "/orders/delivery_partner_account_requests",
      pass_data
    );
    if (response.status_code == 200) {
      SetPaymentLoading(false);
      if (get_data.load_more) {
        setPayments((prevValue) => {
          response.response.data.map((item) => {
            prevValue.push(item);
          });
          return [...prevValue];
        });
      } else {
        setPayments(response.response.data);
      }

      if (response.response.hasOwnProperty("data")) {
        SetVisibleContent(true);
      }
      if (response.response.hasOwnProperty("next_page")) {
        SetNextPage(response.response.next_page);
        SetPageNo((prevalue) => {
          prevalue = prevalue + 1;
          return prevalue;
        });
      }
    }
  }

  function Accept() {
    if (
      save_data?.profile_img == "1" &&
      save_data?.aadhaar == "1" &&
      save_data?.driving_license == "1" &&
      save_data?.pan == "1"
    ) {
      SetTransactionAble(false);
      post_accept();
    } else {
      toast.error("Accept all fields");
    }
  }

  function Reject() {
    if (
      save_data?.profile_img == "0" ||
      save_data?.aadhaar == "0" ||
      save_data?.driving_license == "0" ||
      save_data?.pan == "0" ||
      (transaction_details.hasOwnProperty("rc_book_1") &&
        save_data.rc_book == "0")
    ) {
      toast.error("Fill all the fields");
    } else {
      SetModalVariants((prevValue) => ({
        ...prevValue,
        is_open: true,
        data: save_data,
      }));
    }
  }

  async function post_accept() {
    let pass_data = {
      post: {
        id: save_data.id,
        status: 1,
        profile_img: save_data.profile_img,
        driving_license: save_data.driving_license,
        aadhaar: save_data.aadhaar,
        pan: save_data.pan,
        rc_book: save_data.rc_book,
        reason: save_data.reason,
        profile_img_reason: save_data.profile_img_reason,
        driving_license_reason: save_data.driving_license_reason,
        aadhaar_reason: save_data.aadhaar_reason,
        pan_reason: save_data.pan_reason,
        rc_book_reason: save_data.rc_book_reason,
      },
    };

    let response = await api(
      "/orders/delivery_partner_request_status",
      pass_data
    );
    if (response.status_code == 200) {
      toast.success("accepted");
      setPayments([]);
      let pass_data = {
        sort: "",
        page_no: page_no,
      };
      get_deliveryprtner_request(pass_data, filter_data, view);
    }
  }

  function enable(ele: any) {
    SetShowDetailsLoader(true);
    const timer = setTimeout(() => {
      SetShowDetailsLoader(false);
    }, 500);

    SetTransactionAble(true);
    SetTransactionDetails(ele);

    const newValues = {
      id: ele.agent_id,
      status: ele.status_id,
      profile_img: ele.profile_img_status ? ele.profile_img_status : "",
      driving_license: ele.driving_license_status,
      aadhaar: ele.aadhaar_status,
      pan: ele.pan_status,
      rc_book: ele.rc_book_status,
      reason: ele.rejected_reason ? ele.rejected_reason : "",
      profile_img_reason: ele.profile_img_reason ? ele.profile_img_reason : "",
      driving_license_reason: ele.driving_license_reason
        ? ele.driving_license_reason
        : "",
      aadhaar_reason: ele.aadhaar_reason ? ele.aadhaar_reason : "",
      pan_reason: ele.pan_reason ? ele.pan_reason : "",
      rc_book_reason: ele.rc_book_reason ? ele.rc_book_reason : "",
      active_account: ele.active_account,
    };
    SetSaveData(newValues);
    return () => clearTimeout(timer);
  }

  function disable() {
    SetShowDetailsLoader(false);
    SetTransactionAble(false);
    SetSaveData(initial_value);
  }

  const handleButtonClick = (status: any) => {
    // Handle button click logic based on the status (New, Active, Rejected)
    SetView(status);
    SetTransactionAble(false);
    let pass_data: any = {
      sort: sort_val,
      // page_no: 1,
    };
    setPayments([]);
    // SetNextPage(false);
    SetPageNo((prevalue) => {
      prevalue = 1;
      pass_data.page_no = prevalue;
      get_deliveryprtner_request(pass_data, filter_data, status);
      return prevalue;
    });
  };

  const handleInputChange = (e: any) => {
    const searchValue = e.target.value;
    setFilterData(searchValue);
    // Clear previous timeout
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // Set a new timeout
    const newTimeoutId = setTimeout(() => {
      // Call your API with the search value
      let pass_data = {
        sort: "",
        page_no: 1,
      };
      setPayments([]);
    SetTransactionAble(false);
      
      get_deliveryprtner_request(pass_data, searchValue, view);
    }, 1000); // 5 milliseconds delay

    // Save the timeout ID
    setTimeoutId(newTimeoutId);
  };

  const DeliveryPartnerCard = () => {
    return (
      <div className="col-md-6 sm-mt-6 sm-pb-5 md-ps-2">
        {payment_loading ? (
          [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((ele) => (
            <div className="card mb-2 p-3">
              <div className="row align-items-center border-bottom">
                <div className="col-6 pb-3">
                  <Skeleton height={10} width={50} />
                </div>
                <div className="col-6 text-end pb-3">
                  <Skeleton height={30} width={100} />
                </div>
                <div className="col-6 pb-2">
                  <Skeleton height={10} width={100} count={2} />
                </div>
                <div className="col-6 pb-2 text-end">
                  <Skeleton height={10} width={80} count={2} />
                </div>
              </div>
              <div className="pt-2">
                <Skeleton height={10} width={80} />
                <div className="pt-2">
                  <Skeleton height={10} width={180} />
                </div>
              </div>
            </div>
          ))
        ) : payments.length > 0 ? (
          <>
            <InfiniteScroll
              dataLength={payments.length}
              next={() => {
                let pass_data: any = {
                  order_status: order_status,
                  seller_brand_id: "",
                  seller_outlet_id: "",
                  sort: "",
                  load_more: true,

                  page_no: page_no,
                };

                get_deliveryprtner_request(pass_data, filter_data, view);
              }}
              // hasMore={get_next_page()}
              hasMore={next_page}
              loader={
                <div className="center m-5">
                  <div className="lds-dual-ring"></div>
                  <p className=" small ps-2">Just a second ...</p>
                </div>
              }
            >
              {payments.map((ele, i) => (
                <div
                  key={i}
                  className={
                    transaction_able == true && selected_details == i
                      ? "active-shadow-layout radius  mb-2 p-3"
                      : "card mb-2 p-3"
                  }
                  onClick={() => {
                    enable(ele);
           
                    SetSelectedDetails(i);
                  }}
                >
                  <div className="row align-items-center">
                    <div className="col-6 pb-3">
                     
                      <p className="text-black">Agent ID: #{ele.agent_id}</p>
                     
                  
                    </div>

                    <div className="col-6 end pb-3">  <div className="ms-auto"> {ele.date}</div></div>

                    <div className="col-6 pb-2">
                      <p className="text-dark fw-bold fs-7">{ele?.name}</p>
                    </div>
                    <div className="col-6 end pb-2">
                      <FontAwesome
                        name="circle"
                        size={10}
                        color={ele.status == "Rejected" ? "red" : "green"}
                      />
                      <p
                        className={
                          ele.status == "Rejected"
                            ? "ps-2 text-red"
                            : "ps-2 text-green"
                        }
                      >
                        {ele.status}
                      </p>
                    </div>

                    <div className="col-6 pb-2 d-flex align-items-center">
                      <Ionicons name="call-outline" size={16} color="#aaa" />
                      <p className="ps-1 text-dark">{ele.mobile}</p>
                    </div>

                    <div className="col-6 end pb-2 ">
                      <Ionicons
                        name="md-location-outline"
                        size={16}
                        color="#aaa"
                      />
                      <p className="ps-1 text-dark">{ele.location}</p>
                    </div>

                    {ele.status == "Rejected" ? (
                      <div className="">
                        <div className="pt-1 pb-2">
                          <hr className="text-gray m-0" />
                        </div>

                        <p className="pb-2 text-dark fw-500">Rejected Reason</p>
                        <p className="text-gray">
                          {ele.rejected_reason != ""
                            ? ele.rejected_reason
                            : "-"}
                        </p>
                      </div>
                    ) : null}
                  </div>
                </div>
              ))}
            </InfiniteScroll>
          </>
        ) : (
          <div className="center p-3">
            <div className="card p-3 w-100">
              <div className="w-100 text-center">
                <img
                  src={require("../../assets/img/not-found.png")}
                  className="no-data-img"
                />
              </div>
              <p className="center pt-3">No New Request</p>
            </div>
          </div>
        )}
      </div>
    );
  };

  const sort = (val:any) =>{

    SetSort(val);

   let pass_data = {
      order_status: 1,
      seller_brand_id: "",
      seller_outlet_id: "",
      sort: val,
      page_no: 1,
    };
    SetTransactionAble(false);

    get_deliveryprtner_request(pass_data, filter_data, view);

  }

  return (
    <div className="mb-5">
      <Toaster />
      {/* Header */}
      <div className="head-bar row">
        <div className="col-md-6">
          <h5 className="  mb-2">Delivery Partner Request</h5>
        </div>

        <div className="col-md-6 sm-pt-2 end">
          {/* search */}
          <div className="input-icons form-group p-2">
            <input
              type="text"
              className="form-control search-bar p-0"
              placeholder="Search here..."
              onChange={handleInputChange}
            />
            <div className="cursor px-2 border-left">
              <Ionicons name="md-search" size={16} color="#ccc" />
            </div>
          </div>
          <div className="dropdown">
            <button
              className="btn btn-white dropdown-toggle d-flex align-items-cetner"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src={require("../../assets/icon/filter.png")}
                className="icon-14px"
              />
          
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <a className="dropdown-item" href="#">
                  <div
                    onClick={() => 
                      sort("asc")
                    }
                  >
                    <p className="mb-0 p-2">Oldest</p>
                  </div>
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  <div
                    onClick={() => sort("desc") }
                  >
                    <p className="mb-0 p-2">Newest</p>
                  </div>
                </a>
              </li>
          
            </ul>
          </div>
        </div>

<div className="d-flex">
        <div className="mt-3">
          <button
            type="button"
            className={
              view == "new"
                ? "btn btn-text header-tap me-1 px-3 "
                : "btn btn-text text-black me-1 px-3"
            }
            onClick={() => handleButtonClick("new")}
          >
            New
          </button>
          <button
            type="button"
            className={
              view == ""
                ? "btn btn-text header-tap me-1 px-3 "
                : "btn btn-text text-black me-1 px-3"
            }
            onClick={() => handleButtonClick("")}
          >
            All
          </button>
          <button
            type="button"
            className={
              view == "active"
                ? "btn btn-text header-tap me-1 px-3 "
                : "btn btn-text text-black me-1 px-3"
            }
            onClick={() => handleButtonClick("active")}
          >
            Accepted
          </button>

          <button
            type="button"
            className={
              view == "rejected"
                ? "btn btn-text header-tap me-2 "
                : "btn btn-text text-black me-2"
            }
            onClick={() => handleButtonClick("rejected")}
          >
            Rejected
          </button>
        </div>

   
        </div>
      </div>

      <div className="mt-3 px-md-3 px-sm-0 ">
        <div className="row">
          {width < 768 && transaction_able == false ? (
            <DeliveryPartnerCard />
          ) : width > 768 ? (
            <DeliveryPartnerCard />
          ) : null}

          {transaction_able && (
             <div className="col-md-6  sm-pb-5 sm-mt-6">
              <div className="overflowY-90vh pb-5 position-sticky-top-50px">
                {show_details_loader ? (
                  <div className="card bg-fff p-3">
                    <div className="mb-4">
                      <Skeleton height={10} width={180} />
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-4 pb-3">
                        <div className="pb-2">
                          <Skeleton height={10} width={80} />
                        </div>
                        <Skeleton height={10} width={30} />
                      </div>
                      <div className="col-6 col-md-4 pb-3">
                        <div className="pb-2">
                          <Skeleton height={10} width={80} />
                        </div>
                        <Skeleton height={10} width={30} />
                      </div>
                      <div className="col-6 col-md-4 pb-3">
                        <div className="pb-2">
                          <Skeleton height={10} width={80} />
                        </div>
                        <Skeleton height={10} width={30} />
                      </div>
                      <div className="pt-1 pb-2">
                        <hr className="text-gray m-0" />
                      </div>
                      <div className="mb-2">
                        <div className="mb-2 mt-4">
                          <Skeleton height={10} width={150} />
                        </div>
                        <div className="mb-2">
                          <div className="pb-2">
                            <Skeleton height={10} width={50} />
                          </div>
                          <Skeleton height={10} width={100} />
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-6">
                            <div className="col-4 mb-3 pe-0">
                              <Skeleton height={70} width="100%" />
                            </div>
                          </div>
                          <Skeleton height={30} width="100%" />
                        </div>
                      </div>

                      <div className="mb-2">
                        <div className="mb-2 mt-4">
                          <Skeleton height={10} width={150} />
                        </div>
                        <div className="mb-2">
                          <div className="pb-2">
                            <Skeleton height={10} width={50} />
                          </div>
                          <Skeleton height={10} width={100} />
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-6">
                            <div className="col-4 mb-3 pe-0">
                              <Skeleton height={70} width="100%" />
                            </div>
                            <Skeleton height={30} width="100%" />
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="col-4 mb-3 pe-0">
                              <Skeleton height={70} width="100%" />
                            </div>
                            <Skeleton height={30} width="100%" />
                          </div>
                        </div>
                      </div>

                      <div className="mb-2">
                        <div className="mb-2 mt-4">
                          <Skeleton height={10} width={150} />
                        </div>
                        <div className="mb-2">
                          <div className="pb-2">
                            <Skeleton height={10} width={50} />
                          </div>
                          <Skeleton height={10} width={100} />
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-6">
                            <div className="col-4 mb-3 pe-0">
                              <Skeleton height={70} width="100%" />
                            </div>
                            <Skeleton height={30} width="100%" />
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="col-4 mb-3 pe-0">
                              <Skeleton height={70} width="100%" />
                            </div>
                            <Skeleton height={30} width="100%" />
                          </div>
                        </div>
                      </div>

                      <div className="mb-2">
                        <div className="mb-2 mt-4">
                          <Skeleton height={10} width={150} />
                        </div>
                        <div className="mb-2">
                          <div className="pb-2">
                            <Skeleton height={10} width={50} />
                          </div>
                          <Skeleton height={10} width={100} />
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-6">
                            <div className="col-4 mb-3 pe-0">
                              <Skeleton height={70} width="100%" />
                            </div>
                            <Skeleton height={30} width="100%" />
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="col-4 mb-3 pe-0">
                              <Skeleton height={70} width="100%" />
                            </div>
                            <Skeleton height={30} width="100%" />
                          </div>
                        </div>
                      </div>

                      <div className="mb-2">
                        <div className="mb-2 mt-4">
                          <Skeleton height={10} width={150} />
                        </div>
                        <div className="mb-2">
                          <div className="pb-2">
                            <Skeleton height={10} width={50} />
                          </div>
                          <Skeleton height={10} width={100} />
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-6">
                            <div className="col-4 mb-3 pe-0">
                              <Skeleton height={70} width="100%" />
                            </div>
                            <Skeleton height={30} width="100%" />
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="col-4 mb-3 pe-0">
                              <Skeleton height={70} width="100%" />
                            </div>
                            <Skeleton height={30} width="100%" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="card bg-fff p-3">
                    <div className="d-flex align-items-center mb-4">
                      <div
                        className="cursor pe-3"
                        onClick={() => {
                          disable();
                        }}
                      >
                        <MaterialCommunityIcons
                          name="arrow-left"
                          size={18}
                          color="black"
                        />
                      </div>
                      <h6 className="mb-0">Delivery Partner Request Details</h6>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-4 pb-3">
                        <div className="heading pb-2">Agent Id</div>
                        <div className="value">
                          #{transaction_details.agent_id}
                        </div>
                      </div>

                      <div className="col-6 col-md-4 pb-3">
                        <div className="heading pb-2">Name</div>
                        <div className="value">{transaction_details?.name}</div>
                      </div>
                      <div className="col-6 col-md-4 pb-3">
                        <div className="heading pb-2">User Name</div>
                        <div className="value">
                          {transaction_details?.user_details?.name}
                        </div>
                      </div>

                      <div className="col-6 col-md-4 pb-3">
                        <div className="heading pb-2">Location</div>
                        <div className="value">
                          {transaction_details.location}
                        </div>
                      </div>
                      <div className="col-6 col-md-4 pb-3">
                        <div className="heading pb-2">Mobile</div>
                        <div className="value">
                          {transaction_details.mobile}
                        </div>
                      </div>
                     
                      {transaction_details.status_id == 2 &&
                      <div className="col-12 pb-3">
                        <div className="heading pb-2">Rejected Reason</div>
                        <div className="value">
                          {transaction_details.rejected_reason}
                        </div>
                      </div>
}

                      <div className="pt-1 pb-2">
                        <hr className="text-gray m-0" />
                      </div>

                      <h6 className="mb-3">Superapp User Details</h6>
                      <div className="col-6 col-md-4 pb-3">
                        <div className="heading pb-2">Id</div>
                        <div className="value">
                          #{transaction_details?.user_details?.id}
                        </div>
                      </div>
                      {transaction_details?.user_details?.profile &&
                      <div className="col-6 col-md-4 pb-3">
                        <div className="heading pb-2">profile</div>
                       
                                  <a
                                    href={transaction_details?.user_details?.profile}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      src={transaction_details?.user_details?.profile}
                                      className="delivery_profile_img"
                                    />
                                  </a>
                            
                      </div>
}
                      <div className="col-6 col-md-4 pb-3">
                        <div className="heading pb-2">Name</div>
                        <div className="value">{transaction_details?.user_details?.name}</div>
                      </div>
                   

                      
                      <div className="col-6 col-md-4 pb-3">
                        <div className="heading pb-2">User Name</div>
                        <div className="value">
                          {transaction_details?.user_details?.username}
                        </div>
                      </div>

                  
                      <div className="col-6 col-md-4 pb-3">
                        <div className="heading pb-2">Mobile</div>
                        <div className="value">
                          {transaction_details?.user_details?.mobile}
                        </div>
                      </div>
                    </div>
                 
                    {(save_data.status != "2" ||
                      (save_data.status == "2" &&
                        save_data.active_account == false)) && (
                      <>
                        {/* Profile */}
                        <div className="mb-2">
                          <h6 className="mb-2 mt-4">Profile Details</h6>

                          <div className="mb-2">
                            {save_data.status == "0" ? (
                              <div className="py-2">
                                <div className=" d-flex align-items-center pb-2">
                                  <label className="pe-3 text-dark cursor d-flex align-items-center">
                                    <input
                                      type="radio"
                                      value="1"
                                      checked={save_data.profile_img == "1"}
                                      onChange={(e, action) => {
                                        SetSaveData((oldValues: any) => ({
                                          ...oldValues,
                                          profile_img: e.target.value,
                                        }));
                                      }}
                                    />{" "}
                                    <span className="ps-2">Accept</span>
                                  </label>
                                  {/* <label className=" text-dark cursor d-flex align-items-center">
                                    <input
                                      type="radio"
                                      value="reject"
                                      onChange={handleRadioChangeNor}
                                      checked={save_data.profile_img == "2"}
                                      onChange={(e, action) => {
                                        SetSaveData((oldValues: any) => ({
                                          ...oldValues,
                                          profile_img: "2",
                                        }));
                                      }}
                                    />{" "}
                                    <span className="ps-2">Reject</span>
                                  </label> */}
                                </div>
                                {save_data.profile_img == "2" && (
                                  <input
                                    type="text"
                                    className=" form-control"
                                    placeholder="Reason for rejection"
                                    value={
                                      save_data.profile_img_reason
                                        ? save_data.profile_img_reason
                                        : ""
                                    }
                                    Add
                                    additional
                                    attributes
                                    or
                                    event
                                    handlers
                                    as
                                    needed
                                    onChange={(e) => {
                                      outlet_value_on_change(
                                        "profile_img_reason",
                                        e.target.value
                                      );
                                    }}
                                  />
                                )}
                              </div>
                            ) : save_data.profile_img == "1" ? (
                              <div>
                                <p className="text-darkgray small">Status</p>
                                <p className="text-dark  fw-bold">Accepted</p>
                              </div>
                            ) : (
                              <div>
                                <p className="text-darkgray small">Status</p>
                                <p className="text-dark fw-bold">Rejected</p>
                              </div>
                            )}
                          </div>

                          {transaction_details?.profile_img != null ? (
                            <div className="row">
                              <div className="col-12 col-md-6">
                                <div className="col-4  pe-0">
                                  <a
                                    href={transaction_details?.profile_img}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      src={transaction_details?.profile_img}
                                      className="delivery_profile_img"
                                    />
                                  </a>
                                </div>
                              </div>

                              <div className="col-12 pt-2">
                                <div className="border radius mb-2">
                                  <div className="d-flex align-items-center">
                                    <p className="small w-80 pe-2 p-2">
                                      {transaction_details?.profile_img_name}
                                    </p>
                                    <div className="border-left py-1 px-3 center w-20 cursor">
                                      <a
                                        href={transaction_details?.profile_img}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <p className="text-prime fw-bold">
                                          View
                                        </p>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>

                        {/* Aadhaar */}
                        <div className="mb-2">
                          {/* <div className="row d-flex"> */}
                          <h6 className="mb-2 mt-4">Aadhaar Details</h6>

                          <div className="my-2">
                            {save_data.status == "0" ? (
                              <div className="py-2">
                                <div className=" d-flex align-items-center pb-2">
                                  <label className="pe-3 text-dark cursor d-flex align-items-center">
                                    <input
                                      type="radio"
                                      value="accept1"
                                      onChange={(e, action) => {
                                        SetSaveData((oldValues: any) => ({
                                          ...oldValues,
                                          aadhaar: "1",
                                        }));
                                      }}
                                      checked={save_data.aadhaar == "1"}
                                    />{" "}
                                    <span className="ps-2">Accept</span>
                                  </label>
                                  <label className=" text-dark cursor d-flex align-items-center">
                                    <input
                                      type="radio"
                                      value="reject1"
                                      checked={save_data.aadhaar == "2"}
                                      onChange={(e, action) => {
                                        SetSaveData((oldValues: any) => ({
                                          ...oldValues,
                                          aadhaar: "2",
                                        }));
                                      }}
                                    />{" "}
                                    <span className="ps-2">Reject</span>
                                  </label>
                                </div>

                                {save_data.aadhaar == "2" && (
                                  <input
                                    type="text"
                                    className=" form-control"
                                    placeholder="Reason for rejection"
                                    value={
                                      save_data.aadhaar_reason
                                        ? save_data.aadhaar_reason
                                        : ""
                                    }
                                    onChange={(e) => {
                                      outlet_value_on_change(
                                        "aadhaar_reason",
                                        e.target.value
                                      );
                                    }}
                                    // Add additional attributes or event handlers as needed
                                  />
                                )}
                              </div>
                            ) : save_data.aadhaar == "1" ? (
                              <div>
                                <p className="text-darkgray small">Status</p>
                                <p className="text-dark fw-bold">Accepted</p>
                              </div>
                            ) : (
                              <div>
                                <p className="text-darkgray small">Status</p>
                                <p className="text-dark fw-bold">Rejected</p>
                              </div>
                            )}
                          </div>

                          <div className="row pt-2">
                            <div className="col-12 col-md-6">
                              {transaction_details.aadhaar_1_type ===
                                "file" && (
                                <div className="col-4 pe-0">
                                  <a
                                    href={transaction_details?.aadhaar_1}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <MaterialCommunityIcons
                                      name="file"
                                      size={30}
                                      color="#777"
                                    />
                                  </a>
                                </div>
                              )}

                              {transaction_details.aadhaar_1_type ===
                                "image" && (
                                <div className="col-4 pe-0">
                                  <a
                                    href={transaction_details?.aadhaar_1}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      src={transaction_details?.aadhaar_1}
                                      className="delivery_profile_img"
                                      alt="Description of the image"
                                    />
                                  </a>
                                </div>
                              )}

                              <div className=" pt-2">
                                {transaction_details.aadhaar_1_type ===
                                  "file" ||
                                transaction_details.aadhaar_1_type ===
                                  "image" ? (
                                  <div className="border radius mb-2">
                                    <div className="d-flex align-items-center">
                                      <p className="small w-80 pe-2  p-2">
                                        {transaction_details?.aadhaar_1_name}
                                      </p>
                                      <div className="border-left  px-3 center w-20 cursor">
                                        <a
                                          href={transaction_details?.aadhaar_1}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <p className="text-prime fw-bold">
                                            View
                                          </p>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>

                            <div className="col-12 col-md-6">
                              {transaction_details.aadhaar_2_type ===
                                "file" && (
                                <div className="col-4 pe-0">
                                  <a
                                    href={transaction_details?.aadhaar_2}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <MaterialCommunityIcons
                                      name="file"
                                      size={30}
                                      color="#777"
                                    />
                                  </a>
                                </div>
                              )}

                              {transaction_details.aadhaar_2_type ===
                                "image" && (
                                <div className="col-4 pe-0">
                                  <a
                                    href={transaction_details?.aadhaar_2}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      src={transaction_details?.aadhaar_2}
                                      className="delivery_profile_img"
                                      alt="Description of the image"
                                    />
                                  </a>
                                </div>
                              )}
                              <div className="pt-2">
                                {transaction_details.aadhaar_2_type ===
                                  "file" ||
                                transaction_details.aadhaar_2_type ===
                                  "image" ? (
                                  <div className="border radius mb-2">
                                    <div className="d-flex align-items-center">
                                      <p className="small w-80 pe-2 p-2">
                                        {" "}
                                        {transaction_details?.aadhaar_2_name}
                                      </p>
                                      <div className="border-left px-3 center w-20 cursor">
                                        <a
                                          href={transaction_details?.aadhaar_2}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <p className="text-prime fw-bold">
                                            View
                                          </p>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* License */}
                        <div className="mb-2">
                          <h6 className="mb-2 mt-4">Driving License Details</h6>

                          <div className="my-2">
                            {save_data.status == "0" ? (
                              <div className="pt-2">
                                <div className=" d-flex align-items-center pb-2">
                                  <label className="pe-3 text-dark cursor d-flex align-items-center">
                                    <input
                                      type="radio"
                                      value="accept"
                                      onChange={(e, action) => {
                                        SetSaveData((oldValues: any) => ({
                                          ...oldValues,
                                          driving_license: "1",
                                        }));
                                      }}
                                      checked={save_data.driving_license == "1"}
                                    />{" "}
                                    <span className="ps-2">Accept</span>
                                  </label>
                                  <label className=" text-dark cursor d-flex align-items-center">
                                    <input
                                      type="radio"
                                      value="reject"
                                      onChange={(e, action) => {
                                        SetSaveData((oldValues: any) => ({
                                          ...oldValues,
                                          driving_license: "2",
                                        }));
                                      }}
                                      checked={save_data.driving_license == "2"}
                                    />{" "}
                                    <span className="ps-2">Reject</span>
                                  </label>
                                  {save_data.driving_license == "2" && (
                                    <div className="my-2">
                                      <input
                                        type="text"
                                        className=" form-control"
                                        placeholder="Reason for rejection"
                                        value={
                                          save_data.driving_license_reason
                                            ? save_data.driving_license_reason
                                            : ""
                                        }
                                        onChange={(e) => {
                                          outlet_value_on_change(
                                            "driving_license_reason",
                                            e.target.value
                                          );
                                        }}
                                        // Add additional attributes or event handlers as needed
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            ) : save_data.driving_license == "1" ? (
                              <div>
                                <p className="text-darkgray small">Status</p>
                                <p className="text-dark fw-bold">Accepted</p>
                              </div>
                            ) : (
                              <div>
                                <p className="text-darkgray small">Status</p>
                                <p className="text-dark fw-bold">Rejected</p>
                              </div>
                            )}
                          </div>

                          {/* <div className="row d-flex"> */}
                          <div className="row pt-2">
                            <div className="col-12 col-md-6">
                              {transaction_details.driving_license_1_type ===
                                "file" && (
                                <div className="col-4 pe-0">
                                  <a
                                    href={
                                      transaction_details?.driving_license_1
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <MaterialCommunityIcons
                                      name="file"
                                      size={30}
                                      color="#777"
                                    />
                                  </a>
                                </div>
                              )}

                              {transaction_details.driving_license_1_type ===
                                "image" && (
                                <div className="col-4 pe-0">
                                  <a
                                    href={
                                      transaction_details?.driving_license_1
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      src={
                                        transaction_details?.driving_license_1
                                      }
                                      className="delivery_profile_img"
                                      alt="Description of the image"
                                    />
                                  </a>
                                </div>
                              )}

                              <div className="pt-2">
                                {transaction_details.driving_license_1_type ===
                                  "file" ||
                                transaction_details.driving_license_1_type ===
                                  "image" ? (
                                  <div className="border radius mb-2">
                                    <div className="d-flex align-items-center">
                                      <p className="small w-80 pe-2 p-2">
                                        {
                                          transaction_details?.driving_license_1_name
                                        }
                                      </p>
                                      <div className="border-left py-1 px-3 center w-20 cursor">
                                        <a
                                          href={
                                            transaction_details?.driving_license_1
                                          }
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <p className="text-prime fw-bold">
                                            View
                                          </p>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>

                            <div className="col-12 col-md-6">
                              {transaction_details.driving_license_2_type ===
                                "file" && (
                                <div className="col-4 pe-0">
                                  <a
                                    href={
                                      transaction_details?.driving_license_2
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <MaterialCommunityIcons
                                      name="file"
                                      size={30}
                                      color="#777"
                                    />
                                  </a>
                                </div>
                              )}

                              {transaction_details.driving_license_2_type ===
                                "image" && (
                                <div className="col-4 pe-0">
                                  <a
                                    href={
                                      transaction_details?.driving_license_2
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      src={
                                        transaction_details?.driving_license_2
                                      }
                                      className="delivery_profile_img"
                                      alt="Description of the image"
                                    />
                                  </a>
                                </div>
                              )}

                              <div className=" pt-2">
                                {transaction_details.driving_license_2_type ===
                                  "file" ||
                                transaction_details.driving_license_2_type ===
                                  "image" ? (
                                  <div className="border radius mb-2">
                                    <div className="d-flex align-items-center">
                                      <p className="small w-80 pe-2  p-2">
                                        {
                                          transaction_details?.driving_license_2_name
                                        }
                                      </p>
                                      <div className="border-left py-1 px-3 center w-20 cursor">
                                        <a
                                          href={
                                            transaction_details?.driving_license_2
                                          }
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <p className="text-prime fw-bold">
                                            View
                                          </p>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Pan Card */}
                        <div className="mb-2">
                          <h6 className="mb-2 mt-4">Pan Card Details</h6>
                          <div className="my-2">
                            {save_data.status == "0" ? (
                              <div className="pt-2">
                                <div className=" d-flex align-items-center pb-2">
                                  <label className="pe-3 text-dark cursor d-flex align-items-center">
                                    <input
                                      type="radio"
                                      value="accept"
                                      onChange={(e, action) => {
                                        SetSaveData((oldValues: any) => ({
                                          ...oldValues,
                                          pan: "1",
                                        }));
                                      }}
                                      checked={save_data.pan == "1"}
                                    />{" "}
                                    <span className="ps-2">Accept</span>
                                  </label>
                                  <label className=" text-dark cursor d-flex align-items-center">
                                    <input
                                      type="radio"
                                      value="reject"
                                      onChange={(e, action) => {
                                        SetSaveData((oldValues: any) => ({
                                          ...oldValues,
                                          pan: "2",
                                        }));
                                      }}
                                      checked={save_data.pan == "2"}
                                    />{" "}
                                    <span className="ps-2">Reject</span>
                                  </label>
                                </div>
                                {save_data.pan == "2" && (
                                  <div className="my-2">
                                    <input
                                      type="text"
                                      className=" form-control"
                                      placeholder="Reason for rejection"
                                      value={
                                        save_data.pan_reason
                                          ? save_data.pan_reason
                                          : ""
                                      }
                                      onChange={(e) => {
                                        outlet_value_on_change(
                                          "pan_reason",
                                          e.target.value
                                        );
                                      }}
                                      // Add additional attributes or event handlers as needed
                                    />
                                  </div>
                                )}
                              </div>
                            ) : save_data.pan == "1" ? (
                              <div>
                                <p className="text-darkgray small">Status</p>
                                <p className="text-dark fw-bold">Accepted</p>
                              </div>
                            ) : (
                              <div>
                                <p className="text-darkgray small">Status</p>
                                <p className="text-dark fw-bold">Rejected</p>
                              </div>
                            )}
                          </div>
                          <div className="row pt-2">
                            <div className="col-12 col-md-6">
                              {transaction_details.pan_1_type === "image" && (
                                <div className="col-4 pe-0">
                                  <a
                                    href={transaction_details?.pan_1}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      src={transaction_details?.pan_1}
                                      className="delivery_profile_img"
                                    />{" "}
                                  </a>
                                </div>
                              )}

                              {transaction_details.pan_1_type === "file" && (
                                <div className="col-4 pe-0">
                                  <a
                                    href={transaction_details?.pan_1}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <MaterialCommunityIcons
                                      name="file"
                                      size={30}
                                      color="#777"
                                    />
                                  </a>
                                </div>
                              )}

                              <div className="pt-2">
                                {transaction_details.pan_1_type === "file" ||
                                transaction_details.pan_1_type === "image" ? (
                                  <div className="border radius mb-2">
                                    <div className="d-flex align-items-center">
                                      <p className="small w-80 pe-2  p-2">
                                        {transaction_details?.pan_1_name}
                                      </p>
                                      <div className="border-left py-1 px-3 center w-20 cursor">
                                        <a
                                          href={transaction_details?.pan_1}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <p className="text-prime fw-bold">
                                            View
                                          </p>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>

                            <div className="col-12 col-md-6">
                              {transaction_details.pan_2_type === "file" && (
                                <div className="col-4 pe-0">
                                  <a
                                    href={transaction_details?.pan_2}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <MaterialCommunityIcons
                                      name="file"
                                      size={30}
                                      color="#777"
                                    />
                                  </a>
                                </div>
                              )}

                              {transaction_details.pan_2_type === "image" && (
                                <div className="col-4 pe-0">
                                  <a
                                    href={transaction_details?.pan_2}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      src={transaction_details?.pan_2}
                                      className="delivery_profile_img"
                                      alt="Description of the image"
                                    />
                                  </a>
                                </div>
                              )}

                              <div className="pt-2">
                                {transaction_details.pan_2_type === "file" ||
                                transaction_details.pan_2_type === "image" ? (
                                  <div className="border radius mb-2">
                                    <div className="d-flex align-items-center">
                                      <p className="small w-80 pe-2  p-2">
                                        {transaction_details?.pan_2_name}
                                      </p>
                                      <div className="border-left py-1 px-3 center w-20 cursor">
                                        <a
                                          href={transaction_details?.pan_2}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <p className="text-prime fw-bold">
                                            View
                                          </p>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* RC Book */}
                        {transaction_details.hasOwnProperty("rc_book_1") && (
                          <div className="mb-2">
                            <h6 className="mb-2 mt-4">Rc Book Details</h6>

                            <div className="my-2">
                              {save_data.status == "0" ? (
                                <div className="pt-2">
                                  <div className=" d-flex align-items-center pb-2">
                                    <label className="pe-3 text-dark cursor d-flex align-items-center">
                                      <input
                                        type="radio"
                                        value="accept1"
                                        onChange={(e, action) => {
                                          SetSaveData((oldValues: any) => ({
                                            ...oldValues,
                                            rc_book: "1",
                                          }));
                                        }}
                                        checked={save_data.rc_book == "1"}
                                      />{" "}
                                      <span className="ps-2">Accept</span>
                                    </label>
                                    <label className=" text-dark cursor d-flex align-items-center">
                                      <input
                                        type="radio"
                                        value="reject1"
                                        checked={save_data.rc_book == "2"}
                                        onChange={(e, action) => {
                                          SetSaveData((oldValues: any) => ({
                                            ...oldValues,
                                            rc_book: "2",
                                          }));
                                        }}
                                      />{" "}
                                      <span className="ps-2">Reject</span>
                                    </label>
                                  </div>
                                  {save_data.rc_book == "2" && (
                                    <div className="my-2">
                                      <input
                                        type="text"
                                        className=" form-control"
                                        placeholder="Reason for rejection"
                                        value={
                                          save_data.rc_book_reason
                                            ? save_data.rc_book_reason
                                            : ""
                                        }
                                        onChange={(e) => {
                                          outlet_value_on_change(
                                            "rc_book_reason",
                                            e.target.value
                                          );
                                        }}
                                        // Add additional attributes or event handlers as needed
                                      />
                                    </div>
                                  )}
                                </div>
                              ) : save_data.rc_book == "1" ? (
                                <div>
                                  <p className="text-darkgray small">Status</p>
                                  <p className="text-dark fw-bold">Accepted</p>
                                </div>
                              ) : (
                                <div>
                                  <p className="text-darkgray small">Status</p>
                                  <p className="text-dark fw-bold">Rejected</p>
                                </div>
                              )}
                            </div>

                            <div className="row pt-2">
                              <div className="col-12 col-md-6">
                                {transaction_details.rc_book_1_type ===
                                  "file" && (
                                  <div className="col-4 pe-0">
                                    <a
                                      href={transaction_details?.rc_book_1}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <MaterialCommunityIcons
                                        name="file"
                                        size={30}
                                        color="#777"
                                      />
                                    </a>
                                  </div>
                                )}

                                {transaction_details.rc_book_1_type ===
                                  "image" && (
                                  <div className="col-4 pe-0">
                                    <a
                                      href={transaction_details?.rc_book_1}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <img
                                        src={transaction_details?.rc_book_1}
                                        className="delivery_profile_img"
                                        alt="Description of the image"
                                      />
                                    </a>
                                  </div>
                                )}

                                <div className=" pt-2">
                                  {transaction_details.rc_book_1_type ===
                                    "file" ||
                                  transaction_details.rc_book_1_type ===
                                    "image" ? (
                                    <div className="border radius mb-2">
                                      <div className="d-flex align-items-center">
                                        <p className="small w-80 pe-2  p-2">
                                          {transaction_details?.rc_book_1_name}
                                        </p>
                                        <div className="border-left py-1 px-3 center w-20 cursor">
                                          <a
                                            href={
                                              transaction_details?.rc_book_1
                                            }
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            <p className="text-prime fw-bold">
                                              View
                                            </p>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </div>

                              <div className="col-12 col-md-6">
                                {transaction_details.rc_book_2_type ===
                                  "file" && (
                                  <div className="col-4 pe-0">
                                    <a
                                      href={transaction_details?.rc_book_2}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <MaterialCommunityIcons
                                        name="file"
                                        size={30}
                                        color="#777"
                                      />
                                    </a>
                                  </div>
                                )}

                                {transaction_details.rc_book_2_type ===
                                  "image" && (
                                  <div className="col-4 pe-0">
                                    <a
                                      href={transaction_details?.rc_book_2}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <img
                                        src={transaction_details?.rc_book_2}
                                        className="delivery_profile_img"
                                        alt="Description of the image"
                                      />
                                    </a>
                                  </div>
                                )}

                                <div className=" pt-2">
                                  {transaction_details.rc_book_2_type ===
                                    "file" ||
                                  transaction_details.rc_book_2_type ===
                                    "image" ? (
                                    <div className="border radius mb-2">
                                      <div className="d-flex align-items-center">
                                        <p className="small w-80 pe-2  p-2">
                                          {transaction_details?.rc_book_2_name}
                                        </p>
                                        <div className="border-left py-1 px-3 center w-20 cursor">
                                          <a
                                            href={
                                              transaction_details?.rc_book_2
                                            }
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            <p className="text-prime fw-bold">
                                              View
                                            </p>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </div>

                              {/* {save_data.status == "0" ?
  : (save_data.profile_img=="1"? <p>Accepted</p>:<p>Rejected</p>)} */}
                            </div>
                          </div>
                        )}

                        {save_data.status == "0" && (
                          <div className="pt-4 row">
                            <div className="col-12 col-md-6">
                              <button
                                type="button"
                                className="btn btn-outline-prime px-4 me-2"
                                onClick={() => Reject()}
                              >
                                Reject
                              </button>
                            </div>

                            <div className="col-6 end">
                              <button
                                type="button"
                                className="btn btn-green ms-2 px-4"
                                onClick={() => Accept()}
                              >
                                Accept
                              </button>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
          {/* Payment Gateway: {ele?.transaction?.payment_gateway || 'N/A'}, */}
          {/* PhonePe Response Data: {ele?.transaction?.phonepe_response_data || 'N/A'}, */}
        </div>
      </div>

      <ModalVariants
        open={modal_variants.is_open}
        data={modal_variants.data}
        close={(data: any) => {
          SetModalVariants((prevValue: any) => {
            prevValue.is_open = false;
            prevValue.data = {};

            return { ...prevValue };
          });

          if (data.action == "save") {
            setPayments([]);
            let pass_data = {
              sort: "",
              page_no: page_no,
            };

            SetTransactionAble(false);
            get_deliveryprtner_request(pass_data, filter_data, view);
          }
        }}
      />
    </div>
  );
}
