"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TraceDirectionKey = exports.Direction = exports.Axis = void 0;
var TraceDirectionKey;
exports.TraceDirectionKey = TraceDirectionKey;

(function (TraceDirectionKey) {
  TraceDirectionKey["NEGATIVE"] = "NEGATIVE";
  TraceDirectionKey["POSITIVE"] = "POSITIVE";
  TraceDirectionKey["NONE"] = "NONE";
})(TraceDirectionKey || (exports.TraceDirectionKey = TraceDirectionKey = {}));

var Direction;
exports.Direction = Direction;

(function (Direction) {
  Direction["TOP"] = "TOP";
  Direction["LEFT"] = "LEFT";
  Direction["RIGHT"] = "RIGHT";
  Direction["BOTTOM"] = "BOTTOM";
  Direction["NONE"] = "NONE";
})(Direction || (exports.Direction = Direction = {}));

var Axis;
exports.Axis = Axis;

(function (Axis) {
  Axis["X"] = "x";
  Axis["Y"] = "y";
})(Axis || (exports.Axis = Axis = {}));