import React ,{useState,useEffect} from 'react'
import { useOutletContext } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { api } from "../../utils/Api";


function OutletTotalOrderAmount() {

  const [overview_is_loading, SetOverviewIsLoading] = useState(true);
  const [statistics_data, SetStatisticsData]: any = useState([]);
  const [avg_value, SetAvgValue]: any = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const OutletContext = useOutletContext();

  const now = new Date();

  // const startOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
  // const endOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
  // const [startDateShow, setStartDateShow] = useState(getFormattedDateTime(startOfToday));
  // const [endDateShow, setEndDateShow] = useState(getFormattedDateTime(endOfToday));
  // const [startDate, setStartDate] = useState(convertDateTime(getFormattedDateTime(startOfToday)));
  // const [endDate, setEndDate] = useState(convertDateTime(getFormattedDateTime(endOfToday)));
  const [startDateShow, setStartDateShow] = useState("");
  const [endDateShow, setEndDateShow] = useState("");

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [width, SetWidth] = useState(innerWidth);

  const dropdownOptions = [
    { label: "Highest Order Amount", value: 1 },
    { label: "Lowest Order Amount", value: 2 },
    { label: "Ascending Brand Name", value: 3 },
    { label: "Descending Brand Name", value: 4 },
    { label: "Ascending Outlet Name", value: 5 },
    { label: "Descending Brand Name", value: 6 },
  ];

  useEffect(() => {
   
    let send_data = {
      startDate:startDate,
      endDate:endDate,
    };
    get_overview(send_data);

    // get_outlets_and_channels();
  }, []);


  
  function getFormattedDateTime(date) {
    
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-11
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }
  function convertDateTime(dateTimeStr) {
    const date = new Date(dateTimeStr);
    
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-11
    const year = date.getFullYear();
    
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = '00'; // Set seconds to 00
    
    const formattedDateTime = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    return formattedDateTime;
  }

  const handleStartChange = (e) => {
    //  let start:any = moment(date).format('DD-MM-YYYY HH:mm:ss')
    console.log(e.target.value)
    const convertedDateTime = convertDateTime(e.target.value);
      console.log(convertedDateTime);
      setStartDateShow(e.target.value)
     setStartDate(convertedDateTime);
    //  getSuperappStatistics(start,endDate)
    //  get_order_statistics(start,endDate)
    //  get_order_price_statistics(start,endDate)
    
    };
  
    const handleEndChange = (e) => {
      const convertedDateTime = convertDateTime(e.target.value);
      setEndDateShow(e.target.value)
    
    
        setEndDate(convertedDateTime);
        
      // getSuperappStatistics(startDate,end)
      // get_order_statistics(startDate,end)
      // get_order_price_statistics(startDate,end)
   
     
     
     };

     const handleApply = () => {

      const startTimestamp = new Date(startDateShow).getTime();
      const endTimestamp = new Date(endDateShow).getTime();
  
      console.log(startTimestamp)
      console.log(endTimestamp)
  
      
      console.log("hi")
      if (startTimestamp < endTimestamp) {
      //     getSuperappStatistics(startDate,endDate)
      // get_order_statistics(startDate,endDate,activeOrTab)
      // get_order_price_statistics(startDate,endDate,activeTab)
          // Add your logic for applying the selected dates

          let send_data = {
            page_no: 1,
            have_more: false,
            startDate:startDate,
            endDate:endDate,
          };
        
          get_overview(send_data);
      } else {
        toast.error("End date cannot be earlier than start date.");
      }
  };


  async function get_overview(get_data: any) {

    SetOverviewIsLoading(true)
     let pass_data = {
       get: {
         filter_from:get_data.startDate,
         filter_to: get_data.endDate,
       },
     };
     let response: any = await api("/marketing/statistics_total_outlet_orders_amount", pass_data);
     console.log("/qr/overview response :", response.response);
     if (response.status_code == 200) {
       if (response.response.hasOwnProperty("statistics")) {
         let statistics = response.response.statistics;
 
         console.log(statistics);
         SetStatisticsData(statistics);
       }

       if (response.response.hasOwnProperty("outlet_total_orders")) {
        let avg = response.response.outlet_total_orders;

        SetAvgValue(avg);
      }
       
       SetAvgValue
 
      
 
       SetOverviewIsLoading(false);
     }
   }

   const handleSort = (value) => {
    const sortedData = [...avg_value];

    switch (value) {
      case 1: // Highest Order Amount
        sortedData.sort((a, b) => b.total_orders_amount - a.total_orders_amount);
        break;

      case 2: // Lowest Order Amount
        sortedData.sort((a, b) => a.total_orders_amount - b.total_orders_amount);
        break;

      case 3: // Ascending Brand Name
        sortedData.sort((a, b) => a.brand_name.localeCompare(b.brand_name));
        break;

      case 4: // Descending Brand Name
        sortedData.sort((a, b) => b.brand_name.localeCompare(a.brand_name));
        break;

      case 5: // Ascending Outlet Name
        sortedData.sort((a, b) => a.outlet_name.localeCompare(b.outlet_name));
        break;

      case 6: // Descending Outlet Name
        sortedData.sort((a, b) => b.outlet_name.localeCompare(a.outlet_name));
        break;

      default:
        break;
    }

    SetAvgValue(sortedData);
  };


   const filteredOutlets = avg_value.filter((outlet) =>
    outlet?.outlet_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    outlet?.brand_name?.toLowerCase().includes(searchTerm.toLowerCase())
  );
 
  return (
    <div>
    <div
    className={OutletContext ? "head-bar sticky-sm py-3" : "head-bar py-3"}
    style={OutletContext == false ? { width: width } : {}}
  >
    <div className="d-flex-lg align-items-center">
      <div className="">
        <h6 className="fs-5  mb-0">Total Outlet Orders Amount Statistics</h6>
      </div>

      <div className="ms-lg-auto">  
<div className="d-flex-lg">
  <div className="m-2"> Statistics Filter :</div>
        <div className="me-2">
          {console.log(startDate)}
        <input type="datetime-local"
          onChange={handleStartChange}
        id="startDateTime"
        className="form-control"
        value={startDateShow}
        name="startDateTime" required/>

                {/* <Datetime 
                    id="start"
                    // value={startDate}
                    onChange={handleStartChange}
                    dateFormat={dateFormat}
                    timeFormat={timeFormat}
                    inputProps={{ placeholder: 'Select start date and time' }}
                /> */}
            </div>
          
            <div>
            <input type="datetime-local"
          onChange={handleStartChange}
        id="endDateTime"
        className="form-control"
        value={endDateShow}
        onChange={handleEndChange}
        name="endDateTime" required/>
{/*         
                <Datetime 
                    id="end"
                    value={endDateShow}
                    onChange={handleEndChange}
                    dateFormat={dateFormat}
                    timeFormat={timeFormat}
                    inputProps={{ placeholder: 'Select end date and time' }}
                /> */}
            </div>
            <button className="btn btn-primary btn-sm ms-2 h-10" 
            onClick={handleApply}
            >Apply</button>
            </div>
        </div>
        </div>

    

        </div>

        
<div className="container sm-screen mt-1 mx-1">

<h6 className='mt-2'>Percentage Total Outlet Orders Amount</h6>
{overview_is_loading?
 <div className="row">
    {[1, 2, 3, 4, 5, 6].map(() => (
  <div className="col-lg-3">
  <div className="card p-1 cursor my-1">
    <div>
      <Skeleton height={20} width={100} />
    </div>
    <div>
      <Skeleton height={20} width={150} />
    </div>
  </div>
  </div>
    ))}
</div>:
<div className="row">
  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">0</div>
      <div className="text-end fw-bold">{statistics_data?.percentage_total_outlet_orders_amount_0}%</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">1-500</div>
      <div className="text-end fw-bold">{statistics_data?.percentage_total_outlet_orders_amount_1_500}%</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">501-1000</div>
      <div className="text-end fw-bold">{statistics_data?.percentage_total_outlet_orders_amount_501_1000}%</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">1001-3000</div>
      <div className="text-end fw-bold">{statistics_data?.percentage_total_outlet_orders_amount_1001_3000}%</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">3001-5000</div>
      <div className="text-end fw-bold">{statistics_data?.percentage_total_outlet_orders_amount_3001_5000}%</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">5001-10000</div>
      <div className="text-end fw-bold">{statistics_data?.percentage_total_outlet_orders_amount_5001_10000}%</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">10001-20000</div>
      <div className="text-end fw-bold">{statistics_data?.percentage_total_outlet_orders_amount_10001_20000}%</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">20001-50000</div>
      <div className="text-end fw-bold">{statistics_data?.percentage_total_outlet_orders_amount_20001_50000}%</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">50001-75000</div>
      <div className="text-end fw-bold">{statistics_data?.percentage_total_outlet_orders_amount_50001_75000}%</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">75001-100000</div>
      <div className="text-end fw-bold">{statistics_data?.percentage_total_outlet_orders_amount_75001_100000}%</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">100001+</div>
      <div className="text-end fw-bold">{statistics_data?.percentage_total_outlet_orders_amount_100001_above}%</div>
    </div>
  </div>
</div>
}
<h6 className='mt-2'>Total Outlet Orders Amount</h6>
{overview_is_loading?
 <div className="row">
    {[1, 2, 3, 4, 5, 6].map(() => (
  <div className="col-lg-3">
  <div className="card p-1 cursor my-1">
    <div>
      <Skeleton height={20} width={100} />
    </div>
    <div>
      <Skeleton height={20} width={150} />
    </div>
  </div>
  </div>
    ))}
</div>:
<div className="row">
  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">0</div>
      <div className="text-end fw-bold">{statistics_data?.total_outlet_orders_amount_0}</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">1-100</div>
      <div className="text-end fw-bold">{statistics_data?.total_outlet_orders_amount_1_100}</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">101-200</div>
      <div className="text-end fw-bold">{statistics_data?.total_outlet_orders_amount_101_200}</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">201-300</div>
      <div className="text-end fw-bold">{statistics_data?.total_outlet_orders_amount_201_300}</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">301-500</div>
      <div className="text-end fw-bold">{statistics_data?.total_outlet_orders_amount_301_500}</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">501-1000</div>
      <div className="text-end fw-bold">{statistics_data?.total_outlet_orders_amount_501_1000}</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">1001-3000</div>
      <div className="text-end fw-bold">{statistics_data?.total_outlet_orders_amount_1001_3000}</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">3001-5000</div>
      <div className="text-end fw-bold">{statistics_data?.total_outlet_orders_amount_3001_5000}</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">5001-10000</div>
      <div className="text-end fw-bold">{statistics_data?.total_outlet_orders_amount_5001_10000}</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">10001-20000</div>
      <div className="text-end fw-bold">{statistics_data?.total_outlet_orders_amount_10001_20000}</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">20001-50000</div>
      <div className="text-end fw-bold">{statistics_data?.total_outlet_orders_amount_20001_50000}</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">50001-75000</div>
      <div className="text-end fw-bold">{statistics_data?.total_outlet_orders_amount_50001_75000}</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">75001-100000</div>
      <div className="text-end fw-bold">{statistics_data?.total_outlet_orders_amount_75001_100000}</div>
    </div>
  </div>

  <div className="col-lg-2 col-6 p-1">
    <div className="card p-2 cursor">
      <div className="small">100001+</div>
      <div className="text-end fw-bold">{statistics_data?.total_outlet_orders_amount_100001_above}</div>
    </div>
  </div>
</div>
}


<h6 className='my-2'>Outlet Total Orders</h6>

<input
        type="text"
        className="form-control mb-3"
        placeholder="Search for outlet name..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
        <div className="dropdown">
                    <button
                      className="btn btn-white dropdown-toggle d-flex align-items-cetner"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src={require("../../assets/icon/filter.png")}
                        className="icon-14px"
                      />
                      <p className="mb-0 text-dark d-md-block d-sm-none ps-2">Filter</p>
                    </button>
                    <ul className="dropdown-menu m-2" aria-labelledby="dropdownMenuButton1">
      
                    {dropdownOptions.map((tab, tab_index) => (
      <li  onClick={() => handleSort(tab.value)}  className="cursor">
  
                {tab.label}
         

      </li>
      ))}
                    
                    </ul>
                  </div>
<div className='row'>
{filteredOutlets.length>0 &&
filteredOutlets.map((ord_ele: any, ord_index: any) => (
                            <div 
                            // className="col-md-4 col-12 mb-3"
                             key={ord_index}>
        
        <div className="card p-12 mb-2 col-lg-4">
                                   
                                      <div className="d-flex border-bottom pb-2 mb-2">
                                        <div>
                                        <div className=" pb-3 d-flex">
                                 
                                  <div className="">
                                  <p className="text-dark fw-bold fs-7">{ord_ele?.brand_name}</p>

                                    <p >
                                      {ord_ele?.outlet_name}
                                    </p>
                                  </div>
                                </div>
                                <div>
                                       

</div>

</div>

<div className='ms-auto'>

<div>
<p className="extra-small text-gray">Total Orders Amount</p>
    <p className="pt-1 text-dark fw-bold">
    ₹ {ord_ele.total_orders_amount}
    </p>
</div>
</div>

</div>

</div>
</div>
)
  
  )
}
</div>
</div>
</div>
  )
}
export default OutletTotalOrderAmount