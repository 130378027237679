import React, { useState, useEffect, useContext } from "react";
import { useOutletContext, Link } from "react-router-dom";
import { api } from "../../utils/Api";
import DataContext from "../../utils/Context/DataContext";
import { Ionicons, MaterialIcons } from "@expo/vector-icons";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import SwipeableBottomSheet from "@geomatico/react-swipeable-bottom-sheet";
import ModalStop from "./ModalStop";
import { AppConfig } from "../../config";
import ModalUserDetails from "../Orders/ModalUserDetails";
import InfiniteScroll from "react-infinite-scroll-component";
import ProductList from "../Orders/ProductList";
import toast, { Toaster } from "react-hot-toast";
import OrderDetails1 from "../Orders/OutletOrderDetailsNew";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import AvailableDeliveryPartner from "../Orders/AvailableDeliveryPartner";
import { yellow } from "@material-ui/core/colors";

let map_api = AppConfig.api;
((g: any) => {
  var h: any,
    a: any,
    k: any,
    p = "The Google Maps JavaScript API",
    c: any = "google",
    l = "importLibrary",
    q = "__ib__",
    m: any = document,
    b: any = window;
  b = b[c] || (b[c] = {});
  var d = b.maps || (b.maps = {}),
    r: any = new Set(),
    e = new URLSearchParams(),
    u = () =>
      h ||
      (h = new Promise(async (f: any, n: any) => {
        await (a = m.createElement("script"));
        e.set("libraries", [...r] + "");
        for (k in g)
          e.set(
            k.replace(/[A-Z]/g, (t: any) => "_" + t[0].toLowerCase()),
            g[k]
          );
        e.set("callback", c + ".maps." + q);
        a.src = `https://maps.${c}apis.com/maps/api/js?` + e;
        d[q] = f;
        a.onerror = () => (h = n(Error(p + " could not load.")));
        a.nonce = m.querySelector("script[nonce]")?.nonce || "";
        m.head.append(a);
      }));
  d[l]
    ? console.warn(p + " only loads once. Ignoring:", g)
    : (d[l] = (f: any, ...n: any) => r.add(f) && u().then(() => d[l](f, ...n)));
})({ key: map_api, v: "weekly" });
var lat: any, long: any;

let map: any;
let seller_id_marker: any;

let timeOutId :any;

var bounds: any;
let marker: any = {};
async function initMap(): Promise<void> {
  const { Map }: any = await google.maps.importLibrary("maps");
  const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");
  map = new Map(document.getElementById("map-test") as HTMLElement, {
    zoom: 12,
    center: { lat: 11.341, lng: 77.7172 },
    // center: { lat: 26.7164127, lng: 88.4309916 },

    mapTypeControl: false,
    fullscreenControl: false,
    zoomControl: false,
    streetViewControl: false,
    mapTypeId: "roadmap",
    gestureHandling: "greedy",

    mapId: "DEMO_MAP_ID",
  });

    // Create custom content for the marker
    // const priceTag = document.createElement("div");
    // priceTag.className = "price-tag"; // Ensure you have the CSS defined
    // priceTag.textContent = "$2.5M"; // Customize text content here
  
    // // Create and place the AdvancedMarkerElement
    // seller_id_marker = new AdvancedMarkerElement({
    //   map,
    //   position: { lat: 11.341, lng: 77.7172},
    //   content: priceTag, // Pass the custom content here
    // });
  

  bounds = new google.maps.LatLngBounds();

  map.addListener("center_changed", () => {
    lat = map.getCenter()?.lat();
    long = map.getCenter()?.lng();
  });
  // get_map_details();
}

function openMap(dir_lat: any, dir_lng: any) {
  // window.open(
  //  "https://www.google.com/maps?q=" + dir_lat + "," + dir_lng,
  // "_blank"
  // );

  document.location.href =   "https://www.google.com/maps?q=" + dir_lat + "," + dir_lng,
  "_blank"
}

const responsive = {
  0: { items: 2 },
  200: { items: 3 },
  300: { items: 4 },
  350: { items: 5 },
  568: { items: 10 },
  1024: { items: 12 },
};

// menu_click
const initial_value = {};

export default function MapOrders(props: any) {
  const context: any = useContext(DataContext);
  const [page_no, SetPageNo] = useState(1);
  const [order_id, setOrderId] = useState("");
  const [isOn, setIsOn] = useState(false);
const [outlet_data,setOutletData] =useState({})
  const [next_page, SetNextPage] = useState(false);
  const [order_data, SetOrderData] = useState([]);
  const [order_status, SetOrderStatus] = useState(3);
  const [loader, setLoader] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  const [products_diet_type, SetProductsDietType]: any = useState({});
  const [available_delivery_partner_modal, SetAvailableDeliveryPartnerModal] =
    useState({
      is_open: false,
      data: {},
    });
  const [show_marker_data, SetShowMarkerData] = useState({
    is_open: false,
    view_type: null,
    company_data: {},
    delivery_person_data: {},
    delivery_person_full_data: {},
    page_no: page_no,
    id:"",
    next_page: false,
  });
  const { SetOutletContextData } = useOutletContext();
  const [all_data, SetAllData]: any = useState({});
  const [dp_fiter_data, SetDPFilterData]: any = useState([]);
  const [whole_dp_fiter_data, SetWholeDPFilterData]: any = useState([]);
  const [address, setAddress] = useState('');
  const [session_data, SetSessionData]: any = useState([]);
  const [session_data_details, SetSessionDataDetails]: any = useState({
    agent_id: "",
    company_id: "",
    all: "",
    page: page_no,
  });
  const [selected_tab, SetSelectedTab]: any = useState({
    id: 1,
    name: "Dashboard",
  });
  const [tab_data, SetTabData]: any = useState([
    { id: 1, name: "Dashboard" },
    { id: 2, name: "Live" },
    { id: 3, name: "Session" },
    { id: 4, name: "Profile" },
  ]);
  const [main_tab_data, SetMainTabData] = useState([
    { id: 1, name: "Orders" },
    { id: 2, name: "Delivery Person" },
  ]);
  const [selected_main_tab, SetSelectedMainTab]: any = useState({
    id: 1,
    name: "Orders",
  });
  const [bottom_sheet_open, SetBottomSheetOpen] = useState(false);
  const [visible_content, SetVisibleContent] = useState(false);

  const [width, SetWidth] = useState(window.innerWidth);
  const [live_tab_data, SetLiveTabdata] = useState({
    live_page_no: 1,
    live_next_page: false,
    is_live_api_loading: true,
    reservation_page_no: 1,
    reservation_next_page: false,
    is_reservation_api_loading: true,
  });
  const [live_order_data, SetLiveOrderData] = useState([]);
  const [live_reservation_data, SetLiveReservationData] = useState([]);

  const [modal_variants, SetModalVariants] = useState({
    is_open: false,
    data: {},
  });
  const [user_modal, SetUserModal] = useState({
    is_open: false,
   id:""
  });
  const [show_map_details_loader, SetMapDetailsLoader] = useState(true);
  const [session_loading, SetSessionLoading] = useState(true);
  let intervalId:any;
  let previousMarker: HTMLElement | null = null;
  const markerCollection: Record<string, AdvancedMarkerElement> = {};
let i=0;
  useEffect(() => {
    SetOutletContextData((prevValue) => {
      prevValue.have_menu = false;
      return { ...prevValue };
    });
    

    
    const initialize = async () => {
      await initMap();
      await get_map_details();
      let pass_data = {
        order_status: 12,
   
        sort: "",
        page_no: page_no,
      
      };
      await get_orders(pass_data);
      await get_outlets();

    };

 
  
    initialize();
    // let pass_data = {
    //   order_status: 3,
 
    //   sort: "",
    //   page_no: page_no,
    
    // };
    // get_orders(pass_data);

    return () => {

      context.SetAppData((prevValue: any) => {
        if (prevValue.scroll_locked > 0) {
          prevValue.scroll_locked--;
        }
        return { ...prevValue };
      });
      if(intervalId){
        clearInterval(intervalId);
      }
    };
  }, [isOn]);
 
  useEffect(() => {
    if (bottom_sheet_open) {
      context.SetAppData((prevValue: any) => {
        prevValue.scroll_locked++;
        return { ...prevValue };
      });
    } else {
      context.SetAppData((prevValue: any) => {
        if (prevValue.scroll_locked > 0) {
          prevValue.scroll_locked--;
        }
        return { ...prevValue };
      });
    }
  }, [bottom_sheet_open,show_marker_data.id,isOn]);

  const handleSwitchChange = (event) => {
    const isChecked = event.target.checked;
    console.log(isChecked)
    setIsOn(isChecked);
  }
  const handleChildData = (data) => {
    console.log(data)
    console.log(data.hasOwnProperty("ord_id"))

    if(data.hasOwnProperty("ord_id")){

                                                  SetAvailableDeliveryPartnerModal(
                                                    (prevalue) => {
                                                      prevalue.is_open = true;
                                                      prevalue.data = {
                                                        ord_id: data.ord_id,
                                                        comp_ord_id: data.comp_ord_id,
                                                      };
                                                      return { ...prevalue };
                                                    }
                                                  );
    }else{
    SetUserModal((prevValue: any) => ({
      ...prevValue,
      is_open: true,
      id: data
    }));
  }
// if(data=="brand"){
//       setSellerModal((prevValue: any) => ({
//                   ...prevValue,
//                   is_open: true,
//                 })
//               )
//             }else if(data=="user"){
//   setUserModal((prevValue: any) => ({
//                   ...prevValue,
//                   is_open: true,
//                 }))

//             }
    // setSelectedOption(data);
  };
  const handleCall = (e,fullMobile) => {
    e.stopPropagation()
    if (fullMobile) {
      window.location.href = `tel:${fullMobile}`;
    }
  };

  const handleScroll = () => {
    setIsScrolling(true);
  };
  
  const handleTouchEnd = () => {
    setIsScrolling(false);
  };

  const handleCustomer = (e,order) => {
    console.log(order.customer.user_id)
    e.stopPropagation(); // Stops event bubbling to parent
    SetUserModal((prevValue: any) => ({
      ...prevValue,
      is_open: true,
      id: order.customer.user_id,
    }));
  };
  async function call_del_func(id: any, del_id: any, d_id: any) {
    let pass_data = {
      get: {
        order_id: id,
        callto: del_id,
        agent_order_id: d_id,
      },
    };
    let data_res: any = await api("/orders/call", pass_data);
    if ((data_res.status_code = 200)) {
      if (data_res?.response?.phone_number) {
        // window.open("tel:" + data_res?.response?.phone_number);
        document.location.href = "tel:" + data_res?.response?.phone_number;
      }
    }
  }

  const handleEmail = (e,email) => {
    e.stopPropagation()
    if (email) {
      window.location.href = `mailto:${email}`;
    }
  };

  const OrderSkeleton = () => {
    return (
      <div className="card p-3 cursor">
        <div className="row align-items-center">
          <div className="col-6 pb-3">
            <Skeleton height={10} width={50} />
          </div>
          <div className="col-6 text-end">
            <Skeleton height={30} width={100} />
          </div>
        </div>

        <div className="row pt-2">
          <div className="col-6 pb-2">
            <Skeleton height={10} width={50} />
            <Skeleton height={10} width={120} />
          </div>
          <div className="col-6 pb-2 text-end">
            <Skeleton height={10} width={120} />
            <Skeleton height={10} width={100} />
          </div>
        </div>

        <div className="pb-3 pt-2">
          <hr className="text-gray m-0" />
        </div>

        <div className=" pb-2">
          <Skeleton height={10} width={120} />
        </div>

        <div className="py-2">
          <div className="d-flex pb-3">
            <Skeleton height={18} width={18} />
            <div className="ps-2">
              <Skeleton height={10} width={120} />
              <Skeleton height={10} width={180} />
            </div>
          </div>
          <div className="d-flex pb-3">
            <Skeleton height={18} width={18} />
            <div className="ps-2">
              <Skeleton height={10} width={120} />
              <Skeleton height={10} width={180} />
            </div>
          </div>
        </div>
      </div>
    );
  };
  const OwnAccordian = (acc_props: any) => {
    const [is_open, SetIsopen] = useState(false);
    return (
      <>
        {acc_props.from == "shipment" ? (
          <>
            <div
              className="d-flex pt-3"
              onClick={() => {
                SetIsopen(!is_open);
              }}
            >
              <h6 className="text-dark ">{acc_props.heading}</h6>

              {is_open ? (
                <MaterialIcons
                  name="keyboard-arrow-down"
                  size={24}
                  color="black"
                />
              ) : (
                <MaterialIcons
                  name="keyboard-arrow-up"
                  size={24}
                  color="black"
                />
              )}
            </div>
            <div className="">{is_open ? acc_props.children : null}</div>
          </>
        ) : (
          <>
            <div
              className="d-flex pt-3"
              onClick={() => {
                SetIsopen(!is_open);
              }}
            >
              <h6 className="text-dark ">{acc_props.heading}</h6>
              <div className="ms-auto">
                {is_open ? (
                  <MaterialIcons
                    name="keyboard-arrow-down"
                    size={24}
                    color="black"
                  />
                ) : (
                  <MaterialIcons
                    name="keyboard-arrow-up"
                    size={24}
                    color="black"
                  />
                )}
              </div>
            </div>
            <div className="">{is_open ? acc_props.children : null}</div>
          </>
        )}{" "}
      </>
    );
  };

  async function assign_delivery_partner(get_data: any) {
    let pass_data = {
      post: {
        order_id: available_delivery_partner_modal.data.ord_id,
        company_order_id: available_delivery_partner_modal.data.comp_ord_id,
        delivery_partner_id: get_data.delivery_partner_id,
      },
    };
    let response: any = await api("/orders/assign_delivery_partner", pass_data);
    if (response.status_code == 200) {
      // get_order_detail(id_value.ord_id,false);
    }
  }

  async function get_map_details() {
    // SetMapDetailsLoader(true);
    let response: any = await api("/orders/get_delivery_company_partners");
    if (response.status_code == 200) {
      SetMapDetailsLoader(false);
      if (response.response.hasOwnProperty("data")) {

        SetAllData(response.response.data[0]);
        SetDPFilterData(response.response.data[0].delivery_partners);
        const convertedCoordinates = response.response.data[0].zones[0].coordinates.map(coord => ({
          lat: parseFloat(coord.lat),
          lng: parseFloat(coord.lng)
        }));
        const convertedDelivery = response.response.data[0].zones[0].delivery_coordinates.map(coord => ({
          lat: parseFloat(coord.lat),
          lng: parseFloat(coord.lng)
        }));
        
        const coordinatePoly = new google.maps.Polygon({
          paths: convertedCoordinates,
          strokeColor: "#FFFF00",
          // strokeOpacity: 0.8,
          // strokeWeight: 2,
          // fillColor: "#FF0000",
          fillOpacity: 0,
        });
        const deliveryPoly = new google.maps.Polygon({
          paths: convertedDelivery,
          strokeColor: "#FF0000",
          // strokeOpacity: 0.8,
          // strokeWeight: 2,
          // fillColor: "#FF0000",
          fillOpacity: 0,
        });
      
        coordinatePoly.setMap(map);
        deliveryPoly.setMap(map);

        // const del_locations = [
        //   { lat: 19.076, lng: 72.8777 },
        //   { lat: 19.0761, lng: 72.8778 },
        //   { lat: 19.0762, lng: 72.8779 }
        // ];
        
        // let currentIndex = 0; // Start with the first element in the array
       
        
     
        // function moveToNextLocation() {
        //   const dp_marker = markerCollection["1"];
        //   if (currentIndex >= del_locations.length) {
        //     console.error("Error: currentIndex is out of bounds");
        //     return;
        //   }
        
        //   const currentLocation = del_locations[currentIndex];
        //   if (!currentLocation) {
        //     console.error("Error: Location is undefined");
        //     return;
        //   }
        //   console.log("marker locaton",markerCollection["1"])
        
        //   console.log("Moving to Location:", currentLocation);
        //   dp_marker.position = currentLocation
        //   // Update the index to point to the next location
        //   currentIndex++;
        // }
        
   
        intervalId = setInterval(function () {
          get_current_location();
          // moveToNextLocation();
          
        }, 10000);

  SetWholeDPFilterData(response.response.data[0].delivery_partners);
        SetShowMarkerData((prevalue: any) => {
          prevalue.view_type = "main";
          return { ...prevalue };
        });
        response.response.data.map((ord_ele: any, ord_index: any) => {
          ord_ele.delivery_partners.map(async (ele: any, ord_index: any) => {
            marker[ele.agent_id] = {
              marker: {},
              pass_data: {},
            };
            if (Object.keys(ele.location).length > 0 && ele.logged_in == true) {
              let ele_data: any = {};
              let img_url = "";
              if (ele.marker == "blue") {
                img_url =
                  "https://cdn3.1superapp.com/superapp/delivery-boy-blue.png";
              }
              if (ele.marker == "green") {
                img_url =
                  "https://cdn3.1superapp.com/superapp/delivery-boy-green.png";
              }
              if (ele.marker == "orange") {
                img_url =
                  "https://cdn3.1superapp.com/superapp/delivery-boy-orange.png";
              }
              if (ele.marker == "red") {
                img_url =
                  "https://cdn3.1superapp.com/superapp/delivery-boy-red.png";
              }
              if (ele.marker == "yellow") {
                img_url =
                  "https://cdn3.1superapp.com/superapp/delivery-boy-yellow.png";
              }
              if (ele.marker == "") {
                img_url =
                  "https://cdn3.1superapp.com/superapp/delivery-boy-orange.png";
              }
              let added_url = img_url + "?agent_id=" + ele.agent_id;
              marker[ele.agent_id]["marker"] = new google.maps.Marker({
                position: new google.maps.LatLng(
                  ele.location.lat,
                  ele.location.lng
                ),
                map,
                visible: false,
                // icon: {
                //   url: added_url,
                //   scaledSize: new google.maps.Size(23, 35),
                // },
                anchorPoint: new google.maps.Point(0, -29),
              });
              let pass_data = {
                company_data: ord_ele,
                delivery_person_data: ele,
                added_url: added_url,
                active_orders_list: ele.active_orders_list,
              };
              marker[ele.agent_id].pass_data = pass_data;
              console.log(ele)
              const orderItemIds = ele.active_orders_list.map((order: any) => order.store_order_item_id).join(', ');
              
              const del_details = ele.name +" "+ orderItemIds
              addMarker(Number(ele.location.lat), Number(ele.location.lng),  String(orderItemIds),ele.name,ele.marker,String("del_partner"),pass_data,ele,ele.agent_id);
              
              // const customerInfoWindow = new google.maps.InfoWindow({
              //   content: `
                
              //       ${orderItemIds || "N/A"}<br />
                
              
              //   `,
              // });
              
              
              // marker[ele.agent_id]["marker"].addListener("click", () => {
              //   customerInfoWindow.open(map, marker[ele.agent_id]["marker"]);
              // });
              // customerInfoWindow.open(map,  marker[ele.agent_id]["marker"]);
              // AttachMarkerData(marker, pass_data);
            }
          });
        });
      }
    }
  }

  function AttachMarkerData(marker: any, get_data: any) {
    let doc_var: any = document.querySelector(
      'img[src="' + get_data.added_url + '"]'
    );
    if (doc_var != null) {
      doc_var.style.transform =
        "rotate(" + get_data.delivery_person_data.location.heading + "deg)";
      doc_var.parentElement.style.overflow = "inherit";
    }

    marker[get_data.delivery_person_data.agent_id].marker.addListener(
      "click",
      (ele: any) => {
        Object.keys(marker).map((key) => {
          if (key == get_data.delivery_person_data.agent_id) {
            let doc_var: any = document.querySelector(
              'img[src="' + get_data.added_url + '"]'
            );
            if (doc_var != null) {
              doc_var.style.transform =
                "rotate(" +
                get_data.delivery_person_data.location.heading +
                "deg) scale(2)";
              doc_var.parentElement.style.overflow = "inherit";
              // doc_var.style.transform = "scale(2)";
              doc_var.style.transition = "scale 300ms";
            }
          } else {
            let doc_var: any = document.querySelector(
              'img[src="' + marker[key].pass_data.added_url + '"]'
            );
            if (doc_var != null) {
              doc_var.style.transform =
                "rotate(" +
                marker[key].pass_data.delivery_person_data.location.heading +
                "deg) scale(1)";
              doc_var.parentElement.style.overflow = "inherit";
            }
          }
        });
        SetShowMarkerData((preValue: any) => {
          preValue.is_open = true;
          preValue.view_type = "personal_details";
          preValue.loading = true;
          //  preValue.company_data = get_data.company_data;
          //  preValue.delivery_person_data = get_data.delivery_person_data;
          //  preValue.delivery_person_full_data = response.response.data[0];
          let send_data = {
            company_data: get_data.company_data,
            delivery_person_data: get_data.delivery_person_data,
          };
          get_person_details(send_data);

          let send_data1 = {
            company_data: get_data.company_data,
            delivery_person_data: get_data.delivery_person_data,
            page_no: page_no,
            fetch_more: false,
          };
          SetSessionDataDetails({
            agent_id: get_data.delivery_person_data,
            company_id: get_data.company_data,
            all: get_data.delivery_person_data,
            page: page_no,
          });
          get_person_sessions(send_data1);
          return { ...preValue };
        });
        SetSelectedTab({
          id: 1,
          name: "Dashboard",
        });
        if (width <= 768) {
          SetBottomSheetOpen(true);
        }
      }
    );
  }

  async function get_person_details(get_data: any) {
    let pass_data = {
      get: {
        agent_id: get_data.delivery_person_data.agent_id,
      },
    };
    let response: any = await api(
      "/orders/get_delivery_partners_details",
      pass_data,
      false,
      false,
      "form_data",
      false
    );
    if (response.status_code == 200) {
      if (
        response.response.hasOwnProperty("data") &&
        response.response.data.length != 0
      ) {

        // getAddress(get_data.delivery_person_data.location);
        SetShowMarkerData((preValue: any) => {
          preValue.is_open = true;
          preValue.view_type = "personal_details";
          preValue.loading = false;
          preValue.company_data = get_data.company_data;
          preValue.delivery_person_data = get_data.delivery_person_data;
          preValue.delivery_person_full_data = response.response.data[0];
          return { ...preValue };
        });

        console.log(get_data.delivery_person_data.location)
      }
    } else {
      // SetBottomSheetOpen(false);
      toast.success("no");
      // success("no data")
    }
  }


  async function get_person_sessions(get_data: any) {
    console.log(get_data)
    if (!get_data.fetch_more) {
      SetSessionLoading(true);
    }
    SetSessionDataDetails({
      agent_id: get_data.delivery_person_data,
      company_id: get_data.delivery_person_data,
      all: get_data.delivery_person_data,
      page: page_no,
    });
    let pass_data = {
      get: {
        agent_id: get_data.delivery_person_data.agent_id,
        company_id: get_data.delivery_person_data.id,
        all: "1",
        page: 1,
      },
    };
    let response: any = await api(
      "/orders/get_dp_working_sessions",
      pass_data,
      false,
      false,
      "form_data",
      false
    );
    if (response.status_code == 200) {
      SetSessionLoading(false);
      if (
        response.response.hasOwnProperty("data") &&
        response.response.data.length > 0
      ) {
        SetSessionData((prevalue: any) => {
          if (get_data.fetch_more) {
            response.response.data.map((ele: any) => {
              prevalue.push(ele);
            });
          } else {
            prevalue = response.response.data;
          }
          return [...prevalue];
        });
        SetNextPage(response.response.next_page);
      } else {
        SetSessionData([]);
        SetNextPage(false);
      }
      SetShowMarkerData((preValue: any) => {
        let old_value = preValue.page_no;
        if (response.response.next_page) {
          old_value = old_value + 1;
        }
        preValue.page_no = old_value;
        preValue.next_page = response.response.next_page;
        return { ...preValue };
      });
    }
  }

  async function load_more() {
    let page_no_value = page_no + 1;
    let pass_data = {};
console.log(session_data_details)
    SetPageNo(page_no_value);

    pass_data = {
      get: {
        agent_id: session_data_details.agent_id.agent_id,
        company_id: session_data_details.company_id.id,
        all: "1",
        page: page_no_value,
      },
    };

    let response: any = await api(
      "/orders/get_dp_working_sessions",
      pass_data,
      false,
      false,
      "form_data",
      false
    );
    SetSessionData((prevValue) => {
      response.response.data.map((item) => {
        prevValue.push(item);
      });
      return [...prevValue];
    });
    SetNextPage(response.response.next_page);
  }

  async function get_current_location() {
    let array_id: any = [];

    SetAllData((prevalue: any) => {
      prevalue?.delivery_partners?.map((ele: any) => {
        if (ele.logged_in) {
          array_id.push(ele.agent_id);
        }
      });
      return { ...prevalue };
    });
    const arrString = JSON.stringify(array_id);
    let pass_data = {
      post: {
        agent_ids: arrString,
      },
    };
    let response: any = await api(
      "/orders/get_delivery_partners_location",
      pass_data,
      false,
      false,
      "form_data",
      false
    );
    if (response.status_code == 200) {
      if (response.response.hasOwnProperty("data")) {
        response.response.data.map((ele: any, ord_index: any) => {
        
          console.log(ele.location)

          
          marker[ele.agent_id].pass_data.delivery_person_data.location =
            ele.location;
//             const marker_dp = document.getElementById(`delivery-partner-${ele.agent_id}`);
// console.log(marker_dp)
//             marker_dp.style.outline =  "3px solid #FFFFFF";
//             marker_dp.style.zIndex = "1000"; 
// markerCollection[ele.agent_id].position =ele.location;


const { lat, lng } = ele.location;
const result = { lat: Number(lat), lng: Number(lng) };

const ex_res = { lat: 19.0760, lng: 72.8777 }


const dp_marker =markerCollection[ele.agent_id];
console.log(dp_marker);

  // const del_locations = [
  //         { lat: 19.076, lng: 72.8777 },
  //         { lat: 19.0761, lng: 72.8778 },
  //         { lat: 19.0762, lng: 72.8779 }
  //       ];
        

if (dp_marker) {
  dp_marker.position = result;
  // dp_marker.setPosition(new google.maps.LatLng(lat, lng));
  console.log(dp_marker)
  console.log(dp_marker.position)
// i++;
}

// if (markerCollection[ele.agent_id]) {
//   markerCollection[ele.agent_id].setPosition(new google.maps.LatLng(lat, lng));
// }
// dp_marker.position = del_locations[0]

// console.log(result)

// console.log(ele.location)
// console.log(markerCollection[ele.agent_id].position)

          SetShowMarkerData((preValue: any) => {
            if (preValue.view_type == "personal_details") {
              Object.keys(marker).map((key) => {
                if (key == ele.agent_id) {
                  let myLatlng = new google.maps.LatLng(
                    ele.location.lat,
                    ele.location.lng
                  );
                  marker[key].marker.setPosition(myLatlng);
                  let doc_var: any = document.querySelector(
                    'img[src="' + marker[key].pass_data.added_url + '"]'
                  );
                  if (doc_var != null) {
                    if (preValue.delivery_person_data.agent_id == key) {
                      // doc_var.style.transform = "scale(2)";
                      doc_var.style.transform =
                        "rotate(" + ele.location.heading + "deg scale(2))";
                      doc_var.style.transition = "scale 300ms";
                    } else {
                      doc_var.style.transform =
                        "rotate(" + ele.location.heading + "deg)";
                      doc_var.parentElement.style.overflow = "inherit";
                    }
                  }
                }
              });
            } else {
              Object.keys(marker).map((key) => {
                if (key == ele.agent_id) {
                  let myLatlng = new google.maps.LatLng(
                    ele.location.lat,
                    ele.location.lng
                  );
                  marker[key].marker.setPosition(myLatlng);
                  let doc_var: any = document.querySelector(
                    'img[src="' + marker[key].pass_data.added_url + '"]'
                  );
                  if (doc_var != null) {
                    doc_var.style.transform =
                      "rotate(" + ele.location.heading + "deg)";
                    doc_var.parentElement.style.overflow = "inherit";
                  }
                }
              });
            }
            return { ...preValue };
          });
        });
      }
    }
  }

  function get_session_details(ele: any) {
    return (
      <div className="sm-px-1">
        <div className="card p-3 mb-3 sm-px-1 sm-py-2">
          {ele.working ? (
            <div>
              <div className="d-flex align-items-center pb-3">
                <div className="color-dot-red"></div>
                <p className="ps-2 text-red">Live</p>
              </div>
              <div className="d-flex align-items-center pb-1">
                <p className="text-black">
                  {ele.start_time_data.date}/{ele.start_time_data.month_short}/
                  {ele.start_time_data.year}
                </p>
                <div className="ms-auto">
                  <p className="text-black">{ele.start_time_data.time}</p>
                </div>
              </div>
              <p className="text-black">{ele.start_time_data.day_full}</p>
              <div className="mt-3">
                <p className="extra-small pb-1">Total Session</p>
                <p className="text-black">{ele.duration}</p>
              </div>
            </div>
          ) : (
            <div className="p-1">
              <div className="row">
                <div className="col-6 border-right">
                  <span className="extra-small pb-1 text-gray">Start</span>
                  <div className="d-flex align-items-center py-1">
                    <p className=" text-black">
                      {ele.start_time_data.date}/
                      {ele.start_time_data.month_short}/
                      {ele.start_time_data.year}
                    </p>
                    <div className="ms-auto">
                      <p className="ps-2 text-black">
                        {ele.start_time_data.time}
                      </p>
                    </div>
                  </div>
                  <p className="pb-2 text-black">
                    {ele.start_time_data.day_full}
                  </p>
                </div>
                <div className="col-6">
                  <span className="extra-small pb-1 text-gray">End</span>
                  <div className="d-flex align-items-center py-1">
                    <p className=" text-black">
                      {ele.end_time_data.date}/{ele.end_time_data.month_short}/
                      {ele.end_time_data.year}
                    </p>
                    <div className="ms-auto">
                      <p className="ps-2 text-black">
                        {ele.end_time_data.time}
                      </p>
                    </div>
                  </div>
                  <p className="pb-2 text-black">
                    {ele.end_time_data.day_full}
                  </p>
                </div>
              </div>
              <div className="mt-2">
                <p className=" extra-small pb-1">Total Session</p>
                <p className=" text-black">{ele.duration}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  async function get_live_orders(get_data: any) {
    SetLiveTabdata((prevalue) => {
      prevalue.is_live_api_loading = true;
      return prevalue;
    });
    let pass_data = {
      get: {
        agent_id: get_data.delivery_person_data.agent_id,
        page: get_data.page_no,
        view: "active",
      },
    };
    let response: any = await api(
      "/orders/get_dp_orders",
      pass_data,
      false,
      false,
      "form_data",
      false
    );
    if (response.status_code == 200) {
      if (get_data.fetch_more) {
        SetLiveOrderData((prevalue) => {
          response.response.data.map((ele) => {
            prevalue.push(ele);
          });
          return [...prevalue];
        });
      } else {
        SetLiveOrderData(response.response.data);
      }
      SetLiveTabdata((prevalue) => {
        prevalue.is_live_api_loading = false;
        if (response.response.next_page) {
          prevalue.live_page_no = prevalue.live_page_no + 1;
        }
        prevalue.live_next_page = response.response.next_page;
        return prevalue;
      });
    }
  }

  async function get_live_orders_reservation(get_data: any) {
    SetLiveTabdata((prevalue) => {
      prevalue.is_reservation_api_loading = true;
      return prevalue;
    });
    let pass_data = {
      get: {
        agent_id: get_data.delivery_person_data.agent_id,
        page: get_data.page_no,
        view: "active",
      },
    };
    let response: any = await api(
      "/orders/get_dp_order_reservations",
      pass_data,
      false,
      false,
      "form_data",
      false
    );
    if (response.status_code == 200) {
      if (get_data.fetch_more) {
        SetLiveReservationData((prevalue) => {
          response.response.data.map((ele: any) => {
            prevalue.push(ele);
          });
          return [...prevalue];
        });
      } else {
        SetLiveReservationData(response.response.data);
      }
      SetLiveTabdata((prevalue) => {
        prevalue.is_reservation_api_loading = false;
        if (response.response.next_page) {
          prevalue.reservation_page_no = prevalue.reservation_page_no + 1;
        }
        prevalue.reservation_next_page = response.response.next_page;
        return prevalue;
      });
    }
  }

  const TimelineAccordian = (acc_props: any) => {
    const [is_open, SetIsopen] = useState(false);
    return (
      <>
        <div
          className="d-flex align-items-center pt-2 cursor"
          onClick={() => {
            // SetTimelineAccrData((prevalue)=>{
            //   prevalue.status_timeline = !prevalue.status_timeline
            //   return {...prevalue}
            // })
            SetIsopen(!is_open);
          }}
        >
          <p className="text-dark fw-bold">TIMELINE</p>
          <div className="ms-auto ">
            {is_open ? (
              <MaterialIcons
                name="keyboard-arrow-down"
                size={18}
                color="black"
              />
            ) : (
              <MaterialIcons name="keyboard-arrow-up" size={18} color="black" />
            )}
          </div>
        </div>
        <div className="">{is_open ? acc_props.children : null}</div>
      </>
      
    );
  };
  const OrderComponent = () => {
    return (
      <>
          {visible_content ? (
              order_data.length != 0 ? (
                <div className="row mb-4">
                  {order_data?.map((ord_ele: any, ord_index: any) => (
                    <div className=" col-12 mb-3" key={ord_index}>
                      {/* target="_blank" */}
                      {/* <div className="card p-3 cursor">
                          <div className="row align-items-center">
                            <div className="col-6 pb-3">
                              <p className="text-dark fw-bold">
                                {ord_ele.sector}
                              </p>
                            </div>
                            <div className="col-6 end pb-3">
                              <button
                                className="btn btn-prime btn-sm"
                             
                              >
                                View Details
                              </button>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-6 pb-2">
                              <p className="text-dark fw-500">#{ord_ele.id}</p>
                            </div>
                            <div className="col-6 end pb-2">
                              <p className="extra-small">{ord_ele.date}</p>
                            </div>
                            <div className="col-6 pb-2 d-flex align-items-center">
                              <img
                                src={require("../../assets/icon/scooter.png")}
                                className="icon-14px"
                              />
                              <p className="ps-2 text-gray">
                                {ord_ele.delivery_type}
                              </p>
                            </div>
                            <div className="col-6 end pb-2">
                              <FontAwesome
                                name="circle"
                                size={10}
                                color={
                                  // ord_ele.status == "Accepted"
                                  //   ? "blue"
                                  //   : ord_ele.status == "Completed"
                                  //   ? "green"
                                  //   : ord_ele.status == "Order Placed"
                                  //   ? "orange"
                                  //   : "red"
                                  ord_ele.status_color
                                }
                              />
                              <p
                                // className={
                                //   ord_ele.status == "Accepted"
                                //     ? "text-blue ps-2"
                                //     : ord_ele.status == "Completed"
                                //     ? "text-green ps-2"
                                //     : ord_ele.status == "Order Placed"
                                //     ? "text-orange ps-2"
                                //     : "text-red ps-2"
                                // }
                                className="ps-2"
                                style={{color:ord_ele.status_color}}
                              >
                                {ord_ele.status}
                              </p>
                            </div>
                          </div>

                          <div className="pb-3 pt-2">
                            <hr className="text-gray m-0" />
                          </div>

                          <div className=" pb-2">
                            <p className=" text-dark fs-7 fw-bold">
                              {ord_ele.customer?.name}
                            </p>
                          </div>

                          <div className="py-2">
                            {ord_ele.sector_id == "4" ? (
                              <div className="">
                                <div className="d-flex pb-3">
                                  <img
                                    src={require("../../assets/icon/up-arrow.png")}
                                    className="icon-18px"
                                  />
                                  <div className="ps-2">
                                    <p className="text-dark pb-1">Pickup</p>
                                    <p className="text-dark fw-bold">
                                      {
                                        ord_ele?.customer?.pickup_address
                                          ?.firstname
                                      }{" "}
                                      {
                                        ord_ele?.customer?.pickup_address
                                          ?.lastname
                                      }
                                    </p>
                                    {ord_ele?.customer?.pickup_address
                                      ?.address ? (
                                      <p className="pt-1">
                                        {
                                          ord_ele?.customer?.pickup_address
                                            ?.address
                                        }
                                      </p>
                                    ) : (
                                      <p className="pt-1">
                                        {
                                          ord_ele?.customer?.pickup_address
                                            ?.map_address
                                        }
                                      </p>
                                    )}
                                  </div>
                                </div>

                                <div className="d-flex">
                                  <img
                                    src={require("../../assets/icon/down.png")}
                                    className="icon-18px"
                                  />
                                  <div className="ps-2">
                                    <p className="text-dark pb-1">Delivery</p>
                                    <p className="text-dark fw-bold">
                                      {
                                        ord_ele?.customer?.delivery_address
                                          ?.firstname
                                      }{" "}
                                      {
                                        ord_ele?.customer?.delivery_address
                                          ?.lastname
                                      }
                                    </p>
                                    {ord_ele?.customer?.delivery_address
                                      ?.address ? (
                                      <p className="pt-1">
                                        {
                                          ord_ele?.customer?.delivery_address
                                            ?.address
                                        }
                                      </p>
                                    ) : (
                                      <p className="pt-1">
                                        {
                                          ord_ele?.customer?.delivery_address
                                            ?.map_address
                                        }
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div>
                                <div className="d-flex">
                                  <img
                                    src={require("../../assets/icon/seller.png")}
                                    className="icon-18px"
                                  />
                                  <div className="ps-2">
                                    <p className="text-dark pb-1">
                                      {ord_ele.seller?.brand_name}
                                    </p>
                                    <p className="text-gray">
                                      {ord_ele.seller?.outlet?.name}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          {!ord_ele.active ? (
                            <>
                              <button
                                className="btn btn-orange ms-auto btn-sm px-3"
                                onClick={() => {
                                  activate_order(ord_ele);
                                }}
                              >
                                Accept
                              </button>
                            </>
                          ) : null}
                        </div> */}
                      <div className="card p-12"
                      
                      onClick={() => {
                        SetShowMarkerData((prevValue) => {
                          return {
                            ...prevValue,
                            is_open: true,
                            view_type: "order_details",
                            loading: true,
                            id:ord_ele.id
                          };
                        });
                        // setOrderId(ord_ele.id)
                      }}
                      >
                        {/* <Link to={"/orders/" + ord_ele.id}> */}
                          <div className="d-flex mb-1">
                            <div>
                              <div className="mb-1 fw-500">
                                {ord_ele?.customer?.name} 
                              </div> 
                            </div>
                            <div className="ms-auto text-end">
                              <div className="mb-1">
                                {/* {format(ord_ele?.timeago, "en_US")} */}
                                {ord_ele?.date}
                              </div>
                            </div>
                          </div>
                          <div className="d-flex border-bottom pb-2 mb-2">
                            <div className="mb2">
                              <span
                                className={
                                  ord_ele?.status == "Order Placed" ||
                                  ord_ele?.status == "Seller Accepted"
                                    ? "badge-1 me-2 badge-blue"
                                    : ord_ele?.status == "Seller Rejected"
                                    ? "badge-1 me-2 badge-red"
                                    : ord_ele?.status == "Auto Cancelled"
                                    ? "badge-1 me-2 badge-red-2"
                                    : ord_ele?.status == "Completed"
                                    ? "badge-1 me-2 badge-green"
                                    : ord_ele?.status == "Delivery Partner Accepted"
                                    ? "badge-1 me-2 badge-blue"
                                    : "badge-1 me-2 badge-red-2"

                                    
                                }
                              >
                                {ord_ele?.status}
                              </span>

                              <span className="fs-12">
                                {ord_ele?.delivery_type == "Hyper Local" && (
                                  <img
                                    src={
                                      AppConfig.CDN_Media_url +
                                      "31b719f813a5801b0b9e68653ff6df89.svg"
                                    }
                                    className="img-fluid me-1"
                                    width="14"
                                  />
                                )}
                                {ord_ele?.delivery_type}
                              </span>
                            </div>
                            {ord_ele.dm_order && (
                              <span className="badge-1 me-2 badge-blue ms-2">
                                Demo
                              </span>
                            )}
                            <div className="ms-auto text-end">
                              <div>
                                ID:{" "}
                                <span className="fw-500">{ord_ele?.id}</span>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex border-bottom pb-2 mb-2">
                            <div>
                              <div className="mb-1 fw-500">
                                {ord_ele?.seller?.brand_name}
                              </div>
                              <div className="mb-1 fw-500">
                                {ord_ele?.seller?.outlet?.name}
                              </div>
                            </div>

                            <div className="ms-auto">{ord_ele?.sector}</div>
                          </div>
                          {ord_ele.sector_id == "4" ? (
                            <div className="">
                              <div className="d-flex pb-3">
                                <img
                                  src={require("../../assets/icon/up-arrow.png")}
                                  className="icon-18px"
                                />
                                <div className="ps-2">
                                  <p className="text-dark pb-1">Pickup</p>
                                  <p className="text-dark fw-bold">
                                    {
                                      ord_ele?.customer?.pickup_address
                                        ?.firstname
                                    }{" "}
                                    {
                                      ord_ele?.customer?.pickup_address
                                        ?.lastname
                                    }
                                  </p>
                                  {ord_ele?.customer?.pickup_address
                                    ?.address ? (
                                    <p className="pt-1">
                                      {
                                        ord_ele?.customer?.pickup_address
                                          ?.address
                                      }
                                    </p>
                                  ) : (
                                    <p className="pt-1">
                                      {
                                        ord_ele?.customer?.pickup_address
                                          ?.map_address
                                      }
                                    </p>
                                  )}
                                </div>
                              </div>

                              <div className="d-flex">
                                <img
                                  src={require("../../assets/icon/down.png")}
                                  className="icon-18px"
                                />
                                <div className="ps-2">
                                  <p className="text-dark pb-1">Delivery</p>
                                  <p className="text-dark fw-bold">
                                    {
                                      ord_ele?.customer?.delivery_address
                                        ?.firstname
                                    }{" "}
                                    {
                                      ord_ele?.customer?.delivery_address
                                        ?.lastname
                                    }
                                  </p>
                                  {ord_ele?.customer?.delivery_address
                                    ?.address ? (
                                    <p className="pt-1">
                                      {
                                        ord_ele?.customer?.delivery_address
                                          ?.address
                                      }
                                    </p>
                                  ) : (
                                    <p className="pt-1">
                                      {
                                        ord_ele?.customer?.delivery_address
                                          ?.map_address
                                      }
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <ProductList
                              products={ord_ele?.ordered_products}
                              diet_type={products_diet_type}
                            />
                          )}
                          <div className="border-top">
                            <div className="d-flex align-items-center w-100 pt-2 mb-2">
                              <div>
                                <span className="me-2 fw-500 text-muted">
                                  Total Bill
                                </span>
                                {ord_ele?.cod_order == true &&
                                ord_ele?.cod_payment_status == true ? (
                                  <span className="badge-2">COD Paid</span>
                                ) : ord_ele?.cod_order == true ? (
                                  <span className="badge-2">COD</span>
                                ) : ord_ele?.payment_type_id == "1" &&
                                  ord_ele?.order_completed == false ? (
                                  <span className="badge-2">Online</span>
                                ) : (
                                  ord_ele?.payment_type_id == "1" &&
                                  ord_ele?.order_completed == true && (
                                    <span className="badge-2">Paid</span>
                                  )
                                )}
                              </div>
                              <div className="ms-auto">
                                <span className="fw-300 fs-16">&#8377;</span>{" "}
                                <span className="fw-500">
                                  {ord_ele?.total_amount_payable}
                                </span>
                              </div>
                            </div>
                          </div>
                        {/* </Link> */}
                        <div>
                          {ord_ele?.shipments.length > 0
                            ? ord_ele?.shipments[0]?.super_delivery_order[0]
                                ?.delivery_partner[0]?.delivery_partner?.name
                              ? ord_ele?.shipments[0]?.super_delivery_order[0]?.delivery_partner.map(
                                  (dele_ele, i) =>
                                    dele_ele?.delivery_partner?.name &&
                                    dele_ele?.currently_working && (
                                      <div className="border-top pt-2">
                                        <div className="d-flex ">
                                          {dele_ele.delivery_partner.profile ? (
                                            <img
                                              src={
                                                dele_ele.delivery_partner
                                                  .profile
                                              }
                                              alt="16.a1d4f3f3"
                                              className="rounded-float-start"
                                              height="34"
                                              width="34"
                                              style={{
                                                borderRadius: "50px",
                                              }}
                                            />
                                          ) : (
                                            <i className="fa fa-user m-2"></i>
                                          )}
                                          <div className="ms-2">
                                            <p className="extra-small text-gray pb-1">
                                              {
                                                dele_ele.delivery_partner
                                                  .brand_name
                                              }
                                            </p>

                                            <p>
                                              <span className="fw-bold text-black ">
                                                {dele_ele.delivery_partner.name}
                                              </span>
                                              {/* "15px 50px 30px 5px" */}
                                              <span className="px-1"></span>
                                              <span
                                                className={
                                                  ord_ele.status ==
                                                    "Rejected" ||
                                                  ord_ele.status == "Cancelled"
                                                    ? "text-red v-small ps-0"
                                                    : ord_ele.status ==
                                                        "Order Completed" ||
                                                      ord_ele.status ==
                                                        "Completed"
                                                    ? "text-green v-small ps-0"
                                                    : "text-prime v-small ps-0"
                                                }
                                              >
                                                {dele_ele?.status}
                                              </span>
                                            </p>
                                          </div>
                                          <div className="d-flex align-items-center ms-auto">
                                            <div
                                              className=""
                                              style={{ cursor: "pointer" }}
                                              onClick={(event) => {
                                                event.stopPropagation();
                                                call_del_func(
                                                  ord_ele.id,
                                                  "delivery_partner",
                                                  dele_ele.id
                                                );
                                              }}
                                            >
                                              <img
                                                src={require("../../assets/icon/call.png")}
                                                className="logo-img"
                                              />
                                            </div>

                                            <div className="end">
                                              <div className="ms-auto d-flex">
                                                <div
                                                  className="center  w-50 cursor"
                                                  onClick={() => {
                                                    // SetMapDriverModal({
                                                    //   is_open: true,
                                                    //   lat: ele.delivery[0]?.hyperlocal_pickup
                                                    //     ?.pickup_lat,
                                                    //   lon: ele.delivery[0]?.hyperlocal_pickup
                                                    //     ?.pickup_lng,
                                                    //   id: ele.delivery[0].id,
                                                    // });
                                                    // {console.log(order_data?.id)}
                                                    // SetOrderTrackingModal(
                                                    //   (prevalue: any) => {
                                                    //     prevalue.is_open = true;
                                                    //     prevalue.delivery_index = i;
                                                    //     prevalue.order_details =
                                                    //       order_data[ord_index];

                                                    //     prevalue.delivery_id =
                                                    //     ord_ele?.shipments[0].id;
                                                    //     prevalue.shipment_index = 0;
                                                    //     return { ...prevalue };
                                                    //   }
                                                    // );
                                                  }}
                                                >
                                                  {/* <Entypo
                                            
                                                name="location-pin"
                                                size={14}
                                                color="#666"
                                              /> */}
                                                  <div className="ms-4">
                                                    <img
                                                      src={require("../../assets/icon/map_or.png")}
                                                      className="delivery"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            {/* {shipment.hyperlocal_pickup
                                            .delivery_partner_mobile && (
                                            <>
                                              <div
                                                className="d-flex cursor px-3 border-right"
                                                onClick={() => {
                                                  del_partner_number(
                                                    ele.id,
                                                    shipment.hyperlocal_pickup
                                                      .delivery_partner_mobile_delivery_id,ele.brand_id,ele.outlet_id
                                                  );
                                                }}
                                              >
                                                <img
                                                  src={require("../../../assets/icon/call.png")}
                                                  className="logo-img"
                                                />
                                              </div>
                                              <div
                                                className="m-2"
                                                onClick={() => {
                                                  let send_data = {
                                                    order_id: ele.id,
                                                    chatto : "delivery_partner",
                                                    brand_id:ele.brand_id,
                                                    outlet_id:ele.outlet_id,
                                                    delivery_id : shipment.hyperlocal_pickup
                                                    .delivery_partner_mobile_delivery_id
                                                  };
                                                  chat_pickup(send_data);
                                                }}
                                                style={{width: 22}}
                                              >
                                                <img
                                                  src={
                                                    AppConfig.CDN_Media_url +
                                                    "0de0411aecb1f133a79605154a7e62b6.svg"
                                                  }
                                                  className="img-fluid"
                                                  
                                                />
                                              </div>
                                            </>
                                          )}
    
                                          {shipment.live_tracking == true && (
                                            <div className="cursor px-3">
                                              <img
                                                onClick={() => {
                                                  SetOrderTrackingModal(
                                                    (prevalue: any) => {
                                                      prevalue.is_open = true;
                                                      prevalue.delivery_index =
                                                      shipment_i;
                                                      prevalue.delivery_id =
                                                        shipment.id;
                                                        prevalue.brand_id =
                                                        ele.brand_id;
                                                        prevalue.outlet_id =
                                                        ele.outlet_id;
                                                      prevalue.order_data = ele;
                                                      return {
                                                        ...prevalue,
                                                      };
                                                    }
                                                  );
                                                }}
                                                src={require("../../../assets/icon/map.png")}
                                                className="delivery"
                                              />
                                            </div>
                                          )} */}
                                          </div>
                                        </div>

                                        <div className="d-flex align-items-center mt-2">
                                          <div className="d-flex alig-items-center">
                                            <div className="border-left px-1">
                                              {/* {shipment.hyperlocal_pickup.otp} */}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                )
                              : ord_ele?.order_status_id != 4 && (
                                  <div className="border p-2 text-muted br-4">
                                    <img
                                      src={
                                        AppConfig.CDN_Media_url +
                                        "0b90c59787ac497d0b292aaa9f052f83.svg"
                                      }
                                      className="img-fluid me-2"
                                      width="14"
                                    />
                                    We will assign one delivery partner soon
                                  </div>
                                )
                            : ""}
                        </div>
                        <div>
                          {ord_ele?.pickup_drop.length > 0
                            ? ord_ele?.pickup_drop[0]?.delivery_partner[0]?.delivery_partner?.name
                              ? ord_ele?.pickup_drop[0]?.delivery_partner.map(
                                  (dele_ele, i) =>
                                    dele_ele?.delivery_partner?.name &&
                                    dele_ele?.currently_working && (
                                      <div className="border-top pt-2">
                                        <div className="d-flex ">
                                          {dele_ele.delivery_partner.profile ? (
                                            <img
                                              src={
                                                dele_ele.delivery_partner
                                                  .profile
                                              }
                                              alt="16.a1d4f3f3"
                                              className="rounded-float-start"
                                              height="34"
                                              width="34"
                                              style={{
                                                borderRadius: "50px",
                                              }}
                                            />
                                          ) : (
                                            <i className="fa fa-user m-2"></i>
                                          )}
                                          <div className="ms-2">
                                            <p className="extra-small text-gray pb-1">
                                              {
                                                dele_ele.delivery_partner
                                                  .brand_name
                                              }
                                            </p>

                                            <p>
                                              <span className="fw-bold text-black ">
                                                {dele_ele.delivery_partner.name}
                                              </span>
                                              {/* "15px 50px 30px 5px" */}
                                              <span className="px-1"></span>
                                              <span
                                                className={
                                                  ord_ele.status ==
                                                    "Rejected" ||
                                                  ord_ele.status == "Cancelled"
                                                    ? "text-red v-small ps-0"
                                                    : ord_ele.status ==
                                                        "Order Completed" ||
                                                      ord_ele.status ==
                                                        "Completed"
                                                    ? "text-green v-small ps-0"
                                                    : "text-prime v-small ps-0"
                                                }
                                              >
                                                {dele_ele?.status}
                                              </span>
                                            </p>
                                          </div>
                                          <div className="d-flex align-items-center ms-auto">
                                            <div
                                              className=""
                                              style={{ cursor: "pointer" }}
                                              onClick={(event) => {
                                                event.stopPropagation();
                                                call_del_func(
                                                  ord_ele.id,
                                                  "delivery_partner",
                                                  dele_ele.id
                                                );
                                              }}
                                            >
                                              <img
                                                src={require("../../assets/icon/call.png")}
                                                className="logo-img"
                                              />
                                            </div>

                                            <div className="end">
                                              <div className="ms-auto d-flex">
                                                <div
                                                  className="center  w-50 cursor"
                                                  onClick={() => {
                                                    // SetMapDriverModal({
                                                    //   is_open: true,
                                                    //   lat: ele.delivery[0]?.hyperlocal_pickup
                                                    //     ?.pickup_lat,
                                                    //   lon: ele.delivery[0]?.hyperlocal_pickup
                                                    //     ?.pickup_lng,
                                                    //   id: ele.delivery[0].id,
                                                    // });
                                                    // {console.log(order_data?.id)}
                                                    // SetOrderTrackingModal(
                                                    //   (prevalue: any) => {
                                                    //     prevalue.is_open = true;
                                                    //     prevalue.delivery_index = 0;
                                                    //     prevalue.order_details =
                                                    //       order_data[ord_index];

                                                    //     prevalue.delivery_id =
                                                    //       dele_ele.id;
                                                    //     prevalue.shipment_index = 0;
                                                    //     return { ...prevalue };
                                                    //   }
                                                    // );
                                                  }}
                                                >
                                                  {/* <Entypo
                                            
                                                name="location-pin"
                                                size={14}
                                                color="#666"
                                              /> */}
                                                  <div className="ms-4">
                                                    <img
                                                      src={require("../../assets/icon/map_or.png")}
                                                      className="delivery"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            {/* {shipment.hyperlocal_pickup
                                            .delivery_partner_mobile && (
                                            <>
                                              <div
                                                className="d-flex cursor px-3 border-right"
                                                onClick={() => {
                                                  del_partner_number(
                                                    ele.id,
                                                    shipment.hyperlocal_pickup
                                                      .delivery_partner_mobile_delivery_id,ele.brand_id,ele.outlet_id
                                                  );
                                                }}
                                              >
                                                <img
                                                  src={require("../../../assets/icon/call.png")}
                                                  className="logo-img"
                                                />
                                              </div>
                                              <div
                                                className="m-2"
                                                onClick={() => {
                                                  let send_data = {
                                                    order_id: ele.id,
                                                    chatto : "delivery_partner",
                                                    brand_id:ele.brand_id,
                                                    outlet_id:ele.outlet_id,
                                                    delivery_id : shipment.hyperlocal_pickup
                                                    .delivery_partner_mobile_delivery_id
                                                  };
                                                  chat_pickup(send_data);
                                                }}
                                                style={{width: 22}}
                                              >
                                                <img
                                                  src={
                                                    AppConfig.CDN_Media_url +
                                                    "0de0411aecb1f133a79605154a7e62b6.svg"
                                                  }
                                                  className="img-fluid"
                                                  
                                                />
                                              </div>
                                            </>
                                          )}
    
                                          {shipment.live_tracking == true && (
                                            <div className="cursor px-3">
                                              <img
                                                onClick={() => {
                                                  SetOrderTrackingModal(
                                                    (prevalue: any) => {
                                                      prevalue.is_open = true;
                                                      prevalue.delivery_index =
                                                      shipment_i;
                                                      prevalue.delivery_id =
                                                        shipment.id;
                                                        prevalue.brand_id =
                                                        ele.brand_id;
                                                        prevalue.outlet_id =
                                                        ele.outlet_id;
                                                      prevalue.order_data = ele;
                                                      return {
                                                        ...prevalue,
                                                      };
                                                    }
                                                  );
                                                }}
                                                src={require("../../../assets/icon/map.png")}
                                                className="delivery"
                                              />
                                            </div>
                                          )} */}
                                          </div>
                                        </div>

                                        <div className="d-flex align-items-center mt-2">
                                          <div className="d-flex alig-items-center">
                                            <div className="border-left px-1">
                                              {/* {shipment.hyperlocal_pickup.otp} */}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                )
                              : ord_ele?.order_status_id != 4 && (
                                  <div className="border p-2 text-muted br-4">
                                    <img
                                      src={
                                        AppConfig.CDN_Media_url +
                                        "0b90c59787ac497d0b292aaa9f052f83.svg"
                                      }
                                      className="img-fluid me-2"
                                      width="14"
                                    />
                                    We will assign one delivery partner soon
                                  </div>
                                )
                            : ""}
                        </div>

                        <div className="border-top pt-2">
                          <div className="d-flex">
                          <div>
                            Order ID:{" "}
                            <span className="fw-500">{ord_ele?.order_id}</span>
                          </div>

                          {/* <button
                                                              type="button"
                                                              className="btn btn-prime btn-small px-3 ms-auto"
                                                              // onClick={() => {
                                                              //   openPickDropMap(
                                                              //     ord_ele?.seller?.outlet?.lat,
                                                              //     ord_ele?.seller?.outlet?.lng,
                                                              //     ord_ele?.customer?.delivery_address
                                                              //     ?.lat,
                                                              //     ord_ele?.customer?.delivery_address
                                                              //     ?.lng
                                                              //   );
                                                              // }}
                                                            >
                                                              <span className="small">
                                                                Pickup & Drop Location
                                                              </span>
                                                            </button> */}
                          </div>

                          <div>
                            Order Item ID::{" "}
                            <span className="fw-500">{ord_ele?.id}</span>
                          </div>
                          {ord_ele?.shipments.length > 0 && (
                            <div>
                              Shipment ID:{" "}
                              <span className="fw-500">
                                {ord_ele?.shipments.map(
                                  (ele, i) => ele.id + " "
                                )}
                              </span>
                            </div>
                          )}
                        </div>

                        <div className=" card px-2 mb-2 ">
                          <OwnAccordian heading={"Timeline Details"}>
                            <ul className="delivery_status_vertical w-100 pt-2">
                              {ord_ele?.order_timeline?.map((t_ele: any) => (
                                <li>
                                  <time className="">
                                    {t_ele.time_formatted}
                                  </time>
                                  <div className="timeline ">
                                    <div className="d-flex align-items-center">
                                      {t_ele.type == "delivery_partner" ? (
                                        <img
                                          src={require("../../assets/icon/delivery-boy.png")}
                                          className="w-10 mx-1"
                                          height="16"
                                          width="16"
                                        />
                                      ) : null}
                                      {t_ele.type == "shipment" ? (
                                        <img
                                          src={require("../../assets/icon/cutlery.png")}
                                          className="w-10 mx-1"
                                          height="16"
                                          width="16"
                                        />
                                      ) : null}
                                      <p className="text-gray small ps-1">
                                        {t_ele.status}
                                      </p>

                                      <div className="w-20 center ms-auto">
                                        {t_ele.hasOwnProperty("lat") && (
                                          <button
                                            type="button"
                                            className="btn btn-prime btn-small px-3"
                                            onClick={() => {
                                              openMap(t_ele?.lat, t_ele?.lng);
                                            }}
                                          >
                                            <span className="small">Map</span>
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </OwnAccordian>
                        </div>

                        {/* <button className="btn btn-primary me-2"
            onClick={(e) =>{
              e.stopPropagation();
              SetUserModal((prevValue: any) => ({
                ...prevValue,
                is_open: true,
                id: ord_ele.customer.user_id
              }))}}>View Customer User Details</button> */}
                <button
    className="btn btn-primary me-2"
    onClick={(e) => {
      handleCustomer(e,ord_ele)
   
    }}
  >
    View Customer User Details
  </button>

                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="center p-3">
                  <div className="card p-3 w-100">
                    <div className="w-100 text-center">
                      <img
                        src={require("../../assets/img/not-found.png")}
                        className="w-20"
                      />
                    </div>
                    <p className="center pt-3">No Orders data</p>
                  </div>
                </div>
              )
            ) : (
              <div className="row">
                {[1, 2, 3, 4, 5, 6].map(() => (
                  <div className="col-md-4 col-12 mb-3">
                    <OrderSkeleton />
                  </div>
                ))}
              </div>
            )}
      
      </>)}

  const PersonDetailsPanel = () => {
    return (
      <>
        {show_marker_data.loading ? (
          <div>
            <div className="card px-3 pb-3 pt-2 position-sticky-top">
              <div className="row">
                <div className="col-3 text-center p-2 pt-3 sm-px-0 border-bottom">
                  <Skeleton height={10} width={80} />
                </div>
                <div className="col-3 text-center p-2 pt-3 sm-px-0 border-bottom">
                  <Skeleton height={10} width={80} />
                </div>
                <div className="col-3 text-center p-2 pt-3 sm-px-0 border-bottom">
                  <Skeleton height={10} width={80} />
                </div>
                <div className="col-3 text-center p-2 pt-3 sm-px-0 border-bottom">
                  <Skeleton height={10} width={80} />
                </div>
              </div>
              <div className="pt-3">
                <div className="d-flex align-items-center py-2 w-100">
                  <div>
                    <Skeleton height={10} width={80} />
                    <Skeleton height={10} width={180} />
                  </div>

                  <div className=" ms-auto">
                    <Skeleton height={10} width={80} />
                  </div>
                </div>
              </div>
            </div>

            {selected_tab.name == "Dashboard" ? (
              <div>
                <div className="py-3 row sm-px-1">
                  <div className="col-6 sm-pe-1">
                    <div className="card p-3 sm-px-1 sm-py-2">
                      <div className="h-35">
                        <Skeleton height={10} width={180} />
                      </div>
                      <Skeleton height={10} width={50} />
                    </div>
                  </div>

                  <div className="col-6 sm-pe-1">
                    <div className="card p-3 sm-px-1 sm-py-2">
                      <div className="h-35">
                        <Skeleton height={10} width={180} />
                      </div>
                      <Skeleton height={10} width={50} />
                    </div>
                  </div>
                </div>

                <div className="card p-3 sm-px-1 sm-py-2 mb-3">
                  <div className="row p-2">
                    <div className="col-4 sm-px-1">
                      <div className="h-35">
                        <Skeleton height={10} width={150} />
                      </div>
                      <Skeleton height={10} width={50} />
                    </div>

                    <div className="col-4 sm-px-1">
                      <div className="h-35">
                        <Skeleton height={10} width={150} />
                      </div>
                      <Skeleton height={10} width={50} />
                    </div>

                    <div className="col-4 sm-px-1">
                      <div className="h-35">
                        <Skeleton height={10} width={150} />
                      </div>
                      <Skeleton height={10} width={50} />
                    </div>
                  </div>
                </div>

                <div className="sm-px-1">
                  <div className="card p-3 sm-px-1">
                    <div className="row p-2">
                      <div className="col-4 pb-4 sm-px-1">
                        <div className="h-35">
                          <Skeleton height={10} width={150} />
                        </div>
                        <Skeleton height={10} width={50} />
                      </div>

                      <div className="col-4 pb-4 sm-px-1">
                        <div className="h-35">
                          <Skeleton height={10} width={150} />
                        </div>
                        <Skeleton height={10} width={50} />
                      </div>

                      <div className="col-4 pb-4 sm-px-1">
                        <div className="h-35">
                          <Skeleton height={10} width={150} />
                        </div>
                        <Skeleton height={10} width={50} />
                      </div>

                      <div className="col-4 pb-4 sm-px-1">
                        <div className="h-35">
                          <Skeleton height={10} width={150} />
                        </div>
                        <Skeleton height={10} width={50} />
                      </div>

                      <div className="col-4 pb-4 sm-px-1">
                        <div className="h-35">
                          <Skeleton height={10} width={150} />
                        </div>
                        <Skeleton height={10} width={50} />
                      </div>
                      <div className="col-4 pb-4 sm-px-1">
                        <div className="h-35">
                          <Skeleton height={10} width={150} />
                        </div>
                        <Skeleton height={10} width={50} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        ) : (
          <>
            <div className="">
              <div className="card px-3 pb-3 pt-2 position-sticky-top">
                <AliceCarousel
                  mouseTracking
                  touchMoveDefaultEvents={false}
                  // paddingRight={10}
                  disableDotsControls
                  responsive={responsive}
                  disableButtonsControls
                  controlsStrategy="alternate"
                >
                  {tab_data?.map((tab: any, index: any) => (
                    <div
                      onDragStart={(e) => {
                        e.preventDefault();
                      }}
                      key={index}
                    >
                      <div
                        className="text-center"
                        onClick={() => {
                          SetSelectedTab(tab);
                          if (tab.id == "2") {
                            SetShowMarkerData((prevalue) => {
                              let send_data: any = prevalue;
                              let send_data1: any = prevalue;
                              SetLiveTabdata((live_prevalue: any) => {
                                send_data["fetch_more"] = false;
                                send_data["page_no"] =
                                  live_prevalue.live_page_no;
                                send_data1["fetch_more"] = false;
                                send_data1["page_no"] =
                                  live_prevalue.reservation_page_no;
                                get_live_orders(send_data);
                                get_live_orders_reservation(send_data1);
                                return { ...live_prevalue };
                              });
                              return { ...prevalue };
                            });
                          }
                        }}
                      >
                        <div
                          className={
                            selected_tab.id == tab.id
                              ? "tab-active p-2 pt-3 cursor sm-px-0"
                              : "cursor p-2 pt-3 sm-px-0 border-bottom"
                          }
                        >
                          <div>
                            <p
                              className={
                                selected_tab.id == tab.id
                                  ? "py-1 text-prime"
                                  : "py-1 text-black"
                              }
                            >
                              {tab.name}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </AliceCarousel>
                {/* top */}
                <div className="pt-3">
                  <div className="d-flex align-items-center py-2 w-100 row">
                    <div className="col-8">
                    <p className=" text-black">
                      ID: {show_marker_data.delivery_person_full_data.id}
                    </p>
                    <p className="text-dark fw-bold">
                    {
                      show_marker_data.delivery_person_full_data.user_details
                        .name
                    }
                  </p>
                    </div>
                    <div className="col-4">
                    
                    <div className=" ms-auto">
                      {show_marker_data.delivery_person_full_data
                        .new_reservation_blocked ? (
                        <div className="d-flex align-items-center">
                          <div className="color-dot-yellow"></div>
                          <p className="ps-2 text-orange">Blocked</p>
                        </div>
                      ) : show_marker_data.delivery_person_full_data
                          .logged_in ? (
                        <div className="d-flex align-items-center">
                          <div className="color-dot-green"></div>
                          <p className="ps-2 text-green">Active</p>
                        </div>
                      ) : (
                        <div className="d-flex align-items-center">
                          <div className="color-dot-red"></div>
                          <p className="ps-2 text-black">InActive</p>
                        </div>
                      )}
<p>{show_marker_data.delivery_person_data.location.time}</p>
                     
                    </div>
                  </div>
                  </div>

            
                </div>
              </div>

              {selected_tab.name == "Dashboard" ? (
                <div>
         
                   <div className="col-12 sm-pe-1 mt-3">
                      <div className="card p-3 sm-px-1 sm-py-2">
                        <p className="h-35 text-dark fw-bold sm-fs-10">
                          Latest Location
                        </p>
                        <div className="d-flex">
                        <p className="text-black">
                          {" "}
                          {show_marker_data.delivery_person_data.location.time}
                        </p>
                        <div className="ms-auto">
                              {/* <button
                                className="btn btn-prime btn-small "
                            
                              >  */}
                               {/* <span className="small">Map</span> */}
                                 <img
                                
                                     onClick={() => {
                                      openMap(
                                        show_marker_data.delivery_person_data.location.lat,
                                        show_marker_data.delivery_person_data.location.lng
                                      );
                                    }}
                                      src={require("../../assets/icon/map-arrow.png")}
                                      className="icon-24px"
                                    />
                              {/* </button> */}
                            </div>
                      </div>

                      </div>
                    </div>
                  
                  <div className="py-3 row sm-px-1">
              
                    <div className="col-6 sm-pe-1">
                      <div className="card p-3 sm-px-1 sm-py-2">
                        <p className="h-35 text-dark fw-bold sm-fs-10">
                          Total Ongoing Orders
                        </p>
                        <p className="text-black">
                          {" "}
                          {
                            show_marker_data.delivery_person_full_data
                              .total_ongoing_orders
                          }
                        </p>
                      </div>
                    </div>

                    <div className="col-6 sm-ps-1">
                      <div className="card p-3 sm-px-1 sm-py-2">
                        <p className="h-35 text-dark fw-bold sm-fs-10">
                          Total Ongoing Reservations
                        </p>
                        <p className="text-black">
                          {
                            show_marker_data.delivery_person_full_data
                              .total_ongoing_reservations
                          }
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card p-3 sm-px-1 sm-py-2 mb-3">
                    <div className="row p-2">
                      <div className="col-4 sm-px-1">
                        <p className="h-35 sm-fs-10  text-black">
                          Balance Amount
                        </p>
                        <p className="text-dark fw-bold mt-2">
                          {show_marker_data.delivery_person_data.balance_amount}
                        </p>
                      </div>
                      <div className="col-4 sm-px-1">
                        <p className="h-35 sm-fs-10  text-black">
                          Threshold Amount
                        </p>
                        <p className="text-dark fw-bold mt-2">
                          {
                            show_marker_data.delivery_person_data
                              .threshold_amount
                          }
                        </p>
                      </div>
                      <div className="col-4 sm-px-1">
                        <p className="h-35 sm-fs-10  text-black">
                          Deposited Amount
                        </p>
                        <p className="text-dark fw-bold mt-2">
                          {
                            show_marker_data.delivery_person_data
                              .deposited_amount
                          }
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="sm-px-1">
                    <div className="card p-3 sm-px-1">
                      <div className="row p-2">
                        <div className="col-4 pb-4 sm-px-1">
                          <p className="h-35 sm-fs-10  text-black">
                            Total Assigned Orders
                          </p>
                          <p className="text-dark fw-bold mt-2">
                            {
                              show_marker_data.delivery_person_full_data
                                .total_assigned_orders
                            }
                          </p>
                        </div>
                        <div className="col-4 pb-4 sm-px-1">
                          <p className="h-35 sm-fs-10  text-black">
                            Total Cancelled Orders
                          </p>
                          <p className="text-dark fw-bold mt-2">
                            {
                              show_marker_data.delivery_person_full_data
                                .total_cancelled_orders
                            }
                          </p>
                        </div>
                        <div className="col-4 pb-4 sm-px-1">
                          <p className="h-35 sm-fs-10  text-black">
                            Total Finished Orders
                          </p>
                          <p className="text-dark fw-bold mt-2">
                            {
                              show_marker_data.delivery_person_full_data
                                .total_finished_orders
                            }
                          </p>
                        </div>
                        <div className="col-4 pb-4 sm-px-1">
                          <p className="h-35 sm-fs-10  text-black">
                            Total Rejected Orders
                          </p>
                          <p className="text-dark fw-bold mt-2">
                            {
                              show_marker_data.delivery_person_full_data
                                .total_rejected_orders
                            }
                          </p>
                        </div>
                        <div className="col-4 pb-4 sm-px-1">
                          <p className="h-35 sm-fs-10  text-black ">
                            Total Expired Orders
                          </p>
                          <p className="text-dark fw-bold mt-2">
                            {
                              show_marker_data.delivery_person_full_data
                                .total_expired_orders
                            }
                          </p>
                        </div>
                        <div className="col-4 pb-4 sm-px-1">
                          <p className="h-35 sm-fs-10  text-black">
                            Total Assigned Reservations
                          </p>
                          <p className="text-dark fw-bold mt-2">
                            {
                              show_marker_data.delivery_person_full_data
                                .total_assigned_reservations
                            }
                          </p>
                        </div>
                        <div className="col-4 sm-px-1">
                          <p className="h-35 sm-fs-10  text-black">
                            Total Cancelled Reservations
                          </p>
                          <p className="text-dark fw-bold mt-2">
                            {
                              show_marker_data.delivery_person_full_data
                                .total_cancelled_reservations
                            }
                          </p>
                        </div>
                        <div className="col-4 sm-px-1">
                          <p className="h-35 sm-fs-10  text-black">
                            Total Order Converted Reservations
                          </p>
                          <p className="text-dark fw-bold mt-2">
                            {
                              show_marker_data.delivery_person_full_data
                                .total_order_converted_reservations
                            }
                          </p>
                        </div>
                        <div className="col-4 sm-px-1">
                          <p className="h-35 sm-fs-10  text-black">
                            Total Forwarded Others Reservations
                          </p>
                          <p className="text-dark fw-bold mt-2">
                            {
                              show_marker_data.delivery_person_full_data
                                .total_forwarded_others_reservations
                            }
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {selected_tab.name == "Live" ? (
                <div>
                  <div className="my-2">
                    <div className="bg-fff shadow p-3 radius-none sm-px-1 sm-py-2">
                      <h6 className="mb-0">Live Orders</h6>
                    </div>

                    {!live_tab_data.is_live_api_loading ? (
                      live_order_data.length > 0 ? (
                        <>
                          {live_order_data.map((ele: any, index) => (
                            <div
                              className={
                                index == 0
                                  ? "radius-top-none card p-3 mb-2 sm-px-1 sm-py-2"
                                  : "card p-3 mb-2 sm-px-1 sm-py-2"
                              }
                              key={index}
                            >
                              <div className="">
                                <div className="d-flex align-items-center pb-3">
                                  <div className="color-dot-red"></div>
                                  <p className="ps-2 text-red">Live</p>

                                  <div className="ms-auto">
                                    <Link
                                      to={"/orders/" + ele.id}
                                      target="_blank"
                                    >
                                      <button className="btn btn-sm btn-prime">
                                        Go to Order Details
                                      </button>
                                    </Link>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center pb-1">
                                  <p className=" text-dark"># {ele.id}</p>
                                  <div className="ms-auto">
                                    <p className=" text-gray small">
                                      {ele.time}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {/* <p className=" text-black">Customer</p> */}
                              <div className="row py-3">
                                <div className="col-4 border-right sm-border-right-none pe-0">
                                  <div className="d-flex">
                                    <img
                                      src={require("../../assets/icon/profile.png")}
                                      className="icon-18px"
                                    />
                                    <div className="ps-2">
                                      <p className="extra-small pb-1 text-gray sm-fs-9">
                                        Customer Name
                                      </p>
                                      <p className=" sm-fs-10 text-dark fw-bold">
                                        {ele.customer.name}
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-4 border-right sm-border-right-none pe-0">
                                  <div className="d-flex">
                                    <img
                                      src={require("../../assets/icon/Delivery.png")}
                                      className="icon-18px"
                                    />
                                    <div className="ps-2">
                                      <p className="extra-small pb-1 text-gray sm-fs-9">
                                        Order Type
                                      </p>
                                      <p className=" sm-fs-10 text-dark fw-bold">
                                        {ele.sector_id == 4
                                          ? "Pickupdrop"
                                          : ele.sector_id == 2
                                          ? "Resturant"
                                          : null}
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-4  pe-0">
                                  <div className="d-flex">
                                    <img
                                      src={require("../../assets/icon/indian-rupee.png")}
                                      className="icon-18px"
                                    />
                                    <div className="ps-2">
                                      <p className="extra-small pb-1 text-gray  sm-fs-9">
                                        Total Amount
                                      </p>
                                      <p className=" text-dark fw-bold  sm-fs-10">
                                        ₹ {ele.total_amount_payable}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {ele.sector_id == 4 ? (
                                <div className="pb-2">
                                  <div className=" pt-2 d-flex">
                                    <img
                                      src={require("../../assets/icon/up-arrow.png")}
                                      className="icon-18px"
                                    />
                                    <div className="ps-2">
                                      <p className="fs-7 fw-bold text-dark">
                                        Pickup Address
                                      </p>
                                      <p className="pt-1">
                                        {
                                          ele.customer.pickup_address
                                            .map_address
                                        }
                                      </p>
                                    </div>
                                  </div>

                                  <div className=" pt-3 d-flex">
                                    <img
                                      src={require("../../assets/icon/down.png")}
                                      className="icon-18px"
                                    />
                                    <div className="ps-2">
                                      <p className="fs-7 fw-bold text-dark">
                                        Drop Address
                                      </p>
                                      <p className="pt-1">
                                        {
                                          ele.customer.delivery_address
                                            .map_address
                                        }
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}

                              {ele.sector_id == 2 ? (
                                <div className=" pb-2">
                                  <div className="pt-2 d-flex">
                                    <img
                                      src={require("../../assets/icon/up-arrow.png")}
                                      className="icon-18px"
                                    />
                                    <div className="ps-2">
                                      <p className="fs-7 fw-bold text-dark">
                                        Store Address
                                      </p>

                                      <p className="pt-1">
                                        <p>{ele.seller.outlet.map_address}</p>
                                      </p>
                                    </div>
                                  </div>
                                  <div className=" pt-3 d-flex">
                                    <img
                                      src={require("../../assets/icon/down.png")}
                                      className="icon-18px"
                                    />
                                    <div className="ps-2">
                                      <p className="fs-7 fw-bold text-dark">
                                        Drop Address
                                      </p>

                                      <p className="pt-1">
                                        {
                                          ele.customer.delivery_address
                                            .map_address
                                        }
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}

                              <div className="border-top">
                                <TimelineAccordian>
                                  <div className="">
                                    <ul className="delivery_status_vertical w-100">
                                      {ele.delivery_partner_order?.status_timeline?.map(
                                        (ele: any) => (
                                          <li>
                                            <time className="extra-small w-30 text-gray">
                                              {ele?.time_formatted}
                                            </time>
                                            <div className="timeline w-70">
                                              <span className="text-dark ">
                                                {ele?.status}
                                              </span>
                                              {ele?.user.profile != "" && (
                                                <div className="d-flex align-items-center pt-2">
                                                  {ele?.user.profile ? (
                                                    <img
                                                      src={ele?.user.profile}
                                                      className="mx-1 user-img-timeline"
                                                    />
                                                  ) : (
                                                    <img
                                                      src={require("../../assets/img/profile.png")}
                                                      className="mx-1 user-img-timeline"
                                                    />
                                                  )}
                                                  <p className=" text-gray">
                                                    {ele?.user.name}
                                                  </p>
                                                </div>
                                              )}
                                            </div>
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </div>
                                </TimelineAccordian>
                              </div>
                            </div>
                          ))}
                        </>
                      ) : (
                        <div className="card radius-top-none p-3">
                          <p>No Live Data</p>
                        </div>
                      )
                    ) : (
                      <div>
                        <div className="card p-3 mb-2 sm-px-1 sm-py-2">
                          <div className="">
                            <div className="d-flex align-items-center pb-3">
                              <Skeleton height={10} width={80} />
                              <div className="ms-auto">
                                <Skeleton height={30} width={100} />
                              </div>
                            </div>

                            <div className="d-flex align-items-center pb-1">
                              <Skeleton height={10} width={50} />
                              <div className="ms-auto">
                                <Skeleton height={10} width={80} />
                              </div>
                            </div>

                            <div className="row py-3">
                              <div className="col-4 border-right sm-border-right-none pe-0">
                                <div className="d-flex">
                                  <Skeleton height={18} width={18} />
                                  <div className="ps-2">
                                    <Skeleton
                                      height={10}
                                      width={100}
                                      className="pb-1"
                                    />
                                    <Skeleton height={10} width={80} />
                                  </div>
                                </div>
                              </div>
                              <div className="col-4 border-right sm-border-right-none pe-0">
                                <div className="d-flex">
                                  <Skeleton height={18} width={18} />
                                  <div className="ps-2">
                                    <Skeleton
                                      height={10}
                                      width={100}
                                      className="pb-1"
                                    />
                                    <Skeleton height={10} width={80} />
                                  </div>
                                </div>
                              </div>
                              <div className="col-4 border-right sm-border-right-none pe-0">
                                <div className="d-flex">
                                  <Skeleton height={18} width={18} />
                                  <div className="ps-2">
                                    <Skeleton
                                      height={10}
                                      width={100}
                                      className="pb-1"
                                    />
                                    <Skeleton height={10} width={80} />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="pb-2">
                              <div className=" pt-2 d-flex">
                                <Skeleton height={18} width={18} />
                                <div className="ps-2 w-100">
                                  <div className="pb-1">
                                    <Skeleton height={10} width={80} />
                                  </div>
                                  <Skeleton
                                    height={10}
                                    width="100%"
                                    count={2}
                                  />
                                </div>
                              </div>

                              <div className=" pt-3 d-flex">
                                <Skeleton height={18} width={18} />
                                <div className="ps-2 w-100">
                                  <div className="pb-1">
                                    <Skeleton height={10} width={80} />
                                  </div>
                                  <Skeleton
                                    height={10}
                                    width="100%"
                                    count={2}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="border-top">
                              <div className="pt-2">
                                <Skeleton height={10} width={80} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="mt-2 align-center">
                      {live_tab_data.live_next_page ? (
                        <button
                          className="btn btn-text text-prime w-100 bg-fff radius shadow fw-bold py-2"
                          onClick={() => {
                            SetShowMarkerData((prevalue) => {
                              let send_data: any = prevalue;
                              SetLiveTabdata((live_prevalue: any) => {
                                send_data["fetch_more"] = true;
                                send_data["page_no"] =
                                  live_prevalue.live_page_no;
                                get_live_orders(send_data);
                                return { ...live_prevalue };
                              });
                              return { ...prevalue };
                            });
                          }}
                        >
                          Load more
                        </button>
                      ) : null}
                    </div>
                  </div>

                  <div className="my-2">
                    <div className="bg-fff shadow p-3 radius-none sm-px-1 sm-py-2">
                      <h6>Reservation Orders </h6>
                    </div>

                    {!live_tab_data.is_reservation_api_loading ? (
                      live_reservation_data.length > 0 ? (
                        <>
                          {live_reservation_data.map((ele: any, index) => (
                            <div
                              className={
                                index == 0
                                  ? "radius-top-none card p-3 mb-2 sm-px-1 sm-py-2"
                                  : "card p-3 mb-2 sm-px-1 sm-py-2"
                              }
                              key={index}
                            >
                              {/* <Link to={"/orders/" + ele.id} target="_blank"> */}
                              <div className="d-flex align-items-center pb-3">
                                <div className="color-dot-red"></div>
                                <p className="ps-2 text-red">Live</p>
                              </div>
                              <div className="d-flex align-items-center pb-1">
                                <p className=" text-dark"># {ele.id}</p>
                                <div className="ms-auto">
                                  <p className=" text-gray small">{ele.time}</p>
                                </div>
                              </div>
                              <div className="row py-3">
                                <div className="col-md-4 col-6 border-right sm-border-right-none">
                                  <div className="d-flex">
                                    <img
                                      src={require("../../assets/icon/profile.png")}
                                      className="icon-18px"
                                    />
                                    <div className="ps-2">
                                      <p className="extra-small pb-1 text-gray">
                                        Customer Name
                                      </p>
                                      <p className=" text-dark fw-bold">
                                        {ele.customer.name}
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-6 col-md-4 border-right sm-border-right-none">
                                  <div className="d-flex">
                                    <img
                                      src={require("../../assets/icon/Delivery.png")}
                                      className="icon-18px"
                                    />
                                    <div className="ps-2">
                                      <p className="extra-small pb-1 text-gray">
                                        Order Type
                                      </p>
                                      <p className=" text-dark fw-bold">
                                        {ele.sector_id == 4
                                          ? "Pickupdrop"
                                          : ele.sector_id == 2
                                          ? "Resturant"
                                          : null}
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-6 col-md-4 ">
                                  <div className="d-flex">
                                    <img
                                      src={require("../../assets/icon/indian-rupee.png")}
                                      className="icon-18px"
                                    />
                                    <div className="ps-2">
                                      <p className="extra-small pb-1 text-gray">
                                        Total Amount
                                      </p>
                                      <p className=" text-dark fw-bold">
                                        ₹ {ele.total_amount_payable}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {ele.sector_id == 4 ? (
                                <div className="pb-2">
                                  <div className=" pt-2 d-flex">
                                    <img
                                      src={require("../../assets/icon/up-arrow.png")}
                                      className="icon-18px"
                                    />
                                    <div className="ps-2">
                                      <p className="fs-7 fw-bold text-dark">
                                        Pickup Address
                                      </p>
                                      <p className="pt-1">
                                        {
                                          ele.customer.pickup_address
                                            .map_address
                                        }
                                      </p>
                                    </div>
                                  </div>

                                  <div className=" pt-3 d-flex">
                                    <img
                                      src={require("../../assets/icon/down.png")}
                                      className="icon-18px"
                                    />
                                    <div className="ps-2">
                                      <p className="fs-7 fw-bold text-dark">
                                        Drop Address
                                      </p>
                                      <p className="pt-1">
                                        {
                                          ele.customer.delivery_address
                                            .map_address
                                        }
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}

                              {ele.sector_id == 2 ? (
                                <div className=" pb-2">
                                  <div className="pt-2 d-flex">
                                    <img
                                      src={require("../../assets/icon/up-arrow.png")}
                                      className="icon-18px"
                                    />
                                    <div className="ps-2">
                                      <p className="fs-7 fw-bold text-dark">
                                        Store Address
                                      </p>

                                      <p className="pt-1">
                                        <p>{ele.seller.outlet.map_address}</p>
                                      </p>
                                    </div>
                                  </div>
                                  <div className=" pt-3 d-flex">
                                    <img
                                      src={require("../../assets/icon/down.png")}
                                      className="icon-18px"
                                    />
                                    <div className="ps-2">
                                      <p className="fs-7 fw-bold text-dark">
                                        Drop Address
                                      </p>
                                      {/* <p>
                                            {
                                              ele.customer.delivery_address
                                                .map_address
                                            }
                                          </p> */}
                                      <p className="pt-1">
                                        {
                                          ele.customer.delivery_address
                                            .map_address
                                        }
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}

                              <div className="border-top">
                                <TimelineAccordian>
                                  <div className="">
                                    <ul className="delivery_status_vertical w-100">
                                      {ele.delivery_partner_order?.status_timeline?.map(
                                        (ele: any) => (
                                          <li>
                                            <time className="extra-small w-30 text-gray">
                                              {ele?.time_formatted}
                                            </time>
                                            <div className="timeline w-70">
                                              <span className="text-dark">
                                                : {ele?.status}
                                              </span>
                                              {ele?.user.profile != "" && (
                                                <div className="d-flex align-items-center pt-2">
                                                  {ele?.user.profile ? (
                                                    <img
                                                      src={ele?.user.profile}
                                                      className="mx-1 user-img-timeline"
                                                    />
                                                  ) : (
                                                    <img
                                                      src={require("../../assets/img/profile.png")}
                                                      className="mx-1 user-img-timeline"
                                                    />
                                                  )}

                                                  <p className=" text-gray">
                                                    {ele?.user.name}
                                                  </p>
                                                </div>
                                              )}
                                            </div>
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </div>
                                </TimelineAccordian>
                              </div>
                              {/* </Link> */}
                            </div>
                          ))}
                          <div className="mt-2 align-center">
                            {live_tab_data.reservation_next_page ? (
                              <button
                                className="btn btn-text text-prime w-100 bg-fff radius shadow fw-bold py-2"
                                onClick={() => {
                                  SetShowMarkerData((prevalue) => {
                                    let send_data1: any = prevalue;
                                    SetLiveTabdata((live_prevalue: any) => {
                                      send_data1["fetch_more"] = true;
                                      send_data1["page_no"] =
                                        live_prevalue.reservation_page_no;
                                      get_live_orders_reservation(send_data1);
                                      return { ...live_prevalue };
                                    });
                                    return { ...prevalue };
                                  });
                                }}
                              >
                                Load more
                              </button>
                            ) : null}
                          </div>
                        </>
                      ) : (
                        <div className="card radius-top-none px-3 pb-3">
                          <p>No Reservation Data</p>
                        </div>
                      )
                    ) : (
                      <div>
                        <div className="card p-3 mb-2 sm-px-1 sm-py-2">
                          <div className="">
                            <div className="d-flex align-items-center pb-3">
                              <Skeleton height={10} width={80} />
                              <div className="ms-auto">
                                <Skeleton height={30} width={100} />
                              </div>
                            </div>

                            <div className="d-flex align-items-center pb-1">
                              <Skeleton height={10} width={50} />
                              <div className="ms-auto">
                                <Skeleton height={10} width={80} />
                              </div>
                            </div>

                            <div className="row py-3">
                              <div className="col-4 border-right sm-border-right-none pe-0">
                                <div className="d-flex">
                                  <Skeleton height={18} width={18} />
                                  <div className="ps-2">
                                    <Skeleton
                                      height={10}
                                      width={100}
                                      className="pb-1"
                                    />
                                    <Skeleton height={10} width={80} />
                                  </div>
                                </div>
                              </div>
                              <div className="col-4 border-right sm-border-right-none pe-0">
                                <div className="d-flex">
                                  <Skeleton height={18} width={18} />
                                  <div className="ps-2">
                                    <Skeleton
                                      height={10}
                                      width={100}
                                      className="pb-1"
                                    />
                                    <Skeleton height={10} width={80} />
                                  </div>
                                </div>
                              </div>
                              <div className="col-4 border-right sm-border-right-none pe-0">
                                <div className="d-flex">
                                  <Skeleton height={18} width={18} />
                                  <div className="ps-2">
                                    <Skeleton
                                      height={10}
                                      width={100}
                                      className="pb-1"
                                    />
                                    <Skeleton height={10} width={80} />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="pb-2">
                              <div className=" pt-2 d-flex">
                                <Skeleton height={18} width={18} />
                                <div className="ps-2 w-100">
                                  <div className="pb-1">
                                    <Skeleton height={10} width={80} />
                                  </div>
                                  <Skeleton
                                    height={10}
                                    width="100%"
                                    count={2}
                                  />
                                </div>
                              </div>

                              <div className=" pt-3 d-flex">
                                <Skeleton height={18} width={18} />
                                <div className="ps-2 w-100">
                                  <div className="pb-1">
                                    <Skeleton height={10} width={80} />
                                  </div>
                                  <Skeleton
                                    height={10}
                                    width="100%"
                                    count={2}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="border-top">
                              <div className="pt-2">
                                <Skeleton height={10} width={80} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : null}
              {selected_tab.name == "Session" ? (
                <div className="py-3">
                  {session_loading ? (
                    <div>
                      <div className="sm-px-1">
                        <div className="card p-3 mb-3 sm-px-1 sm-py-2">
                          <div>
                            <div className="d-flex align-items-center pb-3">
                              <Skeleton height={10} width={50} />
                            </div>
                            <div className="d-flex align-items-center pb-1">
                              <Skeleton height={10} width={80} />
                              <div className="ms-auto">
                                <Skeleton height={10} width={50} />
                              </div>
                            </div>
                            <Skeleton height={10} width={50} />
                            <div className="mt-2">
                              <div className="pb-1">
                                <Skeleton height={10} width={80} />
                              </div>
                              <Skeleton height={10} width={120} />
                            </div>
                          </div>
                        </div>

                        <div className="card p-3 mb-3 sm-px-1 sm-py-2">
                          <div className="p-1">
                            <div className="row">
                              <div className="col-6 border-right">
                                <div className="pb-1">
                                  <Skeleton height={8} width={30} />
                                </div>
                                <div className="d-flex align-items-center py-1">
                                  <Skeleton height={10} width={80} />
                                  <div className="ms-auto">
                                    <div className="ps-2">
                                      <Skeleton height={10} width={50} />
                                    </div>
                                  </div>
                                </div>
                                <div className="pb-2">
                                  <Skeleton height={10} width={50} />
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="pb-1">
                                  <Skeleton height={8} width={30} />
                                </div>
                                <div className="d-flex align-items-center py-1">
                                  <Skeleton height={10} width={80} />
                                  <div className="ms-auto">
                                    <div className="ps-2">
                                      <Skeleton height={10} width={50} />
                                    </div>
                                  </div>
                                </div>
                                <div className="pb-2">
                                  <Skeleton height={10} width={50} />
                                </div>
                              </div>
                            </div>
                            <div className="mt-2">
                              <div className="pb-1">
                                <Skeleton height={10} width={80} />
                              </div>
                              <Skeleton height={10} width={120} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      {session_data.length > 0 ? (
                        session_data.map((ele: any) => get_session_details(ele))
                      ) : (
                        <div className=" sm-px-1">
                          <div className="card p-3 text-center">
                            <p>No Session Details</p>
                          </div>
                        </div>
                      )}

                      {next_page ? (
                        <div className=" sm-px-1">
                          <div className="my-3 card p-3 w-100">
                            <p
                              className="text-prime fw-bold text-center cursor"
                              onClick={() => {
                                load_more();
                              }}
                            >
                              Load More
                            </p>
                          </div>
                        </div>
                      ) : null}
                    </>
                  )}
                </div>
              ) : null}
              {selected_tab.name == "Profile" ? (
                <div>
                  {show_marker_data.loading ? (
                    <div className="py-3 sm-px-1">
                      <div className="card p-3">
                        <div className="row align-items-center pb-3">
                          <div className="col-md-2 col-3">
                            <Skeleton
                              height={70}
                              width={70}
                              borderRadius={50}
                            />
                          </div>
                          <div className="col-md-10 col-9">
                            <Skeleton height={8} width={50} />
                            <div className="pt-2">
                              <Skeleton height={10} width={85} />
                            </div>
                          </div>
                        </div>
                        <div className="pt-3">
                          <Skeleton height={10} width={80} />
                          <div className="pt-2">
                            <Skeleton height={10} width={50} />
                          </div>
                        </div>

                        <div className="pt-3">
                          <Skeleton height={10} width={80} />
                          <div className="pt-2">
                            <Skeleton height={10} width={150} />
                          </div>
                        </div>

                        <div className="pt-3">
                          <Skeleton height={10} width={80} />

                          <div className="d-flex pt-2 align-items-center">
                            <div className="pe-2">
                              <Skeleton height={20} width={20} />
                            </div>
                            <div className="pe-2">
                              <Skeleton height={20} width={20} />
                            </div>
                            <div className="pe-2">
                              <Skeleton height={20} width={20} />
                            </div>
                            <div className="pe-2">
                              <Skeleton height={20} width={20} />
                            </div>
                            <div className="pe-2">
                              <Skeleton height={20} width={20} />
                            </div>
                          </div>
                        </div>

                        <div className="pt-3">
                          <Skeleton height={10} width={80} />
                          <div className="pt-2">
                            <Skeleton height={10} width={150} />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="py-3 sm-px-1">
                      <div className="card p-3">
                      <div className="d-flex border-bottom pb-2 mb-2">

                      
    {show_marker_data.delivery_person_full_data?.user_details?.profile ? (
      <img
        src={show_marker_data.delivery_person_full_data.user_details.profile}
        alt="Profile"
        className="profile-round-img"
        height="34"
        width="34"
        style={{ borderRadius: "50%" }}
      />
    ) : (
      <img
        src={require("../../assets/icon/avatar.png")}
        className="profile-round-img"
        height="34"
        width="34"
        style={{ borderRadius: "50%" }}
      />
    )}
              <div className="ms-2">


    <p className="text-dark fw-bold fs-7">{show_marker_data.delivery_person_full_data?.user_details?.name}</p>
    <p>{show_marker_data.delivery_person_full_data?.user_details?.username}</p>
    </div>
      <div className="ms-auto text-end">
                           
                      
                            <div className="d-flex end">
                              <div className="call-btn m-2" onClick={(e) => handleCall(e, show_marker_data.delivery_person_full_data?.user_details.full_mobile)} style={{ cursor: 'pointer' }}>
                                <Ionicons name="md-call" size={18} color="#A80032" />
                              </div>
                              {show_marker_data.delivery_person_full_data?.user_details?.email && (
                                <div className="call-btn" onClick={(e) => handleEmail(e, show_marker_data.delivery_person_full_data?.user_details.email)} style={{ cursor: 'pointer' }}>
                                  <Ionicons name="md-mail" size={18} color="#A80032" />
                                </div>
                              )}
                            </div>
                          </div></div>



  <div className="pt-3">
  <p>User Id: <span className="fw-500">{show_marker_data.delivery_person_full_data?.user_details.user_id}</span></p>
  <p>Email: <span className="fw-500">{show_marker_data.delivery_person_full_data?.user_details.email}</span></p>
  <p>Mobile: <span className="fw-500">{show_marker_data.delivery_person_full_data?.user_details.full_mobile}</span></p>
  



                          {/* <div>
                            <div className="pb-3 d-flex">
                              {ord_ele.hasOwnProperty("profile_picture") && ord_ele.profile_picture != null ? (
                                <div className="pe-2">
                                  <img
                                    src={ord_ele.profile_picture}
                                    alt="Profile"
                                    className="rounded-float-start"
                                    height="34"
                                    width="34"
                                    style={{ borderRadius: "50px" }}
                                  />
                                </div>
                              ) : (
                                <div className="pe-2">
                                  <img
                                    src={require("../../assets/icon/avatar.png")}
                                    className="rounded-float-start"
                                    height="34"
                                    width="34"
                                  />
                                </div>
                              )}
                              <div>
                                <p className="text-dark fw-bold fs-7">{ord_ele?.name}</p>
                                <p>{ord_ele?.user_name}</p>
                              </div>
                            </div>
                      
                            <div>
                              Demo Order User: <span className="fw-500">{ord_ele.demo_order ? "Yes" : "No"}</span>
                            </div>
                            <div>
                              COD: <span className="fw-500">{ord_ele.cod_allowed === "force_allowed" ? "force allowed" : ord_ele.cod_allowed}</span>
                            </div>
                            <div>
                              Mobile: <span className="fw-500">{ord_ele.full_mobile}</span>
                            </div>
                            <div>
                              Blood Group: <span className="fw-500">{ord_ele.blood_group}</span>
                            </div>
                            <div>
                              Gender: <span className="fw-500">{ord_ele.gender}</span>
                            </div>
                            <div>
                              Date of Birth: <span className="fw-500">{ord_ele.dob}</span>
                            </div>
                            <div>
                              Address: <span className="fw-500">{ord_ele.address}</span>
                            </div>
                            <div>
  Height: <span className="fw-500">{ord_ele.height}</span>
</div>
<div>
  Weight: <span className="fw-500">{ord_ele.weight}</span>
</div>
<div>
  Pincode: <span className="fw-500">{ord_ele.pincode}</span>
</div>
<div>
  Referred User ID: <span className="fw-500">{ord_ele.referred_user_id}</span>
</div>
<div>
  Default App: <span className="fw-500">{ord_ele.default_app}</span>
</div>
<div>
  Signup Platform: <span className="fw-500">{ord_ele.signup_platform}</span>
</div>
<div>
  User Status: <span className="fw-500">{ord_ele.user_status}</span>
</div>
<div>
  Profile Verified: <span className="fw-500">{ord_ele.profile_verified ? 'Yes' : 'No'}</span>
</div>
<div>
  Demo Order: <span className="fw-500">{ord_ele.demo_order ? 'Yes' : 'No'}</span>
</div>
<div>
  COD Allowed: <span className="fw-500">{ord_ele.cod_allowed}</span>
</div>

                          </div>
                      
                          <div className="ms-auto text-end">
                            <div className="mb-1">{ord_ele?.created_date}</div>
                            <div>
                              ID: <span className="fw-500">{ord_ele?.id}</span>
                            </div>
                            <div>{ord_ele?.user_status}</div>
                      
                            <div className="d-flex end">
                              <div className="call-btn m-2" onClick={(e) => handleCall(e, ord_ele?.full_mobile)} style={{ cursor: 'pointer' }}>
                                <Ionicons name="md-call" size={18} color="#A80032" />
                              </div>
                              {ord_ele?.email && (
                                <div className="call-btn" onClick={(e) => handleEmail(e, ord_ele?.email)} style={{ cursor: 'pointer' }}>
                                  <Ionicons name="md-mail" size={18} color="#A80032" />
                                </div>
                              )}
                            </div>
                          </div> */}
                          
                        </div>
                        <button className="btn btn-primary ms-auto"
            onClick={() =>
              SetUserModal((prevValue: any) => ({
                ...prevValue,
                is_open: true,
                id: show_marker_data.delivery_person_full_data?.user_details?.user_id
              }))}>View User Details</button>
                        {/* <div className="row align-items-center pb-3">
                          <div className="col-md-2 col-3">
                            <img
                              src={
                                show_marker_data.delivery_person_full_data
                                  .user_details.profile
                              }
                              className="profile-round-img"
                            />
                          </div>
                          <div className="col-md-10 col-9">
                            <p>Agent ID: 1</p>
                            <p className="text-dark pt-2">
                              @
                              {
                                show_marker_data.delivery_person_full_data
                                  .user_details.username
                              }
                            </p>
                          </div>
                        </div>
                        <div className="pt-3">
                          <p>Zone Service</p>
                          <p className="text-dark pt-2">Erode</p>
                        </div>

                        <div className="pt-3">
                          <p>Current Location</p>
                          <p className="text-dark pt-2">
                            Erode Railway Station
                          </p>
                        </div>

                        <div className="pt-3">
                          <p>Customer Rating</p>
                          <div className="d-flex pt-2 align-items-center">
                            <div className="pe-2">
                              <Ionicons
                                name="star-sharp"
                                size={16}
                                color="orange"
                              />
                            </div>
                            <div className="pe-2">
                              <Ionicons
                                name="star-sharp"
                                size={16}
                                color="orange"
                              />
                            </div>
                            <div className="pe-2">
                              <Ionicons
                                name="star-sharp"
                                size={16}
                                color="orange"
                              />
                            </div>
                            <div className="pe-2">
                              <Ionicons
                                name="star-sharp"
                                size={16}
                                color="orange"
                              />
                            </div>
                            <div className="pe-2">
                              <Ionicons
                                name="star-sharp"
                                size={16}
                                color="#eee"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="pt-3">
                          <p>Complaints</p>
                          <p className="text-dark pt-2">No Complaints</p>
                        </div> */}
                      </div>
                    </div>
                  )}
                </div>
              ) : null}
            </div>
          </>
        )}
      </>
    );
  };

  function get_statistics_name(value: any) {
    let return_value: any;
    let value1 = value.replaceAll("_", " ");
    return_value = value1.charAt(0).toUpperCase() + value1.slice(1);
    return return_value;
  }
  async function addMarker(lat: number, lng: number, contentText: string,arrow:any,color:any,from:any,pass_data:any,del_marker:any,agent:any) {
  
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");
    console.log("AdvancedMarkerElement:", AdvancedMarkerElement);
    if (!AdvancedMarkerElement) {
      console.error("AdvancedMarkerElement is not available.");
      return;
    }
    // Create custom content for the marker
    let del_partner =""
    if(contentText==""){
     del_partner =arrow
    }else{
       del_partner =arrow+ ',' + contentText

    }

    const defaultColor = "#FF5733"; // Example fallback color
    const markerColor = color || defaultColor;
    const priceTag = document.createElement("div");
    priceTag.className = "map-marker"; // Ensure you have the CSS defined
    priceTag.id = `delivery-partner-${agent}`;
    priceTag.textContent =  from=="del_partner" ? del_partner : arrow+ contentText; // Customize text content here
   
    priceTag.style.backgroundColor = markerColor;
    // priceTag.style.borderTop = markerColor;
    const triangle = document.createElement("div");
  triangle.style.position = "absolute";
  triangle.style.left = "50%";
  triangle.style.top = "100%";
  triangle.style.transform = "translate(-50%, 0)";
  triangle.style.width = "0";
  triangle.style.height = "0";
  triangle.style.borderLeft = "8px solid transparent";
  triangle.style.borderRight = "8px solid transparent";
  triangle.style.borderTop = `8px solid ${markerColor}`; // Dynamic color
  priceTag.appendChild(triangle);

    // Create and place the AdvancedMarkerElement
    try {
      const marker = new AdvancedMarkerElement({
        map,
        position: { lat, lng },
        content:priceTag,
      });
      console.log("Marker created successfully:", marker);
      if(from!="del_partner"){
       
      marker.addListener("click", () => {

        
        SetShowMarkerData((prevValue) => {
          return {
            ...prevValue,
            is_open: true,
            view_type: "order_details",
            loading: true,
            id: contentText
          };
        });
        setOrderId(order_details_value.id)})
      }else{
        
        markerCollection[agent] = marker;
        marker.addListener("click", () => {
         
          // get_person_details(agent)

      
          if (previousMarker && previousMarker !== priceTag) {

            previousMarker.style.outline = "none";
            previousMarker.style.zIndex = "auto";
        }
          priceTag.style.outline =  "3px solid #FFFFFF";
        priceTag.style.zIndex = "1000"; 

        previousMarker = priceTag;
          SetShowMarkerData((prevValue) => {
            return {
              ...prevValue,
              is_open: true,
              view_type: "personal_details",
              loading: true,
            };
          });

          let send_data = {
            delivery_person_data: del_marker,
          };
          get_person_details(send_data);
          get_person_sessions(send_data);
      })
      return marker;
    }} catch (error) {
      console.error("Error creating marker:", error);
    }
  }

  async function outletMarker(lat: number, lng: number, outlet:any) {
  console.log(outlet)
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");
    console.log("AdvancedMarkerElement:", AdvancedMarkerElement);
    if (!AdvancedMarkerElement) {
      console.error("AdvancedMarkerElement is not available.");
      return;
    }
    // Create custom content for the marker
    let del_partner =""
  

    const defaultColor = "#800080"; // Example fallback color
    const markerColor =  defaultColor;
    // color ||
    const priceTag = document.createElement("div");
    priceTag.className = "map-marker"; // Ensure you have the CSS defined
    // priceTag.id = `delivery-partner-${agent}`;
   // Customize text content here
   
    priceTag.style.backgroundColor = markerColor;
    priceTag.style.borderTop = markerColor;
     // Add text near the marker
  const textElement = document.createElement("div");
  textElement.textContent = `${outlet.brand_name}, ${outlet.outlet_name}`; // Set the text
  textElement.style.position = "absolute";
  textElement.style.top = "-20px"; // Position above the marker
  textElement.style.left = "50%";
  textElement.style.transform = "translateX(-50%)";
  textElement.style.maxWidth = "100px"; 
  textElement.style.fontSize = "12px";
  textElement.style.color = defaultColor;
  textElement.style.whiteSpace = "nowrap";
  priceTag.appendChild(textElement);

  // triangle.style.borderTop = `8px solid ${markerColor}`; // Dynamic color
 
    // Create and place the AdvancedMarkerElement
    try {
      const marker = new AdvancedMarkerElement({
        map,
        position: { lat, lng },
        content:priceTag,
      });
      console.log("Marker created successfully:", marker);

         marker.addListener("click", () => {
          // get_person_details(agent)

          setOutletData(outlet)
    
          SetShowMarkerData((prevValue) => {
            return {
              ...prevValue,
              is_open: true,
              view_type: "outlet_details",
              loading: true,
            };
          });

         
         
      })
   
  } catch (error) {
      console.error("Error creating marker:", error);
    }
  }

  async function get_orders(get_data: any) {
    if (!get_data.load_more && !get_data.refresh) {
      SetVisibleContent(false);
    }

    {
      get_data.refresh && setLoader(true);
    }
    
    let pass_data = {
      get: {
        order_status: 12,
        filter_count:"all",
        page: get_data.page_no,
        // search: search,
        sort: get_data.sort,
      
        demo_orders: get_data.demo? "1" : ""
      },
    };
    let response: any = await api("/orders/get", pass_data);
    if (response.status_code == 200) {
      setLoader(false);
      SetVisibleContent(true);

      if (response.response.hasOwnProperty("data")) {
      
          const newOrderData = response.response.data;
          SetOrderData(newOrderData);
      
          console.log(newOrderData)
            // Restaurant Marker
            newOrderData?.forEach(async(order_details_value, index) => {
              // Restaurant Marker
              console.log(order_details_value,index)
if(order_details_value.seller.hasOwnProperty("outlet")){
  // if(index<2){

    addMarker(Number(order_details_value.seller.outlet.lat), Number(order_details_value.seller.outlet.lng),  String(order_details_value.id),'↑ ',"#4285F4");
    addMarker(Number(order_details_value.customer.delivery_address.lat), Number(order_details_value.customer.delivery_address.lng),  String(order_details_value.id),'↓ ',"#4285F4");

          //  const seller_marker =  seller_id_marker 
           

          //  console.log(seller_marker)
           
          //  new google.maps.Marker({
          //       position: {
          //         lat: parseFloat(order_details_value.seller.outlet.lat),
          //         lng: parseFloat(order_details_value.seller.outlet.lng),
          //       },
          //       map: map, // Ensure `map` is already initialized
          //       label: {
          //         text: String(order_details_value.id), // The text you want to display
          //         color: "white", // Text color
          //         fontSize: "14px", // Text size
          //         fontWeight: "bold", // Text weight
          //       },
           
          //       icon: {
          //         url: "https://cdn3.1superapp.com/superapp/markerpinup.png",
          //         scaledSize: new google.maps.Size(44, 45),
          //         // scaledSize: new google.maps.Size(23, 35),

                 
          //       },
                
          //     });

              // const marker = new AdvancedMarkerElement({
              //   map,
              //   position: { lat: 37.42, lng: -122.1 },
              //   content: priceTag, // Pass the custom content here
              // });
              // google.maps.event.addListener(seller_marker, 'position_changed', function () {
              //   const label = seller_marker.getLabel();
              //   const offsetX = 0;   // Adjust horizontally if needed
              //   const offsetY = -35; // Adjust vertically, change value to increase/decrease space (e.g., -30 to -50 for more margin)
              //   seller_marker.setLabel({
              //     ...label,
              //     labelOrigin: new google.maps.Point(offsetX, offsetY), // Adjust the label's position relative to the marker
              //   });
              // });
              // seller_marker.addListener("click", () => {   
              //   console.log(order_details_value,index)        
              //   console.log("hi")   
              //   sellerInfoWindow.open(map, seller_marker);
              //   SetShowMarkerData((prevValue) => {
              //   return {
              //     ...prevValue,
              //     is_open: true,
              //     view_type: "order_details",
              //     loading: true,
              //     id:order_details_value.id
              //   };
              // });
              // // setOrderId("order_details_value.id")
              // })

              // const sellerInfoWindow = new google.maps.InfoWindow({
              //   // <div style="font-size: 14px; padding: 5px;">
              //   content: `
                
              //       ${order_details_value.id || "N/A"}<br />
               
              //   `,
              //   // </div>
              // });
              
              // sellerInfoWindow.open(map, seller_marker);
      

      
              // Customer Marker
            //  const customer_marker =   new google.maps.Marker({
            //     position: {
            //       lat: parseFloat(order_details_value.customer.delivery_address.lat),
            //       lng: parseFloat(order_details_value.customer.delivery_address.lng),
            //     },
            //     map: map,
            //     icon: {
            //       url: "https://cdn3.1superapp.com/superapp/markerpindown.png",
            //       scaledSize: new google.maps.Size(23, 35),
            //     },
            //     anchorPoint: new google.maps.Point(0, -29),
            //   });
            //   console.log(customer_marker)
            //   const customerInfoWindow = new google.maps.InfoWindow({
            //     content: `
            //   ${order_details_value.id || "N/A"}<br />
                
               
            //     `,
            //   });
              
              

            //   customerInfoWindow.open(map, customer_marker);

            //   customer_marker.addListener("click", () => {   
            //     console.log(order_details_value,index)        
            //     console.log("hi")   
            //     SetShowMarkerData((prevValue) => {
            //     return {
            //       ...prevValue,
            //       is_open: true,
            //       view_type: "order_details",
            //       loading: true,
            //       id: order_details_value.id
            //     };
            //   });
            //   setOrderId(order_details_value.id)})
              const directionsService = new google.maps.DirectionsService();
const directionsRenderer = new google.maps.DirectionsRenderer({
  suppressMarkers: true,
  polylineOptions: {
    strokeOpacity: 0, // Makes the polyline invisible
  },
});

// Initialize `DirectionsRenderer` to attach it to the map
directionsRenderer.setMap(map);


// Connect the two markers using Directions Service
const request = {
  origin: {
    lat: parseFloat(order_details_value.seller.outlet.lat),
    lng: parseFloat(order_details_value.seller.outlet.lng),
  },
  destination: {
    lat: parseFloat(order_details_value.customer.delivery_address.lat),
    lng: parseFloat(order_details_value.customer.delivery_address.lng),
  },
  travelMode: google.maps.TravelMode.DRIVING, // or WALKING, BICYCLING, etc.
};

directionsService.route(request, (result, status) => {
  if (status === google.maps.DirectionsStatus.OK) {
    directionsRenderer.setDirections(result); // Display the route on the map

      // Add a click listener to the blue line
      const directionsDisplay = directionsRenderer.getDirections();
      const route = directionsDisplay.routes[0].overview_path;

      // Create a polyline from the route to add a click event
      const clickableLine = new google.maps.Polyline({
        path: route,
        strokeColor:"#00008B",  // Blue color
        strokeOpacity: 1.0,    // Hide original polyline to make rendered route clickable
        strokeWeight: 10,      // Thick line for easier clicking
        map: map,
      });

      // Add a click listener to the clickable line
      clickableLine.addListener("click", () => {
        // sellerInfoWindow.open(map, seller_marker);
        console.log(order_details_value,index)        
        console.log("hi")   
        SetShowMarkerData((prevValue) => {
        return {
          ...prevValue,
          is_open: true,
          view_type: "order_details",
          loading: true,
          id:order_details_value.id
        };
      });
      });
  } else {
    console.error("Error fetching directions: ", status);
  }
});

              //  }
            }else{
              // if(index<2){
                if(order_details_value.hasOwnProperty("pickup_drop")){

                  addMarker(Number(order_details_value.customer?.pickup_address?.lat), Number(order_details_value.customer?.pickup_address?.lng),  String(order_details_value.id),'↑ ',"pick");
                  addMarker(Number(order_details_value.customer.delivery_address.lat), Number(order_details_value.customer.delivery_address.lng),  String(order_details_value.id),'↓ ',"pick");
                //   const seller_marker =      new google.maps.Marker({
                //   position: {
                //     lat: parseFloat(order_details_value.customer?.pickup_address?.lat),
                //     lng: parseFloat(order_details_value.customer?.pickup_address?.lng),
                //   },
                //   map: map, // Ensure `map` is already initialized
                //   icon: {
                //     url: "https://cdn3.1superapp.com/superapp/markerpinup.png",
                //     scaledSize: new google.maps.Size(23, 35),
                //   },
                //   anchorPoint: new google.maps.Point(0, -29),
                // });
                // const sellerInfoWindow = new google.maps.InfoWindow({
                //   content: `
                   
                //      ${order_details_value.id || "N/A"}<br />
                  
                //   `,

                // });
                
                // sellerInfoWindow.open(map, seller_marker);
                // seller_marker.addListener("click", () => {   
                //   sellerInfoWindow.open(map, seller_marker);
                //   console.log(order_details_value,index)        
                //   console.log("hi")   
                //   SetShowMarkerData((prevValue) => {
                //   return {
                //     ...prevValue,
                //     is_open: true,
                //     view_type: "order_details",
                //     loading: true,
                //     id:order_details_value.id
                //   };
                // });
                // // setOrderId("order_details_value.id")
                // })
        
                // // Customer Marker
                // const customer_marker =    new google.maps.Marker({
                //   position: {
                //     lat: parseFloat(order_details_value.customer?.delivery_address?.lat),
                //     lng: parseFloat(order_details_value.customer?.delivery_address?.lng),
                //   },
                //   map: map,
                //   icon: {
                //     url: "https://cdn3.1superapp.com/superapp/markerpindown.png",
                //     scaledSize: new google.maps.Size(23, 35),
                //   },
                //   anchorPoint: new google.maps.Point(0, -29),
                // });
                // const customerInfoWindow = new google.maps.InfoWindow({
                //   content: `
                  
                //       ${order_details_value.id || "N/A"}<br />
                  
                
                //   `,
                // });
                
                
  
                // customerInfoWindow.open(map, customer_marker);
                // customer_marker.addListener("click", () => {  
                // customerInfoWindow.open(map, customer_marker);

                //   console.log(order_details_value,index)        
                //   console.log("hi")   
                //   SetShowMarkerData((prevValue) => {
                //   return {
                //     ...prevValue,
                //     is_open: true,
                //     view_type: "order_details",
                //     loading: true,
                //     id:order_details_value.id
                //   };
                // });
                // // setOrderId("order_details_value.id")
                // })


                const directionsService = new google.maps.DirectionsService();
                const directionsRenderer = new google.maps.DirectionsRenderer({
                  suppressMarkers: true,
                  polylineOptions: {
                    strokeOpacity: 0, // Makes the polyline invisible
                  },
                });
                
                // Initialize `DirectionsRenderer` to attach it to the map
                directionsRenderer.setMap(map);
                
                
                // Connect the two markers using Directions Service
                const request = {
                  origin: {
                    lat: parseFloat(order_details_value.customer?.pickup_address?.lat),
                    lng: parseFloat(order_details_value.customer?.pickup_address?.lng),
                  },
                  destination: {
                    lat: parseFloat(order_details_value.customer.delivery_address.lat),
                    lng: parseFloat(order_details_value.customer.delivery_address.lng),
                  },
                  travelMode: google.maps.TravelMode.DRIVING, // or WALKING, BICYCLING, etc.
                };
                
                directionsService.route(request, (result, status) => {
                  if (status === google.maps.DirectionsStatus.OK) {
                    directionsRenderer.setDirections(result); // Display the route on the map
                    const directionsDisplay = directionsRenderer.getDirections();
                    const route = directionsDisplay.routes[0].overview_path;
              
                    // Create a polyline from the route to add a click event
                    const clickableLine = new google.maps.Polyline({
                      path: route,
                      strokeColor:"#00008B",  // Blue color
                      strokeOpacity: 1.0,    // Hide original polyline to make rendered route clickable
                      strokeWeight: 10,      // Thick line for easier clicking
                      map: map,
                    });
              
                    // Add a click listener to the clickable line
                    clickableLine.addListener("click", () => {
                      // sellerInfoWindow.open(map, seller_marker);
                      console.log(order_details_value,index)        
                      console.log("hi")   
                      SetShowMarkerData((prevValue) => {
                      return {
                        ...prevValue,
                        is_open: true,
                        view_type: "order_details",
                        loading: true,
                        id:order_details_value.id
                      };
                    });
                    });
                  } else {
                    console.error("Error fetching directions: ", status);
                  }
                });
          
           
                  }
                //  }

            }
            });
            // Connect the two markers with a blue line
          
        
      }

      // if (response.response.hasOwnProperty("next_page")) {
      //   SetNextPage(response.response.next_page);
      //   if(response.response.next_page){
      //   const newPageNo = get_data.page_no + 1
      //   SetPageNo(newPageNo);
      //   let pass_data = {
      //     order_status: 3,
      //     load_more:true,
     
      //     sort: "",
      //     page_no: newPageNo,
        
      //   };
      //   console.log(pass_data)
      //   console.log(page_no)
      //   get_orders(pass_data)

      //   }
      // }

     
    }
  }

  async function get_outlets() {
  
    
   
    let response: any = await api("/sellers/get_all_brand_outlets");
    if (response.status_code == 200) {

      console.log(response);
      console.log(map);


      if (response.response.hasOwnProperty("data") ) {
      console.log("h",isOn)
      if(isOn){
          const newOrderData = response.response.data;
          for (const city of newOrderData) {
            // Add the circle for this city to the map.

         outletMarker(parseFloat(city.lat),parseFloat(city.lon),city)

            // console.log(city.lat)
            // const circle = new google.maps.Circle({
            //   strokeColor: "#FF0000",
            //   strokeOpacity: 0.8,
            //   strokeWeight: 2,
            //   fillColor: "#FF0000",
            //   fillOpacity: 1,
            //   center: {lat:parseFloat(city.lat),lng:parseFloat(city.lon)},
            //   radius: 100,
            // });

            // circle.setMap(map);

          }
        }

            // Connect the two markers with a blue line
          
        
      }

     
    }
  }

  return (
    <div className="p-3">
      <div className="row py-0 px-3 sm-p-0">
        <div className="col-md-7 p-2 card map-height sm-p-0 sm-mt-5">
          <div id="map-test" className="map_class  p-1"></div>
        </div>
        <div className="col-md-5 md-block d-sm-none map_side pe-0 ">
          {/* go back header */}
          {show_marker_data.view_type != "main" &&
          show_marker_data.view_type != null ? (
            <div className="ps-md-2 mb-2">
              <div className="card p-3 mt-2 bg-fff  ">
                <div
                  className="cursor d-flex align-items-center"
                  onClick={() => {
                    const priceTags = document.getElementsByClassName("map-marker");

                    Array.from(priceTags).forEach((priceTag: Element) => {
                      if (priceTag instanceof HTMLElement) {
                        // Apply updates to each priceTag
                        priceTag.style.outline = "none"; // Example new outline
                        priceTag.style.zIndex = "auto"; // Reset zIndex or any other properties
                      }
                    });
                    
                    // window.history.go(-1);
                    SetShowMarkerData((preValue: any) => {
                      preValue.view_type = "main";
                      return { ...preValue };
                    });
                    Object.keys(marker).map((key) => {
                      let doc_var: any = document.querySelector(
                        'img[src="' + marker[key].pass_data.added_url + '"]'
                      );
                      if (doc_var != null) {
                        doc_var.style.transform =
                          "rotate(" +
                          marker[key].pass_data.delivery_person_data.location
                            .heading +
                          "deg) scale(1)";
                        doc_var.parentElement.style.overflow = "inherit";
                        // doc_var.style.transform = "scale(1)";
                      }
                    });
                  }}
                >
                  <Ionicons name="arrow-back-sharp" size={20} color="black" />
                  {show_marker_data.view_type == "personal_details"?"":
                       <p className="text-dark fs-7 ps-2 fw-bold">
                       Go to Orders
                     </p>
}
             
                </div>
              </div>
            </div>
          ) : null}

          <div className="ps-md-2">
            <div className="">
              {show_map_details_loader ? (
                <>
                  <div className="card p-3 mb-2">
                    <div className="mb-4 mt-2">
                      <Skeleton height={10} width={100} />
                    </div>
                    <div className="row pt-2">
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((key) => (
                        <div className="col-4 d-flex pb-4">
                          <Skeleton height={20} width={20} />
                          <div className="ps-2 pb-2">
                            <Skeleton height={10} width={100} />
                            <div className="pt-2">
                              <Skeleton height={10} width={30} />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="card p-3 mt-3">
                    <div className="row">
                      <div className="mb-4 mt-2">
                        <Skeleton height={10} width={80} />
                      </div>
                      <div className="mb-3">
                        <Skeleton height={30} width="100%" />
                      </div>
                      <div className="p-3">
                        <Skeleton
                          height={10}
                          width={80}
                          count={5}
                          className="mb-2"
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : show_marker_data.view_type == "main" ? (
                <>
                  <div className="card p-3  scrollY70">
                    <div className="d-flex">
                    <h6 className="mb-4 fw-bold mt-2">Orders</h6>
                    <div className="ms-auto">
                    <div className="d-flex"><div>Show Outlets</div>
                    <div class="form-check form-switch ms-2">
              <input class="form-check-input" type="checkbox" id="demoOrderSwitch1"
                checked={isOn}
                onChange={handleSwitchChange} />
              <label class="form-check-label" for="demoOrderSwitch1"></label>
            </div>
            </div>

            </div>
                    </div>
                    {/* <div className=""> */}
                    {/* <div className="row pt-2">
                      {Object.keys(all_data?.statistics)?.length > 0
                        ? Object.keys(all_data?.statistics)?.map((key) => (
                            <div className="col-4 d-flex pb-4">
                              {key == "total_agents" ? (
                                <img
                                  src={require("../../assets/icon/Delivery.png")}
                                  className="icon-w20"
                                />
                              ) : key == "total_agents_active_now" ? (
                                <img
                                  src={require("../../assets/icon/user.png")}
                                  className="icon-w20"
                                />
                              ) : key ==
                                "total_agents_active_now_non_blocked" ? (
                                <img
                                  src={require("../../assets/icon/confirm.png")}
                                  className="icon-w20"
                                />
                              ) : key ==
                                "total_agents_active_now_blocked_new" ? (
                                <img
                                  src={require("../../assets/icon/block-user.png")}
                                  className="icon-w20"
                                />
                              ) : key == "total_agents_active_today" ? (
                                <img
                                  src={require("../../assets/icon/user-profile.png")}
                                  className="icon-w20"
                                />
                              ) : key == "total_agents_free" ? (
                                <img
                                  src={require("../../assets/icon/employee.png")}
                                  className="icon-w20"
                                />
                              ) : key == "total_agents_busy" ? (
                                <img
                                  src={require("../../assets/icon/busy.png")}
                                  className="icon-w20"
                                />
                              ) : key == "total_agents_free_reserved" ? (
                                <img
                                  src={require("../../assets/icon/reserve.png")}
                                  className="icon-w20"
                                />
                              ) : key == "total_agents_busy_reserved" ? (
                                <img
                                  src={require("../../assets/icon/delivery-man.png")}
                                  className="icon-w20"
                                />
                              ) : key == "total_agents_active_30d" ? (
                                <img
                                  src={require("../../assets/icon/30day.png")}
                                  className="icon-w20"
                                />
                              ) : key == "total_agents_active_15d" ? (
                                <img
                                  src={require("../../assets/icon/15day.png")}
                                  className="icon-w20"
                                />
                              ) : key == "total_agents_active_1w" ? (
                                <img
                                  src={require("../../assets/icon/1week.png")}
                                  className="icon-w20"
                                />
                              ) : (
                                <img
                                  src={require("../../assets/icon/Delivery.png")}
                                  className="icon-w20"
                                />
                              )}

                              <div className="ps-2 pb-2">
                                <p className="h-35 ">
                                  {get_statistics_name(key)}
                                </p>
                                <p className="text-dark fw-bold mt-2">
                                  {all_data.statistics[key]}
                                </p>
                              </div>
                            </div>
                          ))
                        : null}
                    </div> */}
              <div>
                <OrderComponent/>
        
          </div>
                  </div>
                  <div className="card p-3 mt-3 scrollY70 ">
                    <div className="row">
                      <h6 className="mb-4 fw-bold mt-2">Delivery Person</h6>
                      <div className=" mb-3">
                        
                        <input
                          className="form-control radius w-100 p-2"
                          type="text"
                          name=""
                          id=""
                          placeholder="Search Delivery Person"
                          onChange={(e) => {
                            console.log(e.target.value)
                            let enter_value = e.target.value;
                            
                            clearTimeout(timeOutId); // Clear any existing timeout
                            if (enter_value == "") {
                              SetDPFilterData(all_data.delivery_partners);
                            } else {
                              timeOutId = setTimeout(() => {

                                let old_data = whole_dp_fiter_data.filter(

                                  (value: any) => {
                                    const searchStr =
                                      enter_value.toLowerCase();
                                    const nameMatches = value.name
                                      .toLowerCase()
                                      .includes(searchStr);

                                    return nameMatches;
                                  }
                                )

                                SetDPFilterData(old_data);


                                // SetDPFilterData((prevalue: any) => {
                                //   console.log(prevalue)
                                //   const filteredData = prevalue.filter(
                                //     (value: any) => {
                                //       const searchStr =
                                //         enter_value.toLowerCase();
                                //       const nameMatches = value.name
                                //         .toLowerCase()
                                //         .includes(searchStr);

                                //       return nameMatches;
                                //     }
                                //   );
                                //   // prevalue = filteredData;
                                //   // return [...prevalue];

                                //  return filteredData
                                // });

                                // SetDPFilterData(all_data.delivery_partners)
                              }, 500);
                              return () => clearTimeout(timeOutId);
                            }
                          }}
                        />
                      </div>
                      <div className="p-3">
                        {dp_fiter_data.map(
                          (ele: any) =>
                            ele.hasOwnProperty("logged_in") &&
                            ele.logged_in && (
                              <div className="d-flex align-items-center">
                                <div className="pb-3">
                                  <p
                                    className="text-black"
                                    onClick={() => {
console.log(ele.agent_id)
                                      const marker_dp = document.getElementById(`delivery-partner-${ele.agent_id}`);

                                      marker_dp.style.outline =  "3px solid #FFFFFF";
                                      marker_dp.style.zIndex = "1000"; 
                                      SetShowMarkerData((prevValue) => {
                                        return {
                                          ...prevValue,
                                          is_open: true,
                                          view_type: "personal_details",
                                          loading: true,
                                        };
                                      });

                                      let send_data = {
                                        delivery_person_data: ele,
                                      };
                                      get_person_details(send_data);
                                      get_person_sessions(send_data);
                                    }}
                                  >
                                    {ele.name}
                                  </p>
                                </div>
                                <div className="pb-3 ms-auto">
                                  {ele.active_orders == 0 && (
                                    <button
                                      className="btn-prime btn btn-sm px-3 py-1"
                                      onClick={() =>
                                        SetModalVariants((prevValue) => ({
                                          ...prevValue,
                                          is_open: true,
                                          data: {
                                            company_id: all_data.id,
                                            agent_id: ele.agent_id,
                                            all_data: all_data,
                                          },
                                        }))
                                      }
                                    >
                                      Stop
                                    </button>
                                  )}
                                </div>
                              </div>
                            )
                        )}
                      </div>
                      {dp_fiter_data.some(
                        (ele: any) =>
                          !ele.hasOwnProperty("logged_in") && !ele.logged_in
                      ) && <h6>Offline</h6>}
                      <div className="p-3">
                        {dp_fiter_data.map(
                          (ele: any) =>
                            !ele.hasOwnProperty("logged_in") &&
                            !ele.logged_in && (
                              <div className="d-flex align-items-center">
                                <div className="pb-3">
                                  <p
                                    className="text-black"
                                    onClick={() => {
                                      SetShowMarkerData((prevValue) => {
                                        return {
                                          ...prevValue,
                                          is_open: true,
                                          view_type: "personal_details",
                                          loading: true,
                                        };
                                      });

                                      let send_data = {
                                        delivery_person_data: ele,
                                      };
                                      get_person_details(send_data);
                                      get_person_sessions(send_data);
                                    }}
                                  >
                                    {ele.name}
                                  </p>
                                </div>
                              </div>
                            )
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>

            {show_marker_data.view_type == "personal_details" ? (
              <PersonDetailsPanel />
            ) : null}
              {show_marker_data.view_type == "outlet_details" ? (
             <div className="card p-4">
             <p>{outlet_data.brand_name }</p>
             <p>{outlet_data.outlet_name }</p>
             </div>
            ) : null}

{show_marker_data.view_type == "order_details" ? (
  <>
 {console.log(order_id == "")}
 {console.log(show_marker_data)}


              <OrderDetails1 id={show_marker_data.id} sendMessageToParent={handleChildData}/>
              </>
              // <div>hi</div>

            ) : null}
          </div>
        </div>
      </div>
      <ModalStop
        open={modal_variants.is_open}
        data={modal_variants.data}
        close={(data: any) => {
          SetModalVariants((prevValue: any) => {
            prevValue.is_open = false;
            prevValue.data = {};

            return { ...prevValue };
          });

          if (data.action == "save") {
            data.all_data.delivery_partners.map(
              (ele: any) =>
                ele.hasOwnProperty("logged_in") &&
                ele.logged_in &&
                marker[ele.agent_id]["marker"].setMap(null)
            );

            get_map_details();
          }
        }}
      />

      {width < 768 ? (
        <SwipeableBottomSheet
          overflowHeight={140}
          style={{ zIndex: 1300 }}
          open={bottom_sheet_open}
          onChange={(isOpen) => {
            if (isOpen !== bottom_sheet_open) {
              SetBottomSheetOpen(isOpen);
            }
          }}
          // onChange={() => {
          //   SetBottomSheetOpen((prevalue) => {
          //     if (prevalue) {
          //       prevalue = false;
          //     } else {
          //       prevalue = true;
          //     }
          //     return prevalue;
          //   });
          // }}
          onChange={() => {
            SetBottomSheetOpen((prevalue) => !prevalue);
          }}
          onScroll={handleScroll}
          onTouchEnd={handleTouchEnd}
          // disableSwipe={true}
        >
          <div className="map-swipe-sheet pb-3">
            <div className="bg-fff border-top-radius p-3 pb-1 center w-100">
              <div className="bottom-sheet-hr "></div>
              
            </div>
            
            {show_marker_data.view_type == "main" ? (
              <div className="">
                <div className="bg-fff p-2 pb-0 position-sticky-top">
                <div className="d-flex ms-4"><div>Show Outlets</div>
                    <div class="form-check form-switch ms-2">
              <input class="form-check-input" type="checkbox" id="demoOrderSwitch1"
                checked={isOn}
                onChange={handleSwitchChange} />
              <label class="form-check-label" for="demoOrderSwitch1"></label>
            </div>
            </div>
                  <Toaster
                    toastOptions={{
                      className: "",
                      style: {
                        zIndex: 1400,
                      },
                      duration: 150000,
                    }}
                  />
                  <AliceCarousel
                    mouseTracking
                    touchMoveDefaultEvents={false}
                    // paddingRight={10}
                    disableDotsControls
                    responsive={responsive}
                    disableButtonsControls
                    controlsStrategy="alternate"
                  >
                    {main_tab_data?.map((main_tab: any, index: any) => (
                      <div
                        onDragStart={(e) => {
                          e.preventDefault();
                        }}
                        key={index}
                      >
                        <div
                          className="text-center"
                          onClick={() => {
                            SetSelectedMainTab(main_tab);
                          }}
                        >
                          <div
                            className={
                              selected_main_tab.id == main_tab.id
                                ? "tab-active p-2 pt-3 cursor sm-px-0"
                                : "cursor p-2 pt-3 sm-px-0 border-bottom"
                            }
                          >
                            <div>
                              <p
                                className={
                                  selected_main_tab.id == main_tab.id
                                    ? "py-1 text-prime"
                                    : "py-1 text-black"
                                }
                              >
                                {main_tab.name}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </AliceCarousel>
                </div>
                <div className="p-2 pt-3 pb-5 ">
                  {selected_main_tab.name == "Orders" ? (
                    <div className=" px-2 pt-3 scrollY70">
                      {/* <div className=""> */}
                      <div className="ms-auto">
                

            </div>
                   <OrderComponent/>
                    </div>
                  ) : null}

                  {selected_main_tab.name == "Delivery Person" ? (
                    <div className="card px-2 pt-3 scrollY70">
                      <p className="text-dark fs-7 fw-bold">Delivery Person</p>

                      <div className=" pt-3">
                        <input
                          className="form-control radius w-100 p-2"
                          type="text"
                          name=""
                          id=""
                          placeholder="Search Delivery Person"
                          onChange={(e) => {
                            let enter_value = e.target.value;
                            if (enter_value == "") {
                              SetDPFilterData(all_data.delivery_partners);
                            } else {
                              const timeOutId = setTimeout(() => {
                                let old_data = whole_dp_fiter_data.filter(

                                  (value: any) => {
                                    const searchStr =
                                      enter_value.toLowerCase();
                                    const nameMatches = value.name
                                      .toLowerCase()
                                      .includes(searchStr);

                                    return nameMatches;
                                  }
                                )

                                SetDPFilterData(old_data);
                              }, 500);
                              return () => clearTimeout(timeOutId);
                            }
                          }}
                        />
                      </div>
                      <div className="px-1 py-3">
                        {dp_fiter_data.map((ele: any) =>
                          ele.hasOwnProperty("logged_in") && ele.logged_in && (
                            <div className="d-flex align-items-center">
                              <div className="pb-3">
                                <p
                                  className="text-black"
                                  onClick={() => {
                                    SetShowMarkerData((prevValue) => {
                                      return {
                                        ...prevValue,
                                        is_open: true,
                                        view_type: "personal_details",
                                        loading: true,
                                      };
                                    });

                                    let send_data = {
                                      delivery_person_data: ele,
                                    };

                                    let send_data1 = {
                                      company_data: all_data,
                                      delivery_person_data: ele,
                                      page_no: page_no,
                                      fetch_more: false,
                                    };
                                    get_person_details(send_data);
                                    get_person_sessions(send_data1);
                                  }}
                                >
                                  {ele.name}
                                </p>
                              </div>
                              <div className="pb-3 ms-auto">
                                {ele.active_orders == 0 && (
                                  <button
                                    className="btn-prime btn btn-sm px-3 py-1"
                                    onClick={() =>
                                      SetModalVariants((prevValue) => ({
                                        ...prevValue,
                                        is_open: true,
                                        data: {
                                          company_id: all_data.id,
                                          agent_id: ele.agent_id,
                                          all_data: all_data,
                                        },
                                      }))
                                    }
                                  >
                                    Stop
                                  </button>
                                )}
                              </div>
                            </div>
                          ) 
                        )}
                      </div>

                      {dp_fiter_data.some(
                        (ele: any) =>
                          !ele.hasOwnProperty("logged_in") && !ele.logged_in
                      ) && <h6>Offline</h6>}
                      <div className="p-3">
                        {dp_fiter_data.map(
                          (ele: any) =>
                            !ele.hasOwnProperty("logged_in") &&
                            !ele.logged_in && (
                              <div className="d-flex align-items-center">
                                <div className="pb-3">
                                  <p
                                    className="text-black"
                                    onClick={() => {
                                      let send_data = {
                                        delivery_person_data: ele,
                                      };

                                      let send_data1 = {
                                        company_data: all_data,
                                        delivery_person_data: ele,
                                        page_no: page_no,
                                        fetch_more: false,
                                      };
                                      get_person_details(send_data);
                                      get_person_sessions(send_data1);
                                    
                                    }}
                                  >
                                    {ele.name}
                                  </p>
                                </div>
                              </div>
                            )
                        )}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : (
              <div
                className="cursor d-flex align-items-center bg-fff px-3 pt-2"
                onClick={() => {
                  SetShowMarkerData((preValue: any) => {
                    preValue.view_type = "main";
                    return { ...preValue };
                  });
                  Object.keys(marker).map((key) => {
                    let doc_var: any = document.querySelector(
                      'img[src="' + marker[key].pass_data.added_url + '"]'
                    );
                    if (doc_var != null) {
                      doc_var.style.transform =
                        "rotate(" +
                        marker[key].pass_data.delivery_person_data.location
                          .heading +
                        "deg) scale(1)";
                      doc_var.parentElement.style.overflow = "inherit";
                    }
                  });
                }}
              >
                <Ionicons name="arrow-back-sharp" size={20} color="black" />
                {show_marker_data.view_type == "personal_details"?"":
                <p className="text-dark fs-7 ps-2 fw-bold">Go to Orders</p>
}
              </div>
            )}
            {show_marker_data.view_type == "personal_details" ? (
              <PersonDetailsPanel />
            ) : null}
                {show_marker_data.view_type == "outlet_details" ? (
             <div className="card p-4 mb-4">
             <div>{outlet_data.brand_name }</div>
             <div>{outlet_data.outlet_name }</div>
             </div>
            ) : null}

{show_marker_data.view_type == "order_details" ? (
  <>
 {console.log(order_id == "")}
 {console.log(show_marker_data)}


              <OrderDetails1 id={show_marker_data.id}  sendMessageToParent={handleChildData}/>
              </>
              // <div>hi</div>

            ) : null}
          </div>
          {/* <ModalUserDetails
open={user_modal.is_open}
id={user_modal.id}
close={(data: any) => {
  SetUserModal({
      is_open: false,
      id  :"",
  });
 }}/> */}
        </SwipeableBottomSheet>
      ) : null}
      
      <AvailableDeliveryPartner
        open={available_delivery_partner_modal.is_open}
        data={available_delivery_partner_modal.data}
      
        close={(data: any) => {

          SetAvailableDeliveryPartnerModal((prevalue) => {
            prevalue.is_open = false;
            return { ...prevalue };
          });
          
        if (data.action == "save") {
          console.log(data)
          let pass_data = {
            company_order_id: data.data.brand_id,
            delivery_partner_id: data.data.delivery_partner_id,
          };
          assign_delivery_partner(pass_data);
        }
       
        }}
      />

      <ModalUserDetails
open={user_modal.is_open}
id={user_modal.id}
close={(data: any) => {
  SetUserModal({
      is_open: false,
      id  :"",
  });
 }}/>
    </div>
  );
}
