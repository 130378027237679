import React from "react";
import ImageCom from "./ImageCom";
import useIntersectionObserver from "../../utils/Hooks/use-intersection-observer";

const ImageContainer = (props) => {
  const ref = React.useRef();
  const [isVisible, setIsVisible] = React.useState(false);

  //   useIntersectionObserver({
  //     target: ref,
  //     onIntersect: ([{ isIntersecting }], observerElement) => {
  //       if (isIntersecting) {
  //         setIsVisible(true)
  //         observerElement.unobserve(ref.current)
  //       }
  //     },
  //   })

  useIntersectionObserver({
    target: ref,
    onIntersect: ([{ isIntersecting }], observerElement) => {
      if (isIntersecting) {
        if (!isVisible) {
          props.onIsVisible();
          setIsVisible(true);
        }
        observerElement.unobserve(ref.current);
      }
    },
  });

  return (
    <div ref={ref}>
      {isVisible && (
        <ImageCom
          src={props.src}
          class_name={props.class_name}
          thumb={props.thumb}
          thumb_class_name = {props.thumb_class_name}
        />
      )}
    </div>
  );
};

export default ImageContainer;
