import React, { useState, useEffect } from "react";
import bootstrap from "../../assets/libraries/bootstrap/js/bootstrap";

import { api } from "../../utils/Api";

const initial_value = {
  agent_id: "",
  company_id: "",
  all_data: {},
};

export default function ModalStop(props: any) {
  const [save_data, SetSaveData] = useState(initial_value);
  const [close_data, SetCloseData] = useState({
    action: "close",
    agent_id: "",
    all_data: {},
  });
  const [myModal, setMyModal] = useState<any>(null); // State to store the modal instance

  useEffect(() => {
    let modalInstance = new bootstrap.Modal(
      document.getElementById("DelUser"),
      {}
    );
    setMyModal(modalInstance); // Save the modal instance to the state

    let myModal1 = document.getElementById("DelUser");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      const newValues = {
        agent_id: props.data.agent_id,
        company_id: props.data.company_id,
        all_data: props.data.all_data,
      };

      SetSaveData(newValues);

      myModal.show();
    }
  }, [props.open]);

  async function post_accept() {
    let pass_data = {
      post: {
        agent_id: save_data.agent_id,
        company_id: save_data.company_id,
      },
    };

    let response = await api(
      "/orders/stop_delivery_partner_working",
      pass_data
    );

    if (response.status_code === 200) {
      let item = close_data;
      item.action = "save";
      item.agent_id = save_data.agent_id;
      item.all_data = save_data.all_data;
      // setText("");
      SetSaveData(initial_value);
      SetCloseData(item);

      myModal.hide(); // Hide the modal using the modal instance
    }
  }

  return (
    <div
      className="modal fade"
      id="DelUser"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
      style={{ zIndex: 109067 }}
    >
      <div className="modal-dialog modal-dialog-centered ">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="ModalLabel">
              Stop Delivery Partner Working
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            Are you sure want to stop delivery partner working?
          </div>
          <div className="end p-3 pt-1">
            <button
              type="button"
              className="btn text-danger btn-sm btn-radius px-3"
              onClick={(e) => {
                post_accept();
              }}
            >
              Stop
            </button>
            <button
              type="button"
              className="btn btn-outline-secondary btn-sm btn-radius ms-3 px-3"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
