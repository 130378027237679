
import React, { useState, useEffect, useContext } from "react";
import { api } from "../../utils/Api";
import { format, render, cancel, register } from "timeago.js";
// import ModalDemoUser from "./ModalDemoUser";

import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ModalSettleEligible from "../Orders/ModalSettleEligible";
import ModalSettleApproval from "../Orders/ModalSettleApproval";
import ModalSettlePaid from "../Orders/ModalSettlePaid";
import { AppConfig } from "../../config";
import { Entypo} from "@expo/vector-icons";
import { useNavigate } from 'react-router-dom';
import { Link, useParams } from "react-router-dom";
import {

  FontAwesome,
} from "@expo/vector-icons";



// import ModalCod from "./ModalCod";
// import ModalRemarks from "./ModalRemarks";
// import ModalCart from "./ModalCart";



import {
    Ionicons,
    MaterialIcons,
    MaterialCommunityIcons,
  } from "@expo/vector-icons";
import { common } from "@material-ui/core/colors";

function DeliveryPartnerOrders() {
  const [order_data, SetOrderData] = useState([]);
  const [filter_data, setFilterData] = useState("");
  const [trans_id, setTransId] = useState("");

  const [page_no, SetPageNo] = useState(1);
  const [loader, setLoader] = useState(false);
  const [width, SetWidth] = useState(innerWidth);
  const [demo_modal, setDemoModal] = useState({
    is_open: false,
    data: {},
  });
  const [cod_modal, setCodModal] = useState({
    is_open: false,
    data: {},
  });
  const [remark_modal, setRemarkModal] = useState({
    is_open: false,
    data: {},
  });
  const [cart_modal, setCartModal] = useState({
    is_open: false,
    data: {},
  });
  const [next_page, SetNextPage] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [order_index, setOrderIndex] = useState(null);
  const [selectedApplication, setSelectedApplication] = useState("all");
  const [loading, setLoading] = useState(false); // State to track loading
  const [sort_val, SetSort] = useState("desc")
  const [visible_content, SetVisibleContent] = useState(false);
  const [show_details_loader, SetShowDetailsLoader] = useState(true);
  const [transaction_able, SetTransactionAble] = useState(false);
  const [transaction_details, SetTransactionDetails]: any = useState({});
  const [eligible,setEligible] = useState(false)
  const [price,setPrice] = useState("")
  const [id_value, SetIdValue] = useState(useParams());

  const [approve,setApprove] = useState(false)

  const [paid,setPaid] = useState(false)


  const [cod_eligible,setCodEligible] = useState(false)
  const [cod_price,setCodPrice] = useState("")

  const [cod_approve,setCodApprove] = useState(false)

  const [cod_paid,setCodPaid] = useState(false)
  const Spinner = () => <div className="loader-spinner"></div>;
  const [set_ele_modal, setSetEleModal] = useState({
    is_open: false,
    data: {},
  });

  const [set_approv_modal, setApprovModal] = useState({
    is_open: false,
    data: {},
  });
  const [paid_modal, setPaidModal] = useState({
    is_open: false,
    data: {},
  });

  const [pay,setPay] = useState(false)
  const [update,setUpdate] = useState(false)
  const [input_show,setInputShow] = useState(true)

  const [cod_pay,setCodPay] = useState(false)
  const [cod_update,setCodUpdate] = useState(false)
  const [cod_input_show,setCodInputShow] = useState(true)
  const navigate = useNavigate();
  useEffect(() => {
    let pass_data = {
      order_status: 1,
      seller_brand_id: "",
      seller_outlet_id: "",
      sort: "desc",
      filter:"all",
      page_no: page_no,
    };
    get_users(pass_data, filter_data);
  }, []);

  const sort = (val:any) =>{
   
    SetSort(val);
    SetPageNo(1)
    let pass_data = {
      order_status: 1,
      seller_brand_id: "",
      seller_outlet_id: "",
      sort: val,
      filter:selectedApplication,
      page_no: 1,
    };
    
    get_users(pass_data, filter_data);


  }

  const OrderSkeleton = () => {
    return (
    //   <div className="card p-3 cursor">
    //     <div className="row align-items-center">
    //       <div className="col-6 pb-3">
    //         <Skeleton height={10} width={50} />
    //       </div>
    //       <div className="col-6 text-end">
    //         <Skeleton height={30} width={100} />
    //       </div>
    //     </div>

    //     {/* <div className="row pt-2">
    //       <div className="col-6 pb-2">
    //         <Skeleton height={10} width={50} />
    //         <Skeleton height={10} width={120} />
    //       </div>
    //       <div className="col-6 pb-2 text-end">
    //         <Skeleton height={10} width={120} />
    //         <Skeleton height={10} width={100} />
    //       </div>
    //     </div> */}

    //     <div className="pb-3 pt-2">
    //       <hr className="text-gray m-0" />
    //     </div>

    //     <div className=" pb-2">
    //       <Skeleton height={10} width={120} />
    //     </div>

    //     <div className="py-2">
    //       <div className="d-flex pb-3">
    //         <Skeleton height={18} width={18} />
    //         <div className="ps-2">
    //           <Skeleton height={10} width={120} />
    //           <Skeleton height={10} width={180} />
    //         </div>
    //       </div>
    //       {/* <div className="d-flex pb-3">
    //         <Skeleton height={18} width={18} />
    //         <div className="ps-2">
    //           <Skeleton height={10} width={120} />
    //           <Skeleton height={10} width={180} />
    //         </div>
    //       </div> */}
    //     </div>
    //   </div>
    <div className="card p-12 skeleton-loader">
    <div className="d-flex border-bottom pb-2 mb-2">
      <div>
        <div className="pb-3 d-flex">
          <div className="pe-2">
            <Skeleton height={34} width={34} borderRadius="50%" />
          </div>
          <div>
            <Skeleton height={20} width={100} />
            <Skeleton height={16} width={80} />
          </div>
        </div>
        <div>
          <Skeleton height={16} width={60} />
        </div>
        <div>
          <Skeleton height={20} width={120} />
          <Skeleton height={20} width={120} />
        </div>
      </div>
      <div className="ms-auto text-end">
        <Skeleton height={16} width={80} />
        <Skeleton height={16} width={60} />
        <Skeleton height={16} width={100} />
      </div>
    </div>
    <div className="d-flex justify-content-end">
      <Skeleton height={34} width={150} />
    </div>
  </div>
    );
  };
  function enable(ele: any,i:any) {console.log("hi")
    // SetShowDetailsLoader(true);
    // const timer = setTimeout(() => {
    //   SetShowDetailsLoader(false);
    // }, 500);
    navigate("/delivery_partner_orders/" + ele.id);
    SetTransactionAble(true);
    setOrderIndex(i);
    setTransId(ele.id)
    SetTransactionDetails(ele);

    setPrice( ele.settlement_paid? ele.settlement_paid_amount :  ele.payment_amount)
setEligible(ele?.settlement_eligible)
setApprove(ele?.settlement_approved)



setPay(ele?.settlement_paid)
setPaid(ele?.settlement_paid)
setCodPrice( ele.cod_settlement_paid? ele.cod_settlement_paid_amount :  ele.collected_amount)
setCodEligible(ele.cod_settlement_eligible)
setCodApprove(ele.cod_settlement_approved)



setCodPay(ele?.cod_settlement_paid)
setCodPaid(ele.cod_settlement_paid)
    return () => clearTimeout(timer);
  }
  
  function disable() {
    SetShowDetailsLoader(false);
    SetTransactionAble(false);
  }
  function priceOnchange(e){
    setPrice(e.target.value)
  }
  function codpriceOnchange(e){
    setCodPrice(e.target.value)
  }
  async function get_users(get_data: any, search: any) {
    if (loading) return; // Prevent fetching if already loading
    setLoading(true);
    if (!get_data.load_more && !get_data.refresh) {
      SetVisibleContent(false);
    }
    {get_data.refresh &&
      setLoader(true)
    }

    
    let pass_data = {
      get: {
        // order_status: get_data.order_status,
        sort:get_data.sort,
        page: get_data.page_no,
        // search: search,
        filter: get_data.filter,
      },
    };
    let response: any = await api("/deliverypartners/get_orders", pass_data);
    if (response.status_code == 200) {
      setLoading(false)
console.log(response.response)
setLoader(false)
disable()

      SetVisibleContent(true);

      if (response.response.hasOwnProperty("data")) {
        if (get_data.load_more) {
          SetOrderData((prevValue) => {
            response?.response?.data?.map((ele: any) => {
              prevValue.push(ele);
            });
            return [...prevValue];
          });
        } else {
          SetOrderData(response.response.data);
        }
      }

      if (response.response.hasOwnProperty("next_page")) {
        SetNextPage(response.response.next_page);
        SetPageNo((prevalue) => {
          prevalue = prevalue + 1;
          return prevalue;
        });
      }

    //   if (response.response?.ordered_product_spec) {
    //     SetProductsDietType((prevValue: any) => {
    //       return { ...prevValue, ...response?.response?.ordered_product_spec };
    //     });
    //   }
    }
  }

  const handleInputChange = (e: any) => {
   
    const searchValue = e.target.value;
   
    // Clear previous timeout
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // Set a new timeout
    const newTimeoutId = setTimeout(() => {
      SetPageNo(1)
      // Call your API with the search value
      setFilterData(searchValue);
      let pass_data = {
        sort: "",
        page_no: 1,
        // order_status:order_status
      };
console.log("hi")
      get_users(pass_data, searchValue);
      disable();
    }, 1000); // 5 milliseconds delay
 
    setTimeoutId(newTimeoutId);
  };

    const handleCall = (e,fullMobile) => {
      e.stopPropagation()
      if (fullMobile) {
        window.location.href = `tel:${fullMobile}`;
      }
    };

    const handleEmail = (e,email) => {
      e.stopPropagation()
      if (email) {
        window.location.href = `mailto:${email}`;
      }
    };

    const DeliveryPartnerCard = () => {
      return (
        <div className="col-md-6 sm-mt-6 sm-pb-5">
        <InfiniteScroll
                    dataLength={order_data.length}
                    next={() => {
                      let pass_data = {
                        // order_status: order_status,
                        seller_brand_id: "",
                        seller_outlet_id: "",
                        sort: sort_val,
                        load_more: true,
        filter:selectedApplication,
                        page_no: page_no,
                      };
                      console.log("no")
                      get_users(pass_data, filter_data);
                    }}
                    hasMore={next_page}
                    loader={
                      <div className="center m-5">
                        <div className="lds-dual-ring"></div>
                        <p className=" small ps-2">Just a second ...</p>
                      </div>
                    }
                  >
                       {visible_content ? (
                      order_data.length != 0 ? (
        <div >
                          {order_data?.map((ord_ele: any, ord_index: any) => (
                            <div 
                            // className="col-md-4 col-12 mb-3"
                             key={ord_index}>
        
        <div className="card p-12 mb-2"      onClick={() => {
                                enable(ord_ele,ord_index);
                              
                                // SetSelectedDetails(i);
                              }}>
                                 {/* <Link to={"/orders/" + ord_ele.id}> */}
                                   
                                      <div className="d-flex border-bottom pb-2 mb-2">
                                        <div>
                                        <div className=" pb-3 d-flex">
                                  {ord_ele.agent.hasOwnProperty("profile_picture") && ord_ele.agent.profile_picture != null
                                   ? (
                                    <div className="pe-2">
                                      <img
                                        src={ord_ele.agent.profile_picture}
                                        alt="16.a1d4f3f3"
                                        className="rounded-float-start"
                                        height="34"
                                        width="34"
                                        style={{ borderRadius: "50px" }}
                                      ></img>
                                    </div>
                                  ) : (
                                    <div className="pe-2">
                                      <img
                                        src={require("../../assets/icon/avatar.png")}
                                        className="rounded-float-start"
                                        height="34"
                                        width="34"
                                      />
                                    </div>
                                  )}
                                  <div className="">
                                    <p className="text-dark fw-bold fs-7">
                                      {ord_ele?.agent.name}
                                    </p>
                                    <p>{ord_ele?.agent.user_name}</p>
                                  </div>
                                </div>
                                {/* {ord_ele.demo_order && */}
                                <div>
                                           
                                            <span className="fw-500">{ord_ele.company_name}</span>
                                          </div>
        {/* } */}
        
        <div className="my-2">
                                            <b>Customer :</b>
                                          </div>
        
                                          <div className=" pb-3 d-flex">
                                  {ord_ele.customer.hasOwnProperty("profile_picture") && ord_ele.customer.profile_picture != null
                                   ? (
                                    <div className="pe-2">
                                      <img
                                        src={ord_ele.customer.profile_picture}
                                        alt="16.a1d4f3f3"
                                        className="rounded-float-start"
                                        height="34"
                                        width="34"
                                        style={{ borderRadius: "50px" }}
                                      ></img>
                                    </div>
                                  ) : (
                                    <div className="pe-2">
                                      <img
                                        src={require("../../assets/icon/avatar.png")}
                                        className="rounded-float-start"
                                        height="34"
                                        width="34"
                                      />
                                    </div>
                                  )}
                                  <div className="">
                                    <p className="text-dark fw-bold fs-7 ">
                                      {ord_ele?.customer.name}
                                    </p>
                                    <p>{ord_ele?.customer.user_name}</p>
                                  </div>
                                </div>
                                          <div>
                                         
                                            <span className="fw-500">
                                            {ord_ele.full_mobile}
        
        
                                            </span>
                                          </div>
        
                                          
        
                                          {/* <div className="mb-2">
                                            <span
                                              className={
                                                ord_ele?.status ==  "Order Placed" || ord_ele?.status =="Seller Accepted"
                                                  ? "badge-1 me-2 badge-blue"
                                                  : ord_ele?.status == "Seller Rejected"
                                                  ? "badge-1 me-2 badge-red"
                                                  : ord_ele?.status == "Auto Cancelled"
                                                  ? "badge-1 me-2 badge-red-2"
                                                  : ord_ele?.status == "Completed"
                                                  ? "badge-1 me-2 badge-green"
                                                  : "badge-1 me-2 badge-red-2"
                                              }
                                            >
                                              {ord_ele?.status}
                                            </span>
                                            
                                            <span className="fs-12">
                                              {ord_ele?.delivery_type
         == "Hyper Local" && (
                                                <img
                                                  src={
                                                    AppConfig.CDN_Media_url +
                                                    "31b719f813a5801b0b9e68653ff6df89.svg"
                                                  }
                                                  className="img-fluid me-1"
                                                  width="14"
                                                />
                                              )}
                                              {ord_ele?.delivery_type}
                                            </span>
                                          </div>
        {ord_ele.dm_order &&
                                          <span
                                              className="badge-1 me-2 badge-blue mt-2"
                                              >Demo</span>
        }
                                          <div className="mb-1 fw-500">
                                            {ord_ele?.seller?.brand_name}
                                          </div>
                                          <div className="mb-1 fw-500">
                                          {ord_ele?.seller?.outlet?.name}
                                          </div>
                                         */}
                                        </div>
                                        <div className="ms-auto text-end">
                                          <div className="mb-1">
                                            {/* {format(ord_ele?.created_timeago, "en_US")} */}
                                          {ord_ele.time}
                                          </div>
                                          <div>
                                            ID:{" "}
                                            <span className="fw-500">{ord_ele?.id}</span>
                                          </div>
                                 <div className="ms-auto">        
                      {/* <FontAwesome
                        name="circle"
                        size={10}
                        color={ord_ele.status == "Rejected" ? "red" : "green"}
                      /> */}
                                          <p
                        className={
                          ord_ele.order_status == "Rejected"
                            ? "ps-2 pb-2 text-red"
                            : "ps-2  pb-2 text-green"
                        }
                      >
                        {ord_ele.order_status}
                      </p>
                      </div>
                      <div>
              
                                         {ord_ele?.sector}
                                          </div>
        
        
        
                                      </div>
        
                                      </div>
        
                                      {/* <ProductList
                                        products={ord_ele?.ordered_products}
                                        diet_type={products_diet_type}
                                      /> */}
                                     {/* { !ord_ele.demo_order && */}
        
                                  </div>
                                  </div>
        
                          ))}
        </div>
           ) : (
            <div className="center p-3">
              <div className="card p-3 w-100">
                <div className="w-100 text-center">
                  <img
                    src={require("../../assets/img/not-found.png")}
                    className="w-20"
                  />
                </div>
                <p className="center pt-3">No Orders data</p>
              </div>
            </div>
          )
        ) : (
        
            [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((ele: any) => (
                    <div className="card mb-2 p-3">
                      <div className="row">
                        <div className="col-6 pb-2">
                          <Skeleton height={10} width={80} />
                        </div>
                        <div className="col-6 text-end pb-2">
                          <Skeleton height={30} width={100} />
                        </div>
                        <div className="col-6 pb-2">
                          <Skeleton height={10} width={50} />
                        </div>
                        <div className="col-6 pb-2 text-end">
                          <Skeleton height={10} width={120} />
                        </div>
                        <div className="col-6 pb-3 d-flex">
                          <div className="pe-2">
                            <Skeleton height={34} width={34} borderRadius={50} />
                          </div>
                          <div className="">
                            <Skeleton height={10} width={100} count={2} />
                          </div>
                        </div>
                        <div className="col-6 end pb-3">
                          <Skeleton height={10} width={80} />
                        </div>
                        <div className="px-2 pb-3">
                          <hr className="text-gray m-0" />
                        </div>
                        <div className="col-3 pb-2">
                          <Skeleton height={10} width={80} />
                          <Skeleton height={10} width={40} />
                        </div>
        
                        <div className="col-3 pb-2">
                          <Skeleton height={10} width={80} />
                          <Skeleton height={10} width={40} />
                        </div>
        
                        <div className="col-3 pb-2">
                          <Skeleton height={10} width={80} />
                          <Skeleton height={10} width={40} />
                        </div>
        
                        <div className="col-3 pb-2">
                          <Skeleton height={10} width={80} />
                          <Skeleton height={10} width={40} />
                        </div>
                      </div>
                    </div>
                  ))
        
        )}
        </InfiniteScroll>
        </div>
      );
    };

    const handleApplicationChange = (event: any) => {
      console.log(event.target.value);
      SetPageNo(1);
    

      let pass_data = {
        order_status: 1,
        seller_brand_id: "",
        seller_outlet_id: "",
        sort: sort_val,
        filter:event.target.value,
        page_no: 1,
      };
      get_users(pass_data, filter_data);
      // ProductList(event.target.value, filter_data, send_data);
      // SetOutletId(event.target.value);
      setSelectedApplication(event.target.value);
      // let pass_data = {
      //   sort: "",
      //   page_no: 1,
      // };
      // setPayments([]);
      // SetTransactionAble(false)
      // get_categories(pass_data, filter_data,event.target.value);
    };
  

    function eligibleOnChange(val){
      if(val==true){
      // setEligible(false)
      // saveEligible(false)
  
      const set_data = {
        value: transaction_details.id,
        dm: false,
        from:"ship",
        det: "eligible"
      
        
      };
      setSetEleModal({
        is_open: true,
        data: set_data,
      });
      }else{
        setEligible(true)
        saveEligible(true)
  
      }
    }
    function approveOnChange(val){
      if(val==true){
      // setApprove(false)
      // saveApprove(false)
      const set_data = {
        value: transaction_details.id,
        dm: false,
        from:"ship",
        det: "approved"
      
        
      };
      setSetEleModal({
        is_open: true,
        data: set_data,
      });
  
      }else{
        setApprove(true)
      saveApprove(true)
  
  
      }
    }
  
    function paidOnChange(val){
      if(val==true){
        const set_data = {
          value: transaction_details.id,
          dm: false,
          from:"ship",
          det: "paid"
        
          
        };
        setSetEleModal({
          is_open: true,
          data: set_data,
        });
      }else{
        setPaid(true)
  
      }
    }
    async function saveEligible(val) {
      setEligible(val)
  
      let pass_data = {
      
        post: {
  
          status: val?"1":"2",
          order_id: id_value.id
      
        }
      };
      console.log(pass_data)
      console.log(id_value)

      // let data_res;
      // if(props.data.from =="ship"){
      //  data_res = await api("/deliverypartners/update_settlement_eligible", pass_data);
      // } else if(props.data.from =="cod"){
      //   data_res = await api("/deliverypartners/update_partner_cod_eligible_orders", pass_data);
      //  }else{
      let  data_res = await api("/deliverypartners/update_settlement_eligible", pass_data);
      // }
    console.log(data_res)
      if (data_res.status_code == 200) {
        SetTransactionDetails((prevalue: any) => {
          prevalue.settlement_eligible=data_res.response.settlement_eligible
          return { ...prevalue };
        });

      // let item = close_data;
      // console.log(item)
      // item.action = "save";
      // item.value =  data_res.response.data.cod_allowed_all_users;
      // if(props.data.from =="details"){
      // item.value =  data_res.response.data[0];
      // }
  
      // SetCloseData(item);
    
      // myModal.hide();
      }
    }
  
    async function saveApprove(val) {
      setApprove(val)
  console.log(transaction_details)
      let pass_data = {
      
        post: {
  
          status: val?"1":"2",
          order_id: id_value.id
      
        }
      };
      console.log(pass_data)
      console.log(trans_id)

      

      // let data_res;
      // if(props.data.from =="ship"){
      //  data_res = await api("/deliverypartners/update_settlement_eligible", pass_data);
      // } else if(props.data.from =="cod"){
      //   data_res = await api("/deliverypartners/update_partner_cod_eligible_orders", pass_data);
      //  }else{
      //  let data_res = await api("/orders/update_seller_settlement_eligible", pass_data);
  
       let data_res = await api("/deliverypartners/update_settlement_approved", pass_data);
      // }
    console.log(data_res)
      if (data_res.status_code == 200) {
        SetTransactionDetails((prevalue: any) => {
          prevalue.settlement_approved=data_res.response.settlement_approved
          return { ...prevalue };
        });
      // let item = close_data;
      // console.log(item)
      // item.action = "save";
      // item.value =  data_res.response.data.cod_allowed_all_users;
      // if(props.data.from =="details"){
      // item.value =  data_res.response.data[0];
      // }
  
      // SetCloseData(item);
    
      // myModal.hide();
      }
    }
  
    async function Save(val:any) {
      setPaid(val)
          let pass_data = {
          
            post: {
      
              status: val?"1":"2",
              order_id: id_value.id,
              amount: price
            }
          };
          console.log(pass_data)
          // let data_res;
          // if(props.data.from =="ship"){
          //  data_res = await api("/deliverypartners/update_settlement_paid", pass_data);
          // } else if(props.data.from =="cod"){
          //   data_res = await api("/deliverypartners/update_partner_cod_paid_orders", pass_data);
          //  }else{
           let data_res = await api("/deliverypartners/update_settlement_paid", pass_data);
          // }
        console.log(data_res)
          if (data_res.status_code == 200) {
       
            setUpdate(false)
            setInputShow(false)
            setPay(data_res.response.settlement_paid)
            SetTransactionDetails((prevalue: any) => {
              prevalue.settlement_paid_amount=data_res.response.settlement_paid_amount;
              prevalue.settlement_paid_time=data_res.response.settlement_paid_time;
              prevalue.settlement_paid=data_res.response.settlement_paid;


              return { ...prevalue };
            });
      
      
      
          }
        }

        function codeligibleOnChange(val){
          if(val==true){
          // setEligible(false)
          // saveEligible(false)
      
          const set_data = {
            value: transaction_details.id,
            dm: false,
            from:"cod",
            det: "eligible"
          
            
          };
          setSetEleModal({
            is_open: true,
            data: set_data,
          });
          }else{
            setCodEligible(true);
            codsaveEligible(true)
      
          }
        }
        function codapproveOnChange(val){
          if(val==true){
          // setApprove(false)
          // saveApprove(false)
          const set_data = {
            value: transaction_details.id,
            dm: false,
            from:"cod",
            det: "approved"
          
            
          };
          setSetEleModal({
            is_open: true,
            data: set_data,
          });
      
          }else{
            setCodApprove(true)
          codsaveApprove(true)
      
      
          }
        }
      
        function codpaidOnChange(val){
          if(val==true){
            const set_data = {
              value: transaction_details.id,
              dm: false,
              from:"cod",
              det: "paid"
            
              
            };
            setSetEleModal({
              is_open: true,
              data: set_data,
            });
          }else{
            setCodPaid(true)
      
          }
        }
        async function codsaveEligible(val) {
          setCodEligible(val)
      
          let pass_data = {
          
            post: {
      
              status: val?"1":"2",
              order_id: id_value.id
          
            }
          };
          console.log(pass_data)
          console.log(id_value)
    
          // let data_res;
          // if(props.data.from =="ship"){
          //  data_res = await api("/deliverypartners/update_settlement_eligible", pass_data);
          // } else if(props.data.from =="cod"){
          //   data_res = await api("/deliverypartners/update_partner_cod_eligible_orders", pass_data);
          //  }else{
          // let  data_res = await api("/deliverypartners/update_settlement_eligible", pass_data);

         let data_res = await api("/deliverypartners/update_partner_cod_eligible_orders", pass_data);
          // }
        console.log(data_res)
          if (data_res.status_code == 200) {
            SetTransactionDetails((prevalue: any) => {
              prevalue.settlement_eligible=data_res.response.settlement_eligible
              return { ...prevalue };
            });
    
          // let item = close_data;
          // console.log(item)
          // item.action = "save";
          // item.value =  data_res.response.data.cod_allowed_all_users;
          // if(props.data.from =="details"){
          // item.value =  data_res.response.data[0];
          // }
      
          // SetCloseData(item);
        
          // myModal.hide();
          }
        }
      
        async function codsaveApprove(val) {
          setCodApprove(val)
      console.log(transaction_details)
          let pass_data = {
          
            post: {
      
              status: val?"1":"2",
              order_id: id_value.id
          
            }
          };
          console.log(pass_data)
          console.log(trans_id)
    
          
    
          // let data_res;
          // if(props.data.from =="ship"){
          //  data_res = await api("/deliverypartners/update_settlement_eligible", pass_data);
          // } else if(props.data.from =="cod"){
          //   data_res = await api("/deliverypartners/update_partner_cod_eligible_orders", pass_data);
          //  }else{
          //  let data_res = await api("/orders/update_seller_settlement_eligible", pass_data);
      
           let data_res = await api("/deliverypartners/update_partner_cod_approved_orders", pass_data);
          // }
        console.log(data_res)
          if (data_res.status_code == 200) {
            SetTransactionDetails((prevalue: any) => {
              prevalue.cod_settlement_approved=data_res.response.cod_settlement_approved
              return { ...prevalue };
            });
          // let item = close_data;
          // console.log(item)
          // item.action = "save";
          // item.value =  data_res.response.data.cod_allowed_all_users;
          // if(props.data.from =="details"){
          // item.value =  data_res.response.data[0];
          // }
      
          // SetCloseData(item);
        
          // myModal.hide();
          }
        }
      
        async function codSave(val:any) {
          setCodPaid(val)
              let pass_data = {
              
                post: {
          
                  status: val?"1":"2",
                  order_id: id_value.id,
                  amount: cod_price
                }
              };
              console.log(pass_data)
              // let data_res;
              // if(props.data.from =="ship"){
              //  data_res = await api("/deliverypartners/update_settlement_paid", pass_data);
              // } else if(props.data.from =="cod"){
              //   data_res = await api("/deliverypartners/update_partner_cod_paid_orders", pass_data);
              //  }else{
               let data_res = await api("/deliverypartners/update_partner_cod_paid_orders", pass_data);
              // }
            console.log(data_res)
              if (data_res.status_code == 200) {
           
                setCodUpdate(false)
                setCodInputShow(false)
                setCodPay(data_res.response.cod_settlement_paid)
                SetTransactionDetails((prevalue: any) => {
                  prevalue.cod_settlement_paid_amount=data_res.response.cod_settlement_paid_amount;
                  prevalue.cod_settlement_paid_time=data_res.response.cod_settlement_paid_time;
                  prevalue.cod_settlement_paid=data_res.response.cod_settlement_paid;
    
    
                  return { ...prevalue };
                });
          
          
          
              }
            }


  return (
    <>
    <div className="head-bar ">
      <div className="d-flex">
    <div className="col-md-6">
      <h5 className="  mb-2">
      Delivery Partner Orders</h5>
    </div>
    <div className="ms-auto">
      <div className="d-flex">
    <div className="form-group d-flex align-items-center ps-0">
         
         {/* {console.log(appliction)} */}
         <select
           data-show-content="true"
           className="form-control product-timing-location"
           id="exampleFormControlSelect1"
           value={selectedApplication}
           onChange={handleApplicationChange}
         >
           {/* {outlets?.map((ele, i) => ( */}
           <option value="all">All</option>
  <option value="cod_not_eligible">Cod Not Eligible</option>
  <option value="cod_not_approved">Cod Not Approved</option>
  <option value="cod_unpaid">Cod Unpaid</option>
  <option value="cod_partially_paid">Cod Partially Paid</option>
  <option value="cod_paid">Cod Paid</option>
  <option value="cod_unpaid_partially_paid">Cod Unpaid Partially Paid</option>
  <option value="settlement_not_eligible">Settlement Not Eligible</option>
  <option value="settlement_not_approved">Settlement Not Approved</option>
  <option value="settlement_unpaid">Settlement Unpaid</option>
  <option value="settlement_partially_paid">Settlement Partially Paid</option>
  <option value="settlement_paid">Settlement Paid</option>
  <option value="settlement_unpaid_partially_paid">Settlement Unpaid Partially Paid</option>
           {/* ))} */}
         </select>
         <div className=" ms-auto pe-2">
           <img
             src={
               AppConfig.CDN_Media_url +
               "6b8041f390f1ce51def0c7659b39a408.svg"
             }
             className="w-10px"
           />
         </div>
       </div>

         <div className="dropdown ms-2">
            <button
              className="btn btn-white dropdown-toggle d-flex align-items-cetner"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src={require("../../assets/icon/filter.png")}
                className="icon-14px"
              />
          
            </button>
        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">

        <li>
                <a className="dropdown-item" href="#">
                  <div
                    onClick={() => sort("desc") }
                  >
                    <p className="mb-0 p-2">Newest</p>
                  </div>
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  <div
                    onClick={() => 
                      sort("asc")
                    }
                  >
                    <p className="mb-0 p-2">Oldest</p>
                  </div>
                </a>
              </li>
             
          
            </ul>
      </div>
      </div>
        {/* <div className="d-flex">
      <div className="input-icons form-group p-2">
        <input
          type="text"
          className="form-control search-bar p-0"
          placeholder="Search here ..."
          onChange={handleInputChange}
        />
        <div className="cursor px-2 border-left">
          <Ionicons name="md-search" size={16} color="#ccc" />
        </div>
      </div>
      <div className="dropdown">
            <button
              className="btn btn-white dropdown-toggle d-flex align-items-cetner"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src={require("../../assets/icon/filter.png")}
                className="icon-14px"
              />
          
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <a className="dropdown-item" href="#">
                  <div
                    onClick={() => 
                      sort("asc")
                    }
                  >
                    <p className="mb-0 p-2">Oldest</p>
                  </div>
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  <div
                    onClick={() => sort("desc") }
                  >
                    <p className="mb-0 p-2">Newest</p>
                  </div>
                </a>
              </li>
          
            </ul>
          </div>

          {loader?
         <button  className="btn btn-white dropdown-toggle d-flex align-items-cetner">
        <Spinner />
        </button>:
          <button  className="btn btn-white dropdown-toggle d-flex align-items-cetner"
           onClick={()=>{
            let pass_data = {
              order_status: 1,
              seller_brand_id: "",
              seller_outlet_id: "",
              sort: "",
              page_no: 1,
              refresh:true,
            };
            SetPageNo(1)
           
            get_users(pass_data, filter_data);

           }}> 
           <img
                src={require("../../assets/icon/refresh.png")}
                className="icon-20px"
              /></button>
          }
          </div> */}
    </div>

    </div>
  </div>

<div className="px-3 mt-2 sm-pt-4">

<div className="row">



{width < 768 && transaction_able == false ? (
            <DeliveryPartnerCard />
          ) : width > 768 ? (
            <DeliveryPartnerCard />
          ) : null}

{transaction_able ? (
            <div className="col-md-6  sm-pb-5 sm-mt-6">
              <div className="overflowY-90vh pb-5 position-sticky-top-50px">
                {/* {show_details_loader ? (
                  <div className="card bg-fff p-3 mb-3">
                    <div className="mb-4">
                      <Skeleton height={10} width={120} />
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-4 pb-3">
                        <Skeleton height={10} width={80} />
                        <div className="pt-2 d-flex">
                          <div className="pe-2">
                            <Skeleton
                              height={34}
                              width={34}
                              borderRadius={50}
                            />
                          </div>
                          <div className="">
                            <Skeleton height={10} width={100} count={2} />
                          </div>
                        </div>
                      </div>
                      <div className="col-6 col-md-4 pb-3">
                        <div className="pb-2">
                          <Skeleton height={10} width={80} />
                        </div>
                        <Skeleton height={10} width={30} />
                      </div>
                      <div className="col-6 col-md-4 pb-3">
                        <div className="pb-2">
                          <Skeleton height={10} width={80} />
                        </div>
                        <Skeleton height={10} width={30} />
                      </div>
                      <div className="col-6 col-md-4 pb-3">
                        <div className="pb-2">
                          <Skeleton height={10} width={80} />
                        </div>
                        <Skeleton height={10} width={30} />
                      </div>
                      <div className="col-6 col-md-4 pb-3">
                        <div className="pb-2">
                          <Skeleton height={10} width={80} />
                        </div>
                        <Skeleton height={10} width={30} />
                      </div>
                      <div className="col-6 col-md-4 pb-3">
                        <div className="pb-2">
                          <Skeleton height={10} width={80} />
                        </div>
                        <Skeleton height={10} width={30} />
                      </div>
                      <div className="col-6 col-md-4 pb-3">
                        <div className="pb-2">
                          <Skeleton height={10} width={80} />
                        </div>
                        <Skeleton height={10} width={30} />
                      </div>
                      <div className="col-6 col-md-4 pb-3">
                        <div className="pb-2">
                          <Skeleton height={10} width={80} />
                        </div>
                        <Skeleton height={10} width={30} />
                      </div>
                      <div className="col-6 col-md-4 pb-3">
                        <div className="pb-2">
                          <Skeleton height={10} width={80} />
                        </div>
                        <Skeleton height={10} width={30} />
                      </div>
                      <div className="col-6 col-md-4 pb-3">
                        <div className="pb-2">
                          <Skeleton height={10} width={80} />
                        </div>
                        <Skeleton height={10} width={30} />
                      </div>
                      <div className="col-6 col-md-4 pb-3">
                        <div className="pb-2">
                          <Skeleton height={10} width={80} />
                        </div>
                        <Skeleton height={10} width={30} />
                      </div>
                      <div className="col-6 col-md-4 pb-3">
                        <div className="pb-2">
                          <Skeleton height={10} width={80} />
                        </div>
                        <Skeleton height={10} width={30} />
                      </div>
                      <div className="col-6 col-md-4 pb-3">
                        <div className="pb-2">
                          <Skeleton height={10} width={80} />
                        </div>
                        <Skeleton height={10} width={30} />
                      </div>
                    </div>
                    <div className="border-top pt-3 mt-2">
                      <div className="mb-4">
                        <Skeleton height={10} width={120} />
                      </div>
                      <div className="row">
                        <div className="col-6 col-md-4 pb-3">
                          <div className="pb-2">
                            <Skeleton height={10} width={80} />
                          </div>
                          <Skeleton height={10} width={30} />
                        </div>
                        <div className="col-6 col-md-4 pb-3">
                          <div className="pb-2">
                            <Skeleton height={10} width={80} />
                          </div>
                          <Skeleton height={10} width={30} />
                        </div>
                        <div className="col-6 col-md-4 pb-3">
                          <div className="pb-2">
                            <Skeleton height={10} width={80} />
                          </div>
                          <Skeleton height={10} width={30} />
                        </div>
                        <div className="col-6 col-md-4 pb-3">
                          <div className="pb-2">
                            <Skeleton height={10} width={80} />
                          </div>
                          <Skeleton height={10} width={30} />
                        </div>
                        <div className="col-6 col-md-4 pb-3">
                          <div className="pb-2">
                            <Skeleton height={10} width={80} />
                          </div>
                          <Skeleton height={10} width={30} />
                        </div>
                        <div className="col-6 col-md-4 pb-3">
                          <div className="pb-2">
                            <Skeleton height={10} width={80} />
                          </div>
                          <Skeleton height={10} width={30} />
                        </div>
                        <div className="col-12 pb-3">
                          <div className="pb-2">
                            <Skeleton height={10} width={80} />
                          </div>
                          <Skeleton height={10} width="100%" count={2} />
                        </div>

                        <div className="col-6 col-md-4 pb-3">
                          <div className="pb-2">
                            <Skeleton height={10} width={80} />
                          </div>
                          <Skeleton height={10} width={30} />
                        </div>
                        <div className="col-6 col-md-4 pb-3">
                          <div className="pb-2">
                            <Skeleton height={10} width={80} />
                          </div>
                          <Skeleton height={10} width={30} />
                        </div>
                        <div className="col-6 col-md-4 pb-3">
                          <div className="pb-2">
                            <Skeleton height={10} width={80} />
                          </div>
                          <Skeleton height={10} width={30} />
                        </div>
                      </div>
                    </div>
                    <div className="border-top pt-3 mt-2">
                      <div className="mb-4">
                        <Skeleton height={10} width={120} />
                      </div>
                      <div className="row">
                        <div className="col-6 col-md-4 pb-3">
                          <div className="pb-2">
                            <Skeleton height={10} width={80} />
                          </div>
                          <Skeleton height={10} width={30} />
                        </div>
                        <div className="col-6 col-md-4 pb-3">
                          <div className="pb-2">
                            <Skeleton height={10} width={80} />
                          </div>
                          <Skeleton height={10} width={30} />
                        </div>
                        <div className="col-6 col-md-4 pb-3">
                          <div className="pb-2">
                            <Skeleton height={10} width={80} />
                          </div>
                          <Skeleton height={10} width={30} />
                        </div>
                        <div className="col-6 col-md-4 pb-3">
                          <div className="pb-2">
                            <Skeleton height={10} width={80} />
                          </div>
                          <Skeleton height={10} width={30} />
                        </div>
                        <div className="col-12 pb-3">
                          <div className="pb-2">
                            <Skeleton height={10} width={80} />
                          </div>
                          <Skeleton height={10} width="100%" count={2} />
                        </div>
                        <div className="col-12 pb-3">
                          <div className="pb-2">
                            <Skeleton height={10} width={80} />
                          </div>
                          <Skeleton height={10} width="100%" count={2} />
                        </div>
                      </div>
                      <div className="border-top pt-3 mt-2">
                        <div className="mb-4">
                          <Skeleton height={10} width={120} />
                        </div>
                        <div className="row">
                          <div className="col-6 col-md-4 pb-3">
                            <div className="pb-2">
                              <Skeleton height={10} width={80} />
                            </div>
                            <Skeleton height={10} width={30} />
                          </div>
                          <div className="col-6 col-md-4 pb-3">
                            <div className="pb-2">
                              <Skeleton height={10} width={80} />
                            </div>
                            <Skeleton height={10} width={30} />
                          </div>
                          <div className="col-6 col-md-4 pb-3">
                            <div className="pb-2">
                              <Skeleton height={10} width={80} />
                            </div>
                            <Skeleton height={10} width={30} />
                          </div>
                          <div className="col-6 col-md-4 pb-3">
                            <div className="pb-2">
                              <Skeleton height={10} width={80} />
                            </div>
                            <Skeleton height={10} width={30} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : ( */}
                 <div className="card p-12 mb-2">
                         {/* <Link to={"/orders/" + ord_ele.id}> */}
                         <div className="d-flex mb-2">
                         <div
                        className="cursor pe-3"
                        onClick={() => {
                          disable();
                        }}
                      >
                        <MaterialCommunityIcons
                          name="arrow-left"
                          size={20}
                          color="black"
                        />
                      </div>
                      <h6 className="mb-0">Order Details</h6>
                    </div>
                              <div className="d-flex border-bottom pb-2 mb-2">
                                <div>
                                <div className=" pb-3 d-flex">
                          {transaction_details.agent.hasOwnProperty("profile_picture") && transaction_details.agent.profile_picture != null
                           ? (
                            <div className="pe-2">
                              <img
                                src={transaction_details.agent.profile_picture}
                                alt="16.a1d4f3f3"
                                className="rounded-float-start"
                                height="34"
                                width="34"
                                style={{ borderRadius: "50px" }}
                              ></img>
                            </div>
                          ) : (
                            <div className="pe-2">
                              <img
                                src={require("../../assets/icon/avatar.png")}
                                className="rounded-float-start"
                                height="34"
                                width="34"
                              />
                            </div>
                          )}
                          <div className="">
                            <p className="text-dark fw-bold fs-7">
                              {transaction_details?.agent.name}
                            </p>
                            <p>{transaction_details?.agent.user_name}</p>
                          </div>
                        </div>
                    
{/* } */}

<div>
                                   
                                   <span className="fw-500">{transaction_details.company_name}</span>
                                 </div>
{/* } */}

<div className="my-2">
                                   <b>Customer :</b>
                                 </div>

                                 <div className=" pb-3 d-flex">
                         {transaction_details.customer.hasOwnProperty("profile_picture") && transaction_details.customer.profile_picture != null
                          ? (
                           <div className="pe-2">
                             <img
                               src={transaction_details.customer.profile_picture}
                               alt="16.a1d4f3f3"
                               className="rounded-float-start"
                               height="34"
                               width="34"
                               style={{ borderRadius: "50px" }}
                             ></img>
                           </div>
                         ) : (
                           <div className="pe-2">
                             <img
                               src={require("../../assets/icon/avatar.png")}
                               className="rounded-float-start"
                               height="34"
                               width="34"
                             />
                           </div>
                         )}
                         <div className="">
                           <p className="text-dark fw-bold fs-7 ">
                             {transaction_details?.customer.name}
                           </p>
                           <p>{transaction_details?.customer.user_name}</p>
                         </div>
                       </div>
                                  {/* <div className="mb-2">
                                    <span
                                      className={
                                        ord_ele?.status ==  "Order Placed" || ord_ele?.status =="Seller Accepted"
                                          ? "badge-1 me-2 badge-blue"
                                          : ord_ele?.status == "Seller Rejected"
                                          ? "badge-1 me-2 badge-red"
                                          : ord_ele?.status == "Auto Cancelled"
                                          ? "badge-1 me-2 badge-red-2"
                                          : ord_ele?.status == "Completed"
                                          ? "badge-1 me-2 badge-green"
                                          : "badge-1 me-2 badge-red-2"
                                      }
                                    >
                                      {ord_ele?.status}
                                    </span>
                                    
                                    <span className="fs-12">
                                      {ord_ele?.delivery_type
 == "Hyper Local" && (
                                        <img
                                          src={
                                            AppConfig.CDN_Media_url +
                                            "31b719f813a5801b0b9e68653ff6df89.svg"
                                          }
                                          className="img-fluid me-1"
                                          width="14"
                                        />
                                      )}
                                      {ord_ele?.delivery_type}
                                    </span>
                                  </div>
{ord_ele.dm_order &&
                                  <span
                                      className="badge-1 me-2 badge-blue mt-2"
                                      >Demo</span>
}
                                  <div className="mb-1 fw-500">
                                    {ord_ele?.seller?.brand_name}
                                  </div>
                                  <div className="mb-1 fw-500">
                                  {ord_ele?.seller?.outlet?.name}
                                  </div>
                                 */}
                                </div>
                                <div className="ms-auto text-end">
                                  <div className="mb-1">
                                    {transaction_details?.time}
                                  
                                  </div>
                                  <div>
                                    ID:{" "}
                                    <span className="fw-500">{transaction_details?.id}</span>
                                  </div>
                                  <div>
                                 {transaction_details?.sector}
                                  </div>

                                  {/* <div className="d-flex end">
                                  <div className="call-btn m-2" onClick={(e)=>handleCall(e,transaction_details?.full_mobile)} style={{ cursor: 'pointer' }}>
        <Ionicons
          name="md-call"
          size={18}
          color="#A80032"
        />
      </div>
      {transaction_details?.email &&
         <div
          className="call-btn"
           onClick={(e)=>handleEmail(e,transaction_details?.email)} 
           style={{ cursor: 'pointer' }}>
        <Ionicons
          name="md-mail"
          size={18}
          color="#A80032"
        />
                              </div>
}
                              </div> */}
                                </div>
                              </div>

                              <div className="d-flex border-bottom pb-2 mb-2">
                           <div>

                          
                            <div>{transaction_details?.full_mobile}</div>
                            <div>{transaction_details?.gender}</div>
                            <div>{transaction_details?.address}</div>
                            <div>{transaction_details?.pincode}</div>
                            {transaction_details?.blood_group &&
                            <div>
                            Blood Group:{" "}
                                    <span className="fw-500">{transaction_details?.blood_group}</span>
                                  </div>
}
                                  {transaction_details?.default_app &&
                                  <div>
                                  Default App:{" "}
                                    <span className="fw-500">{transaction_details?.default_app}</span>
                                  </div>
}
                                  {transaction_details?.dob &&
                                  <div>
                                  DOB:{" "}
                                    <span className="fw-500">{transaction_details?.dob}</span>
                                  </div>
}
                                  {transaction_details?.email &&
                     
                                  <div>
                                  Email:{" "}
                                    <span className="fw-500">{transaction_details?.email}</span>
                                  </div>
}
                                  {transaction_details?.height &&
                                  <div>
                                  Height:{" "}
                                    <span className="fw-500">{transaction_details?.height}</span>
                                  </div>
}
                                
                                  {transaction_details?.weight &&
                                    <div>
                                  Weight:{" "}
                                    <span className="fw-500">{transaction_details?.weight}</span>
                                  </div>
}

{/* {transaction_details?.ecommerce_remarks.length>0 &&
                                    <div>
                                <b> Ecommerce Remarks:{" "}</b> 
                                <ul>
                                    <div>{transaction_details?.ecommerce_remarks.map((ele,i)=>(

                                      <li>{ele.message}<p>{ele.create_time}</p></li>
                                    ))}</div>
                                    </ul>
                                  </div>
}

{transaction_details?.ecommerce_cart_response.length>0 &&
                                    <div>
                                <b> Ecommerce Cart Response:{" "}</b> 
                                <ul>
                                    <div>{transaction_details?.ecommerce_cart_response.map((ele,i)=>(

                                      <li>{ele.response}<p>{ele.notes}</p><p>{ele.create_time}</p></li>
                                    ))}</div>
                                    </ul>
                                  </div>
} */}
  
                      

                           
                      






                            
                           </div>
                                <div className="ms-auto text-end">
                                
                                  <div>
                                    Delivery Partner Settlement Amount:{" "}
                                    <span className="fw-500">&#8377; {transaction_details?.payment_amount}</span>
                                  </div>
                                  <div>
                                    COD Settlement Amount:{" "}
                                    <span className="fw-500">&#8377; {transaction_details?.cod_settlement_paid_amount}</span>
                                  </div>
                                </div>
                              </div>
                              <div className="py-1">
                                {transaction_details?.payment_type_id =="2" &&
              <div className="row px-0">
                <div className="fw-bold text-black mb-3 fs-7 ps-0 ms-2">
                  COD Handover
                </div>
                {/* <div className="timeline-1">
                  <div className="d-flex">
                    <div
                      className={
                        transaction_details?.cod_settlement_eligible
                          ? "timeline-dot active"
                          : "timeline-dot"
                      }
                    >
                      {transaction_details?.cod_settlement_eligible && (
                        <img
                          src={
                            AppConfig.CDN_Media_url +
                            "be453854bc89e35e154efa5e26814afa.svg"
                          }
                          className="img-fluid"
                          width={10}
                        />
                      )}
                    </div>
                    <div className="d-flex">
                    <div className="w-100 ps-2">Handover Eligible</div>
                    <div className="ms-2">
                    <b
                          style={{ color: "blue", cursor: "pointer" }}
                            onClick={() => {
                                const set_data = {
                                    value: transaction_details.id,
                                    dm: transaction_details?.cod_settlement_eligible,
                                    from:"cod",

                                  
                                    
                                  };
                                  setSetEleModal({
                                    is_open: true,
                                    data: set_data,
                                  });

                                  
                            }}
                          >
                            Update
                          </b>
                          </div>
                          </div>
                  </div>
                  <div className="d-flex">
                    <div className="timeline-line-container">
                      <div
                        className={
                          transaction_details?.cod_settlement_approved
                            ? "timeline-line active"
                            : "timeline-line"
                        }
                      ></div>
                    </div>
                  
                  </div>
                  <div className="d-flex">
                    <div
                      className={
                        transaction_details?.cod_settlement_approved
                          ? "timeline-dot active"
                          : "timeline-dot"
                      }
                    >
                      {transaction_details?.cod_settlement_approved && (
                        <img
                          src={
                            AppConfig.CDN_Media_url +
                            "be453854bc89e35e154efa5e26814afa.svg"
                          }
                          className="img-fluid"
                          width={10}
                        />
                      )}
                    </div>
                  <div className="d-flex">

                    <div className="w-100 ps-2">Handover Approved</div>
                    <div className="ms-2">
                    <b
                          style={{ color: "blue", cursor: "pointer" }}
                            onClick={() => {
                              const set_data = {
                                    value: transaction_details.id,
                                    dm: transaction_details?.cod_settlement_approved,
                                    from:"cod",

                                  
                                    
                                  };
                                  setApprovModal({
                                    is_open: true,
                                    data: set_data,
                                  });

                                  
                            }}
                          >
                            Update
                          </b>
                          </div>
                          </div>

                  </div>
                  <div className="d-flex">
                    <div className="timeline-line-container">
                      <div
                        className={
                          transaction_details?.cod_settlement_paid
                            ? "timeline-line active"
                            : "timeline-line"
                        }
                      ></div>
                    </div>
                    <div className="w-100"></div>
                  </div>
                  <div className="d-flex">
                    <div
                      className={
                        transaction_details?.cod_settlement_paid
                          ? "timeline-dot active"
                          : "timeline-dot"
                      }
                    >
                      {transaction_details?.cod_settlement_paid && (
                        <img
                          src={
                            AppConfig.CDN_Media_url +
                            "be453854bc89e35e154efa5e26814afa.svg"
                          }
                          className="img-fluid"
                          width={10}
                        />
                      )}
                    </div>
                    <div className="d-flex">
                    <div className="w-100 ps-2">
                    Handover Paid
                      {transaction_details?.cod_settlement_paid && (
                        <div>
                          <div>
                            {
                              transaction_details?.cod_settlement_paid_time
                            }
                          </div>
                          <div className="fw-500 fs-15">
                            &#8377;{" "}
                            {
                              transaction_details?.cod_settlement_paid_amount
                            }
                          </div>
                        </div>
                      )}
                    </div>

                    <b
                           style={{ color: "blue", cursor: "pointer" }}
                           className="ms-2"
                            onClick={() => {
                              const set_data = {
                                    value: transaction_details.id,
                                    dm: transaction_details?.cod_settlement_paid,
                                    from:"cod",
                                    price : transaction_details.cod_settlement_paid? transaction_details.cod_settlement_paid_amount :  transaction_details.collected_amount
                                  };
                                  setPaidModal({
                                    is_open: true,
                                    data: set_data,
                                  
                                  });

                                  
                            }}
                          >
                            Update
                          </b>

                          </div>
                  </div>
                </div> */}

<div className="timeline-1">
                  <div className="d-flex">
                    <div
                      className={
                        cod_eligible
                          ? "timeline-dot active"
                          : "timeline-dot"
                      }

                      onClick={()=>{
                        codeligibleOnChange(cod_eligible)
                      }}
                    >
                      {cod_eligible && (
                        <img
                          src={
                            AppConfig.CDN_Media_url +
                            "be453854bc89e35e154efa5e26814afa.svg"
                          }
                          className="img-fluid"
                          width={10}
                        />
                      )}
                    </div>
                    <div className="d-flex">
                    <div className="w-100 ps-2">Handover Eligible</div>
                    <div className="ms-2">
                    {/* <b
                         style={{ color: "blue", cursor: "pointer" }}
                            onClick={() => {
                                const set_data = {
                                    value: order_data.id,
                                    dm: order_data?.seller_settlement?.settlement_eligible,
                                    from:"details",
                                  
                                    
                                  };
                                  setSetEleModal({
                                    is_open: true,
                                    data: set_data,
                                  });

                                  
                            }}
                          >
                            Update
                          </b> */}
                          </div>
                          </div>
                  </div>
                  <div className="d-flex">
                    <div className="timeline-line-container">
                      <div
                        className={
                          cod_eligible
                            ? "timeline-line active"
                            : "timeline-line"
                        }
                      ></div>
                    </div>
                  
                  </div>
                  <div className="d-flex">
                    <div
                      className={
                        cod_approve
                          ? "timeline-dot active"
                          : "timeline-dot"
                      }
                      onClick={() => {
                        // if (!approve) {
                          console.log('approve is false, calling approveOnChange');
                          codapproveOnChange(cod_approve);
                      
                          if (cod_eligible === false) {
                            console.log('eligible is false, calling saveEligible');
                            codsaveEligible(true);
                          }
                        // }
                      }}
                    >
                      {cod_approve && (
                        <img
                          src={
                            AppConfig.CDN_Media_url +
                            "be453854bc89e35e154efa5e26814afa.svg"
                          }
                          className="img-fluid"
                          width={10}
                        />
                      )}
                    </div>
                  <div className="d-flex">

                    <div className="w-100 ps-2">Handover Approved</div>
                    <div className="ms-2">
                    {/* <b
                         style={{ color: "blue", cursor: "pointer" }}
                            onClick={() => {
                              const set_data = {
                                    value: order_data.id,
                                    dm: order_data?.seller_settlement?.settlement_approved,

                                    from:"details",
                                  
                                    
                                  };
                                  setApprovModal({
                                    is_open: true,
                                    data: set_data,
                                  });

                                  
                            }}
                          >
                            Update
                          </b> */}
                          </div>
                          </div>

                  </div>
                  <div className="d-flex">
                    <div className="timeline-line-container">
                      <div
                        className={
                          cod_paid
                            ? "timeline-line active"
                            : "timeline-line"
                        }
                      ></div>
                    </div>
                    <div className="w-100"></div>
                  </div>
                  <div className="d-flex">
                    <div
                      className={
                        cod_paid
                          ? "timeline-dot active"
                          : "timeline-dot"
                      }

                      onClick={()=>{
                        
                        codpaidOnChange(cod_paid)
                        setCodInputShow(true)

                        if (cod_eligible === false) {
                          console.log('eligible is false, calling saveEligible');
                          codsaveEligible(true);
                        }
                        if (cod_approve === false) {
                          console.log('eligible is false, calling saveEligible');
                          codsaveApprove(true);
                        }
                      }}
                    >
                      {cod_paid && (
                        <img
                          src={
                            AppConfig.CDN_Media_url +
                            "be453854bc89e35e154efa5e26814afa.svg"
                          }
                          className="img-fluid"
                          width={10}
                        />
                      )}
                    </div>
                    <div className="d-flex">

                      {console.log(transaction_details)}
                    <div className="w-100 ps-2">
                    Handover Paid 
                      {transaction_details?.cod_settlement_paid && (
                        <div>
                          <div>
                            {
                              transaction_details?.cod_settlement_paid_time
                            }
                          </div>
                          <div className="fw-500 fs-15">
                            &#8377;{" "}
                            {
                              transaction_details?.cod_settlement_paid_amount
                            }
                          </div>
                        </div>
                      )}

{cod_pay ?
    !cod_update &&  <b
                         style={{ color: "blue", cursor: "pointer" }}
                            onClick={() => {
                         setCodUpdate(true)
                         setCodInputShow(true)

                                  
                            }}
                          >
                            Update
                          </b>
                           :
cod_paid &&  cod_input_show &&
<>
<div className="d-flex"><p><input className="me-2 mt-2" type="number" value={cod_price}  onChange={(e)=>codpriceOnchange(e)}/></p>
{/* <button
              type="button"
              className="btn btn-success ms-2"
            
              onClick={()=>
                // myModal.hide();
                Save(true)}
                  // data-bs-dismiss="modal"
            >
              Save
            </button> */}
            <div className="d-flex align-items-center">
                                          <button
                                            className="btn btn-sm px-2 btn-outline-success"
                                            onClick={() => {
                                              // SetopenModal({
                                              //   is_open: true,
                                              //   data: {
                                              //     id: ele.id,
                                              //   },
                                              // });
                                              codSave(true)
                                            }}
                                          >
                                            <Entypo
                                              name="check"
                                              size={12}
                                              color="green"
                                            />
                                            {/* ✓ */}
                                          </button>
                                          <button
                                            className="btn btn-sm px-2 btn-outline-secondary ms-3"
                                            onClick={() => {
                                              // SetopenRejModal({
                                              //   is_open: true,
                                              //   data: {
                                              //     id: ele.id,
                                              //   },
                                              // });
                                              setCodInputShow(false)
                                              setCodPaid(false)
                                            }}
                                          >
                                            <Ionicons
                                              name="close"
                                              size={15}
                                              color="#111"
                                            />
                                            {/* ✗ */}
                                          </button>
                                        </div>
            </div>
            </>
}

{cod_update && cod_input_show &&<div className="d-flex"><p><input className="me-2 mt-2" type="number" value={cod_price}  onChange={(e)=>codpriceOnchange(e)}/></p>
{/* <button
              type="button"
              className="btn btn-success ms-2"
            
              onClick={()=>
                // myModal.hide();
                Save(true)}
                  // data-bs-dismiss="modal"
            >
              Save
            </button> */}
            <div className="d-flex align-items-center">
                                          <button
                                            className="btn btn-sm px-2 btn-outline-success"
                                            onClick={() => {
                                              // SetopenModal({
                                              //   is_open: true,
                                              //   data: {
                                              //     id: ele.id,
                                              //   },
                                              // });
                                              codSave(true)
                                            }}
                                          >
                                            <Entypo
                                              name="check"
                                              size={12}
                                              color="green"
                                            />
                                            {/* ✓ */}
                                          </button>
                                          <button
                                            className="btn btn-sm px-2 btn-outline-secondary ms-3"
                                            onClick={() => {
                                              // SetopenRejModal({
                                              //   is_open: true,
                                              //   data: {
                                              //     id: ele.id,
                                              //   },
                                              // });
                                              setCodInputShow(false)
                                              setCodUpdate(false)
                                            }}
                                          >
                                            <Ionicons
                                              name="close"
                                              size={15}
                                              color="#111"
                                            />
                                            {/* ✗ */}
                                          </button>
                                        </div>
            
            
            </div>}



                    </div>

                    {/* <b
                         style={{ color: "blue", cursor: "pointer" }}
                            onClick={() => {
                              const set_data = {
                                    value: order_data.id,
                                    dm: order_data?.seller_settlement?.settlement_paid,
                                  from:"details",
                                    price : order_data.seller_settlement.settlement_paid? order_data.seller_settlement.settlement_paid_amount :  order_data.total_amount_payable
                                  };
                                  setPaidModal({
                                    is_open: true,
                                    data: set_data,
                                  });

                                  
                            }}
                          >
                            Update
                          </b> */}

                          </div>
                  </div>
                </div>
              </div>
}
            </div>
                              <div className="py-1">
              <div className="row px-0">
                <div className="fw-bold text-black mb-3 fs-7 ps-0 ms-2">
                  Delivery Partner Settlement
                </div>
                {/* <div className="timeline-1">
                  <div className="d-flex">
                    <div
                      className={
                        transaction_details?.settlement_eligible
                          ? "timeline-dot active"
                          : "timeline-dot"
                      }
                    >
                      {transaction_details?.settlement_eligible && (
                        <img
                          src={
                            AppConfig.CDN_Media_url +
                            "be453854bc89e35e154efa5e26814afa.svg"
                          }
                          className="img-fluid"
                          width={10}
                        />
                      )}
                    </div>
                    <div className="d-flex">
                    <div className="w-100 ps-2">Settlement Eligible</div>
                    <div className="ms-2">
                        <b
                           style={{ color: "blue", cursor: "pointer" }}
                            onClick={() => {
                                const set_data = {
                                    value: transaction_details.id,
                                    dm: transaction_details?.settlement_eligible,
                                    from:"ship",

                                  
                                    
                                  };
                                  setSetEleModal({
                                    is_open: true,
                                    data: set_data,
                                  });

                                  
                            }}
                          >
                            Update
                          </b>
                          </div>
                          </div>
                  </div>
                  <div className="d-flex">
                    <div className="timeline-line-container">
                      <div
                        className={
                          transaction_details?.settlement_approved
                            ? "timeline-line active"
                            : "timeline-line"
                        }
                      ></div>
                    </div>
                  
                  </div>
                  <div className="d-flex">
                    <div
                      className={
                        transaction_details?.settlement_approved
                          ? "timeline-dot active"
                          : "timeline-dot"
                      }
                    >
                      {transaction_details?.settlement_approved && (
                        <img
                          src={
                            AppConfig.CDN_Media_url +
                            "be453854bc89e35e154efa5e26814afa.svg"
                          }
                          className="img-fluid"
                          width={10}
                        />
                      )}
                    </div>
                  <div className="d-flex">

                    <div className="w-100 ps-2">Settlement Approved</div>
                    <div className="ms-2">
                    <b
                         style={{ color: "blue", cursor: "pointer" }}
                            onClick={() => {
                              const set_data = {
                                    value: transaction_details.id,
                                    dm: transaction_details?.settlement_approved,
                                    from:"ship",

                                  
                                    
                                  };
                                  setApprovModal({
                                    is_open: true,
                                    data: set_data,
                                  });

                                  
                            }}
                          >
                            Update
                          </b>
                          </div>
                          </div>

                  </div>
                  <div className="d-flex">
                    <div className="timeline-line-container">
                      <div
                        className={
                          transaction_details?.settlement_paid
                            ? "timeline-line active"
                            : "timeline-line"
                        }
                      ></div>
                    </div>
                    <div className="w-100"></div>
                  </div>
                  <div className="d-flex">
                    <div
                      className={
                        transaction_details?.settlement_paid
                          ? "timeline-dot active"
                          : "timeline-dot"
                      }
                    >
                      {transaction_details?.settlement_paid && (
                        <img
                          src={
                            AppConfig.CDN_Media_url +
                            "be453854bc89e35e154efa5e26814afa.svg"
                          }
                          className="img-fluid"
                          width={10}
                        />
                      )}
                    </div>
                    <div className="d-flex">
                    <div className="w-100 ps-2">
                      Settlement Paid
                      {transaction_details?.settlement_paid && (
                        <div>
                          <div>
                            {
                              transaction_details?.settlement_paid_time
                            }
                          </div>
                          <div className="fw-500 fs-15">
                            &#8377;{" "}
                            {
                              transaction_details?.settlement_paid_amount
                            }
                          </div>
                        </div>
                      )}
                    </div>

                    <b
                            style={{ color: "blue", cursor: "pointer" }}
                            onClick={() => {
                              const set_data = {
                                    value: transaction_details.id,
                                    dm: transaction_details?.settlement_paid,
                                    from:"ship",
                                    price : transaction_details.settlement_paid? transaction_details.settlement_paid_amount :  transaction_details.payment_amount
                                  };
                                  setPaidModal({
                                    is_open: true,
                                    data: set_data,
                                  
                                  });

                                  
                            }}
                          >
                            Update
                          </b>

                          </div>
                  </div>
                </div> */}

<div className="timeline-1">
                  <div className="d-flex">
                    <div
                      className={
                        eligible
                          ? "timeline-dot active"
                          : "timeline-dot"
                      }

                      onClick={()=>{
                        eligibleOnChange(eligible)
                      }}
                    >
                      {eligible && (
                        <img
                          src={
                            AppConfig.CDN_Media_url +
                            "be453854bc89e35e154efa5e26814afa.svg"
                          }
                          className="img-fluid"
                          width={10}
                        />
                      )}
                    </div>
                    <div className="d-flex">
                    <div className="w-100 ps-2">Settlement Eligible</div>
                    <div className="ms-2">
                    {/* <b
                         style={{ color: "blue", cursor: "pointer" }}
                            onClick={() => {
                                const set_data = {
                                    value: order_data.id,
                                    dm: order_data?.seller_settlement?.settlement_eligible,
                                    from:"details",
                                  
                                    
                                  };
                                  setSetEleModal({
                                    is_open: true,
                                    data: set_data,
                                  });

                                  
                            }}
                          >
                            Update
                          </b> */}
                          </div>
                          </div>
                  </div>
                  <div className="d-flex">
                    <div className="timeline-line-container">
                      <div
                        className={
                          eligible
                            ? "timeline-line active"
                            : "timeline-line"
                        }
                      ></div>
                    </div>
                  
                  </div>
                  <div className="d-flex">
                    <div
                      className={
                        approve
                          ? "timeline-dot active"
                          : "timeline-dot"
                      }
                      onClick={() => {
                        // if (!approve) {
                          console.log('approve is false, calling approveOnChange');
                          approveOnChange(approve);
                      
                          if (eligible === false) {
                            console.log('eligible is false, calling saveEligible');
                            saveEligible(true);
                          }
                        // }
                      }}
                    >
                      {approve && (
                        <img
                          src={
                            AppConfig.CDN_Media_url +
                            "be453854bc89e35e154efa5e26814afa.svg"
                          }
                          className="img-fluid"
                          width={10}
                        />
                      )}
                    </div>
                  <div className="d-flex">

                    <div className="w-100 ps-2">Settlement Approved</div>
                    <div className="ms-2">
                    {/* <b
                         style={{ color: "blue", cursor: "pointer" }}
                            onClick={() => {
                              const set_data = {
                                    value: order_data.id,
                                    dm: order_data?.seller_settlement?.settlement_approved,

                                    from:"details",
                                  
                                    
                                  };
                                  setApprovModal({
                                    is_open: true,
                                    data: set_data,
                                  });

                                  
                            }}
                          >
                            Update
                          </b> */}
                          </div>
                          </div>

                  </div>
                  <div className="d-flex">
                    <div className="timeline-line-container">
                      <div
                        className={
                          paid
                            ? "timeline-line active"
                            : "timeline-line"
                        }
                      ></div>
                    </div>
                    <div className="w-100"></div>
                  </div>
                  <div className="d-flex">
                    <div
                      className={
                       paid
                          ? "timeline-dot active"
                          : "timeline-dot"
                      }

                      onClick={()=>{
                        
                        paidOnChange(paid)
                        setInputShow(true)

                        if (eligible === false) {
                          console.log('eligible is false, calling saveEligible');
                          saveEligible(true);
                        }
                        if (approve === false) {
                          console.log('eligible is false, calling saveEligible');
                          saveApprove(true);
                        }
                      }}
                    >
                      {paid && (
                        <img
                          src={
                            AppConfig.CDN_Media_url +
                            "be453854bc89e35e154efa5e26814afa.svg"
                          }
                          className="img-fluid"
                          width={10}
                        />
                      )}
                    </div>
                    <div className="d-flex">

                      {console.log(transaction_details)}
                    <div className="w-100 ps-2">
                      Settlement Paid 
                      {transaction_details?.settlement_paid && (
                        <div>
                          <div>
                            {
                              transaction_details?.settlement_paid_time
                            }
                          </div>
                          <div className="fw-500 fs-15">
                            &#8377;{" "}
                            {
                              transaction_details?.settlement_paid_amount
                            }
                          </div>
                        </div>
                      )}

{pay ?
    !update &&  <b
                         style={{ color: "blue", cursor: "pointer" }}
                            onClick={() => {
                         setUpdate(true)
                         setInputShow(true)

                                  
                            }}
                          >
                            Update
                          </b>
                           :
paid &&  input_show &&
<>
<div className="d-flex"><p><input className="me-2 mt-2" type="number" value={price}  onChange={(e)=>priceOnchange(e)}/></p>
{/* <button
              type="button"
              className="btn btn-success ms-2"
            
              onClick={()=>
                // myModal.hide();
                Save(true)}
                  // data-bs-dismiss="modal"
            >
              Save
            </button> */}
            <div className="d-flex align-items-center">
                                          <button
                                            className="btn btn-sm px-2 btn-outline-success"
                                            onClick={() => {
                                              // SetopenModal({
                                              //   is_open: true,
                                              //   data: {
                                              //     id: ele.id,
                                              //   },
                                              // });
                                              Save(true)
                                            }}
                                          >
                                            <Entypo
                                              name="check"
                                              size={12}
                                              color="green"
                                            />
                                            {/* ✓ */}
                                          </button>
                                          <button
                                            className="btn btn-sm px-2 btn-outline-secondary ms-3"
                                            onClick={() => {
                                              // SetopenRejModal({
                                              //   is_open: true,
                                              //   data: {
                                              //     id: ele.id,
                                              //   },
                                              // });
                                              setInputShow(false)
                                              setPaid(false)
                                            }}
                                          >
                                            <Ionicons
                                              name="close"
                                              size={15}
                                              color="#111"
                                            />
                                            {/* ✗ */}
                                          </button>
                                        </div>
            </div>
            </>
}

{update && input_show &&<div className="d-flex"><p><input className="me-2 mt-2" type="number" value={price}  onChange={(e)=>priceOnchange(e)}/></p>
{/* <button
              type="button"
              className="btn btn-success ms-2"
            
              onClick={()=>
                // myModal.hide();
                Save(true)}
                  // data-bs-dismiss="modal"
            >
              Save
            </button> */}
            <div className="d-flex align-items-center">
                                          <button
                                            className="btn btn-sm px-2 btn-outline-success"
                                            onClick={() => {
                                              // SetopenModal({
                                              //   is_open: true,
                                              //   data: {
                                              //     id: ele.id,
                                              //   },
                                              // });
                                              Save(true)
                                            }}
                                          >
                                            <Entypo
                                              name="check"
                                              size={12}
                                              color="green"
                                            />
                                            {/* ✓ */}
                                          </button>
                                          <button
                                            className="btn btn-sm px-2 btn-outline-secondary ms-3"
                                            onClick={() => {
                                              // SetopenRejModal({
                                              //   is_open: true,
                                              //   data: {
                                              //     id: ele.id,
                                              //   },
                                              // });
                                              setInputShow(false)
                                              setUpdate(false)
                                            }}
                                          >
                                            <Ionicons
                                              name="close"
                                              size={15}
                                              color="#111"
                                            />
                                            {/* ✗ */}
                                          </button>
                                        </div>
            
            
            </div>}



                    </div>

                    {/* <b
                         style={{ color: "blue", cursor: "pointer" }}
                            onClick={() => {
                              const set_data = {
                                    value: order_data.id,
                                    dm: order_data?.seller_settlement?.settlement_paid,
                                  from:"details",
                                    price : order_data.seller_settlement.settlement_paid? order_data.seller_settlement.settlement_paid_amount :  order_data.total_amount_payable
                                  };
                                  setPaidModal({
                                    is_open: true,
                                    data: set_data,
                                  });

                                  
                            }}
                          >
                            Update
                          </b> */}

                          </div>
                  </div>
                </div>
              </div>
            </div>

           
                          </div>
                {/* )} */}
              </div>
            </div>
          ) : null}
</div>



{/* 
<ModalDemoUser
        open={demo_modal.is_open}
        data={demo_modal.data}
     
        close={(data: any) => {
          console.log("Edit Outlet Modal On Close :", data);
          setDemoModal({
            is_open: false,
            data: {},
          
          });
          if (data.action == "save") {

            // let pass_data = {
            //     order_status: 1,
            //     seller_brand_id: "",
            //     seller_outlet_id: "",
            //     sort: "",
            //     page_no: page_no,
            //   };
            //   get_users(pass_data, filter_data);
            // SetDefaultCheckout(data.value)
            // get_users();
            console.log(data.index)
            SetOrderData((prevValue) => {
              prevValue[data.index].demo_order = data.demo_order;
              return [...prevValue];
            });
          }
        }}
      />
      <ModalCod
        open={cod_modal.is_open}
        data={cod_modal.data}
     
        close={(data: any) => {
          console.log("Edit Outlet Modal On Close :", data);
          setCodModal({
            is_open: false,
            data: {},
          
          });
          if (data.action == "save") {

            // let pass_data = {
            //     order_status: 1,
            //     seller_brand_id: "",
            //     seller_outlet_id: "",
            //     sort: "",
            //     page_no: page_no,
            //   };
            //   get_users(pass_data, filter_data);
            // SetDefaultCheckout(data.value)
            // get_users();
            console.log(data.index)
            SetOrderData((prevValue) => {
              prevValue[data.index].cod_allowed = data.demo_order;
              return [...prevValue];
            });
          }
        }}
      />
            <ModalRemarks
        open={remark_modal.is_open}
        data={remark_modal.data}
     
        close={(data: any) => {
          console.log("Edit Outlet Modal On Close :", data);
          setRemarkModal({
            is_open: false,
            data: {},
          
          });
          if (data.action == "save") {

            // let pass_data = {
            //     order_status: 1,
            //     seller_brand_id: "",
            //     seller_outlet_id: "",
            //     sort: "",
            //     page_no: page_no,
            //   };
            //   get_users(pass_data, filter_data);
            // SetDefaultCheckout(data.value)
            // get_users();
            console.log(data.index)
            SetOrderData((prevValue) => {
              prevValue[data.index].ecommerce_remarks = data.demo_order;
              return [...prevValue];
            });
          }
        }}
      />

<ModalCart
        open={cart_modal.is_open}
        data={cart_modal.data}
     
        close={(data: any) => {
          console.log("Edit Outlet Modal On Close :", data);
          setCartModal({
            is_open: false,
            data: {},
          
          });
          if (data.action == "save") {

            // let pass_data = {
            //     order_status: 1,
            //     seller_brand_id: "",
            //     seller_outlet_id: "",
            //     sort: "",
            //     page_no: page_no,
            //   };
            //   get_users(pass_data, filter_data);
            // SetDefaultCheckout(data.value)
            // get_users();
          
            console.log(data.index)
            SetOrderData((prevValue) => {
              prevValue[data.index].ecommerce_cart_response = data.demo_order;
              return [...prevValue];
            });
          }
        }}
      /> */}

<ModalSettleEligible
        open={set_ele_modal.is_open}
        data={set_ele_modal.data}
     
        close={(data: any) => {
          console.log("Edit Outlet Modal On Close :", data);
          setSetEleModal({
            is_open: false,
            data: {},
          
          });
          if (data.action == "save") {
console.log(data)
   
if(data.from == "ship"){
      if(data.det == "eligible"){
      setEligible(false);
    console.log(approve)
      // if(approve==true){
      saveApprove(false);
      // }
      
      // if(paid){
      Save(false)
      SetTransactionDetails((prevalue: any) => {
        prevalue.settlement_eligible=data.value.settlement_eligible
        return { ...prevalue };
      });

      
    
      // }
      }else if(data.det == "approved"){
        setApprove(false);
        // {paid == true &&
          Save(false)

          SetTransactionDetails((prevalue: any) => {
            prevalue.settlement_approved=data.value.settlement_approved
            return { ...prevalue };
          });
          // }
      }else if(data.det == "paid"){
console.log("hi")
        SetTransactionDetails((prevalue: any) => {
          prevalue.settlement_paid_amount=data.value.settlement_paid_amount;
          prevalue.settlement_paid_time=data.value.settlement_paid_time;
      
          prevalue.settlement_paid=data.value.settlement_paid;


          


          return { ...prevalue };
        });
        setPaid(false)
        setPay(false)
        setUpdate(false)
      }
    }else  if(data.from == "cod"){
      if(data.det == "eligible"){
      setCodEligible(false);
    console.log(approve)
      // if(approve==true){
      codsaveApprove(false);
      // }
      
      // if(paid){
      codSave(false)
      SetTransactionDetails((prevalue: any) => {
        prevalue.cod_settlement_eligible=data.value.cod_settlement_eligible
        return { ...prevalue };
      });

      
    
      // }
      }else if(data.det == "approved"){
        setCodApprove(false);
        // {paid == true &&
          codSave(false)

          SetTransactionDetails((prevalue: any) => {
            prevalue.cod_settlement_approved=data.value.cod_settlement_approved
            return { ...prevalue };
          });
          // }
      }else if(data.det == "paid"){
console.log("hi")
        SetTransactionDetails((prevalue: any) => {
          prevalue.cod_settlement_paid_amount=data.value.cod_settlement_paid_amount;
          prevalue.cod_settlement_paid_time=data.value.cod_settlement_paid_time;
      
          prevalue.cod_settlement_paid=data.value.cod_settlement_paid;


          


          return { ...prevalue };
        });
        setCodPaid(false)
        setCodPay(false)
        setCodUpdate(false)
      }
    }


            // SetDefaultCheckout(data.value)
            // get_users();
            // SetUsersData((prevValue) => {
            //   prevValue[data.index] = data.value;
            //   return [...prevValue];
            // });
          }
        }}
      />

{/* <ModalSettleApproval
        open={set_approv_modal.is_open}
        data={set_approv_modal.data}
     
        close={(data: any) => {
          console.log("Edit Outlet Modal On Close :", data);
          setApprovModal({
            is_open: false,
            data: {},
          
          });
          if (data.action == "save") {

      
            let pass_data = {
              order_status: 1,
              seller_brand_id: "",
              seller_outlet_id: "",
              sort: "",
              page_no: page_no,
            };
            get_users(pass_data, filter_data);


            // SetDefaultCheckout(data.value)
            // get_users();
            // SetUsersData((prevValue) => {
            //   prevValue[data.index] = data.value;
            //   return [...prevValue];
            // });
          }
        }}
      /> */}


{/* <ModalSettlePaid
        open={paid_modal.is_open}
        data={paid_modal.data}
     
        close={(data: any) => {
          console.log("Edit Outlet Modal On Close :", data);
          setPaidModal({
            is_open: false,
            data: {},
          
          });
          if (data.action == "save") {
            console.log(data.value)
            let pass_data = {
              order_status: 1,
              seller_brand_id: "",
              seller_outlet_id: "",
              sort: "",
              page_no: 1,
            };
            get_users(pass_data, filter_data);
      
              // get_order_detail(id_value.ord_id,false);

            // SetDefaultCheckout(data.value)
            // get_users();
            // SetUsersData((prevValue) => {
            //   prevValue[data.index] = data.value;
            //   return [...prevValue];
            // });
          }
        }}
      /> */}
</div>


</>
  )
}

export default DeliveryPartnerOrders