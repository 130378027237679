import React, { useState, useEffect, useContext } from "react";
import bootstrap from "../../assets/libraries/bootstrap/js/bootstrap";


let myModal = {};

export default function ViewOutletOrders(props: any) {
  const [close_data, SetCloseData] = useState({
    action: "close",
  });

  useEffect(() => {
    let myModal1 = document.getElementById("ViewOutletOrdersModal");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      myModal = new bootstrap.Modal(
        document.getElementById("ViewOutletOrdersModal"),
        {}
      );
      myModal.show();
    }
  }, [props.open]);

  return (
    <div
      className="modal fade"
      id="ViewOutletOrdersModal"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <div className="  modal-title" id="ModalLabel">
              <h5>Orders</h5>
            </div>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                let item = close_data;
                item.action = "close";
                SetCloseData(item);
                myModal.hide();
              }}
            ></button>
          </div>
          <div className="modal-body">
            <p className="mb-0 text-dark">List of Items</p>
            <div className="mt-3">
              <div className="row border-bottom py-2">
                <div className="col-1">
                  <img
                    src={require("../../assets/img/mob.png")}
                    className="w-100"
                  />
                </div>
                <div className="col-8">
                  <p className="mb-0">Item1</p>
                  <small>2 Qty</small>
                </div>
                <div className="col-3">
                  <p className="mb-0">₹ 500</p>
                </div>
              </div>
              <div className="row border-bottom py-2">
                <div className="col-1">
                  <img
                    src={require("../../assets/img/mob.png")}
                    className="w-100"
                  />
                </div>
                <div className="col-8">
                  <p className="mb-0">Item1</p>
                  <small>2 Qty</small>
                </div>
                <div className="col-3">
                  <p className="mb-0">₹ 500</p>
                </div>
              </div>
              <div className="row border-bottom py-2">
                <div className="col-1">
                  <img
                    src={require("../../assets/img/mob.png")}
                    className="w-100"
                  />
                </div>
                <div className="col-8">
                  <p className="mb-0">Item1</p>
                  <small>2 Qty</small>
                </div>
                <div className="col-3">
                  <p className="mb-0">₹ 500</p>
                </div>
              </div>
              <div className="row border-bottom py-2">
                <div className="col-1">
                  <img
                    src={require("../../assets/img/chart-up.png")}
                    className="w-100"
                  />
                </div>
                <div className="col-8">
                  <p className="mb-0">Item1</p>
                  <small>2 Qty</small>
                </div>
                <div className="col-3">
                  <p className="mb-0">₹ 500</p>
                </div>
              </div>
            </div>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-sm btn-secondary me-3"
              onClick={() => {
                let item = close_data;
                item.action = "close";
                SetCloseData(item);
                myModal.hide();
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-sm btn-prime ms-auto"
              onClick={(event) => {
                let item = close_data;
                item.action = "close";
                SetCloseData(item);
                myModal.hide();
              }}
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
