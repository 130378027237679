import React, { useState, useEffect, useContext, useRef } from "react";
import { AppConfig } from "../../config";

export default function ProductList(props: any) {
    const [products, setProducts]: any = useState();
    const [diet_type, setDietType]: any = useState({});
    useEffect(() => {
        
        if(props){
            setProducts(props.products);
            setDietType(props.diet_type);
        }
    }, [props]);
    return (
        <div className="font-noto-sans">
            {products?.map((product: any, i: any) => (
                <div className="mb-2 d-flex">
                    <div className="me-2 product-diet">
                        {(diet_type.hasOwnProperty('p_'+product?.id) && diet_type['p_'+product?.id]) && (
                            <>
                                {(diet_type['p_'+product?.id][0].value == AppConfig.diet_type.vegetarian) ? (
                                    <img
                                        src={
                                          AppConfig.CDN_Image_url +
                                          "e301ce3af353aab421a2f76c898a439d.png"
                                        }
                                        className="img-fluid"
                                      />
                                ):(diet_type['p_'+product?.id][0].value == AppConfig.diet_type.non_vegetarian) ? (
                                    <img
                                        src={
                                          AppConfig.CDN_Image_url +
                                          "5c7a11ae863a9b5cfa0e1844c7a186b9.png"
                                        }
                                        className="img-fluid"
                                      />
                                ):(diet_type['p_'+product?.id][0].value == AppConfig.diet_type.eggetarian) ? (
                                    <img
                                        src={
                                          AppConfig.CDN_Image_url +
                                          "fea28c272b8bbfb74f54b073bea5cfaa.png"
                                        }
                                        className="img-fluid"
                                      />
                                ):(diet_type['p_'+product?.id][0].value == AppConfig.diet_type.vegan) ? (
                                    <img
                                        src={
                                          AppConfig.CDN_Image_url +
                                          "1e2d32e8b1e3f2fd5eb65c3b8957674e.png"
                                        }
                                        className="img-fluid"
                                      />
                                ):(<></>)}
                            </>
                        )} 
                    </div>
                    <div className="product-diet-details">
                        <div className="text-dark fw-500 mb-2px">
                            {product?.quantity} <span>x </span> 
                            {product?.variant_name}
                        </div>
                        {product.addons?.length > 0 && (
                            <div className="ps-3 fs-12 text-muted">
                                {product.addons?.map((addon: any, j: any) => (
                                    <>
                                    <span className="white-space-nowrap">{addon?.quantity} <span>x </span> {addon?.addon_name}</span>
                                    {(product.addons[j+1]?.addon_name)&&(<>, </>)}
                                    </>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
}