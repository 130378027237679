import React, { useEffect, useState } from "react";
import bootstrap from "../../assets/libraries/bootstrap/js/bootstrap";
// import { values } from "lodash";
let myModal:any = {};

export default function ModalChannelForceAvailable(props) {
  const [unaval, setUnAval]:any = useState("");
  const [formCheckbox, setFormCheckbox]: any = useState({
    value: 9,
    hours: 2
  });

  const [close_data, SetCloseData] = useState({
    action: "close",
  });
  useEffect(() => {
    console.log("ModalChannelForceAvailable On mount :", props);
    let myModal2:any = document.getElementById("ModalChannelForceAvailable");
    myModal2.addEventListener("hidden.bs.modal", function (event) {
      console.log("event :", event);
      props.close(close_data);

    });
  }, []);

  useEffect(() => {
    if (props.open) {
      setUnAval(props.next_slot);
      console.log("ModalChannelForceAvailable On mount :", props);
      myModal = new bootstrap.Modal(
        document.getElementById("ModalChannelForceAvailable"),
        {}
      );
      myModal.show();
    }
  }, [props.open]);

  function submit() {
    if(formCheckbox?.value == 11 && !formCheckbox?.time){
      setFormCheckbox((prev: any) => {
        prev.error = 'Kindly enter date and time';
        return {...prev};
      });
    } else {
      let item:any = close_data;
      item.action = "save";
      item.value = formCheckbox;
      item.data = props.data;
      item.type = props.type;
      SetCloseData(item);

      // props.close(item); // Passes the item which includes the action "save" to the close function
      setUnAval("");
      myModal.hide();
    }
  }

  return (
    <div
      className="modal fade"
      id="ModalChannelForceAvailable"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-fullscreen">
        <div className="modal-content">
          <div className="p-3 d-flex align-items-center">
            <h6 className="mb-0">
              Turning ON Beyond Schedule
            </h6>
          </div>
          <div className="modal-body">
            <div className="px-2">
              <div className="mb-2 text-muted">Re-enable Regular schedule automatically after</div>
              <div>
                <div className="radio-border mb-3">
                  <div className="md-radio">
                    <input id="autoonradio1" type="radio"
                      checked={formCheckbox.value == 9 && formCheckbox.hours == 2}
                      onChange={(e) => {
                        setFormCheckbox({
                          value: 9,
                          hours: 2
                        });
                      }}
                    />
                    <label htmlFor="autoonradio1">2 Hours</label>
                  </div>
                </div>
                <div className="radio-border mb-3">
                  <div className="md-radio">
                    <input id="autoonradio2" type="radio"
                      checked={formCheckbox.value == 9 && formCheckbox.hours == 4}
                      onChange={(e) => {
                        setFormCheckbox({
                          value: 9,
                          hours: 4
                        });
                      }}
                    />
                    <label htmlFor="autoonradio2">4 Hours</label>
                  </div>
                </div>

                <div className="radio-border mb-3">
                  <div className="md-radio">
                    <input id="autoonradio3" type="radio"
                      checked={formCheckbox.value == 10}
                      onChange={(e) => {
                        setFormCheckbox({
                          value: 10,
                          days: 1
                        });
                      }}
                    />
                    <label htmlFor="autoonradio3">1 Day</label>
                  </div>
                </div>
                {(unaval.hasOwnProperty("next_slot") && unaval?.next_slot) && (
                  <div className="radio-border mb-3">
                    <div className="md-radio">
                      <input id="autoonradio4" type="radio"
                        checked={formCheckbox.value == 12}
                        onChange={(e) => {
                          setFormCheckbox({
                            value: 12
                          });
                        }}
                      />
                      <label htmlFor="autoonradio4">Until Next Available Schedule</label>
                    </div>
                  </div>
                )}

                <div className="radio-border mb-3">
                  <div className="md-radio">
                    <input id="autoonradio5" type="radio"
                      checked={formCheckbox.value == 11}
                      onChange={(e) => {
                        setFormCheckbox({value: 11});
                      }}
                    />
                    <label htmlFor="autoonradio5">Custom Date & Time</label>
                  </div>
                </div>
                {formCheckbox.value == 11 && (
                  <div className="mb-3">
                    <input
                      type="datetime-local"
                      className="form-control br-0-imp"
                      value={formCheckbox.time}
                      onChange={(e) => {
                        setFormCheckbox({
                          value: 11,
                          time: e.target.value
                        });
                      }}
                    />
                    {formCheckbox?.error && (
                      <div className="text-danger fs-12 mt-1">{formCheckbox?.error}</div>
                    )}
                  </div>
                )}
                <div className="fs-12 text-muted mb-1">Manually Turn Off</div>
                <div className="radio-border mb-3">
                  <div className="md-radio">
                    <input id="autoonradio6" type="radio"
                      checked={formCheckbox.value == 7}
                      onChange={(e) => {
                        setFormCheckbox({value: 7});
                      }}
                    />
                    <label htmlFor="autoonradio6">I will turn off myself</label>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-white "
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={submit}
            >
              Done
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
