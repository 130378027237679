import React, { useState, useEffect, useRef, createRef } from "react";
import bootstrap from "../../assets/libraries/bootstrap/js/bootstrap";
import { toPng } from 'html-to-image';
import { api } from "../../utils/Api";
import { useParams } from "react-router-dom";
import QRCodeStyling from "qr-code-styling";
import toast from "react-hot-toast";

let myModal = {};

export default function ModalPrintQr(props: any) {
  const [close_data, SetCloseData] = useState({
    action: "close",
  });
  const [id_value, SerIdValue] = useState(useParams());
  // const ref:any = useRef([]);
  const elementRef = useRef(null);
  const elementRefTamil = useRef(null);
  let qrDom: any = [];
  const [print_data, SetPrintData]: any = useState([]);
  let qr_initial_data: any = {
    data: '',
    width: 580,
    height: 580,
    // data: " ",
    margin: 0,
    image: "",
    dotsOptions: {
      type: "square",
      color: "#000",
      gradient: null,
    },
    dotsOptionsHelper: {
      colorType: {
        single: true,
        gradient: false,
      },
      gradient: {
        linear: true,
        radial: false,
        color1: "#000",
        color2: "#000",
        rotation: "0",
        colorStops: [
          { offset: 0, color: "#000" },
          { offset: 1, color: "#000" },
        ],
        type: "linear",
      },
    },
    cornersSquareOptions: {
      type: "",
      color: "#000",
      gradient: null,
    },
    cornersSquareOptionsHelper: {
      colorType: {
        single: true,
        gradient: false,
      },
      gradient: {
        linear: true,
        radial: false,
        color1: "#000",
        color2: "#000",
        rotation: "0",
        colorStops: [
          { offset: 0, color: "#000" },
          { offset: 1, color: "#000" },
        ],
        type: "linear",
      },
    },
    cornersDotOptions: {
      type: "",
      color: "#000",
      gradient: null,
    },
    cornersDotOptionsHelper: {
      colorType: {
        single: true,
        gradient: false,
      },
      gradient: {
        linear: true,
        radial: false,
        color1: "#000",
        color2: "#000",
        rotation: "0",
        colorStops: [
          { offset: 0, color: "#000" },
          { offset: 1, color: "#000" },
        ],
        type: "linear",
      },
    },
    backgroundOptions: {
      color: "#fff",
      gradient: null,
    },
    backgroundOptionsHelper: {
      colorType: {
        single: true,
        gradient: false,
      },
      gradient: {
        linear: true,
        radial: false,
        color1: "#ffffff",
        color2: "#ffffff",
        rotation: "0",
        colorStops: [
          { offset: 0, color: "#ffffff" },
          { offset: 1, color: "#ffffff" },
        ],
        type: "linear",
      },
    },
    imageOptions: {
      hideBackgroundDots: true,
      imageSize: 0.4,
      margin: 0,
      crossOrigin: "anonymous",
      // crossOrigin: "use-credentials",
    },
    qrOptions: {
      typeNumber: "0",
      mode: "Byte",
      errorCorrectionLevel: "Q",
    },
  };
  let qrCode: any = {};
  let divToPrint: any

  useEffect(() => {
    console.log("ModalViewQr On mount :", props);
    let myModal1 = document.getElementById("PrintQrModal");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      // qr_initial_data.data = "";
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      console.log("ModalViewQr On Open :", props);
      myModal = new bootstrap.Modal(document.getElementById("PrintQrModal"), {});
      myModal.show();
    }
    SetPrintData(props.data);
    // qr_initial_data.data = props.data.qr_link;
    // qrCode = new QRCodeStyling(qr_initial_data);
    // qrCode.append(ref.current);
    // qrCode.update({ data: props.data.qr_link });

    // }, [props.open]);
  }, [props]);

  useEffect(() => {
    if (print_data.length > 0) {
      print_data.map((ele: any, i: any) => {
        qr_initial_data.data = ele.qr_link
        qrCode[i] = new QRCodeStyling(qr_initial_data);
        qrCode[i].append(document.getElementById('qr_out_' + i));
      })
      divToPrint = document.getElementById('qr-print-area');

      print_data.map((ele: any, i: any) => {
        qr_initial_data.data = ele.qr_link
        qr_initial_data.width = 560
        qr_initial_data.height = 560
        qrCode[i] = new QRCodeStyling(qr_initial_data);
        qrCode[i].append(document.getElementById('qr_out_tamil_' + i));
      })
      divToPrint = document.getElementById('qr-print-area-tamil');
    }
  }, [print_data]);

  const htmlToImageConvert = () => {
    toPng(elementRef.current, { cacheBust: false })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = "sellers-qr-eng.png";
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const htmlToImageConvertTamil = () => {
    toPng(elementRefTamil.current, { cacheBust: false })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = "sellers-qr-tamil.png";
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  async function download_qrcode() {
    let qids:any = [];
    print_data.map((ele: any, i: any) => {
      qids.push(ele.id);
    })

    let pass_data = {
      post: {
        // brand: id_value.brand_id,
        // page: get_data.page_no,
        id: qids,
      },
    };
    let response: any = await api("/qr/seller_qr_downloaded", pass_data);
    if (response.status_code == 200) {
      if (response.response.status) {
      }
    }
  }

  return (
    <div
      className="modal"
      id="PrintQrModal"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-fullscreen modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header shadow-bottom">
            <h6 className="  modal-title" id="ModalLabel">
              Print Qr
            </h6>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                let item = close_data;
                item.action = "close";
                SetCloseData(item);
                myModal.hide();
              }}
            ></button>
          </div>

          <div className="modal-body">
            <div className="position-relative">
              {print_data.length > 0 && (
                <div ref={elementRef} id="qr-print-area" className="row qr-print-area">
                  {print_data.map((ele: any, i: any) => (
                    <div className="col-4 px-0 qr-bg-eng">
                      <div className="qr-canvas" id={'qr_out_' + i} />
                      <div className="qr-data-info-sm">{ele.qr_link}</div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="position-relative">
              {print_data.length > 0 && (
                <div ref={elementRefTamil} id="qr-print-area-tamil" className="row qr-print-area">
                  {print_data.map((ele: any, i: any) => (
                    <div className="col-4 px-0 qr-bg-tamil">
                      <div className="qr-canvas" id={'qr_out_tamil_' + i} />
                      <div className="qr-data-info-sm">{ele.qr_link}</div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary-outline btn-radius px-3 me-2"
              onClick={() => {
                let item = close_data;
                item.action = "close";
                SetCloseData(item);
                myModal.hide();
              }}
            >
              Close
            </button>
            <button
              type="button"
              className="btn bg-prime text-white btn-radius px-3"
              onClick={() => {
                download_qrcode()
              }}
            >
              Mark As Downloaded
            </button>
            <button
              type="button"
              className="btn bg-prime text-white btn-radius px-3"
              onClick={() => {
                htmlToImageConvert()
              }}
            >
              Download English
            </button>
            <button
              type="button"
              className="btn bg-prime text-white btn-radius px-3"
              onClick={() => {
                htmlToImageConvertTamil()
              }}
            >
              Download Tamil
            </button>
            <button
              type="button"
              className="btn bg-prime text-white btn-radius px-3"
              onClick={() => {
                htmlToImageConvert()
                htmlToImageConvertTamil()
                download_qrcode()
              }}
            >
              Download All
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
