import React, { useEffect, useState } from "react";

const DataContext = React.createContext();

function DataProvider(props) {
  const [sample_data, SetSampleData] = useState("");
  const [app_data, SetAppData] = useState({
    brand_id: "",
    outlet_id: "",
    user_data: {},
    scroll_locked: 0,
  });
  let html: any = document.querySelector("html"), body: any = document.querySelector("body");


  useEffect(() => {
    console.log('app_data.scroll_locked', app_data.scroll_locked);
    
    if(app_data.scroll_locked > 0){
      html.style.overflowY = "hidden";
      html.style.overscrollBehaviorY = "none";
      body.style.overflowY = "hidden";
      body.style.overscrollBehaviorY = "none";
    } else {
      html.style.overflowY = "";
      html.style.overscrollBehaviorY = "";
      body.style.overflowY = "";
      body.style.overscrollBehaviorY = "";
    }
  }, [app_data?.scroll_locked]);

  return (
    <DataContext.Provider
      value={{
        app_data,
        SetAppData,
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
}
export { DataProvider };

// export const DataProvider = DataContext.Provider;
export const DataConsumer = DataContext.Consumer;

export default DataContext;
