import React, { useState, useEffect, useContext } from "react";
import bootstrap from "../../assets/libraries/bootstrap/js/bootstrap";

import { api } from "../../utils/Api";
import { useParams } from "react-router-dom";

import DataContext from "../../utils/Context/DataContext";
import { AppHelper } from "../../utils/Helper";

import { Feather, Ionicons, SimpleLineIcons } from "@expo/vector-icons";

import InfiniteScroll from "react-infinite-scroll-component";

let myModal = {};

const initial_value = {
  hightlight: false,
  selected_files: [],
};

export default function ModalAddGallery(props: any) {
  const [id_value, SetIdValue] = useState(useParams());
  const context = useContext(DataContext);
  const [save_data, SetSaveData] = useState(initial_value);
  const [close_data, SetCloseData] = useState({
    action: "close",
  });
  const [modal_type, SetModalType] = useState("");
  const [read_only, SetReadOnly] = useState(false);
  const fileInputRef = React.createRef();

  ////////
  const [view_data, SetViewData] = useState("main");
  const [size_data, SetSizeData] = useState([]);
  const [next_page, SetNextPage] = useState(false);
  const [image_base_url, SetImageBaseUrl] = useState("");
  const [gallery_data, SetGalleryData] = useState([]);
  const [page_no, SetPageNo] = useState(1);
  const [selected_images, SetSelectedImages] = useState([]);

  const [width, SetWidth] = React.useState(innerWidth);
  const [single_Img, SetSingleImage] = useState(false);

  useEffect(() => {
    if (props.open) {
      myModal = new bootstrap.Modal(
        document.getElementById("GalleryModal"),
        {}
      );
      myModal.show();
      SetSaveData({
        hightlight: false,
        selected_files: [],
      });
      SetModalType(props.type);
      if (props.from == "add_product") {
        get_gallery(page_no);
        SetSelectedImages(props.selected);
      }
      if (props.from == "create_brand") {
        get_gallery(page_no);
        SetSelectedImages(props.selected);
      }
      if (props.from == "brand") {
        get_gallery(page_no);
        SetSelectedImages([]);
      }

      if (props.from == "product_detail") {
        get_gallery(page_no);
        // SetSelectedImages(props.selected)
      }
      if (props.from == "create_outlet") {
        get_gallery(page_no);
       
        SetSelectedImages(props.selected);
        SetSingleImage(true);
        // SetSelectedImages(props.selected)
      }

      if (props.from == "create_banner") {
        get_gallery(page_no);
        SetSelectedImages(props.selected);

        SetSingleImage(true);
      }

      if (props.from == "group_detail") {
        get_gallery(page_no);
      }
    }
  }, [props.open]);

  useEffect(() => {
    let myModal1 = document.getElementById("GalleryModal");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      SetReadOnly(false);
      SetSaveData({
        hightlight: false,
        selected_files: [],
      });

      props.close(close_data);
    });
  }, []);

  function openFileDialog() {
    if (props.disabled) return;
    fileInputRef.current.click();
  }

  function onDragOver(evt) {
    evt.preventDefault();
    if (props.disabled) return;
    SetSaveData((prevValue: any) => {
      prevValue.hightlight = true;
      return { ...prevValue };
    });
  }

  function onDragLeave() {
    SetSaveData((prevValue: any) => {
      prevValue.hightlight = false;
      return { ...prevValue };
    });
  }

  function onDrop(event) {
    event.preventDefault();

    if (props.disabled) return;

    const files = event.dataTransfer.files;
    let old_value = save_data.selected_files;
    const array = fileListToArray(files, old_value);
    SetSaveData((prevValue: any) => {
      prevValue.hightlight = false;
      prevValue.selected_files = array;
      return { ...prevValue };
    });
  }

  function onFilesAdded(evt) {
    if (props.disabled) return;
    const files = evt.target.files;

    let old_value = save_data.selected_files;
    const array = fileListToArray(files, old_value);
    SetSaveData((prevValue: any) => {
      prevValue.selected_files = array;
      return { ...prevValue };
    });
  }

  function fileListToArray(list, value) {
    const old_value = value;
    const array = [];
    for (var i = 0; i < list.length; i++) {
      let data = {};
      data.file = list.item(i);
      data.url = URL.createObjectURL(list.item(i));
      data.upload_percentage = 0;
      old_value.push(data);
    }
    return old_value;
  }

  async function upload() {
    let selected_img = save_data.selected_files;
    for (var i = 0; i < selected_img.length; i++) {
      let pass_data = {
        get: {},
        post: {
          image: selected_img[i].file,
        },
      };
      // if (context.app_data.app_from == 'seller') {
      if (id_value.hasOwnProperty("brand_id")) {
        pass_data.get.brand = id_value.brand_id;
      } else {
        pass_data.get.m_id = id_value.m_id;
      }

      let res_data = await api("/Imagegallery/upload", pass_data, false, true);

      if (res_data.response.hasOwnProperty("id")) {
        if (i + 1 == selected_img.length) {
          if (props.from == "gallery") {
            let item = close_data;
            item.action = "save";
            item.value = selected_img;
            SetCloseData(item);
            myModal.hide();
          } else {
            SetViewData("main");
            SetPageNo(1);
            SetGalleryData([]);
            get_gallery(1);
          }
        }
        continue;
      } else {
        break;
      }
    }
  }

  async function get_gallery(page_no_value) {
    let pass_data = {
      get: {
        page: page_no_value,
      },
    };

    let response = await api("/Imagegallery/get", pass_data);
    if (response.status_code == 200) {
      if (response.response.hasOwnProperty("next_page")) {
        SetNextPage(response.response.next_page);
      }
      if (response.response.hasOwnProperty("sizes")) {
        SetSizeData(response.response.sizes);
      }
      if (response.response.hasOwnProperty("image_base_url")) {
        SetImageBaseUrl(response.response.image_base_url);
      }
      if (response.response.hasOwnProperty("data")) {
        SetGalleryData((prevValue: any) => {
          let res_data_value = response.response.data;
          let selected_image_value = [];
          if (props.selected !== undefined && props.selected.length > 0) {
            selected_image_value = props.selected;
          }

          res_data_value.map((ele: any) => {
            ele.checked = false;
            if (selected_image_value.length > 0) {
              selected_image_value.map((s_ele: any, s_index: any) => {
                if (ele.id == s_ele.id) {
                  ele.checked = true;
                } else {
                  // ele.checked = false;
                }
              });
            } else {
              // ele.checked = false;
            }
            prevValue.push(ele);
          });

          return [...prevValue];
        });
      }
    }
  }

  function fetchMoreData() {
    SetPageNo((prevValue: any) => {
      prevValue = prevValue + 1;
      get_gallery(prevValue);
      return prevValue;
    });
  }

  async function on_image_submit() {
    let selected_image_value = selected_images;
    SetViewData("main");
    SetPageNo(1);
    SetNextPage(false);
    SetGalleryData([]);
    SetSelectedImages([]);
    let item = close_data;
    item.action = "save";
    item.value = selected_image_value;
    if (props.from == "group_detail") {
      item.type = props.type;
      item.variant_data = props.data;
    }

    SetCloseData(item);
    myModal.hide();
  }

  return (
    <div
      className="modal"
      id="GalleryModal"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-fullscreen-sm-down  modal-dialog-centered">
        <div className="modal-content">
          {/* modal header */}
          <div className="modal-header">
            {props.from == "gallery" ? (
              <h5 className="modal-title" id="ModalLabel">
                Add Gallery
              </h5>
            ) : null}

            {props.from == "add_product" ? (
              <>
                {view_data == "add" ? (
                  <div
                    className="me-2"
                    onClick={() => {
                      SetViewData("main");
                    }}
                  >
                    <Ionicons name="arrow-back" size={16} color="black" />
                  </div>
                ) : null}
                <h5 className="modal-title" id="ModalLabel">
                  {view_data == "main" ? "Select Gallery Image" : null}
                  {view_data == "add" ? "Add Gallery Image" : null}
                </h5>
              </>
            ) : null}

            {props.from == "create_outlet" ? (
              <>
                {view_data == "add" ? (
                  <div
                    className="me-2"
                    onClick={() => {
                      SetViewData("main");
                    }}
                  >
                    <Ionicons name="arrow-back" size={16} color="black" />
                  </div>
                ) : null}
                <h5 className="modal-title" id="ModalLabel">
                  {view_data == "main" ? "Select Gallery Image" : null}
                  {view_data == "add" ? "Add Gallery Image" : null}
                </h5>
              </>
            ) : null}

            {props.from == "create_banner" ? (
              <>
                {view_data == "add" ? (
                  <div
                    className="me-2"
                    onClick={() => {
                      SetViewData("main");
                    }}
                  >
                    <Ionicons name="arrow-back" size={16} color="black" />
                  </div>
                ) : null}
                <h5 className="modal-title" id="ModalLabel">
                  {view_data == "main" ? "Select Gallery Image" : null}
                  {view_data == "add" ? "Add Gallery Image" : null}
                </h5>
              </>
            ) : null}

            {props.from == "prodct_details" ? (
              <>
                {view_data == "add" ? (
                  <div
                    className="me-2"
                    onClick={() => {
                      SetViewData("main");
                    }}
                  >
                    <Ionicons name="arrow-back" size={16} color="black" />
                  </div>
                ) : null}
                <h5 className="modal-title" id="ModalLabel">
                  {view_data == "main" ? "Select Gallery Image" : null}
                  {view_data == "add" ? "Add Gallery Image" : null}
                </h5>
              </>
            ) : null}

            {props.from == "group_detail" ? (
              <>
                {view_data == "add" ? (
                  <div
                    className="me-2"
                    onClick={() => {
                      SetViewData("main");
                    }}
                  >
                    <Ionicons name="arrow-back" size={16} color="black" />
                  </div>
                ) : null}
                <h5 className="modal-title" id="ModalLabel">
                  {view_data == "main" ? "Select Gallery Image" : null}
                  {view_data == "add" ? "Add Gallery Image" : null}
                </h5>
              </>
            ) : null}

            {props.from == "create_brand" ? (
              <>
                {view_data == "add" ? (
                  <div
                    className="me-2"
                    onClick={() => {
                      SetViewData("main");
                    }}
                  >
                    <Ionicons name="arrow-back" size={16} color="black" />
                  </div>
                ) : null}
                <h5 className="modal-title" id="ModalLabel">
                  {view_data == "main" ? "Select Gallery Image" : null}
                  {view_data == "add" ? "Add Gallery Image" : null}
                </h5>
              </>
            ) : null}

            {props.from == "brand" ? (
              <>
                {view_data == "add" ? (
                  <div
                    className="me-2"
                    onClick={() => {
                      SetViewData("main");
                    }}
                  >
                    <Ionicons name="arrow-back" size={16} color="black" />
                  </div>
                ) : null}
                <h5 className="modal-title" id="ModalLabel">
                  {view_data == "main" ? "Select Gallery Image" : null}
                  {view_data == "add" ? "Add Gallery Image" : null}
                </h5>
              </>
            ) : null}

            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                let item = close_data;
                item.action = "close";
                SetCloseData(item);
                myModal.hide();
              }}
            ></button>
          </div>

          {/* modal body */}
          <div className="modal-body">
            {props.from == "gallery" ? (
              <div className="">
                <div className=" pb-5">
                  <div
                    className={
                      save_data.hightlight ? "Dropzone Highlight" : "Dropzone"
                    }
                    onDragOver={onDragOver}
                    onDragLeave={onDragLeave}
                    onDrop={onDrop}
                    onClick={openFileDialog}
                    style={{
                      cursor: props.disabled ? "default" : "pointer",
                    }}
                  >
                    <Feather name="upload-cloud" size={30} color="#bbb" />

                    <input
                      ref={fileInputRef}
                      className="FileInputNone"
                      type="file"
                      multiple
                      onChange={(evt) => {
                        onFilesAdded(evt);
                      }}
                    />
                    <p className="text-gray mt-3">Drag or Select Files</p>
                  </div>
                  <div className="row pt-3">
                    {save_data.selected_files.map((element, index) => (
                      <div
                        className="col-lg-2 col-md-3 col-4 p-1 position-relative"
                        key={index}
                      >
                        <img src={element.url} className="add_img" />
                        <div
                          className="cursor text-center text-red over-img"
                          onClick={() => {
                            SetSaveData((prevValue: any) => {
                              let file_data = prevValue.selected_files;
                              file_data.splice(index, 1);
                              prevValue.selected_files = file_data;
                              return { ...prevValue };
                            });
                          }}
                        >
                          <Ionicons name="ios-close" size={17} color="#333" />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : null}

            {props.from == "add_product" ? (
              <div>
                {view_data == "main" ? (
                  <div
                    id="scrollableDiv"
                    className={
                      width > 748 ? "h-300  scroll-auto" : "h-90vh scroll-auto"
                    }
                  >
                    <InfiniteScroll
                      scrollableTarget="scrollableDiv"
                      dataLength={gallery_data.length}
                      next={() => {
                        fetchMoreData();
                      }}
                      hasMore={next_page}
                      loader={
                        <div
                          style={{
                            textAlign: "center",
                            width: "100%",
                            padding: "10px",
                          }}
                        >
                          <div className="lds-ellipsis">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        </div>
                      }
                      endMessage={
                        <p style={{ textAlign: "center" }}>
                          {/* <b>End Of Data</b> */}
                        </p>
                      }
                    >
                      <div className="row px-2">
                        <div
                          className="col-md-3 col-6 img-list-height px-2 center cursor"
                          onClick={() => {
                            // imageAdd;
                            SetViewData("add");
                          }}
                        >
                          <Ionicons name="md-add" size={20} color="gray" />
                          <h6 className="ms-1 mb-0 text-gray">Add</h6>
                        </div>
                        {gallery_data.map((item, index) => (
                          <label
                            className="col-md-3 col-6 p-1 cursor hover-img"
                            key={index}
                          >
                            <div
                              className={
                                item.checked
                                  ? "position-relative selected_img"
                                  : "position-relative card-img bg-light"
                              }
                            >
                              <img
                                src={AppHelper.imageSrc(
                                  image_base_url,
                                  item.url,
                                  { format: "webp", width: 200 }
                                )}
                                alt={item.id}
                                className="img-ss"
                              />

                              <div className="position-check ">
                                <input
                                  className="form-check-input-custom"
                                  type="checkbox"
                                  checked={item.checked}
                                  onChange={(e) => {
                                    SetSelectedImages((prevValue: any) => {
                                      if (e.target.checked) {
                                        prevValue.push(item);
                                      } else {
                                        prevValue.map(
                                          (ele: any, index: any) => {
                                            if (item.id == ele.id) {
                                              prevValue.splice(index, 1);
                                            }
                                          }
                                        );
                                      }
                                      return [...prevValue];
                                    });
                                    SetGalleryData((prevValue: any) => {
                                      prevValue[index].checked =
                                        !prevValue[index].checked;
                                      return [...prevValue];
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </label>
                        ))}
                      </div>
                    </InfiniteScroll>
                  </div>
                ) : null}

                {view_data == "add" ? (
                  <div className="">
                    <div className=" p-3 pb-5">
                      <div
                        className={
                          save_data.hightlight
                            ? "Dropzone Highlight"
                            : "Dropzone"
                        }
                        onDragOver={onDragOver}
                        onDragLeave={onDragLeave}
                        onDrop={onDrop}
                        onClick={openFileDialog}
                        style={{
                          cursor: props.disabled ? "default" : "pointer",
                        }}
                      >
                        <SimpleLineIcons
                          name="cloud-upload"
                          size={40}
                          color="#2589f5"
                        />
                        <input
                          ref={fileInputRef}
                          className="FileInputNone"
                          type="file"
                          multiple
                          onChange={(evt) => {
                            onFilesAdded(evt);
                          }}
                        />
                        <span className="text-primary mt-3">
                          Drag or Select Files
                        </span>
                      </div>
                      <div className="row pt-3">
                        {save_data.selected_files.map((element, index) => (
                          <div
                            className="col-lg-2 col-md-4 col-sm-3 col-6 p-2 position-relative"
                            key={index}
                          >
                            <img src={element.url} className="add_img" />
                            <div
                              className="cursor text-center text-red over-img"
                              onClick={() => {
                                SetSaveData((prevValue: any) => {
                                  let file_data = prevValue.selected_files;
                                  file_data.splice(index, 1);
                                  prevValue.selected_files = file_data;
                                  return { ...prevValue };
                                });
                              }}
                            >
                              <Ionicons name="ios-close" color="#333" />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}

            {props.from == "create_outlet" ? (
              <div>
                {view_data == "main" ? (
                  <div
                    id="scrollableDiv"
                    className={
                      width > 748 ? "h-300  scroll-auto" : "h-90vh scroll-auto"
                    }
                  >
                    <InfiniteScroll
                      scrollableTarget="scrollableDiv"
                      dataLength={gallery_data.length}
                      next={() => {
                        fetchMoreData();
                      }}
                      hasMore={next_page}
                      // height={300}
                      // width="100%"
                      loader={
                        <div
                          style={{
                            textAlign: "center",
                            width: "100%",
                            padding: "10px",
                          }}
                        >
                          <div className="lds-ellipsis">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        </div>
                      }
                      endMessage={
                        <p style={{ textAlign: "center" }}>
                          {/* <b>End Of Data</b> */}
                        </p>
                      }
                    >
                      <div className="row px-2">
                        <div
                          className="col-md-3 col-6 img-list-height px-2 center cursor"
                          onClick={() => {
                            // imageAdd;
                            SetViewData("add");
                          }}
                        >
                          <Ionicons name="md-add" size={20} color="gray" />
                          <h6 className="ms-1 mb-0 text-gray">Add</h6>
                        </div>
                        {gallery_data.map((item, index) => (
                          <label
                            className="col-md-3 col-6 p-1 cursor hover-img"
                            key={index}
                          >
                            <div
                              className={
                                item.checked
                                  ? "position-relative selected_img"
                                  : "position-relative card-img"
                              }
                            >
                              <img
                                src={AppHelper.imageSrc(
                                  image_base_url,
                                  item.url,
                                  { format: "webp", width: 200 }
                                )}
                                alt={item.id}
                                className="img-ss"
                              />

                              <div className="position-check ">
                                <input
                                  className="form-check-input-custom"
                                  type="checkbox"
                                  checked={item.checked}
                                  onChange={(e) => {
                                    SetSelectedImages((prevValue: any) => {
                                      if (e.target.checked) {
                                        prevValue.push(item);
                                        prevValue.map(
                                          (ele: any, index: any) => {
                                            if (item.id != ele.id) {
                                              prevValue.splice(index, 1);
                                            }
                                          }
                                        );
                                      } else {
                                        prevValue.map(
                                          (ele: any, index: any) => {
                                            if (item.id == ele.id) {
                                              prevValue.splice(index, 1);
                                            }
                                          }
                                        );
                                      }
                                      return [...prevValue];
                                    });
                                    SetGalleryData((prevValue: any) => {
                                      (prevValue[index].checked =
                                        !prevValue[index].checked),
                                        prevValue.map((ele: any, i: any) => {
                                          if (index != i) {
                                            prevValue[i].checked = false;
                                          }
                                        });

                                      return [...prevValue];
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </label>
                        ))}
                      </div>
                    </InfiniteScroll>
                  </div>
                ) : null}

                {view_data == "add" ? (
                  <div className="">
                    <div className=" p-3 pb-5">
                      <div
                        className={
                          save_data.hightlight
                            ? "Dropzone Highlight"
                            : "Dropzone"
                        }
                        onDragOver={onDragOver}
                        onDragLeave={onDragLeave}
                        onDrop={onDrop}
                        onClick={openFileDialog}
                        style={{
                          cursor: props.disabled ? "default" : "pointer",
                        }}
                      >
                        <SimpleLineIcons
                          name="cloud-upload"
                          size={40}
                          color="#2589f5"
                        />
                        <input
                          ref={fileInputRef}
                          className="FileInputNone"
                          type="file"
                          multiple
                          onChange={(evt) => {
                            onFilesAdded(evt);
                          }}
                        />
                        <span className="text-primary mt-3">
                          Drag or Select Files
                        </span>
                      </div>
                      <div className="row pt-3">
                        {save_data.selected_files.map((element, index) => (
                          <div
                            className="col-lg-2 col-md-4 col-sm-3 col-6 p-2 position-relative"
                            key={index}
                          >
                            <img src={element.url} className="add_img" />
                            <div
                              className="cursor text-center text-red over-img"
                              onClick={() => {
                                SetSaveData((prevValue: any) => {
                                  let file_data = prevValue.selected_files;
                                  file_data.splice(index, 1);
                                  prevValue.selected_files = file_data;
                                  return { ...prevValue };
                                });
                              }}
                            >
                              <Ionicons name="ios-close" color="#333" />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}

            {props.from == "create_banner" ? (
              <div>
                {view_data == "main" ? (
                  <div
                    id="scrollableDiv"
                    className={
                      width > 748 ? "h-300  scroll-auto" : "h-90vh scroll-auto"
                    }
                  >
                    <InfiniteScroll
                      scrollableTarget="scrollableDiv"
                      dataLength={gallery_data.length}
                      next={() => {
                        fetchMoreData();
                      }}
                      hasMore={next_page}
                      // height={300}
                      // width="100%"
                      loader={
                        <div
                          style={{
                            textAlign: "center",
                            width: "100%",
                            padding: "10px",
                          }}
                        >
                          <div className="lds-ellipsis">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        </div>
                      }
                      endMessage={
                        <p style={{ textAlign: "center" }}>
                          {/* <b>End Of Data</b> */}
                        </p>
                      }
                    >
                      <div className="row px-2">
                        <div
                          className="col-md-3 col-6 img-list-height px-2 center cursor"
                          onClick={() => {
                            // imageAdd;
                            SetViewData("add");
                          }}
                        >
                          <Ionicons name="md-add" size={20} color="gray" />
                          <h6 className="ms-1 mb-0 text-gray">Add</h6>
                        </div>
                        {gallery_data.map((item, index) => (
                          <label
                            className="col-md-3 col-6 p-1 cursor hover-img"
                            key={index}
                          >
                            <div
                              className={
                                item.checked
                                  ? "position-relative selected_img"
                                  : "position-relative card-img"
                              }
                            >
                              <img
                                src={AppHelper.imageSrc(
                                  image_base_url,
                                  item.url,
                                  { format: "webp", width: 200 }
                                )}
                                alt={item.id}
                                className="img-ss"
                              />

                              <div className="position-check ">
                                <input
                                  className="form-check-input-custom"
                                  type="checkbox"
                                  checked={item.checked}
                                  onChange={(e) => {
                                    SetSelectedImages((prevValue: any) => {
                                      if (e.target.checked) {
                                        prevValue.push(item);
                                        prevValue.map(
                                          (ele: any, index: any) => {
                                            if (item.id != ele.id) {
                                              prevValue.splice(index, 1);
                                            }
                                          }
                                        );
                                      } else {
                                        prevValue.map(
                                          (ele: any, index: any) => {
                                            if (item.id == ele.id) {
                                              prevValue.splice(index, 1);
                                            }
                                          }
                                        );
                                      }
                                      return [...prevValue];
                                    });
                                    SetGalleryData((prevValue: any) => {
                                      (prevValue[index].checked =
                                        !prevValue[index].checked),
                                        prevValue.map((ele: any, i: any) => {
                                          if (index != i) {
                                            prevValue[i].checked = false;
                                          }
                                        });

                                      return [...prevValue];
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </label>
                        ))}
                      </div>
                    </InfiniteScroll>
                  </div>
                ) : null}

                {view_data == "add" ? (
                  <div className="">
                    <div className=" p-3 pb-5">
                      <div
                        className={
                          save_data.hightlight
                            ? "Dropzone Highlight"
                            : "Dropzone"
                        }
                        onDragOver={onDragOver}
                        onDragLeave={onDragLeave}
                        onDrop={onDrop}
                        onClick={openFileDialog}
                        style={{
                          cursor: props.disabled ? "default" : "pointer",
                        }}
                      >
                        <SimpleLineIcons
                          name="cloud-upload"
                          size={40}
                          color="#2589f5"
                        />
                        <input
                          ref={fileInputRef}
                          className="FileInputNone"
                          type="file"
                          multiple
                          onChange={(evt) => {
                            onFilesAdded(evt);
                          }}
                        />
                        <span className="text-primary mt-3">
                          Drag or Select Files
                        </span>
                      </div>
                      <div className="row pt-3">
                        {save_data.selected_files.map((element, index) => (
                          <div
                            className="col-lg-2 col-md-4 col-sm-3 col-6 p-2 position-relative"
                            key={index}
                          >
                            <img src={element.url} className="add_img" />
                            <div
                              className="cursor text-center text-red over-img"
                              onClick={() => {
                                SetSaveData((prevValue: any) => {
                                  let file_data = prevValue.selected_files;
                                  file_data.splice(index, 1);
                                  prevValue.selected_files = file_data;
                                  return { ...prevValue };
                                });
                              }}
                            >
                              <Ionicons name="ios-close" color="#333" />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}

            {props.from == "product_detail" ? (
              <div>
                {view_data == "main" ? (
                  <div
                    id="scrollableDiv"
                    className={
                      width > 748 ? "h-300  scroll-auto" : "h-90vh scroll-auto"
                    }
                  >
                    <InfiniteScroll
                      scrollableTarget="scrollableDiv"
                      dataLength={gallery_data.length}
                      next={() => {
                        fetchMoreData();
                      }}
                      hasMore={next_page}
                      // height={300}
                      // width="100%"
                      loader={
                        <div
                          style={{
                            textAlign: "center",
                            width: "100%",
                            padding: "10px",
                          }}
                        >
                          <div className="lds-ellipsis">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        </div>
                      }
                      endMessage={
                        <p style={{ textAlign: "center" }}>
                          {/* <b>End Of Data</b> */}
                        </p>
                      }
                    >
                      <div className="row px-2">
                        <div
                          className="col-md-3 col-6 img-list-height px-2 center cursor"
                          onClick={() => {
                            // imageAdd;
                            SetViewData("add");
                          }}
                        >
                          <Ionicons name="md-add" size={20} color="gray" />
                          <h6 className="ms-1 mb-0 text-gray">Add</h6>
                        </div>
                        {gallery_data.map((item, index) => (
                          <label
                            className="col-md-3 col-6 p-1 cursor hover-img"
                            key={index}
                          >
                            <div
                              className={
                                item.checked
                                  ? "position-relative selected_img"
                                  : "position-relative card-img"
                              }
                            >
                              <img
                                src={AppHelper.imageSrc(
                                  image_base_url,
                                  item.url,
                                  { format: "webp", width: 200 }
                                )}
                                alt={item.id}
                                className="img-ss"
                              />

                              <div className="position-check ">
                                <input
                                  className="form-check-input-custom"
                                  type="checkbox"
                                  checked={item.checked}
                                  onChange={(e) => {
                                    SetSelectedImages((prevValue: any) => {
                                      if (e.target.checked) {
                                        prevValue.push(item);
                                      } else {
                                        prevValue.map(
                                          (ele: any, index: any) => {
                                            if (item.id == ele.id) {
                                              prevValue.splice(index, 1);
                                            }
                                          }
                                        );
                                      }
                                      return [...prevValue];
                                    });
                                    SetGalleryData((prevValue: any) => {
                                      prevValue[index].checked =
                                        !prevValue[index].checked;
                                      return [...prevValue];
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </label>
                        ))}
                      </div>
                    </InfiniteScroll>
                  </div>
                ) : null}

                {view_data == "add" ? (
                  <div className="">
                    <div className=" p-3 pb-5">
                      <div
                        className={
                          save_data.hightlight
                            ? "Dropzone Highlight"
                            : "Dropzone"
                        }
                        onDragOver={onDragOver}
                        onDragLeave={onDragLeave}
                        onDrop={onDrop}
                        onClick={openFileDialog}
                        style={{
                          cursor: props.disabled ? "default" : "pointer",
                        }}
                      >
                        <SimpleLineIcons
                          name="cloud-upload"
                          size={40}
                          color="#2589f5"
                        />
                        <input
                          ref={fileInputRef}
                          className="FileInputNone"
                          type="file"
                          multiple
                          onChange={(evt) => {
                            onFilesAdded(evt);
                          }}
                        />
                        <span className="text-primary mt-3">
                          Drag or Select Files
                        </span>
                      </div>
                      <div className="row pt-3">
                        {save_data.selected_files.map((element, index) => (
                          <div
                            className="col-lg-2 col-md-4 col-sm-3 col-6 p-2 position-relative"
                            key={index}
                          >
                            <img src={element.url} className="add_img" />
                            <div
                              className="cursor text-center text-red over-img"
                              onClick={() => {
                                SetSaveData((prevValue: any) => {
                                  let file_data = prevValue.selected_files;
                                  file_data.splice(index, 1);
                                  prevValue.selected_files = file_data;
                                  return { ...prevValue };
                                });
                              }}
                            >
                              <Ionicons name="ios-close" color="#333" />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}

            {props.from == "group_detail" ? (
              <div>
                {view_data == "main" ? (
                  <div
                    id="scrollableDiv"
                    className={
                      width > 748 ? "h-300  scroll-auto" : "h-90vh scroll-auto"
                    }
                  >
                    <InfiniteScroll
                      scrollableTarget="scrollableDiv"
                      dataLength={gallery_data.length}
                      next={() => {
                        fetchMoreData();
                      }}
                      hasMore={next_page}
                      // height={300}
                      // width="100%"
                      loader={
                        <div
                          style={{
                            textAlign: "center",
                            width: "100%",
                            padding: "10px",
                          }}
                        >
                          <div className="lds-ellipsis">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        </div>
                      }
                      endMessage={
                        <p style={{ textAlign: "center" }}>
                          {/* <b>End Of Data</b> */}
                        </p>
                      }
                    >
                      <div className="row px-2">
                        <div
                          className="col-md-3 col-6 img-list-height px-2 center cursor"
                          onClick={() => {
                            // imageAdd;
                            SetViewData("add");
                          }}
                        >
                          <Ionicons name="md-add" size={20} color="gray" />
                          <h6 className="ms-1 mb-0 text-gray">Add</h6>
                        </div>
                        {gallery_data.map((item, index) => (
                          <label
                            className="col-md-3 col-6 p-1 cursor hover-img"
                            key={index}
                          >
                            <div
                              className={
                                item.checked
                                  ? "position-relative selected_img"
                                  : "position-relative card-img"
                              }
                            >
                              <img
                                src={AppHelper.imageSrc(
                                  image_base_url,
                                  item.url,
                                  { format: "webp", width: 200 }
                                )}
                                alt={item.id}
                                className="img-ss"
                              />

                              <div className="position-check ">
                                <input
                                  className="form-check-input-custom"
                                  type="checkbox"
                                  checked={item.checked}
                                  onChange={(e) => {
                                    SetSelectedImages((prevValue: any) => {
                                      if (e.target.checked) {
                                        prevValue.push(item);
                                      } else {
                                        prevValue.map(
                                          (ele: any, index: any) => {
                                            if (item.id == ele.id) {
                                              prevValue.splice(index, 1);
                                            }
                                          }
                                        );
                                      }
                                      return [...prevValue];
                                    });
                                    SetGalleryData((prevValue: any) => {
                                      prevValue[index].checked =
                                        !prevValue[index].checked;
                                      return [...prevValue];
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </label>
                        ))}
                      </div>
                    </InfiniteScroll>
                  </div>
                ) : null}

                {view_data == "add" ? (
                  <div className="">
                    <div className=" p-3 pb-5">
                      <div
                        className={
                          save_data.hightlight
                            ? "Dropzone Highlight"
                            : "Dropzone"
                        }
                        onDragOver={onDragOver}
                        onDragLeave={onDragLeave}
                        onDrop={onDrop}
                        onClick={openFileDialog}
                        style={{
                          cursor: props.disabled ? "default" : "pointer",
                        }}
                      >
                        <SimpleLineIcons
                          name="cloud-upload"
                          size={40}
                          color="#2589f5"
                        />
                        <input
                          ref={fileInputRef}
                          className="FileInputNone"
                          type="file"
                          multiple
                          onChange={(evt) => {
                            onFilesAdded(evt);
                          }}
                        />
                        <span className="text-primary mt-3">
                          Drag or Select Files
                        </span>
                      </div>
                      <div className="row pt-3">
                        {save_data.selected_files.map((element, index) => (
                          <div
                            className="col-lg-2 col-md-4 col-sm-3 col-6 p-2 position-relative"
                            key={index}
                          >
                            <img src={element.url} className="add_img" />
                            <div
                              className="cursor text-center text-red over-img"
                              onClick={() => {
                                SetSaveData((prevValue: any) => {
                                  let file_data = prevValue.selected_files;
                                  file_data.splice(index, 1);
                                  prevValue.selected_files = file_data;
                                  return { ...prevValue };
                                });
                              }}
                            >
                              <Ionicons name="ios-close" color="#333" />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}

            {props.from == "create_brand" ? (
              <div>
                {view_data == "main" ? (
                  <div
                    id="scrollableDiv"
                    className={
                      width > 748 ? "h-300  scroll-auto" : "h-90vh scroll-auto"
                    }
                  >
                    <InfiniteScroll
                      scrollableTarget="scrollableDiv"
                      dataLength={gallery_data.length}
                      next={() => {
                        fetchMoreData();
                      }}
                      hasMore={next_page}
                      // height={300}
                      // width="100%"
                      loader={
                        <div
                          style={{
                            textAlign: "center",
                            width: "100%",
                            padding: "10px",
                          }}
                        >
                          <div className="lds-ellipsis">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        </div>
                      }
                      endMessage={
                        <p style={{ textAlign: "center" }}>
                          {/* <b>End Of Data</b> */}
                        </p>
                      }
                    >
                      <div className="row px-2">
                        <div
                          className="col-md-3 col-6 img-list-height px-2 center"
                          onClick={() => {
                            // imageAdd;
                            SetViewData("add");
                          }}
                        >
                          <Ionicons name="md-add" size={20} color="gray" />
                          <h6 className="ms-1 mb-0 text-gray">Add</h6>
                        </div>
                        {gallery_data.map((item, index) => (
                          <label
                            className="col-md-3 col-6 p-1 cursor hover-img"
                            key={index}
                          >
                            <div
                              className={
                                item.checked
                                  ? "position-relative selected_img"
                                  : "position-relative card-img"
                              }
                            >
                              <img
                                src={AppHelper.imageSrc(
                                  image_base_url,
                                  item.url,
                                  { format: "webp", width: 200 }
                                )}
                                alt={item.id}
                                className="img-ss"
                              />

                              <div className="position-check ">
                                <input
                                  className="form-check-input-custom"
                                  type="checkbox"
                                  checked={item.checked}
                                  onChange={(e) => {
                                    SetSelectedImages((prevValue: any) => {
                                      if (e.target.checked) {
                                        // prevValue.push(item)
                                        prevValue[0] = item;
                                      } else {
                                        // prevValue.map(
                                        //   (ele: any, index: any) => {
                                        //     if (item.id == ele.id) {
                                        //       prevValue.splice(index, 1)
                                        //     }
                                        //   },
                                        // )
                                        prevValue[0] = "";
                                      }
                                      return [...prevValue];
                                    });
                                    SetGalleryData((prevValue: any) => {
                                      if (e.target.checked) {
                                        prevValue.map(
                                          (pre_ele: any, pre_index: any) => {
                                            if (pre_index == index) {
                                              pre_ele.checked = true;
                                            } else {
                                              pre_ele.checked = false;
                                            }
                                          }
                                        );
                                      } else {
                                        prevValue.map(
                                          (pre_ele: any, pre_index: any) => {
                                            pre_ele.checked = false;
                                          }
                                        );
                                      }

                                      return [...prevValue];
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </label>
                        ))}
                      </div>
                    </InfiniteScroll>
                  </div>
                ) : null}

                {view_data == "add" ? (
                  <div className="">
                    <div className=" p-3 pb-5">
                      <div
                        className={
                          save_data.hightlight
                            ? "Dropzone Highlight"
                            : "Dropzone"
                        }
                        onDragOver={onDragOver}
                        onDragLeave={onDragLeave}
                        onDrop={onDrop}
                        onClick={openFileDialog}
                        style={{
                          cursor: props.disabled ? "default" : "pointer",
                        }}
                      >
                        <SimpleLineIcons
                          name="cloud-upload"
                          size={40}
                          color="#2589f5"
                        />
                        <input
                          ref={fileInputRef}
                          className="FileInputNone"
                          type="file"
                          multiple
                          onChange={(evt) => {
                            onFilesAdded(evt);
                          }}
                        />
                        <span className="text-primary mt-3">
                          Drag or Select Files
                        </span>
                      </div>
                      <div className="row pt-3">
                        {save_data.selected_files.map((element, index) => (
                          <div
                            className="col-lg-2 col-md-4 col-sm-3 col-6 p-2 position-relative"
                            key={index}
                          >
                            <img src={element.url} className="add_img" />
                            <div
                              className="cursor text-center text-red over-img"
                              onClick={() => {
                                SetSaveData((prevValue: any) => {
                                  let file_data = prevValue.selected_files;
                                  file_data.splice(index, 1);
                                  prevValue.selected_files = file_data;
                                  return { ...prevValue };
                                });
                              }}
                            >
                              <Ionicons name="ios-close" color="#333" />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}

            {props.from == "brand" ? (
              <div>
                {view_data == "main" ? (
                  <div
                    id="scrollableDiv"
                    className={
                      width > 748 ? "h-300  scroll-auto" : "h-90vh scroll-auto"
                    }
                  >
                    <InfiniteScroll
                      scrollableTarget="scrollableDiv"
                      dataLength={gallery_data.length}
                      next={() => {
                        fetchMoreData();
                      }}
                      hasMore={next_page}
                      // height={300}
                      // width="100%"
                      loader={
                        <div
                          style={{
                            textAlign: "center",
                            width: "100%",
                            padding: "10px",
                          }}
                        >
                          <div className="lds-ellipsis">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        </div>
                      }
                      endMessage={
                        <p style={{ textAlign: "center" }}>
                          {/* <b>End Of Data</b> */}
                        </p>
                      }
                    >
                      <div className="row px-2">
                        <div
                          className="col-md-3 col-6 img-list-height px-2 center"
                          onClick={() => {
                            // imageAdd;
                            SetViewData("add");
                          }}
                        >
                          <Ionicons name="md-add" size={20} color="gray" />
                          <h6 className="ms-1 mb-0 text-gray">Add</h6>
                        </div>
                        {gallery_data.map((item, index) => (
                          <label
                            className="col-md-3 col-6 p-1 cursor hover-img"
                            key={index}
                          >
                            <div
                              className={
                                item.checked
                                  ? "position-relative selected_img"
                                  : "position-relative card-img"
                              }
                            >
                              <img
                                src={AppHelper.imageSrc(
                                  image_base_url,
                                  item.url,
                                  { format: "webp", width: 200 }
                                )}
                                alt={item.id}
                                className="img-ss"
                              />

                              <div className="position-check ">
                                <input
                                  className="form-check-input-custom"
                                  type="checkbox"
                                  checked={item.checked}
                                  onChange={(e) => {
                                    SetSelectedImages((prevValue: any) => {
                                      if (e.target.checked) {
                                        prevValue[0] = item;
                                      } else {
                                        prevValue[0] = "";
                                      }
                                      return [...prevValue];
                                    });
                                    SetGalleryData((prevValue: any) => {
                                      if (e.target.checked) {
                                        prevValue.map(
                                          (pre_ele: any, pre_index: any) => {
                                            if (pre_index == index) {
                                              pre_ele.checked = true;
                                            } else {
                                              pre_ele.checked = false;
                                            }
                                          }
                                        );
                                      } else {
                                        prevValue.map(
                                          (pre_ele: any, pre_index: any) => {
                                            pre_ele.checked = false;
                                          }
                                        );
                                      }

                                      return [...prevValue];
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </label>
                        ))}
                      </div>
                    </InfiniteScroll>
                  </div>
                ) : null}

                {view_data == "add" ? (
                  <div className="">
                    <div className=" p-3 pb-5">
                      <div
                        className={
                          save_data.hightlight
                            ? "Dropzone Highlight"
                            : "Dropzone"
                        }
                        onDragOver={onDragOver}
                        onDragLeave={onDragLeave}
                        onDrop={onDrop}
                        onClick={openFileDialog}
                        style={{
                          cursor: props.disabled ? "default" : "pointer",
                        }}
                      >
                        <SimpleLineIcons
                          name="cloud-upload"
                          size={40}
                          color="#2589f5"
                        />
                        <input
                          ref={fileInputRef}
                          className="FileInputNone"
                          type="file"
                          multiple
                          onChange={(evt) => {
                            onFilesAdded(evt);
                          }}
                        />
                        <span className="text-primary mt-3">
                          Drag or Select Files
                        </span>
                      </div>
                      <div className="row pt-3">
                        {save_data.selected_files.map((element, index) => (
                          <div
                            className="col-lg-2 col-md-4 col-sm-3 col-6 p-2 position-relative"
                            key={index}
                          >
                            <img src={element.url} className="add_img" />
                            <div
                              className="cursor text-center text-red over-img"
                              onClick={() => {
                                SetSaveData((prevValue: any) => {
                                  let file_data = prevValue.selected_files;
                                  file_data.splice(index, 1);
                                  prevValue.selected_files = file_data;
                                  return { ...prevValue };
                                });
                              }}
                            >
                              <Ionicons name="ios-close" color="#333" />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>

          {/* modal footer */}
          <div className="modal-footer d-flex align-items-center w-100">
            <button
              type="button"
              className="btn btn-secondary-outline btn-sm btn-radius px-3"
              onClick={() => {
                let item = close_data;
                item.action = "close";
                SetCloseData(item);
                myModal.hide();
              }}
            >
              Close
            </button>

            <div className="ms-auto">
              {props.from == "gallery" ? (
                Object.keys(save_data.selected_files).length > 0 ? (
                  <button
                    type="button"
                    className="btn bg-prime btn-radius text-white fw-bold px-3 btn-sm"
                    onClick={() => {
                      upload();
                    }}
                  >
                    Upload
                  </button>
                ) : null
              ) : null}

              {props.from == "add_product" ? (
                <>
                  {view_data == "main" ? (
                    <div className="">
                      <button
                        type="button"
                        className="btn btn-custom-outline btn-sm px-3"
                        onClick={() => {
                          on_image_submit();
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  ) : null}

                  {view_data == "add" ? (
                    <div className="">
                      <button
                        type="button"
                        className="btn bg-prime btn-radius text-white fw-bold px-3 btn-sm"
                        onClick={() => {
                          upload();
                        }}
                      >
                        Upload
                      </button>
                    </div>
                  ) : null}
                </>
              ) : null}

              {props.from == "create_outlet" ? (
                <>
                  {view_data == "main" ? (
                    <div className="">
                      <button
                        type="button"
                        className="btn btn-primary-outline btn-sm px-3"
                        onClick={() => {
                          on_image_submit();
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  ) : null}

                  {view_data == "add" ? (
                    <div className="">
                      <button
                        type="button"
                        className="btn bg-prime btn-radius btn-primary-outline fw-bold px-3 btn-sm"
                        onClick={() => {
                          upload();
                        }}
                      >
                        Upload
                      </button>
                    </div>
                  ) : null}
                </>
              ) : null}

              {props.from == "create_banner" ? (
                <>
                  {view_data == "main" ? (
                    <div className="">
                      <button
                        type="button"
                        className="btn btn-custom-outline btn-sm px-3"
                        onClick={() => {
                          on_image_submit();
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  ) : null}

                  {view_data == "add" ? (
                    <div className="">
                      <button
                        type="button"
                        className="btn bg-prime btn-radius text-white fw-bold px-3 btn-sm"
                        onClick={() => {
                          upload();
                        }}
                      >
                        Upload
                      </button>
                    </div>
                  ) : null}
                </>
              ) : null}

              {props.from == "product_detail" ? (
                <>
                  {view_data == "main" ? (
                    <div className="">
                      <button
                        type="button"
                        className="btn btn-custom-outline btn-sm px-3"
                        onClick={() => {
                          on_image_submit();
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  ) : null}

                  {view_data == "add" ? (
                    <div className="">
                      <button
                        type="button"
                        className="btn bg-prime btn-radius text-white fw-bold px-3 btn-sm"
                        onClick={() => {
                          upload();
                        }}
                      >
                        Upload
                      </button>
                    </div>
                  ) : null}
                </>
              ) : null}

              {props.from == "group_detail" ? (
                <div className="">
                  {view_data == "main" ? (
                    <div className="">
                      <button
                        type="button"
                        className="btn btn-custom-outline px-3 btn-sm"
                        onClick={() => {
                          on_image_submit();
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  ) : null}

                  {view_data == "add" ? (
                    <div className="">
                      <button
                        type="button"
                        className="btn bg-prime btn-radius text-white fw-bold px-3 btn-sm"
                        onClick={() => {
                          upload();
                        }}
                      >
                        Upload
                      </button>
                    </div>
                  ) : null}
                </div>
              ) : null}

              {props.from == "create_brand" ? (
                <>
                  {view_data == "main" ? (
                    <div className="">
                      <button
                        type="button"
                        className="btn btn-custom-outline btn-sm px-3 "
                        onClick={() => {
                          on_image_submit();
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  ) : null}

                  {view_data == "add" ? (
                    <div className="">
                      <button
                        type="button"
                        className="btn bg-prime btn-radius text-white fw-bold px-3 btn-sm"
                        onClick={() => {
                          upload();
                        }}
                      >
                        Upload
                      </button>
                    </div>
                  ) : null}
                </>
              ) : null}

              {props.from == "brand" ? (
                <>
                  {view_data == "main" ? (
                    <div className="">
                      <button
                        type="button"
                        className="btn bg-prime btn-radius text-white fw-bold px-3 btn-sm"
                        onClick={() => {
                          // on_image_submit()

                          let selected_image_value = selected_images;
                          SetViewData("main");
                          SetPageNo(1);
                          SetNextPage(false);
                          SetGalleryData([]);
                          SetSelectedImages([]);
                          let item = close_data;
                          item.action = "save";
                          item.value = selected_image_value;
                          item.pass_value = props.data;
                          SetCloseData(item);
                          myModal.hide();
                        }}
                      >
                        Update
                      </button>
                    </div>
                  ) : null}

                  {view_data == "add" ? (
                    <div className="">
                      <button
                        type="button"
                        className="btn bg-prime btn-radius text-white fw-bold px-3 btn-sm"
                        onClick={() => {
                          upload();
                        }}
                      >
                        Upload
                      </button>
                    </div>
                  ) : null}
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
