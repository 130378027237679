import React, { useState, useEffect, useContext } from "react";
import DataContext from "../../utils/Context/DataContext";

export default function DeliveryCompanyPartner(props: any) {
  const context = useContext(DataContext);

  const [page_no, SetPageNo] = useState(1);

  useEffect(() => {}, []);

  return (
    <div className="">
      {/* <div > */}
      {/* Header */}
      <div className="head-bar row">
        <div className="col-md-6">
          <h5 className="  mb-2">Delivery Company & Partner</h5>
        </div>
      </div>

      {/* body */}
      <div className="px-3 sm-mt-2 sm-pt-4">
        <div className="mt-4">
          <div className="row">
            {/* {order_data?.map((ord_ele: any, ord_index: any) => ( */}
            <div className="col-md-6 col-12 mb-3">
              <div
                className="card p-3 cursor"
                onClick={() => {
                  //   navigate("/orders/" + ord_ele.id);
                }}
              >
                {/* header */}
                <div className="d-flex mb-2">
                  <div className="mb-2">
                    <h6 className="mb-2">Order Id </h6>
                  </div>
                </div>
              </div>
            </div>
            {/* ))} */}
          </div>
        </div>
        <div className="d-flex">
          {page_no > 1 ? (
            <button
              className="btn btn-primary "
              onClick={() => {
                SetPageNo((prevalue: any) => {
                  return prevalue - 1;
                });
              }}
            >
              Prev
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
}
