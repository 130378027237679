import React, { useState, useEffect, useRef } from "react";
import bootstrap from "../../assets/libraries/bootstrap/js/bootstrap";
import "react-loading-skeleton/dist/skeleton.css";

let myModal: any;
export default function ConfirmModal(props: any) {
  const [close_data, SetCloseData] = useState({
    action: "close",
    value: "",
    index: "",
    status: false
  });

  useEffect(() => {
    let myModal1: any = document.getElementById("ConfirmModal");
    myModal1.addEventListener("hidden.bs.modal", function (event:any) {
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      myModal = new bootstrap.Modal(
        document.getElementById("ConfirmModal"),
        {}
      );
      myModal.show();
    }
  }, [props.open]);
  

  return (
    <div
      className="modal fade"
      id="ConfirmModal"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog  modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title" id="ModalLabel">{props?.data?.title}</h6>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body" id="ConfirmModalBody">
            <div className="mb-3">
            {props?.data?.desc}
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-primary" onClick={() => {
              let close_data_t:any = {};
              close_data_t.status = true;
              close_data_t.data = props?.data;  
              props.done(close_data_t);
              myModal.hide();
            }}>{props?.data?.button?props?.data?.button:'Done'}</button>
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  );
}
