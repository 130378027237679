"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.calculateDirectionDelta = calculateDirectionDelta;

var _types = require("../types");

var _common = require("./common");

function calculateDirectionDelta(traceDirections) {
  var delta = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var length = traceDirections.length;
  var i = length - 1;
  var direction = _types.TraceDirectionKey.NONE;

  for (; i >= 0; i--) {
    var current = traceDirections[i];
    var currentKey = (0, _common.getDirectionKey)(current);
    var currentValue = (0, _common.getDirectionValue)(current[currentKey]);
    var prev = traceDirections[i - 1] || {};
    var prevKey = (0, _common.getDirectionKey)(prev);
    var prevValue = (0, _common.getDirectionValue)(prev[prevKey]);
    var difference = (0, _common.getDifference)(currentValue, prevValue);

    if (difference >= delta) {
      direction = currentKey;
      break;
    } else {
      direction = prevKey;
    }
  }

  return direction;
}